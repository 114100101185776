import {InsuranceCompanyType} from 'interfaces';

export const insuranceCompanyTypeOptions = [
  {
    label: 'Yes, I have commercial insurance',
    value: InsuranceCompanyType.commercial,
  },
  // {
  //   label: 'Yes, I have Medicare, Medicaid or Tricare',
  //   value: InsuranceCompanyType.public,
  // },
  {label: 'None', value: InsuranceCompanyType.none},
];

export const insuranceCompanyOptions = [
  {
    label: '47198 BO',
    value: 'BO00017',
  },
  {
    label: 'AARP (A United HealthCare Insurance Company)',
    value: '00283',
  },
  {
    label: 'AARP Dental',
    value: 'DE001',
  },
  {
    label: 'Absolute Total Care',
    value: '00344',
  },
  {
    label: 'ACCESS IPA BO',
    value: 'BO10061',
  },
  {
    label: 'Acclaim Inc',
    value: '01410',
  },
  {
    label: 'ACS Benefit Services',
    value: '000947',
  },
  {
    label: 'Adavanced Primary Care Network BO',
    value: 'BO00013',
  },
  {
    label: 'Administrative Services Inc',
    value: '00468',
  },
  {
    label: 'ADOC aka ADVANCED DOCTORS OF ORANGE COUNTY BO',
    value: 'BO10002',
  },
  {
    label: 'ADVANCED MEDICAL DOCTORS OF CA BO',
    value: 'BO10049',
  },
  {
    label: 'Advantage by Bridgeway Health Solutions',
    value: '00345',
  },
  {
    label: 'Advantage by Buckeye Community Health Plan',
    value: '00346',
  },
  {
    label: 'Advantage by Managed Health Services',
    value: '00347',
  },
  {
    label: 'Advantage by Superior HealthPlan',
    value: '00348',
  },
  {
    label: 'ADVANTAGE CARE IPA BO',
    value: 'BO10014',
  },
  {
    label: 'Advantica Benefits Dental',
    value: 'DE002',
  },
  {
    label: 'Advantica BO',
    value: 'BO00123',
  },
  {
    label: 'ADVANTRA (TEXAS, NEW MEXICO, ARIZONA ONLY)',
    value: '00292',
  },
  {
    label: 'Aetna',
    value: '00001',
  },
  {
    label: 'Aetna American Continental Ins Co',
    value: '00501',
  },
  {
    label: 'Aetna Better Health (CA)',
    value: '00979',
  },
  {
    label: 'Aetna Better Health (FL)',
    value: '00980',
  },
  {
    label: 'AETNA BETTER HEALTH (IL)',
    value: '000936',
  },
  {
    label: 'Aetna Better Health (KS)',
    value: '01411',
  },
  {
    label: 'AETNA BETTER HEALTH (KY)',
    value: '000937',
  },
  {
    label: 'AETNA BETTER HEALTH (LA)',
    value: '000938',
  },
  {
    label: 'Aetna Better Health (MD)',
    value: '00981',
  },
  {
    label: 'AETNA BETTER HEALTH (MI)',
    value: '000939',
  },
  {
    label: 'AETNA BETTER HEALTH (MO)',
    value: '000940',
  },
  {
    label: 'AETNA BETTER HEALTH (NJ)',
    value: '000942',
  },
  {
    label: 'Aetna Better Health (NV)',
    value: '00982',
  },
  {
    label: 'AETNA BETTER HEALTH (PA)',
    value: '000943',
  },
  {
    label: 'AETNA BETTER HEALTH (TX)',
    value: '000944',
  },
  {
    label: 'AETNA BETTER HEALTH (TX) CHIP',
    value: '000945',
  },
  {
    label: 'AETNA BETTER HEALTH (WV)',
    value: '000946',
  },
  {
    label: 'AETNA BETTER HEALTH VIRGINIA',
    value: '000935',
  },
  {
    label: 'Aetna Dental',
    value: 'DE003',
  },
  {
    label: 'Aetna Long Term Care',
    value: '00010',
  },
  {
    label: 'Aetna Retiree Medical Plan - Administrator',
    value: '00983',
  },
  {
    label: 'Aetna Senior Supplemental',
    value: '00677',
  },
  {
    label: 'Affinity BO',
    value: 'BO00034',
  },
  {
    label: 'Affinity Essentials',
    value: '000948',
  },
  {
    label: 'Affinity Health Plan',
    value: '00349',
  },
  {
    label: 'Affinity Health Plan Medicare',
    value: '00469',
  },
  {
    label: 'AFLAC',
    value: '000949',
  },
  {
    label: 'AFLAC - Dental',
    value: '00011',
  },
  {
    label: 'AFLAC - Medicare Supplemental',
    value: '00470',
  },
  {
    label: 'AGIA. Inc.',
    value: '00986',
  },
  {
    label: 'AHMC HEALTHCARE BO',
    value: 'BO00599',
  },
  {
    label: 'AIG',
    value: 'BO10042',
  },
  {
    label: 'AIMS',
    value: 'BO10076',
  },
  {
    label: 'Alabama Medicaid',
    value: '00013',
  },
  {
    label: 'Alabama Medicaid Dental',
    value: 'DE0068',
  },
  {
    label: 'Alameda Alliance Health Plan',
    value: '00459',
  },
  {
    label: 'Alaska Medicaid',
    value: '000951',
  },
  {
    label: 'Alicare Multiplan-BO',
    value: 'BO00062',
  },
  {
    label: 'ALIERA HEALTH CARE',
    value: '01345',
  },
  {
    label: 'Alignment Health Plan BO',
    value: 'BO00192',
  },
  {
    label: 'All Savers Life Insurance',
    value: '00635',
  },
  {
    label: 'Allegiance Benefit Plan Management',
    value: '00471',
  },
  {
    label: 'Alliant Health Plans',
    value: '00988',
  },
  {
    label: 'Allied Benefit Systems',
    value: '00636',
  },
  {
    label: 'Allied Benefits-BO',
    value: 'BO00035',
  },
  {
    label: 'Allina Health Aetna',
    value: '01346',
  },
  {
    label: 'Allways Health',
    value: 'BO000214',
  },
  {
    label: 'ALLWELL',
    value: '01336',
  },
  {
    label: 'AlohaCare',
    value: '00989',
  },
  {
    label: 'ALTAMED HEALTH SERVICES-BO',
    value: 'BO00024',
  },
  {
    label: 'Alternative Insurance Resources, Inc',
    value: '00990',
  },
  {
    label: 'Altus Dental',
    value: 'DE0129',
  },
  {
    label: 'AMBETTER',
    value: '01328',
  },
  {
    label: 'Ambetter of Arkansas',
    value: '00991',
  },
  {
    label: 'Ambetter Superior Health Plan',
    value: '00670',
  },
  {
    label: 'Ameriben',
    value: '00637',
  },
  {
    label: 'American Behavioral Benefit Managers',
    value: '000918',
  },
  {
    label:
      'American Family Insurance Group - Medicare Supplemental and PPO Policies',
    value: '00316',
  },
  {
    label: 'American Family Life Assurance Co. (AFLAC) Dental',
    value: 'DE004',
  },
  {
    label: 'American General Life and Accident',
    value: '00021',
  },
  {
    label: 'American Health Medicare',
    value: '00467',
  },
  {
    label: 'American Income Life Ins.Co.',
    value: '00992',
  },
  {
    label: 'American Medical Security (AMS)',
    value: '00993',
  },
  {
    label: 'American National Insurance Company',
    value: '00412',
  },
  {
    label: 'American National Life Insurance Company of Texas',
    value: '00413',
  },
  {
    label: 'American Network Ins. Medicare Supplement',
    value: '00994',
  },
  {
    label: 'American Postal Workers Union (APWU)',
    value: '00022',
  },
  {
    label: 'American Republic Insurance Company (ARIC)',
    value: '00023',
  },
  {
    label: 'American Republic Insurance Company (ARIC)',
    value: 'MD002',
  },
  {
    label: 'American Retirement Life Insurance Company Medicare Supplement',
    value: '00350',
  },
  {
    label: 'AmeriChoice of New Jersey (Commercial)',
    value: '00024',
  },
  {
    label: 'AmeriChoice of New Jersey (Medicaid)',
    value: '01412',
  },
  {
    label: 'Americo Financial Life & Annuity',
    value: '00995',
  },
  {
    label: 'AMERIGROUP',
    value: '00025',
  },
  {
    label: 'AmeriHealth (DE, NJ, PA)',
    value: '000929',
  },
  {
    label: 'AmeriHealth Administrators',
    value: '00460',
  },
  {
    label: 'AmeriHealth Caritas DC',
    value: '00996',
  },
  {
    label: 'AmeriHealth Caritas Delaware',
    value: '01413',
  },
  {
    label: 'AmeriHealth Caritas Iowa',
    value: '00997',
  },
  {
    label: 'AmeriHealth Caritas Louisiana (LaCare)',
    value: '00998',
  },
  {
    label: 'AmeriHealth Caritas PA',
    value: '00351',
  },
  {
    label: 'AmeriHealth Caritas VIP Care Plus',
    value: '00999',
  },
  {
    label: 'AmeriHealth New Jersey',
    value: '01000',
  },
  {
    label: 'AmeriHealth Northeast Pennsylvania',
    value: '01001',
  },
  {
    label: 'AmeriHealth Pennsylvania',
    value: '01002',
  },
  {
    label: 'AmeriHealth VIP Care',
    value: '01003',
  },
  {
    label: 'Amerisure-BO',
    value: 'BO00061',
  },
  {
    label: 'Ameritas Life',
    value: '00026',
  },
  {
    label: 'Ameritas Life Insurance Company Dental',
    value: 'DE0137',
  },
  {
    label: 'Angeles IPA-BO',
    value: 'BO00033',
  },
  {
    label: 'Antares',
    value: '00352',
  },
  {
    label: 'ANTHEM - GA',
    value: '01347',
  },
  {
    label: 'Anthem BCBS Kentucky',
    value: '00036',
  },
  {
    label: 'Anthem Blue Cross CA Dental',
    value: 'DE0055',
  },
  {
    label: 'Apex Benefits Services',
    value: '01004',
  },
  {
    label: 'APPLECARE MEDICAL GROUP-BO',
    value: 'BO00028',
  },
  {
    label: 'Arbor Health Plan',
    value: '01325',
  },
  {
    label: 'Arbor Health Plan (Only Nebraska Area)',
    value: '00461',
  },
  {
    label: 'Arise Health Care',
    value: '01005',
  },
  {
    label: 'Arise Health Plan Medicare Select Policy',
    value: '01006',
  },
  {
    label: 'ARIZONA COMPLETE HEALTH',
    value: '01348',
  },
  {
    label: 'Arizona Medicaid (AHCCCS)',
    value: '00027',
  },
  {
    label: 'Arizona Medicaid Dental',
    value: 'DE0069',
  },
  {
    label: "Arizona Physician's IPA",
    value: '00205',
  },
  {
    label: 'Arkansas Medicaid',
    value: '00028',
  },
  {
    label: 'Arkansas Medicaid Dental',
    value: 'DE0070',
  },
  {
    label: 'ARKANSAS TOTAL CARE',
    value: '01349',
  },
  {
    label: 'ARNETT HEALTH PLANS',
    value: '01350',
  },
  {
    label: 'ASR Health Benefits',
    value: '01400',
  },
  {
    label: 'Associacion De Maestros Puerto Rico',
    value: '01007',
  },
  {
    label: 'ASSOCIATED HISPANIC PHYS.',
    value: 'BO10040',
  },
  {
    label: 'ASSOCIATED HISPANIC PHYS.-BO',
    value: 'BO00026',
  },
  {
    label: 'Asuris Northwest Health',
    value: '00411',
  },
  {
    label: 'ATRIO HEALTH PLANS-BO',
    value: 'BO017',
  },
  {
    label: 'Aultcare',
    value: '00318',
  },
  {
    label: 'Automated Benefit Services',
    value: '01008',
  },
  {
    label: 'Auxiant',
    value: '01009',
  },
  {
    label: 'Avalon Administrative Services',
    value: '01010',
  },
  {
    label: 'Avera Health BO-BO',
    value: 'BO001800',
  },
  {
    label: 'Avera Health Plan',
    value: '000896',
  },
  {
    label: 'AVESIS INCORPORATED BO',
    value: 'BO100065',
  },
  {
    label: 'Avesis Vision BO',
    value: 'BO00149',
  },
  {
    label: 'AvMed Health Plans',
    value: '00206',
  },
  {
    label: 'Bankers Fidelity Life Insurance Company',
    value: '01011',
  },
  {
    label: 'Bankers Fidelity-BO',
    value: 'BO001830',
  },
  {
    label: 'Bankers Life & Casualty',
    value: '01012',
  },
  {
    label: 'Bankers Life And Casualty - EDI',
    value: '000926',
  },
  {
    label: 'Bankers Life And Casualty-BO',
    value: 'BO00063',
  },
  {
    label: 'Banner Aetna',
    value: '01351',
  },
  {
    label: 'Banner University Family Care',
    value: '01013',
  },
  {
    label: 'Baycare Select Health Plan',
    value: '01401',
  },
  {
    label: 'BC ILLINOIS MEDICARE ADVANTAGE',
    value: '01329',
  },
  {
    label: 'BC MONTANA MEDICARE ADVANTAGE',
    value: '01330',
  },
  {
    label: 'BC NEW MEXICO MEDICARE ADVANTAGE',
    value: '01331',
  },
  {
    label: 'BC OKLAHOMA MEDICARE ADVANTAGE',
    value: '01332',
  },
  {
    label: 'BC TEXAS MEDICARE ADVANTAGE',
    value: '01333',
  },
  {
    label: 'BCBS ANTHEM VIVITY',
    value: '000931',
  },
  {
    label: 'BCBS Of Alabama (Institutional)',
    value: '00462',
  },
  {
    label: 'BCBS of Alaska (Premera)',
    value: '01014',
  },
  {
    label: 'BCBS of Arizona',
    value: '00029',
  },
  {
    label: 'BCBS of Arkansas',
    value: '00030',
  },
  {
    label: 'BCBS of Central New York',
    value: '00330',
  },
  {
    label: 'BCBS of Connecticut',
    value: '00031',
  },
  {
    label: 'BCBS of Delaware',
    value: '01015',
  },
  {
    label: 'BCBS of Georgia',
    value: '00032',
  },
  {
    label: 'BCBS of Hawaii',
    value: '00463',
  },
  {
    label: 'BCBS of Illinois',
    value: '00033',
  },
  {
    label: 'BCBS of Indiana',
    value: '00034',
  },
  {
    label: 'BCBS of Louisiana',
    value: '00037',
  },
  {
    label: 'BCBS of Maine',
    value: '00038',
  },
  {
    label: 'BCBS of Michigan (Professional)',
    value: '01016',
  },
  {
    label: 'BCBS of Michigan (Thru Blue Exchange)',
    value: '00039',
  },
  {
    label: 'BCBS of Minnesota',
    value: '00040',
  },
  {
    label: 'BCBS of Mississippi',
    value: '00041',
  },
  {
    label: 'BCBS of Missouri',
    value: '00042',
  },
  {
    label: 'BCBS of Montana',
    value: '00475',
  },
  {
    label: 'BCBS of Nevada',
    value: '00043',
  },
  {
    label: 'BCBS of New Hampshire',
    value: '00044',
  },
  {
    label: 'BCBS of New Jersey (Horizon)',
    value: '00045',
  },
  {
    label: 'BCBS of New Mexico',
    value: '00046',
  },
  {
    label: 'BCBS of New York (Empire)',
    value: '00047',
  },
  {
    label: 'BCBS of New York (Excellus)',
    value: '00465',
  },
  {
    label: 'BCBS of North Dakota',
    value: '00311',
  },
  {
    label: 'BCBS of Ohio',
    value: '00048',
  },
  {
    label: 'BCBS of Oklahoma',
    value: '00454',
  },
  {
    label: 'BCBS of Oregon (Regence)',
    value: '00049',
  },
  {
    label: 'BCBS of Pennsylvania (Highmark)',
    value: '00050',
  },
  {
    label: 'BCBS of Pennsylvania (Highmark) [Institutional]',
    value: '00476',
  },
  {
    label: 'BCBS of Rhode Island',
    value: '00051',
  },
  {
    label: 'BCBS of Rochester New York Dental',
    value: 'DE0056',
  },
  {
    label: 'BCBS of the Rochester Area (NY)',
    value: '00308',
  },
  {
    label: 'BCBS of Utica-Watertown (NY)',
    value: '00309',
  },
  {
    label: 'BCBS of Vermont',
    value: '00477',
  },
  {
    label: 'BCBS of Virginia',
    value: '00052',
  },
  {
    label: 'BCBS of West Virginia',
    value: '00331',
  },
  {
    label: 'BCBS of Western New York',
    value: '00325',
  },
  {
    label: 'BCBS of Wisconsin',
    value: '00053',
  },
  {
    label: 'BCBS of Wyoming',
    value: '00312',
  },
  {
    label: 'BCBS Puerto Rico(Triple-S Salud)',
    value: '01300',
  },
  {
    label: 'BCBS Texas Medicaid STAR CHIP',
    value: '01017',
  },
  {
    label: 'BCBS TEXAS MEDICAID STAR Kids',
    value: '01352',
  },
  {
    label: 'BCBSMN BLUE PLUS MEDICAID',
    value: '01353',
  },
  {
    label: 'BCBSMN BLUE PLUS MEDICAID NON-EMERG TRANSPORTATION',
    value: '01354',
  },
  {
    label: 'Beacon Value Options-BO',
    value: 'BO00145',
  },
  {
    label: 'Beechstreet-BO',
    value: 'BO008',
  },
  {
    label: 'Behavioral Health Systems, Inc.',
    value: '01019',
  },
  {
    label: 'Benefit Management Inc',
    value: '00478',
  },
  {
    label: 'BERKSHIRE HATHAWAY INSURANCE CO',
    value: 'BO10056',
  },
  {
    label: 'Best Choice Health Plan',
    value: '00419',
  },
  {
    label: 'Better Health Plans (Unison Health Plan)',
    value: '00055',
  },
  {
    label: 'Better Health Plans of Florida',
    value: '01020',
  },
  {
    label: 'BIND',
    value: '01378',
  },
  {
    label: 'Blue Benefit Administrators of Mass',
    value: '01021',
  },
  {
    label:
      'Blue Cross Blue Sheild Delaware Fully - Insured Dental Group Business',
    value: 'DE0126',
  },
  {
    label: 'Blue Cross Blue Shield Alabama (Commercial)',
    value: '00207',
  },
  {
    label: 'Blue Cross Blue Shield Alaska (Premera)',
    value: '00209',
  },
  {
    label: 'Blue Cross Blue Shield Colorado',
    value: '00210',
  },
  {
    label: 'Blue Cross Blue Shield Delaware (Carefirst)',
    value: '00282',
  },
  {
    label: 'Blue Cross Blue Shield District of Columbia (Carefirst)',
    value: '00288',
  },
  {
    label: 'Blue Cross Blue Shield Florida',
    value: '00211',
  },
  {
    label: 'Blue Cross Blue Shield Iowa (Wellmark)',
    value: '00212',
  },
  {
    label: 'Blue Cross Blue Shield Kansas',
    value: '00213',
  },
  {
    label: 'Blue Cross Blue Shield Kansas City',
    value: '00306',
  },
  {
    label: 'Blue Cross Blue Shield Maryland (Carefirst)',
    value: '00289',
  },
  {
    label: 'Blue Cross Blue Shield Massachusetts',
    value: '00214',
  },
  {
    label: 'Blue Cross Blue Shield Nebraska',
    value: '00215',
  },
  {
    label: 'Blue Cross Blue Shield North Carolina',
    value: '00216',
  },
  {
    label: 'Blue Cross Blue Shield of Kansas City MO Dental',
    value: 'DE0057',
  },
  {
    label: 'Blue Cross Blue Shield of Massachusetts Dental',
    value: 'DE0058',
  },
  {
    label: 'Blue Cross Blue Shield of South Carolina Dental',
    value: 'DE0059',
  },
  {
    label: 'Blue Cross Blue Shield South Carolina',
    value: '00217',
  },
  {
    label: 'Blue Cross Blue Shield South Dakota (Wellmark)',
    value: '00218',
  },
  {
    label: 'Blue Cross Blue Shield Tennessee',
    value: '00219',
  },
  {
    label: 'Blue Cross Blue Shield Texas',
    value: '00220',
  },
  {
    label: 'BLUE CROSS COMMUNITY CENTENNIAL',
    value: '01355',
  },
  {
    label: 'BLUE CROSS COMMUNITY HEALTH PLANS',
    value: '01356',
  },
  {
    label: 'Blue Cross Community Options',
    value: '01022',
  },
  {
    label: 'Blue Cross of Arkansas Dental',
    value: 'DE0060',
  },
  {
    label: 'Blue Cross of California',
    value: '00002',
  },
  {
    label: 'Blue Cross of Idaho',
    value: '00056',
  },
  {
    label: 'Blue Cross of Northeastern Pennsylvania',
    value: '00057',
  },
  {
    label: 'Blue Cross of Utah (Regence)',
    value: '01023',
  },
  {
    label: 'Blue Cross Washington (Premera)',
    value: '00221',
  },
  {
    label: 'Blue Exchange',
    value: 'S001',
  },
  {
    label: 'Blue Shield of CA - Pending Eligibility',
    value: 'BO00002',
  },
  {
    label: 'Blue Shield of CA Promise - All Numeric Member ID',
    value: '01405',
  },
  {
    label: 'Blue Shield of California',
    value: '00003',
  },
  {
    label: 'Blue Shield of Idaho',
    value: '01322',
  },
  {
    label: 'Blue Shield of North Eastern New York',
    value: '00480',
  },
  {
    label: 'Blue Shield of Pennsylvania Dental Plus',
    value: 'DE0127',
  },
  {
    label: 'Blue Shield of Washington (Regence)',
    value: '00058',
  },
  {
    label: 'BlueChoice HealthPlan South Carolina Medicaid',
    value: '00284',
  },
  {
    label: 'BlueCross BlueShield of National Capital Area (CareFirst) Dental',
    value: 'DE0061',
  },
  {
    label: 'BLUECROSS BLUESHIELD OF WESTERN NEW YORK MEDICAID',
    value: '01357',
  },
  {
    label: 'BlueCross Medicare Advantage',
    value: '01024',
  },
  {
    label: 'Bluegrass Family Health',
    value: '00290',
  },
  {
    label: 'BMC HealthNet Plan',
    value: '00399',
  },
  {
    label: 'Boon Group',
    value: '01025',
  },
  {
    label: 'Boon-Chapman Benefit Admin',
    value: '00420',
  },
  {
    label: 'BRAND NEW DAY HMO BO',
    value: 'BO10021',
  },
  {
    label: 'Bravo Health, Inc.',
    value: '00222',
  },
  {
    label: 'BRIDGESPAN',
    value: '000952',
  },
  {
    label: 'Bridgeway Arizona',
    value: '00353',
  },
  {
    label: 'Bright Health',
    value: '01026',
  },
  {
    label: 'Bright Health Medicare Advantage',
    value: '01338',
  },
  {
    label: 'BRIGHT HEALTH PHYSICIANS/-BO',
    value: 'BO00027',
  },
  {
    label: 'Buckeye Community Health',
    value: '00354',
  },
  {
    label: 'Cal-Viva',
    value: '01030',
  },
  {
    label: 'CALCARE',
    value: 'BO10026',
  },
  {
    label: 'California Medicaid (Medi-Cal)',
    value: '00059',
  },
  {
    label: 'California Medicaid - Medi-Cal Dental',
    value: 'DE0071',
  },
  {
    label: 'CalOptima',
    value: '01029',
  },
  {
    label: 'Capital Blue Cross (Pennsylvania)',
    value: '00060',
  },
  {
    label: 'Capital District Physicians Health Plan (CDPHP)',
    value: '00328',
  },
  {
    label: 'Capital Health Plan',
    value: '01406',
  },
  {
    label: 'CAPITOL ADMINISTRATORS',
    value: 'BO10062',
  },
  {
    label: 'Capitol Administrators - EDI',
    value: '01031',
  },
  {
    label: 'CAPMN-BO',
    value: 'BO00023',
  },
  {
    label: 'CAPROCK',
    value: '00482',
  },
  {
    label: 'Care First Health Plan-BO',
    value: 'BO000469',
  },
  {
    label: 'Care Improvement Plus',
    value: '00891',
  },
  {
    label: 'Care Improvement plus (BO)-BO',
    value: 'BO00098',
  },
  {
    label: 'Care1st Health Plan Arizona',
    value: '00638',
  },
  {
    label: 'Care1st of California - BO',
    value: 'BO000144',
  },
  {
    label: 'Carefirst Administrators',
    value: '01402',
  },
  {
    label: 'Carefirst of Maryland Inc. Dental',
    value: 'DE0062',
  },
  {
    label: 'CareMore Health Plan',
    value: '00671',
  },
  {
    label: 'CarePlus Health Plan',
    value: '00223',
  },
  {
    label: 'CareSource Dental',
    value: 'DE0072',
  },
  {
    label: 'CareSource Health',
    value: '00224',
  },
  {
    label: 'CARESOURCE INDIANA',
    value: '01358',
  },
  {
    label: 'CARESOURCE KENTUCKY',
    value: '01371',
  },
  {
    label: 'CARESOURCE MEDICAID KENTUCKY',
    value: '01372',
  },
  {
    label: 'CARESOURCE MEDICARE ADVANTAGE KENTUCKY',
    value: '01373',
  },
  {
    label: 'CARESOURCE OF GEORGIA',
    value: '01359',
  },
  {
    label: 'CARESOURCE OHIO',
    value: '01360',
  },
  {
    label: 'CARESOURCE WEST VIRGINIA',
    value: '01361',
  },
  {
    label: 'CARMO-BO',
    value: 'BO00025',
  },
  {
    label: 'Carolina Care Plan, Inc. (CCP)',
    value: '00483',
  },
  {
    label: 'Carpenters Health and Welfare Trust Fund of St Louis',
    value: '01033',
  },
  {
    label: 'Carson Smith Scholarship Fund',
    value: 'BO00121',
  },
  {
    label: 'CBCA Administrators-BO',
    value: 'BO00036',
  },
  {
    label: 'CDS Group Health',
    value: '01034',
  },
  {
    label: 'Celtic Insurance',
    value: '01035',
  },
  {
    label: 'CeltiCare',
    value: '00414',
  },
  {
    label: 'CenCal',
    value: '01036',
  },
  {
    label: 'Cenpatico - Arizona',
    value: '00356',
  },
  {
    label: 'Cenpatico - Florida',
    value: '00357',
  },
  {
    label: 'Cenpatico - Georgia',
    value: '00358',
  },
  {
    label: 'Cenpatico - Illinois',
    value: '01037',
  },
  {
    label: 'Cenpatico - Indiana',
    value: '00359',
  },
  {
    label: 'Cenpatico - Kansas',
    value: '00360',
  },
  {
    label: 'Cenpatico - Kentucky',
    value: '01038',
  },
  {
    label: 'Cenpatico - Massachusetts',
    value: '00415',
  },
  {
    label: 'Cenpatico - Mississippi',
    value: '01039',
  },
  {
    label: 'Cenpatico - Missouri',
    value: '00640',
  },
  {
    label: 'Cenpatico - New Hampshire',
    value: '01040',
  },
  {
    label: 'Cenpatico - Ohio',
    value: '00361',
  },
  {
    label: 'Cenpatico - South Carolina',
    value: '00362',
  },
  {
    label: 'Cenpatico - Texas',
    value: '01041',
  },
  {
    label: 'Cenpatico - Wisconsin',
    value: '01042',
  },
  {
    label: 'Cenpatico Behavioral Health',
    value: '01043',
  },
  {
    label: 'Cenpatico Behavioral Health Texas',
    value: '01044',
  },
  {
    label: 'Centene - Advantage by Peach State',
    value: '01045',
  },
  {
    label: 'Centene - Advantage by Sunshine State',
    value: '01046',
  },
  {
    label: 'Centene - Ambetter Sunshine Health',
    value: '01047',
  },
  {
    label: 'Centene - California Health & Wellness',
    value: '01048',
  },
  {
    label: 'Centene - Coordinated Care',
    value: '01049',
  },
  {
    label: 'Centene - Granite State Health Plan',
    value: '01050',
  },
  {
    label: 'Centene - Home State Health Plan',
    value: '01051',
  },
  {
    label: 'Centene - Kentucky Spirit Health Plan',
    value: '01052',
  },
  {
    label: 'Centene - Magnolia Health Plan',
    value: '01053',
  },
  {
    label: 'Centene - Michigan Fidelis Secure Care',
    value: '01054',
  },
  {
    label: 'Centene - New Hampshire Healthy Families',
    value: '01055',
  },
  {
    label: 'Centene - Peach State Health Plan',
    value: '00385',
  },
  {
    label: 'Centene Advantage Plans',
    value: '01056',
  },
  {
    label: 'CENTER IPA',
    value: 'BO10034',
  },
  {
    label: 'Central California Alliance for Health',
    value: '01057',
  },
  {
    label: 'CENTRAL HEALTH',
    value: 'BO00193',
  },
  {
    label: 'Central Health Plan',
    value: 'BO10019',
  },
  {
    label: 'Central Health Plan-BO',
    value: 'BO00134',
  },
  {
    label: 'Central Reserve Life Insurance Company Medicare Supplement',
    value: '00363',
  },
  {
    label: 'Central Senior Care',
    value: '01058',
  },
  {
    label: 'Central States Fund',
    value: '00315',
  },
  {
    label: 'Central States Indemnity',
    value: '01059',
  },
  {
    label: 'Cerner Health Plan',
    value: '01060',
  },
  {
    label: 'CHAMPVA/Spina Bifida/Children of Women Vietnam Vets',
    value: '00063',
  },
  {
    label: 'CHARTIS',
    value: 'BO10077',
  },
  {
    label: 'CHC - Coventry Health Care Federal',
    value: '00313',
  },
  {
    label: 'CHC - Illinois',
    value: '0009110',
  },
  {
    label: 'CHC FLORIDA/VISTA/SUMMIT',
    value: '00366',
  },
  {
    label: 'CHC Nevada Altius Health Plans',
    value: '00014',
  },
  {
    label: 'CHC of Carolinas',
    value: '000958',
  },
  {
    label: 'CHC of Delaware',
    value: '00064',
  },
  {
    label: 'CHC of Georgia',
    value: '00065',
  },
  {
    label: 'CHC of Iowa',
    value: '00066',
  },
  {
    label: 'CHC of Kansas',
    value: '00067',
  },
  {
    label: 'CHC of Louisiana',
    value: '00068',
  },
  {
    label: 'CHC of Nebraska',
    value: '00069',
  },
  {
    label: 'Childrens Mercy PCN',
    value: '01062',
  },
  {
    label: 'CHP Direct',
    value: '01063',
  },
  {
    label: 'Christian Brothers Service',
    value: '00653',
  },
  {
    label: 'Christie Student Health Plans',
    value: '01064',
  },
  {
    label: 'CHRISTUS Health Plan Medicaid',
    value: '01065',
  },
  {
    label: 'CHRISTUS Health Plan New Mexico',
    value: '01066',
  },
  {
    label: 'CHRISTUS Health Plan NM Medicare Advantage',
    value: '01067',
  },
  {
    label: 'CHRISTUS Health Plan TX HIX',
    value: '01342',
  },
  {
    label: 'CHURCH MUTUAL INSURANCE CO',
    value: 'BO10086',
  },
  {
    label: 'Cigna',
    value: '00004',
  },
  {
    label: 'CIGNA Dental',
    value: 'DE005',
  },
  {
    label: 'Claims Management Service Inc',
    value: '01068',
  },
  {
    label: 'Clear Health Alliance',
    value: '01403',
  },
  {
    label: 'Clover Health - CarePoint Medicare Advantage',
    value: '01069',
  },
  {
    label: 'CMFG Life Insurance',
    value: '01070',
  },
  {
    label: 'CNIC-BO',
    value: 'BO00037',
  },
  {
    label: 'COASTAL COMMUNITIES PHYSICIAN NTWRK',
    value: 'BO10067',
  },
  {
    label: 'Colonial Penn Insurance-BO',
    value: 'BO018',
  },
  {
    label: 'Colonial Penn Life',
    value: '000910',
  },
  {
    label: 'Colonial Penn Life',
    value: '00894',
  },
  {
    label: 'Colorado Access',
    value: '00070',
  },
  {
    label: 'COLORADO COMMUNITY HEALTH ALLIANCE',
    value: '01362',
  },
  {
    label: 'Colorado Medicaid',
    value: '00071',
  },
  {
    label: 'Colorado Medicaid Dental',
    value: 'DE0073',
  },
  {
    label: 'Commercial Paper Generic',
    value: 'BO00140',
  },
  {
    label: 'COMMUNITY CARE BHO',
    value: 'BO22231',
  },
  {
    label: 'Community Care of Oklahoma',
    value: '00072',
  },
  {
    label: 'Community First Health Plans',
    value: '01390',
  },
  {
    label: 'Community Health Choice',
    value: '01071',
  },
  {
    label: 'Community Health Group',
    value: '00641',
  },
  {
    label: 'Community Health Plan of Washington',
    value: '00291',
  },
  {
    label: 'Community Health Plan-BO',
    value: 'BO000994',
  },
  {
    label: 'Community HealthFirst Medicare (CHF Medicare Advantage)',
    value: '00422',
  },
  {
    label: 'CompBenefits Dental',
    value: 'DE006',
  },
  {
    label: 'ComPsych Employee Assistance Program',
    value: 'BO00144',
  },
  {
    label: 'COMPWEST',
    value: 'BO10063',
  },
  {
    label: 'Concentrix Insurance Solutions',
    value: '01072',
  },
  {
    label: 'ConnectiCare',
    value: '00423',
  },
  {
    label: 'ConnectiCare - Medicare',
    value: '01073',
  },
  {
    label: 'Connecticut Medicaid',
    value: '00073',
  },
  {
    label: 'Connecticut Medicaid Dental',
    value: 'DE0074',
  },
  {
    label: 'Constellation Health (INMEDIATA HEALTH GROUP)',
    value: '01074',
  },
  {
    label: 'Consumers Life',
    value: '01075',
  },
  {
    label: 'Consumers Mutual Insurance of Michigan',
    value: '01076',
  },
  {
    label: 'Continental General Insurance Company Medicare Supplement',
    value: '00364',
  },
  {
    label: 'Cook Children’s Health Plan',
    value: '01077',
  },
  {
    label: 'Cooperative Benefit Administrators (CBA)',
    value: '00074',
  },
  {
    label: 'Cooperative Benefit Administrators Dental',
    value: 'DE007',
  },
  {
    label: 'CoreSource (FMH)',
    value: '00076',
  },
  {
    label: 'CoreSource (Little Rock)',
    value: '00077',
  },
  {
    label: 'CoreSource (MD, PA, IL)',
    value: '00078',
  },
  {
    label: 'CoreSource (OH)',
    value: '00080',
  },
  {
    label: 'Coresource Little Rock Dental',
    value: 'DE008',
  },
  {
    label: 'Corporate Benefit Service',
    value: '00424',
  },
  {
    label: 'CORVEL CORPORATION',
    value: 'BO10087',
  },
  {
    label: 'Country Life Insurance Company',
    value: 'MD001',
  },
  {
    label: 'COUNTY OF KERN POS PLAN',
    value: 'BO10050',
  },
  {
    label: 'CountyCare',
    value: '01078',
  },
  {
    label: 'Covenant Administrators (TPA)',
    value: '01079',
  },
  {
    label: 'Coventry Advantra Freedom - CHC Texas',
    value: '00082',
  },
  {
    label: 'COVENTRY ADVANTRA SAVINGS',
    value: '00229',
  },
  {
    label: 'Coventry Health and Life (Oklahoma)',
    value: '00083',
  },
  {
    label: 'COVENTRY HEALTH AND LIFE-NEVADA',
    value: '00293',
  },
  {
    label: 'Coventry Health Care Carelink (Advantra)',
    value: '00084',
  },
  {
    label: 'Coventry Health Care Carelink Medicaid',
    value: '00085',
  },
  {
    label: 'Coventry Health Care Carenet',
    value: '00086',
  },
  {
    label: 'COVENTRY HEALTH CARE CAROLINAS WELLPATH',
    value: '000933',
  },
  {
    label: 'COVENTRY HEALTH CARE VIRGINIA',
    value: '01337',
  },
  {
    label: 'COVENTRY HEALTH LIFE-TN ONLY',
    value: '00227',
  },
  {
    label: 'Coventry Healthcare National Network',
    value: '00087',
  },
  {
    label: 'COVENTRY MISSOURI',
    value: '00294',
  },
  {
    label: 'COVENTRYCARES',
    value: '00367',
  },
  {
    label: 'CoventryCares of Michigan',
    value: '000904',
  },
  {
    label: 'COVENTRYONE',
    value: '00295',
  },
  {
    label: 'Cox Health Plan',
    value: '01080',
  },
  {
    label: 'CSA Fraternal Live Medicare Supplement',
    value: '00368',
  },
  {
    label: 'CSI Life Insurance Company',
    value: '01082',
  },
  {
    label: 'Culinary Health Fund',
    value: '01083',
  },
  {
    label: 'DakotaCare',
    value: '00425',
  },
  {
    label: 'Dakotacare BO-BO',
    value: 'BO001801',
  },
  {
    label: 'Davis Vision BO',
    value: 'BO000215',
  },
  {
    label: 'DBP - Americhoice (NJ/PA) / UHC (MS) Dental',
    value: 'DE009',
  },
  {
    label: 'Dean Health Plan',
    value: '01084',
  },
  {
    label: 'DeCare Dental Health Insurance',
    value: 'DE0120',
  },
  {
    label: 'Definity Health',
    value: '01085',
  },
  {
    label: 'Delaware MAP-BO',
    value: 'BO077',
  },
  {
    label: 'Delaware MAP-BO',
    value: 'BO00077',
  },
  {
    label: 'Delaware Medicaid',
    value: '00455',
  },
  {
    label: 'Dell Childrens Health Plan',
    value: '000920',
  },
  {
    label: 'Delta Dental (Northeast) (ME NH VT)',
    value: 'DE0130',
  },
  {
    label: 'Delta Dental CA FedVIP',
    value: 'DE0131',
  },
  {
    label: 'Delta Dental District of Columbia',
    value: 'DE0132',
  },
  {
    label: 'Delta Dental Insurance Co. (DDIC)',
    value: 'DE0133',
  },
  {
    label: 'Delta Dental Massachusetts',
    value: 'DE0134',
  },
  {
    label: 'Delta Dental of Alabama (DDIC)',
    value: 'DE0136',
  },
  {
    label: 'Delta Dental of Arizona',
    value: 'DE0138',
  },
  {
    label: 'Delta Dental of Arkansas',
    value: 'DE0139',
  },
  {
    label: 'Delta Dental of California',
    value: 'DE0140',
  },
  {
    label: 'Delta Dental of Delaware',
    value: 'DE0141',
  },
  {
    label: 'Delta Dental of Florida (DDIC)',
    value: 'DE0142',
  },
  {
    label: 'Delta Dental of Georgia (DDIC)',
    value: 'DE0143',
  },
  {
    label: 'Delta Dental of Idaho',
    value: 'DE0144',
  },
  {
    label: 'Delta Dental of Illinois Group Plans',
    value: 'DE0145',
  },
  {
    label: 'Delta Dental of Indiana',
    value: 'DE0146',
  },
  {
    label: 'Delta Dental of Iowa',
    value: 'DE0147',
  },
  {
    label: 'Delta Dental of Iowa - Dental Wellness Plan',
    value: 'DE0175',
  },
  {
    label: 'Delta Dental of Kansas',
    value: 'DE0148',
  },
  {
    label: 'Delta Dental of Kentucky',
    value: 'DE0149',
  },
  {
    label: 'Delta Dental of Louisiana (DDIC)',
    value: 'DE0150',
  },
  {
    label: 'Delta Dental of Maryland and Pennsylvania',
    value: 'DE0151',
  },
  {
    label: 'Delta Dental of Michigan',
    value: 'DE0152',
  },
  {
    label: 'Delta Dental of Minnesota',
    value: 'DE0153',
  },
  {
    label: 'Delta Dental of Mississippi (DDIC)',
    value: 'DE0154',
  },
  {
    label: 'Delta Dental of Missouri',
    value: 'DE0155',
  },
  {
    label: 'Delta Dental of Montana (DDIC)',
    value: 'DE0156',
  },
  {
    label: 'Delta Dental of Nebraska',
    value: 'DE0157',
  },
  {
    label: 'Delta Dental of Nevada (DDIC)',
    value: 'DE0158',
  },
  {
    label: 'Delta Dental of New Jersey',
    value: 'DE0159',
  },
  {
    label: 'Delta Dental of New Mexico',
    value: 'DE0160',
  },
  {
    label: 'Delta Dental of New York',
    value: 'DE0161',
  },
  {
    label: 'Delta Dental of North Carolina',
    value: 'DE0162',
  },
  {
    label: 'Delta Dental of North Dakota',
    value: 'DE0163',
  },
  {
    label: 'Delta Dental of Ohio',
    value: 'DE0164',
  },
  {
    label: 'Delta Dental of Oklahoma',
    value: 'DE0165',
  },
  {
    label: 'Delta Dental of Rhode Island',
    value: 'DE0166',
  },
  {
    label: 'Delta Dental of South Carolina',
    value: 'DE0167',
  },
  {
    label: 'Delta Dental of Tennessee',
    value: 'DE0168',
  },
  {
    label: 'Delta Dental of Texas (DDIC)',
    value: 'DE0169',
  },
  {
    label: 'Delta Dental of Utah (DDIC)',
    value: 'DE0170',
  },
  {
    label: 'Delta Dental of Washington',
    value: 'DE0171',
  },
  {
    label: 'Delta Dental of Washington DC',
    value: 'DE0172',
  },
  {
    label: 'Delta Dental of West Virginia',
    value: 'DE0173',
  },
  {
    label: 'Delta Dental of Wisconsin',
    value: 'DE0174',
  },
  {
    label: 'Delta Dental Puerto Rico',
    value: 'DE0135',
  },
  {
    label: 'DELTA HEALTH SYSTEMS',
    value: 'BO10027',
  },
  {
    label: 'DeltaCare USA - Claims',
    value: '01086',
  },
  {
    label: 'DeltaCare USA - Encounters',
    value: '01087',
  },
  {
    label: 'DeltaCare USA Claims Dental',
    value: 'DE0067',
  },
  {
    label: 'DeltaCare USA Dental',
    value: 'DE0010',
  },
  {
    label: 'Delware Medical Assistance Program',
    value: 'PL003',
  },
  {
    label: 'DentaQuest - Government Plans Florida Dental',
    value: 'DE0078',
  },
  {
    label: 'DentaQuest Illinois Dental',
    value: 'DE0076',
  },
  {
    label: 'DentaQuest Maryland Dental',
    value: 'DE0075',
  },
  {
    label: 'DentaQuest Virginia Dental',
    value: 'DE0077',
  },
  {
    label: 'Dentist Direct LLC Dental',
    value: 'DE0011',
  },
  {
    label: 'Denver Health Medical Plan',
    value: '00426',
  },
  {
    label: 'Deseret Mutual (DMBA)',
    value: '00427',
  },
  {
    label: 'Desert Mutual',
    value: '000701',
  },
  {
    label: 'DEVOTED HEALTH',
    value: '01363',
  },
  {
    label: 'Dialysis Verification-BO',
    value: 'BO010',
  },
  {
    label: 'Diamond Plan (Maryland Medicaid)',
    value: '00088',
  },
  {
    label: "Director's Guild",
    value: '00089',
  },
  {
    label: 'District No. 9, I. A. of M. & A. W. Welfare Trust',
    value: '01088',
  },
  {
    label: 'District of Columbia Medicaid',
    value: '00231',
  },
  {
    label: 'District of Columbia Medicaid Dental',
    value: 'DE0079',
  },
  {
    label: 'Diversified Administration Corp',
    value: '01089',
  },
  {
    label: 'Driscoll Health Plan',
    value: '01090',
  },
  {
    label: 'Easy Choice Health Plan-BO',
    value: 'BO00137',
  },
  {
    label: 'EBA&M',
    value: 'BO10043',
  },
  {
    label: 'EBMS',
    value: '00643',
  },
  {
    label: 'Educators Mutual Insurance (EMI)',
    value: '01091',
  },
  {
    label: 'EHS MEDICAL GROUP IPA',
    value: 'BO10005',
  },
  {
    label: 'Elderplan Inc',
    value: '00642',
  },
  {
    label: 'ELDERPLAN-BO',
    value: 'BO00038',
  },
  {
    label: 'Emblem Health Dental',
    value: 'DE0012',
  },
  {
    label: 'Emblem Health Plan-BO',
    value: 'BO019',
  },
  {
    label: 'EMBLEM HEALTH-BO',
    value: 'BO00053',
  },
  {
    label: 'EMBLEM HEALTH-EDI',
    value: '000101',
  },
  {
    label: 'EMPIRE BC NEW YORK',
    value: '01364',
  },
  {
    label: 'Empire Blue Cross and Blue Shield Dental',
    value: 'DE0064',
  },
  {
    label: 'Empire Blue Cross Blue Shield Dental',
    value: 'DE0063',
  },
  {
    label: 'EMPLOYEE BENEFIT MANAGEMENT SYSTEM (EBMS)',
    value: '000954',
  },
  {
    label: 'Employers Health Insurance Dental',
    value: 'DE0013',
  },
  {
    label: 'Envolve BO',
    value: 'BO00596',
  },
  {
    label: 'EPSI, Inc.',
    value: '01093',
  },
  {
    label: 'Equitable Life Casualty Ins Co',
    value: '00675',
  },
  {
    label: 'ESIS',
    value: 'BO10038',
  },
  {
    label: 'ESIS CENTRAL WC CLAIMS',
    value: 'BO10078',
  },
  {
    label: 'Essence Healthcare',
    value: '00403',
  },
  {
    label: 'Evercare',
    value: '01094',
  },
  {
    label: 'Everence Dental',
    value: 'DE0014',
  },
  {
    label: 'Everence Financial',
    value: '01095',
  },
  {
    label: 'Evergreen Health',
    value: '01096',
  },
  {
    label: 'Evergreen Health Co-Op',
    value: '01097',
  },
  {
    label: 'Expert Benefit Solutions',
    value: '01098',
  },
  {
    label: 'Eye Quest-BO',
    value: 'BO00124',
  },
  {
    label: 'Eyefinity-BO',
    value: 'BO9001',
  },
  {
    label: 'EYEMED CLAIMS BO',
    value: 'BO10004',
  },
  {
    label: 'Fallon Community Health Plan',
    value: '00092',
  },
  {
    label: 'Fallon Community Health Plan (Exception)',
    value: '01099',
  },
  {
    label: 'Farm Bureau Health Plans',
    value: '00682',
  },
  {
    label: 'FARMERS',
    value: 'BO10088',
  },
  {
    label: 'FCE Benefit Administrators',
    value: '00644',
  },
  {
    label: 'FCS01-BO',
    value: 'BO00020',
  },
  {
    label: 'Federated Insurance Company',
    value: '00093',
  },
  {
    label: 'FHHS-BO',
    value: 'BO00125',
  },
  {
    label: 'Fidelis Care New York',
    value: '00329',
  },
  {
    label: 'First Ameritas Life Insurance Corporation of New York Dental',
    value: 'DE0015',
  },
  {
    label: 'First Ameritas of New York',
    value: '00094',
  },
  {
    label: 'First Carolina Care',
    value: '01100',
  },
  {
    label: 'First Choice Health-BO',
    value: 'BO000995',
  },
  {
    label: 'First Community Health Plan',
    value: '01101',
  },
  {
    label: 'First Community Health Plans',
    value: '000917',
  },
  {
    label: 'FIRST COMP INSURANCE',
    value: 'BO10068',
  },
  {
    label: 'First Health (various plans)-BO',
    value: 'BO006',
  },
  {
    label: 'First Health Life',
    value: '09001',
  },
  {
    label: 'First Health Network (Coventry National)',
    value: '01102',
  },
  {
    label: 'First Medical Network',
    value: '01103',
  },
  {
    label: 'First Priority Hlth-BO',
    value: 'BO00059',
  },
  {
    label: 'First Reliance Standard Life',
    value: '00096',
  },
  {
    label: 'First Reliance Standard Life Ins. Co. (NY Business) Dental',
    value: 'DE0016',
  },
  {
    label: 'First United American Ins.Co',
    value: '01104',
  },
  {
    label: 'First United American Life-BO',
    value: 'BO00039',
  },
  {
    label: 'FirstCare',
    value: '01105',
  },
  {
    label: 'Fiserv Health',
    value: 'PL002',
  },
  {
    label: 'FISERV WAUSAU',
    value: '01106',
  },
  {
    label: 'Florida Health Care Plans',
    value: '00645',
  },
  {
    label: 'Florida Medicaid',
    value: '00098',
  },
  {
    label: 'Florida Medicaid Dental',
    value: 'DE0080',
  },
  {
    label: 'FMH-BO',
    value: 'BO00126',
  },
  {
    label: 'Food Employers & Bakery',
    value: '01107',
  },
  {
    label: 'Freedom Blue',
    value: '00319',
  },
  {
    label: 'Freedom Health Medicare Advantage',
    value: '01408',
  },
  {
    label: 'FREEDOM LIFE INS COMPANY OF AMERICA',
    value: 'BO10079',
  },
  {
    label: 'Fresenius Health Partners',
    value: '01109',
  },
  {
    label: 'Fringe Benefits',
    value: '01110',
  },
  {
    label: 'FSL Admin by KBA',
    value: '01111',
  },
  {
    label: 'FUTURE CARE MEDICAL ASSOCIATES IPA',
    value: 'BO10089',
  },
  {
    label: 'G.M.P. Employers Retiree Trust',
    value: '01112',
  },
  {
    label: 'GALLAGHER BASSETT',
    value: 'BO10041',
  },
  {
    label: 'Gateway Health Plan',
    value: '000919',
  },
  {
    label: 'Geisinger Health Plan',
    value: '00458',
  },
  {
    label: 'Geisinger Health Plan Gold',
    value: '01113',
  },
  {
    label: 'Geisinger Health Plan-BO',
    value: 'BO00076',
  },
  {
    label: 'GEMCare',
    value: '01114',
  },
  {
    label: 'GEMCARE HEALTH PLAN',
    value: 'BO10046',
  },
  {
    label: 'Georgia Medicaid',
    value: '00100',
  },
  {
    label: 'Georgia Medicaid Dental',
    value: 'DE0081',
  },
  {
    label: 'GHI',
    value: '01115',
  },
  {
    label: 'GHI/Emblem-BO',
    value: 'BO00040',
  },
  {
    label: 'Gilsbar',
    value: '00430',
  },
  {
    label: 'GLOBAL CARE MEDICAL GROUP',
    value: 'BO10090',
  },
  {
    label: 'Globe Life & Accident Ins.Co.',
    value: '01117',
  },
  {
    label: 'GOLDEN EMPIRE MANAGED CARE',
    value: 'BO10064',
  },
  {
    label: 'Golden Rule Insurance',
    value: '00647',
  },
  {
    label: 'Government Employees Health Association (GEHA) Dental',
    value: 'DE0017',
  },
  {
    label: 'Government Employees Hospital Association (GEHA)',
    value: '00101',
  },
  {
    label: 'Government Employees Hospital Association (GEHA) Dental',
    value: 'DE0019',
  },
  {
    label: 'Government Employees Hospital Association Dental',
    value: 'DE0018',
  },
  {
    label: 'Great American Life Assurance Company Medicare Supplement',
    value: '00369',
  },
  {
    label: 'Great American Life Insurance Company Medicare Supplement',
    value: '00370',
  },
  {
    label: 'Great West Healthcare',
    value: '00102',
  },
  {
    label: 'Great-West Healthcare Dental',
    value: 'DE0020',
  },
  {
    label: 'Greek Catholic Union of the USA',
    value: '01118',
  },
  {
    label: 'GROUP & PENSION ADMINISTRATORS',
    value: '00264',
  },
  {
    label: 'Group Dental Services',
    value: 'DE0121',
  },
  {
    label: 'Group Health Co-Operative',
    value: '00486',
  },
  {
    label: 'Group Health Northwest',
    value: '01119',
  },
  {
    label: 'Group Health Plan (GHP)',
    value: '00103',
  },
  {
    label: 'Group Hospitalization and Medical Services',
    value: '01120',
  },
  {
    label: 'Group Practice Affiliates',
    value: '00371',
  },
  {
    label: 'Group Resources-BO',
    value: 'BO00041',
  },
  {
    label: 'Guardian Individual Plan Dental',
    value: 'DE0021',
  },
  {
    label: 'Guardian Life Insurance Company of America Dental',
    value: 'DE0022',
  },
  {
    label: 'Guardian-BO',
    value: 'BO00042',
  },
  {
    label: 'HANKOOK IPA',
    value: 'BO10029',
  },
  {
    label: 'Harken Health',
    value: '00909',
  },
  {
    label: 'Harmony Health Plan (WellCare of Florida)',
    value: '00372',
  },
  {
    label: 'HARTFORD INSURANCE CO',
    value: 'BO10057',
  },
  {
    label: 'Harvard Pilgrim Health Care',
    value: '00104',
  },
  {
    label: 'Hawaii Medicaid',
    value: '00232',
  },
  {
    label: 'Hawaii Medical Assurance Assoc (HMAA)',
    value: '00600',
  },
  {
    label: 'Hawaii Medical Service Assoc (HMSA)',
    value: '00484',
  },
  {
    label: 'HCC MEDICAL INSURANCE SERVICES',
    value: 'BO10069',
  },
  {
    label: 'Health Alliance Medical Plan (HAP)',
    value: '00105',
  },
  {
    label: 'Health Alliance Medical Plan (IL)',
    value: '00648',
  },
  {
    label: 'Health Alliance-BO',
    value: 'BO00093',
  },
  {
    label: 'Health America Advantra-BO',
    value: 'BO00065',
  },
  {
    label: 'Health America Of Pennsylvania',
    value: '000901',
  },
  {
    label: 'Health America Of Pennsylvania-BO',
    value: 'BO00058',
  },
  {
    label: 'HEALTH CARE LA IPA',
    value: 'BO10006',
  },
  {
    label: 'Health Choice Arizona',
    value: '00233',
  },
  {
    label: 'Health Choice of Arizona Dental',
    value: 'DE0023',
  },
  {
    label: 'Health Choice Oklahoma',
    value: '00649',
  },
  {
    label: 'Health Cost Solutions',
    value: '01121',
  },
  {
    label: 'HEALTH FIRST HEALTH PLAN',
    value: '000924',
  },
  {
    label: 'HEALTH FIRST INSURANCE',
    value: '000925',
  },
  {
    label: 'Health Net Medicare Advantage',
    value: '01369',
  },
  {
    label: 'Health Net National',
    value: '00005',
  },
  {
    label: 'Health New England Eligibility Standard',
    value: '00601',
  },
  {
    label: 'HEALTH ONE',
    value: 'BO10080',
  },
  {
    label: 'Health Partners (Minnesota)',
    value: '00317',
  },
  {
    label: 'Health Partners of Philadelphia',
    value: '00106',
  },
  {
    label: 'Health Plan of Nevada / Sierra Health Dental',
    value: 'DE0024',
  },
  {
    label: 'Health Plan of San Mateo',
    value: '00432',
  },
  {
    label: 'Health Plan of Upper Ohio Valley',
    value: '01124',
  },
  {
    label: 'Health Plans Inc.-BO',
    value: 'BO00084',
  },
  {
    label: 'HEALTH SERVICES CHILDREN SPECIAL NEEDS',
    value: '01125',
  },
  {
    label: 'Health Share / Care Oregon',
    value: '01126',
  },
  {
    label: 'HealthAmerica And HealthAssurance',
    value: '00107',
  },
  {
    label: 'HEALTHCARE HIGHWAYS',
    value: '01370',
  },
  {
    label: 'HealthCare Inc. (Promina)',
    value: '00108',
  },
  {
    label: 'HEALTHCARE MANAGEMENT ADMIN',
    value: '000955',
  },
  {
    label: 'Healthcare Management Administrators',
    value: '01127',
  },
  {
    label: 'Healthcare Partners-BO',
    value: 'BO00043',
  },
  {
    label: 'HEALTHCARE PARTNERS/HERITAGE',
    value: 'BO10070',
  },
  {
    label: 'HEALTHCARE PARTNERS/HERITAGE-BO',
    value: 'BO00054',
  },
  {
    label: 'HEALTHCHOICE',
    value: '01367',
  },
  {
    label: 'HEALTHCHOICE SELECT',
    value: '01368',
  },
  {
    label: 'HEALTHCOMP',
    value: 'BO10044',
  },
  {
    label: 'HealthComp Administrators',
    value: '01128',
  },
  {
    label: 'HEALTHe Exchange',
    value: '00373',
  },
  {
    label: 'HealthEase (WellCare of Florida)',
    value: '00374',
  },
  {
    label: 'HealthEase Kids (WellCare of Florida)',
    value: '00375',
  },
  {
    label: 'HEALTHEDGE ADMINISTRATORS',
    value: 'BO10051',
  },
  {
    label: 'HealthEZ',
    value: '01129',
  },
  {
    label: 'Healthfirst New York',
    value: '00110',
  },
  {
    label: 'Healthfirst of New Jersey',
    value: '00234',
  },
  {
    label: 'Healthgram (Primary PhysiciansCare)',
    value: '01130',
  },
  {
    label: 'HealthMarkets: Mid West National Life Insurance',
    value: '00138',
  },
  {
    label: 'Healthnow',
    value: '00326',
  },
  {
    label: 'HealthPartners MN Dental',
    value: 'DE0025',
  },
  {
    label: 'HealthPlan of Nevada',
    value: '00651',
  },
  {
    label: 'HEALTHPLANS INC',
    value: '000957',
  },
  {
    label: 'Healthplex Inc. Dental',
    value: 'DE0026',
  },
  {
    label: 'HealthPlus of Michigan',
    value: '00111',
  },
  {
    label: 'HealthPlus-BO',
    value: 'BO00044',
  },
  {
    label: 'HealthScope',
    value: '00652',
  },
  {
    label: 'HealthSmart Benefit Solutions',
    value: '01132',
  },
  {
    label: 'HealthSpring',
    value: '00342',
  },
  {
    label: 'HealthTeam Advantage',
    value: '01134',
  },
  {
    label: 'HEALTHY BLUE LOUISIANA',
    value: '01375',
  },
  {
    label: 'Heartland National Life Ins Co',
    value: '00684',
  },
  {
    label: 'Heritage Physician Network (Houston)',
    value: '01135',
  },
  {
    label: 'Highmark Senior Solutions',
    value: '01136',
  },
  {
    label: 'HIP NEW YORK',
    value: '00965',
  },
  {
    label: 'HIPNY',
    value: '00235',
  },
  {
    label: 'HM Care Advantage Admin by KBA',
    value: '01138',
  },
  {
    label: 'HMA (Hawaii Mainland Administrators)',
    value: '01139',
  },
  {
    label: 'Hometown Health',
    value: '00433',
  },
  {
    label: 'HOP-BO',
    value: 'BO00060',
  },
  {
    label: 'Horizon Healthcare Dental Services',
    value: 'DE0128',
  },
  {
    label: 'Horizon New Jersey Health',
    value: '00297',
  },
  {
    label: 'HSA Health Plan',
    value: 'BO00120',
  },
  {
    label: 'HSBS Oklahoma City',
    value: '01140',
  },
  {
    label: 'Hudson Health Plan',
    value: '01141',
  },
  {
    label: 'Humana',
    value: '00112',
  },
  {
    label: 'Humana CareSource (KY)',
    value: '01142',
  },
  {
    label: 'Humana Dental',
    value: 'DE0027',
  },
  {
    label: 'HUMANA LONG TERM CARE',
    value: '01327',
  },
  {
    label: 'IBEW Local 508 Health Plan',
    value: '00678',
  },
  {
    label: 'iCare (Independent Care Health Plan)',
    value: '01143',
  },
  {
    label: 'Idaho Medicaid',
    value: '00113',
  },
  {
    label: 'Idaho Medicaid Dental',
    value: 'DE0082',
  },
  {
    label: 'Ideal Life Insurance',
    value: '01144',
  },
  {
    label: 'IEHP Direct Health Plan-BO',
    value: 'BO00092',
  },
  {
    label: 'Illinicare Health Plan',
    value: '00690',
  },
  {
    label: 'Illinois Medicaid',
    value: '00114',
  },
  {
    label: 'Illinois Medicaid Dental',
    value: 'DE0083',
  },
  {
    label: 'IMG',
    value: 'BO10071',
  },
  {
    label: 'IMMIGRATION HEALTH SERVICES',
    value: 'BO10022',
  },
  {
    label: 'IMPERIAL HEALTH',
    value: 'BO10039',
  },
  {
    label: 'Independence Administrators',
    value: '00435',
  },
  {
    label: 'Independence Blue Cross (Pennsylvania)',
    value: '00115',
  },
  {
    label: 'Independent Health',
    value: '00436',
  },
  {
    label: 'Indiana Medicaid',
    value: '00116',
  },
  {
    label: 'Indiana Medicaid Dental',
    value: 'DE0084',
  },
  {
    label: 'Indiana University Health Plan',
    value: '000916',
  },
  {
    label: 'Inland Empire Health',
    value: '00236',
  },
  {
    label: 'Innovante-BO',
    value: 'BO013',
  },
  {
    label: 'Insurers Administrative Corporation (IAC)',
    value: '01146',
  },
  {
    label: 'INTEGRA Administrative Group',
    value: '01147',
  },
  {
    label: 'Integrated Mental Health Services',
    value: '00376',
  },
  {
    label: 'International Services-BO',
    value: 'BO00045',
  },
  {
    label: 'InterValley Health Plan',
    value: '00117',
  },
  {
    label: 'Iowa Medicaid',
    value: '00118',
  },
  {
    label: 'Iowa Medicaid Dental',
    value: 'DE0085',
  },
  {
    label: 'IOWA TOTAL CARE',
    value: '01374',
  },
  {
    label: 'IPM TRUST FUND',
    value: 'BO10052',
  },
  {
    label: 'Island Home Insurance',
    value: '01150',
  },
  {
    label: 'IU Health Plans',
    value: 'BO000321',
  },
  {
    label: 'Jackson Memorial Hospital Health Plan',
    value: '00237',
  },
  {
    label: 'Jai Medical Systems',
    value: '01151',
  },
  {
    label: 'John Hopkins Health Plan',
    value: '00437',
  },
  {
    label: 'John Hopkins US Family Health Plan',
    value: '01409',
  },
  {
    label: 'Johns Hopkins HealthCare LLC',
    value: '01152',
  },
  {
    label: 'Kaiser Foundation Health Plan of Colorado',
    value: '00121',
  },
  {
    label: 'Kaiser Foundation Health Plan of Hawaii',
    value: '00122',
  },
  {
    label: 'Kaiser Foundation Health Plan of Hawaii (DHMO Choice)',
    value: '01153',
  },
  {
    label: 'Kaiser Foundation Health Plan of the Mid-Atlantic',
    value: '00124',
  },
  {
    label: 'Kaiser Foundation Health Plan of the Northwest',
    value: '00125',
  },
  {
    label: 'KAISER FOUNDATION HEALTH PLAN WASHINGTON',
    value: '01340',
  },
  {
    label: 'KAISER FOUNDATION HEALTH PLAN WASHINGTON OPTIONS',
    value: '01341',
  },
  {
    label: 'Kaiser NW Dental',
    value: 'DE0028',
  },
  {
    label: 'Kaiser Permanente of Georgia',
    value: '00126',
  },
  {
    label: 'Kaiser Permanente of Northern California',
    value: '00127',
  },
  {
    label: 'Kaiser Permanente of Southern California',
    value: '00488',
  },
  {
    label: 'Kansas Medicaid',
    value: '00128',
  },
  {
    label: 'Kansas Medicaid Dental',
    value: 'DE0086',
  },
  {
    label: 'Katy Medical Group',
    value: '01156',
  },
  {
    label: 'KEENAN & ASSOCIATES BO',
    value: 'BO10025',
  },
  {
    label: 'Kemper Benefits',
    value: '01157',
  },
  {
    label: 'Kentucky Medicaid',
    value: '00129',
  },
  {
    label: 'Kentucky Medicaid Dental',
    value: 'DE0087',
  },
  {
    label: 'KERN HEALTH SYSTEMS BO',
    value: 'BO10003',
  },
  {
    label: 'Key Benefit Administrators (KBA)',
    value: '00654',
  },
  {
    label: 'Key Benefits Administrators (IN)',
    value: '01158',
  },
  {
    label: 'Key Healthy Partners',
    value: '01159',
  },
  {
    label: 'KeySolution',
    value: '01160',
  },
  {
    label: 'Keystone First Community Health Plan',
    value: '01404',
  },
  {
    label: 'Keystone Mercy Health Plan',
    value: '00377',
  },
  {
    label: 'Keystone VIP Choice',
    value: '01161',
  },
  {
    label: 'Kitsap Physician Services',
    value: '00438',
  },
  {
    label: 'KSKJ Life',
    value: '01162',
  },
  {
    label: 'LA Care Health Plan',
    value: '0009650',
  },
  {
    label: 'LAMC IPA-BO',
    value: 'BO00031',
  },
  {
    label: 'Leggett & Platt',
    value: '01163',
  },
  {
    label: 'Leon Medical Centers Health Plan',
    value: '01164',
  },
  {
    label: 'LIBERTY MUTUAL',
    value: 'BO10058',
  },
  {
    label: 'Liberty National Life Ins.Co.',
    value: '01165',
  },
  {
    label: 'LifeCare Assurance Company',
    value: '01166',
  },
  {
    label: 'Lifetime Benefit Solutions (RMSCO)',
    value: '01167',
  },
  {
    label: 'Lifewise Arizona',
    value: '01168',
  },
  {
    label: 'Lifewise Health Plan of Oregon',
    value: '00655',
  },
  {
    label: 'Lifewise Health Plan of Washington',
    value: '01169',
  },
  {
    label: 'Lifewise-BO',
    value: 'BO000993',
  },
  {
    label: 'Lincoln Financial',
    value: '01170',
  },
  {
    label: 'Lincoln Financial Group Dental',
    value: 'DE0030',
  },
  {
    label: 'Lincoln FINANCIAL INSURANCE COMPANY Dental',
    value: 'DE0029',
  },
  {
    label: 'Lincoln Heritage',
    value: '01171',
  },
  {
    label: 'LOCAL 1199',
    value: '00634',
  },
  {
    label: 'LOCAL 1199-BO',
    value: 'BO00234',
  },
  {
    label: 'Local 272 welfare Fund-BO',
    value: 'BO00046',
  },
  {
    label: 'LoneStar TPA',
    value: '01172',
  },
  {
    label: 'Loomis-BO',
    value: 'BO005',
  },
  {
    label: 'LOS ANGELES MEDICAL CENTER LAMC',
    value: 'BO10059',
  },
  {
    label: 'Louisiana Health Exchange',
    value: '00474',
  },
  {
    label: 'Louisiana Healthcare Connections',
    value: '01173',
  },
  {
    label: 'Louisiana Healthcare Connections - Centene',
    value: '10756',
  },
  {
    label: 'Louisiana Medicaid',
    value: '00130',
  },
  {
    label: 'Loyal American Life Insurance Company Medicare Supplement',
    value: '00378',
  },
  {
    label: 'Loyal Christian Benefit Association',
    value: '01174',
  },
  {
    label: 'Lumico Life Insurance',
    value: '01175',
  },
  {
    label: 'Magellan Behavioral Health',
    value: '00676',
  },
  {
    label: 'Magellan Complete Care of VA',
    value: '12260',
  },
  {
    label: 'MAGELLAN HEALTH SERVICES',
    value: '01418',
  },
  {
    label: 'Magnacare',
    value: '00879',
  },
  {
    label: 'MAGNACARE-BO',
    value: 'BO00056',
  },
  {
    label: 'MAIL HANDLERS BENEFIT PLAN',
    value: '00095',
  },
  {
    label: 'Maine Community Health Options',
    value: '00656',
  },
  {
    label: 'Maine Medicaid',
    value: '00239',
  },
  {
    label: 'Maine Medicaid Dental',
    value: 'DE0088',
  },
  {
    label: 'Managed Care of America',
    value: '01176',
  },
  {
    label: 'Managed Health Network (MHN)',
    value: '01177',
  },
  {
    label: 'Managed Health Services Indiana',
    value: '00379',
  },
  {
    label: 'Managed Health Services Wisconsin',
    value: '00380',
  },
  {
    label: 'Manhattan Life-BO',
    value: 'BO001820',
  },
  {
    label: 'MAPFRE Dental',
    value: 'DE0031',
  },
  {
    label: 'MAPFRE Life Puerto Rico',
    value: '01178',
  },
  {
    label: 'MARCH VISION CARE BO',
    value: 'BO10008',
  },
  {
    label: 'MARCH VISION EDI',
    value: '01420',
  },
  {
    label: 'Maricopa Care Advantage (Arizona)',
    value: '00298',
  },
  {
    label: 'Maricopa Health Plan (Arizona)',
    value: '00299',
  },
  {
    label: 'Maryland Medicaid',
    value: '00132',
  },
  {
    label: 'Maryland Medicaid Dental',
    value: 'DE0089',
  },
  {
    label: 'Massachusetts Medicaid',
    value: '00133',
  },
  {
    label: 'Massachusetts Medicaid Dental',
    value: 'DE0090',
  },
  {
    label: 'MASSHEALTH-BO',
    value: 'BO00082',
  },
  {
    label: 'MATRIX ABSENCE MANAGEMENT INC',
    value: 'BO10081',
  },
  {
    label: 'MBA BENEFIT ADMINISTRATORS INC (slc)',
    value: 'BO00201',
  },
  {
    label: 'McLaren Health Plan Commercial',
    value: 'BO000051',
  },
  {
    label: 'MDWise Exchange',
    value: '01180',
  },
  {
    label: 'MDWISE Medicaid Health Plans',
    value: '000923',
  },
  {
    label: 'Med-Pay Inc',
    value: '01188',
  },
  {
    label: 'MedBen',
    value: '00657',
  },
  {
    label: 'MedCost Benefit Services',
    value: '00439',
  },
  {
    label: 'Medica',
    value: '00134',
  },
  {
    label: 'Medica - Medicare Supplement',
    value: '01450',
  },
  {
    label: 'Medica Health Plans',
    value: '01182',
  },
  {
    label: 'Medica of Minnesota Dental',
    value: 'DE0033',
  },
  {
    label: 'Medica2',
    value: '01183',
  },
  {
    label: 'Medicaid of Nevada Dental',
    value: 'DE0091',
  },
  {
    label: 'Medicaid of New York (Dental Clinics Only)',
    value: 'DE0176',
  },
  {
    label: 'Medical Associates Health Plan/Health Choices',
    value: '01184',
  },
  {
    label: 'Medical Card System',
    value: '01185',
  },
  {
    label: 'Medical Card System (MCS) Dental',
    value: 'DE0034',
  },
  {
    label: 'Medical Mutual of Ohio',
    value: '00135',
  },
  {
    label: 'Medical Mutual of Ohio Dental',
    value: 'DE0035',
  },
  {
    label: 'Medicare Advantage - Pennsylvania',
    value: '01186',
  },
  {
    label: 'Medicare Part A and B',
    value: '00007',
  },
  {
    label: 'Medicare y Mucho Mas (INMEDIATA HEALTH GRP CORP)',
    value: '01187',
  },
  {
    label: 'Medico Insurance Company',
    value: '00679',
  },
  {
    label: 'MEDIGOLD HEALTH PLANS',
    value: '000959',
  },
  {
    label: 'Medsolutions-BO',
    value: 'BO00047',
  },
  {
    label: 'MedStar Family Choice',
    value: '01189',
  },
  {
    label: 'MedStar Health Plan',
    value: '00658',
  },
  {
    label: 'Mercy Care Plan (Arizona)',
    value: '00243',
  },
  {
    label: 'MercyCare Health Plan (WI)',
    value: '01190',
  },
  {
    label: 'Meridian Health Plan of Illinois',
    value: '00902',
  },
  {
    label: 'Meritain Health',
    value: '00893',
  },
  {
    label: 'Meritian-BO',
    value: 'BO00048',
  },
  {
    label: 'MESVISION BO',
    value: 'BO10013',
  },
  {
    label: 'MetLife Dental',
    value: 'DE0036',
  },
  {
    label: 'MetLife Dental Family',
    value: '00244',
  },
  {
    label: 'MetroPlus Health Plan',
    value: '00659',
  },
  {
    label: 'Metroplus-BO',
    value: 'BO00049',
  },
  {
    label: 'Metropolitan Health Plan (MHP)',
    value: '00141',
  },
  {
    label: 'MHNET Behavioral Health',
    value: '01192',
  },
  {
    label: 'Michigan Medicaid',
    value: '00245',
  },
  {
    label: 'Michigan Medicaid Dental',
    value: 'DE0092',
  },
  {
    label: 'Michigan Medicaid Pending Eligibility',
    value: '00440',
  },
  {
    label: 'Michigan MIChild',
    value: '00246',
  },
  {
    label: 'Michigan Mutual-BO',
    value: 'BO00057',
  },
  {
    label: 'Mid Atlantic Medical Services, LLC (MAMSI)',
    value: '00240',
  },
  {
    label: 'Minnesota Medicaid',
    value: '00142',
  },
  {
    label: 'Minnesota Medicaid Dental',
    value: 'DE0093',
  },
  {
    label: "Mississippi Children's Health Insurance Program",
    value: '01193',
  },
  {
    label: 'Mississippi Medicaid',
    value: '00144',
  },
  {
    label: 'Mississippi Medicaid Dental',
    value: 'DE0094',
  },
  {
    label: 'Mississippi State Employees and Teachers Health Plan',
    value: '00145',
  },
  {
    label: 'Missouri Care',
    value: '01194',
  },
  {
    label: 'Missouri Medicaid',
    value: '00146',
  },
  {
    label: 'Missouri Medicaid Dental',
    value: 'DE0095',
  },
  {
    label: 'MMSI (Mayo Health)',
    value: '00147',
  },
  {
    label: 'Moda Health',
    value: '01195',
  },
  {
    label: 'MODA Health Plan-BO',
    value: 'BO000991',
  },
  {
    label: 'Molina Healthcare of California',
    value: '00148',
  },
  {
    label: 'MOLINA HEALTHCARE OF FLORIDA',
    value: '00300',
  },
  {
    label: 'Molina Healthcare of Illinois',
    value: '00485',
  },
  {
    label: 'Molina Healthcare of Michigan',
    value: '00150',
  },
  {
    label: 'Molina Healthcare of New Mexico',
    value: '00247',
  },
  {
    label: 'Molina Healthcare of Ohio',
    value: '00151',
  },
  {
    label: 'Molina Healthcare of Puerto Rico',
    value: '01196',
  },
  {
    label: 'Molina Healthcare of South Carolina',
    value: '01197',
  },
  {
    label: 'Molina Healthcare of Texas',
    value: '00152',
  },
  {
    label: 'Molina Healthcare of Utah',
    value: '00153',
  },
  {
    label: 'Molina Healthcare of Washington',
    value: '00154',
  },
  {
    label: 'Molina Healthcare of Wisconsin',
    value: '01198',
  },
  {
    label: 'Molina of CA (Exclusive Usage) BO',
    value: 'BO00148',
  },
  {
    label: 'Montana Medicaid',
    value: '00248',
  },
  {
    label: 'Montana Medicaid - DPHHS/Health Policy Services Division Dental',
    value: 'DE0097',
  },
  {
    label: 'Montana Medicaid Dental',
    value: 'DE0096',
  },
  {
    label: 'Montefiore CMO',
    value: '01199',
  },
  {
    label: 'Monumental Life Insurance Co-BO',
    value: 'BO00064',
  },
  {
    label: 'MORECARE',
    value: '01365',
  },
  {
    label: 'Motion Picture Industries-BO',
    value: 'BO009',
  },
  {
    label: 'Mountain State',
    value: '00320',
  },
  {
    label: 'MPM10-BO',
    value: 'BO00016',
  },
  {
    label: 'Municipal Health Benefit Fund',
    value: '01200',
  },
  {
    label: 'Mutual Health Services',
    value: '01201',
  },
  {
    label: 'Mutual of Omaha',
    value: '00155',
  },
  {
    label: 'MVP Health Care (New York)',
    value: '00156',
  },
  {
    label: 'MVP Health Plan of New York Dental',
    value: 'DE0032',
  },
  {
    label: 'National Association of Letter Carriers (NALC)',
    value: '00157',
  },
  {
    label: 'National Telecommunications Cooperative Association (NTCA)',
    value: '01203',
  },
  {
    label: 'Nationwide Mutual Insurance-BO',
    value: 'BO00073',
  },
  {
    label: 'Nebraska Medicaid',
    value: '01204',
  },
  {
    label: 'Nebraska Total Care',
    value: '01205',
  },
  {
    label: 'NEHEN - Neighborhood Health Plans',
    value: '00500',
  },
  {
    label: 'Neighborhood Health Partnership',
    value: '00249',
  },
  {
    label: 'Neighborhood Health Plan RI',
    value: '00502',
  },
  {
    label: 'NETWORK HEALTH -BO-BO',
    value: 'BO00083',
  },
  {
    label: 'Network Health Insurance Corp',
    value: '009012',
  },
  {
    label: 'Network Health Plan of WI',
    value: '00466',
  },
  {
    label: 'Nevada Medicaid',
    value: '00159',
  },
  {
    label: 'Nevada Medicaid - First Health Services Corp Dental',
    value: 'DE0098',
  },
  {
    label: 'New Era Insurance Company',
    value: '000902',
  },
  {
    label: 'New Era Insurance Company -EDU',
    value: '01339',
  },
  {
    label: 'New Era Insurance Company-BO',
    value: 'BO00074',
  },
  {
    label: 'New Hampshire Medicaid',
    value: '00160',
  },
  {
    label: 'New Hampshire Medicaid Dental',
    value: 'DE0099',
  },
  {
    label: 'New Jersey Medicaid',
    value: '00161',
  },
  {
    label: 'New Jersey Medicaid Dental',
    value: 'DE0100',
  },
  {
    label: 'New Mexico Health Connections',
    value: '01207',
  },
  {
    label: 'New Mexico Medicaid',
    value: '00162',
  },
  {
    label: 'New Mexico Medicaid Dental',
    value: 'DE0101',
  },
  {
    label: 'New Reflections',
    value: 'BO00146',
  },
  {
    label: 'New York Medicaid',
    value: '00163',
  },
  {
    label: 'New York Medicaid Dental',
    value: 'DE0102',
  },
  {
    label: 'NMM01-BO',
    value: 'BO00030',
  },
  {
    label: 'North Carolina Medicaid',
    value: '00164',
  },
  {
    label: 'North Carolina Medicaid Dental',
    value: 'DE0103',
  },
  {
    label: 'North Dakota Medicaid',
    value: '00250',
  },
  {
    label: 'North Dakota Medicaid Dental',
    value: 'DE0104',
  },
  {
    label: 'Northwest Administrators',
    value: '01208',
  },
  {
    label: 'not supported yet-BO',
    value: 'BONONEDI',
  },
  {
    label: 'Nova Healthcare Administrators',
    value: '00441',
  },
  {
    label: 'NovaSys Health',
    value: '00335',
  },
  {
    label: 'NVA BO',
    value: 'BO00597',
  },
  {
    label: 'OH Dental / UHC Dental Government Programs',
    value: 'DE0122',
  },
  {
    label: 'Ohana Health Plan (WellCare of Hawaii)',
    value: '00383',
  },
  {
    label: 'Ohio Medicaid',
    value: '00165',
  },
  {
    label: 'Ohio Medicaid Dental',
    value: 'DE0105',
  },
  {
    label: 'Oklahoma Employees Group Insurance Division',
    value: '01209',
  },
  {
    label: 'Oklahoma Medicaid',
    value: '00166',
  },
  {
    label: 'Oklahoma Medicaid Dental',
    value: 'DE0106',
  },
  {
    label: 'Old Surety Life Insurance Co',
    value: '01323',
  },
  {
    label: 'OmniCare (Michigan)',
    value: '00167',
  },
  {
    label: 'ONE CARE BO',
    value: 'BO000218',
  },
  {
    label: 'OPERATING ENGINEERS LOCAL 428',
    value: '000956',
  },
  {
    label: 'Operating Engineers Local No.428',
    value: '01210',
  },
  {
    label: 'operating engineers welfare fund-BO',
    value: 'BO015',
  },
  {
    label: 'Opticare BO',
    value: 'BO10009',
  },
  {
    label: 'Optima/Sentara',
    value: '00310',
  },
  {
    label: 'ORANGE COUNTY ADVANTAGE MEDICAL GRP',
    value: 'BO10047',
  },
  {
    label: 'Orange County Fire Authority',
    value: '01211',
  },
  {
    label: 'Oregon Medicaid',
    value: '00168',
  },
  {
    label: 'Oregon Medicaid Dental',
    value: 'DE0107',
  },
  {
    label: 'Oregon’s Health CO-OP',
    value: '01212',
  },
  {
    label: 'Oscar Health EDI',
    value: '01213',
  },
  {
    label: 'OSCAR HEALTH NON-EDI BO',
    value: 'BO10055',
  },
  {
    label: 'Oxford Health Plans',
    value: '00169',
  },
  {
    label: 'PA513-BO',
    value: 'BO00015',
  },
  {
    label: 'PACIFIC COMPENSATION',
    value: 'BO10082',
  },
  {
    label: 'PACIFIC HEALTHCARE IPA',
    value: 'BO10016',
  },
  {
    label: 'PACIFIC IPA',
    value: 'BO10011',
  },
  {
    label: 'Pacific Source Health Plan',
    value: '00442',
  },
  {
    label: 'PacifiCare (PPO)',
    value: '00304',
  },
  {
    label: 'Pacificare Dental and Vision HMO',
    value: 'DE0123',
  },
  {
    label: 'Pacificare Dental and Vision PPO',
    value: 'DE0124',
  },
  {
    label: 'PacifiCare of Arizona (HMO)',
    value: '00254',
  },
  {
    label: 'PacifiCare Of California',
    value: '00008',
  },
  {
    label: 'PacifiCare of Colorado (HMO)',
    value: '00255',
  },
  {
    label: 'Pacificare of Nevada -BO',
    value: 'BO001',
  },
  {
    label: 'PacifiCare Of Oklahoma',
    value: '00170',
  },
  {
    label: 'PACIFICARE OF OREGON',
    value: '00251',
  },
  {
    label: 'PACIFICARE OF TEXAS',
    value: '00252',
  },
  {
    label: 'PACIFICARE OF WASHINGTON',
    value: '00253',
  },
  {
    label: 'PacificSource Administrators',
    value: '00977',
  },
  {
    label: 'PacificSource Community Solutions (Medicaid)',
    value: '01214',
  },
  {
    label: 'PacificSource Medicare',
    value: '01215',
  },
  {
    label: 'Pan-American Life Insurance',
    value: '00660',
  },
  {
    label: 'Panamerican Life Puerto Rico (PALIC)',
    value: '01216',
  },
  {
    label: 'PAPER-BO',
    value: 'BO00014',
  },
  {
    label: 'PARADIGM MANAGEMENT SERVICES',
    value: 'BO10065',
  },
  {
    label: 'Paramount Health',
    value: '01217',
  },
  {
    label: 'Paramount Health Care',
    value: '000921',
  },
  {
    label: 'Partners National Health Plans of NC',
    value: '00444',
  },
  {
    label: 'Partnership Health Plan of California',
    value: '00256',
  },
  {
    label: 'Passport Advantage',
    value: '00384',
  },
  {
    label: 'Passport Health Plan',
    value: '00257',
  },
  {
    label: 'Peak Pace Solution LLC BO',
    value: 'BO000216',
  },
  {
    label: 'Pekin Life Insurance',
    value: '01218',
  },
  {
    label: 'Penn Treaty Network America Ins Medicare Supp',
    value: '01219',
  },
  {
    label: 'Pennsylvania Blue Shield (Camp Hill) Dental',
    value: 'DE0065',
  },
  {
    label: 'Pennsylvania Health and Wellness',
    value: '01220',
  },
  {
    label: 'Pennsylvania Medicaid',
    value: '00171',
  },
  {
    label: 'Pennsylvania Medicaid Dental',
    value: 'DE0108',
  },
  {
    label: 'Pennsylvania Medicaid Dental',
    value: 'DE0109',
  },
  {
    label: 'People First',
    value: '01221',
  },
  {
    label: 'Peoples Health',
    value: '01222',
  },
  {
    label: 'PerformCare',
    value: '000922',
  },
  {
    label: 'Personal Insurance Administrators',
    value: '00322',
  },
  {
    label: 'PERSONALCARE',
    value: '00230',
  },
  {
    label: 'PHCS-BO',
    value: 'BO007',
  },
  {
    label: 'PHYSICIANS CARE-BO',
    value: 'BO00019',
  },
  {
    label: 'Physicians health choice-BO',
    value: 'BO00095',
  },
  {
    label: 'Physicians Health Plan Northern IN',
    value: '01223',
  },
  {
    label: 'Physicians Health Plan of Mid-Michigan',
    value: '01224',
  },
  {
    label: 'Physicians Mutual Insurance Company',
    value: '00172',
  },
  {
    label: 'Physicians Plus Insurance',
    value: '01225',
  },
  {
    label: 'PIA',
    value: 'BO10036',
  },
  {
    label: 'Piedmont',
    value: '01226',
  },
  {
    label: 'Piedmont Wellstar HealthPlans',
    value: '01227',
  },
  {
    label: 'Pinnacle Claims Management',
    value: '01228',
  },
  {
    label: 'PINNACLE CLAIMS MANAGEMENT INC BO',
    value: 'BO10023',
  },
  {
    label: 'Pinnacle Physician Management Org',
    value: '01229',
  },
  {
    label: 'PIONEER MEDICAL GROUP',
    value: 'BO10072',
  },
  {
    label: 'Pittman and Associates',
    value: '00260',
  },
  {
    label: 'Plan de Salud Hospital Menonita',
    value: '01230',
  },
  {
    label: 'Planned Administrator Inc',
    value: '01231',
  },
  {
    label: 'Prairie States',
    value: '01232',
  },
  {
    label: 'Preferred Care Partners',
    value: '01233',
  },
  {
    label: 'Preferred Health System of Kansas',
    value: '01234',
  },
  {
    label: 'PREFERRED IPA OF CA-BO',
    value: 'BO00022',
  },
  {
    label: 'Preferred IPA-BO',
    value: 'BO00032',
  },
  {
    label: 'Preferred Medicare Choice(PMC)(INMEDIATA HEALTH GRP)',
    value: '01235',
  },
  {
    label: 'PreferredOne',
    value: '00261',
  },
  {
    label: 'Premera Blue Cross Dental',
    value: 'DE0066',
  },
  {
    label: 'Premier Eye Care BO',
    value: 'BO00011',
  },
  {
    label: 'Premier Health',
    value: '00661',
  },
  {
    label: 'Prestige Health Choice',
    value: '00662',
  },
  {
    label: 'PRIMARY CARE ASSOCIATES OF CA',
    value: 'BO10020',
  },
  {
    label: 'PRIME HEALTH SERVICES',
    value: 'BO10083',
  },
  {
    label: 'PRIMECARE OF COACHELLA VALLEY',
    value: 'BO10060',
  },
  {
    label: 'PrimeWest Health',
    value: '00321',
  },
  {
    label: 'Principal Financial Group (Nippon Life)',
    value: '00174',
  },
  {
    label: 'Principal Financial Group Dental',
    value: 'DE0037',
  },
  {
    label: 'Principal Life Insurance Co. Dental',
    value: 'DE0038',
  },
  {
    label: 'Priority Health',
    value: '00324',
  },
  {
    label: 'PROFESSIONAL BENEFITS ADMIN (IL)',
    value: '00268',
  },
  {
    label: 'Prominence Health Plan (PPO & HMO)',
    value: '01237',
  },
  {
    label: 'Prominence Health Plan of Nevada (Medicare Advantage)',
    value: '01238',
  },
  {
    label: 'Prominence Health Plan of Texas (Medicare Advantage)',
    value: '01239',
  },
  {
    label: 'PROSPECT AMVI',
    value: 'BO10018',
  },
  {
    label: 'PROSPECT LACARE',
    value: 'BO10024',
  },
  {
    label: 'Providence Health Plan',
    value: '00262',
  },
  {
    label:
      'Provident American Life & Health Insurance Company Medicare Supplement',
    value: '00386',
  },
  {
    label: 'Public Employee Benefit Authority',
    value: '01240',
  },
  {
    label: 'Public Employees Health Plan (PEHP)',
    value: '00445',
  },
  {
    label: 'Public Employees Health Program - CHIP',
    value: '01241',
  },
  {
    label: 'Public Employees Health Program - Medicare',
    value: '01242',
  },
  {
    label: 'Puerto Rico Medicaid (ODSI)',
    value: '00263',
  },
  {
    label: 'Puritan Life Insurance',
    value: '01243',
  },
  {
    label: 'QualCare',
    value: '00663',
  },
  {
    label: 'Qualchoice',
    value: '00402',
  },
  {
    label: 'Quartz ASO',
    value: '01343',
  },
  {
    label: 'QuikTrip Corporation',
    value: '00387',
  },
  {
    label: 'REGAL-BO',
    value: 'BO00021',
  },
  {
    label: 'Regence Group Administration',
    value: '01244',
  },
  {
    label: 'Reliance Standard Life',
    value: '00176',
  },
  {
    label: 'Reliance Standard Life Insurance Company Dental',
    value: 'DE0039',
  },
  {
    label: 'Rhode Island Medicaid',
    value: '000960',
  },
  {
    label: 'RightCare from Scott and White Health Plan',
    value: '01246',
  },
  {
    label: 'Rocky Mountain Health Plan',
    value: '00270',
  },
  {
    label: 'Royal Neighbors of America',
    value: '01248',
  },
  {
    label: 'S and S Healthcare Strategies',
    value: '01249',
  },
  {
    label: 'S. USA Life Insurance Company, Inc.',
    value: '01250',
  },
  {
    label: 'San Francisco Health Plan - CA',
    value: '00271',
  },
  {
    label: 'San Joaquin Health Plan',
    value: '00272',
  },
  {
    label: 'Sanford Health BO-BO',
    value: 'BO001789',
  },
  {
    label: 'Sanford Health Plan',
    value: '00400',
  },
  {
    label: 'Santa Clara Family Health Plan',
    value: '01252',
  },
  {
    label: 'Santa Clara Valley Health and Hospital',
    value: '00446',
  },
  {
    label: 'SBLI USA Life Insurance Company, Inc.',
    value: '01253',
  },
  {
    label: 'SCAN HMO',
    value: '00177',
  },
  {
    label: 'SCAN HMO Pending Eligibility',
    value: 'BO00600',
  },
  {
    label: 'Schaller Anderson Aetna Better Health of New York',
    value: '01254',
  },
  {
    label: 'Schaller Anderson Aetna Better Health OH',
    value: '01255',
  },
  {
    label: 'Schaller Anderson Delaware Physicians, Inc',
    value: '01256',
  },
  {
    label: 'Schaller Anderson Maryland Physicians Care',
    value: '01257',
  },
  {
    label: 'Schaller Anderson Mercy Care',
    value: '01258',
  },
  {
    label: 'Schaller Anderson Mercy Maricopa',
    value: '01259',
  },
  {
    label: 'Schaller Anderson Missouri Care',
    value: '01260',
  },
  {
    label: 'Schaller Anderson Parkland Community Health Plan',
    value: '01261',
  },
  {
    label: 'Scott White Health Plan',
    value: '00305',
  },
  {
    label: 'Securian Dental',
    value: 'DE0040',
  },
  {
    label: 'Security Blue-BO',
    value: 'BO00068',
  },
  {
    label: 'Security Health Plan',
    value: '01262',
  },
  {
    label: 'SEDGWICK',
    value: 'BO10048',
  },
  {
    label: 'Select Health of South Carolina',
    value: '00388',
  },
  {
    label: 'Select Health Utah',
    value: '00447',
  },
  {
    label: 'Select Senior Clinic',
    value: '01263',
  },
  {
    label: 'SelectCare of Texas (Beaumont)',
    value: '01264',
  },
  {
    label: 'SelectCare of Texas (Golden Triangle)',
    value: '01265',
  },
  {
    label: 'SelectCare of Texas (Houston)',
    value: '01266',
  },
  {
    label: 'SelectCare of Texas (Integranet)',
    value: '01267',
  },
  {
    label: 'SelectCare of Texas (Kelsey-Seybold)',
    value: '01268',
  },
  {
    label: 'SelectCare of Texas (Memorial Clinical Assoc)',
    value: '01269',
  },
  {
    label: 'SelectCare of Texas (Northwest Diagnostic)',
    value: '01270',
  },
  {
    label: 'Self Pay-BO',
    value: 'BO002',
  },
  {
    label: 'Selman & Company',
    value: '01271',
  },
  {
    label: 'Senior Dimensions',
    value: '01272',
  },
  {
    label: 'Senior Health Services Center-Univ American Family Co.',
    value: '01273',
  },
  {
    label: 'Senior Whole Health',
    value: '01274',
  },
  {
    label: 'SEOUL MEDICAL GROUP',
    value: 'BO10017',
  },
  {
    label: 'Seton Health-BO',
    value: 'BO00097',
  },
  {
    label: 'Sharp Health Plan',
    value: '000777',
  },
  {
    label: 'SHEET METAL WORKERS HEALTH PLAN',
    value: 'BO10073',
  },
  {
    label: 'Shenandoah Life Insurance',
    value: '01275',
  },
  {
    label: 'Sierra Health Service',
    value: '00473',
  },
  {
    label: 'Significa Benefit Services',
    value: '00091',
  },
  {
    label: 'Silver Summit Health Plan',
    value: '01276',
  },
  {
    label: 'Simply Healthcare Plans',
    value: '00665',
  },
  {
    label: 'SisCo Benefits',
    value: '01277',
  },
  {
    label: 'Soundpath Health',
    value: '00666',
  },
  {
    label: 'Soundpath Health-BO',
    value: 'BO000996',
  },
  {
    label: 'SOUTH ATLANTIC MED GRP',
    value: 'BO10035',
  },
  {
    label: 'SOUTH ATLANTIC MED. GRP.-BO',
    value: 'BO00029',
  },
  {
    label: 'South Carolina Medicaid',
    value: '00273',
  },
  {
    label: 'South Carolina Medicaid Dental',
    value: 'DE0110',
  },
  {
    label: 'South Dakota Medicaid',
    value: '00179',
  },
  {
    label: 'Southeastern Indiana Health Organization',
    value: '01278',
  },
  {
    label: 'Southern Health Services (SHS)',
    value: '00180',
  },
  {
    label: 'SOUTHWEST MULTI-CRAFT HEALTH WELFARE TRUST FUND',
    value: 'BO00122',
  },
  {
    label: 'Southwest Service Administrator',
    value: 'BO000521',
  },
  {
    label: 'Special Agents Mutual Benefit Association',
    value: '00389',
  },
  {
    label: 'SPECTERA CLAIMS DEPARTMENT BO',
    value: 'BO10007',
  },
  {
    label: 'SPECTERA VISION EDI',
    value: '01419',
  },
  {
    label: 'Spectrum Health',
    value: '01279',
  },
  {
    label: 'SPJST Medicare Supplement',
    value: '00390',
  },
  {
    label: 'St Joseph IPA',
    value: 'BO000156',
  },
  {
    label: 'Standard Ins. Co. (OR Business) Dental',
    value: 'DE0041',
  },
  {
    label: 'Standard Insurance',
    value: '00181',
  },
  {
    label: 'Standard Insurance of New York',
    value: '00182',
  },
  {
    label: 'Standard Life and Accident Insurance Company',
    value: '00416',
  },
  {
    label: 'Standard Life Insurance Company of New York Dental',
    value: 'DE0042',
  },
  {
    label: 'STATE COMPENSATION INSURANCE FUND',
    value: 'BO10030',
  },
  {
    label: 'State Farm Health Ins-BO',
    value: 'BO00070',
  },
  {
    label: 'State Farm Ins',
    value: '00897',
  },
  {
    label: 'State Mutual (Lincoln Novation)',
    value: '001908',
  },
  {
    label: 'State Mutual Med Supp',
    value: '01280',
  },
  {
    label: 'StayWell (WellCare of Florida)',
    value: '00391',
  },
  {
    label: 'StayWell Kids (WellCare of Florida)',
    value: '00392',
  },
  {
    label: 'Sterling Life Insurance-BO',
    value: 'BO00072',
  },
  {
    label: 'Stewart C Miller and Co',
    value: '01281',
  },
  {
    label: 'STEWART MEDICAL GROUP',
    value: 'BO10084',
  },
  {
    label: 'Stirling Benefits Inc-BO',
    value: 'BO00001',
  },
  {
    label: 'Stonebridge Life Insurance Company',
    value: '009201',
  },
  {
    label: 'STTEC-Secure Horizons-BO',
    value: 'BO011',
  },
  {
    label: 'StudentResources',
    value: '00184',
  },
  {
    label: 'SummaCare Health Plan',
    value: '00448',
  },
  {
    label: 'Summit America Insurance Service',
    value: '01284',
  },
  {
    label: 'Sunflower State Health (Kansas)',
    value: '01285',
  },
  {
    label: 'Sunshine State Health Plan',
    value: '00327',
  },
  {
    label: 'Superior HealthPlan Texas',
    value: '00393',
  },
  {
    label: 'Superior Vision BO',
    value: 'BO00096',
  },
  {
    label: 'Sutter Select',
    value: '01286',
  },
  {
    label: 'Tall Tree Administrators',
    value: 'BO00142',
  },
  {
    label: 'Teachers Health Trust -BO',
    value: 'BO004',
  },
  {
    label: 'TEAMSTERS & FOOD EMPLOYERS SECURITY BO',
    value: 'BO000217',
  },
  {
    label: 'TennDent',
    value: '01287',
  },
  {
    label: 'Tennessee Medicaid (TennCare)',
    value: '00185',
  },
  {
    label: 'Tennessee Medicaid - Tenncare Dental',
    value: 'DE0111',
  },
  {
    label: 'TexanPlus (Integranet)',
    value: '01288',
  },
  {
    label: 'TexanPlus (Kelsey-Seybold)',
    value: '01289',
  },
  {
    label: 'TexanPlus - North Texas Area',
    value: '00406',
  },
  {
    label: 'TexanPlus - Southeast Texas Area',
    value: '00407',
  },
  {
    label: 'Texas Medicaid',
    value: '00186',
  },
  {
    label: 'Texas Medicaid BO-BO',
    value: 'BO00111',
  },
  {
    label: 'Texas Medicaid Dental',
    value: 'DE0112',
  },
  {
    label: 'The Loomis Company',
    value: '00667',
  },
  {
    label: 'The ULLICO Family of Companies',
    value: '01291',
  },
  {
    label: 'Three Rivers Health Plans (Unison Health Plan)',
    value: '00187',
  },
  {
    label: 'Thrivent',
    value: '00683',
  },
  {
    label: "Today's Options",
    value: '00409',
  },
  {
    label: 'Total Carolina Care',
    value: '00901',
  },
  {
    label: 'Total Health Care Commercial BO',
    value: 'BO000050',
  },
  {
    label: 'TransactRX Infusion and Specialty',
    value: '01292',
  },
  {
    label: 'TransactRX Part D',
    value: '01293',
  },
  {
    label: 'Transamerica Life Insurance (KBA)',
    value: '01294',
  },
  {
    label: 'Transamerica Life Insurance Company',
    value: '01295',
  },
  {
    label: 'TRANSAMERICA LIFE INSURANCE HEALTHMARKETS',
    value: '000953',
  },
  {
    label: 'Transamerica Premier Life Insurance Co.',
    value: '01296',
  },
  {
    label: 'TRANSWESTERN',
    value: 'BO10045',
  },
  {
    label: 'Travelers Insurance Company',
    value: 'BO10032',
  },
  {
    label: 'Travelers Insurance Company-BO',
    value: 'BO00067',
  },
  {
    label: 'TRI VALLEY MEDICAL GROUP',
    value: 'BO10028',
  },
  {
    label: 'TRIBAL HEALTH PARTNERS',
    value: 'BO10074',
  },
  {
    label: 'Tribute / SelectCare of Oklahoma',
    value: '00410',
  },
  {
    label: 'Tricare East',
    value: '00975',
  },
  {
    label: 'TRICARE for Life',
    value: '009502',
  },
  {
    label: 'TRICARE Overseas',
    value: '01298',
  },
  {
    label: 'Tricare West',
    value: '00009',
  },
  {
    label: 'Trillium Community Health Plan',
    value: '01299',
  },
  {
    label: 'Triple S Advantage',
    value: '01407',
  },
  {
    label: 'Triple-S Medicare Advantage Dental',
    value: 'DE0113',
  },
  {
    label: 'Triple-S Salud Dental',
    value: 'DE0043',
  },
  {
    label: 'TRISTAR RISK MANAGEMENT',
    value: 'BO10066',
  },
  {
    label: 'True Health New Mexico',
    value: '01379',
  },
  {
    label: 'Trustmark Dental',
    value: 'DE0044',
  },
  {
    label: 'Trustmark Insurance aka HOP',
    value: '00189',
  },
  {
    label: 'Tufts Health Plan',
    value: '00190',
  },
  {
    label: 'UAW retirees medical benefits-BO',
    value: 'BO016',
  },
  {
    label: 'Ucare of Minnesota',
    value: '00449',
  },
  {
    label: 'UHA',
    value: 'BO000049',
  },
  {
    label: 'Ultimate Health Plans',
    value: '01301',
  },
  {
    label: 'UMR - Cincinnati Dental',
    value: 'DE0045',
  },
  {
    label: 'UMR - Harrington/Columbus Dental',
    value: 'DE0046',
  },
  {
    label: 'UMR - Lexington Dental',
    value: 'DE0047',
  },
  {
    label: 'UMR - Onalaska Dental',
    value: 'DE0048',
  },
  {
    label: 'UMR - Sant Antonio Dental',
    value: 'DE0049',
  },
  {
    label: 'UMR - Wausau/UHIS Dental',
    value: 'DE0050',
  },
  {
    label: 'UMR Wausau',
    value: '00417',
  },
  {
    label: 'UMWA Health and Retirements Funds',
    value: '01302',
  },
  {
    label: 'UNDERWRITERS SAFETY & CLAIMS',
    value: '00269',
  },
  {
    label: 'Unicare',
    value: '00303',
  },
  {
    label: 'UNIFIED LIFE INSURANCE CO',
    value: 'BO10085',
  },
  {
    label: 'Union Pacific Railroad Employees Health Systems',
    value: '01303',
  },
  {
    label: 'Union Pacific Railroad Employes Health Systems',
    value: '00394',
  },
  {
    label: 'Unison Administrative Services-BO',
    value: 'BO00069',
  },
  {
    label: 'United American Ins Co',
    value: '00673',
  },
  {
    label: 'United Concordia - Fee for Service Dental',
    value: 'DE0051',
  },
  {
    label: 'United Concordia Dental Plus',
    value: 'DE0125',
  },
  {
    label: 'UNITED FOOD & COMMERCIAL WORKERS TR',
    value: 'BO10037',
  },
  {
    label: 'United Healthcare',
    value: '00192',
  },
  {
    label: 'United Healthcare - Optum Behavioral Solutions',
    value: 'UHG007',
  },
  {
    label: 'United Healthcare Community Plan Kansas',
    value: 'UHG002',
  },
  {
    label: 'United Healthcare Community Plan Tennessee',
    value: 'UHG003',
  },
  {
    label: 'United Healthcare Community Plan-BO',
    value: 'BO00075',
  },
  {
    label: 'United Healthcare Facets Detroit Community and State',
    value: 'UHG004',
  },
  {
    label: 'United Healthcare Facets Pittsburgh Community and State',
    value: 'UGH005',
  },
  {
    label: 'United Healthcare Nevada Market',
    value: 'UHG006',
  },
  {
    label: 'United Healthcare SecureHorizons',
    value: 'UHG008',
  },
  {
    label: 'United Healthcare Student Resources',
    value: 'UHG009',
  },
  {
    label: 'United Healthcare- West',
    value: 'UHG0010',
  },
  {
    label: 'United Teacher Associates Insurance Company Medicare Supplement',
    value: '00395',
  },
  {
    label: 'UnitedHealth Group - Community Plan',
    value: 'UHG001',
  },
  {
    label: 'UnitedHealthcare Community Plan TN Dental',
    value: 'DE0052',
  },
  {
    label: 'UnitedHealthcare Dental',
    value: 'DE0053',
  },
  {
    label: 'UnitedHealthcare Plan of the River Valley',
    value: '00277',
  },
  {
    label: 'UnitedHealthcare StudentResources Dental',
    value: 'DE0054',
  },
  {
    label: 'Unity Health Plans',
    value: '00450',
  },
  {
    label: 'Univera',
    value: '00451',
  },
  {
    label: 'Universal American',
    value: '01304',
  },
  {
    label: 'Universal Benefit Corp',
    value: '01305',
  },
  {
    label: 'Universal Care of CA',
    value: '00193',
  },
  {
    label: 'University Care Advantage Arizona',
    value: '01306',
  },
  {
    label: 'University Care Advantage AZ',
    value: '00668',
  },
  {
    label: 'University Family Care',
    value: '00278',
  },
  {
    label: 'University of Arizona Health Plans - UHM',
    value: '01307',
  },
  {
    label: 'UNIVERSITY OF MISSOURI',
    value: '00296',
  },
  {
    label: 'University of Utah Health Plans',
    value: '01308',
  },
  {
    label: 'University Physicians Care Advantage (Arizona)',
    value: '00301',
  },
  {
    label: 'University Physicians Healthcare Group (Arizona)',
    value: '00302',
  },
  {
    label: 'UPMC Health Plan (Tristate)',
    value: '00452',
  },
  {
    label: 'US Family Health Plan-BO',
    value: 'BO00051',
  },
  {
    label: 'USAA Life Insurance Company',
    value: '00194',
  },
  {
    label: 'UT-ID Teamsters Security Fund',
    value: 'BO00141',
  },
  {
    label: 'Utah Medicaid',
    value: '00672',
  },
  {
    label: 'Utah Pipe Trades Welfare Trust',
    value: 'BO00200',
  },
  {
    label: 'VA Fee Basis Program',
    value: '00195',
  },
  {
    label: 'VA Health Administration Center',
    value: '01309',
  },
  {
    label: 'Valley Care Program',
    value: '01310',
  },
  {
    label: 'Valley Health Plan',
    value: '01311',
  },
  {
    label: 'Valley Preferred-BO',
    value: 'BO00066',
  },
  {
    label: 'Vantage Health',
    value: '00396',
  },
  {
    label: 'VBA BO',
    value: 'BO00598',
  },
  {
    label: 'Vermont Medicaid',
    value: '00196',
  },
  {
    label: 'Vermont Medicaid Dental',
    value: 'DE0114',
  },
  {
    label: 'Veteran Administrators (VA) Healthcare',
    value: '00680',
  },
  {
    label: 'VETERANS AFFAIRS FEE BASIS PROGRAM',
    value: '00955',
  },
  {
    label: 'Village Family Practice',
    value: '01314',
  },
  {
    label: 'VILLAGE HEALTH HCP',
    value: 'BO10075',
  },
  {
    label: 'Virginia Medicaid',
    value: '00197',
  },
  {
    label: 'Virginia Medicaid Dental',
    value: 'DE0115',
  },
  {
    label: 'VISION CARE PROCESSING UNIT BO',
    value: 'BO10015',
  },
  {
    label: 'Vision Service Plan BO',
    value: 'BO10001',
  },
  {
    label: 'VIVA Health',
    value: '00336',
  },
  {
    label: 'VNS CHOICE',
    value: '00397',
  },
  {
    label: 'Vytra',
    value: '00279',
  },
  {
    label: 'Washington Medicaid',
    value: '00198',
  },
  {
    label: 'Washington Medicaid (ProviderOne)',
    value: '00453',
  },
  {
    label: 'Washington Medicaid Dental',
    value: 'DE0116',
  },
  {
    label: 'Washington National',
    value: '01315',
  },
  {
    label: 'WEA Trust',
    value: '00401',
  },
  {
    label: 'WEB-TPA, Inc.',
    value: '00398',
  },
  {
    label: 'WELL SENSE',
    value: '01335',
  },
  {
    label: 'WellCare Health Plans',
    value: '00418',
  },
  {
    label: 'WellMed-BO',
    value: 'BO012',
  },
  {
    label: 'Wellpath Select (Carolinas)',
    value: '00199',
  },
  {
    label: 'Wells Fargo Third Party Administrators, Inc. (CHIP and PEIA)',
    value: '00280',
  },
  {
    label: 'Wellsense',
    value: '01316',
  },
  {
    label: 'West Virginia Family Health Plan',
    value: '01317',
  },
  {
    label: 'West Virginia Medicaid',
    value: '00200',
  },
  {
    label: 'West Virginia Medicaid Dental',
    value: 'DE0117',
  },
  {
    label: 'Western & Southern Financial Group',
    value: '00669',
  },
  {
    label: 'WESTERN GROWERS ASSURANCE TRUST',
    value: 'BO10033',
  },
  {
    label: 'Western Health Advantage',
    value: '00281',
  },
  {
    label: 'Windsor Medicare Extra',
    value: '01318',
  },
  {
    label: 'Wisconsin Medicaid',
    value: '00201',
  },
  {
    label: 'Wisconsin Medicaid Chronic Disease Program',
    value: '00286',
  },
  {
    label: 'Wisconsin Medicaid Dental',
    value: 'DE0118',
  },
  {
    label: 'Wisconsin Medicaid Well Woman Program',
    value: '00287',
  },
  {
    label: 'World Insurance (ARIC)',
    value: '00202',
  },
  {
    label: 'WPS Health Insurance',
    value: '01320',
  },
  {
    label: 'Writers Guild',
    value: '00203',
  },
  {
    label: 'Wyoming Medicaid',
    value: '00204',
  },
  {
    label: 'Wyoming Medicaid Dental',
    value: 'DE0119',
  },
  {
    label: 'YORK RISK SERVICES GROUP',
    value: 'BO10053',
  },
  {
    label: 'YourCare Health Plan',
    value: '01321',
  },
  {
    label: 'ZENITH INSURANCE CO',
    value: 'BO10054',
  },
  {
    label: 'Zurich American Ins. Co.-BO',
    value: 'BO00071',
  },
  {
    label: 'ZURICH-BO',
    value: 'BO00055',
  },
];
