export enum IntakeType {
  medical = 'medical',
  therapy = 'therapy',
}

export enum IntakeStatus {
  completed = 'completed',
  ongoing = 'ongoing',
  notStarted = 'not-started',
}

export enum QuestionStatus {
  saved = 'answered',
  notSaved = 'not-answered',
}

export enum IntakeFormTypeEnum {
  dob = 'dob',
  radio = 'radio',
  text = 'text',
  multicheck = 'multicheck',
  address = 'address',
  medications = 'medications',
  compound = 'compound',
  tab = 'tab',
  date = 'date',
  radioOptions = 'radioOptions',
}
