import React from 'react';
import {FieldValues} from 'react-hook-form';

import {AbstractFormItem} from '../Base/AbstractFormItem';
import {getTextInputClasses, TextInputProps} from '../Base/Models/TextInput';

export function FormCheckBox<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  type = 'checkbox',
  placeholder,
  bgColor = 'white',
  inputClasses,
  ariaLabel,
  ...rest
}: TextInputProps<TFieldValues, TContext>) {
  const checkBoxSizeClass = 'w-4 h-4';
  return (
    <AbstractFormItem
      id={id}
      showLabelAfterInput={true}
      inlineLabelAndInput={true}
      {...rest}
      render={({field: {value, onChange}, fieldState: {error}}) => (
        <input
          placeholder={placeholder}
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          className={getTextInputClasses(
            error,
            `${inputClasses} ${checkBoxSizeClass}`,
            bgColor,
          )}
          aria-label={ariaLabel}
        />
      )}
    ></AbstractFormItem>
  );
}
