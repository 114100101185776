import React, {FC, Suspense, useState} from 'react';
import {Button} from 'components';
import Modal from 'components/Basic/Modal';
import {useToggle} from 'hooks';
import {ProviderCancelAppointmentFormData, SliceStatus} from 'interfaces';
import {useTranslation} from 'react-i18next';

import {cancellationSteps} from './steps';

type FormNavigationProps = {
  isFirst: boolean;
  isLast: boolean;
  form: string;
  isSubmitting: boolean;
  totalSteps: number;
  handleBackClick: () => void;
  disabled?: boolean;
};
const FormNavigation: FC<FormNavigationProps> = ({
  isFirst,
  isLast,
  disabled = false,
  handleBackClick,
  isSubmitting,
}) => {
  const {t} = useTranslation();

  return (
    <>
      {!isFirst ? (
        <Button
          type="button"
          className="px-7 py-2 rounded-lg border border-gray-400  hover:text-white hover:bg-primary"
          onClick={handleBackClick}
          textColor="text-gray-700"
          btnType="custom"
          fontWeight="semibold"
        >
          {t('go_back')}
        </Button>
      ) : null}

      <Button
        type="submit"
        form="appointment-cancellation-form"
        disabled={disabled || isSubmitting}
        className="px-7 py-2 shadow-sm text-sm"
        btnType="primary"
        fontWeight="semibold"
      >
        {isLast ? t('submit') : t('next', 'Next')}
      </Button>
    </>
  );
};

export const ProviderCancelAppointment = ({
  cancelAppointment,
  localTime,
  resetAppointmentIdToCancel,
  setShowCancelSuccessModal,
}: {
  cancelAppointment: (
    reasonforCancellation?: string,
    onSuccess?: () => void,
    onError?: () => void,
  ) => void;
  idToCancel?: string;
  resetAppointmentIdToCancel: () => void;
  localTime?: Date;
  setShowCancelSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [formState, setFormState] = useState({
    reasonForCancellation: '',
    otherReasonForCancellation: '',
  });
  const twentyFourHours = new Date().getTime() + 24 * 60 * 60 * 1000;
  const moreThan24HoursNotice = localTime!.getTime() > twentyFourHours;
  const formIdx = moreThan24HoursNotice ? 2 : 1;
  const {t} = useTranslation();

  const {toggleOn, onToggleClick} = useToggle(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [status, setStatus] = useState(SliceStatus.idle);
  const isLast = currentStep === Object.keys(cancellationSteps[formIdx]).length;
  const isFirst = currentStep === 1;
  const close = () => {
    onToggleClick();
    resetAppointmentIdToCancel();
    setCurrentStep(1);
  };

  const onSuccess = () => {
    close();
    setStatus(SliceStatus.resolved);

    setTimeout(() => {
      setShowCancelSuccessModal(true);
    }, 100);
  };

  const onError = () => {
    setStatus(SliceStatus.rejected);
  };

  const onSubmit = (values: ProviderCancelAppointmentFormData) => {
    setFormState({...formState, ...values});

    if (isLast) {
      setStatus(SliceStatus.pending);

      const reason =
        values.reasonForCancellation === 'other'
          ? values.otherReasonForCancellation
          : values.reasonForCancellation;
      cancelAppointment(reason, onSuccess, onError);
    } else setCurrentStep(prev => prev + 1);
  };

  const View = cancellationSteps[formIdx][currentStep];

  const formProps = {
    onSubmit,
    store: formState,
    formId: 'appointment-cancellation-form',
  };

  const handleBackClick = () => {
    if (!isFirst) setCurrentStep(prev => prev - 1);
  };

  const formNavigationProps: FormNavigationProps = {
    isLast,
    isFirst,
    form: 'appointment-cancellation-form',
    handleBackClick,
    totalSteps: Object.keys(cancellationSteps[formIdx]).length,
    isSubmitting: status === SliceStatus.pending,
  };

  return (
    <Suspense fallback={<div />}>
      <Modal
        showCloseIcon
        messageType="none"
        buttonFn={close}
        isOpen={toggleOn}
        infiniteScrollBehavior={false}
        title={t('cancel_appointment.title', 'Cancel Appointment?')}
        titleClasses="font-semibold px-2 text-lg block"
        floatingTitle={false}
        headerClasses="w-full h-16 px-4 flex justify-end items-center"
        containerClasses="w-full w-[445px] max-h-[80vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-2xl text-left align-middle transition-all shadow-lg"
        footer={<FormNavigation {...formNavigationProps} />}
      >
        <View {...formProps} />
      </Modal>
    </Suspense>
  );
};
