import format from 'date-fns/format';
import {
  selectAllNotifications,
  selectUnreadNotificationsCount,
} from 'features/Notification/notificationSelectors';
import {TNotification} from 'interfaces';
import {useSelector} from 'react-redux';

export function useUserNotifications(): {
  notifications: TNotification[];
  unreadNotifsLength: number;
  getNotificationsMap: () => {
    [key: string]: TNotification[];
  };
} {
  const notifications = useSelector(selectAllNotifications);
  const unreadNotifsLength = useSelector(selectUnreadNotificationsCount);

  const getNotificationsMap = () => {
    return notifications.reduce<{[key: string]: TNotification[]}>(
      (prev, curr) => {
        const dateStr = curr.createdAt
          ? format(new Date(curr.createdAt), 'yyyy/MM/dd zzzz')
          : format(new Date(), 'yyyy/MM/dd zzzz');

        if (prev[dateStr]) {
          prev[dateStr].push(curr);
        } else {
          prev[dateStr] = [curr];
        }
        return prev;
      },
      {},
    );
  };

  return {
    notifications,
    getNotificationsMap,
    unreadNotifsLength,
  };
}
