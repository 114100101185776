import React from 'react';

import {SvgIcon} from './SvgIcon';

export const GenericFile: SvgIcon = ({
  width = 26,
  height = 30,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 30"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M22,28.5H4c-1.38,0-2.5-1.12-2.5-2.5V4c0-1.38,1.12-2.5,2.5-2.5h12.2s.03,0,.05,0v6.5c0,1.52,1.23,2.75,2.75,2.75h5.5v15.25c0,1.38-1.12,2.5-2.5,2.5Zm2.15-19.25c-.08-.14-.18-.27-.28-.39l-5.8-6.52c-.1-.11-.21-.21-.32-.3v5.96c0,.69,.56,1.25,1.25,1.25h5.15ZM4,30H22c2.21,0,4-1.79,4-4V10.52c0-.98-.36-1.93-1.01-2.66L19.19,1.34c-.76-.85-1.85-1.34-2.99-1.34H4C1.79,0,0,1.79,0,4V26c0,2.21,1.79,4,4,4Z" />
  </svg>
);

export const VideoFile: SvgIcon = ({
  width = 26,
  height = 30,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 30"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M22,28.5H4c-1.38,0-2.5-1.12-2.5-2.5V4c0-1.38,1.12-2.5,2.5-2.5H22c1.38,0,2.5,1.12,2.5,2.5V26c0,1.38-1.12,2.5-2.5,2.5Zm-18,1.5H22c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4H4C1.79,0,0,1.79,0,4V26c0,2.21,1.79,4,4,4ZM10.75,10.52c0-.21,.24-.32,.4-.2l5.85,4.48c.13,.1,.13,.3,0,.4l-5.85,4.48c-.16,.13-.4,0-.4-.2V10.52Zm1.31-1.39c-1.15-.88-2.81-.06-2.81,1.39v8.95c0,1.45,1.66,2.27,2.81,1.39l5.85-4.48c.92-.7,.92-2.08,0-2.78l-5.85-4.48Z" />
  </svg>
);

export const TextFile: SvgIcon = ({
  width = 26,
  height = 30,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 30"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M4,28.5H22c1.38,0,2.5-1.12,2.5-2.5V10.75h-5.5c-1.52,0-2.75-1.23-2.75-2.75V1.5s-.03,0-.05,0H4c-1.38,0-2.5,1.12-2.5,2.5V26c0,1.38,1.12,2.5,2.5,2.5ZM17.75,2.04v5.96c0,.69,.56,1.25,1.25,1.25h5.15c-.08-.14-.18-.27-.28-.39l-5.8-6.52c-.1-.11-.21-.21-.32-.3Zm4.25,27.96H4c-2.21,0-4-1.79-4-4V4C0,1.79,1.79,0,4,0h12.2c1.14,0,2.23,.49,2.99,1.34l5.8,6.52c.65,.73,1.01,1.68,1.01,2.66v15.48c0,2.21-1.79,4-4,4ZM9.39,14.59c-.49,0-.89-.4-.89-.89s.4-.89,.89-.89h6.59c.49,0,.89,.4,.89,.89s-.4,.89-.89,.89h-2.23v7.41c0,.55-.45,1-1,1h-.13c-.55,0-1-.45-1-1v-7.41h-2.23Z" />
  </svg>
);

export const AudioFile: SvgIcon = ({
  width = 26,
  height = 30,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 30"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M24.5,26c0,1.38-1.12,2.5-2.5,2.5H4c-1.38,0-2.5-1.12-2.5-2.5V4c0-1.38,1.12-2.5,2.5-2.5H22c1.38,0,2.5,1.12,2.5,2.5V26ZM4,30H22c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4H4C1.79,0,0,1.79,0,4V26c0,2.21,1.79,4,4,4ZM13.75,9c0-.41-.34-.75-.75-.75s-.75,.34-.75,.75v11c0,.41,.34,.75,.75,.75s.75-.34,.75-.75V9Zm2.25,2.25c.41,0,.75,.34,.75,.75v5c0,.41-.34,.75-.75,.75s-.75-.34-.75-.75v-5c0-.41,.34-.75,.75-.75Zm3.75,1.75c0-.41-.34-.75-.75-.75s-.75,.34-.75,.75v3c0,.41,.34,.75,.75,.75s.75-.34,.75-.75v-3Zm-9.75-1.75c.41,0,.75,.34,.75,.75v5c0,.41-.34,.75-.75,.75s-.75-.34-.75-.75v-5c0-.41,.34-.75,.75-.75Zm-2.25-.25c0-.41-.34-.75-.75-.75s-.75,.34-.75,.75v7c0,.41,.34,.75,.75,.75s.75-.34,.75-.75v-7Z" />
  </svg>
);

export const ImageFile: SvgIcon = ({
  width = 26,
  height = 30,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 30"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M22,28.5H4c-1.38,0-2.5-1.12-2.5-2.5V4c0-1.38,1.12-2.5,2.5-2.5H22c1.38,0,2.5,1.12,2.5,2.5V26c0,1.38-1.12,2.5-2.5,2.5Zm-18,1.5H22c2.21,0,4-1.79,4-4V4c0-2.21-1.79-4-4-4H4C1.79,0,0,1.79,0,4V26c0,2.21,1.79,4,4,4Zm4.82-13.76c.1-.1,.26-.1,.35,0l4.29,4.29c.29,.29,.77,.29,1.06,0s.29-.77,0-1.06l-1.13-1.13s.05-.06,.07-.09l3.28-5.39c.1-.16,.34-.16,.43,0l4.17,7.5c.2,.36,.66,.49,1.02,.29,.36-.2,.49-.66,.29-1.02l-4.17-7.5c-.65-1.17-2.33-1.21-3.02-.06l-3.15,5.17-2.08-2.08c-.68-.68-1.79-.68-2.47,0l-4.29,4.29c-.29,.29-.29,.77,0,1.06,.29,.29,.77,.29,1.06,0l4.29-4.29Zm-2.07-7.24c0-.69,.56-1.25,1.25-1.25s1.25,.56,1.25,1.25-.56,1.25-1.25,1.25-1.25-.56-1.25-1.25Zm1.25-2.75c-1.52,0-2.75,1.23-2.75,2.75s1.23,2.75,2.75,2.75,2.75-1.23,2.75-2.75-1.23-2.75-2.75-2.75Z" />
  </svg>
);

export const ZipFile: SvgIcon = ({
  width = 26,
  height = 30,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 30"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M22,28.5c1.38,0,2.5-1.12,2.5-2.5V4c0-1.38-1.12-2.5-2.5-2.5H13.75v1.5c0,.97-.78,1.75-1.75,1.75-.14,0-.25,.11-.25,.25v1c0,.14,.11,.25,.25,.25,.97,0,1.75,.78,1.75,1.75v1c0,.97-.78,1.75-1.75,1.75-.14,0-.25,.11-.25,.25v1c0,.14,.11,.25,.25,.25,.97,0,1.75,.78,1.75,1.75v2c0,.09-.02,.17-.04,.25h.29c.97,0,1.75,.78,1.75,1.75v4c0,.97-.78,1.75-1.75,1.75h-2c-.97,0-1.75-.78-1.75-1.75v-4c0-.97,.78-1.75,1.75-1.75h.29c-.03-.08-.04-.16-.04-.25v-2c0-.14-.11-.25-.25-.25-.97,0-1.75-.78-1.75-1.75v-1c0-.97,.78-1.75,1.75-1.75,.14,0,.25-.11,.25-.25v-1c0-.14-.11-.25-.25-.25-.97,0-1.75-.78-1.75-1.75v-1c0-.97,.78-1.75,1.75-1.75,.14,0,.25-.11,.25-.25V1.5H4c-1.38,0-2.5,1.12-2.5,2.5V26c0,1.38,1.12,2.5,2.5,2.5H22Zm0,1.5H4c-2.21,0-4-1.79-4-4V4C0,1.79,1.79,0,4,0H22c2.21,0,4,1.79,4,4V26c0,2.21-1.79,4-4,4Zm-10.25-12c0-.14,.11-.25,.25-.25h2c.14,0,.25,.11,.25,.25v4c0,.14-.11,.25-.25,.25h-2c-.14,0-.25-.11-.25-.25v-4Z" />
  </svg>
);

export const ReadOnly: SvgIcon = ({
  width = 16,
  height = 16,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 6"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M5,6C2,6,0,3,0,3,0,3,2,0,5,0s5,3,5,3c0,0-2,3-5,3ZM2.03,2.26c-.3,.26-.56,.52-.75,.74,.2,.22,.45,.48,.75,.74,.79,.67,1.81,1.26,2.97,1.26s2.19-.58,2.97-1.26c.3-.26,.56-.52,.75-.74-.2-.22-.45-.48-.75-.74-.79-.67-1.81-1.26-2.97-1.26s-2.19,.58-2.97,1.26Zm2.97,2.24c.83,0,1.5-.67,1.5-1.5s-.67-1.5-1.5-1.5-1.5,.67-1.5,1.5,.67,1.5,1.5,1.5Z" />
  </svg>
);
