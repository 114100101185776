import {initialOnboardingState} from 'definitions/Onboarding';
import {
  EndPoints,
  HttpMethods,
  MemberProfile,
  OnboardingValues,
} from 'interfaces';
import {Response} from 'redaxios';
import {entries, joinArrStrings, unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';

const memberStringPropsKeysRegexp =
  /^(currentGenderIdentification|gender|drugAllergies|visADocForAnxDep|medicationsForAnxDep|purposeOfReg|suicidalThoughts|currentlySuicidal|seizure|governmentID)/;
const memberStringListPropsKeysRegexp =
  /^(allergies|existingConditions|currentMedications)/;
const memberArrayPropsKeysRegexp = /^(pregnancyInfo|diagnosis|governmentID)/;
const memberObjectPropsKeysRegexp =
  /^(anxiety|depression|shippingAddress|localPharmData)/;

function getInitialState(user: MemberProfile): OnboardingValues {
  const initialValues: OnboardingValues = initialOnboardingState;
  let key;
  let value;
  for ([key, value] of entries(user)) {
    if (memberStringPropsKeysRegexp.test(key))
      initialValues[key] = value || initialValues[key];
    if (memberStringListPropsKeysRegexp.test(key))
      initialValues[key] =
        joinArrStrings(value as string[]) || initialValues[key];
    if (memberArrayPropsKeysRegexp.test(key))
      initialValues[key] = value || initialValues[key];
    if (memberObjectPropsKeysRegexp.test(key))
      initialValues[key] = value || initialValues[key];
    if (/dateOfBirth/.test(key) && value)
      initialValues[key] = {
        year: new Date(value as Date).getFullYear(),
        month: new Date(value as Date).getMonth() + 1,
        day: new Date(value as Date).getDate(),
      };
  }
  return initialValues;
}

const saveOnboardingData = async (
  data: Partial<OnboardingValues> & {regStage: string},
): Promise<void> => {
  try {
    await requestHandler<
      {
        message: string;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.SaveOnboardingData,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const submitOnboardingData = async (
  data: Partial<OnboardingValues> | FormData,
): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<
      {
        message: MemberProfile;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.SubmitOnboardingData,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const OnboardingService = {
  getInitialState,
  saveOnboardingData,
  submitOnboardingData,
};
