import {EndPoints, HttpMethods, UserRoles} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';

const submitRatingDetails = async (data: {
  role: UserRoles;
  appointmentId: number;
  audioVideo: number;
  provider?: number;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: `/api/${data.role}/${EndPoints.AppointmentRating}` as unknown as EndPoints,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const RatingService = {
  submitRatingDetails,
};
