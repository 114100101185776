import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {MemberProfile} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, Observable, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {PaypalService} from 'services/api';
import {PaystackService} from 'services/api/Paystack';

import {digitalPracticeActions} from '../digitalPracticeSlice';

// Generate PayPal Partner Referral Link
export const generatePayPalPartnerReferralLinkEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getPayPalOnboardingReferralLink.match),
    switchMap(({payload: {callback}}) =>
      from(PaypalService.payPalOnboardingReferralLink()).pipe(
        concatMap(({message: {referralLink}}) => {
          callback(referralLink);
          return of(
            digitalPracticeActions.getPayPalOnboardingReferralLinkSuccess(),
          );
        }),
        catchError((message: string) =>
          concat(
            of(
              digitalPracticeActions.getPayPalOnboardingReferralLinkFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

// Generate PayPal Partner Referral Link Failure Message
export const generatePayPalPartnerReferralLinkFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getPayPalOnboardingReferralLinkFailure.match),
    tap(({payload: message}) => {
      Toast({type: 'error', message});
    }),
    ignoreElements(),
  );

export const updatePayPalSellerInfoEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.updatePayPalSellerInfo.match),
    mergeMap(({payload}) =>
      from(PaypalService.updateSellerInfo(payload)).pipe(
        mergeMap(({data}) => [
          userActions.setUser(data.message),
          digitalPracticeActions.updatePayPalSellerInfoSuccess(
            'PayPal Linked Successfully',
          ),
          push({
            pathname: '/provider/profile/revenue',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(digitalPracticeActions.updatePayPalSellerInfoFailure(message)),
          ),
        ),
      ),
    ),
  );

export const updatePayPalSellerInfoFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.updatePayPalSellerInfoFailure.match),
    tap(({payload: message}) => {
      Toast({type: 'error', message});
    }),
    ignoreElements(),
  );

export const updatePayPalSellerInfoSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.updatePayPalSellerInfoSuccess.match),
    tap(({payload: message}) => {
      Toast({type: 'success', message});
    }),
    ignoreElements(),
  );

export const getPayPalSellerOnboardingDetailsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getPayPalSellerOnboardingDetails.match),
    switchMap(({payload: {callback}}) =>
      from(PaypalService.getPayPalSellerOnboardingDetails()).pipe(
        mergeMap(({message}) => {
          callback(message);
          return of(
            digitalPracticeActions.getPayPalSellerOnboardingDetailsSuccess(),
          );
        }),
        catchError((message: string) =>
          concat(
            of(
              digitalPracticeActions.getPayPalSellerOnboardingDetailsFailure(
                message,
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const getPayPalSellerOnboardingDetailsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      digitalPracticeActions.getPayPalSellerOnboardingDetailsFailure.match,
    ),
    tap(({payload: message}) => {
      Toast({type: 'error', message});
    }),
    ignoreElements(),
  );

export const createPaystackSubaccountEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.createPaystackSubaccount.match),
    switchMap(({payload: {role, account}}) =>
      from(PaystackService.createPaystackSubaccount(role, account)).pipe(
        concatMap(data => {
          return of(data).pipe(
            mergeMap(() => [
              digitalPracticeActions.createPaystackSubaccountSuccess(data),
            ]),
          );
        }),
        catchError(() =>
          concat(of(digitalPracticeActions.createPaystackSubaccountFailure())),
        ),
      ),
    ),
  );

export const createPaystackSubccountFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.createPaystackSubaccountFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: 'Something went wrong, please try again.',
      });
    }),
    ignoreElements(),
  );

export const createPaystackSubccountSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.createPaystackSubaccountSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: 'Paystack account linke successfully',
      });
    }),
    ignoreElements(),
  );

export const getSouthAfricanBankListEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getSouthAfricanBankList.match),
    switchMap(({payload: {role}}) =>
      from(PaystackService.getSouthAfricanBankList(role)).pipe(
        mergeMap(({data}) => [
          digitalPracticeActions.getSouthAfricanBankListSuccess(data),
        ]),
        catchError(() =>
          concat(of(digitalPracticeActions.getSouthAfricanBankListFailure())),
        ),
      ),
    ),
  );

export const updatePaystackSubaccountEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.updatePaystackSubaccount.match),
    switchMap(({payload: {role, account}}) =>
      from(PaystackService.updatePaystackSubaccount(role, account)).pipe(
        concatMap(data => {
          return of(data).pipe(
            mergeMap(() => [
              digitalPracticeActions.updatePaystackSubaccountSuccess(data),
            ]),
          );
        }),
        catchError(() =>
          concat(of(digitalPracticeActions.updatePaystackSubaccountFailure())),
        ),
      ),
    ),
  );

export const updatePaystackSubccountFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.updatePaystackSubaccountFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: 'Something went wrong, please try again.',
      });
    }),
    ignoreElements(),
  );

export const updatePaystackSubccountSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.updatePaystackSubaccountSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: 'Paystack account updated successfully',
      });
    }),
    ignoreElements(),
  );

const waitForUserSet = (action$: Observable<AppActions>) =>
  action$.pipe(filter(userActions.setUser.match), take(1));

export const verifyPaystackTransactionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(digitalPracticeActions.verifyPaystackTransaction.match),
    concatMap(action =>
      waitForUserSet(action$).pipe(
        withLatestFrom(state$),
        mergeMap(([_, currState]) =>
          from(
            PaystackService.verifyPaystackTransaction(action.payload.reference),
          ).pipe(
            mergeMap(({data}) => [
              userActions.setUser({
                ...currState.user.current,
                ...data.message,
                paidOrTrialAccess: {
                  access: true,
                },
              } as MemberProfile),
              digitalPracticeActions.verifyPaystackTransactionSuccess(
                'Payment Card Added Successfully',
              ),
              push({
                pathname:
                  Array.isArray(data.message.paystackCards) &&
                  data.message.paystackCards.length > 1
                    ? '/payment'
                    : '/dashboard',
              }),
            ]),
            catchError((message: string) => {
              return concat(
                of(
                  digitalPracticeActions.verifyPaystackTransactionFailure(
                    message,
                  ),
                ),
              );
            }),
          ),
        ),
      ),
    ),
  );

export const verifyPaystackTransactionFailure: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.verifyPaystackTransactionFailure.match),
    tap(({payload: message}) => {
      Toast({type: 'error', message});
    }),
    ignoreElements(),
  );

export const verifyPaystackTransactionSuccess: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.verifyPaystackTransactionSuccess.match),
    tap(({payload: message}) => {
      Toast({type: 'success', message});
    }),
    ignoreElements(),
  );

export const RevenueEpics = [
  generatePayPalPartnerReferralLinkEpic,
  generatePayPalPartnerReferralLinkFailureEpic,
  updatePayPalSellerInfoEpic,
  updatePayPalSellerInfoFailureEpic,
  updatePayPalSellerInfoSuccessEpic,
  getPayPalSellerOnboardingDetailsEpic,
  getPayPalSellerOnboardingDetailsFailureEpic,
  createPaystackSubaccountEpic,
  createPaystackSubccountFailureEpic,
  createPaystackSubccountSuccessEpic,
  updatePaystackSubaccountEpic,
  updatePaystackSubccountFailureEpic,
  updatePaystackSubccountSuccessEpic,
  getSouthAfricanBankListEpic,
  verifyPaystackTransactionEpic,
  verifyPaystackTransactionFailure,
  verifyPaystackTransactionSuccess,
].flatMap(epic => epic);
