import {AppDispatch, AppThunk} from 'app/store';
import {userActions} from 'features/User';
import {AppointmentTypes, OnboardingValues, UserRoles} from 'interfaces';
import {OnboardingService} from 'services/api';

import {createAction} from '@reduxjs/toolkit';

export const saveOnboardingData =
  (
    data: Partial<OnboardingValues> & {
      regStage: string;
      setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
    },
  ): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch) => {
    const {setIsSubmitting, ...rest} = data;
    try {
      await OnboardingService.saveOnboardingData(rest);
      dispatch(userActions.checkSession(UserRoles.member));
    } catch (err) {
      // TODO: how to handle error of onboarding data, toast or show on form?
      dispatch(
        userActions.setAsyncError({filter: 'appointment', message: err}),
      );
      setIsSubmitting(false);

      return false;
    }
    setIsSubmitting(false);
    return true;
  };

export const submitOnboardingData = createAction<{
  data: Partial<OnboardingValues> | FormData;
  appointmentData?: {
    appointmentDateTimeString: string;
    providerId: string;
    appointmentType: AppointmentTypes;
    patientTimezone: string;
    calendarId: number;
    appointmentTypeID: string;
  };
}>('SubmitOnboardingData');
export const submitOnboardingDataSuccess = createAction(
  'SubmitOnboardingDataSuccess',
);
export const submitOnboardingDataFailed = createAction(
  'SubmitOnboardingDataFailed',
);
