import React, {FC, lazy, Suspense} from 'react';
import {Button, MailIcon} from 'components';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {componentLoader} from 'utils';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

export const ProviderAppointmentCancellationSuccess: FC<{
  showCancelSuccessModal: boolean;
  setShowCancelSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({showCancelSuccessModal, setShowCancelSuccessModal}) => {
  const {t} = useTranslation();

  const closeModal = () => {
    setShowCancelSuccessModal(false);
  };

  const location = useLocation();
  const history = useHistory();
  const isOnCalendarPage = location.pathname === '/provider/calendar';

  const Footer = () => {
    if (isOnCalendarPage) {
      closeModal();
    }
    return (
      <>
        <Button
          type="button"
          className="px-7 py-2 rounded-lg border border-gray-400  hover:text-white hover:bg-primary"
          onClick={() => {
            setShowCancelSuccessModal(false);
            if (!isOnCalendarPage) {
              history.push('/provider/calendar');
            }
          }}
          textColor="text-gray-700"
          btnType="custom"
        >
          {t('Go to Calendar')}
        </Button>

        <Button
          type="button"
          className="px-7 py-2 shadow-sm text-sm"
          btnType="primary"
          onClick={closeModal}
        >
          {t('Done')}
        </Button>
      </>
    );
  };

  return (
    <Suspense fallback={<div>{t('loading', 'Loading...')}</div>}>
      <Modal
        showCloseIcon
        messageType="none"
        buttonFn={closeModal}
        isOpen={showCancelSuccessModal}
        title={t('Cancel Appointment')}
        infiniteScrollBehavior={false}
        titleClasses="font-semibold px-2 text-lg block"
        floatingTitle={false}
        headerClasses="w-full h-16 px-4 flex justify-end items-center"
        containerClasses="w-full w-[445px] max-h-[80vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-2xl text-left align-middle transition-all shadow-lg"
        footer={<Footer />}
      >
        <div className="w-full flex items-center content-center justify-center gap-10">
          <div className="flex-1">
            <MailIcon />
          </div>
          <div className="flex-3">
            <p className="text-gray-700 py-3 leading-relaxed text-base">
              {t(
                'cancel_appointment.successText',
                'We sent an email to your patient about the cancellation. Make sure you have available appointments within 24-48 hours.',
              )}
            </p>
          </div>
        </div>
      </Modal>
    </Suspense>
  );
};
