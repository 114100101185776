import {isBillable} from 'features/Provider/Payments/determineBillingStatus';
import {selectUserProfile} from 'features/User';
import {
  AcuityLabelsId,
  AppointmentLabel,
  BillingHistoryTypes,
  GroupCallAppointmentLabelsIDs,
} from 'interfaces';
import {useSelector} from 'react-redux';
import {calculateHrsMins, calendarMonths} from 'utils';

function groupByDate(arr: AppointmentLabel[]) {
  const groups = arr.reduce((groups, appt) => {
    const [, month, date] = appt.date.split('T')[0].split('-');

    const key = `${calendarMonths[Number(month)]} ${Number(date)}`;
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(appt);
    return groups;
  }, {});

  return groups;
}

export function useBilling(
  _roomMode: 'normal' | 'group',
  billingHistory: BillingHistoryTypes,
) {
  const {
    appointmentData = [],
    providerModelRemark,
    forMonth,
    isInitialTherapyBillable,
  } = billingHistory;

  const user = useSelector(selectUserProfile);

  const groupedAppts = groupByDate(appointmentData);

  const accountType = (val: string) => {
    if (val.toLowerCase().trim() === 'wellnite') return 'Wellnite';
    else return 'Scale - Your Digital Practice';
  };

  const labelColors = {
    [AcuityLabelsId.canceled3hrs]: {
      background: '#f97316',
      color: '#fff',
    },
    [AcuityLabelsId.completed]: {background: '#90f7c3', color: '#000'},
    [AcuityLabelsId.dontChargeNoShow]: {
      background: '#e5e7eb',
      color: '#000',
    },
    [AcuityLabelsId.freeSession]: {
      background: '#a21caf',
      color: '#fff',
    },
    [AcuityLabelsId.innetwork]: {
      background: '#7dd3fc',
      color: '#000',
    },
    [AcuityLabelsId.mSmessup]: {
      background: '#ca8a04',
      color: '#000',
    },
    [AcuityLabelsId.noShow]: {
      background: '#000',
      color: '#fff',
    },
    [AcuityLabelsId.paperworkAppt]: {
      background: '#a21caf',
      color: '#fff',
    },
    [AcuityLabelsId.providerCancel]: {
      background: '#e5e7eb',
      color: '#000',
    },
    [AcuityLabelsId.providerFree]: {
      background: '#34d399',
      color: '#000',
    },
    [AcuityLabelsId.providerMissed]: {
      background: '#7dd3fc',
      color: '#000',
    },
    [AcuityLabelsId.rescheduled]: {
      background: '#a21caf',
      color: '#fff',
    },
    [AcuityLabelsId.techIssue]: {
      background: '#2563eb',
      color: '#fff',
    },
    [AcuityLabelsId.techIssueFree]: {
      background: '#2563eb',
      color: '#fff',
    },
    [AcuityLabelsId.dpoop]: {
      background: '#ff0000',
      color: '#000',
    },
    [AcuityLabelsId.dpoopCompleted]: {
      background: '#87cd9b',
      color: '#000',
    },
    [AcuityLabelsId.dpInNetworkCompleted]: {
      background: '#ff0000',
      color: '#fff',
    },
    [AcuityLabelsId.testAccount]: {
      background: '#0000ff',
      color: '#fff',
    },
    [AcuityLabelsId.shortDuration]: {
      background: '#0000ff',
      color: '#fff',
    },
  };

  const billingFor = () => {
    if (forMonth) return forMonth.description;
    if (appointmentData.length) {
      const [, fmonth, fdate] = appointmentData[0].date
        .split('T')[0]
        .split('-');
      const [, tmonth, tdate] = appointmentData[appointmentData.length - 1].date
        .split('T')[0]
        .split('-');

      return `${fdate} ${calendarMonths[fmonth]} - ${tdate} ${calendarMonths[tmonth]}`;
    } else return '';
  };

  const billableNormalAppts = appointmentData
    .filter(
      appt =>
        isBillable(
          appt.labels
            ? appt.labels[0]
            : {
                name: '',
                id: 0,
                color: '',
              },
          providerModelRemark!,
          appt.patientAccountType,
          appt.type,
          appt.canceled,
          appt.duration,
          user!.role,
          isInitialTherapyBillable,
        ) === 'billable',
    )
    .map(appt => appt);

  const totalAppointmentsDurationInMinutes = appointmentData.reduce(
    (prev, curr) => prev + Number(curr.duration),
    0,
  );
  const totalAppointmentsDurationInHours = Number(
    (totalAppointmentsDurationInMinutes / 60).toFixed(2),
  );
  const totalApptsDuration = calculateHrsMins(
    totalAppointmentsDurationInMinutes,
  );

  const billableApptsDuration = calculateHrsMins(
    billableNormalAppts.reduce((prev, curr) => prev + Number(curr.duration), 0),
  );

  const billableGroupAppts = appointmentData.reduce((obj, appt) => {
    const classID = appt.classID!;
    if (
      Array.isArray(appt.labels) &&
      appt.labels.some(
        label => label.id === GroupCallAppointmentLabelsIDs.joined,
      )
    ) {
      obj[classID] = obj[classID] ?? [];
      obj[classID].push(appt);
    }

    return obj;
  }, {} as Record<string, AppointmentLabel[]>);

  const normalAppointment = {
    appointmentData,
    billableAppts: billableNormalAppts,
    providerModelRemark,
    all: groupedAppts,
    totalApptsDuration,
    totalAppointmentsDurationInHours,
    billableApptsDuration,
    accountType,
    billingFor,
    labelColors,
  };

  const groupAppointment = {
    appointmentData,
    billableAppts: billableGroupAppts,
    billableParticipants: Object.keys(billableGroupAppts).length
      ? Object.keys(billableGroupAppts).reduce((p, c) => {
          return p + billableGroupAppts[c].length;
        }, 0)
      : 0,
    billingFor,
    all: groupedAppts,
  };

  return {
    normalAppointment,
    groupAppointment,
  };
}

export type NormalAppointmentType = ReturnType<
  typeof useBilling
>['normalAppointment'];
export type GroupAppointmentType = ReturnType<
  typeof useBilling
>['groupAppointment'];
