import {RootState} from 'app/rootReducer';
import {AppDispatch} from 'app/store';
import {Appointment} from 'interfaces';
import {AppointmentService} from 'services/api';

import {AsyncThunk, createAsyncThunk} from '@reduxjs/toolkit';

export const getCalendarAppointments: AsyncThunk<
  Appointment[] | undefined,
  void,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  Appointment[] | undefined,
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('appointments/getAppointments', async (_, {rejectWithValue}) => {
  try {
    const response = await AppointmentService.getAppointments();
    return response.data.message.appointments;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getProviderCalendarAppointments: AsyncThunk<
  Appointment[] | undefined,
  {yearAndMonth: string; userRole: 'therapist' | 'prescriber'},
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  Appointment[] | undefined,
  {yearAndMonth: string; userRole: 'therapist' | 'prescriber'},
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'appointments/getAppointments',
  async ({yearAndMonth, userRole}, {rejectWithValue}) => {
    try {
      const response = await AppointmentService.getProviderAppointments(
        yearAndMonth,
        userRole,
      );
      return response.data.message.appointments;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
