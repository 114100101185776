import {selectUserProfile} from 'features/User';
import {useSelector} from 'react-redux';
import {isProvider} from 'utils';

import {useGetMemberThroughParams} from './useGetMemberThroughParams';

export function useGetMemberEmailForSharedFeature() {
  const {member} = useGetMemberThroughParams();
  const user = useSelector(selectUserProfile);
  const memberEmail = isProvider(user) ? member?.email : user?.email;

  return {memberEmail};
}
