import React, {FC} from 'react';
import {Button, ChevronLeftIcon, ChevronRightIcon} from 'components';
import {CurrentUser, UserRoles} from 'interfaces';

type FormNavigationProps = {
  user: CurrentUser;
  isLast: boolean;
  isFirst: boolean;
  handleBackClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  isSubmitting?: boolean;
};

export const FormNavigationButtons: FC<FormNavigationProps> = ({
  user,
  isLast,
  isFirst,
  handleBackClick,
  isSubmitting,
}) => {
  return (
    <section className="flex items-center justify-between gap-2 mt-4 pt-4 border-t">
      {isFirst || isLast ? (
        <div />
      ) : (
        <Button
          type="button"
          className="min-w-[124px] min-h-[48px] group rounded-full px-4 py-2.5 shadow-sm text-sm flex items-center justify-center"
          btnType="primary"
          fontWeight="semibold"
          onClick={handleBackClick}
        >
          <ChevronLeftIcon
            aria-hidden="true"
            height={11}
            strokeColor="text-white group-hover:text-primary"
          />
          <span className="text-base">Previous</span>
        </Button>
      )}
      {isLast ? (
        <Button
          type="submit"
          className="min-w-[124px] min-h-[48px] group rounded-full px-4 py-2.5 shadow-sm text-sm flex items-center justify-center"
          btnType="primary"
          fontWeight="semibold"
          form="rating-form"
          isSubmitting={isSubmitting}
          loaderType="Oval"
        >
          Submit
        </Button>
      ) : (
        <Button
          type="submit"
          className="min-w-[124px] min-h-[48px] group rounded-full px-4 py-2.5 shadow-sm text-sm flex items-center justify-center"
          btnType="primary"
          fontWeight="semibold"
          form="rating-form"
          isSubmitting={isSubmitting}
          loaderType="Oval"
        >
          {user?.role === UserRoles.member ? (
            <>
              <span className="text-base">Next</span>
              <ChevronRightIcon
                aria-hidden="true"
                height={11}
                strokeColor="text-white group-hover:text-primary"
              />
            </>
          ) : (
            <span className="text-base">Submit</span>
          )}
        </Button>
      )}
    </section>
  );
};
