import {useEffect, useState} from 'react';

export function useWindowSize(): {
  width?: number | undefined;
  height?: number | undefined;
  isDesktop?: boolean;
} {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });
  const [isDesktop, setIsDesktop] = useState<boolean | undefined>();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setIsDesktop(window.innerWidth > 768);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {...windowSize, isDesktop};
}
