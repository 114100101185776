export enum PolicyTypesEnum {
  medicaid = 'medicaid',
  medicare = 'medicare',
  pos = 'POS',
}

export enum PlanCoverageStatusEnum {
  active = 'active',
  inactive = 'inactive',
}

export enum DependencyStatusEnum {
  self = 'Own coverage',
  spouse = 'Spouse',
  child = 'Children',
}

export type PverifySubscriberType = {
  Address1: string;
  Address2: string;
  City: string;
  CommunicationNumber: string;
  Date: {
    Date: string;
    Type: string;
  }[];
  DOB_R: string;
  Firstname: string;
  Gender_R: string;
  Identification: {
    Code: string;
    Type: string;
    Name: string;
  }[];

  Lastname_R: string;
  Middlename: string;
  State: string;
  Suffix: string;
  Zip: string;
  MilitaryPersonnelInfo: string;
  FullName: string;
};

export type PverifyDependentType = {
  DependentInfo: PverifySubscriberType;
  Relationship: string;
};

export type PverifyPlanCoverage = {
  Status: PlanCoverageStatusEnum;
  EffectiveDate: string;
  ExpiryDate: string;
  PlanName: string;
  PolicyType: PolicyTypesEnum;
  GroupNumber: string;
  GroupName: string;
  PlanNetworkID: string;
  PlanNetworkName: string;
  SubscriberRelationship: string;
  PlanNumber: string;
  HRAorHSALimitationsRemaining: string;
  LastUpdatedDateOfEDI: string;
};

type InNetOutNetTypes = {
  Value: string;
  Notes: string;
};

export type MentalHealthSummaryType = {
  TherapyVisitsLimitationsInNet: InNetOutNetTypes;
  TherapyVisitsLimitationsOutNet: InNetOutNetTypes;
  TherapyVisitsRemainingInNet: InNetOutNetTypes;
  TherapyVisitsRemainingOutNet: InNetOutNetTypes;
  TherapyAmountLimitationsInNet: InNetOutNetTypes;
  TherapyAmountLimitationsOutNet: InNetOutNetTypes;
  TherapyAmountRemainingInNet: InNetOutNetTypes;
  TherapyAmountRemainingOutNet: InNetOutNetTypes;
  ServiceCoveredInNet: string;
  ServiceCoveredOutNet: string;
  CoPayInNet: InNetOutNetTypes;
  CoInsInNet: InNetOutNetTypes;
  CoPayOutNet: InNetOutNetTypes;
  CoInsOutNet: InNetOutNetTypes;
  UMOName: string;
  UMOTelephone: string;
  InNetServiceAuthorizationInfo: string;
  OutNetServiceAuthorizationInfo: string;
  IndividualDeductibleInNet: InNetOutNetTypes;
  IndividualDeductibleOutNet: InNetOutNetTypes;
  IndividualDeductibleRemainingInNet: InNetOutNetTypes;
  IndividualDeductibleRemainingOutNet: InNetOutNetTypes;
  FamilyDeductibleInNet: InNetOutNetTypes;
  FamilyDeductibleOutNet: InNetOutNetTypes;
  FamilyDeductibleRemainingInNet: InNetOutNetTypes;
  FamilyDeductibleRemainingOutNet: InNetOutNetTypes;
  IndividualOOP_InNet: InNetOutNetTypes;
  IndividualOOP_OutNet: InNetOutNetTypes;
  IndividualOOPRemainingInNet: InNetOutNetTypes;
  IndividualOOPRemainingOutNet: InNetOutNetTypes;
  FamilyOOPInNet: InNetOutNetTypes;
  FamilyOOPOutNet: InNetOutNetTypes;
  FamilyOOPRemainingInNet: InNetOutNetTypes;
  FamilyOOPRemainingOutNet: InNetOutNetTypes;
};

export type PverifyInfoType = {
  requestId: number;
  processedWithError: boolean;
  errorCode: string;
  errorDescription: string;
  pVerifyPayerCode: string;
  payerName: string;
  verificationType: string;
  location: string;
  referenceId: string;
  resultPracticeType: string;
  subscriber: PverifySubscriberType;
  planCoverage: PverifyPlanCoverage;
  dependent: PverifyDependentType;
  mentalHealthSummary: MentalHealthSummaryType;
};
