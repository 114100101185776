import {useState} from 'react';
import {sendGreetingsSchema} from 'definitions/Yup';
import {digitalPracticeActions} from 'features/Provider/DigitalPractice/digitalPracticeSlice';
import {ColleaguesType, SliceStatus, UserRoles} from 'interfaces';
import {useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

export function useCollaboration() {
  const [showModal, setShowModal] = useState(false);
  const [selectedProvider, selectProvider] = useState<Pick<
    ColleaguesType,
    | 'providerFullName'
    | 'profession'
    | 'providerPersonalEmailId'
    | 'wellniteEmailId'
  > | null>(null);
  const [showGreeting, setShowGreeting] = useState(false);
  const [showGreetingSuccessModal, setShowGreetingSuccessModal] =
    useState(false);
  const [sendGreetingsStatus, setSendGreetingsStatus] = useState(
    SliceStatus.idle,
  );
  const isSubmitting = sendGreetingsStatus === SliceStatus.pending;

  // cash withdraw
  const [showCashWithdrawModal, setShowCashWithdrawModal] = useState(false);

  const dispatch = useDispatch();

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(sendGreetingsSchema),
  });

  const onSubmit = (value: {message: string}) => {
    if (!selectedProvider) return;

    setSendGreetingsStatus(SliceStatus.pending);

    const onSuccess = () => {
      setShowGreeting(false);
      setShowGreetingSuccessModal(true);
      setSendGreetingsStatus(SliceStatus.resolved);
      formMethods.reset({message: ''});
    };

    const onError = () => {
      setSendGreetingsStatus(SliceStatus.rejected);
    };

    const selectedProfession = selectedProvider?.profession;

    const role = (
      selectedProfession && Array.isArray(selectedProfession)
        ? selectedProfession[0]?.toLowerCase()
        : selectedProfession?.toLowerCase() || UserRoles.therapist
    ) as UserRoles;

    const data = {
      fullName: selectedProvider?.providerFullName,
      email: selectedProvider?.providerPersonalEmailId ?? '',
      secondaryEmail: selectedProvider?.wellniteEmailId ?? '',
      role,
      message: value.message,
    };

    dispatch(digitalPracticeActions.sendGreetings({data, onSuccess, onError}));
  };

  return {
    formMethods,
    onSubmit,
    showModal,
    setShowModal,
    selectedProvider,
    selectProvider,
    showGreeting,
    setShowGreeting,
    showGreetingSuccessModal,
    setShowGreetingSuccessModal,
    isSubmitting,

    showCashWithdrawModal,
    setShowCashWithdrawModal,
  };
}
