import React, {FC, useCallback, useEffect, useMemo, useRef} from 'react';
import {AppDispatch, useAppSelector} from 'app/store';
import {WithAsyncPaginateType} from 'components/Basic/Form/Select/models';
import {detachedSearchBarStyles} from 'components/Basic/Form/Select/styles';
import {DetachedSearchSelect, FormSelect} from 'components/Basic/Form/V2';
import {providerActions} from 'features/Provider';
import {
  selectAllMembers,
  selectHasMoreMembers,
  selectLastFetchedMembers,
} from 'features/Provider/Members/membersSelectors';
import {selectUserProfile} from 'features/User';
import {useBooking, useRequesting} from 'hooks';
import {MemberProfile, OptionType, SliceStatus} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {AsyncPaginate} from 'react-select-async-paginate';
import {lastValueFrom, Subject} from 'rxjs';
import {isProvider} from 'utils';

import {OrderMembersBy} from '../../../../services/api/Provider';

type Props = {
  memberPresentInState: boolean;
  onMemberSelect: (memberIdOrEmail: string) => void;
  preselectedMember?: MemberProfile;
  useEmailAsId?: boolean;
} & Partial<ReturnType<typeof useBooking>>;

const Members_Limit = 20;

export const ClientChooser: FC<Props> = ({
  control,
  memberPresentInState,
  onMemberSelect,
  preselectedMember,
  useEmailAsId,
}: Props) => {
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();
  const {searchStatus: isMembersLoading} = useAppSelector(
    state => state.provider,
  );
  const members = useSelector(selectAllMembers);
  const lastFetchedMembers = useSelector(selectLastFetchedMembers);
  const membersRef = useRef<MemberProfile[]>([]);
  membersRef.current = members;
  const dispatch = useDispatch<AppDispatch>();
  const hasMoreMembers = useSelector(selectHasMoreMembers);
  const isSearchMembersLoading = isMembersLoading === SliceStatus.pending;
  const isMemberDetailsLoading =
    useRequesting('provider') === SliceStatus.pending;

  const loading$ = useRef<
    Subject<{
      options: OptionType[];
      hasMore: boolean;
    }>
  >();

  const mapMember = (member: MemberProfile) => ({
    value: useEmailAsId ? member.email : member.patientId,
    label: member.fullName,
  });

  const clientOptions = useMemo(() => members.map(mapMember), [members]);

  const lastFetchedOptions = useMemo(
    () => lastFetchedMembers.map(mapMember),
    [lastFetchedMembers],
  );

  const fetchMoreMembers = () => {
    loading$.current = new Subject();
    fetchMembers({limit: Members_Limit + membersRef.current.length});
  };

  const fetchMembers = ({limit}: {limit: number}) => {
    dispatch(
      providerActions.getMembers({
        limit,
        role: user!.role,
        orderBy: OrderMembersBy.AlphaAz,
      }),
    );
  };

  useEffect(() => {
    if (!memberPresentInState && isProvider(user))
      fetchMembers({limit: Members_Limit});
  }, [memberPresentInState]);

  useEffect(() => {
    if (preselectedMember) {
      onMemberSelect(preselectedMember.email);
    }
  }, [preselectedMember]);

  useEffect(() => {
    if (!isSearchMembersLoading && !isMemberDetailsLoading) {
      loading$.current?.next({
        options: lastFetchedOptions,
        hasMore: hasMoreMembers,
      });

      loading$.current?.complete();
    }
  }, [clientOptions]);

  const filterMembers = (value: string) => {
    loading$.current = new Subject();
    if (value.length < 3) {
      dispatch(providerActions.resetMembersToSnapshot());
    } else {
      dispatch(
        providerActions.searchMembers({
          role: user!.role,
          searchTerm: value?.toLowerCase(),
          providerId: user?._id,
          orderBy: OrderMembersBy.AlphaAz,
        }),
      );
    }
  };

  const onLoadOptions = useCallback(
    (
      search: string,
      currentLoadedOptions: {label: string; value: string}[],
    ) => {
      const isScrolling = currentLoadedOptions.length;

      if (isScrolling) {
        fetchMoreMembers();
      } else {
        filterMembers(search);
      }

      return lastValueFrom(loading$.current!);
    },
    [],
  );

  return control ? (
    <FormSelect
      control={control}
      id="client"
      loadOptions={onLoadOptions}
      options={clientOptions}
      isPaginated={true}
      detachedSearchBar={true}
      debounceTimeout={1000}
      loadingMessage={() => t('loading') + '...'}
      classes="-mt-6"
      isMulti={false}
      isSearchable={true}
      isAsync={true}
      extractValue={true}
      styles={{
        ...detachedSearchBarStyles,
        detachedButton: (isOpen: boolean, hasValue: boolean) => {
          const styles = detachedSearchBarStyles.detachedButton!(
            isOpen,
            hasValue,
          );
          return {
            ...styles,
            borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
            color: hasValue ? 'black' : '#6B6B6B',
          };
        },
      }}
      placeholder={t('chooseClient')}
      label={null}
      onMenuClose={() => filterMembers('')}
      onChange={(newValue, setControlValue) => {
        setControlValue(newValue?.value);
        onMemberSelect(newValue?.value);
      }}
    />
  ) : (
    <DetachedSearchSelect
      id="client"
      selectComponent={AsyncPaginate as WithAsyncPaginateType}
      loadOptions={onLoadOptions}
      options={clientOptions}
      {...(preselectedMember
        ? {
            value: {
              value: useEmailAsId
                ? preselectedMember.email
                : preselectedMember.patientId,
              label: preselectedMember.fullName,
            },
          }
        : {})}
      isPaginated={true}
      debounceTimeout={1000}
      loadingMessage={() => t('loading') + '...'}
      isMulti={false}
      isSearchable={true}
      styles={{
        ...detachedSearchBarStyles,
        detachedButton: (isOpen: boolean, hasValue: boolean) => {
          const styles = detachedSearchBarStyles.detachedButton!(
            isOpen,
            hasValue,
          );
          return {
            ...styles,
            borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
            color: hasValue ? 'black' : '#6B6B6B',
          };
        },
      }}
      placeholder={t('chooseClient')}
      onMenuClose={() => filterMembers('')}
      onChange={newValue => {
        onMemberSelect(newValue!.value);
      }}
    ></DetachedSearchSelect>
  );
};
