import React from 'react';

import {SvgIcon} from './SvgIcon';

export const PasswordShowIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M255.66,112c-77.94,0-157.89,45.11-220.83,135.33a16,16,0,0,0-.27,17.77C82.92,340.8,161.8,400,255.66,400,348.5,400,429,340.62,477.45,264.75a16.14,16.14,0,0,0,0-17.47C428.89,172.28,347.8,112,255.66,112Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="256"
      cy="256"
      r="80"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PasswordHideIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M432,448a15.92,15.92,0,0,1-11.31-4.69l-352-352A16,16,0,0,1,91.31,68.69l352,352A16,16,0,0,1,432,448Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M255.66,384c-41.49,0-81.5-12.28-118.92-36.5-34.07-22-64.74-53.51-88.7-91l0-.08c19.94-28.57,41.78-52.73,65.24-72.21a2,2,0,0,0,.14-2.94L93.5,161.38a2,2,0,0,0-2.71-.12c-24.92,21-48.05,46.76-69.08,76.92a31.92,31.92,0,0,0-.64,35.54c26.41,41.33,60.4,76.14,98.28,100.65C162,402,207.9,416,255.66,416a239.13,239.13,0,0,0,75.8-12.58,2,2,0,0,0,.77-3.31l-21.58-21.58a4,4,0,0,0-3.83-1A204.8,204.8,0,0,1,255.66,384Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M490.84,238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349,110.55,302,96,255.66,96a227.34,227.34,0,0,0-74.89,12.83,2,2,0,0,0-.75,3.31l21.55,21.55a4,4,0,0,0,3.88,1A192.82,192.82,0,0,1,255.66,128c40.69,0,80.58,12.43,118.55,37,34.71,22.4,65.74,53.88,89.76,91a.13.13,0,0,1,0,.16,310.72,310.72,0,0,1-64.12,72.73,2,2,0,0,0-.15,2.95l19.9,19.89a2,2,0,0,0,2.7.13,343.49,343.49,0,0,0,68.64-78.48A32.2,32.2,0,0,0,490.84,238.6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M256,160a95.88,95.88,0,0,0-21.37,2.4,2,2,0,0,0-1,3.38L346.22,278.34a2,2,0,0,0,3.38-1A96,96,0,0,0,256,160Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M165.78,233.66a2,2,0,0,0-3.38,1,96,96,0,0,0,115,115,2,2,0,0,0,1-3.38Z"
    />
  </svg>
);

export const EmailIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <rect
      x="48"
      y="96"
      width="416"
      height="320"
      rx="40"
      ry="40"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polyline
      points="112 160 256 272 400 160"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PasswordLockIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M336,208V113a80,80,0,0,0-160,0v95"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="96"
      y="208"
      width="320"
      height="272"
      rx="48"
      ry="48"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const SecurePaymentLock: SvgIcon = ({
  width = 24,
  height = 24,
  strokeColor = 'text-black',
  strokeWidth = 2,
  fillColor = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fillColor}
    stroke="currentColor"
    strokeWidth={strokeWidth}
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`feather feather-lock ${strokeColor}`}
  >
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
  </svg>
);

export const CheckmarkIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polyline
      points="352 176 217.6 336 160 272"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WarningIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-red-500',
  strokeWidth = 1.2,
  fillColor = '#FF0033',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      fillRule="evenodd"
      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WarningTriangleIcon: SvgIcon = ({
  width = 18,
  height = 15,
  fillColor = '#FF5555',
  strokeColor = 'text-transparent',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9291 12.7592L9.43066 1.74453C9.2383 1.41849 8.76163 1.41849 8.56927 1.74453L2.07085 12.7592C1.88102 13.081 2.10907 13.5 2.50154 13.5H15.4984C15.8909 13.5 16.1189 13.081 15.9291 12.7592ZM10.7226 0.982333C9.94984 -0.327444 8.0501 -0.327444 7.27736 0.982333L0.778932 11.997C-0.00477052 13.3254 0.955841 15 2.50154 15H15.4984C17.0441 15 18.0047 13.3254 17.221 11.997L10.7226 0.982333ZM8.99997 4.25C9.41418 4.25 9.74997 4.58579 9.74997 5V8.5C9.74997 8.91421 9.41418 9.25 8.99997 9.25C8.58576 9.25 8.24997 8.91421 8.24997 8.5V5C8.24997 4.58579 8.58576 4.25 8.99997 4.25ZM9.74997 11C9.74997 10.5858 9.41418 10.25 8.99997 10.25C8.58576 10.25 8.24997 10.5858 8.24997 11V11.5C8.24997 11.9142 8.58576 12.25 8.99997 12.25C9.41418 12.25 9.74997 11.9142 9.74997 11.5V11Z"
      fill={fillColor}
    />
  </svg>
);

export const PendingCircleIcon: SvgIcon = ({
  width = 16,
  height = 17,
  fillColor = '#2E62EC',
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 8.5C14.5 12.0899 11.5899 15 8 15C4.41015 15 1.5 12.0899 1.5 8.5C1.5 4.91015 4.41015 2 8 2C11.5899 2 14.5 4.91015 14.5 8.5ZM16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM5 9.5C5.55228 9.5 6 9.05229 6 8.5C6 7.94772 5.55228 7.5 5 7.5C4.44772 7.5 4 7.94772 4 8.5C4 9.05229 4.44772 9.5 5 9.5ZM12 8.5C12 9.05229 11.5523 9.5 11 9.5C10.4477 9.5 10 9.05229 10 8.5C10 7.94772 10.4477 7.5 11 7.5C11.5523 7.5 12 7.94772 12 8.5ZM8 9.5C8.55229 9.5 9 9.05229 9 8.5C9 7.94772 8.55229 7.5 8 7.5C7.44772 7.5 7 7.94772 7 8.5C7 9.05229 7.44772 9.5 8 9.5Z"
      fill={fillColor}
    />
  </svg>
);

export const CameraIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-red-500',
  strokeWidth = 1.2,
  fillColor = '#FF0033',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <title>Camera</title>
    <path
      d="M350.54 148.68l-26.62-42.06C318.31 100.08 310.62 96 302 96h-92c-8.62 0-16.31 4.08-21.92 10.62l-26.62 42.06C155.85 155.23 148.62 160 140 160H80a32 32 0 00-32 32v192a32 32 0 0032 32h352a32 32 0 0032-32V192a32 32 0 00-32-32h-59c-8.65 0-16.85-4.77-22.46-11.32z"
      stroke="#9b9b9b"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <circle
      cx="256"
      cy="272"
      r="80"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
    />
    <path
      stroke="#9b9b9b"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="32"
      d="M124 158v-22h-24v22"
    />
  </svg>
);

export const WarningTriangle: SvgIcon = ({
  width = 20,
  height = 18,
  strokeColor = 'text-lightRed',
  strokeWidth = 0,
  fillColor = 'red',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 20 18"
    strokeWidth={strokeWidth}
    stroke="currentColor"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.07083 1.15373L0.354426 14.2133C-0.60182 15.8583 0.489306 18 2.2836 18H17.7164C19.5107 18 20.6018 15.8583 19.6456 14.2133L11.9292 1.15373C11.0349 -0.384578 8.96509 -0.384578 8.07083 1.15373ZM17.9189 15.2226L10.2073 2.17113L10.2037 2.16502L10.2001 2.15888C10.1482 2.06952 10.1001 2.03509 10.0764 2.02142C10.0493 2.00581 10.0236 2 10 2C9.97641 2 9.95068 2.00581 9.92359 2.02142C9.89986 2.03509 9.85185 2.06952 9.7999 2.15888L9.79633 2.16502L9.79272 2.17113L2.08116 15.2225C1.97397 15.4101 1.97577 15.6314 2.06962 15.8156C2.11481 15.9043 2.16721 15.9536 2.20026 15.9756C2.22537 15.9924 2.24643 16 2.2836 16H17.7164C17.7536 16 17.7746 15.9924 17.7997 15.9756C17.8328 15.9536 17.8852 15.9043 17.9304 15.8156C18.0242 15.6314 18.026 15.4101 17.9189 15.2226ZM9 6.5C9 5.94772 9.44771 5.5 10 5.5C10.5523 5.5 11 5.94772 11 6.5V10.5C11 11.0523 10.5523 11.5 10 11.5C9.44771 11.5 9 11.0523 9 10.5V6.5ZM10 12.5C9.44771 12.5 9 12.9477 9 13.5C9 14.0523 9.44771 14.5 10 14.5C10.5523 14.5 11 14.0523 11 13.5C11 12.9477 10.5523 12.5 10 12.5Z"
    />
  </svg>
);

export const FormStepProgressIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-lightRed',
  strokeWidth = 0,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 20 20"
    strokeWidth={strokeWidth}
    stroke="currentColor"
    className={`stroke-current ${strokeColor}`}
  >
    <circle cx="10" cy="10" r="9.25" stroke="#C0922A" strokeWidth="1.5" />
    <circle cx="10" cy="10" r="1" fill="#C0922A" />
    <circle cx="6" cy="10" r="1" fill="#C0922A" />
    <circle cx="14" cy="10" r="1" fill="#C0922A" />
  </svg>
);
