import React, {FC} from 'react';
import Logo from 'assets/images/logo-blue-text.png';
// import LogoWebp from 'assets/images/logo-blue-text.webp'
import Logo2x from 'assets/images/logo-blue-text@2x.png';
// import LogoWebp2x from 'assets/images/logo-blue-text@2x.webp'
import Logo3x from 'assets/images/logo-blue-text@3x.png';
// import LogoWebp3x from 'assets/images/logo-blue-text@3x.webp'
import LogoWebp4x from 'assets/images/logo-blue-text@4x.webp';
import {LazyImage} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';
import {Link} from 'react-router-dom';

const logoLqip = lqip('../../assets/images/logo-blue-text.png');

export const BlueLogoTextHeader: FC<{classes?: string}> = ({classes}) => (
  <header>
    <nav className={classes}>
      <Link to="/">
        <LazyImage
          lqip={logoLqip}
          src={Logo}
          srcSet={`${Logo}, ${Logo2x}, ${Logo3x}`}
          webp={`${LogoWebp4x}, ${LogoWebp4x}, ${LogoWebp4x}`}
          type="image/png"
          alt="Wellnite Logo links to home"
          className="w-[142px] h-[34px] py-2 mx-auto object-contain"
          aspectRatio={8.7}
        />
      </Link>
    </nav>
  </header>
);
