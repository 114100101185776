import {AirtableProviderDataTypes, InsuranceAndColleagues} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const getAirtableProviderData = async (): Promise<
  Response<{
    data: AirtableProviderDataTypes;
  }>
> => {
  try {
    const res = await requestHandler<{
      data: AirtableProviderDataTypes;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.AirtableProviderRecord,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateAirtableProviderCredential = async (
  providerInfo: AirtableProviderDataTypes,
): Promise<Response<{data: AirtableProviderDataTypes}>> => {
  try {
    const res = await requestHandler<{data: AirtableProviderDataTypes}>({
      method: HttpMethods.POST,
      url: EndPoints.AirtableProviderRecord,
      data: {
        ...providerInfo,
      },
    });
    return res;
  } catch (error) {
    return Promise.reject(unwrapAPIError(error));
  }
};

const getAirtableProviderFieldsOptions = async (): Promise<
  Response<{
    message: {[key: string]: string[]};
  }>
> => {
  try {
    const res = await requestHandler<{
      message: {[key: string]: string[]};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GetAirtableProviderFieldsOptions,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getInsuranceAndColleagues = async (): Promise<
  Response<{
    message: InsuranceAndColleagues;
  }>
> => {
  try {
    const res = await requestHandler<{
      message: InsuranceAndColleagues;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GetInsuranceAndColleagues,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const AirtableProviderService = {
  getAirtableProviderData,
  getAirtableProviderFieldsOptions,
  getInsuranceAndColleagues,
  updateAirtableProviderCredential,
};
