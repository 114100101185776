export enum BROWSER_ENUM {
  BRAVE = 'brave',
  EDGE = 'edge',
  INTERNET_EXPLORER = 'internet_explorer',
  FIRE_FOX = 'firefox',
  OPERA = 'opera',
  UC_BROWSER = 'uc_browser',
  SAMSUNG_BROWSER = 'samsung_browser',
  CHROME = 'chrome',
  SAFARI = 'safari',
  UNKNOWN = 'unknown',
}
