import {
  MemberProfile,
  PaystackPaymentCardsListResponse,
  PaystackSubaccountDetails,
  TherapistProfile,
  UserRoles,
} from 'interfaces';
import {PaystackBankData} from 'interfaces/Paystack.types';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const createPaystackSubaccount = async (
  role: UserRoles,
  account: PaystackSubaccountDetails,
): Promise<PaystackSubaccountDetails> => {
  try {
    const res = await requestHandler<{
      message: TherapistProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}/${EndPoints.CreatePaystackSubaccount}` as unknown as EndPoints,
      data: account,
    });

    return res.data.message.paystackSubaccountDetails!;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updatePaystackSubaccount = async (
  role: UserRoles,
  account: PaystackSubaccountDetails,
): Promise<PaystackSubaccountDetails> => {
  try {
    const res = await requestHandler<{
      message: {
        paystackSubaccountDetails: PaystackSubaccountDetails;
      };
    }>({
      method: HttpMethods.PUT,
      url: `/api/${role}/${EndPoints.UpdatePaystackSubaccount}` as unknown as EndPoints,
      data: account,
    });

    return res.data.message.paystackSubaccountDetails;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getSouthAfricanBankList = async (
  role: UserRoles,
): Promise<PaystackBankData> => {
  try {
    const res = await requestHandler<{
      message: PaystackBankData;
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}/${EndPoints.GetSouthAfricanBankList}` as unknown as EndPoints,
    });

    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const initiatePaystackTransaction = async (): Promise<
  Response<{message: string}>
> => {
  try {
    const res = await requestHandler<{message: string}>({
      method: HttpMethods.POST,
      url: `${EndPoints.InitiatePaystackTransaction}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const verifyPaystackTransaction = async (
  reference: string,
): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{
      message: MemberProfile;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.VerifyPaystackTransaction}?reference=${reference}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getPaystackPaymentCardsList = async (): Promise<
  Response<{
    message: PaystackPaymentCardsListResponse;
  }>
> => {
  try {
    const res = await requestHandler<{
      message: PaystackPaymentCardsListResponse;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GetPaystackPaymentCardsList,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const setPaystackDefaultPaymentCard = async (
  paymentToken: string,
): Promise<{message: {newPaystackDefaultCard: string}}> => {
  try {
    const res = await requestHandler<{
      message: {newPaystackDefaultCard: string};
    }>({
      method: HttpMethods.PUT,
      url: EndPoints.SetPaystackDefaultPaymentCard,
      data: {paymentToken},
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const deletePaymentCardOnPaystack = async (
  paymentToken: string,
): Promise<{message: string}> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.DELETE,
      url: `${EndPoints.DeleteAPaymentCardOnPaystack}/?paymentToken=${paymentToken}` as unknown as EndPoints,
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const PaystackService = {
  createPaystackSubaccount,
  updatePaystackSubaccount,
  getSouthAfricanBankList,
  initiatePaystackTransaction,
  verifyPaystackTransaction,
  getPaystackPaymentCardsList,
  setPaystackDefaultPaymentCard,
  deletePaymentCardOnPaystack,
};
