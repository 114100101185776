import React, {FC, Fragment, useState} from 'react';
import classNames from 'classnames';
import {ArrowDownIcon} from 'components/Basic/SvgIcon';
import dayjs from 'dayjs';
import {DateTimePickerContainer} from 'features/Appointment/Calendar2/CalendarContainer.styles';
import {useCalendarLocalization} from 'hooks/useCalendarLocalization';
import DayPickerInput from 'react-day-picker/DayPicker';

import {Popover, Transition} from '@headlessui/react';

export const DatePicker: FC<{
  label: string;
  value: Date;
  onChange: (value: Date) => void;
}> = ({label, value: _value, onChange}) => {
  const [value, setValue] = useState<Date>(_value || new Date());
  const {months, weekdaysLong, weekdaysShort, locale, firstDayOfWeek} =
    useCalendarLocalization();

  const onApply = () => {
    if (value) onChange(value);
  };

  const handleDayClick = (day: Date) => {
    setValue(day);
  };

  return (
    <Popover className="w-full relative">
      {({open}) => (
        <>
          <Popover.Button
            className={classNames(
              'w-full h-12 flex justify-between items-center relative rounded-lg px-3 border border-gray-400',
              {
                'border-primary shadow-outline': open,
              },
            )}
          >
            <div className="space-y-1">
              <label
                htmlFor="name"
                className="block text-left text-[11px] font-medium text-[#747474]"
              >
                {label}
              </label>
              <div className="block w-full text-gray-900 text-xs text-left font-medium">
                {dayjs(_value).format('ddd, MMM D')}
              </div>
            </div>
            <div>
              <ArrowDownIcon />
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="fixed left-1/2 z-10 mt-1 w-full max-w-xs -translate-x-1/2 transform mx-0 border shadow-lg rounded-md overflow-hidden">
              <div className="bg-white flex h-fit">
                <DateTimePickerContainer className="w-full">
                  <DayPickerInput
                    showOutsideDays
                    onDayClick={handleDayClick}
                    selectedDays={[value]}
                    disabledDays={{before: new Date()}}
                    month={value}
                    months={months}
                    weekdaysLong={weekdaysLong}
                    weekdaysShort={weekdaysShort}
                    locale={locale}
                    firstDayOfWeek={firstDayOfWeek}
                  />
                </DateTimePickerContainer>
              </div>

              <div className="w-full flex justify-start space-x-3 border-t h-[36px] px-3 text-xs bg-white">
                <Popover.Button className="py-1 hover:text-gray-600 text-gray-600">
                  Close
                </Popover.Button>
                <Popover.Button
                  className="py-1 text-blue-600 hover:text-blue-500"
                  onClick={onApply}
                >
                  Apply
                </Popover.Button>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
