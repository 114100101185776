import {FieldOptionsType, FormDataType} from './constants';

export const generateFieldOptions = (
  key: string,
  data: FormDataType,
): FieldOptionsType[] =>
  Array.isArray(data[key]) ? data[key].map(x => ({label: x, value: x})) : [];

export const generateStringArrayOptions = (
  field: FieldOptionsType[],
): string[] =>
  Array.isArray(field) ? field.map(x => x.value).filter(Boolean) : [];
