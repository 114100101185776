import React, {FC, Fragment, useEffect} from 'react';
import {useCalendar} from 'hooks';
import {useCalendarLocalization} from 'hooks/useCalendarLocalization';
import {CurrentUser, ProviderProfile, UserRoles} from 'interfaces';
import {Link} from 'react-router-dom';

import {Transition} from '@headlessui/react';

export const PaypalBanner: FC<{user: CurrentUser}> = ({user}) => {
  const {calendarAppointments} = useCalendar();
  const [bannerOpen, setBannerOpen] = React.useState<boolean>(false);
  const {locale} = useCalendarLocalization();

  const translations = () => {
    switch (locale) {
      case 'es':
        return {
          linkAccount:
            'Vincula tu cuenta de PayPal para habilitar los cargos al cliente.',
          dismiss: 'Descartar',
        };
      case 'pt':
        return {
          linkAccount:
            'Vincule sua conta do PayPal para habilitar cobranças ao cliente.',
          dismiss: 'Dispensar',
        };
      case 'en':
      default:
        return {
          linkAccount: 'Link your PayPal account to enable client charges.',
          dismiss: 'Dismiss',
        };
    }
  };

  const today = new Date();
  const fourDaysLater = new Date();
  fourDaysLater.setDate(today.getDate() + 4);

  const upcomingAppointments = calendarAppointments.filter(
    ({dateOfAppointment, patientDetails}) => {
      const appointmentDate = new Date(dateOfAppointment);
      return (
        appointmentDate >= today &&
        appointmentDate <= fourDaysLater &&
        patientDetails?.paypalCustomerId
      );
    },
  );

  useEffect(() => {
    if (
      upcomingAppointments.length &&
      user?.role === UserRoles.therapist &&
      !(user as ProviderProfile)?.paypalMerchantId
    ) {
      setBannerOpen(true);
    } else {
      setBannerOpen(false);
    }
  }, [user, calendarAppointments]);

  return (
    <Transition
      enterFrom="opacity-0"
      enterTo="opacity-100"
      show={bannerOpen}
      as={Fragment}
    >
      <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <p className="text-sm leading-6 text-white">
          <Link
            to="/provider/profile/revenue"
            onClick={() => setBannerOpen(false)}
            className="hover:underline"
          >
            {translations().linkAccount} <span aria-hidden="true">&rarr;</span>
          </Link>
        </p>
        <div className="flex flex-1 justify-end">
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            onClick={() => setBannerOpen(false)}
          >
            <span className="sr-only">{translations().dismiss}</span>
            <p className="h-5 w-5 text-white" aria-hidden="true">
              X
            </p>
          </button>
        </div>
      </div>
    </Transition>
  );
};
