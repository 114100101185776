import * as Yup from 'yup';

export const ProviderCancelAppointmentSchema = Yup.object()
  .shape({
    reasonForCancellation: Yup.string().required(
      'Please enter a reason for cancellation',
    ),
    otherReasonForCancellation: Yup.string().when('reasonForCancellation', {
      is: reasonForCancellation => reasonForCancellation === 'other',
      then: Yup.string().required(
        'Please enter the other reason for cancellation',
      ),
      otherwise: Yup.string().notRequired(),
    }),
  })
  .required();
