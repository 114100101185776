import {
  PrescriberProfile,
  ReleaseNotesPutResponse,
  ReleaseNotesType,
  TherapistProfile,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const reactivateChatFeature = createAction('reactivateChatFeature');
export const deactivateChatFeature = createAction('deactivateChatFeature');
export const reactivateChatFeatureSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('reactivateChatFeatureSuccess');
export const reactivateChatFeatureFailure = createAction<string>(
  'reactivateChatFeatureFailure',
);
export const deactivateChatFeatureSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('deactivateChatFeatureSuccess');
export const deactivateChatFeatureFailure = createAction<string>(
  'deactivateChatFeatureFailure',
);

export const getReleaseNotes = createAction('getReleaseNotes');
export const getReleaseNotesFailure = createAction<string>(
  'getReleaseNotesFailure',
);
export const getReleaseNotesSuccess = createAction<ReleaseNotesType>(
  'getReleaseNotesSuccess',
);

export const updateReleaseNotes = createAction<{releaseCode: number}>(
  'updateReleaseNotes',
);
export const updateReleaseNotesFailure = createAction(
  'updateReleaseNotesFailure',
);
export const updateReleaseNotesSuccess = createAction<ReleaseNotesPutResponse>(
  'updateReleaseNotesSuccess',
);

export const updateProfileImage = createAction<string | undefined>(
  'updateProfileImage',
);

export const setPreferredLanguage = createAction<{language: string}>(
  'setPreferredLanguage',
);
