import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {getCurrencyRateByCode} from '../currencyActions';
import {
  selectCurrencyRateByCode,
  selectCurrencyRateIsLoading,
} from '../currencySelectors';

export interface CurrencyExchangeRateProps {
  code: string;
  value?: number;
}

export function CurrencyExchangeRate({
  code,
  value,
}: CurrencyExchangeRateProps): React.ReactElement {
  const currencyRate = useSelector(selectCurrencyRateByCode(code));
  const isLoading = useSelector(selectCurrencyRateIsLoading);
  const {t} = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currencyRate) {
      dispatch(getCurrencyRateByCode({code}));
    }
  }, [currencyRate, code]);

  if (isLoading) {
    return (
      <div className="w-full flex flex-row justify-between animate-pulse">
        <div className="w-20 h-4 bg-gray-400"></div>
        <div className="w-20 h-4 bg-gray-400"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-row justify-between w-full">
      <div>
        <p className="text-base text-gray-700">1 US Dollar =</p>
        <p className="text-md text-gray-900 font-bold">
          {currencyRate?.code} {currencyRate?.value?.toFixed(2)}
        </p>
      </div>
      {currencyRate?.value ? (
        <div className="flex flex-col items-end">
          <p className="text-sm text-gray-700">
            {t('currencyExchangeRate.covertedAmountLabel', {
              code,
              defaultValue: `Converted Amount in ${code}`,
            })}
          </p>
          <p className="text-md text-gray-900 font-bold">
            {((value || 0) * currencyRate?.value).toFixed(2)}
          </p>
        </div>
      ) : null}
    </div>
  );
}
