import React, {MouseEvent} from 'react';
import {TFunction} from 'i18next';
import {
  AcuityLabelsName,
  AppointmentLabels,
  CurrentUser,
  MemberProfile,
} from 'interfaces';
import {ChargeStatus, Meeting} from 'interfaces/Meeting.types';

export type CalendarItemProps = {
  startTime: string;
  patientId?: string;
  duration?: string;
  patientFirstName?: string;
  patientLastName?: string;
  appointmentID: number;
  classID?: number;
  notes?: string;
  labels: {id: number; name: string; color: string}[] | null;
  calendar: string;
  category: string;
  user: CurrentUser;
  providerId?: string;
  patientDetails?: MemberProfile;
  meeting?: Meeting;
  appointmentChargeCurrency?: string;
  localTime: Date;
  participants?: {
    firstName: string;
    lastName: string;
    appointmentID: number;
    labels: AppointmentLabels[] | null;
  }[];
  canceled?: boolean;
  cancelAppointment(appointmentID: number): void;
  price?: number;
  shouldShowCancelButton: boolean;
  handleCancel: (event: MouseEvent) => void;
  isGroupCall: boolean;
  updateCallInterest: (event: MouseEvent) => void;
  isJoinedInGroupCall: boolean;
  loadingState: {
    interest: boolean;
    joining: boolean;
  };
  isInterestedInGroupCall: boolean;
  isGroupCallNotAllowed: boolean;
  onJoinClick: (event: MouseEvent) => void;
  chargeStatus?: ChargeStatus;
  onShowChargeModal: (event: MouseEvent) => void;
  onChargeClick: (chargeAmount?: number) => void;
  isLoading: boolean;
  showApptError: {
    show: boolean;
    message: string;
  };
  onCloseApptTooEarlyModal: () => void;
  setOnCallPricingSkip: React.Dispatch<React.SetStateAction<boolean>>;
  showChargeModal: boolean;
  setShowChargeModal: React.Dispatch<React.SetStateAction<boolean>>;
  showSuccessModal: boolean;
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  isPastEvent?: (date: Date) => boolean | undefined;
  onShowPaymentRequestModal: (event: MouseEvent) => void;
  setShowPaymentRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPaymentRequestModal: boolean;
  setShowSuccessPaymentModal: React.Dispatch<React.SetStateAction<boolean>>;
  showSuccessPaymentModal: boolean;
  videoLink?: string;
};

// dropdown currencies
export const currencies = [{value: 'usd', label: 'USD$'}];

export const labelType = (t: TFunction<'translation', undefined>) => ({
  [AcuityLabelsName.interested]: (
    <div className="bg-orange-600 px-3 py-1 rounded-full text-white">
      {t('interested', 'Interested')}
    </div>
  ),
  [AcuityLabelsName.notInterested]: (
    <div className="bg-red-600 px-3 py-1 rounded-full text-white">
      {t('notInterested', 'Not Interested')}
    </div>
  ),
  [AcuityLabelsName.joined]: (
    <div className="bg-green-600 px-3 py-1 rounded-full text-white">
      {t('joined', 'Joined')}
    </div>
  ),
});
