/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {AppStore, store} from 'app/store';
import {ShareData} from 'interfaces';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import {CometChat} from '@cometchat-pro/chat';
import * as Sentry from '@sentry/browser';

import * as serviceWorker from './serviceWorker';

import 'index.css';

import 'eventsource-polyfill';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ReviewsWidget: () => any;
    webkitAudioContext: typeof AudioContext;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cypress: any;
    store: AppStore;
    __insp: any;
    __inspld: any;
    heap: any;
  }

  interface Navigator {
    share(data?: ShareData): Promise<void>;
    // @ts-ignore
    canShare?: (data?: ShareData) => boolean;
    standalone?: boolean;
  }
}

if (window.Cypress) {
  window.store = store;
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      process.env.REACT_APP_SENTRY_API_KEY ||
      'https://10ff77969aaf4b69a5c32ca9577f4d2c@o428419.ingest.sentry.io/5373867',
    environment: process.env.NODE_ENV,
    beforeSend: (event, hint) => {
      const isNonErrorException =
        event.exception?.values?.[0]?.value?.startsWith(
          'Non-Error exception captured',
        ) ||
        (hint?.originalException as Error)?.message?.startsWith(
          'Non-Error exception captured',
        );

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        return null;
      }
      return event;
    },
    denyUrls: [
      // Random plugins/extensions
      /top\.GLOBALS/,
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      /originalCreateNotification/,
      /canvas\.contentDocument/,
      /MyApp_RemoveAllHighlights/,
      /http:\/\/tt\.epicplay\.com/,
      /Can't find variable: ZiteReader/,
      /jigsaw is not defined/,
      /ComboSearch is not defined/,
      /http:\/\/loading\.retry\.widdit\.com\//,
      /atomicFindClose/,
      // Facebook borked
      /fb_xd_fragment/,
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      /bmi_SafeAddOnload/,
      /EBCallBackMessageReceived/,
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      /conduitPage/,
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      /Script error./,
    ],
    allowUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  } as Sentry.BrowserOptions);
}
const appID = process.env.REACT_APP_COMET_CHAT_APP_ID;
const region = process.env.REACT_APP_COMET_CHAT_REGION;

const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForFriends()
  .setRegion(region)
  .autoEstablishSocketConnection(true)
  .build();

CometChat.init(appID, appSetting).then(
  () => {
    console.info('chat initialized');
  },
  error => {
    console.error('chat initialization failed with error:', error);
  },
);

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require('./app/App').default;

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  import('./scripts').then(scripts => scripts.init());
};

render();
//@ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  //@ts-ignore
  module.hot.accept('./app/App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
