import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {appointmentActions} from 'features/Appointment';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {
  Appointment,
  AppointmentTypes,
  DirectBookingSessionType,
  UserRoles,
} from 'interfaces';
import {Epic} from 'redux-observable';
import {
  catchError,
  concat,
  filter,
  from,
  ignoreElements,
  mergeMap,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import {BookingService} from 'services';
import {DirectBookingService} from 'services/api/DirectBooking';

import {directBookingActions} from './directBookingSlice';

// Direct Booking Options Epic
export const getDirectBookingOptionsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(directBookingActions.getDirectBookingFilterOptions.match),
    switchMap(() =>
      from(DirectBookingService.getDirectBookingFilterData()).pipe(
        mergeMap(({data: {message}}) => [
          directBookingActions.getDirectBookingFilterOptionSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(directBookingActions.getDirectBookingFilterOptionsFailure()),
            of(
              userActions.setAsyncError({
                filter: 'directBooking',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

// Direct Booking Searched Provider Epic
export const getDirectBookingSearchedProviderEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = actions$ =>
  actions$.pipe(
    filter(directBookingActions.getDirectBookingSearchedProviders.match),
    switchMap(({payload}) =>
      from(
        DirectBookingService.getDirectBookingSearchedProvidersData(payload),
      ).pipe(
        mergeMap(({data: {message}}) => [
          directBookingActions.getDirectBookingSearchedProvidersSuccess(
            message,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(directBookingActions.getDirectBookingSearchedProvidersFailure()),
            of(
              userActions.setAsyncError({
                filter: 'directBooking',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const getProviderByIdEpic: Epic<AppActions, AppActions, RootState> = (
  actions$,
  state$,
) =>
  actions$.pipe(
    filter(directBookingActions.getProviderById.match),
    switchMap(({payload}) =>
      from(DirectBookingService.getProviderById(payload)).pipe(
        withLatestFrom(state$),
        mergeMap(
          ([
            {
              data: {message},
            },
            state,
          ]) => {
            const {
              directBooking: {selectedProvider},
            } = state;
            return [
              directBookingActions.getProviderByIdSuccess(message),
              ...(!selectedProvider
                ? [
                    directBookingActions.setDirectBookingSelectedProvider(
                      message,
                    ),
                  ]
                : []),
            ];
          },
        ),
        catchError((message: string) =>
          concat(
            of(directBookingActions.getProviderByIdFailure()),
            of(
              userActions.setAsyncError({
                filter: 'directBooking',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const bookAppointmentEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(directBookingActions.bookAppointment.match),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {providerId, ...rest},
        },
      ]) => {
        // Show loading toast

        const toastId = Toast({
          type: 'promise',
          title: 'Appointment 📆',
          message: 'Scheduling appointment...',
        });
        // Use concat to emit the service call followed by success/failure actions
        return concat(
          from(
            BookingService.bookAppointment({
              ...rest,
              [`${
                rest.appointmentType ===
                AppointmentTypes.video_call_with_therapist
                  ? 'therapistId'
                  : 'prescriberId'
              }`]: providerId,
            }),
          ).pipe(
            mergeMap((appointment: Appointment) => {
              // Update toast content on success
              if (toastId) {
                Toast({
                  type: 'success',
                  title: 'Appointment Success',
                  message: i18next.t('other.yourAppointmentHasBeenBooked'),
                  updateId: toastId,
                  pauseOnHover: true,
                });
              }

              // Return success actions
              return [
                directBookingActions.bookAppointmentSuccess(),
                directBookingActions.setDirectBookingSelectedSession(
                  {} as DirectBookingSessionType,
                ),
                userActions.checkSession(UserRoles.member),
                appointmentActions.bookAppointmentSuccess(appointment),
              ];
            }),
            catchError((message: string) => {
              // Hide loading toast on failure
              if (toastId) {
                Toast({
                  type: 'error',
                  title: 'Appointment Failed!',
                  message:
                    message ||
                    `Failed to book appointment. Please try again later.`,

                  autoClose: 5000,
                  updateId: toastId,
                  pauseOnHover: true,
                });
              }
              // Return failure action
              return of(directBookingActions.bookAppointmentFailure(message));
            }),
          ),
        );
      },
    ),
  );

export const bookAppointmentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(directBookingActions.bookAppointmentFailure.match),
    withLatestFrom(state$),
    mergeMap(
      ([
        {payload: message},
        {
          directBooking: {selectedSession},
        },
      ]) => {
        const directBookingFailed =
          message?.includes('is not an available time slot') &&
          selectedSession?.appointmentType;

        Toast({
          type: 'error',
          title: directBookingFailed
            ? i18next.t('other.slotUnavailable')
            : i18next.t('other.appointmentFailed'),
          message: directBookingFailed
            ? i18next.t('unfortunatelySlotBooked')
            : message,
          autoClose: directBookingFailed ? false : undefined,
        });

        if (directBookingFailed) {
          push({
            pathname: `/book-appointment/${selectedSession.appointmentType}`,
          });
        }

        return directBookingFailed
          ? [
              directBookingActions.setDirectBookingSelectedSession(
                {} as DirectBookingSessionType,
              ),
            ]
          : [];
      },
    ),
    ignoreElements(),
  );

export const directBookingEpics = [
  getDirectBookingOptionsEpic,
  getDirectBookingSearchedProviderEpic,
  getProviderByIdEpic,
  bookAppointmentEpic,
  bookAppointmentFailureEpic,
];
