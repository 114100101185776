import Dexie from 'dexie';
import {
  MemberProfile,
  PrescriberProfile,
  TherapistProfile,
  TNotification,
  WellniteContentDraftState,
} from 'interfaces';
import log from 'loglevel';

export const isIndexedDBAvailable = 'indexedDB' in window;
export class AppCache extends Dexie {
  user!: Dexie.Table<
    MemberProfile | TherapistProfile | PrescriberProfile,
    string
  >;
  notifications!: Dexie.Table<TNotification, string>;
  wellniteContent!: Dexie.Table<WellniteContentDraftState, string>;
  swVersions!: Dexie.Table<number, string>;
  providerMembers!: Dexie.Table<MemberProfile, string>;
  constructor(dbName: string) {
    super(dbName);

    if (!isIndexedDBAvailable) {
      log.info("This browser doesn't support IndexedDB");
      return;
    }

    this.version(1).stores({
      posts: 'userPost.postId, userPost.date',
      moreData: '',
      chat: 'messageId, chatOwner, timeOfMessage',
    });

    this.version(2).stores({
      patients: 'email, date',
    });
    this.version(3).stores({
      patients: 'email, date, verified, subscriptionStatus',
    });

    this.version(4).stores({
      patients: 'email, date, verified, subscriptionStatus, doctorName',
    });

    this.version(5).stores({
      patients: 'email, date, subscriptionStatus',
    });

    this.version(6).stores({
      availabilityInfo: 'appointmentType',
    });
    this.version(7).stores({
      user: 'email',
    });
    this.version(8).stores({
      user: 'email',
      notifications: '_id, createdAt',
    });
    this.version(9).stores({
      appointments: 'appointmentID,dateOfAppointment',
    });
    this.version(10).stores({
      swVersions: '++',
    });
    this.version(11).stores({
      providerMembers: 'patientId',
    });
    this.version(12).stores({
      appointments: null,
    });
    this.version(13).stores({
      wellniteContent: 'userId',
    });
    this.user = this.table('user');
    this.notifications = this.table('notifications');
    this.providerMembers = this.table('providerMembers');
    this.wellniteContent = this.table('wellniteContent');
  }
}
