import React, {FC} from 'react';
import {motion} from 'framer-motion';

interface Props {
  children: React.ReactNode;
  innerWidth: number;
}
export const MotionDiv: FC<Props> = ({children, innerWidth}) => {
  return (
    <motion.div
      initial={
        window.innerWidth < innerWidth
          ? {
              opacity: 0,
              x: 100,
            }
          : false
      }
      exit={{
        opacity: window.innerWidth < innerWidth ? 0.8 : 1,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
    >
      {children}
    </motion.div>
  );
};
