import {LOCATION_CHANGE} from 'connected-react-router';
import {submitOnboardingDataSuccess} from 'features/Appointment/Onboarding/onboardingActions';
import {
  verifyOneTimePassword,
  verifyOneTimePasswordSuccess,
} from 'features/Member/Onboarding/PhoneVerification/phoneVerificationActions';
import {
  postMarketingChannel,
  postMarketingChannelSuccess,
} from 'features/Member/Thanks/marketingChannelAction';
import {
  checkout,
  checkoutSuccess,
} from 'features/Payment/Checkout/checkoutActions';
import {
  addCreditCard,
  addCreditCardSuccess,
} from 'features/Payment/CreditCardMgmt/creditCardMgmtActions';
import {
  saveInsuranceImage,
  saveInsuranceImageSuccess,
} from 'features/Payment/CurrentPlan/currentPlanActions';
import {
  cancelSubscription,
  cancelSubscriptionSuccess,
} from 'features/Payment/ManageSubscription/manageSubscriptionActions';
import {
  onboardingOneTimePayment,
  onboardingOneTimePaymentSuccess,
} from 'features/Payment/OnboardingOneTimePayment/onboardingOneTimePaymentActions';
import {
  resubscribe,
  resubscribeSuccess,
} from 'features/Payment/ResubscriptionCheckout/resubscriptionCheckoutActions';
import {
  updatePlan,
  updatePlanSuccess,
} from 'features/Payment/UpdatePlan/updatePlanActions';
import {
  outSideWellniteRegister,
  outsideWellniteRegisterSuccess,
} from 'features/User/Register/OutsideWellniteRegister/outsideWellniteRegisterActions';
import {
  register,
  registerSuccess,
} from 'features/User/Register/registerActions';
import {
  IntercomEventTypes,
  MemberProfile,
  StripePlanNames,
  StripePlanTypes,
} from 'interfaces';
import {trackIntercomEvent} from 'services/api/Intercom/helpers';

import {createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit';

const listenerMiddleware = createListenerMiddleware();

/**
 * @description
 * 1. if user is on video page then hide the intercom widget
 * 2. if user is not on video page then show the intercom widget
 * 3. if user click on insurance signup link, then emit an intercom event
 */
listenerMiddleware.startListening({
  type: LOCATION_CHANGE,
  effect: (action: any) => {
    const pathname = action?.payload?.location?.pathname;

    if (pathname === '/dashboard') {
      window.Intercom('update', {hide_default_launcher: false});
    } else {
      window.Intercom('update', {hide_default_launcher: true});
    }

    if (pathname.includes('/zd/register')) {
      trackIntercomEvent(IntercomEventTypes.ZD_REGISTER);
    }
  },
});

/**
 * @description on account creation, emit an intercom event
 */
listenerMiddleware.startListening({
  matcher: isAnyOf(register, outSideWellniteRegister),
  effect: async (_, listenerApi) => {
    if (
      // @ts-ignore
      (await listenerApi.condition(registerSuccess)) ||
      // @ts-ignore
      (await listenerApi.condition(outsideWellniteRegisterSuccess))
    ) {
      const user = (listenerApi.getState() as any)?.user
        .current as MemberProfile;

      if (user) {
        trackIntercomEvent(IntercomEventTypes.ACCOUNT_CREATED, {
          userId: user._id,
          email: user.email,
          name: user.fullName,
          accountType: user.accountType,
        });
        trackIntercomEvent(
          `Patient Plan Is ${user?.paymentPlan}` as IntercomEventTypes,
        );
      }
    }
  },
});

/**
 * @description if user is uninsured type, emit an intercom event
 * this event will emit only one time for every user
 */
listenerMiddleware.startListening({
  actionCreator: postMarketingChannel,
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(postMarketingChannelSuccess)) {
      trackIntercomEvent(IntercomEventTypes.PHONE_VERIFICATION_PENDING);
    }
  },
});

/**
 * @description Whenever an credit card is uploaded, emit an intercom event
 */
listenerMiddleware.startListening({
  matcher: isAnyOf(checkout, addCreditCard, onboardingOneTimePayment),
  effect: async (_, listenerApi) => {
    if (
      // @ts-ignore
      (await listenerApi.condition(checkoutSuccess)) ||
      // @ts-ignore
      (await listenerApi.condition(addCreditCardSuccess)) ||
      // @ts-ignore
      (await listenerApi.condition(onboardingOneTimePaymentSuccess))
    ) {
      trackIntercomEvent(IntercomEventTypes.CREDIT_CARD_ADDED);
    }
  },
});

/**
 * @description if insurance image is uploaded first time and user is insured type i.e.(INSURANCE), emit an intercom event
 * this event will emit only one time for every user
 */
listenerMiddleware.startListening({
  predicate: (action, _, previousState) => {
    const prevData = (previousState as any)?.user?.current as MemberProfile;
    const isUploadInsuranceFirstTime =
      prevData &&
      !prevData?.insuranceImageFront &&
      !prevData?.insuranceImageBack &&
      action.type === 'saveInsuranceImage' &&
      prevData?.paymentPlan === 'together';

    return isUploadInsuranceFirstTime;
  },
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(saveInsuranceImageSuccess)) {
      trackIntercomEvent(IntercomEventTypes.IN_DASHBOARD);
    }
  },
});

/**
 * @description Whenever an insurance image is uploaded, emit an intercom event
 */
listenerMiddleware.startListening({
  actionCreator: saveInsuranceImage,
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(saveInsuranceImageSuccess)) {
      trackIntercomEvent(IntercomEventTypes.INSURANCE_CARD_UPLOADED);
    }
  },
});

/**
 * @description Whenever governmentID image is uploaded, emit an intercom event
 */
listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    const prevData = (previousState as any)?.user?.current as MemberProfile;
    const currData = (currentState as any)?.user?.current as MemberProfile;

    const isGovernmentIDUploaded =
      prevData &&
      currData &&
      prevData?.governmentID !== currData?.governmentID &&
      action.type === 'SubmitOnboardingData';

    return isGovernmentIDUploaded;
  },
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(submitOnboardingDataSuccess)) {
      trackIntercomEvent(IntercomEventTypes.GOVERNMENT_ID_UPLOADED);
    }
  },
});

/**
 * @description Whenever Date Of Birth is Changed, emit an intercom event
 */
listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    const prevData = (previousState as any)?.user?.current as MemberProfile;
    const currData = (currentState as any)?.user?.current as MemberProfile;

    const isDOBChanged =
      prevData &&
      currData &&
      String(prevData?.dateOfBirth) !== String(currData?.dateOfBirth) &&
      action.type === 'SubmitOnboardingData';

    return isDOBChanged;
  },
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(submitOnboardingDataSuccess)) {
      trackIntercomEvent(IntercomEventTypes.DATE_OF_BIRTH_ADDED);
    }
  },
});

/**
 * @description Whenever Phone Number is Verified, emit an intercom event
 */
listenerMiddleware.startListening({
  actionCreator: verifyOneTimePassword,
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(verifyOneTimePasswordSuccess)) {
      const user = (listenerApi.getState() as any)?.user
        .current as MemberProfile;

      trackIntercomEvent(IntercomEventTypes.PHONE_VERIFICATION_DONE);

      if (user?.paymentPlan !== StripePlanNames.together) {
        trackIntercomEvent(IntercomEventTypes.IN_DASHBOARD);
      }
    }
  },
});

/**
 * @description Whenever Plan is Updated, emit an intercom event
 */
listenerMiddleware.startListening({
  actionCreator: updatePlan,
  effect: async (action, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(updatePlanSuccess)) {
      const {paymentPlan, paymentPlanDuration} = action.payload;

      let ev: IntercomEventTypes;
      if (paymentPlan === StripePlanTypes.new_therapy)
        ev = IntercomEventTypes.PATIENT_PLAN_IS_THERAPY;
      else if (paymentPlan === StripePlanTypes.medicalcare)
        ev = IntercomEventTypes.PATIENT_PLAN_IS_MEDICALCARE;
      else if (paymentPlan === StripePlanTypes.medicalcare_plus_therapy)
        ev = IntercomEventTypes.PATIENT_PLAN_IS_MEDICALCARE_PLUS_THERAPY;
      else if (paymentPlan === StripePlanTypes.together)
        ev = IntercomEventTypes.PATIENT_PLAN_IS_TOGETHER;
      else ev = IntercomEventTypes.PATIENT_PLAN_IS_INACTIVE;

      trackIntercomEvent(ev, {
        paymentPlan,
        paymentPlanDuration,
      });
    }
  },
});

/**
 * @description Whenever Subscription is Canceled, emit an intercom event
 */
listenerMiddleware.startListening({
  actionCreator: cancelSubscription,
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(cancelSubscriptionSuccess)) {
      trackIntercomEvent(IntercomEventTypes.PATIENT_CANCELLED_SUBSCRIPTION);
    }
  },
});

/**
 * @description Whenever Member/Patient Re-Subscribed to a Plan, emit an intercom event
 */
listenerMiddleware.startListening({
  actionCreator: resubscribe,
  effect: async (_, listenerApi) => {
    // @ts-ignore
    if (await listenerApi.condition(resubscribeSuccess)) {
      const user = (listenerApi.getState() as any)?.user
        .current as MemberProfile;

      trackIntercomEvent(
        `Patient Resubscribed To a Plan : ${user?.paymentPlan}` as IntercomEventTypes,
      );
    }
  },
});

export default listenerMiddleware;
