import React, {FC} from 'react';
import classNames from 'classnames';
import {Button} from 'components/Basic';
import {FormTextInput} from 'components/Basic/Form/V2';
import {OnboardingShippingProps} from 'interfaces';
import {Control, FieldErrorsImpl, FieldValues} from 'react-hook-form';

const shippingAddressFields = [
  {
    id: 'streetAddress',
    name: 'shippingAddress.streetAddress',
    label: 'Shipping Address',
    placeholder: 'No. 52, Mighty street',
  },
  {
    id: 'city',
    name: 'shippingAddress.city',
    label: 'City',
    placeholder: 'City',
  },
  {
    id: 'state',
    name: 'shippingAddress.state',
    label: 'State',
    placeholder: 'State',
  },
  {
    id: 'postalCode',
    name: 'shippingAddress.postalCode',
    label: 'Zip Code',
    placeholder: '103234',
  },
];

const commonInputClasses =
  'text-sm leading-4 p-4 placeholder-gray-600 rounded-[8px]';

type Props<TFieldValues extends FieldValues, TContext extends object> = {
  apiError: string;
  onSubmit: any;
  isPending: boolean;
  errors: Partial<FieldErrorsImpl<OnboardingShippingProps>>;
  control: Control<TFieldValues, TContext>;
};

export const ShippingForm: FC<Props<any, any>> = ({
  onSubmit,
  control,
  errors,
  apiError,
  isPending,
}) => {
  return (
    <form
      id="shipping-address-form"
      className="pt-6 flex flex-wrap w-full justify-between"
      onSubmit={onSubmit}
    >
      {shippingAddressFields.map(field => (
        <div className="md:w-[49%] w-full">
          <FormTextInput
            key={field.id}
            id={field.id}
            name={field.name}
            label={field.label}
            classes="p-0 md:mb-5"
            labelClasses="text-xs leading-4 md:mt-4"
            inputClasses={classNames(commonInputClasses, {
              '!border-red-500': Boolean(
                errors.shippingAddress?.[field.id]?.message || apiError,
              ),
            })}
            placeholder={field.placeholder}
            control={control}
          />
        </div>
      ))}
      <section className="pt-4 flex flex-col justify-end text-center ml-auto">
        <Button
          type="submit"
          form="shipping-address-form"
          borderColor="transparent"
          className="mx-auto py-3 px-5 text-base"
          isSubmitting={isPending}
        >
          Save
        </Button>
      </section>
    </form>
  );
};
