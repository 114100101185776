import {ReactElement} from 'react';

export enum AvailabilityDay {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export type BlockedAvailability = {
  id: number;
  calendarTimezone: string;
  start: string;
  end: string;
  notes: string;
  description: string;
  error?: string;
};

export type TimePeriod = 'AM' | 'PM';

export type Time = {
  hour: number;
  minute: number;
  period: TimePeriod;
};

export type TimeRange = {
  from: Time;
  to: Time;
};

export type AvailabilityInString = Record<AvailabilityDay, string[]>;
export type AvailabilityInTimeRange = Record<AvailabilityDay, TimeRange[]>;

export type SpecificDayAvailability<T> = {
  id: number;
  start: string;
  end: string;
  hours: T;
};

export type RawAvailabilityData = {
  recurring: AvailabilityInString;
  specificDay: SpecificDayAvailability<AvailabilityInString>[];
  blocked: BlockedAvailability[];
};

export type DateTime = Time & {
  date: Date;
};

export type AvailabilityData = {
  recurring: AvailabilityInTimeRange;
  specificDay: SpecificDayAvailability<AvailabilityInTimeRange>[];
  blocked: BlockedAvailability[];
};

export type BlockAvailabilityReq = {
  startDate: Date;
  endDate: Date;
  notes: string;
};

export type TimeObject = {
  hour: number;
  minute: number;
  period: 'am' | 'pm';
};

export interface Range {
  from: TimeObject;
  to: TimeObject;
}

export const padZero = (digit: number | string) =>
  String(digit).padStart(2, '0');

export type SelectOption = {
  label: string;
  value: Time;
};

export interface SpecificDayAvailabilityData {
  startDate: Date;
  endDate: Date;
  availability: AvailabilityInString;
}

export interface SpecificDayAvailabilityResponse {
  id: number;
  everyOther: boolean;
  start: Date;
  end: Date;
  hours: AvailabilityInString;
}

export type FormattedAvailabilityType = {
  id: number;
  title: string;
  start: Date;
  end: Date;
  isAvailability: boolean;
  isBlocked?: boolean;
  isDayOff?: boolean;
  availability?: BlockedAvailability;
  notes?: string;
  description?: string;
  calendarTimezone?: string;
  children?: ReactElement | null;
};
