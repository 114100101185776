import {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type: mime});
};

export function useImageResizer(
  imageIsNotArray?: boolean,
  minHeight = 320,
): {
  resizeImage: any;
  fileSize: string | null;
  fileName: string | null;
  baseString: string | null;
  setBaseString: Dispatch<SetStateAction<string | null>>;
  getOptimizedImage: (file: File) => Promise<File>;
} {
  const MAX_WIDTH = 640;
  const MAX_HEIGHT = 360;
  const MIN_WIDTH = 320;
  const MIN_HEIGHT = minHeight;
  const MIME_TYPE = 'image/jpeg';
  const QUALITY = 0.9;
  const [fileSize, setFileSize] = useState<string | null>('');
  const [baseString, setBaseString] = useState<string | null>('');
  const [fileName, setFileName] = useState<string | null>('');
  //convert base64 to imageFile

  const resizeImage = ({
    file,
    name,
    setValue,
  }: {
    file: any;
    name: string;
    setValue: (
      name: string,
      value: unknown,
      config?:
        | Partial<{
            shouldValidate: boolean;
            shouldDirty: boolean;
          }>
        | undefined,
    ) => void;
  }) => {
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      console.log('error load');
    };

    img.onload = () => {
      URL.revokeObjectURL(img.src);
      const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        blob => {
          const size = blob && readableBytes(blob);
          setFileSize(size);
          setValue(
            name,

            imageIsNotArray
              ? dataURLtoFile(
                  canvas.toDataURL(MIME_TYPE, QUALITY),
                  `${uuidv4()}${file?.name}`,
                )
              : [
                  dataURLtoFile(
                    canvas.toDataURL(MIME_TYPE, QUALITY),
                    `${uuidv4()}${file?.name}`,
                  ),
                ],
            {shouldValidate: true},
          );
        },
        MIME_TYPE,
        QUALITY,
      );
      setBaseString(canvas.toDataURL(MIME_TYPE, QUALITY));
      setFileName(file?.name);
    };
  };

  const calculateSize = useCallback(
    (img: HTMLImageElement, maxWidth: number, maxHeight: number) => {
      let width = img.width;
      let height = img.height;

      if (width < MIN_WIDTH) {
        width = MIN_WIDTH;
      }
      if (height < MIN_HEIGHT) {
        height = MIN_HEIGHT;
      }
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      return [width, height];
    },
    [],
  );

  const getOptimizedImage = (file: File): Promise<File> => {
    return new Promise(function (resolve) {
      const blobURL = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobURL;
      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        console.log('Cannot load image');
      };
      img.onload = () => {
        URL.revokeObjectURL(img.src);
        const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, newWidth, newHeight);
        canvas.toBlob(() => {
          resolve(
            dataURLtoFile(
              canvas.toDataURL(MIME_TYPE, QUALITY),
              `${uuidv4()}${file.name}`,
            ),
          );
        });
      };
    });
  };

  return {
    resizeImage,
    fileSize,
    fileName,
    baseString,
    setBaseString,
    getOptimizedImage,
  };
}

export function readableBytes(bytes: Blob | number) {
  const size = bytes instanceof Blob ? bytes.size : bytes;
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  return (size / 1024 ** i).toFixed(2) + ' ' + sizes[i];
}
