import {RequestPasswordTokenFormData} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const requestPasswordToken = createAction<RequestPasswordTokenFormData>(
  'RequestPasswordToken',
);
export const requestPasswordTokenSuccess = createAction(
  'RequestPasswordTokenSuccess',
);
export const requestPasswordTokenFailure = createAction(
  'RequestPasswordTokenFailure',
);
