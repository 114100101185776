import {useEffect} from 'react';
import {useAppDispatch} from 'app/store';
import {
  selectProviderOnboardingStatus,
  selectProviderOnboardingStatusIsInitialized,
} from 'features/Provider/providerSelectors';
import {getProviderData} from 'features/Provider/Register/registerActions';
import {selectUserProfile} from 'features/User/userSelectors';
import {SliceStatus} from 'interfaces';
import {useSelector} from 'react-redux';
import {isProvider} from 'utils';

/**
 * Fetch provider data. Fetches only once.
 * To refresh, run refreshProviderData.
 */
export function useInitializeProviderData(): {
  isProviderReady: boolean;
  isLoadingProvider: boolean;
  refreshProviderData: VoidFunction;
} {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUserProfile);

  const isProviderUser = isProvider(user);
  const isProviderLoadingStatus = useSelector(selectProviderOnboardingStatus);
  const isProviderInitialized = useSelector(
    selectProviderOnboardingStatusIsInitialized,
  );

  const isLoadingProvider = isProviderLoadingStatus === SliceStatus.pending;
  const providerNotReady = isProviderUser && !isProviderInitialized;

  const fetchProviderData = () => {
    dispatch(getProviderData(user!.role));
  };

  useEffect(() => {
    if (providerNotReady && !isLoadingProvider) {
      fetchProviderData();
    }
  }, [providerNotReady, isLoadingProvider]);

  return {
    isLoadingProvider,
    isProviderReady: !providerNotReady,
    refreshProviderData: fetchProviderData,
  };
}
