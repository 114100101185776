import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const getReferralData = async (
  email: string,
): Promise<
  Response<{
    message: {
      referralCode: string;
      referralCount: string;
      referralCoinsTogether: string;
    };
  }>
> => {
  try {
    const res = await requestHandler<{
      message: {
        referralCode: string;
        referralCount: string;
        referralCoinsTogether: string;
      };
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetReferralData}/${email}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const postMarketingChannel = async (data: {
  whereFrom: string;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<
      {
        message: string;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.SaveMarketingChannel,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const ReferralService = {
  getReferralData,
  postMarketingChannel,
};
