import React, {FC} from 'react';
import classnames from 'classnames';
import {FieldError} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

export const FormError: FC<{
  id?: string;
  error?: FieldError;
  errorClasses?: string;
}> = ({error, id = 'form-error', errorClasses = ''}) => {
  const {t} = useTranslation();
  return error?.message ? (
    <p
      data-cy={id}
      data-testid={id}
      role="alert"
      className={classnames(
        errorClasses,
        'mt-2 text-sm text-center text-red-500',
        {
          hidden: !error,
        },
      )}
    >
      {t(error.message)}
    </p>
  ) : null;
};
