import styled from 'styled-components';

export const AppointmentCalendarContainer = styled.section`
  .DayPicker-Day--today {
    color: ${props => props.theme.colors.blue[600]};
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    background-color: #eff1f1;
  }

  &&&& .DayPicker-Month {
    width: 100%;
    margin: 0px 0px;
  }

  &&&& .DayPicker-Day {
    padding: 1rem;
  }

  .DayPicker-Caption > div {
    font-size: 1rem;
    padding: 0px;
    border-bottom: none;
    padding-bottom: 1rem;
    // margin-bottom: 2rem;
  }

  .DayPicker-NavButton {
    top: 0px;
    right: 0px;
  }

  .DayPicker-Day--disabled {
    background: transparent;
  }

  .DayPicker-Day.DayPicker-Day--selected {
    background-color: ${props => props.theme.colors.blue[600]};

    &:hover {
      background-color: ${props => props.theme.colors.blue[600]};
    }
  }

  // overrides

  .DayPicker-Caption > div {
    text-align: center;
  }

  .DayPicker {
    padding: 10px;
  }

  &&&& .DayPicker-Day {
    border-radius: 15px;
    // outline: ${props => `2px solid ${props.theme.colors.gray[200]}`};
    // outline-offset: -4px;
    font-size: 14px;
    position: relative;
    @media (max-width: 400px) {
      padding: 0.8rem;
      border-radius: 12px;
    }
    @media (max-width: 350px) {
      font-size: 12px;
      border-radius: 10px;
    }
    @media (max-width: 300px) {
      padding: 0.5rem;
    }
  }

  &&&& .DayPicker-Day--disabled {
    outline: none;
  }

  &&&& .DayPicker-Day--selected {
    outline: 3px solid white;
    outline-offset: -2px;
    font-weight: normal;
    &:after {
      color: white;
    }
  }

  // appointments

  .DayPicker-Day.DayPicker-Day--appointments {
    padding-bottom: 0;

    &:after {
      display: block;
      content: '\u25CF';
      font-size: 5px;
      color: ${props => props.theme.colors.blue[600]};
      white-space: pre-wrap;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }
`;

export const DateTimePickerContainer = styled.section`
  .DayPicker {
    width: 100%;
  }
  .DayPicker-Day--today {
    color: ${props => props.theme.colors.blue[600]};
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    background-color: #eff1f1;
  }

  &&&& .DayPicker-Month {
    width: 100%;
    margin: 0px 0px;
  }

  &&&& .DayPicker-Day {
    padding: 0.5rem;
  }

  .DayPicker-Caption {
    margin-bottom: 0.5em;
    padding: 0;
  }

  .DayPicker-Caption > div {
    text-align: center;
    font-size: 0.75rem;
    padding: 0px 10px;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    height: 36px;
    display: flex;
    align-items: center;
  }

  .DayPicker-NavButton {
    top: 9px;
    right: 12px;
    width: 0.75em;
    height: 0.75em;
  }

  .DayPicker-Day--disabled {
    background: transparent;
  }

  .DayPicker-Day.DayPicker-Day--selected {
    background-color: ${props => props.theme.colors.blue[600]};

    &:hover {
      background-color: ${props => props.theme.colors.blue[600]};
    }
  }

  &&&& .DayPicker-Day {
    border-radius: 15px;
    // outline: ${props => `2px solid ${props.theme.colors.gray[200]}`};
    // outline-offset: -4px;
    font-size: 0.75rem;
    position: relative;
    @media (max-width: 400px) {
      padding: 0.8rem;
      border-radius: 12px;
    }
    @media (max-width: 350px) {
      font-size: 12px;
      border-radius: 10px;
    }
    @media (max-width: 300px) {
      padding: 0.5rem;
    }
  }

  &&&& .DayPicker-Day--disabled {
    outline: none;
  }

  &&&& .DayPicker-Day--selected {
    outline: 3px solid white;
    outline-offset: -2px;
    font-weight: normal;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8b9898;
    text-align: center;
    font-size: 0.75em;
  }
`;

export const DateTimePickerWrapper = styled(DateTimePickerContainer)`
  &&&& {
    /* Add or override styles here */
    .DayPicker-wrapper {
      padding-bottom: 2px;
    }
    .DayPicker-Caption > div {
      font-size: 14px;
      padding: 6px 26px;
      font-weight: 600;
      border: none;
      height: auto;
      justify-content: center;
    }
    .DayPicker-Day {
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      border-radius: 100%;
      width: 36px;
      height: 36px;
    }
    .DayPicker:not(.DayPicker--interactionDisabled)
      .DayPicker-Day:not(.DayPicker-Day--disabled):not(
        .DayPicker-Day--selected
      ):not(.DayPicker-Day--outside):hover {
      background: #e6e6e6;
    }
    .DayPicker-Day.DayPicker-Day--selected {
      background-color: #315eff;
      color: #ffffff;
    }
    .DayPicker-NavBar {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 100%;
      align-items: center;
    }
    .DayPicker-NavButton {
      position: unset;
    }
    .DayPicker-NavButton:hover {
      opacity: 0.5;
    }
    .DayPicker-NavButton--prev,
    .DayPicker-NavButton--next {
      width: 22px;
      height: 22px;
      background-size: contain;
    }
    .DayPicker-NavButton--prev {
      margin-right: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' width='22' height='22'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5' /%3E%3C/svg%3E");
      background-position-x: -6px;
    }
    .DayPicker-NavButton--next {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' width='22' height='22'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m8.25 4.5 7.5 7.5-7.5 7.5' /%3E%3C/svg%3E");
      background-position-x: 6px;
    }

    /* Add or override more styles as needed */
  }
`;
