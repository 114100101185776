import React, {FC, ReactNode} from 'react';

export const Heading: FC<{classNames?: string; children: ReactNode}> = ({
  children,
  classNames,
}) => {
  return (
    <h1
      className={`font-bold my-0 leading-snug ${
        classNames ? classNames : 'text-3xl'
      } md:text-4xl`}
    >
      {children}
    </h1>
  );
};
