import React, {FC} from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';

export interface LinkProps {
  pathname: string;
  linkName: string;
  current: boolean;
  classNames?: string;
  linkClasses?: string;
  children?: React.ReactNode;
}

export const MemberNavLink: FC<LinkProps> = ({
  pathname,
  current,
  linkName,
  children,
  classNames = 'pl-24',
  linkClasses = 'px-3 py-6 lg:py-3',
}) => {
  return (
    <Link to={pathname}>
      <div
        className={classnames(
          `group flex items-center justify-between mx-1 lg:my-5 text-base font-inter leading-snug ${classNames}`,
          {
            'text-blue-600 bg-primary/10 rounded-full font-medium': current,
            'text-gray-700 hover:text-blue-600': !current,
          },
        )}
      >
        <span
          className={`${
            linkName.toLowerCase() === 'share' ? 'text-white' : ''
          } ${linkClasses}`}
        >
          {linkName}
        </span>
        {children}
      </div>
    </Link>
  );
};
