import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {memberActions} from 'features/Member';
import {userActions} from 'features/User';
import {SocialOnboardingStatusEnum, UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {StripeService} from 'services';

import {paymentActions} from '../paymentSlice';

export const saveInsuranceImageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.saveInsuranceImage.match),
    switchMap(
      ({payload: {insuranceImageData, onboardStage, fromSocialOnboarding}}) =>
        from(
          StripeService.saveInsuranceImage(
            insuranceImageData,
            onboardStage,
            fromSocialOnboarding,
          ),
        ).pipe(
          mergeMap(() => {
            if (!fromSocialOnboarding) {
              return [
                userActions.checkSession(UserRoles.member),
                paymentActions.saveInsuranceImageSuccess(),
                userActions.setNotification({
                  message: fromSocialOnboarding
                    ? 'Book Therapy or Doctor Consultation Now'
                    : 'Your insurance card images have updated 📷',
                  messageType: 'success',
                  // navigateTo: '/book-appointment/video_call_with_therapist',
                }),
                push({
                  pathname: '/dashboard',
                }),
              ];
            } else {
              return [
                paymentActions.saveInsuranceImageSuccess(),
                userActions.setSocialOnboardStage(onboardStage ?? 1),
                memberActions.changeSocialOnboardingStatus({
                  socialOnboardingStatus: SocialOnboardingStatusEnum.complete,
                }),
              ];
            }
          }),
          catchError((message: string) =>
            concat(
              of(paymentActions.saveInsuranceImageFailure()),
              of(
                userActions.setAsyncError({
                  filter: 'payment',
                  message,
                }),
              ),
            ),
          ),
        ),
    ),
  );

export const paymentCurrentPlanEpics = [saveInsuranceImageEpic];
