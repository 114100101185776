import React, {FC} from 'react';
import Placeholder from 'assets/images/silhouette.jpg';
import classNames from 'classnames';
import {EditAvatarIcon, LoadingSpinner} from 'components';
import {useAvatarUpload, useUserInfo} from 'hooks';

import WellbitsBadge from './WellbitsBadge';

const UserInfo: FC = () => {
  const {fullName, image, wellbitWallet} = useUserInfo();

  const {previewImage, handleChange, isUploading, onSubmit, resetForm} =
    useAvatarUpload();

  return (
    <section
      className={classNames(
        'flex lg:flex-col items-center justify-start lg:text-center gap-6 lg:gap-1 px-4 py-6 md:pl-24 lg:px-4',
        {
          'lg:pb-16': !previewImage,
        },
      )}
    >
      <input
        hidden
        name="image"
        id="image"
        onChange={handleChange}
        type="file"
        accept="image/*"
        data-testid="avatar"
      ></input>
      <label htmlFor="image">
        <div className="relative w-20 h-20">
          <img
            className="w-full h-full rounded-full object-cover"
            src={previewImage || image || Placeholder}
            alt="avatar"
          />
          <div
            className={classNames(
              'w-full h-full rounded-full absolute inline-flex justify-center items-center top-0 left-0 lg:bg-black lg:bg-opacity-50 hover:opacity-100 transition-opacity duration-500',
              {
                'lg:opacity-0': !isUploading,
                'lg:opacity-100': isUploading,
              },
            )}
          >
            {isUploading ? (
              <LoadingSpinner type="Oval" color="#FFFFFF" />
            ) : (
              <>
                <p className="text-xl cursor-pointer text-gray-100 hidden lg:block">
                  Edit
                </p>
                <div className="w-20 h-20 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 block lg:hidden">
                  <span className="absolute bottom-0 right-0 h-6 w-6 p-1 bg-white inline-flex justify-center items-center border rounded-full">
                    <EditAvatarIcon strokeWidth={4} />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </label>
      <div className="flex flex-col">
        <p className="text-lg leading-snug font-normal text-gray-700">{`${fullName}`}</p>
        <WellbitsBadge data={wellbitWallet} />
      </div>
      <section
        className={classNames('justify-evenly w-40', {
          hidden: !previewImage,
          flex: previewImage,
        })}
      >
        <button
          onClick={resetForm}
          className="my-6 p-0 cursor-pointer text-gray-700 text-sm leading-snug border-0 bg-transparent"
        >
          Cancel
        </button>
        <button
          onClick={onSubmit}
          className={classNames(
            'my-6 p-0 cursor-pointer text-sm leading-snug border-0 bg-transparent',
            {
              'text-blue-600 pointer-events-auto': previewImage,
              'pointer-events-none text-gray-700': !previewImage,
            },
          )}
        >
          Save
        </button>
      </section>
    </section>
  );
};

export default UserInfo;
