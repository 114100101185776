import * as Yup from 'yup';

export const bioDataSchema = Yup.object().shape({
  firstName: Yup.string().required('validations.required'),
  middleName: Yup.string(),
  lastName: Yup.string().required('validations.required'),
  dateOfBirth: Yup.string().required('validations.required'),
  sex: Yup.string()
    .required('validations.required')
    .typeError('validations.required'),
  phoneNumber: Yup.string().required('validations.required'),
  pronoun: Yup.string(),
  race: Yup.string(),
  ethnicity: Yup.string(),
});

export const medicationsSchema = Yup.object().shape({
  medications: Yup.array().of(
    Yup.object().shape({
      medication: Yup.string().required('Medication Required').ensure(),
      dosage: Yup.string(),
      frequency: Yup.number()
        .typeError('validations.mustBeNumber')
        .required('validations.required'),
      sinceWhen: Yup.date().typeError('validations.required').nullable(),
      effective: Yup.string().oneOf(['yes', 'no'], 'validations.required'),
    }),
  ),
});

export const whatHasLedYouToSeekCounselingNowSchema = Yup.object().shape({
  whatHasLedYouToSeekCounselingNow: Yup.string().required(
    'validations.required',
  ),
});

export const haveYouDealtWithAnyOfTheFollowingEmotionalOrBehavioralProblemsSchema =
  Yup.object().shape({
    emotionalOrBehavioralProblems: Yup.array().of(Yup.string()),
    otherEmotionalOrBehavioralProblems: Yup.string().when(
      'emotionalOrBehavioralProblems',
      {
        is: value => value === 'Other',
        then: Yup.string().required('validations.required'),
      },
    ),
  });

export const whoSuggestedThatYouSeeACounselorSchema = Yup.object().shape({
  whoSuggestedThatYouSeeACounselor: Yup.string().nullable(),
  otherWhoSuggestedThatYouSeeACounselor: Yup.string().when(
    'whoSuggestedThatYouSeeACounselor',
    {
      is: value => value === 'Other',
      then: Yup.string().required('validations.required'),
    },
  ),
});

export const whatWouldYouLikeToGainFromCounselingNowSchema = Yup.object().shape(
  {
    whatWouldYouLikeToGainFromCounselingNow: Yup.string(),
  },
);

export const whatSupportDoYouHaveInYourLifeSchema = Yup.object().shape({
  whatSupportDoYouHaveInYourLife: Yup.string(),
});

export const doYouHaveAnyDifficultiesWithAlcoholDrugsOrFoodSchema =
  Yup.object().shape({
    doYouHaveAnyDifficultiesWithAlcoholDrugsOrFood: Yup.string(),
  });

export const asAChildDidYouExperienceDifficultyWithAnyOfTheFollowingSchema =
  Yup.object().shape({
    asAChildDidYouExperienceDifficultyWithAnyOfTheFollowing: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required('Value is required'),
        details: Yup.string().nullable(),
      }),
    ),
    otherAsAChildDidYouExperienceDifficultyWithAnyOfTheFollowing:
      Yup.string().when(
        'asAChildDidYouExperienceDifficultyWithAnyOfTheFollowing',
        {
          is: value => value === 'Other',
          then: Yup.string().required('validations.required'),
        },
      ),
  });

export const inCaseYouHaveReceivedPsychotherapyOrCounselingInThePastSchema =
  Yup.object().shape({
    inCaseYouHaveReceivedPsychotherapyOrCounselingInThePast: Yup.string(),
  });

export const whichIfAnyOfTheseSubstancesDoYouCurrentlyUseOrHaveUsedInThePastSchema =
  Yup.object().shape({
    whichIfAnyOfTheseSubstancesDoYouCurrentlyUseOrHaveUsedInThePast:
      Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required('Value is required'),
          details: Yup.string().nullable(),
        }),
      ),
    otherWhichIfAnyOfTheseSubstancesDoYouCurrentlyUseOrHaveUsedInThePast:
      Yup.string().when(
        'whichIfAnyOfTheseSubstancesDoYouCurrentlyUseOrHaveUsedInThePast',
        {
          is: value => value === 'Other',
          then: Yup.string().required('validations.required'),
        },
      ),
  });

export const relationshipSatisfactionSchema = Yup.object().shape({
  relationshipSatisfaction: Yup.string().nullable(),
});

export const whatsYourSleepingPatternDoYouHaveTroubleSleepingSchema =
  Yup.object().shape({
    whatsYourSleepingPatternDoYouHaveTroubleSleeping: Yup.string(),
  });

export const doYouExerciseSchema = Yup.object().shape({
  doYouExercise: Yup.string(),
});

export const describeYourCurrentPhysicalHealthSchema = Yup.object().shape({
  describeYourCurrentPhysicalHealth: Yup.string().nullable(),
  moreDetailsDescribeYourCurrentPhysicalHealth: Yup.string(),
});

export const howWouldYouDescribeYourReligiousOrSpiritualBeliefsAndPracticesSchema =
  Yup.object().shape({
    howWouldYouDescribeYourReligiousOrSpiritualBeliefsAndPractices:
      Yup.string(),
  });

export const doYouRecentlyHaveSignificantLifeChangesOrStressfulEventsSchema =
  Yup.object().shape({
    doYouRecentlyHaveSignificantLifeChangesOrStressfulEvents: Yup.string(),
  });

export const whatAreYourStrengthsAndWeaknessesSchema = Yup.object().shape({
  whatAreYourStrengthsAndWeaknesses: Yup.string(),
});

export const whatIsYourCurrentEmploymentSituationIfOtherSpecifySchema =
  Yup.object().shape({
    whatIsYourCurrentEmploymentSituation: Yup.array().of(Yup.string()),
    otherWhatIsYourCurrentEmploymentSituation: Yup.string().when(
      'whatIsYourCurrentEmploymentSituation',
      {
        is: value => value === 'Other',
        then: Yup.string().required('validations.required'),
      },
    ),
  });

export const ifCurrentlyEmployedSchema = Yup.object().shape({
  whatIsYourOccupation: Yup.string(),
  doYouEnjoyYourWork: Yup.string(),
  howManyHoursADayDoYouWork: Yup.string(),
  doYouTakeWorkHomeWithYou: Yup.string(),
});

export const howIsYourSocialInteractionSchema = Yup.object().shape({
  howIsYourSocialInteraction: Yup.array().of(Yup.string()),
  otherHowIsYourSocialInteraction: Yup.string().when(
    'howIsYourSocialInteraction',
    {
      is: value => value === 'Other',
      then: Yup.string().required('validations.required'),
    },
  ),
});

export const whatIsYourCurrentFinancialSituationSchema = Yup.object().shape({
  whatIsYourCurrentFinancialSituation: Yup.array().of(Yup.string()),
  otherWhatIsYourCurrentFinancialSituation: Yup.string().when(
    'whatIsYourCurrentFinancialSituation',
    {
      is: value => value === 'Other',
      then: Yup.string().required('validations.required'),
    },
  ),
});

export const whatIsYourLegalSituationSchema = Yup.object().shape({
  whatIsYourLegalSituation: Yup.array().of(Yup.string()),
  otherWhatIsYourLegalSituation: Yup.string().when('whatIsYourLegalSituation', {
    is: value => value === 'Other',
    then: Yup.string().required('validations.required'),
  }),
});

export const haveYouEverTriedToTakeLifeSchema = Yup.object()
  .shape({
    haveYouEverTriedToTakeLife: Yup.string()
      .oneOf(['yes', 'no'], 'validations.required')
      .required()
      .typeError('validations.required'),
    ifYesSpecifyHaveYouEverTriedToTakeLife: Yup.string().when(
      'haveYouEverTriedToTakeLife',
      {
        is: value => value === 'yes',
        then: Yup.string().required('validations.required'),
      },
    ),
    haveYouEverTriedToTakeSomeonesLife: Yup.string()
      .oneOf(['yes', 'no'], 'validations.required')
      .required()
      .typeError('validations.required'),
    ifYesSpecifyHaveYouEverTriedToTakeSomeonesLife: Yup.string().when(
      'haveYouEverTriedToTakeSomeonesLife',
      {
        is: value => value === 'yes',
        then: Yup.string().required('validations.required'),
      },
    ),
  })
  .required();

export const thoughtsOfTakingOwnLifePast12MonthsSchema = Yup.object()
  .shape({
    thoughtsOfTakingOwnLifePast12Months: Yup.string()
      .oneOf(['yes', 'no'], 'validations.required')
      .required()
      .typeError('validations.required'),
    ifYesSpecifyHaveYouEverThoughtOfTakingYourLifeInPast12Months:
      Yup.string().when('thoughtsOfTakingOwnLifePast12Months', {
        is: value => value === 'yes',
        then: Yup.string().required('validations.required'),
      }),
    thoughtsOfTakingOthersLifePast12Months: Yup.string()
      .oneOf(['yes', 'no'], 'validations.required')
      .required()
      .typeError('validations.required'),
    ifYesSpecifyHaveYouEverThoughtOfTakingOthersLifeInPast12Months:
      Yup.string().when('thoughtsOfTakingOthersLifePast12Months', {
        is: value => value === 'yes',
        then: Yup.string().required('validations.required'),
      }),
  })
  .required();

export const homeAddressSchema = Yup.object().shape({
  street: Yup.string().required('validations.required'),
  apt: Yup.string(),
  city: Yup.string().required('validations.required'),
  state: Yup.string().required('validations.required'),
  zipcode: Yup.string()
    .required('validations.required')
    .matches(
      /^(\d{5}(-\d{4})?|\d{5}|\d{4}|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d)$/,
      'validations.zipcode',
    ),
});

export const emergencyContactSchema = Yup.object().shape({
  emergencyContactName: Yup.string().required('validations.required'),
  emergencyContactPhoneNumber: Yup.string().required('validations.required'),
  emergencyContactRelationship: Yup.string().required('validations.required'),
});

export const signatureSchema = Yup.object()
  .shape({
    signature: Yup.string().required(),
  })
  .required();
