import {
  DirectBookingFilterType,
  DirectBookingProviderType,
  DirectBookingSearchProviderParams,
  SearchedProvidersType,
} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

// Get Direct Booking Options Data
const getDirectBookingFilterData = async (): Promise<
  Response<{
    message: DirectBookingFilterType;
  }>
> => {
  try {
    const res = await requestHandler<{
      message: DirectBookingFilterType;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.DirectBookingOptions as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

// Get Direct Booking Searched Provider Data
interface QueryParam {
  paramName: string;
  paramValue: string;
}

const getQueryString = (params: QueryParam[] = []) => {
  const queryString = params
    .filter(param => param.paramName && param.paramValue)
    .map(param => `${param.paramName}=${param.paramValue}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};

const getDirectBookingSearchedProvidersData = async ({
  conditions,
  paymentType,
  state,
  country,
  profession,
  size,
  offset,
}: DirectBookingSearchProviderParams = {}): Promise<
  Response<{
    message: SearchedProvidersType;
  }>
> => {
  const queryParams = [
    {paramName: 'conditions', paramValue: conditions || ''},
    {paramName: 'payment_type', paramValue: paymentType || ''},
    {paramName: 'state', paramValue: state || ''},
    {paramName: 'country', paramValue: country || ''},
    {paramName: 'profession', paramValue: profession || ''},
    {paramName: 'size', paramValue: size || ''},
    {paramName: 'offset', paramValue: offset || ''},
  ];

  try {
    const res = await requestHandler<{
      message: SearchedProvidersType;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.DirectBookingSearchProviders}${getQueryString(
        queryParams,
      )}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getProviderById = async (
  providerId: string,
): Promise<
  Response<{
    message: DirectBookingProviderType;
  }>
> => {
  try {
    const res = await requestHandler<{
      message: DirectBookingProviderType;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.DirectBookingSearchProvider}/${providerId}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const DirectBookingService = {
  getDirectBookingFilterData,
  getDirectBookingSearchedProvidersData,
  getProviderById,
};
