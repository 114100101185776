import {StripePlanDurations, StripePlanNames} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';
import {StripeError, Token} from '@stripe/stripe-js';

export const resubscribe = createAction<{
  stripeResponse: {
    token?: Token | undefined;
    error?: StripeError | undefined;
  };
  paymentPlan:
    | StripePlanNames.mindfulness
    | StripePlanNames.uninsured
    | StripePlanNames.together
    | StripePlanNames.therapy
    | StripePlanNames.medicalCarePlusTherapy
    | StripePlanNames.medicalCare
    | StripePlanNames.newTherapy;
  paymentPlanDuration: StripePlanDurations;
}>('resubscribe');
export const resubscribeSuccess = createAction('resubscribeSuccess');
export const resubscribeFailure = createAction('resubscribeFailure');
