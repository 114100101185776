import React, {useEffect, useState} from 'react';
import {notificationActions} from 'features/Notification';
import {selectUserProfile} from 'features/User';
import {useUserNotifications} from 'hooks';
import {MemberProfile} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {getCountryDetailsByIsoCode} from 'utils';

import {Button} from '../Button';
import Modal from '../Modal';

export default function SessionRateChangeAlert() {
  const [show, setShow] = useState(false);
  const {notifications} = useUserNotifications();
  const {t} = useTranslation();
  const user = useSelector(selectUserProfile) as MemberProfile;
  const dispatch = useDispatch();

  const providerCurrency = getCountryDetailsByIsoCode(
    user?.countryOfResidence.code,
  );

  const sessionRateNotif = notifications.find(
    n => n.notificationType === 'session_rate_change',
  );

  useEffect(() => {
    if (
      sessionRateNotif &&
      sessionRateNotif.notificationId &&
      sessionRateNotif.state === 'unread'
    ) {
      setShow(true);
    }
  }, [sessionRateNotif]);

  const onOk = () => {
    setShow(false);
    if (sessionRateNotif?._id) {
      dispatch(
        notificationActions.openNotificationState({
          notificationId: sessionRateNotif.notificationId,
          entityId: sessionRateNotif._id,
          changes: {state: 'read'},
        }),
      );
    }
  };

  if (show) {
    return (
      <Modal
        messageType="none"
        isOpen={show}
        buttonFn={() => setShow(false)}
        floatingTitle={false}
        showCloseIcon={true}
        title={t('alert.sessionRate.title', 'Rate Change Alert')}
        classes="!p-0"
        titleClasses="text-base font-medium font-inter leading-tight text-gray-800"
        headerClasses="w-full h-16 pl-6 pr-4 flex justify-end items-center shadow-0"
        containerClasses="w-full max-h-[90vh] bg-white max-w-sm overflow-hidden transform rounded-[20px] text-left align-middle transition-all shadow-lg !rounded-2xl"
        footer={
          <Button className="w-full py-2" borderRadius="lg" onClick={onOk}>
            {t('ok', 'Ok!')}
          </Button>
        }
        footerClasses="h-[70px] !px-4"
        backdropClasses="bg-black/30"
      >
        <div className="p-5 space-y-10">
          <div className="w-fit mx-auto flex justify-center items-center gap-2.5 py-2.5 px-4 border rounded-[70px] mb-3">
            <img
              className="w-10 h-10 rounded-full"
              src={user?.therapistDetails?.image}
              alt="Therapist Photo"
            />
            <div className="space-y-1">
              <p className="flex flex-nowrap font-inter text-sm font-medium leading-5 text-gray-600">
                {t('alert.sessionRate.providerRate', {
                  providerName: user?.therapistDetails?.fullName.split(' ')[0],
                })}
              </p>
              <p className="flex flex-nowrap font-inter text-base font-semibold leading-5">
                <span className="text-gray-600 font-normal">
                  {providerCurrency?.currency ?? ''}&nbsp;
                  {providerCurrency?.currencySymbol ?? '$'}
                </span>
                &nbsp;
                {`${user?.therapistDetails?.sessionRate?.digitalPractice.min}-${user?.therapistDetails?.sessionRate?.digitalPractice.max}`}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
}
