import React from 'react';

import {SvgIcon, SvgIconProps} from './SvgIcon';

export const IdFrontIcon: SvgIcon = ({width = 30, height = 22}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H26C27.7949 0.75 29.25 2.20507 29.25 4V18C29.25 19.7949 27.7949 21.25 26 21.25H4C2.20507 21.25 0.75 19.7949 0.75 18V4Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M3.75 6C3.75 4.75736 4.75736 3.75 6 3.75H13C14.2426 3.75 15.25 4.75736 15.25 6V16C15.25 17.2426 14.2426 18.25 13 18.25H6C4.75736 18.25 3.75 17.2426 3.75 16V6Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <circle cx="9.5" cy="9.5" r="1.75" stroke="#ED82B3" strokeWidth="1.5" />
    <path
      d="M7.43412 13.5737C7.56436 12.5319 8.45002 11.75 9.5 11.75C10.55 11.75 11.4356 12.5319 11.5659 13.5737L12.1504 18.25H6.84959L7.43412 13.5737Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M18 6.75C18 6.33579 18.3358 6 18.75 6H26.25C26.6642 6 27 6.33579 27 6.75C27 7.16421 26.6642 7.5 26.25 7.5H18.75C18.3358 7.5 18 7.16421 18 6.75Z"
      fill="#ED82B3"
    />
    <path
      d="M18 9.75C18 9.33579 18.3358 9 18.75 9H26.25C26.6642 9 27 9.33579 27 9.75C27 10.1642 26.6642 10.5 26.25 10.5H18.75C18.3358 10.5 18 10.1642 18 9.75Z"
      fill="#ED82B3"
    />
    <path
      d="M18 12.75C18 12.3358 18.3358 12 18.75 12H23.25C23.6642 12 24 12.3358 24 12.75C24 13.1642 23.6642 13.5 23.25 13.5H18.75C18.3358 13.5 18 13.1642 18 12.75Z"
      fill="#ED82B3"
    />
  </svg>
);

export const IdBackIcon: SvgIcon = ({width = 30, height = 22}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4C0.75 2.20507 2.20507 0.75 4 0.75H26C27.7949 0.75 29.25 2.20507 29.25 4V18C29.25 19.7949 27.7949 21.25 26 21.25H4C2.20507 21.25 0.75 19.7949 0.75 18V4Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M3 5.75C3 5.33579 3.33579 5 3.75 5H26.25C26.6642 5 27 5.33579 27 5.75C27 6.16421 26.6642 6.5 26.25 6.5H3.75C3.33579 6.5 3 6.16421 3 5.75Z"
      fill="#ED82B3"
    />
  </svg>
);

export const PassportIcon: SvgIcon = ({width = 19, height = 29}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 4C0.75 2.20508 2.20507 0.75 4 0.75H15C16.7949 0.75 18.25 2.20507 18.25 4V25C18.25 26.7949 16.7949 28.25 15 28.25H4C2.20507 28.25 0.75 26.7949 0.75 25V4Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M3.75 6C3.75 4.75736 4.75736 3.75 6 3.75H13C14.2426 3.75 15.25 4.75736 15.25 6V16C15.25 17.2426 14.2426 18.25 13 18.25H6C4.75736 18.25 3.75 17.2426 3.75 16V6Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <circle cx="9.5" cy="9.5" r="1.75" stroke="#ED82B3" strokeWidth="1.5" />
    <path
      d="M7.43412 13.5737C7.56436 12.5319 8.45002 11.75 9.5 11.75C10.55 11.75 11.4356 12.5319 11.5659 13.5737L12.1504 18.25H6.84959L7.43412 13.5737Z"
      stroke="#ED82B3"
      strokeWidth="1.5"
    />
    <path
      d="M5 20.75C5 20.3358 5.33579 20 5.75 20H13.25C13.6642 20 14 20.3358 14 20.75C14 21.1642 13.6642 21.5 13.25 21.5H5.75C5.33579 21.5 5 21.1642 5 20.75Z"
      fill="#ED82B3"
    />
    <path
      d="M5 23.75C5 23.3358 5.33579 23 5.75 23H13.25C13.6642 23 14 23.3358 14 23.75C14 24.1642 13.6642 24.5 13.25 24.5H5.75C5.33579 24.5 5 24.1642 5 23.75Z"
      fill="#ED82B3"
    />
  </svg>
);

export const SmileIcon: React.FC<SvgIconProps> = ({
  width = 42,
  height = 42,
  fillColor = '#FFDDA1',
  strokeColor = 'black',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21" cy="21" r="21" fill={fillColor} />
      <path
        d="M9.37793 24C10.7101 29.1757 15.4084 33 20.9999 33C26.5914 33 31.2897 29.1757 32.6218 24"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <circle cx="13" cy="15" r="2" fill={strokeColor} />
      <circle cx="29" cy="15" r="2" fill={strokeColor} />
    </svg>
  );
};

export const SmileIcon2: React.FC<SvgIconProps> = ({
  width = 42,
  height = 42,
  fillColor = '#FFBF32',
  strokeColor = '#2F2F2F',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
    >
      <path
        d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z"
        fill={fillColor}
      />
      <mask
        id="mask0_4944_632"
        style={{maskType: 'alpha'}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="42"
        height="42"
      >
        <path
          d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z"
          fill="#FFEBBE"
        />
      </mask>
      <g mask="url(#mask0_4944_632)">
        <g filter="url(#filter0_f_4944_632)">
          <circle
            cx="4.45092"
            cy="24.0813"
            r="8.10326"
            fill="#ED82B3"
            fillOpacity="0.8"
          />
        </g>
        <g filter="url(#filter1_f_4944_632)">
          <circle
            cx="37.5486"
            cy="24.0813"
            r="8.10326"
            fill="#ED82B3"
            fillOpacity="0.8"
          />
        </g>
      </g>
      <path
        d="M19.2414 18.6373C19.2414 16.1603 17.2334 14.1523 14.7564 14.1523C12.2795 14.1523 10.2715 16.1603 10.2715 18.6373"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M31.5675 18.6373C31.5675 16.1603 29.5596 14.1523 27.0826 14.1523C24.6056 14.1523 22.5977 16.1603 22.5977 18.6373"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.16309 20.0869C6.16309 20.0869 8.30569 28.076 20.7718 28.076C33.2379 28.076 35.3805 20.0869 35.3805 20.0869"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_f_4944_632"
          x="-37.6523"
          y="-18.022"
          width="84.2061"
          height="84.2065"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_4944_632"
          />
        </filter>
        <filter
          id="filter1_f_4944_632"
          x="-4.55469"
          y="-18.022"
          width="84.2061"
          height="84.2065"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_4944_632"
          />
        </filter>
      </defs>
    </svg>
  );
};
