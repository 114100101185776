import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {CollaborationService} from 'services/api';

import {digitalPracticeActions} from './../digitalPracticeSlice';

const sendGreetingsEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.sendGreetings.match),
    switchMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(CollaborationService.sendGreetings(rest.data)).pipe(
        concatMap(() => [onSuccess()]),
        mergeMap(() => [digitalPracticeActions.sendGreetingsSuccess()]),
        catchError((message: string) =>
          concat(of(digitalPracticeActions.sendGreetingsFailure(message))).pipe(
            tap(() => onError()),
          ),
        ),
      ),
    ),
  );

const sendGreetingsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.sendGreetingsFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const collaborationEpics = [sendGreetingsEpic, sendGreetingsFailureEpic];
