import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  switchMap,
  tap,
} from 'rxjs/operators';
import {StripeService} from 'services';

import {appointmentActions} from '../appointmentSlice';

export const sendPaymentRequestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.sendPaymentRequest.match),
    switchMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(StripeService.sendPaymentRequest(rest)).pipe(
        concatMap(({data: message}) => {
          onSuccess();
          return of(appointmentActions.sendPaymentRequestSuccess(message));
        }),
        catchError((message: string) => {
          onError();
          return concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(appointmentActions.sendPaymentRequestFailure({message})),
          );
        }),
      ),
    ),
  );

export const sendPaymentRequestFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.sendPaymentRequestFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message: message.message});
      }
    }),
    ignoreElements(),
  );

export const paymentRequestEpics = [
  sendPaymentRequestEpic,
  sendPaymentRequestFailureEpic,
];
