import CancellationNotice from './CancellationNotice';
import ReasonForCancellationForm from './ReasonForCancellationForm';

export const cancellationSteps = {
  1: {
    1: CancellationNotice,
    2: ReasonForCancellationForm,
  },
  2: {
    1: ReasonForCancellationForm,
  },
};
