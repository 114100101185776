import {RefObject, useCallback, useEffect, useRef, useState} from 'react';

/**
 * Hook to get the width of a ref (HTML element)
 *
 * @usage
 * const {ref, width} = useElementRefSize();
 * <div ref={ref} />
 */
export function useElementRefSize(): {
  ref: RefObject<HTMLDivElement> | undefined;
  width: number;
} {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const updateWidth = useCallback(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useEffect(() => {
    updateWidth();

    if (ref.current) {
      const resizeObserver = new ResizeObserver(() => updateWidth());
      resizeObserver.observe(ref.current);

      return () => {
        resizeObserver.disconnect();
      };
    }

    return () => {};
  }, [ref, updateWidth]);

  return {width, ref};
}
