import {SendDigitalPracticeLinkToContactsData} from 'services/api/DigitalPractice';

import {createAction} from '@reduxjs/toolkit';

export const sendDigitalPracticeLinkToContacts = createAction<{
  data: SendDigitalPracticeLinkToContactsData;
  onSuccess?: VoidFunction;
  onError?: (message: string) => void;
}>('sendDigitalPracticeLinkToContacts');
export const sendDigitalPracticeLinkToContactsSuccess = createAction(
  'sendDigitalPracticeLinkToContactsSuccess',
);
export const sendDigitalPracticeLinkToContactsFailure = createAction<string>(
  'sendDigitalPracticeLinkToContactsFailure',
);
