import {InsuranceImageFormData} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const saveInsuranceImage = createAction<{
  insuranceImageData: InsuranceImageFormData;
  onboardStage?: number;
  fromSocialOnboarding: boolean;
}>('saveInsuranceImage');
export const saveInsuranceImageSuccess = createAction(
  'saveInsuranceImageSuccess',
);
export const saveInsuranceImageFailure = createAction(
  'saveInsuranceImageFailure',
);

export const updateInsuranceDetails = createAction('updateInsuranceDetails');
