import {
  FetchReceiptPdfArguments,
  FetchReceiptsResponse,
  UserRoles,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const fetchReceipts = createAction<{role: UserRoles}>('fetchReceipts');
export const fetchReceiptsFailure = createAction('fetchReceiptsFailure');
export const fetchReceiptsSuccess = createAction<FetchReceiptsResponse>(
  'fetchReceiptsSuccess',
);

type FetchReceiptPdfActionArguments = {
  onSuccess: (blob: Blob) => void;
  onError?: (error?: Error) => void;
} & FetchReceiptPdfArguments;

export const fetchReceiptPdf =
  createAction<FetchReceiptPdfActionArguments>('fetchPdf');
export const fetchReceiptPdfFailure = createAction<string>(
  'fetchReceiptPdfFailure',
);
export const fetchReceiptPdfSuccess = createAction('fetchReceiptPdfSuccess');
