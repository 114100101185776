import * as Yup from 'yup';

export const UpdatePhoneSchema = Yup.object()
  .shape({
    phoneNumber: Yup.string()
      .required('validations.phoneRequired')
      .test('invalid', 'validations.invalidPhoneNumber', phone =>
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
          phone || '',
        ),
      ),
  })
  .required();
