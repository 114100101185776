export enum IntercomEventTypes {
  ZD_REGISTER = 'ZD Registration',
  ACCOUNT_CREATED = 'Account Created',
  DATE_OF_BIRTH_ADDED = 'Date of Birth Added',
  CREDIT_CARD_ADDED = 'Credit Card Added',
  IN_DASHBOARD = 'In Dashboard',
  INSURANCE_CARD_UPLOADED = 'Insurance Card Uploaded',
  INSURANCE_INFORMATION_ADDED = 'Insurance Information Added',
  INSURANCE_ADDRESS_DETAILS_ADDED = 'Insurance Address Details Added',
  PVERIFY_VERIFICATION_SUCCESS = 'Pverify verification Successful ',
  PVERIFY_VERIFICATION_FAILED = 'Pverify Verification Failed',
  GOVERNMENT_ID_UPLOADED = 'Government ID Uploaded',
  PHONE_VERIFICATION_PENDING = 'Phone Verification Pending',
  PHONE_VERIFICATION_DONE = 'Phone Verification Done',
  PATIENT_CANCELLED_SUBSCRIPTION = 'Patient Cancelled Subscription',
  PATIENT_PLAN_IS_THERAPY = 'Patient Plan Updated To Therapy',
  PATIENT_PLAN_IS_MEDICALCARE = 'Patient Plan Updated To Medicalcare',
  PATIENT_PLAN_IS_MEDICALCARE_PLUS_THERAPY = 'Patient Plan Updated To Medicalcare + Therapy',
  PATIENT_PLAN_IS_TOGETHER = 'Patient Plan Update To Together',
  PATIENT_PLAN_IS_INACTIVE = 'Patient Plan Is Inactive',
  PATIENT_SESSION_ENDED = 'patient-session-ended',
  PATIENT_LOGGED_IN = 'patient-logged-in',
}
