import {
  BillingHistoryTypes,
  BillingLabels,
  ProviderProfile,
  ProviderRole,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const getAllProviderBills = createAction<string>('getAllProviderBills');
export const getAllProviderBillsSuccess = createAction<BillingLabels>(
  'getAllProviderBillsSuccess',
);
export const getAllProviderBillsFailure = createAction(
  'getAllProviderBillsFailure',
);

export const getBillingHistory = createAction<{
  pageNo: number;
  size: number;
  roomMode: 'normal' | 'group';
}>('getBillingHistory');
export const getBillingHistoryFailure = createAction<{
  roomMode: 'normal' | 'group';
}>('getBillingHistoryFailure');
export const getBillingHistorySuccess = createAction<{
  roomMode: 'normal' | 'group';
  result: {
    data: BillingHistoryTypes[];
    hasMore: boolean;
    totalCount: number;
    nextPage: number;
    previousPage: number;
  };
}>('getBillingHistorySuccess');

export const setPlatformFeePercentage = createAction<{
  feePercentage: number;
  role: ProviderRole;
}>('setPlatformFeePercentage');
export const setPlatformFeePercentageSuccess = createAction<ProviderProfile>(
  'setPlatformFeePercentageSuccess',
);
export const setPlatformFeePercentageFailure = createAction(
  'setPlatformFeePercentageFailure',
);

export const setDigitalPracticeSessionRate = createAction<{
  role: ProviderRole;
  min: number;
  max: number;
}>('SetDigitalPracticeSessionRate');
export const setDigitalPracticeSessionRateFailure = createAction(
  'SetDigitalPracticeSessionRateFailure',
);
export const setDigitalPracticeSessionRateSuccess = createAction<{
  min: number;
  max: number;
}>('SetDigitalPracticeSessionRateSuccess');
