import {userActions} from 'features/User/userSlice';
import {SliceStatus} from 'interfaces';

import {
  ActionReducerMapBuilder,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

import {
  admitRequestStatusNotification,
  emitAdmitRequestNotification,
  emitVideoPresenceNotification,
  participantJoinedNotification,
  setAdmitRequestStatus,
} from './Notifications/notificationsActions';

import {rateVideoCall} from 'Rating/ratingActions';

export const providerStartCall = createAction<{
  patientId: string;
  callStarted: boolean;
}>('providerStartCall');

type VideoSliceState = {
  participantJoined: boolean;
  status: SliceStatus;
  error: string;
  admissionStatus: string;
  rateVideoCall: {
    appointmentID: string;
  };
};

const videoSlice = createSlice({
  name: 'video',
  initialState: {
    participantJoined: false,
    status: SliceStatus.idle,
    error: '',
    admissionStatus: '',
    rateVideoCall: {
      appointmentID: '',
    },
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<VideoSliceState>) =>
    builder
      .addCase(participantJoinedNotification, (state, action) => ({
        ...state,
        participantJoined: action.payload,
      }))
      .addCase(setAdmitRequestStatus, (state, action) => ({
        ...state,
        admissionStatus: action.payload,
      }))
      .addCase(userActions.setAsyncError, (state, action) => ({
        ...state,
        error:
          action.payload.filter === 'video'
            ? action.payload?.message
            : state.error,
      }))
      .addCase(userActions.resetAsyncError, (state, action) => ({
        ...state,
        error: action.payload === 'video' ? '' : state.error,
      }))
      .addCase(rateVideoCall, (state, action) => ({
        ...state,
        rateVideoCall: {appointmentID: action.payload.appointmentID},
      }))
      .addDefaultCase(state => state),
});

export const {reducer: videoReducer, name: videoReducerName} = videoSlice;

export type TVideoActions = ReturnType<
  | typeof participantJoinedNotification
  | typeof emitVideoPresenceNotification
  | typeof emitAdmitRequestNotification
  | typeof admitRequestStatusNotification
  | typeof setAdmitRequestStatus
  | typeof providerStartCall
  | typeof rateVideoCall
>;

export const videoActions = {
  participantJoinedNotification,
  emitVideoPresenceNotification,
  emitAdmitRequestNotification,
  admitRequestStatusNotification,
  setAdmitRequestStatus,
  providerStartCall,
  rateVideoCall,
};

export type VideoState = ReturnType<typeof videoReducer>;
