import React, {FC, useEffect} from 'react';
import {
  selectDigitalPracticeProvider,
  selectDigitalPracticeProviderStatus,
} from 'app/selectors';
import {uiActions} from 'app/uiReducer';
import {selectUserProfile, userActions} from 'features/User';
import {DigitalPracticeProvider, MemberProfile, SliceStatus} from 'interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useLocation, useParams} from 'react-router';
import {getLanguageFromCountry} from 'utils';

export const withDigitalPracticeProvider: (
  Component: FC<{
    provider: DigitalPracticeProvider;
    providerLoadingStatus: SliceStatus;
  }>,
) => () => JSX.Element = Component => {
  const WithDigitalPracticeProviderWrapper = () => {
    const dispatch = useDispatch();
    const {providerId} = useParams<{providerId: string}>();

    const user = useSelector(selectUserProfile) as MemberProfile;
    const location = useLocation();

    const provider = useSelector(selectDigitalPracticeProvider);
    const providerLoadingStatus = useSelector(
      selectDigitalPracticeProviderStatus,
    );

    /**
     * redirect user to dashboard if they have verified the phone number.
     * this check applies only for digital practice member if they try to access
     * the practice options page after successful registration
     */
    if (user?.isPhoneVerified) {
      return <Redirect to="/dashboard" />;
    }

    useEffect(() => {
      if (provider?.countryOfResidence) {
        dispatch(
          userActions.setUser({
            ...user,
            preferredLanguage:
              getLanguageFromCountry(provider?.countryOfResidence?.code) ??
              'en',
          }),
        );
      }
    }, [provider]);

    useEffect(() => {
      const isOnRegisterPage = location.pathname.includes(
        '/practice/register/',
      );

      if (providerId)
        dispatch(
          uiActions.getDigitalPracticeProviderData({
            providerId,
            isReferralLink: isOnRegisterPage,
          }),
        );
    }, [providerId]);

    return (
      <Component
        provider={provider}
        providerLoadingStatus={providerLoadingStatus}
      />
    );
  };

  return WithDigitalPracticeProviderWrapper;
};
