import {UploadOptions} from './Pages/Register.types';

export enum FileTypes {
  // Images and Videos
  image = 'image/*',
  video = 'video/*',
  audio = 'audio/*',

  // Document formats
  pdf = 'application/pdf',
  word = 'application/msword',
  wordx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  excel = 'application/vnd.ms-excel',
  excelx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  powerpoint = 'application/vnd.ms-powerpoint',
  powerpointx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  text = 'text/plain',
  rtf = 'application/rtf',
  csv = 'text/csv',

  // Web and Data formats
  html = 'text/html',
  json = 'application/json',
  xml = 'application/xml',
}

export const typesOfDocuments = `${FileTypes.pdf},${FileTypes.word},${FileTypes.wordx},${FileTypes.excel},${FileTypes.excelx},${FileTypes.powerpoint},${FileTypes.powerpointx}`;
export const Map_DocumentToAcceptableType = {
  [UploadOptions.professionalHeadshot]: FileTypes.image,
  [UploadOptions.resume]: typesOfDocuments,
};
export const UploadOptionFileType = {
  [UploadOptions.professionalHeadshot]: 'image',
  [UploadOptions.resume]: 'document',
};
