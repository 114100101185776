import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {
  catchError,
  concat,
  filter,
  from,
  ignoreElements,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {CurrencyService} from 'services/api/Currency';

import {currencyActions} from './currencySlice';

export const getCurrencyRateByCodeEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(currencyActions.getCurrencyRateByCode.match),
    switchMap(action =>
      from(CurrencyService.getCurrencyRateByCode(action.payload.code)).pipe(
        mergeMap(message => [
          currencyActions.getCurrencyRateByCodeSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(currencyActions.getCurrencyRateByCodeFailure(message)),
            of(
              userActions.setAsyncError({
                filter: 'currency',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

const getCurrencyRateByCodeFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(currencyActions.getCurrencyRateByCodeFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const currencyEpics = [
  getCurrencyRateByCodeEpic,
  getCurrencyRateByCodeFailureEpic,
];
