import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {SliceStatus} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of, tap} from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  ignoreElements,
  map,
  mergeMap,
} from 'rxjs/operators';
import {AvailabilityService} from 'services';

import {availabilityActions} from './availabilitySlice';

const getProviderAvailabilityHoursEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.getProviderAvailabilityHours.match),
    mergeMap(({payload: role}) =>
      from(AvailabilityService.getProviderAvailabilityHours(role)).pipe(
        mergeMap(data => [
          availabilityActions.getProviderAvailabilityHoursSuccess(data),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'availability',
                message,
              }),
            ),
            of(
              availabilityActions.getProviderAvailabilityHoursFailure(message),
            ),
          ),
        ),
      ),
    ),
  );

const getProviderAvailabilityHoursFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.getProviderAvailabilityHoursFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const saveProviderAvailabilityHoursEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.saveProviderRecurringAvailability.match),
    mergeMap(({payload: {cb, ...rest}}) =>
      from(AvailabilityService.saveProviderAvailabilityHours(rest)).pipe(
        concatMap(data => {
          cb(SliceStatus.resolved);
          return of(
            availabilityActions.saveProviderRecurringAvailabilitySuccess(data),
          );
        }),
        catchError((message: string) => {
          cb(SliceStatus.rejected);
          return concat(
            of(
              userActions.setAsyncError({
                filter: 'availability',
                message,
              }),
            ),
            of(
              availabilityActions.saveProviderRecurringAvailabilityFailure(
                message,
              ),
            ),
          );
        }),
      ),
    ),
  );

const saveProviderAvailabilityHoursSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.saveProviderRecurringAvailability.match),
    delay(4000),
    map(() => availabilityActions.resetProviderAvailabilitySaveStatus()),
  );

const saveProviderAvailabilityHoursFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.saveProviderRecurringAvailabilityFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const blockProviderAvailabilityEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.blockProviderAvailability.match),
    mergeMap(({payload: {cb, ...rest}}) =>
      from(AvailabilityService.blockProviderAvailability(rest)).pipe(
        concatMap(data => {
          if (data.error) {
            cb(SliceStatus.rejected);
            return of(
              availabilityActions.blockProviderAvailabilityFailure(data.error),
            );
          }
          cb(SliceStatus.resolved);
          return of(
            availabilityActions.blockProviderAvailabilitySuccess(data),
            availabilityActions.getProviderAvailabilityHours(rest.role),
          );
        }),
        catchError((message: string) => {
          cb(SliceStatus.rejected);
          return concat(
            of(
              userActions.setAsyncError({
                filter: 'availability',
                message,
              }),
            ),
            of(availabilityActions.blockProviderAvailabilityFailure(message)),
          );
        }),
      ),
    ),
  );

const blockProviderAvailabilityFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.blockProviderAvailabilityFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const blockProviderAvailabilitySuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.blockProviderAvailabilitySuccess.match),
    tap(({payload: {description}}) => {
      Toast({
        type: 'success',
        message: i18next.t('availabilityEpic.blockedOffTime', {
          description,
        }),
      });
    }),
    ignoreElements(),
  );

export const unblockProviderAvailabilityEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.unblockProviderAvailability.match),
    mergeMap(({payload: {blockId, role, cb}}) =>
      from(
        AvailabilityService.unblockProviderAvailability({blockId, role}),
      ).pipe(
        concatMap(({message}) => {
          cb && cb(SliceStatus.resolved);
          return of(
            availabilityActions.unblockProviderAvailabilitySuccess({
              message,
              blockId,
            }),
          );
        }),
        catchError((message: string) => {
          cb && cb(SliceStatus.rejected);
          return concat(
            of(
              userActions.setAsyncError({
                filter: 'availability',
                message,
              }),
            ),
            of(availabilityActions.unblockProviderAvailabilityFailure(message)),
          );
        }),
      ),
    ),
  );

const unblockProviderAvailabilityFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.unblockProviderAvailabilityFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const unblockProviderAvailabilitySuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.unblockProviderAvailabilitySuccess.match),
    tap(({payload}) => {
      Toast({type: 'success', message: payload.message});
    }),
    ignoreElements(),
  );

export const setSpecificDayAvailabilityEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.setSpecificDayAvailability.match),
    mergeMap(({payload: {cb, ...rest}}) =>
      from(AvailabilityService.setSpecificDayAvailability(rest)).pipe(
        concatMap(({message}) => {
          cb(SliceStatus.resolved);
          return of(
            availabilityActions.setSpecificDayAvailabilitySuccess(message),
            availabilityActions.getProviderAvailabilityHours(rest.role),
          );
        }),
        catchError((message: string) => {
          cb(SliceStatus.rejected);
          return concat(
            of(
              userActions.setAsyncError({
                filter: 'availability',
                message,
              }),
            ),
            of(availabilityActions.setSpecificDayAvailabilityFailure(message)),
          );
        }),
      ),
    ),
  );

const setSpecificDayAvailabilityFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.setSpecificDayAvailabilityFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

const setSpecificDayAvailabilitySuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(availabilityActions.setSpecificDayAvailabilitySuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('availabilityEpic.specificAvailabilitySuccess'),
      });
    }),
    ignoreElements(),
  );

export const providerAvailabilityEpics = [
  getProviderAvailabilityHoursEpic,
  getProviderAvailabilityHoursFailureEpic,
  saveProviderAvailabilityHoursEpic,
  saveProviderAvailabilityHoursSuccessEpic,
  saveProviderAvailabilityHoursFailureEpic,
  blockProviderAvailabilityEpic,
  blockProviderAvailabilityFailureEpic,
  blockProviderAvailabilitySuccessEpic,
  unblockProviderAvailabilityEpic,
  unblockProviderAvailabilityFailureEpic,
  unblockProviderAvailabilitySuccessEpic,
  setSpecificDayAvailabilityEpic,
  setSpecificDayAvailabilityFailureEpic,
  setSpecificDayAvailabilitySuccessEpic,
].flatMap(epic => epic);
