export enum CalendarMode {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export const durationEventColor = {
  '15': {
    backgroundColor: '#EAFAFD',
    color: '#1399C3',
  },
  '30': {
    backgroundColor: '#FCEAF7',
    color: '#DD53CF',
  },
  '45': {
    backgroundColor: '#FFF7E6',
    color: '#C0922A',
  },
  '60': {
    backgroundColor: '#EAEFFD',
    color: '#2E62EC',
  },
};

export const selectedEventColor = {
  backgroundColor: '#2E62EC',
  color: '#fff',
};

export const twoMonthsFromCurrentMonth = new Date(Date.now() + 5270400000);
