import * as Yup from 'yup';

export const CardHolderNameSchema = Yup.object().shape({
  cardHolderName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .test('invalid', 'Must include first and last name', name =>
      /^[a-zA-Z]+ [a-zA-Z]+/.test(name || ''),
    ),
  couponCode: Yup.string().optional(),
});
