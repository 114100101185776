import {RootState} from 'app/rootReducer';
import {AppDispatch} from 'app/store';
import {
  ChatParticipant,
  CometChatMessageType,
  CometFriend,
  LastMessage,
} from 'interfaces';
import {MessagingService} from 'services/api';

import {CometChat} from '@cometchat-pro/chat';
import {AsyncThunk, createAction, createAsyncThunk} from '@reduxjs/toolkit';

import {messagingActions} from './messagingSlice';

export const loginUserOnCometChat: AsyncThunk<
  CometChat.User | undefined,
  {authToken: string; cb?: () => void},
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  CometChat.User | undefined,
  {authToken: string; cb?: () => void},
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'cometchat/loginUserOnCometChat',
  async ({authToken, cb}, {rejectWithValue, dispatch}) => {
    try {
      const user = await CometChat.login(authToken);
      if (window.location.pathname !== '/message') {
        dispatch(messagingActions.getUnreadMessageCount());
      }
      if (typeof cb === 'function') {
        cb();
      }
      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getFriendsList: AsyncThunk<
  {result: CometFriend[]; isNext: boolean},
  {next: boolean} | undefined,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  {result: CometFriend[]; isNext: boolean},
  {next: boolean} | undefined,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('cometchat/getFriendsList', async (payload, {rejectWithValue}) => {
  try {
    const result = await MessagingService.getUserList(payload?.next);
    return {
      result,
      isNext: !!payload?.next,
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMessageHistory: AsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  string,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'cometchat/getMessageHistory',
  async (participantUID, {rejectWithValue, getState}) => {
    try {
      const messages = getState().messaging.messages.data;
      const res = await MessagingService.getMessageHistory(participantUID);
      return {...res, data: [...messages, ...res.data]};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMoreMessageHistory: AsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  {participantUID: string; lastMessageId: string},
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  {participantUID: string; lastMessageId: string},
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'cometchat/getMoreMessageHistory',
  async ({participantUID, lastMessageId}, {rejectWithValue, getState}) => {
    try {
      const messages = getState().messaging.messages.data;
      const res = await MessagingService.getMoreMessageHistory(
        participantUID,
        lastMessageId,
      );
      return {...res, data: [...messages, ...res.data]};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addMessage: AsyncThunk<
  CometChatMessageType[],
  CometChatMessageType,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  CometChatMessageType[],
  CometChatMessageType,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('cometchat/addMessage', (message, {rejectWithValue, getState}) => {
  try {
    const messagesCpy = [...getState().messaging.messages.data];
    return [message, ...messagesCpy];
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateMessage: AsyncThunk<
  CometChatMessageType[],
  CometChatMessageType,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  CometChatMessageType[],
  CometChatMessageType,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('cometchat/updateMessage', (message, {rejectWithValue, getState}) => {
  try {
    const messagesCpy = [...getState().messaging.messages.data];
    const msgIdx = messagesCpy.findIndex(m => m.id === message.id);
    messagesCpy[msgIdx] = message;
    return messagesCpy;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteMessage: AsyncThunk<
  CometChatMessageType[],
  string,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  CometChatMessageType[],
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('cometchat/updateMessage', (messageId, {rejectWithValue, getState}) => {
  try {
    const messagesCpy = [...getState().messaging.messages.data];
    const msgIdx = messagesCpy.findIndex(m => m.id === messageId);
    messagesCpy.splice(msgIdx, 1);
    return messagesCpy;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUnreadMessageCount: AsyncThunk<
  number,
  void,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  number,
  void,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>('cometchat/getUnreadMessageCount', async (_, {rejectWithValue}) => {
  try {
    const res = await MessagingService.getUnreadMessageCount();
    return res;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMediaMessageHistory: AsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  string,
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  string,
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'cometchat/getMediaMessageHistory',
  async (participantUID, {rejectWithValue, getState}) => {
    try {
      const messages = getState().messaging.messages.data;
      const res = await MessagingService.getMediaMessageHistory(participantUID);
      return {...res, data: [...messages, ...res.data]};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getMoreMediaMessageHistory: AsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  {participantUID: string; lastMessageId: string},
  {
    state: RootState;
    rejectValue: string;
  }
> = createAsyncThunk<
  {
    hasMore: boolean;
    lastMessageId: any;
    data: CometChatMessageType[];
  },
  {participantUID: string; lastMessageId: string},
  {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'cometchat/getMoreMediaMessageHistory',
  async ({participantUID, lastMessageId}, {rejectWithValue, getState}) => {
    try {
      const mediaMessages = getState().messaging.mediaMessages.data;
      const res = await MessagingService.getMoreMediaMessageHistory(
        participantUID,
        lastMessageId,
      );
      return {...res, data: [...mediaMessages, ...res.data]};
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const resetMediaMessageHistory = createAction(
  'cometchat/resetMediaMessageHistory',
);
export const cometChatLogOut = createAction('cometchat/logOut');
export const cometChatLogOutFailure = createAction('cometchat/logOutFailure');
export const cometChatLogOutSuccess = createAction('cometchat/logOutSuccess');

export const setUnreadMessageCount = createAction<number>(
  'cometchat/setUnreadMessageCount',
);
export const resetUnreadMessageCount = createAction(
  'cometchat/resetUnreadMessageCount',
);

export const resetMessageHistory = createAction(
  'cometchat/resetMessageHistory',
);

export const searchFriends = createAction<{next?: boolean; keyword: string}>(
  'cometchat/searchFriends',
);
export const searchFriendsFailure = createAction(
  'cometchat/searchFriendsFailure',
);
export const searchFriendsSuccess = createAction<{
  result: CometFriend[];
  isNext?: boolean;
}>('cometchat/searchFriendsSuccess');

export const resetSearchedFriendsList = createAction(
  'cometchat/resetSearchedFriendsList',
);

export const setLastMessage = createAction<{
  uid: string;
  data: LastMessage;
}>('cometchat/setLastMessage');

export const setParticipant = createAction<ChatParticipant>(
  'cometchat/setParticipant',
);
export const resetParticipant = createAction('cometchat/resetParticipant');

export const setUnreadCount = createAction<string>('cometchat/setUnreadCount');
export const resetUnreadCount = createAction<string>(
  'cometchat/resetUnreadCount',
);

// add searched friends to the list if it doesn't exists
export const addFriend = createAction<CometFriend>('cometchat/addFriend');

export const setUserPresenceStatus = createAction<{
  uid: string;
  status: 'online' | 'offline';
  lastActiveAt: number;
}>('cometchat/setUserPresenceStatus');

export const setUserTypingStatus = createAction<{
  uid: string;
  typing: boolean;
}>('cometchat/setUserTypingStatus');

export const markMessageAsDelivered = createAction<{
  uid: string;
  messageId: string;
  deliveredAt: number;
}>('cometchat/markMessageAsDelivered');

export const markMessageAsRead = createAction<{
  uid: string;
  messageId: string;
  readAt: number;
}>('cometchat/markMessageAsRead');
