import {TFunction} from 'i18next';

export const cancelAppointmentOptions = (
  t: TFunction<'translatin', undefined>,
) => [
  {
    value: t(
      'cancelAppointmentOptions.Medical Emergency',
      'Medical Emergency: I truly apologize for missing our scheduled time. I had an unexpected medical emergency and needed immediate attention.',
    ),
    label: t(
      'cancelAppointmentOptions.Medical Emergency',
      'Medical Emergency: I truly apologize for missing our scheduled time. I had an unexpected medical emergency and needed immediate attention.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Patient Emergency',
      'Patient Emergency: I’m so sorry for not being present. A critical situation arose with one of my patients that I absolutely had to attend to.',
    ),
    label: t(
      'cancelAppointmentOptions.Patient Emergency',
      'Patient Emergency: I’m so sorry for not being present. A critical situation arose with one of my patients that I absolutely had to attend to.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Unforeseen Circumstances',
      'Unforeseen Circumstances: Regrettably, I couldn’t make it due to some personal emergencies that came up unexpectedly. I hope you understand.',
    ),
    label: t(
      'cancelAppointmentOptions.Unforeseen Circumstances',
      'Unforeseen Circumstances: Regrettably, I couldn’t make it due to some personal emergencies that came up unexpectedly. I hope you understand.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Transportation Issues',
      'Transportation Issues: I did plan to be there on time, but my car had a sudden malfunction and public transportation was delayed. I sincerely apologize for the inconvenience.',
    ),
    label: t(
      'cancelAppointmentOptions.Transportation Issues',
      'Transportation Issues: I did plan to be there on time, but my car had a sudden malfunction and public transportation was delayed. I sincerely apologize for the inconvenience.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Illness',
      'Illness: Unfortunately, I wasn’t feeling well today. I thought it best to stay put.',
    ),
    label: t(
      'cancelAppointmentOptions.Illness',
      'Illness: Unfortunately, I wasn’t feeling well today. I thought it best to stay put.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Double-Booked',
      'Double-Booked: There was an oversight on my end, and I mistakenly scheduled two appointments at the same time. I sincerely apologize for this confusion.',
    ),
    label: t(
      'cancelAppointmentOptions.Double-Booked',
      'Double-Booked: There was an oversight on my end, and I mistakenly scheduled two appointments at the same time. I sincerely apologize for this confusion.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Communication Error',
      'Communication Error: I regret the mix-up. There was a misunderstanding about our appointment’s time and location. I take full responsibility for the confusion.',
    ),
    label: t(
      'cancelAppointmentOptions.Communication Error',
      'Communication Error: I regret the mix-up. There was a misunderstanding about our appointment’s time and location. I take full responsibility for the confusion.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Family Obligations',
      'Family Obligations: Unexpected family matters arose that I had to attend to. My sincerest apologies for not being available.',
    ),
    label: t(
      'cancelAppointmentOptions.Family Obligations',
      'Family Obligations: Unexpected family matters arose that I had to attend to. My sincerest apologies for not being available.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Workload',
      'Workload: It turned out to be an incredibly demanding day with back-to-back patient care and other duties. I regret not being able to manage our scheduled time.',
    ),
    label: t(
      'cancelAppointmentOptions.Workload',
      'Workload: It turned out to be an incredibly demanding day with back-to-back patient care and other duties. I regret not being able to manage our scheduled time.',
    ),
  },
  {
    value: t(
      'cancelAppointmentOptions.Technical Glitch',
      'Technical Glitch: I apologize for the disruption. We experienced some technical issues with our electronic health record system, causing unexpected delays in my schedule.',
    ),
    label: t(
      'cancelAppointmentOptions.Technical Glitch',
      'Technical Glitch: I apologize for the disruption. We experienced some technical issues with our electronic health record system, causing unexpected delays in my schedule.',
    ),
  },
  {
    value: 'other',
    label: t('otherReason', 'Other Reason'),
  },
];
