import React, {FC} from 'react';
import {AudioIcon, FileIcon, PhotoIcon, VideoIcon} from 'components';
import {SvgIconProps} from 'components/Basic/SvgIcon/SvgIcon';

export const Icon: FC<{name: string; color?: boolean} & SvgIconProps> = ({
  name,
  color = false,
  ...res
}) => {
  switch (name?.toLowerCase()) {
    case 'image':
      return <PhotoIcon {...(color && {strokeColor: ''})} {...res} />;
    case 'audio':
      return <AudioIcon {...(color && {strokeColor: ''})} {...res} />;
    case 'video':
      return <VideoIcon {...(color && {strokeColor: ''})} {...res} />;
    default:
      return <FileIcon {...(color && {strokeColor: ''})} {...res} />;
  }
};
