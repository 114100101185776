import {SliceStatus} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  ExercisesPaginationType,
  ExerciseType,
  GeneratedExerciseData,
} from './utils/constants';
import {FormSteps} from './CreateExercise';
import {
  generateExercise,
  generateExerciseFailure,
  generateExerciseSuccess,
  getExercises,
  getExercisesFailure,
  getExercisesSuccess,
  publishExercise,
  publishExerciseFailure,
  publishExerciseSuccess,
  regenerateExerciseQuestion,
  regenerateExerciseQuestionFailure,
  regenerateExerciseQuestionSuccess,
  resetExercises,
  resetWellniteExercise,
  saveExerciseDraft,
  sendInitialExerciseRequest,
  sendInitialExerciseRequestFailure,
  sendInitialExerciseRequestSuccess,
  updateExerciseAnswers,
  updateExerciseAnswersFailure,
  updateExerciseAnswersSuccess,
} from './wellniteExerciseActions';

export type WellniteExerciseSliceState = {
  status: SliceStatus;
  formStep?: FormSteps;
  exercise: ExerciseType;
  generatedExercise: GeneratedExerciseData;
  questions?: string[];
  regenerateExerciseStatus?: SliceStatus;
  error: string;
  exercises: {
    exercises: ExerciseType[];
    pagination: ExercisesPaginationType;
  };
  exercisesStatus: SliceStatus;
};

export const wellniteExerciseSliceInitialState: WellniteExerciseSliceState = {
  status: SliceStatus.idle,
  exercise: {} as ExerciseType,
  generatedExercise: {} as GeneratedExerciseData,
  regenerateExerciseStatus: SliceStatus.idle,
  error: '',
  exercises: {
    exercises: [],
    pagination: {
      currentPage: 1,
      hasNextPage: true,
      totalExercises: 0,
      nextPage: 1,
    },
  },
  exercisesStatus: SliceStatus.idle,
};

const wellniteExerciseSlice = createSlice({
  name: 'wellniteExercise',
  initialState: wellniteExerciseSliceInitialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<WellniteExerciseSliceState>,
  ) =>
    builder
      .addCase(generateExercise, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(generateExerciseFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(generateExerciseSuccess, (state, {payload}) => ({
        ...state,
        generatedExercise: payload,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(regenerateExerciseQuestion, state => ({
        ...state,
        status: SliceStatus.pending,
        regenerateExerciseStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(regenerateExerciseQuestionFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        regenerateExerciseStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(regenerateExerciseQuestionSuccess, (state, {payload}) => ({
        ...state,
        questions: payload.questions,
        regenerateExerciseStatus: SliceStatus.resolved,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(saveExerciseDraft, (state, action) => ({
        ...state,
        generatedExercise: {
          ...state.generatedExercise,
          ...action.payload,
        },
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(publishExercise, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(publishExerciseFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(publishExerciseSuccess, (state, {payload}) => ({
        ...state,
        exercise: payload,
        exercises: {
          ...state.exercises,
          exercises: [payload, ...state.exercises.exercises],
        },
        formStep: FormSteps.generateExerciseTemplate,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(resetWellniteExercise, state => ({
        ...state,
        generatedExercise: {} as GeneratedExerciseData,
        status: SliceStatus.idle,
        error: '',
      }))
      .addCase(updateExerciseAnswers, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(updateExerciseAnswersFailure, (state, {payload}) => ({
        ...state,
        status: SliceStatus.rejected,
        error: payload,
      }))
      .addCase(updateExerciseAnswersSuccess, (state, {payload}) => ({
        ...state,
        exercise: {
          ...state.exercise,
          ...payload,
        },
        exercises: {
          ...state.exercises,
          exercises: state.exercises.exercises.map(exercise =>
            exercise._id === payload._id ? {...exercise, ...payload} : exercise,
          ),
        },
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(sendInitialExerciseRequest, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(sendInitialExerciseRequestFailure, (state, {payload}) => ({
        ...state,
        status: SliceStatus.rejected,
        error: payload,
      }))
      .addCase(sendInitialExerciseRequestSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(getExercises, state => ({
        ...state,
        exercisesStatus: SliceStatus.pending,
      }))
      .addCase(getExercisesFailure, state => ({
        ...state,
        exercisesStatus: SliceStatus.rejected,
      }))
      .addCase(getExercisesSuccess, (state, action) => ({
        ...state,
        exercisesStatus: SliceStatus.resolved,
        exercises: {
          exercises: [
            ...state.exercises.exercises,
            ...action.payload.exercises,
          ],
          pagination: action.payload.pagination,
        },
      }))
      .addCase(resetExercises, state => ({
        ...state,
        exercisesStatus: SliceStatus.idle,
        exercises: {
          exercises: [],
          pagination: {
            currentPage: 1,
            hasNextPage: true,
            totalExercises: 0,
            nextPage: 1,
          },
        },
      })),
});

export const {
  reducer: wellniteExerciseReducer,
  name: wellniteExerciseReducerName,
} = wellniteExerciseSlice;

export type TWellniteExerciseActions =
  | ReturnType<typeof generateExercise>
  | ReturnType<typeof generateExerciseFailure>
  | ReturnType<typeof generateExerciseSuccess>
  | ReturnType<typeof regenerateExerciseQuestion>
  | ReturnType<typeof regenerateExerciseQuestionFailure>
  | ReturnType<typeof regenerateExerciseQuestionSuccess>
  | ReturnType<typeof saveExerciseDraft>
  | ReturnType<typeof publishExercise>
  | ReturnType<typeof publishExerciseFailure>
  | ReturnType<typeof publishExerciseSuccess>
  | ReturnType<typeof resetWellniteExercise>
  | ReturnType<typeof getExercises>
  | ReturnType<typeof getExercisesFailure>
  | ReturnType<typeof getExercisesSuccess>
  | ReturnType<typeof resetExercises>
  | ReturnType<typeof updateExerciseAnswers>
  | ReturnType<typeof updateExerciseAnswersFailure>
  | ReturnType<typeof updateExerciseAnswersSuccess>
  | ReturnType<typeof sendInitialExerciseRequest>
  | ReturnType<typeof sendInitialExerciseRequestFailure>
  | ReturnType<typeof sendInitialExerciseRequestSuccess>;

export const wellniteExerciseActions = {
  generateExercise,
  generateExerciseFailure,
  generateExerciseSuccess,
  regenerateExerciseQuestion,
  regenerateExerciseQuestionFailure,
  regenerateExerciseQuestionSuccess,
  saveExerciseDraft,
  publishExercise,
  publishExerciseFailure,
  publishExerciseSuccess,
  resetWellniteExercise,
  getExercises,
  getExercisesFailure,
  getExercisesSuccess,
  resetExercises,
  updateExerciseAnswers,
  updateExerciseAnswersFailure,
  updateExerciseAnswersSuccess,
  sendInitialExerciseRequest,
  sendInitialExerciseRequestFailure,
  sendInitialExerciseRequestSuccess,
};

export type WellniteExerciseState = ReturnType<typeof wellniteExerciseReducer>;
