import React, {FC} from 'react';
import {DefaultLayout, MotionDiv} from 'components';
import {LayoutProps} from 'components/Layouts/Layout.types';
import {selectUserProfile} from 'features/User';
import {UserRoles} from 'interfaces';
import {useSelector} from 'react-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';

import {RouteTransition} from './AnimatedRoutes';

export const PrivateRoute: FC<{
  component: FC<RouteComponentProps>;
  authorizedRoles: UserRoles[];
  path: string | string[];
  layout?: FC<LayoutProps>;
  layoutProps?: {bgColor?: string; lgColumns?: string};
  exact?: boolean;
}> = ({
  component: Component,
  authorizedRoles,
  layout: ComponentLayout,
  layoutProps,
  ...rest
}) => {
  const user = useSelector(selectUserProfile);
  const Layout = ComponentLayout || DefaultLayout;
  const layoutStyles = {
    lgColumns: layoutProps?.lgColumns || 'lg:grid-cols-4',
    bgColor: layoutProps?.bgColor || 'bg-white',
  };

  const renderPrivateComponent: FC<RouteComponentProps> = props => {
    if (!user)
      return (
        <MotionDiv innerWidth={769}>
          <Redirect
            to={{
              pathname: '/login',
              state: {from: props.location.pathname},
            }}
          />
        </MotionDiv>
      );
    if (authorizedRoles.includes(user.role))
      return (
        <MotionDiv innerWidth={769}>
          <Layout {...layoutStyles}>
            <Component {...props} />
          </Layout>
        </MotionDiv>
      );

    return (
      <MotionDiv innerWidth={769}>
        <Redirect to="/" />
      </MotionDiv>
    );
  };
  return <RouteTransition {...rest} render={renderPrivateComponent} />;
};
