import {CurrencyRate} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const getCurrencyRateByCode = createAction<{
  code: string;
}>('getCurrencyRateByCode ');
export const getCurrencyRateByCodeFailure = createAction<string>(
  'getCurrencyRateByCodeFailure',
);
export const getCurrencyRateByCodeSuccess = createAction<CurrencyRate>(
  'getCurrencyRateByCodeSuccess',
);
