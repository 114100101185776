import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast/index';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {StripeService} from 'services';
import {PaypalService} from 'services/api';

import {PaystackService} from '../../../services/api/Paystack';
import {paymentActions} from '../paymentSlice';

// Epic for adding Stripe credit card.
export const addCreditCardEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.addCreditCard.match),
    mergeMap(
      ({
        payload: {
          stripeResponse: {token},
        },
      }) =>
        from(StripeService.addCreditCard(token)).pipe(
          mergeMap(() => [
            paymentActions.addCreditCardSuccess(),
            userActions.checkSession(UserRoles.member),
          ]),
          catchError((message: string) =>
            concat(of(paymentActions.addCreditCardFailure({message}))),
          ),
        ),
    ),
  );

export const addCreditCardFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.addCreditCardFailure.match),
    mergeMap(({payload}) =>
      of(
        userActions.setAsyncError({
          filter: 'payment',
          message: payload.message,
        }),
      ),
    ),
  );

export const addCreditCardSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.addCreditCardSuccess.match),
    tap(() => {
      Toast({
        message: 'Your card was added 🚀',
        type: 'success',
      });
    }),
    delayWhen(() =>
      action$.pipe(filter(userActions.checkSessionSuccess.match)),
    ),
    switchMap(() => [
      push({
        pathname: '/dashboard',
      }),
    ]),
  );

// Epic for setting a default payment card.
export const setDefaultCardEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.setDefaultCard.match),
    mergeMap(({payload: {cardId, last4}}) =>
      from(StripeService.setDefaultCard({cardId})).pipe(
        mergeMap(() => [
          userActions.checkSession(UserRoles.member),
          paymentActions.setDefaultCardSuccess(last4),
        ]),
        catchError((message: string) =>
          concat(of(paymentActions.addCreditCardFailure({message}))),
        ),
      ),
    ),
  );

export const setDefaultCardSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.setDefaultCardSuccess.match),
    tap(({payload}) => {
      Toast({
        type: 'success',
        message: `Your card ending with ${payload} set as default`,
      });
    }),
    ignoreElements(),
  );

export const setDefaultCardFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.setDefaultCardFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

// Epic for fetching the PayPal payment card list
export const getPayPalPaymentCardsListEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.getPayPalPaymentCardsList.match),
    mergeMap(({payload: {defaultCard}}) =>
      from(PaypalService.getPayPalPaymentCardsList()).pipe(
        mergeMap(payload => [
          userActions.checkSession(UserRoles.member),
          paymentActions.getPayPalPaymentCardsListSuccess({
            ...payload,
            defaultCard,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.getPayPalPaymentCardsListFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const getPayPalPaymentCardsListFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.getPayPalPaymentCardsListFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

// Epic for setting a default payment card.
export const setPayPalDefaultPaymentCardEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.setPayPalDefaultPaymentCard.match),
    mergeMap(({payload}) =>
      from(
        PaypalService.setPayPalDefaultPaymentCard(payload.paymentToken),
      ).pipe(
        mergeMap(({message}) => [
          userActions.checkSession(UserRoles.member),
          paymentActions.setPayPalDefaultPaymentCardSuccess({
            newPaypalDefaultCard: message.newPaypalDefaultCard,
            newPaypalDefaultCardLast4: payload.lastDigits,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.setPayPalDefaultPaymentCardFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const setPayPalDefaultPaymentCardFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.setPayPalDefaultPaymentCardFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

export const setPayPalDefaultPaymentCardSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.setPayPalDefaultPaymentCardSuccess.match),
    tap(({payload}) => {
      Toast({
        type: 'success',
        message: `Your card with the number ${payload.newPaypalDefaultCardLast4} has been set as the default.`,
      });
    }),
    ignoreElements(),
  );

// Epic for handling the deletion of a payment card on PayPal.
export const deletePaymentCardOnPayPalEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.deletePaymentCardOnPayPal.match),
    mergeMap(({payload}) =>
      from(PaypalService.deletePaymentCardOnPayPal(payload)).pipe(
        mergeMap(({message}) => [
          userActions.checkSession(UserRoles.member),
          paymentActions.deletePaymentCardOnPayPalSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.deletePaymentCardOnPayPalFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const deletePaymentCardOnPayPalFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.deletePaymentCardOnPayPalFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

export const deletePaymentCardOnPayPalSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.deletePaymentCardOnPayPalSuccess.match),
    tap(({payload}) => {
      Toast({type: 'success', message: payload});
    }),
    ignoreElements(),
  );

// Epic for fetching the Paystack payment card list
export const getPaystackPaymentCardsListEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.getPaystackPaymentCardsList.match),
    mergeMap(() =>
      from(PaystackService.getPaystackPaymentCardsList()).pipe(
        mergeMap(({data}) => [
          userActions.checkSession(UserRoles.member),
          paymentActions.getPaystackPaymentCardsListSuccess(data.message),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.getPaystackPaymentCardsListFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const getPaystackPaymentCardsListFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.getPaystackPaymentCardsListFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

// Epic for setting a default payment card.
export const setPaystackDefaultPaymentCardEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.setPaystackDefaultPaymentCard.match),
    mergeMap(({payload}) =>
      from(
        PaystackService.setPaystackDefaultPaymentCard(payload.paymentToken),
      ).pipe(
        mergeMap(({message}) => [
          userActions.checkSession(UserRoles.member),
          paymentActions.setPaystackDefaultPaymentCardSuccess({
            newPaystackDefaultCard: message.newPaystackDefaultCard,
            newPaystackDefaultCardLast4: payload.last4,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.setPaystackDefaultPaymentCardFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const setPaystackDefaultPaymentCardFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.setPaystackDefaultPaymentCardFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

export const setPaystackDefaultPaymentCardSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.setPaystackDefaultPaymentCardSuccess.match),
    tap(({payload}) => {
      Toast({
        type: 'success',
        message: `Your card with the number ${payload.newPaystackDefaultCardLast4} has been set as the default.`,
      });
    }),
    ignoreElements(),
  );

// Epic for handling the deletion of a payment card on Paystack.
export const deletePaymentCardOnPaystackEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.deletePaymentCardOnPaystack.match),
    mergeMap(({payload}) =>
      from(PaystackService.deletePaymentCardOnPaystack(payload)).pipe(
        mergeMap(() => [
          userActions.checkSession(UserRoles.member),
          paymentActions.deletePaymentCardOnPaystackSuccess(payload),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.deletePaymentCardOnPaystackFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const deletePaymentCardOnPaystackFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.deletePaymentCardOnPaystackFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

export const deletePaymentCardOnPaystackSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.deletePaymentCardOnPaystackSuccess.match),
    tap(() => {
      Toast({type: 'success', message: 'Payment Card Deleted Successfully'});
    }),
    ignoreElements(),
  );

export const paymentCardsEpics = [
  addCreditCardEpic,
  addCreditCardFailureEpic,
  addCreditCardSuccessEpic,
  setDefaultCardEpic,
  setDefaultCardFailureEpic,
  setDefaultCardSuccessEpic,
  // PayPal
  getPayPalPaymentCardsListEpic,
  getPayPalPaymentCardsListFailureEpic,
  setPayPalDefaultPaymentCardEpic,
  setPayPalDefaultPaymentCardFailureEpic,
  setPayPalDefaultPaymentCardSuccessEpic,
  deletePaymentCardOnPayPalEpic,
  deletePaymentCardOnPayPalFailureEpic,
  deletePaymentCardOnPayPalSuccessEpic,
  // Paystack
  getPaystackPaymentCardsListEpic,
  getPaystackPaymentCardsListFailureEpic,
  setPaystackDefaultPaymentCardEpic,
  setPaystackDefaultPaymentCardFailureEpic,
  setPaystackDefaultPaymentCardSuccessEpic,
  deletePaymentCardOnPaystackEpic,
  deletePaymentCardOnPaystackFailureEpic,
  deletePaymentCardOnPaystackSuccessEpic,
];
