export const countriesTimeZones = {
  US: {
    timezones: [
      {
        default: false,
        timezone: 'america/adak',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'pacific/honolulu',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/anchorage',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/juneau',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/metlakatla',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/nome',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/sitka',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/yakutat',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: true,
        timezone: 'america/los_angeles',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/boise',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/denver',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/phoenix',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/chicago',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/knox',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/tell_city',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/menominee',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/north_dakota/beulah',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/north_dakota/center',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/north_dakota/new_salem',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/detroit',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/indianapolis',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/marengo',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/petersburg',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/vevay',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/vincennes',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/indiana/winamac',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/kentucky/louisville',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/kentucky/monticello',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
      {
        default: false,
        timezone: 'america/new_york',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
    ],
  },
  ZA: {
    timezones: [
      {
        default: true,
        timezone: 'Africa/Johannesburg',
        utcOffset: '+02:00',
        gmtoffset: '+02:00',
      },
    ],
  },
  AU: {
    timezones: [
      {
        default: true,
        timezone: 'Australia/Sydney',
        utcOffset: '+11:00',
        gmtoffset: '+11:00',
      },
      {
        default: false,
        timezone: 'Australia/Melbourne',
        utcOffset: '+11:00',
        gmtoffset: '+11:00',
      },
      {
        default: false,
        timezone: 'Australia/Brisbane',
        utcOffset: '+10:00',
        gmtoffset: '+10:00',
      },
      {
        default: false,
        timezone: 'Australia/Perth',
        utcOffset: '+08:00',
        gmtoffset: '+08:00',
      },
      {
        default: false,
        timezone: 'Australia/Adelaide',
        utcOffset: '+10:30',
        gmtoffset: '+10:30',
      },
      {
        default: false,
        timezone: 'Australia/Hobart',
        utcOffset: '+11:00',
        gmtoffset: '+11:00',
      },
      {
        default: false,
        timezone: 'Australia/Darwin',
        utcOffset: '+09:30',
        gmtoffset: '+09:30',
      },
      {
        default: false,
        timezone: 'Australia/Canberra',
        utcOffset: '+11:00',
        gmtoffset: '+11:00',
      },
      {
        default: false,
        timezone: 'Australia/Lord_Howe',
        utcOffset: '+11:00',
        gmtoffset: '+11:00',
      },
    ],
  },

  BR: {
    timezones: [
      {
        default: true,
        timezone: 'America/Sao_Paulo',
        utcOffset: '-03:00',
        gmtoffset: '-03:00',
      },
    ],
  },
  CL: {
    timezones: [
      {
        default: true,
        timezone: 'America/Santiago',
        utcOffset: '-03:00',
        gmtoffset: '-03:00',
      },
      {
        default: false,
        timezone: 'Pacific/Easter',
        utcOffset: '-06:00',
        gmtoffset: '-06:00',
      },
    ],
  },
  CA: {
    timezones: [
      {
        default: true,
        timezone: 'America/Toronto',
        utcOffset: '-05:00',
        gmtoffset: '-05:00',
      },
      {
        default: false,
        timezone: 'America/Vancouver',
        utcOffset: '-08:00',
        gmtoffset: '-08:00',
      },
      {
        default: false,
        timezone: 'America/Edmonton',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Winnipeg',
        utcOffset: '-06:00',
        gmtoffset: '-06:00',
      },
      {
        default: false,
        timezone: 'America/Halifax',
        utcOffset: '-04:00',
        gmtoffset: '-04:00',
      },
      {
        default: false,
        timezone: 'America/St_Johns',
        utcOffset: '-03:30',
        gmtoffset: '-03:30',
      },
      {
        default: false,
        timezone: 'America/Regina',
        utcOffset: '-06:00',
        gmtoffset: '-06:00',
      },
      {
        default: false,
        timezone: 'America/Iqaluit',
        utcOffset: '-05:00',
        gmtoffset: '-05:00',
      },
      {
        default: false,
        timezone: 'America/Whitehorse',
        utcOffset: '-08:00',
        gmtoffset: '-08:00',
      },
      {
        default: false,
        timezone: 'America/Yellowknife',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Rankin_Inlet',
        utcOffset: '-06:00',
        gmtoffset: '-06:00',
      },
      {
        default: false,
        timezone: 'America/Glace_Bay',
        utcOffset: '-03:00',
        gmtoffset: '-03:00',
      },
      {
        default: false,
        timezone: 'America/Pangnirtung',
        utcOffset: '-05:00',
        gmtoffset: '-05:00',
      },
      {
        default: false,
        timezone: 'America/Blanc-Sablon',
        utcOffset: '-04:00',
        gmtoffset: '-04:00',
      },
      {
        default: false,
        timezone: 'America/Moncton',
        utcOffset: '-04:00',
        gmtoffset: '-04:00',
      },
      {
        default: false,
        timezone: 'America/Nipigon',
        utcOffset: '-05:00',
        gmtoffset: '-05:00',
      },
      {
        default: false,
        timezone: 'America/Dawson',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Creston',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Fort_Nelson',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Dawson_Creek',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
    ],
  },
  CO: {
    timezones: [
      {
        default: true,
        timezone: 'America/Bogota',
        utcOffset: '-05:00',
        gmtoffset: '-05:00',
      },
    ],
  },
  MX: {
    timezones: [
      {
        default: true,
        timezone: 'America/Mexico_City',
        utcOffset: '-06:00',
        gmtoffset: '-06:00',
      },
      {
        default: false,
        timezone: 'America/Cancun',
        utcOffset: '-05:00',
        gmtoffset: '-05:00',
      },
      {
        default: false,
        timezone: 'America/Merida',
        utcOffset: '-05:00',
        gmtoffset: '-05:00',
      },
      {
        default: false,
        timezone: 'America/Monterrey',
        utcOffset: '-06:00',
        gmtoffset: '-06:00',
      },
      {
        default: false,
        timezone: 'America/Matamoros',
        utcOffset: '-06:00',
        gmtoffset: '-06:00',
      },
      {
        default: false,
        timezone: 'America/Mazatlan',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Chihuahua',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Ojinaga',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Hermosillo',
        utcOffset: '-07:00',
        gmtoffset: '-07:00',
      },
      {
        default: false,
        timezone: 'America/Tijuana',
        utcOffset: '-08:00',
        gmtoffset: '-08:00',
      },
    ],
  },
  NZ: {
    timezones: [
      {
        default: true,
        timezone: 'Pacific/Auckland',
        utcOffset: '+13:00',
        gmtoffset: '+13:00',
      },
      {
        default: false,
        timezone: 'Pacific/Chatham',
        utcOffset: '+13:45',
        gmtoffset: '+13:45',
      },
    ],
  },
  ES: {
    timezones: [
      {
        default: true,
        timezone: 'Europe/Madrid',
        utcOffset: '+01:00',
        gmtoffset: '+01:00',
      },
      {
        default: false,
        timezone: 'Atlantic/Canary',
        utcOffset: '+00:00',
        gmtoffset: '+00:00',
      },
    ],
  },
};

export const timezoneOptions = (selectedCountry?: string) => {
  const countriesToMap = selectedCountry
    ? [countriesTimeZones[selectedCountry]]
    : Object.values(countriesTimeZones);

  return countriesToMap.flatMap(country =>
    country.timezones.map(({timezone}: {timezone: string}) => ({
      label: timezone,
      value:
        country.timezones.find(
          (tz: {timezone: string}) => tz.timezone === timezone,
        )?.utcOffset || '',
    })),
  );
};
