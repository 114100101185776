import React from 'react';

import {SvgIconProps} from './SvgIcon';

export const AvatarIcon: React.FC<SvgIconProps> = ({
  width = 20,
  height = 20,
  fillColor = 'black',
  strokeColor = 'none',
  strokeWidth = '0',
  classes = '',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill={fillColor}
    className={classes}
  >
    <defs>
      <clipPath id="clip-avatar">
        <rect width="32" height="32" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-avatar)">
      <g transform="translate(-468 -468)">
        <g>
          <g>
            <g>
              <path
                d="M499.109,496.336a29.21,29.21,0,0,0-9.273-3.7,22.694,22.694,0,0,0,3.812-8.169c.754-3.128.461-9.248-2.543-13.062a9.113,9.113,0,0,0-14.209,0c-3,3.813-3.3,9.933-2.543,13.061a22.853,22.853,0,0,0,3.675,7.982.991.991,0,0,0-.021.223,29.243,29.243,0,0,0-9.115,3.663A2,2,0,0,0,470,500h28a2,2,0,0,0,1.11-3.664ZM470,498a27.043,27.043,0,0,1,8.585-3.409,1.981,1.981,0,0,0,.961-.525,8.552,8.552,0,0,0,3.272.6,11.671,11.671,0,0,0,2.2-.218,1,1,0,1,0-.377-1.964,7.223,7.223,0,0,1-4.566-.369,1.961,1.961,0,0,0-.346-.722A20.549,20.549,0,0,1,476.3,484c-.64-2.656-.393-8.1,2.17-11.355a7.112,7.112,0,0,1,11.066,0c2.564,3.254,2.81,8.7,2.17,11.356a20.55,20.55,0,0,1-3.436,7.4,2,2,0,0,0,1.146,3.194A27.247,27.247,0,0,1,498,498Z"
                fill={fillColor}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
