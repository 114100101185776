import React from 'react';
import {usePaymentReq} from 'hooks/usePaymentReq';
import {Appointment, SliceStatus} from 'interfaces';
import {useSelector} from 'react-redux';
import {getFormattedMemberAppointmentDate} from 'services/Booking/helpers';

import {selectUserProfile} from '../../User';
import {currencies} from '../Calendar/CalendarItemProps';

import PaymentRequestModal from './PaymentRequestModal';

export default function PaymentRequestGlobalHandler() {
  const user = useSelector(selectUserProfile);

  const {
    meetingData,
    isLoading: isLoadingPaymentReqDetails,
    showPaymentRequestModal,
    setShowPaymentRequestModal,
    showSuccessPaymentModal,
    setShowSuccessPaymentModal,
    isPaymentDue,
  } = usePaymentReq();

  if (!meetingData) return null;

  const isAlreadyPaid = !!(meetingData?.chargeStatus === 'charged');
  const localTime = getFormattedMemberAppointmentDate(
    meetingData as unknown as Appointment,
  );

  return (
    <div>
      {/* Modals */}
      <PaymentRequestModal
        user={user}
        currencies={currencies}
        price={Number(meetingData?.appointmentCharge)}
        appointmentID={String(meetingData?.appointmentID)}
        charged={meetingData?.chargeStatus}
        duration={meetingData?.duration}
        showPaymentRequestModal={showPaymentRequestModal}
        setShowPaymentRequestModal={setShowPaymentRequestModal}
        isLoading={isLoadingPaymentReqDetails === SliceStatus.pending}
        showSuccessModal={showSuccessPaymentModal}
        setShowSuccessModal={setShowSuccessPaymentModal}
        patientId={meetingData?.patientId || ''}
        localTime={localTime}
        isPaymentDue={isPaymentDue}
        isAlreadyPaid={isAlreadyPaid}
      />
    </div>
  );
}
