import {useEffect, useState} from 'react';
import {ProfileImageSchema} from 'definitions/Yup';
import {memberActions} from 'features/Member';
import {providerActions} from 'features/Provider';
import {selectUserProfile} from 'features/User';
import {ProfileImageFormData, SliceStatus} from 'interfaces';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {isMember} from 'utils';

import {yupResolver} from '@hookform/resolvers/yup';

import {useImageResizer} from './useImageResizer';

export function useAvatarUpload(): {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: any;
  register: any;
  resetForm: () => void;
  isUploading: boolean;
  previewImage: string | null;
} {
  const {setValue, handleSubmit, reset, register} =
    useForm<ProfileImageFormData>({
      defaultValues: {image: undefined},
      resolver: yupResolver(ProfileImageSchema),
    });
  const {resizeImage, baseString, setBaseString} = useImageResizer(true);
  const user = useSelector(selectUserProfile);
  const dispatch = useDispatch();

  const [status, setStatus] = useState(SliceStatus.idle);

  const resetForm = () => {
    reset({image: undefined});
    setBaseString('');
  };

  const onSubmit = handleSubmit(values => {
    setStatus(SliceStatus.pending);
    const onSuccess = () => {
      setStatus(SliceStatus.resolved);
      reset({image: undefined});
      setBaseString('');
    };

    const onError = () => {
      setStatus(SliceStatus.rejected);
    };

    if (isMember(user)) {
      dispatch(
        memberActions.changeProfilePicture({image: values, onSuccess, onError}),
      );
    } else {
      dispatch(
        providerActions.updateProviderProfilePicture({
          image: values,
          role: user!.role,
          onSuccess,
          onError,
        }),
      );
    }
  });
  const isUploading = status === SliceStatus.pending;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resizeImage({
      file: event?.currentTarget?.files?.[0],
      name: 'image',
      setValue,
    });
  };

  useEffect(() => {
    // @ts-ignore
    register('image');
  }, [register]);

  return {
    handleChange,
    onSubmit,
    isUploading,
    previewImage: baseString,
    resetForm,
    register,
  };
}
