import * as Yup from 'yup';

export const ProviderRegisterSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .test('invalid', 'Must include first and last name', name =>
      /^[a-zA-Z]+ [a-zA-Z]+/.test(name ?? ''),
    ),
  stateOfResidence: Yup.string().when('countryOfResidence.code', {
    is: 'US',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  phoneNumber: Yup.string()
    .required('Required')
    .min(10, 'Invalid Phone Number'),
  countryOfResidence: Yup.object()
    .shape({
      code: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
    })
    .nullable()
    .required('Required'),
  referralCode: Yup.string().test({
    name: 'is-valid-referral-code',
    message: 'Invalid referral code',
    test: value => {
      if (value) {
        return /^rec[a-zA-Z0-9]{1,64}$/.test(value);
      }
      return true;
    },
  }),
});
