import React from 'react';

import {SvgIcon} from './SvgIcon';

export const LocationIcon: SvgIcon = ({
  width = 26,
  height = 22,
  strokeColor = 'black',
  strokeWidth = 0.7,
  fillColor = 'black',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -1 14.25 20"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      stroke={strokeColor}
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.19,0C3.3,0,0,3.32,0,7.25s3.82,8.32,6.39,11.28c0,.01,.43,.47,.94,.47h.05c.51,0,.92-.46,.94-.47,2.41-2.77,5.94-7.35,5.94-11.28,0-3.93-2.57-7.25-7.06-7.25Zm.24,17.73s-.05,.04-.08,.06c-.03-.02-.06-.04-.08-.06l-.31-.36C4.53,14.58,1.19,10.75,1.19,7.25,1.19,3.96,3.94,1.19,7.19,1.19c4.05,0,5.87,3.04,5.87,6.06,0,2.66-1.89,6.18-5.63,10.48h0Zm-.29-14.13c-1.97,0-3.56,1.6-3.56,3.56s1.6,3.56,3.56,3.56,3.56-1.6,3.56-3.56-1.6-3.56-3.56-3.56Zm0,5.94c-1.31,0-2.4-1.09-2.4-2.4s1.07-2.38,2.38-2.38,2.38,1.07,2.38,2.38c0,1.31-1.04,2.4-2.35,2.4Z"
    />
  </svg>
);
