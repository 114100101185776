import {OnboardingValues} from 'interfaces/Pages/Onboarding.types';

export const initialOnboardingState: OnboardingValues = {
  dateOfBirth: {day: 1, month: 1, year: 1990},
  currentGenderIdentification: '',
  currentGender: '',
  gender: '',
  pregnancyInfo: [],
  drugAllergies: '',
  allergies: '',
  visADocForAnxDep: '',
  diagnosis: [],
  existingConditions: '',
  medicationsForAnxDep: '',
  currentMedications: 'None',
  purposeOfReg: '',
  seizure: '',
  shippingAddress: {
    streetAddress: '',
    city: '',
    state: '',
    postalCode: '',
  },
  suicidalThoughts: '',
  suicidalThoughtsInDetails: '',
  suicidalThoughtsOthers: '',
  suicidalThoughtsOthersInDetails: '',
  currentlySuicidal: '',
  currentlySuicidalInDetails: '',
  currentlySuicidalOthers: '',
  currentlySuicidalOthersInDetails: '',
  localPharmData: {
    pharmName: '',
    pharmAddress: '',
  },
  anxiety: {
    nervous: '',
    controlWorrying: '',
    worryingTooMuch: '',
    troubleRelaxing: '',
    restlessness: '',
    easilyAnnoyed: '',
    fear: '',
  },
  depression: {
    littleInterest: '',
    feelingDown: '',
    sleepingTroubles: '',
    feelingTired: '',
    underOrOverEating: '',
    feelBadAboutSelf: '',
    troubleConcentrating: '',
    slowMovtOrFidgety: '',
    thoughtsOfHurtingSelf: '',
  },
  governmentID: [],
  signature: '',
};
