import React, {FC, lazy, Suspense, useEffect, useMemo, useState} from 'react';
import {RootState} from 'app/rootReducer';
import {Button, LoadingSpinner} from 'components';
import {FormSelect} from 'components/Basic/Form/V2';
import {providerActions} from 'features/Provider';
import {selectMemberById} from 'features/Provider/Members/membersSelectors';
import {selectUserNotification, selectUserProfile} from 'features/User';
import {useQuery, useRequesting} from 'hooks';
import {useBooking} from 'hooks/useBooking';
import {
  AppointmentDurations,
  AppointmentTypes,
  MemberProfile,
  PrescriberProfile,
  SliceStatus,
  TherapistProfile,
  UserAccountType,
  UserRoles,
} from 'interfaces';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {
  componentLoader,
  isMember,
  isProvider,
  isUserFromUnitedStates,
} from 'utils';

import {getDirectBookingSelectedProvider} from '../../../DirectBooking/directBookingSelectors';

import {BookingCalendar} from './BookingCalendar';
import {ClientChooser} from './ClientChooser';
import {TimeGrid} from './TimeGrid';

import 'react-day-picker/lib/style.css';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

type Props = {
  appointmentType: AppointmentTypes;
  member?: MemberProfile;
  duration?: AppointmentDurations;
};

type OptionType = {label: string; value: string};
// eslint-disable-next-line max-lines-per-function
export const BookingForm: FC<Props> = React.memo(
  ({appointmentType, duration, member}) => {
    const history = useHistory();
    const user = useSelector(selectUserProfile);

    const {message, messageType, navigateTo} = useSelector(
      selectUserNotification,
    );
    const directBookingProvider = useSelector(getDirectBookingSelectedProvider);

    const isMemberDetailsLoading =
      useRequesting('provider') === SliceStatus.pending;

    const dispatch = useDispatch();
    const [memberId, setMemberId] = useState<string>();
    const location = useLocation<{id: string}>();
    const patientId = useQuery().get('patientId');

    const id = location?.state?.id;
    const memberPresentInState = Boolean(location?.state?.id || patientId);
    const getMemberId = memberId || id || patientId!;
    const memberById = useSelector((state: RootState) => {
      return selectMemberById(state, getMemberId);
    });

    const getProviderId = () =>
      (user as TherapistProfile)?.therapistId ||
      (user as PrescriberProfile)?.prescriberId;

    const {
      onSubmit,
      time,
      control,
      watch,
      register,
      errors,
      providerId,
      memberState,
      zones,
      disabledDays,
      selectedTime,
      isLoading,
      bookingCharge,
      setValue,
      onConfirmBooking,
      closeExtraChargeModal,
      getProviderCalendarData,
      availability,
      providerLoadingStatus,
      slotLoadingStatus,
    } = useBooking(appointmentType, duration!, member || memberById);

    const isPending =
      isLoading === SliceStatus.pending ||
      bookingCharge.status === SliceStatus.pending;
    const clientInputValue = watch('client');

    const isUserFromUnitedStatesCheck = isUserFromUnitedStates(user);

    useEffect(() => {
      return () => {
        if (history.location.state && (history.location.state as any).id) {
          const state = {...(history.location.state as any)};
          delete state.id;
          history.replace({...history.location, state});
        }
      };
    }, []);

    useEffect(() => {
      if (isProvider(user) && memberPresentInState) {
        dispatch(
          providerActions.getMemberById({
            patientId: id || patientId!,
            role: user!.role,
          }),
        );
      }
    }, [id, user!.role, memberPresentInState]);

    useEffect(() => {
      if (
        isProvider(user) &&
        clientInputValue &&
        memberId &&
        !memberPresentInState
      ) {
        dispatch(
          providerActions.getMemberById({
            patientId: clientInputValue,
            role: user!.role,
          }),
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientInputValue, memberId, user!.role, memberPresentInState]);

    useEffect(() => {
      if (isProvider(user) && clientInputValue) {
        setValue('providerId', getProviderId());
      }
    }, [clientInputValue]);

    const {t} = useTranslation();

    const BookAppointmentButtonText =
      (user?.subscriptionStatus?.includes('not-subscribed') &&
        appointmentType !== 'chat_with_coach') ||
      isProvider(user)
        ? t('book_now')
        : t('complete_appointment');

    const [providerType, bookingType] = (() => {
      switch (appointmentType) {
        case AppointmentTypes.chat_with_coach:
          return [t('coach'), t('chat_with_coach')];
        case AppointmentTypes.video_call_with_therapist:
          return [t('therapist'), t('video_call_with_therapist')];
        default:
          return [t('doctor'), t('doctor_consultation')];
      }
    })();

    const providerOptions: OptionType[] = useMemo(() => {
      if (isProvider(user)) {
        return [
          {
            label: user?.fullName,
            value: user?.therapistId || user?.prescriberId,
          },
        ];
      }

      const mapProvider = (providerDetails: {
        acuity: {
          calendarId: number;
        };
        fullName: string;
        email: string;
        _id: string;
        prescriberId?: string;
        therapistId?: string;
      }) => ({
        label: providerDetails.fullName,
        value: providerDetails?.prescriberId ?? providerDetails?.therapistId,
      });

      const scaleTherapistCall =
        user?.accountType === UserAccountType.scale &&
        appointmentType === AppointmentTypes.video_call_with_therapist;

      if (scaleTherapistCall) {
        return availability && Array.isArray(availability?.providers)
          ? availability.providers
              .filter(p => p?.therapistId === user.therapistDetails.therapistId)
              .map(mapProvider)
          : [];
      }

      if (directBookingProvider) {
        return [
          {
            label: directBookingProvider.providerFullName ?? '',
            value: directBookingProvider.providerId,
          },
          ...availability.providers
            .filter(p => p?.therapistId !== directBookingProvider.providerId)
            .map(mapProvider),
        ];
      }

      return (
        availability?.providers?.map(x => ({
          label: x.fullName,
          value: x?.therapistId ?? x?.prescriberId ?? '',
          calendarId: x.acuity.calendarId,
        })) || []
      );
    }, [user, availability]);

    const modalProps = {
      message,
      messageType,
      isOpen: true,
      ...(navigateTo && {
        buttonFn: () => {
          history.push(navigateTo);
        },
      }),
    };

    return (
      <>
        {messageType === 'none' || !isProvider(user) ? null : (
          <Suspense fallback={<div />}>
            <Modal {...modalProps} />
          </Suspense>
        )}
        <form
          onSubmit={onSubmit}
          className="max-w-sm mx-auto px-2 overflow-y-auto"
        >
          {isProvider(user) ? null : (
            <h1 className="font-medium text-lg pt-5 md:pt-10 pb-4 my-0">
              {bookingType}
            </h1>
          )}
          <input
            type="text"
            className="hidden"
            {...register('appointmentType')}
          />
          {isProvider(user) && memberPresentInState ? (
            <FormSelect
              control={control}
              id="client"
              label={t('client')}
              classes="mb-5"
              labelClasses="font-light text-xs"
              defaultValue={memberById?.patientId}
              extractValue={true}
              options={[
                {
                  value: memberById?.patientId,
                  label: memberById?.fullName,
                },
              ]}
            />
          ) : null}
          {isProvider(user) && !memberPresentInState ? (
            <ClientChooser
              control={control}
              memberPresentInState={memberPresentInState}
              onMemberSelect={id => setMemberId(id)}
            />
          ) : null}
          {isMember(user) ? (
            <FormSelect
              control={control}
              id="providerId"
              defaultValue=""
              label={t('choose', {providerType})}
              classes="mb-5"
              extractValue={true}
              labelClasses="font-light text-xs"
              isLoading={providerLoadingStatus === SliceStatus.pending}
              options={isPending ? [] : providerOptions}
              loadingMessage={() =>
                `${t('loading')} ${providerType.toLowerCase()}...`
              }
              placeholder={t('choose_your', {providerType})}
              noOptionsMessage={e =>
                e.inputValue
                  ? t('no_options_with_input', {
                      providerType: providerType.toLowerCase(),
                      inputValue: e.inputValue,
                    })
                  : t('no_options')
              }
              onChange={(v: any) => {
                setValue('providerId', v.value);
              }}
              isDisabled={
                providerLoadingStatus === SliceStatus.pending ||
                slotLoadingStatus === SliceStatus.pending
              }
            />
          ) : null}
          {isUserFromUnitedStatesCheck ? (
            <FormSelect
              control={control}
              id="state"
              label={t('state')}
              classes="mb-5"
              labelClasses="font-light text-xs"
              extractValue={true}
              isLoading={memberById && !memberById?.stateOfResidence}
              defaultValue={memberById?.stateOfResidence ?? memberState}
              options={[
                {
                  value: memberById?.stateOfResidence ?? memberState,
                  label: memberById?.stateOfResidence ?? memberState,
                },
              ]}
            />
          ) : null}
          <FormSelect
            control={control}
            id="timezone"
            label="Time Zones"
            classes="mb-5"
            extractValue={true}
            labelClasses="font-light text-xs"
            inputClasses="capitalize"
            options={zones}
          />
          {providerId ? (
            providerLoadingStatus === SliceStatus.pending ? null : (
              <>
                <BookingCalendar
                  isAvailabilityLoading={
                    slotLoadingStatus === SliceStatus.pending
                  }
                  disabledDays={disabledDays}
                  control={control}
                  onMonthChange={month => {
                    // on month change, fetch the availability for entire month rather than just the selected day
                    getProviderCalendarData(month, false);
                  }}
                ></BookingCalendar>

                {slotLoadingStatus === SliceStatus.pending ? null : (
                  <TimeGrid
                    time={time}
                    watch={watch}
                    register={register}
                    selectedTime={selectedTime}
                    label="Select time"
                    errors={errors}
                  ></TimeGrid>
                )}
              </>
            )
          ) : isMemberDetailsLoading ? (
            <div className="w-full flex justify-center py-4">
              <LoadingSpinner type="Oval" height={20} />
            </div>
          ) : null}
          <section className="pt-16 md:pt-10 pb-20 md:pb-24 text-center">
            <Button
              disabled={Boolean(selectedTime) && isPending}
              type="submit"
              borderColor="transparent"
              className="w-full md:w-3/5 rounded-full p-3"
              isSubmitting={isPending}
            >
              {BookAppointmentButtonText}
            </Button>
          </section>
        </form>

        {bookingCharge.showModal ? (
          <Modal
            messageType="none"
            isOpen={bookingCharge.showModal}
            buttonFn={closeExtraChargeModal}
          >
            <article className="flex flex-col items-center justify-center px-5">
              {user?.role === UserRoles.member ? (
                <p className="text-lg text-justify">
                  <Trans i18nKey="outOfTime">
                    Hey there! Seems like you have run out of time. The price
                    for the session selected is: $
                    {{amount: bookingCharge.amount}}. Once you click&nbsp;
                    <span className="font-semibold">Complete Booking</span>,
                    your card on file will be charged. If you have previously
                    canceled any appointments with more than 24hr notice in the
                    past 30 days, please Text: “Balance 24” to (415) 449-7796
                    for immediate resolution.
                  </Trans>
                </p>
              ) : (
                <p className="text-lg text-center">
                  {t('patientWillBeChargedConfirm')}
                </p>
              )}

              <section className="grid grid-cols-1 md:grid-cols-2 md:gap-x-5 mt-12">
                <Button
                  type="button"
                  onClick={onConfirmBooking}
                  borderColor="transparent"
                  className="rounded-full px-8 py-3"
                >
                  {user?.role === UserRoles.member
                    ? t('complete_booking')
                    : t('yes_book')}
                </Button>
                <Button
                  type="button"
                  onClick={closeExtraChargeModal}
                  borderColor="transparent"
                  bgColor="red-600"
                  textColor="red-600"
                  className="rounded-full px-8 py-3 font-semibold"
                  btnType="danger"
                  outline
                >
                  {t('no_go_back')}
                </Button>
              </section>
            </article>
          </Modal>
        ) : null}
      </>
    );
  },
);
