import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {MemberState} from './memberSlice';

function getMember(state: RootState): MemberState {
  return state.member;
}

export const selectMember = createSelector(getMember, member => member);
export const selectMemberStatus = createSelector(
  getMember,
  member => member.status,
);

export const selectMemberUploading = createSelector(
  getMember,
  member => member.uploading,
);
export const selectInsuranceNames = createSelector(
  getMember,
  member => member.insuranceNames,
);
export const selectPverifyDetails = createSelector(
  getMember,
  member => member.pverifyData.data,
);
export const selectSocialOnboardingStatus = createSelector(
  getMember,
  member => member.socialOnboardingStatus,
);
export const selectMemberLanguageStatus = createSelector(
  getMember,
  member => member.languageStatus,
);

export const selectAccountTab = createSelector(
  getMember,
  member => member.accountTab,
);
