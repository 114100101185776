import React, {FC} from 'react';
import classNames from 'classnames';
import {Button, RightNavIcon, WellniteLogo} from 'components';
import {
  FormSelect,
  FormTextArea,
  FormTextInput,
} from 'components/Basic/Form/V2';
import {generateStringArrayOptions} from 'features/WellniteExercise/utils';
import {
  ExerciseStepsProps,
  GenerateFormDataType,
} from 'features/WellniteExercise/utils/constants';
import {generateExercise} from 'features/WellniteExercise/wellniteExerciseActions';
import {useRequesting} from 'hooks';
import {TFunction} from 'i18next';
import {SliceStatus} from 'interfaces';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';

import {yupResolver} from '@hookform/resolvers/yup';

import {generateExerciseValidationSchema} from '../../validations';

import {customSelectStyles} from './styles';

const PromptsOptions = (t: TFunction) => [
  {
    label: t('wellniteExercise.gaslighting', 'Gaslighting'),
    value: 'Gaslighting',
  },
  {label: t('wellniteExercise.sanity', 'Sanity'), value: 'Sanity'},
  {label: t('wellniteExercise.beliefs', 'Beliefs'), value: 'Beliefs'},
  {label: t('wellniteExercise.memory', 'Memory'), value: 'Memory'},
];

const GenerateExerciseTemplate: FC<ExerciseStepsProps> = ({onSubmit}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const generatedExerciseStatus = useRequesting('wellniteExercise');
  const {patientId} = useParams<{patientId: string}>();
  const {control, watch, handleSubmit} = useForm({
    resolver: yupResolver(generateExerciseValidationSchema),
    defaultValues: {
      title: '',
      description: '',
      prompts: [],
    },
  });

  const values = watch();
  const desCharLength = values?.description?.length ?? 0;
  const emptyFields: boolean = Object.values(values).some(value =>
    Array.isArray(value) ? value.length === 0 : value.trim() === '',
  );

  const onSubmitGenerateExercise = (data: GenerateFormDataType) => {
    const {title, description, prompts} = data;
    const getPrompts = generateStringArrayOptions(prompts);
    dispatch(
      generateExercise({
        title,
        description,
        prompts: getPrompts,
        patientId,
        onSuccess: () => setTimeout(onSubmit, 60),
      }),
    );
  };

  const isRegeneratingExercise =
    generatedExerciseStatus === SliceStatus.pending;

  return (
    <div className="flex flex-col justify-center gap-10">
      <h1 className="font-montserrat text-3xl lg:text-[56px] lg:leading-[68px] font-bold m-0 text-center">
        {t(
          'wellniteExercise.generateExerciseTemplate',
          'Generate Exercise Template',
        )}
      </h1>
      <form>
        <div className="w-full md:w-7/12 md:max-w-xl h-auto flex flex-col gap-5 mx-auto mb-10">
          <FormTextInput
            id="title"
            name="title"
            control={control}
            label={t('wellniteExercise.exerciseTitle', 'Exercise Title')}
            classes="pt-0"
            labelClasses="font-inter text-xs leading-4 font-medium"
            inputClasses="font-inter text-sm font-medium leading-4 w-full h-12 px-6 rounded-xl"
            errorClasses="!text-left"
            isRequired={true}
          />
          <FormTextArea
            id="description"
            name="description"
            control={control}
            label={
              <div className="flex flex-row justify-between mb-2">
                <p className="font-inter text-xs leading-4 font-medium after:content-['*'] after:ml-0.5 after:text-red-500">
                  {t('wellniteExercise.addDescription', 'Add description')}
                </p>
                <span className="font-inter text-xs leading-4 font-medium pl-2">
                  {desCharLength}/120
                </span>
              </div>
            }
            containerClasses="pt-0"
            classes="font-inter text-sm font-medium leading-4 px-6 py-4 rounded-xl resize-none"
            errorClasses="!text-left"
            rows={3}
            maxChars={120}
            isRequired={true}
          />
          <FormSelect
            id="prompts"
            name="prompts"
            control={control}
            label={t(
              'wellniteExercise.prompts',
              'Prompts (e.g. “positive psychology exercise, silver linings etc.)',
            )}
            placeholder=""
            options={PromptsOptions(t)}
            creatable
            isMulti
            classes="pt-0 font-inter text-sm leading-4 font-medium"
            labelClasses="font-inter text-xs leading-4 font-medium"
            errorClasses="!text-left font-light"
            styles={{
              ...customSelectStyles,
              control: (provided, state) => ({
                ...customSelectStyles.control(provided, state),
                height: 'auto',
              }),
            }}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            isRequired={true}
          />

          <Button
            type="button"
            btnType="primary"
            fontSize="sm"
            fontWeight="medium"
            className={classNames(
              'z-999 !rounded-full !leading-4 px-6 h-12 self-center mt-5 flex items-center justify-center',
              {
                'bg-opacity-70': emptyFields || isRegeneratingExercise,
              },
            )}
            disabled={emptyFields || isRegeneratingExercise}
            onClick={handleSubmit(onSubmitGenerateExercise)}
          >
            {t('wellniteExercise.generate', 'Generate')}&nbsp;
            {isRegeneratingExercise ? (
              <WellniteLogo
                width={18}
                height={18}
                strokeColor="text-white"
                classes="animate-ping ml-2"
              />
            ) : (
              <RightNavIcon
                width={18}
                height={18}
                strokeColor="white"
                strokeWidth={2}
              />
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default GenerateExerciseTemplate;
