import React from 'react';

import {SvgIcon} from './SvgIcon';

export const CircleCheckIcon: SvgIcon = ({
  width = 20,
  height = 20,
  classes = '',
  fillColor = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="2 2 20 20"
    stroke="currentColor"
    className={`${classes}`}
    width={width}
    height={height}
  >
    <path d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
);
