import {
  PrescriberProfile,
  ProfileImageFormData,
  TherapistProfile,
  UpdateProviderPreferredLanguageFormData,
  UpdateProviderProfileFormData,
  UpdateProviderProfileInfoFormData,
  UserRoles,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const updateProviderProfile = createAction<
  UpdateProviderProfileFormData & {
    role: UserRoles;
  }
>('updateProviderProfile');
export const updateProviderProfileFailure = createAction(
  'updateProviderProfileFailure',
);
export const updateProviderProfileSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('updateProviderProfileSuccess');

export const updateProviderPreferredLanguage = createAction<
  UpdateProviderPreferredLanguageFormData & {
    role: UserRoles;
  }
>('updateProviderPreferredLanguage');
export const updateProviderPreferredLanguageFailure = createAction(
  'updateProviderPreferredLanguageFailure',
);
export const updateProviderPreferredLanguageSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('updateProviderPreferredLanguageSuccess');

export const updateProviderProfileInfo = createAction<
  UpdateProviderProfileInfoFormData & {
    role: UserRoles;
    onSuccess?: VoidFunction;
  }
>('updateProviderProfileInfo');
export const updateProviderProfileInfoFailure = createAction(
  'updateProviderProfileInfoFailure',
);
export const updateProviderProfileInfoSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('updateProviderProfileInfoSuccess');

export const updateProviderProfilePicture = createAction<{
  image: ProfileImageFormData;
  role: UserRoles;
  onSuccess: () => void;
  onError: () => void;
}>('updateProviderProfilePicture');
export const updateProviderProfilePictureFailure = createAction<string>(
  'updateProviderProfilePictureFailure',
);
export const updateProviderProfilePictureSuccess = createAction(
  'updateProviderProfilePictureSuccess',
);
