import React, {FC} from 'react';
import ModalWarning from 'assets/images/modal-warning.png';
import ModalWarningWebp from 'assets/images/modal-warning.webp';
import ModalWarning2x from 'assets/images/modal-warning@2x.png';
import ModalWarningWebp2x from 'assets/images/modal-warning@2x.webp';
import ModalWarning3x from 'assets/images/modal-warning@3x.png';
import ModalWarningWebp3x from 'assets/images/modal-warning@3x.webp';
import {LazyImage} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';

import {CloseCircleIcon} from '../SvgIcon';

const modalWarningLqip = lqip('../../../assets/images/modal-warning.png');

interface Props {
  confirm: () => void;
  cancel: () => void;
  show: boolean;
  message: string;
}

export const RouterPrompt: FC<Props> = ({show, cancel, message}) => {
  return show ? (
    <div className="fixed bottom-0 z-999 inset-x-0 px-4 pb-6 inset-0 p-0 flex flex-col items-center justify-center bg-gray-100 bg-opacity-40">
      <div className="w-full max-w-lg bg-white p-8 rounded-xl shadow-md z-40  relative">
        <button className="" onClick={cancel}>
          <CloseCircleIcon
            width={25}
            height={25}
            strokeColor="text-black"
            strokeWidth={30}
            classes="absolute top-2 right-2 z-10 cursor-pointer"
          />
        </button>
        <div className="flex flex-col justify-center items-center">
          <LazyImage
            lqip={modalWarningLqip}
            src={ModalWarning}
            srcSet={`${ModalWarning}, ${ModalWarning2x} 2x, ${ModalWarning3x}`}
            webp={`${ModalWarningWebp}, ${ModalWarningWebp2x} 2x, ${ModalWarningWebp3x}`}
            type="image/png"
            className="w-24 object-contain"
            aspectRatio={1 / 1}
            alt="Warning Sign"
          />
          <p
            className="my-5 px-10 text-gray-700 text-center leading-8"
            dangerouslySetInnerHTML={{__html: message}}
          />
        </div>
      </div>
    </div>
  ) : null;
};
