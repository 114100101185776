import {
  ColleaguesType,
  GetColleaguesParamsType,
  GetColleaguesTargetType,
  PaystackBank,
  SliceStatus,
  Wellbit,
  WellbitHistory,
  WellbitTypeTotal,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  sendGreetings,
  sendGreetingsFailure,
  sendGreetingsSuccess,
} from './Collaboration/CollaborationActions';
import {
  getColleagues,
  getColleaguesFailure,
  getColleaguesSuccess,
  resetSearchColleagues,
  searchColleagues,
  searchColleaguesFailure,
  searchColleaguesSuccess,
} from './Collaboration/Colleagues/colleaguesActions';
import {
  clearWellbitsState,
  getProviderColleagueWellbit,
  getProviderColleagueWellbitFailure,
  getProviderColleagueWellbitSuccess,
  getWellbitsData,
  getWellbitsDataFailure,
  getWellbitsDataSuccess,
  getWellbitWithdrawalHistory,
  getWellbitWithdrawalHistoryFailure,
  getWellbitWithdrawalHistorySuccess,
  resetTransactionHistory,
  withdrawWellbits,
  withdrawWellbitsFailure,
  withdrawWellbitsSuccess,
} from './Collaboration/Progress/progressActions';
import {
  sendDigitalPracticeLinkToContacts,
  sendDigitalPracticeLinkToContactsFailure,
  sendDigitalPracticeLinkToContactsSuccess,
} from './DigitalPracticeLinkModal/digitalPracticeLinkActions';
import {
  completeStripeOnboarding,
  completeStripeOnboardingFailure,
  completeStripeOnboardingSuccess,
  createPaystackSubaccount,
  createPaystackSubaccountFailure,
  createPaystackSubaccountSuccess,
  createStripeConnectAccount,
  createStripeConnectAccountFailure,
  createStripeConnectAccountSuccess,
  getPayPalOnboardingReferralLink,
  getPayPalOnboardingReferralLinkFailure,
  getPayPalOnboardingReferralLinkSuccess,
  getPayPalSellerOnboardingDetails,
  getPayPalSellerOnboardingDetailsFailure,
  getPayPalSellerOnboardingDetailsSuccess,
  getSouthAfricanBankList,
  getSouthAfricanBankListFailure,
  getSouthAfricanBankListSuccess,
  reauthenticateStripeOnboarding,
  reauthenticateStripeOnboardingFailure,
  reauthenticateStripeOnboardingSuccess,
  updatePayPalSellerInfo,
  updatePayPalSellerInfoFailure,
  updatePayPalSellerInfoSuccess,
  updatePaystackSubaccount,
  updatePaystackSubaccountFailure,
  updatePaystackSubaccountSuccess,
  verifyPaystackTransaction,
  verifyPaystackTransactionFailure,
  verifyPaystackTransactionSuccess,
} from './Revenue/revenueActions';

interface ColleaguesTypeTemplate {
  data: ColleaguesType[];
  offset?: string;
  hasMore: boolean;
  status: SliceStatus;
  error?: string;
  searchParams?: GetColleaguesParamsType;
}

const ColleaguesTypeTemplateDefaultValue = {
  data: [] as ColleaguesType[],
  offset: '',
  hasMore: false,
  status: SliceStatus.idle,
};

export type DigitalPracticeSliceState = {
  stripeConnect: {
    status: SliceStatus;
  };
  southAfricanBankList: {
    data: PaystackBank[];
    status: SliceStatus;
  };
  wellbits: {
    currentAvailable: number;
    wellbitTypesTotalCount: WellbitTypeTotal;
    totalWellbits: number;
    status: SliceStatus;
  };
  collaboration: {
    cashingHistory: {
      data: WellbitHistory[];
      hasMore: boolean;
      pageNo: number;
      balanceHistory: number;
    };
    status: SliceStatus;
  };
  referrals: ColleaguesTypeTemplate;
  supervisees: ColleaguesTypeTemplate;
  supervisors: ColleaguesTypeTemplate;
  snapshotColleagues: {
    referrals: ColleaguesTypeTemplate;
    supervisees: ColleaguesTypeTemplate;
    supervisors: ColleaguesTypeTemplate;
  };
  providerWellbits: {
    wellbits: Wellbit[];
    hasMore: boolean;
    pageNo: number;
    total: number;
  };
  wellbit: {
    status: SliceStatus;
  };
  status: SliceStatus;
  searchStatus: SliceStatus;
  error: string;
};

export const digitalPracticeInitialState: DigitalPracticeSliceState = {
  stripeConnect: {
    status: SliceStatus.idle,
  },
  southAfricanBankList: {
    data: [],
    status: SliceStatus.idle,
  },
  wellbits: {
    currentAvailable: 0,
    wellbitTypesTotalCount: {} as WellbitTypeTotal,
    totalWellbits: 0,
    status: SliceStatus.idle,
  },
  collaboration: {
    cashingHistory: {
      data: [] as WellbitHistory[],
      hasMore: false,
      pageNo: 1,
      balanceHistory: 0,
    },
    status: SliceStatus.idle,
  },

  referrals: ColleaguesTypeTemplateDefaultValue,
  supervisees: ColleaguesTypeTemplateDefaultValue,
  supervisors: ColleaguesTypeTemplateDefaultValue,
  snapshotColleagues: {
    referrals: ColleaguesTypeTemplateDefaultValue,
    supervisees: ColleaguesTypeTemplateDefaultValue,
    supervisors: ColleaguesTypeTemplateDefaultValue,
  },

  providerWellbits: {
    wellbits: [] as Wellbit[],
    hasMore: false,
    pageNo: 1,
    total: 0,
  },
  wellbit: {
    status: SliceStatus.idle,
  },
  status: SliceStatus.idle,
  searchStatus: SliceStatus.idle,
  error: '',
};

const digitalPracticeSlice = createSlice({
  name: 'digitalPractice',
  initialState: digitalPracticeInitialState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<DigitalPracticeSliceState>,
  ) =>
    builder
      .addCase(createStripeConnectAccount, state => ({
        ...state,
        stripeConnect: {
          ...state.stripeConnect,
          status: SliceStatus.pending,
        },
      }))
      .addCase(createStripeConnectAccountSuccess, state => ({
        ...state,
        stripeConnect: {
          ...state.stripeConnect,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(createStripeConnectAccountFailure, state => ({
        ...state,
        stripeConnect: {
          ...state.stripeConnect,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(getSouthAfricanBankList, state => ({
        ...state,
        southAfricanBankList: {
          ...state.southAfricanBankList,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getSouthAfricanBankListSuccess, (state, action) => ({
        ...state,
        southAfricanBankList: {
          data: action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getSouthAfricanBankListFailure, state => ({
        ...state,
        southAfricanBankList: {
          ...state.southAfricanBankList,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(getWellbitsData, state => ({
        ...state,
        wellbits: {
          ...state.wellbits,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getWellbitsDataSuccess, (state, action) => ({
        ...state,
        wellbits: {
          ...state.wellbits,
          wellbitTypesTotalCount: {
            ...state.wellbits.wellbitTypesTotalCount,
            ...action.payload.wellbitsTypeCount,
          },
          currentAvailable: action.payload.currentAvailableWellbits,
          totalWellbits: action.payload.totalWellbits,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getWellbitsDataFailure, state => ({
        ...state,
        wellbits: {
          ...state.wellbits,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(withdrawWellbits, state => ({
        ...state,
        wellbits: {
          ...state.wellbits,
          status: SliceStatus.pending,
        },
      }))
      .addCase(withdrawWellbitsSuccess, state => ({
        ...state,
        wellbits: {
          ...state.wellbits,
          currentAvailable: 0,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(withdrawWellbitsFailure, state => ({
        ...state,
        wellbits: {
          ...state.wellbits,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(getWellbitWithdrawalHistory, state => ({
        ...state,
        collaboration: {
          ...state.collaboration,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getWellbitWithdrawalHistorySuccess, (state, action) => ({
        ...state,
        collaboration: {
          ...state.collaboration,
          cashingHistory: {
            ...state.collaboration.cashingHistory,
            data: state.collaboration.cashingHistory.data.concat(
              action.payload.cashingHistory,
            ),
            pageNo: action.payload.nextPage,
            hasMore: action.payload.hasMore,
            balanceHistory: action.payload.balanceHistory,
          },
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getWellbitWithdrawalHistoryFailure, state => ({
        ...state,
        collaboration: {
          ...state.collaboration,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(resetTransactionHistory, state => ({
        ...state,
        collaboration: {
          ...state.collaboration,
          cashingHistory: {
            ...state.collaboration.cashingHistory,
            data: [] as WellbitHistory[],
            pageNo: 1,
            hasMore: false,
          },
        },
      }))
      .addCase(getColleagues, (state, action) => {
        const target: GetColleaguesTargetType = action.payload.target;
        return {
          ...state,
          [target]: {
            ...state[target],
            status: SliceStatus.pending,
            error: '',
          },
        };
      })
      .addCase(getColleaguesFailure, (state, action) => {
        const target = action.payload.target;
        return {
          ...state,
          [target]: {
            ...state[target],
            status: SliceStatus.rejected,
            error: action.payload.error,
          },
        };
      })
      .addCase(
        getColleaguesSuccess,
        (
          state,
          {
            payload: {
              target,
              data: {hasMore, offset, result},
            },
          },
        ) => ({
          ...state,
          [target]: {
            hasMore,
            offset,
            data: [...state[target].data, ...result],
            status: SliceStatus.resolved,
          },
          snapshotColleagues: {
            ...state.snapshotColleagues,
            [target]: {
              hasMore,
              offset,
              data: [...state[target].data, ...result],
              status: SliceStatus.resolved,
            },
          },
        }),
      )
      .addCase(searchColleagues, (state, {payload: {target}}) =>
        Object.keys(state.snapshotColleagues[target]).length === 0
          ? {
              ...state,
              snapshotColleagues: {
                ...state.snapshotColleagues,
                [target]: {...state[target], status: SliceStatus.pending},
              },
            }
          : {
              ...state,
              searchStatus: SliceStatus.pending,
            },
      )
      .addCase(
        searchColleaguesFailure,
        (state, {payload: {target, error}}) => ({
          ...state,
          [target]: {...state[target], error, status: SliceStatus.rejected},
          searchStatus: SliceStatus.rejected,
        }),
      )
      .addCase(
        searchColleaguesSuccess,
        (
          state,
          {
            payload: {
              target,
              data: {result, hasMore},
            },
          },
        ) => ({
          ...state,
          [target]: {
            data: result,
            hasMore,
            offset: '',
            status: SliceStatus.resolved,
          },
          searchStatus: SliceStatus.resolved,
        }),
      )
      .addCase(resetSearchColleagues, (state, {payload: target}) => ({
        ...state,
        [target]: {
          ...state.snapshotColleagues[target],
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getProviderColleagueWellbit, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getProviderColleagueWellbitSuccess, (state, action) => ({
        ...state,
        providerWellbits: {
          ...state.providerWellbits,
          wellbits: state.providerWellbits.wellbits.concat(
            action.payload.result,
          ),
          hasMore: action.payload.hasMore,
          pageNo: action.payload.nextPage,
          total: action.payload.total,
        },
        status: SliceStatus.resolved,
      }))
      .addCase(getProviderColleagueWellbitFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(clearWellbitsState, state => ({
        ...state,
        providerWellbits: {
          ...state.providerWellbits,
          wellbits: [] as Wellbit[],
          hasMore: false,
          pageNo: 1,
          total: 0,
        },
      }))
      .addDefaultCase(state => state),
});

export const {
  reducer: digitalPracticeReducer,
  name: digitalPracticeReducerName,
} = digitalPracticeSlice;

export type TDigitalPracticeActions =
  | ReturnType<typeof createStripeConnectAccount>
  | ReturnType<typeof createStripeConnectAccountSuccess>
  | ReturnType<typeof createStripeConnectAccountFailure>
  | ReturnType<typeof reauthenticateStripeOnboarding>
  | ReturnType<typeof reauthenticateStripeOnboardingSuccess>
  | ReturnType<typeof reauthenticateStripeOnboardingFailure>
  | ReturnType<typeof completeStripeOnboarding>
  | ReturnType<typeof completeStripeOnboardingSuccess>
  | ReturnType<typeof completeStripeOnboardingFailure>
  | ReturnType<typeof getWellbitsData>
  | ReturnType<typeof getWellbitsDataSuccess>
  | ReturnType<typeof getWellbitsDataFailure>
  | ReturnType<typeof withdrawWellbits>
  | ReturnType<typeof withdrawWellbitsSuccess>
  | ReturnType<typeof withdrawWellbitsFailure>
  | ReturnType<typeof getWellbitWithdrawalHistory>
  | ReturnType<typeof getWellbitWithdrawalHistorySuccess>
  | ReturnType<typeof getWellbitWithdrawalHistoryFailure>
  | ReturnType<typeof resetTransactionHistory>
  | ReturnType<typeof getColleagues>
  | ReturnType<typeof getColleaguesSuccess>
  | ReturnType<typeof getColleaguesFailure>
  | ReturnType<typeof searchColleagues>
  | ReturnType<typeof searchColleaguesFailure>
  | ReturnType<typeof searchColleaguesSuccess>
  | ReturnType<typeof resetSearchColleagues>
  | ReturnType<typeof sendGreetings>
  | ReturnType<typeof sendGreetingsSuccess>
  | ReturnType<typeof sendGreetingsFailure>
  | ReturnType<typeof sendDigitalPracticeLinkToContacts>
  | ReturnType<typeof sendDigitalPracticeLinkToContactsSuccess>
  | ReturnType<typeof sendDigitalPracticeLinkToContactsFailure>
  | ReturnType<typeof getProviderColleagueWellbit>
  | ReturnType<typeof getProviderColleagueWellbitSuccess>
  | ReturnType<typeof getProviderColleagueWellbitFailure>
  | ReturnType<typeof clearWellbitsState>
  | ReturnType<typeof getPayPalOnboardingReferralLink>
  | ReturnType<typeof getPayPalOnboardingReferralLinkSuccess>
  | ReturnType<typeof getPayPalOnboardingReferralLinkFailure>
  | ReturnType<typeof updatePayPalSellerInfo>
  | ReturnType<typeof updatePayPalSellerInfoSuccess>
  | ReturnType<typeof updatePayPalSellerInfoFailure>
  | ReturnType<typeof getPayPalSellerOnboardingDetails>
  | ReturnType<typeof getPayPalSellerOnboardingDetailsSuccess>
  | ReturnType<typeof getPayPalSellerOnboardingDetailsFailure>
  | ReturnType<typeof createPaystackSubaccount>
  | ReturnType<typeof createPaystackSubaccountSuccess>
  | ReturnType<typeof createPaystackSubaccountFailure>
  | ReturnType<typeof updatePaystackSubaccount>
  | ReturnType<typeof updatePaystackSubaccountSuccess>
  | ReturnType<typeof updatePaystackSubaccountFailure>
  | ReturnType<typeof getSouthAfricanBankList>
  | ReturnType<typeof getSouthAfricanBankListSuccess>
  | ReturnType<typeof getSouthAfricanBankListFailure>;

export const digitalPracticeActions = {
  createStripeConnectAccount,
  createStripeConnectAccountSuccess,
  createStripeConnectAccountFailure,
  reauthenticateStripeOnboarding,
  reauthenticateStripeOnboardingFailure,
  reauthenticateStripeOnboardingSuccess,
  completeStripeOnboarding,
  completeStripeOnboardingFailure,
  completeStripeOnboardingSuccess,
  getWellbitsData,
  getWellbitsDataSuccess,
  getWellbitsDataFailure,
  withdrawWellbits,
  withdrawWellbitsSuccess,
  withdrawWellbitsFailure,
  getWellbitWithdrawalHistory,
  getWellbitWithdrawalHistorySuccess,
  getWellbitWithdrawalHistoryFailure,
  resetTransactionHistory,
  getColleagues,
  getColleaguesFailure,
  getColleaguesSuccess,
  searchColleagues,
  searchColleaguesFailure,
  searchColleaguesSuccess,
  resetSearchColleagues,
  sendGreetingsFailure,
  sendGreetings,
  sendGreetingsSuccess,
  sendDigitalPracticeLinkToContactsFailure,
  sendDigitalPracticeLinkToContacts,
  sendDigitalPracticeLinkToContactsSuccess,
  getProviderColleagueWellbit,
  getProviderColleagueWellbitSuccess,
  getProviderColleagueWellbitFailure,
  clearWellbitsState,
  getPayPalOnboardingReferralLink,
  getPayPalOnboardingReferralLinkSuccess,
  getPayPalOnboardingReferralLinkFailure,
  updatePayPalSellerInfo,
  updatePayPalSellerInfoSuccess,
  updatePayPalSellerInfoFailure,
  getPayPalSellerOnboardingDetails,
  getPayPalSellerOnboardingDetailsSuccess,
  getPayPalSellerOnboardingDetailsFailure,
  createPaystackSubaccount,
  createPaystackSubaccountSuccess,
  createPaystackSubaccountFailure,
  updatePaystackSubaccount,
  updatePaystackSubaccountSuccess,
  updatePaystackSubaccountFailure,
  getSouthAfricanBankList,
  getSouthAfricanBankListSuccess,
  getSouthAfricanBankListFailure,
  verifyPaystackTransaction,
  verifyPaystackTransactionSuccess,
  verifyPaystackTransactionFailure,
};

export type DigitalPracticeState = ReturnType<typeof digitalPracticeReducer>;
