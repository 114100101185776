import {selectUserProfile} from 'features/User';
import {
  StripePlanDurations,
  StripePlanNames,
  StripePlanTypes,
} from 'interfaces';
import {useSelector} from 'react-redux';
import {StripeService} from 'services';
import {isMember} from 'utils';

const getNewPlanName = (planName: StripePlanTypes) => {
  switch (planName) {
    case StripePlanTypes.mindfulness:
      return StripePlanTypes.medicalcare_plus_therapy;
    case StripePlanTypes.uninsured:
      return StripePlanTypes.medicalcare;
    case StripePlanTypes.therapy:
      return StripePlanTypes.new_therapy;
    default:
      return planName;
  }
};

export function usePlanDetails(): {
  planName: string;
  userCurrentPlan: string;
  userCurrentPlanWithDuration: StripePlanNames;
  userCurrentPlanDuration: StripePlanDurations;
  insuranceImages?: {
    front: string;
    back: string;
  };
} {
  const user = useSelector(selectUserProfile);
  if (isMember(user)) {
    return {
      planName: StripeService.getPlanPageName(user),
      userCurrentPlan: getNewPlanName(user.__subPaymentPlan),
      userCurrentPlanWithDuration: user.paymentPlan,
      userCurrentPlanDuration: user.paymentPlan?.split(
        `${user.__subPaymentPlan}_`,
      )[1] as StripePlanDurations,
      insuranceImages: {
        front: user.insuranceImageFront,
        back: user.insuranceImageBack,
      },
    };
  }

  return {
    planName: StripeService.getPlanPageName(user),
    userCurrentPlan: 'N/A',
    userCurrentPlanWithDuration: StripePlanNames.uninsured,
    userCurrentPlanDuration: StripePlanDurations.monthly,
  };
}
