import * as Yup from 'yup';

export const OtpSchema = Yup.object().shape({
  code: Yup.string().test('code', 'Otp Validation', function _(value) {
    if (!value) {
      return this.createError({message: 'validations.otpReq'});
    } else if (value?.length && isNaN(+value)) {
      return this.createError({message: 'validations.otpNum'});
    } else if (value!.length < 6) {
      return this.createError({message: 'validations.otpSixDigits'});
    } else {
      return true;
    }
  }),
});
