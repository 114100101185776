import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {StripePlanDurations, StripePlanNames, UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {StripeService} from 'services';

import {paymentActions} from '../paymentSlice';

export const UpdatePlanEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.updatePlan.match),
    switchMap(
      ({
        payload: {
          paymentPlanDuration,
          paymentPlan,
          idempotencyKey,
          onSuccess,
          onError,
        },
      }) =>
        from(
          StripeService.updatePlan(
            {
              paymentPlan:
                paymentPlanDuration === StripePlanDurations.monthly
                  ? paymentPlan
                  : (`${paymentPlan}_${paymentPlanDuration}` as StripePlanNames),
              __subPaymentPlan: paymentPlan,
            },
            idempotencyKey,
          ),
        ).pipe(
          mergeMap(() => {
            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess();
            }

            return [
              paymentActions.updatePlanSuccess(),
              userActions.checkSession(UserRoles.member),
              userActions.setNotification({
                message: 'Your plan has been updated',
                messageType: 'success',
              }),
              push({
                pathname: '/dashboard',
              }),
            ];
          }),
          takeUntil(action$.pipe(filter(userActions.setUser.match))),
          catchError((message: string) => {
            if (onError && typeof onError === 'function') {
              onError(message);
            }
            return concat(
              of(
                userActions.setAsyncError({
                  filter: 'payment',
                  message,
                }),
              ),
              of(paymentActions.updatePlanFailure()),
            );
          }),
        ),
    ),
  );

export const updatePlanFailureEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(paymentActions.updatePlanFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

export const paymentUpdatePlanEpics = [UpdatePlanEpic, updatePlanFailureEpic];
