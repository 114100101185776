import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {MessagingState} from './messagingSlice';

function getUser(state: RootState): MessagingState {
  return state.messaging;
}

export const selectMessages = createSelector(
  getUser,
  messaging => messaging.messages,
);
export const selectMediaMessages = createSelector(
  getUser,
  messaging => messaging.mediaMessages,
);
export const selectLoadingMediaMessages = createSelector(
  getUser,
  messaging => messaging.mediaMessagesLoading,
);

export const selectFriendsList = createSelector(
  getUser,
  messaging => messaging.friendsList,
);

export const selectSearchedFriendsList = createSelector(
  getUser,
  messaging => messaging.searchedFriendsList,
);

export const selectUnreadMessagesCount = createSelector(
  getUser,
  messaging => messaging.unreadMessageCount,
);

export const selectParticipant = createSelector(
  getUser,
  messaging => messaging.participant,
);

export const selectParticipantById = createSelector(
  getUser,
  (_: RootState, uid: string) => uid,
  (messaging, uid) => {
    return messaging.friendsList.data.find(f => f.uid === uid);
  },
);
