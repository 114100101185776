import {
  BookAppointmentSchema,
  RescheduleAppointmentSchema,
} from 'definitions/Yup';
import * as Yup from 'yup';

export type BookAppointmentData = Yup.InferType<typeof BookAppointmentSchema>;
export type RescheduleAppointmentData = Yup.InferType<
  typeof RescheduleAppointmentSchema
>;

export enum AppointmentTypes {
  'doctor_consultation' = 'doctor_consultation',
  'chat_with_coach' = 'chat_with_coach',
  'video_call_with_therapist' = 'video_call_with_therapist',
  'group_call_with_therapist' = 'group_call_with_therapist',
  'recurring_session' = 'recurring_session',
}

export type AvailabilityInfo = {
  acuityCalendar: {availabilityDate: string; availabilityTimes: string[]}[];
  category: string;
  providerDetails: {
    email: string;
    fullName: string;
    prescriberId: string;
    mentalHealthCoachId: string;
    therapistId: string;
    acuity: {
      calendarId: number;
    };
  };
};

export enum AppointmentDurations {
  ten = '10',
  thirty = '30',
  forty_five = '45',
  sixty = '60',
}

export type BookGroupAppointmentTypes = {
  appointmentType: AppointmentTypes;
  duration: number;
  dates: string[];
  title: string;
};

export type GetProviderAvailabiilitySlotsType = {
  slots: string[];
};

export type AvailableProvider = {
  acuity: {
    calendarId: number;
  };
  fullName: string;
  email: string;
  _id: string;
  prescriberId?: string;
  therapistId?: string;
  sessionRate?: {
    digitalPractice: {
      min: number;
      max: number;
    };
  };
  countryOfResidence?: {
    code: string;
  };
};
export type AvailableProviders = AvailableProvider[];
export type Slot = {availabilityDate: string; availabilityTimes: string[]};
export type SlotsByProviderId = {
  [key: string]: Slot[];
};
export type AvailableSlots = {
  category: string;
  appointmentTypeName: string;
  appointmentTypeId: number;
  slots: Slot[];
};
export type Availability = {
  providers: AvailableProviders;
  category: string;
  appointmentTypeName: string;
  appointmentTypeId: number;
  slots: SlotsByProviderId;
};
