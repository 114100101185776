import React, {forwardRef} from 'react';
import {FieldValues} from 'react-hook-form';
import styled from 'styled-components';

import {
  AbstractFormItem,
  AbstractFormItemProps,
} from '../Base/AbstractFormItem';

type TextAreaProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = AbstractFormItemProps<TFieldValues, TContext> & {
  classes?: string;
  bgColor?: string;
  rows?: number;
  id: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  value?: string;
};

const TextArea = styled.textarea`
  ::-webkit-scrollbar {
    display: none !important;
  }
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
`;

export const ForwardsRefFormTextArea = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps<any, any>
>(
  (
    {
      id,
      placeholder,
      rows = 4,
      bgColor = 'white',
      classes,
      ...rest
    }: TextAreaProps<any, any>,
    ref,
  ) => {
    return (
      <AbstractFormItem
        id={id}
        {...rest}
        render={({field: {value, onChange}}) => (
          <TextArea
            placeholder={placeholder}
            ref={ref}
            id={id}
            rows={rows}
            value={value ?? rest.value}
            onChange={onChange}
            className={`${classes} appearance-none border border-gray-400 focus:border-primary w-full p-3 leading-tight focus:outline-none rounded-md bg-${bgColor}`}
          />
        )}
      />
    );
  },
);
