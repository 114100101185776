import React, {FC, ReactNode, Suspense} from 'react';
import Artwork from 'assets/images/artwork.png';
import ArtworkWebp from 'assets/images/artwork.webp';
import Artwork2x from 'assets/images/artwork@2x.png';
import ArtworkWebp2x from 'assets/images/artwork@2x.webp';
import Artwork3x from 'assets/images/artwork@3x.png';
import ArtworkWebp3x from 'assets/images/artwork@3x.webp';
import ProviderIllu from 'assets/images/provider_illu.png';
import ShareStory from 'assets/images/share-story.png';
import ShareStoryWebp from 'assets/images/share-story.webp';
import ShareStory2x from 'assets/images/share-story@2x.png';
import ShareStoryWebp2x from 'assets/images/share-story@2x.webp';
import ShareStory3x from 'assets/images/share-story@3x.png';
import ShareStoryWebp3x from 'assets/images/share-story@3x.webp';
import {Button} from 'components/Basic';
import {BlueLogoTextHeader} from 'components/Images';
import {LazyImage} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation} from 'react-router';
import styled from 'styled-components';

const artWorkLqip = lqip('../../assets/images/artwork.png');
const artWorkLqipProviderIllu = lqip('../../assets/images/provider_illu.png');
const shareStoryLqip = lqip('../../assets/images/share-story.png');

const ArtworkImage = styled(props => <LazyImage {...props} />)`
  border-radius: 1.5rem;
  img {
    border-radius: 1.5rem;
  }
`;

export const AuthLayout: FC<{
  children: ReactNode[] | ReactNode;
}> = ({children}) => {
  const location = useLocation();
  const {t} = useTranslation();
  const registerPage = location.pathname.includes('register');
  const providerRegister = location.pathname.includes('provider/registration');

  const OpenBlogSite = () => {
    window.open(
      'https://www.wellnite.com/blog',
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <article className="min-w-full flex h-screen">
      <section className="hidden hidden lg:block basis-[25%] shrink-0 max-w-md h-screen bg-lightOrange">
        <div className="h-16 mx-auto flex justify-center items-center flex-col relative">
          <BlueLogoTextHeader classes="absolute left-10 top-3" />
        </div>

        <div className="h-[calc(100vh_-_64px)] mx-auto flex justify-center items-center flex-col relative">
          {registerPage ? (
            <LazyImage
              lqip={shareStoryLqip}
              src={ShareStory}
              srcSet={`${ShareStory}, ${ShareStory2x} 2x, ${ShareStory3x}`}
              webp={`${ShareStoryWebp}, ${ShareStoryWebp2x} 2x, ${ShareStoryWebp3x}`}
              type="image/png"
              alt="share story"
              className="w-44 xl:w-72 mx-auto py-1 object-contain"
              aspectRatio={4 / 3}
            />
          ) : providerRegister ? (
            <ArtworkImage
              lqip={artWorkLqipProviderIllu}
              src={ProviderIllu}
              type="image/png"
              alt="artwork"
              className="w-44 xl:w-60 object-contain"
              aspectRatio={1 / 1}
            />
          ) : (
            <>
              <ArtworkImage
                lqip={artWorkLqip}
                src={Artwork}
                srcSet={`${Artwork}, ${Artwork2x} 2x, ${Artwork3x}`}
                webp={`${ArtworkWebp}, ${ArtworkWebp2x} 2x, ${ArtworkWebp3x}`}
                type="image/png"
                alt="artwork"
                className="w-44 xl:w-60 object-contain"
                aspectRatio={1 / 1}
              />
              <div className="text-center my-8 flex items-center justify-center flex-col font-montserrat">
                <h3 className="font-bold text-base xl:text-xl mb-3 leading-6">
                  <Trans i18nKey="blogArticle">
                    Check our support articles
                    <br />
                    for helpful tips and interesting
                    <br />
                    mental healthcare topics.
                  </Trans>
                </h3>
                <p className="font-inter text-sm leading-4 text-darkGray">
                  {t('avgRead')}
                </p>
              </div>
              <Button
                className="px-5 py-2 leading-4 box-border border-1 font-inter font-medium hover:bg-black hover:text-white hover:border-black"
                type="button"
                btnType="custom"
                borderColor="lightGray"
                borderRadius="full"
                textColor="darkGray"
                onClick={OpenBlogSite}
              >
                {t('readMore')}
              </Button>
            </>
          )}
        </div>
      </section>
      <section className="w-full h-screen overflow-y-auto">
        <Suspense>{children}</Suspense>
      </section>
    </article>
  );
};
