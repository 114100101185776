import React, {FC} from 'react';
import {MotionDiv} from 'components/Animation';
import {selectUserProfile} from 'features/User';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {isMember} from 'utils';

export const withoutSubscription: (
  Component: FC,
  allowAccess?: boolean,
) => () => JSX.Element = (Component, allowAccess = false) => {
  const WithoutSubscriptionWrapper = () => {
    const user = useSelector(selectUserProfile);
    if (isMember(user)) {
      if (
        user.paidOrTrialAccess?.access &&
        user.subscriptionStatus !== 'not-subscribed' &&
        allowAccess === false
      )
        return (
          <MotionDiv innerWidth={769}>
            {' '}
            <Redirect to="/dashboard" />
          </MotionDiv>
        );
      return <Component />;
    }
    return (
      <MotionDiv innerWidth={769}>
        <Redirect to="/login" />
      </MotionDiv>
    );
  };

  return WithoutSubscriptionWrapper;
};
