enum AgeGroup {
  '0_12' = '0-12',
  '13_18' = '13-18',
  '19_39' = '19-39',
  '40_59' = '40-59',
  '60_plus' = '60+',
  others = 'others',
}

interface AgeGroupData {
  count: number;
  percentage: number;
}

type PatientsByAgeGroup = {
  [key in AgeGroup]: AgeGroupData;
};

interface PatientsByConditionData {
  count: number;
  percentage: number;
  patientsCount: number;
}

interface PatientsByConditions {
  depression: PatientsByConditionData;
  anxiety: PatientsByConditionData;
  moodShift: PatientsByConditionData;
  totalPatientsCount: number;
  patientsCount: number;
}

interface ProviderExperienceRating {
  _id: number | null;
  count: number;
}

export interface WidgetsPatientsDetails {
  numberOfPatients: number;
  patientScore: number;
  patientsByAgeGroup: PatientsByAgeGroup;
  patientsByConditions: PatientsByConditions;
  providerExperienceRatingsCount: ProviderExperienceRating[];
}

interface Colleague {
  name: string;
  headshot: string;
  headshotLarge: string;
  email: string;
}

interface InsuranceDetail {
  name: string;
  headshot: string;
}

interface AvailabilityThisWeek {
  total: number;
  available: number;
}

interface PassiveEarnings {
  totalPassiveEarnings: number;
  personalEarningsLastWeek: number;
  personalEarningsCurrentWeek: number;
  personalEarningsComparisonPercentage: number;
  colleaguesEarningsLastWeek: number;
  colleaguesEarningsCurrentWeek: number;
  colleaguesEarningsComparisonPercentage: number;
}

export interface WidgetsProviderDetails {
  colleagues: {total: Colleague[]; referralCode: string};
  insuranceDetails: InsuranceDetail[];
  availabilityThisWeek: AvailabilityThisWeek;
  passiveEarnings: PassiveEarnings;
}
