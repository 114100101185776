import React, {useRef} from 'react';
import {BookAppointment} from 'features/Appointment/Booking/BookAppointment/index3';
import {AppointmentTypes, MemberProfile, UserRoles} from 'interfaces';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

type Props = {
  duration: string;
  providerRole: UserRoles;
  user: MemberProfile;
};
const BackgroundContainer = styled.div`
  position: relative;
  background-image: url('https://d2qulvgqu65efe.cloudfront.net/theme/mountains.png');
  background-color: rgb(103, 105, 170);
  background-size: cover;
  background-position: center;
  height: 100%;
`;

export function WherebyMemberLeftCallView({
  user,
  duration,
  providerRole,
}: Props): React.ReactElement {
  const {t} = useTranslation();

  const footerRef = useRef<HTMLDivElement>(null);
  return (
    <section className="w-full h-full">
      <BackgroundContainer>
        <div className="w-full h-full overflow-auto">
          <div className="p-8">
            <div className="bg-white shadow-lg rounded-lg p-4 max-w-lg mx-auto mt-5">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-bold text-gray-900">
                  {t('youveLeftTheRoom')}
                </h2>
                <span className="text-sm text-gray-500">{duration}</span>
              </div>
              <p className="text-gray-600 mt-1">{t('meetingHasEnded')}</p>
            </div>

            {providerRole === UserRoles.therapist ? (
              <div className="bg-white shadow-lg rounded-lg max-w-lg p-6 mx-auto mt-10 overflow-hidden">
                <BookAppointment
                  appointmentType={AppointmentTypes.video_call_with_therapist}
                  footerRef={footerRef}
                  member={user}
                  defaultAppointmentTypeTab={AppointmentTypes.recurring_session}
                />
                <div className="w-full" ref={footerRef} />
              </div>
            ) : null}
          </div>
        </div>
      </BackgroundContainer>
    </section>
  );
}

export default WherebyMemberLeftCallView;
