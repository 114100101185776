import styled from 'styled-components';

export const Ul = styled.ul`
  list-style: decimal;
  padding-left: 2rem;
  padding-bottom: 2rem;
  font-size: 0.9rem;

  li {
    margin-bottom: 1.5rem;
  }

  li h4 {
    font-weight: 500;
  }

  li p {
    color: #6b6b6b;
    margin-top: 0.5rem;
  }

  li table {
    margin-top: 0.7rem;
  }

  li ul {
    padding-top: 0.3rem;
  }

  li h5 {
    font-size: 0.9rem;
  }

  table {
    border-collapse: separate;
    overflow: hidden;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #e1e1e1;
    padding: 12px;
    background: #f9f9fb;
  }

  table tr:first-child td:first-child {
    border-top-left-radius: 10px;
  }

  table tr:first-child td:last-child {
    border-top-right-radius: 10px;
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`;
