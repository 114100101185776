import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {LoadingSpinner} from 'components';
import {usePaypal} from 'hooks/usePaypal';
import {EndPoints, HttpMethods} from 'interfaces';
import {Meeting} from 'interfaces/Meeting.types';
import {useTranslation} from 'react-i18next';
import {requestHandler} from 'services/api';

import {OnApproveData} from '@paypal/paypal-js';

type PaypalButtonsProps = {
  therapistId: string;
  appointmentID: string;
  appointmentStartDate: string;
  appointmentEndDate: string;
  onSuccess: () => void;
  onError: (err: string) => void;
};

export const PaypalButtons: FC<PaypalButtonsProps> = ({
  therapistId,
  appointmentID,
  appointmentStartDate,
  appointmentEndDate,
  onError,
  onSuccess,
}) => {
  const {paypal} = usePaypal();
  const [buttonsRendered, setButtonsRendered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const {t} = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const payPalButton = useMemo(
    () =>
      paypal?.Buttons &&
      paypal.Buttons({
        fundingSource: paypal?.FUNDING?.CARD,
        style: {
          layout: 'horizontal',
        },
        createOrder: async () => {
          setIsCancelled(false);
          const response = await requestHandler<{
            message: {orderId?: string};
          }>({
            method: HttpMethods.POST,
            url: `/api/patient/paypal/create-standard-order?therapistID=${therapistId}&appointmentID=${appointmentID}` as unknown as EndPoints,
            data: {},
          });
          setIsLoading(false);

          return response.data.message.orderId!;
        },
        onCancel: () => {
          setIsCancelled(true);
          setIsLoading(false);
        },
        onClick: () => {
          // on credit or debit card button click
          setIsLoading(true);
        },
        onApprove: async (data: OnApproveData) => {
          setIsLoading(true);
          await requestHandler<{
            message: Meeting;
          }>({
            method: HttpMethods.POST,
            url: `/api/patient/paypal/capture-standard-order?therapistID=${therapistId}&appointmentID=${appointmentID}&startDate=${appointmentStartDate}&endDate=${appointmentEndDate}` as unknown as EndPoints,
            data: {orderID: data.orderID},
          });

          setIsLoading(false);
          onSuccess();
        },
        onError: (err: Record<string, unknown>) => {
          console.error(JSON.stringify(err));
          setIsLoading(false);
          onError(t('chargeAppointments.paymentError'));
        },
        onInit: () => {
          setButtonsRendered(true);
        },
      }),
    [paypal],
  );

  useEffect(() => {
    if (payPalButton && !buttonsRendered) {
      setIsLoading(true);
      payPalButton?.render('#paypal-button-container').then(() => {
        setIsLoading(false);
      });
    }
  }, [payPalButton]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.contentRect.height > 100) {
            setIsLoading(false);
          } else if (!isCancelled) {
            setIsLoading(true);
          }
        }
      });
      observer.observe(container);
      return () => observer.disconnect();
    }
    return () => {};
  }, [containerRef]);

  return (
    <div className="w-full flex flex-col items-center">
      {isLoading ? <LoadingSpinner type="ThreeDots" /> : null}
      <div
        id="paypal-button-container"
        className="w-full"
        ref={containerRef}
      ></div>
    </div>
  );
};
