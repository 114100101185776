import React, {FC, lazy, Suspense} from 'react';
import classnames from 'classnames';
import {CalendarIcon} from 'components';
import {selectUnreadMessagesCount} from 'features/Messaging';
import {selectUserProfile} from 'features/User';
import {useCalendar, useRouterPush} from 'hooks';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import {componentLoader, isUserFromUnitedStates} from 'utils';

import {filterNavMenuItems, NavMenuItem} from './navigationConstants';

import 'styles/Animations.css';

const Count = styled.div`
  padding-top: 1.5px;
  font-size: 0.5rem;
`;

const MobileTabletCalendar = lazy(() =>
  componentLoader(() => import('features/Appointment/Calendar/MobileCalendar')),
);

export const MobileTabletNavigation: FC = () => {
  const {appointmentCount, isCalendarOpen, showCalendar, closeCalendar} =
    useCalendar();
  const location = useLocation();
  const {pathname} = location;
  const user = useSelector(selectUserProfile);
  const isUserFromUS = isUserFromUnitedStates(user);
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount);

  const handleClick = () => {
    showCalendar();
  };
  const {onRoutePush} = useRouterPush();
  const handleRouteClick = (url: string) => {
    closeCalendar();
    onRoutePush(url);
  };
  const count = appointmentCount();

  const menuItems = filterNavMenuItems(user!.role!, isUserFromUS);

  const appointments = count.split(' ')[0];
  return (
    <>
      <CSSTransition
        classNames="zoomIn"
        appear={true}
        unmountOnExit
        in={isCalendarOpen}
        timeout={1000}
      >
        <Suspense fallback={<div />}>
          <MobileTabletCalendar closeCalendar={closeCalendar} />
        </Suspense>
      </CSSTransition>

      <footer className="fixed z-50 inset-x-0 bottom-0 bg-white lg:hidden border-t border-gray-200">
        <nav
          className={classnames(
            'flex flex-row flex-no-wrap justify-around md:px-48 items-center h-16',
          )}
        >
          {menuItems.map(({route, icon: Icon, name}: NavMenuItem) => (
            <button
              key={route}
              aria-label={name}
              onClick={() => {
                handleRouteClick(route);
              }}
              className="relative"
            >
              <Icon
                strokeColor={
                  !isCalendarOpen && pathname === route
                    ? 'text-blue-600'
                    : undefined
                }
              />
              {route === '/message' && unreadMessagesCount ? (
                <div className="absolute -top-1 -right-1 block h-3 w-3 rounded-full text-white shadow-solid bg-blue-500 ">
                  <Count>{unreadMessagesCount}</Count>
                </div>
              ) : null}
            </button>
          ))}

          <button onClick={handleClick}>
            <span className="inline-block relative">
              <CalendarIcon
                strokeColor={isCalendarOpen ? 'text-blue-600' : undefined}
              />
              {appointments === '0' ? null : (
                <div className="absolute -top-1 -right-1 block h-3 w-3 rounded-full text-white shadow-solid bg-blue-500 ">
                  <Count>{appointments}</Count>
                </div>
              )}
            </span>
          </button>
        </nav>
      </footer>
    </>
  );
};
