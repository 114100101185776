import React from 'react';

import {SvgIcon} from './SvgIcon';

export const VerticalThreeDots: SvgIcon = ({
  width = 26,
  height = 22,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4 19"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <circle cx="2" cy="2.3999" r="2" fill="black" />
    <circle cx="2" cy="9.3999" r="2" fill="black" />
    <circle cx="2" cy="16.3999" r="2" fill="black" />
  </svg>
);
