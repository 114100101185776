import {
  addMinutes,
  differenceInYears,
  format,
  formatDistanceToNow,
  isFriday,
  isMonday,
  isSaturday,
  isThisWeek,
  isThursday,
  isToday,
  isTuesday,
  isWednesday,
  isYesterday,
} from 'date-fns';
import {format as formatTZ, utcToZonedTime} from 'date-fns-tz';
import {AvailabilityInTimeRange} from 'interfaces';

import {range} from './helpers';

const now = new Date();
const currYear = now.getFullYear();
const currMonth = now.getMonth();

export const calendarMonths = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const daysInMonth = (
  iYear: number = currYear - 13,
  iMonth: number = currMonth + 1,
): {value: number; label: string}[] => {
  const days = 32 - new Date(iYear, iMonth - 1, 32).getDate();
  return range(1, days + 1).map(day => ({label: `${day}`, value: day}));
};

export const monthsInYear = (
  iYear: number = currYear - 12,
): {value: number; label: string}[] => {
  const months = Number(iYear) === currYear - 12 ? +currMonth + 1 : 12;
  return range(1, months + 1).map(month => ({
    value: month,
    label: calendarMonths[month],
  }));
};

export const yearOptions = (): {value: number; label: string}[] => {
  return range(currYear - 90, currYear - 5)
    .map(year => ({value: year, label: `${year}`}))
    .reverse();
};

export const dateTimeStringCompatibility = (date: string): string => {
  let nth = 0;
  return `${date.replace(/-/g, match => {
    nth += 1;
    return nth < 3 ? '/' : match;
  })} 00:00:00`;
};

export const formattedDOB = (dob: Date): Date => {
  const slicedDOB = `${dob}`.slice(0, 10);
  const formattedDate = dateTimeStringCompatibility(slicedDOB);
  return new Date(formattedDate);
};

export const convertDateToDiffTimeZone = (
  time: string,
  timeZone: string,
): string => {
  let nth = 0;
  const safariFix = time
    .replace(/-/g, match => {
      nth += 1;
      return nth < 3 ? '/' : match;
    })
    .replace('T', ' ');
  const utcDate = utcToZonedTime(new Date(safariFix), timeZone);
  return formatTZ(utcDate, 'h:mm aaaa', {timeZone});
};

export function timeSince(date: string | number) {
  const seconds = Math.floor(
    (new Date().getTime() - new Date(date).getTime()) / 1000,
  );

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} hrs ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} mins ago`;
  }
  return `${Math.floor(seconds)} secs ago`;
}

export const timeFromNow = (timestamp: number): string =>
  formatDistanceToNow(timestamp, {addSuffix: true, includeSeconds: true});

export const formatChatLastMsgDate = (date: number) => {
  if (isToday(new Date(date))) return format(date, 'hh:mm a');
  if (isYesterday(new Date(date))) return 'Yesterday';
  if (isThisWeek(date)) {
    if (isMonday(new Date(date))) return 'Monday';
    if (isTuesday(new Date(date))) return 'Tuesday';
    if (isWednesday(new Date(date))) return 'Wednesday';
    if (isThursday(new Date(date))) return 'Thursday';
    if (isFriday(new Date(date))) return 'Friday';
    if (isSaturday(new Date(date))) return 'Saturday';
    else return 'Sunday';
  }

  return format(new Date(date), 'dd/MM/yy');
};

export const formatChatDate = (date: number) => {
  if (isToday(new Date(date))) return 'Today';
  if (isYesterday(new Date(date))) return 'Yesterday';

  if (isThisWeek(date)) {
    if (isMonday(new Date(date))) return 'Monday';
    if (isTuesday(new Date(date))) return 'Tuesday';
    if (isWednesday(new Date(date))) return 'Wednesday';
    if (isThursday(new Date(date))) return 'Thursday';
    if (isFriday(new Date(date))) return 'Friday';
    if (isSaturday(new Date(date))) return 'Saturday';
    else return 'Sunday';
  }

  const yearDifference = differenceInYears(new Date(date), new Date());
  if (yearDifference === 0) {
    return format(new Date(date), "iii',' MMM d");
  }

  return format(new Date(date), "MMM d',' yyyy");
};

export function calculateHrsMins(mins: number) {
  const num = mins;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  const hour =
    rhours > 0 ? (rhours == 1 ? `${rhours} hr ` : `${rhours} hr `) : '';
  const minute =
    rminutes > 0
      ? rminutes == 1
        ? `${rminutes} min`
        : `${rminutes} mins `
      : '';
  return {
    hour,
    minute,
  };
}

export function convertDateObjToUTC(dateObj: Date) {
  return Date.UTC(
    dateObj.getUTCFullYear(),
    dateObj.getUTCMonth(),
    dateObj.getUTCDate(),
    dateObj.getUTCHours(),
    dateObj.getUTCMinutes(),
    dateObj.getUTCSeconds(),
  );
}

export const convertTimeToDiffTimeZone = (
  time: string,
  timeZone: string,
  duration?: string,
): string => {
  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const timeWithGenericDate = `${currentDate}T${time}`;
  let nth = 0;
  const safariFix = timeWithGenericDate
    .replace(/-/g, match => {
      nth += 1;
      return nth < 3 ? '/' : match;
    })
    .replace('T', ' ');

  const zonedTime = utcToZonedTime(new Date(safariFix), timeZone);

  if (duration) {
    const startTime = formatTZ(zonedTime, 'h:mm aaaa', {timeZone});
    const endDate = addMinutes(zonedTime, Number(duration));
    const endTime = formatTZ(endDate, 'h:mm aaaa', {timeZone});
    return `${startTime} - ${endTime}`;
  }

  return formatTZ(zonedTime, 'h:mm aaaa', {timeZone});
};

export type LocaleString = 'en' | 'pt' | 'es';

export function mapDayToEnglish(dayName: string, language: LocaleString) {
  const lowerCaseDayName = dayName.toLowerCase();

  // Map day names from Spanish to English
  const spanishToEnglish = {
    lunes: 'Monday',
    martes: 'Tuesday',
    miércoles: 'Wednesday',
    jueves: 'Thursday',
    viernes: 'Friday',
    sábado: 'Saturday',
    domingo: 'Sunday',
  };

  // Map day names from Portuguese to English
  const portugueseToEnglish = {
    'segunda-feira': 'Monday',
    'terça-feira': 'Tuesday',
    'quarta-feira': 'Wednesday',
    'quinta-feira': 'Thursday',
    'sexta-feira': 'Friday',
    sábado: 'Saturday',
    domingo: 'Sunday',
  };

  // Select the appropriate mapping based on the language
  const mapping = language === 'es' ? spanishToEnglish : portugueseToEnglish;

  // Return the corresponding English day name
  return mapping[lowerCaseDayName] || dayName;
}

export function translateAvailabilityKeys(
  availability: AvailabilityInTimeRange,
  language: LocaleString,
) {
  const translatedAvailability = {};

  // Iterate through the original availability object
  // eslint-disable-next-line
  for (const key in availability) {
    // Translate the key to English
    const englishKey = mapDayToEnglish(key, language);

    // Add the translated key and its corresponding value to the new object
    translatedAvailability[englishKey] = availability[key];
  }

  return translatedAvailability;
}

/* 
  returns a string representing the timezone offset. e.g: +05:30, -03:00
 */
export function convertMinutesToTimezoneOffset(minutes: number) {
  const sign = minutes < 0 ? '-' : '+';

  const absMinutes = Math.abs(minutes);
  const hours = Math.floor(absMinutes / 60);
  const minutesPart = absMinutes % 60;

  const hoursString = hours.toString().padStart(2, '0');
  const minutesString = minutesPart.toString().padStart(2, '0');

  return `${sign}${hoursString}:${minutesString}`;
}
