import {CurrencyRate, SliceStatus} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  getCurrencyRateByCode,
  getCurrencyRateByCodeFailure,
  getCurrencyRateByCodeSuccess,
} from './currencyActions';

export type CurrencySliceState = {
  data: Record<string, CurrencyRate>;
  status: SliceStatus;
  error: string;
};

export const initialState: CurrencySliceState = {
  data: {},
  status: SliceStatus.idle,
  error: '',
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CurrencySliceState>) =>
    builder
      .addCase(getCurrencyRateByCode, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getCurrencyRateByCodeFailure, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(getCurrencyRateByCodeSuccess, (state, {payload}) => ({
        ...state,
        status: SliceStatus.rejected,
        data: {
          ...state.data,
          [payload.code]: payload,
        },
      }))
      .addDefaultCase(state => state),
});

export const {reducer: currencyReducer, name: currencyReducerName} =
  currencySlice;

export type TCurrencyActions =
  | ReturnType<typeof getCurrencyRateByCode>
  | ReturnType<typeof getCurrencyRateByCodeSuccess>
  | ReturnType<typeof getCurrencyRateByCodeFailure>;

export const currencyActions = {
  getCurrencyRateByCode,
  getCurrencyRateByCodeSuccess,
  getCurrencyRateByCodeFailure,
};

export type CurrencyState = ReturnType<typeof currencyReducer>;
