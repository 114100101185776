import {
  PayPalGetSellerOnboardingDetailsResponse,
  PaystackBank,
  PaystackSubaccountDetails,
  UserRoles,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const createStripeConnectAccount = createAction<{
  role: UserRoles;
}>('createStripeConnectAccount');

export const createStripeConnectAccountSuccess = createAction<{
  onboardUrl: string;
}>('createStripeConnectAccountSuccess');

export const createStripeConnectAccountFailure = createAction(
  'createStripeConnectAccountFailure',
);

export const reauthenticateStripeOnboarding = createAction<{role: string}>(
  'reauthenticateStripeOnboarding',
);
export const reauthenticateStripeOnboardingSuccess = createAction(
  'reauthenticateStripeOnboardingSuccess',
);
export const reauthenticateStripeOnboardingFailure = createAction<string>(
  'reauthenticateStripeOnboardingSuccess',
);

export const completeStripeOnboarding = createAction<{role: string}>(
  'completedStripeOnboarding',
);
export const completeStripeOnboardingSuccess = createAction(
  'completedStripeOnboardingSuccess',
);
export const completeStripeOnboardingFailure = createAction<string>(
  'completedStripeOnboardingFailure',
);

// Paypal Integration Actions
export const getPayPalOnboardingReferralLink = createAction<{
  callback: (referralLink: string) => void;
}>('getPayPalOnboardingReferralLink');

export const getPayPalOnboardingReferralLinkSuccess = createAction(
  'getPayPalOnboardingReferralLinkSuccess',
);

export const getPayPalOnboardingReferralLinkFailure = createAction<string>(
  'getPayPalOnboardingReferralLinkFailure',
);

export const updatePayPalSellerInfo = createAction<{
  merchantId: string;
  role: UserRoles;
}>('UpdatePayPalSellerInfo');
export const updatePayPalSellerInfoSuccess = createAction<string>(
  'UpdatePayPalSellerInfoSuccess',
);
export const updatePayPalSellerInfoFailure = createAction<string>(
  'UpdatePayPalSellerInfoFailure',
);

export const getPayPalSellerOnboardingDetails = createAction<{
  callback: (details: PayPalGetSellerOnboardingDetailsResponse) => void;
}>('getPayPalSellerOnboardingDetails');
export const getPayPalSellerOnboardingDetailsSuccess = createAction(
  'getPayPalSellerOnboardingDetailsSuccess',
);
export const getPayPalSellerOnboardingDetailsFailure = createAction<string>(
  'getPayPalSellerOnboardingDetailsFailure',
);

// Paystack Integration Actions
export const createPaystackSubaccount = createAction<{
  role: UserRoles;
  account: PaystackSubaccountDetails;
}>('createPaystackSubaccount');

export const createPaystackSubaccountSuccess =
  createAction<PaystackSubaccountDetails>('createPaystackSubaccountSuccess');

export const createPaystackSubaccountFailure = createAction(
  'createPaystackSubaccountFailure',
);

export const updatePaystackSubaccount = createAction<{
  role: UserRoles;
  account: PaystackSubaccountDetails;
}>('updatePaystackSubaccount');

export const updatePaystackSubaccountSuccess =
  createAction<PaystackSubaccountDetails>('updatePaystackSubaccountSuccess');

export const updatePaystackSubaccountFailure = createAction(
  'updatePaystackSubaccountFailure',
);

export const getSouthAfricanBankList = createAction<{role: UserRoles}>(
  'getSouthAfricankBankList',
);

export const getSouthAfricanBankListSuccess = createAction<PaystackBank[]>(
  'getSouthAfricankBankListSuccess',
);

export const getSouthAfricanBankListFailure = createAction(
  'getSouthAfricankBankListFailure',
);

export const verifyPaystackTransaction = createAction<{
  reference: string;
}>('verifyPaystackTransaction');

export const verifyPaystackTransactionSuccess = createAction<string>(
  'verifyPaystackTransactionSuccess',
);

export const verifyPaystackTransactionFailure = createAction<string>(
  'verifyPaystackTransactionFailure',
);
