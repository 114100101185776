import {StripePlanDurations, StripePlanTypes} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const updatePlan = createAction<{
  paymentPlan:
    | StripePlanTypes.mindfulness
    | StripePlanTypes.uninsured
    | StripePlanTypes.therapy
    | StripePlanTypes.medicalcare_plus_therapy
    | StripePlanTypes.medicalcare
    | StripePlanTypes.new_therapy
    | StripePlanTypes.together;
  paymentPlanDuration: StripePlanDurations;
  idempotencyKey: string;
  onSuccess: () => void;
  onError: (message: string) => void;
}>('UpdatePlan');
export const updatePlanSuccess = createAction('UpdatePlanSuccess');
export const updatePlanFailure = createAction('UpdatePlanFailure');
