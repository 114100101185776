import React, {Component, ErrorInfo, lazy, ReactNode, Suspense} from 'react';
import {MotionDiv} from 'components';
import {Redirect} from 'react-router-dom';
import {history} from 'utils';

import * as Sentry from '@sentry/browser';

const Modal = lazy(() => import('components/Basic/Modal'));
const ChunkErrorModal = lazy(() => import('./ChunkErrorModal'));

export class ErrorBoundary extends Component<{children: ReactNode}> {
  state = {
    hasError: false,
    errorMessage: '',
    redirect: false,
    isChunkError: false,
  };
  timeoutId: NodeJS.Timeout | null = null;
  static getDerivedStateFromError(error: Error | null): {
    hasError: boolean;
    errorMessage: string;
    isChunkError: boolean;
  } {
    const genericErrorMessage =
      'There was an error with this page. Our engineering team will sort this out shortly.';
    if (error === null) {
      return {
        hasError: true,
        errorMessage: genericErrorMessage,
        isChunkError: false,
      };
    }
    const isChunkError =
      (error && error.message.includes('Loading chunk')) ||
      (error && error.name === 'ChunkLoadError');

    return {
      hasError: true,
      errorMessage: isChunkError
        ? 'Failed to load a component. Refreshing...'
        : genericErrorMessage,
      isChunkError,
    };
  }

  componentDidCatch(error: Error | null, errorInfo: ErrorInfo): void {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });

    if (this.state.isChunkError) {
      // Trigger a page reload after a short delay to show the chunk error message
      this.timeoutId = setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }
  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
  handleButtonFn = () => {
    this.setState({hasError: false});
    history.push('/dashboard');
  };

  render(): React.ReactNode {
    if (this.state.redirect) {
      return (
        <MotionDiv innerWidth={769}>
          <Redirect to="/" />
        </MotionDiv>
      );
    }

    return this.state.hasError ? (
      <div>
        <Suspense fallback={<div />}>
          {this.state.isChunkError ? (
            <ChunkErrorModal />
          ) : (
            <Modal
              messageType="error"
              message={this.state.errorMessage}
              isOpen={true}
              buttonFn={this.handleButtonFn}
            />
          )}
        </Suspense>
      </div>
    ) : (
      this.props.children
    );
  }
}
