import {useCallback, useEffect, useMemo, useState} from 'react';
import {RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {addDays, format} from 'date-fns';
import {
  selectAppointmentStatus,
  selectAvailabilitySlots,
} from 'features/Appointment';
import {
  bookRecurringAppointment,
  getProviderRecurringAvailableSlots,
  resetProviderAvailableSlots,
} from 'features/Appointment/Booking/bookingActions';
import {providerActions} from 'features/Provider';
import {selectMemberById} from 'features/Provider/Members/membersSelectors';
import {selectUserProfile} from 'features/User';
import {
  AppointmentDurations,
  AppointmentTypes,
  OptionType,
  SliceStatus,
} from 'interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {BookingService} from 'services';
import {
  calculateStartDate,
  convertTimeToDiffTimeZone,
  isProvider,
  isTherapist,
} from 'utils';

export function useRecurringBookingV2(duration: string, startDate?: Date) {
  const user = useSelector(selectUserProfile);
  const memberTimezone = BookingService.isCountryOfResidenceRegion(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    user!.countryOfResidence.code,
  )
    ? Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
    : BookingService.getCountryDefaultTimeZone(user!.countryOfResidence.code);

  const defaultOptionRecurrence: OptionType = {value: '6', label: '6 weeks'};
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [recurrence, setRecurrence] = useState<OptionType>(
    defaultOptionRecurrence,
  );
  const [noteMessage, setNoteMessage] = useState('');

  const [bookingCharge, setBookingCharge] = useState({
    status: SliceStatus.idle,
    showModal: false,
    amount: 0,
  });

  const availabilitySlots = useSelector(selectAvailabilitySlots);
  const availabilitySlotsToMemberTimezone = availabilitySlots?.slots;
  const appointmentStatus = useSelector(selectAppointmentStatus);
  const dispatch = useDispatch();

  const isLoading = availabilitySlots.loading === SliceStatus.pending;
  const isLoadingBooking =
    appointmentStatus === SliceStatus.pending ||
    bookingCharge.status === SliceStatus.pending;

  const availabilityRequestResolved =
    availabilitySlots.loading === SliceStatus.resolved;

  const location = useLocation<{id: string}>();
  const id = location?.state?.id;

  const member = useSelector((state: RootState) => {
    return selectMemberById(state, id);
  });
  const effectiveStartDate = useMemo(() => {
    return calculateStartDate(selectedDay, startDate);
  }, [selectedDay]);

  const [memberEmail, setMemberEmail] = useState('');

  useEffect(() => {
    return () => {
      dispatch(resetProviderAvailableSlots());
    };
  }, []);
  useEffect(() => {
    const handleGetAvailability = () => {
      if (selectedDay) {
        dispatch(
          getProviderRecurringAvailableSlots({
            duration: duration,
            startDate: effectiveStartDate as string,
            numberOfWeeks: recurrence.value,
            ...(isTherapist(user) && {
              patientEmail: memberEmail,
            }),
          }),
        );
      }
    };
    handleGetAvailability();
  }, [duration, recurrence.value, selectedDay]);

  const computeEndDate = useCallback(() => {
    if (effectiveStartDate) {
      const endDate = addDays(
        new Date(effectiveStartDate),
        7 * (Number(recurrence.value) - 1),
      );
      return format(endDate, 'EEEE, d LLL yyyy');
    }
    return '';
  }, [recurrence.value, selectedDay]);

  const memberPresentInState = Boolean(location?.state?.id);

  useEffect(() => {
    if (isProvider(user) && memberPresentInState) {
      dispatch(
        providerActions.getMemberById({
          patientId: id,
          role: user!.role,
        }),
      );
    }
  }, [id, user!.role, memberPresentInState]);

  useEffect(() => {
    if (isProvider(user) && memberPresentInState) {
      setMemberEmail(member?.email);
    }
  }, [member, memberPresentInState]);

  const handleInputChange = (value: any) => {
    setMemberEmail(value);
  };

  const timeInProviderTimeZone = availabilitySlots?.slots?.find(
    time => convertTimeToDiffTimeZone(time, memberTimezone) === selectedTime,
  );
  const appointmentDateTimeString = `${effectiveStartDate}T${timeInProviderTimeZone}`;

  const bookAppointment = () => {
    const appointmentData = {
      numberOfWeeks: Number(recurrence.value),
      therapistId: isTherapist(user)
        ? user?.therapistId
        : (user?.therapistDetails.therapistId as string),
      appointmentStartDateTimeString: appointmentDateTimeString,
      patientTimezone: memberTimezone,
      appointmentDuration: duration,
      noteMessage,
      ...(isTherapist(user) && {
        patientEmail: memberEmail,
      }),
    };

    dispatch(bookRecurringAppointment(appointmentData));
  };

  const checkbookingChargeAndbookAppointment = async () => {
    try {
      setBookingCharge({...bookingCharge, status: SliceStatus.pending});
      const res = await BookingService.getBookingCharge({
        role: user!.role,
        patientEmail: isTherapist(user) ? memberEmail : user!.email,
        appointmentType: AppointmentTypes.video_call_with_therapist,
        numberOfWeeks: recurrence.value,
        isRecurring: 'true',
        appointmentDuration: duration as AppointmentDurations,
      });
      if (res.data.message.charge > 0) {
        setBookingCharge({
          ...bookingCharge,
          status: SliceStatus.resolved,
          amount: res.data.message.charge,
          showModal: true,
        });
      } else {
        setBookingCharge({
          ...bookingCharge,
          status: SliceStatus.resolved,
          showModal: false,
        });
        bookAppointment();
      }
    } catch (error) {
      setBookingCharge({
        ...bookingCharge,
        status: SliceStatus.rejected,
        showModal: false,
      });
      Toast({
        type: 'error',
        message: 'oops! something went wrong, please try booking again.',
      });
    }
  };

  const closeExtraChargeModal = () => {
    setBookingCharge({
      ...bookingCharge,
      showModal: false,
    });
  };

  const onConfirmBooking = () => {
    bookAppointment();
    setBookingCharge({...bookingCharge, showModal: false});
  };

  return {
    isLoading,
    timeSlots: availabilitySlotsToMemberTimezone,
    selectedTime,
    setSelectedTime,
    selectedDay,
    setSelectedDay,
    recurrence,
    setRecurrence,
    availabilityRequestResolved,
    computeEndDate,
    checkbookingChargeAndbookAppointment,
    bookingCharge,
    closeExtraChargeModal,
    onConfirmBooking,
    isLoadingBooking,
    member,
    memberEmail,
    setNoteMessage,
    memberTimezone,
    handleInputChange,
    memberPresentInState,
  };
}
