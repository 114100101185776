import React from 'react';

import {SvgIcon} from './SvgIcon';

export const SortIcon: SvgIcon = ({
  width = 26,
  height = 22,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 9.5"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M0,.75C0,.34,.34,0,.75,0H20.25c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75H.75c-.41,0-.75-.34-.75-.75ZM2,4.75c0-.41,.34-.75,.75-.75h15.5c.41,0,.75,.34,.75,.75s-.34,.75-.75,.75H2.75c-.41,0-.75-.34-.75-.75Zm3.75,3.25c-.41,0-.75,.34-.75,.75s.34,.75,.75,.75H15.25c.41,0,.75-.34,.75-.75s-.34-.75-.75-.75H5.75Z"
    />
  </svg>
);
