import {useCallback} from 'react';
import {useHistory} from 'react-router';

export function useRouterPush(): {
  onRoutePush: (pathname: string, options?: {[key: string]: unknown}) => void;
} {
  const router = useHistory();
  const onRoutePush = useCallback(
    (pathname: string, options = {}) => {
      router.push({pathname, ...options});
    },
    [router],
  );

  return {
    onRoutePush,
  };
}
