import * as Yup from 'yup';

export const DirectBookingSchema = Yup.object()
  .shape({
    profession: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    conditions: Yup.array().of(Yup.string()).required('Required'),
    paymentType: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    languages: Yup.array().of(Yup.string()).required('Required'),
    ethnicity: Yup.array().of(Yup.string()).required('Required'),
    education: Yup.string().required('Required'),
  })
  .required();
