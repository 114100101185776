import React, {FC, useMemo} from 'react';
import {WithAsyncPaginateType} from 'components/Basic/Form/Select/models';
import {detachedSearchBarStyles} from 'components/Basic/Form/Select/styles';
import {DetachedSearchSelect, FormSelect} from 'components/Basic/Form/V2';
import {selectUserProfile} from 'features/User';
import {useBooking} from 'hooks';
import {
  AppointmentTypes,
  Availability,
  BookAppointmentData,
  DigitalPracticeAccountTypes,
  DirectBookingProviderType,
  OptionType,
  SliceStatus,
  UserAccountType,
} from 'interfaces';
import {UseFormSetValue} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AsyncPaginate} from 'react-select-async-paginate';
import {getCountryDetailsByIsoCode} from 'utils';

type Props = {
  appointmentType?: AppointmentTypes;
  directBookingProvider?: DirectBookingProviderType;
  availability: Availability;
  providerType: string;
  providerLoadingStatus: SliceStatus;
  slotLoadingStatus: SliceStatus;
  setValue: UseFormSetValue<BookAppointmentData>;
  portalContainer?: Element | DocumentFragment;
} & Partial<ReturnType<typeof useBooking>>;

export const ProviderChooser: FC<Props> = ({
  control,
  appointmentType,
  directBookingProvider,
  availability,
  providerType,
  setValue,
  providerLoadingStatus,
  slotLoadingStatus,
  portalContainer,
}: Props) => {
  const {t} = useTranslation();
  const user = useSelector(selectUserProfile);
  const isDPOOPMember =
    user?.digitalPracticeAccountType ===
    DigitalPracticeAccountTypes.outOfPocket;

  const countryCode = user?.countryOfResidence?.code || 'US';
  const providerCountryDetails = getCountryDetailsByIsoCode(countryCode);
  const currency = providerCountryDetails?.currency;
  const currencySymbol = providerCountryDetails?.currencySymbol;

  const dpOOPSessionRate = `${currency} ${currencySymbol}${
    user?.therapistDetails?.sessionRate?.digitalPractice?.min || 0
  }-${user?.therapistDetails?.sessionRate?.digitalPractice?.max || 0}`;

  const providerOptions: OptionType[] = useMemo(() => {
    const mapProvider = (providerDetails: {
      fullName: string;
      acuity: {
        calendarId: number;
      };
      prescriberId?: string;
      therapistId?: string;
      _id: string;
      email: string;
    }) => ({
      label: isDPOOPMember
        ? `${providerDetails.fullName} (${t('provider_rate', {
            value: dpOOPSessionRate,
          })})`
        : providerDetails.fullName,
      value:
        providerDetails?.prescriberId ?? providerDetails?.therapistId ?? '',
    });

    if (directBookingProvider) {
      return [
        {
          label: directBookingProvider.providerFullName ?? '',
          value: directBookingProvider.providerId,
        },
        ...(Array.isArray(availability?.providers)
          ? availability.providers
              .filter(p => p?.therapistId !== directBookingProvider.providerId)
              .map(mapProvider)
          : []),
      ];
    }

    const scaleTherapistCall =
      user?.accountType === UserAccountType.scale &&
      appointmentType === AppointmentTypes.video_call_with_therapist;

    if (scaleTherapistCall) {
      return availability && Array.isArray(availability?.providers)
        ? availability.providers
            .filter(p => p?.therapistId === user.therapistDetails.therapistId)
            .map(mapProvider)
        : [];
    }

    return availability && Array.isArray(availability?.providers)
      ? availability?.providers?.map(x => ({
          label: x.fullName,
          value: x?.therapistId ?? x?.prescriberId ?? '',
          calendarId: x.acuity.calendarId,
        }))
      : [];
  }, [user, availability]);

  return control ? (
    <FormSelect
      control={control}
      id="providerId"
      options={providerOptions as []}
      isPaginated={false}
      detachedSearchBar={true}
      debounceTimeout={1000}
      loadingMessage={() => `Loading ${providerType.toLowerCase()}...`}
      classes="-mt-6"
      isMulti={false}
      isSearchable={true}
      isAsync={false}
      extractValue={true}
      portalContainer={portalContainer}
      styles={{
        ...detachedSearchBarStyles,
        detachedButton: (isOpen: boolean, hasValue: boolean) => {
          const styles = detachedSearchBarStyles.detachedButton!(
            isOpen,
            hasValue,
          );
          return {
            ...styles,
            borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
            color: hasValue ? 'black' : '#6B6B6B',
          };
        },
      }}
      isLoading={providerLoadingStatus === SliceStatus.pending}
      isDisabled={
        providerLoadingStatus === SliceStatus.pending ||
        slotLoadingStatus === SliceStatus.pending
      }
      placeholder={`Choose Your ${providerType}`}
      label={null}
      onChange={(v: any) => {
        setValue('providerId', v.value);
      }}
      noOptionsMessage={e =>
        e.inputValue
          ? `No ${providerType.toLowerCase()} found : ${e.inputValue}`
          : 'No options'
      }
    />
  ) : (
    <DetachedSearchSelect
      id="providerId"
      selectComponent={AsyncPaginate as WithAsyncPaginateType}
      options={providerOptions}
      isPaginated={true}
      debounceTimeout={1000}
      loadingMessage={() => `Loading ${providerType.toLowerCase()}...`}
      isMulti={false}
      isSearchable={true}
      isLoading={providerLoadingStatus === SliceStatus.pending}
      isDisabled={
        providerLoadingStatus === SliceStatus.pending ||
        slotLoadingStatus === SliceStatus.pending
      }
      styles={{
        ...detachedSearchBarStyles,
        detachedButton: (isOpen: boolean, hasValue: boolean) => {
          const styles = detachedSearchBarStyles.detachedButton!(
            isOpen,
            hasValue,
          );
          return {
            ...styles,
            borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
            color: hasValue ? 'black' : '#6B6B6B',
          };
        },
      }}
      placeholder={`Choose Your ${providerType}`}
      onChange={(v: any) => {
        setValue('providerId', v.value);
      }}
    ></DetachedSearchSelect>
  );
};
