import {setUser, userActions} from 'features/User/userSlice';
import {
  Appointment,
  Availability,
  AvailabilityInfo,
  AvailableProvider,
  GroupCallAppointmentLabelsIDs,
  SliceStatus,
} from 'interfaces';

import {
  ActionReducerMapBuilder,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  bookAppointment,
  bookAppointmentFailure,
  bookAppointmentSuccess,
  bookGroupAppointment,
  bookGroupAppointmentFailure,
  bookGroupAppointmentSuccess,
  bookOneTimeAppointment,
  bookOneTimeAppointmentFailure,
  bookOneTimeAppointmentSuccess,
  bookRecurringAppointment,
  bookRecurringAppointmentFailure,
  bookRecurringAppointmentSuccess,
  cancelAppointment,
  cancelAppointmentFailure,
  cancelAppointmentSuccess,
  getAvailableProviders,
  getAvailableProvidersFailure,
  getAvailableProvidersSuccess,
  getAvailableSlots,
  getAvailableSlotsFailure,
  getAvailableSlotsSuccess,
  getProviderDataForAppointment,
  getProviderDataForAppointmentFailure,
  getProviderDataForAppointmentSuccess,
  getProviderRecurringAvailableSlots,
  getProviderRecurringAvailableSlotsFailure,
  getProviderRecurringAvailableSlotsSuccess,
  resetProviderAvailableSlots,
  updateGroupCallAppointmentLabel,
  updateGroupCallAppointmentLabelFailure,
  updateGroupCallAppointmentLabelSuccess,
} from './Booking/bookingActions';
import {
  getCalendarAppointments,
  getProviderCalendarAppointments,
} from './Calendar/calendarActions';
import {
  chargeDigitalPracticeAppointment,
  chargeDigitalPracticeAppointmentFailure,
  chargeDigitalPracticeAppointmentSuccess,
  payLaterRequest,
  payLaterRequestFailure,
  payLaterRequestSuccess,
  setAppointmentPrice,
  setAppointmentPriceFailure,
  setAppointmentPriceSuccess,
  standardCheckoutChargeSuccess,
} from './Charge/chargeActions';
import {
  submitOnboardingData,
  submitOnboardingDataFailed,
  submitOnboardingDataSuccess,
} from './Onboarding/onboardingActions';
import {
  sendPaymentRequest,
  sendPaymentRequestFailure,
  sendPaymentRequestSuccess,
} from './SendPaymentRequest/SendPaymentRequestActions';
// import {availabilityInfoAdapter} from './appointmentAdapters';

const addAppointment = createAction<Appointment>('addAppointment');
const removeAppointment = createAction<Appointment>('removeAppointment');

export function removeUndefinedIds(availabilityInfo: {
  [key: string]: AvailabilityInfo;
}) {
  const keys = Object.keys(availabilityInfo).filter(key => key !== 'undefined');
  const filteredData = keys.reduce<{
    [key: string]: AvailabilityInfo;
  }>((prev, curr) => {
    prev[curr] = availabilityInfo[curr];
    return prev;
  }, {});
  return filteredData;
}

type AppointmentSliceState = {
  availabilitySlots: {
    slots: string[];
    loading: SliceStatus;
  };

  status: SliceStatus;
  appointments: Appointment[];
  dueAppointments: number[];
  loading: SliceStatus;
  error: string;

  availability: Availability;
  providersLoadingStatus: SliceStatus;
  slotsLoadingStatus: SliceStatus;

  // provider
  provider: {
    data: AvailableProvider;
    status: SliceStatus;
  };
};

export const initialState = {
  availabilitySlots: {
    slots: [],
    loading: SliceStatus.idle,
  },

  status: SliceStatus.idle,
  appointments: [] as Appointment[],
  dueAppointments: [],
  loading: SliceStatus.idle,
  error: '',

  availability: {} as Availability,
  providersLoadingStatus: SliceStatus.idle,
  slotsLoadingStatus: SliceStatus.idle,
  provider: {
    data: {} as AvailableProvider,
    status: SliceStatus.idle,
  },
};

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AppointmentSliceState>) =>
    builder
      .addCase(userActions.setAsyncError, (state, action) => ({
        ...state,
        error:
          action.payload.filter === 'appointment'
            ? action.payload.message
            : state.error,
      }))
      .addCase(userActions.resetAsyncError, (state, action) => ({
        ...state,
        error: action.payload === 'appointment' ? '' : state.error,
      }))
      .addCase(getCalendarAppointments.pending, state => {
        state.loading = SliceStatus.pending;
      })
      .addCase(getCalendarAppointments.fulfilled, (state, action) => {
        state.loading = SliceStatus.resolved;
        state.appointments = action.payload ?? [];
      })
      .addCase(getCalendarAppointments.rejected, state => {
        state.loading = SliceStatus.rejected;
      })
      .addCase(bookAppointment, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(bookAppointmentFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(
        bookAppointmentSuccess,
        (state, action: PayloadAction<Appointment>) => {
          state.status = SliceStatus.resolved;
          state.loading = SliceStatus.idle;
          state.appointments.push(action.payload);
        },
      )
      .addCase(bookOneTimeAppointment, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(bookOneTimeAppointmentFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(bookOneTimeAppointmentSuccess, state => {
        state.status = SliceStatus.resolved;
        state.loading = SliceStatus.idle;
      })
      .addCase(cancelAppointmentSuccess, (state, action) => {
        state.appointments = state.appointments.filter(
          appointment => appointment.appointmentID !== action.payload,
        );
      })
      .addCase(submitOnboardingData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(submitOnboardingDataSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(submitOnboardingDataFailed, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(chargeDigitalPracticeAppointment, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(chargeDigitalPracticeAppointmentSuccess, (state, action) => {
        const updatedAppointments = state.appointments.map(appointment => {
          if (
            appointment.appointmentID === action.payload.message.appointmentID
          ) {
            return {...appointment, meeting: {...action.payload.message}};
          }
          return appointment;
        });
        return {
          ...state,
          appointments: updatedAppointments,
          status: SliceStatus.resolved,
        };
      })
      .addCase(standardCheckoutChargeSuccess, (state, action) => {
        const updatedAppointments = state.appointments.map(appointment => {
          if (
            appointment.appointmentID === action.payload.message.appointmentID
          ) {
            return {
              ...appointment,
              meeting: {...appointment.meeting!, ...action.payload.message},
            };
          }
          return appointment;
        });
        return {
          ...state,
          appointments: updatedAppointments,
        };
      })
      .addCase(chargeDigitalPracticeAppointmentFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(payLaterRequest, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(payLaterRequestSuccess, (state, action) => {
        return {
          ...state,
          dueAppointments: action.payload.message.dueAppointments,
          status: SliceStatus.resolved,
        };
      })
      .addCase(setUser, (state, action) => {
        return {
          ...state,
          dueAppointments: action.payload.dueAppointments,
        };
      })
      .addCase(payLaterRequestFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(setAppointmentPrice, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(setAppointmentPriceSuccess, (state, action) => {
        const updatedAppointments = state.appointments.map(appointment => {
          if (
            appointment.appointmentID === action.payload.message.appointmentID
          ) {
            return {...appointment, meeting: {...action.payload.message}};
          }
          return appointment;
        });
        return {
          ...state,
          appointments: updatedAppointments,
          status: SliceStatus.resolved,
        };
      })
      .addCase(setAppointmentPriceFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(bookGroupAppointment, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(bookGroupAppointmentFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(bookGroupAppointmentSuccess, (state, action) => {
        state.status = SliceStatus.resolved;
        state.appointments.push(action.payload);
      })
      .addCase(updateGroupCallAppointmentLabel, state => {
        state.status = SliceStatus.pending;
        state.loading = SliceStatus.pending;
      })
      .addCase(updateGroupCallAppointmentLabelSuccess, (state, action) => {
        const {appointmentID, message} = action.payload;
        const getAppointmentIdx = state.appointments.findIndex(
          apt => apt.appointmentID === appointmentID,
        );
        const getAppointment = state.appointments[getAppointmentIdx];
        const [groupCallStatus] = message;
        const {id} = groupCallStatus;

        if (
          !getAppointment?.labels &&
          id !== GroupCallAppointmentLabelsIDs.joined
        ) {
          getAppointment.labels = message;
        }

        if (
          id !== GroupCallAppointmentLabelsIDs.joined &&
          getAppointment.labels?.length
        ) {
          getAppointment.labels[0] = groupCallStatus;
        }

        if (
          id === GroupCallAppointmentLabelsIDs.joined &&
          getAppointment.labels?.length
        ) {
          getAppointment.labels[1] = groupCallStatus;
        }

        state.status = SliceStatus.resolved;
        state.loading = SliceStatus.resolved;
      })
      .addCase(updateGroupCallAppointmentLabelFailure, state => {
        state.status = SliceStatus.rejected;
        state.loading = SliceStatus.rejected;
      })
      .addCase(getProviderRecurringAvailableSlots, state => {
        state.availabilitySlots.loading = SliceStatus.pending;
      })
      .addCase(getProviderRecurringAvailableSlotsSuccess, (state, action) => {
        state.availabilitySlots.loading = SliceStatus.resolved;
        state.availabilitySlots.slots = action.payload.slots;
      })
      .addCase(getProviderRecurringAvailableSlotsFailure, state => {
        state.availabilitySlots.loading = SliceStatus.rejected;
      })
      .addCase(resetProviderAvailableSlots, state => {
        state.availabilitySlots.slots = [];
      })
      .addCase(bookRecurringAppointment, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(bookRecurringAppointmentFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(
        bookRecurringAppointmentSuccess,
        (state, action: PayloadAction<Appointment[]>) => {
          state.status = SliceStatus.resolved;
          state.loading = SliceStatus.idle;
          state.appointments.push(...action.payload);
        },
      )
      .addCase(getAvailableProviders, state => ({
        ...state,
        providersLoadingStatus: SliceStatus.pending,
      }))
      .addCase(getAvailableProvidersFailure, state => ({
        ...state,
        providersLoadingStatus: SliceStatus.rejected,
      }))
      .addCase(getAvailableProvidersSuccess, (state, action) => ({
        ...state,
        availability: {
          ...state.availability,
          providers: action.payload,
          slots: {},
        },
        providersLoadingStatus: SliceStatus.resolved,
      }))
      .addCase(getAvailableSlots, state => ({
        ...state,
        slotsLoadingStatus: SliceStatus.pending,
      }))
      .addCase(getAvailableSlotsFailure, state => ({
        ...state,
        slotsLoadingStatus: SliceStatus.rejected,
      }))
      .addCase(getAvailableSlotsSuccess, (state, action) => ({
        ...state,
        availability: {
          ...state.availability,
          ...action.payload.data,
          slots: {
            [action.payload.providerId]: action.payload.data.slots,
          },
        },
        slotsLoadingStatus: SliceStatus.resolved,
      }))
      .addCase(getProviderDataForAppointment, state => ({
        ...state,
        provider: {
          ...state.provider,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getProviderDataForAppointmentFailure, state => ({
        ...state,
        provider: {
          ...state.provider,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(getProviderDataForAppointmentSuccess, (state, action) => ({
        ...state,
        providerLoadingStatus: SliceStatus.resolved,
        provider: {
          ...state.provider,
          data: action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(addAppointment, (state, action) => {
        const idx = state.appointments.findIndex(
          apt => apt.appointmentID === action.payload.appointmentID,
        );
        if (idx === -1) {
          state.appointments.push(action.payload);
        } else {
          state.appointments[idx] = action.payload;
        }
      })
      .addCase(removeAppointment, (state, action) => {
        const idx = state.appointments.findIndex(
          apt => apt.appointmentID === action.payload.appointmentID,
        );
        state.appointments.splice(idx, 1);
        return state;
      })

      .addCase(sendPaymentRequest, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(sendPaymentRequestFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(sendPaymentRequestSuccess, (state, action) => {
        const appointments = state.appointments.map(appointment =>
          appointment.appointmentID === action.payload.message.appointmentID
            ? {...appointment, meeting: {...action.payload.message}}
            : appointment,
        );

        return {
          ...state,
          appointments,
          status: SliceStatus.resolved,
        };
      })
      .addDefaultCase(state => state),
});

export const {reducer: appointmentReducer, name: appointmentReducerName} =
  appointmentSlice;

export type TAppointmentActions =
  | ReturnType<typeof submitOnboardingData>
  | ReturnType<typeof submitOnboardingDataSuccess>
  | ReturnType<typeof submitOnboardingDataFailed>
  | ReturnType<typeof bookAppointment>
  | ReturnType<typeof bookAppointmentSuccess>
  | ReturnType<typeof bookAppointmentFailure>
  | ReturnType<typeof bookOneTimeAppointment>
  | ReturnType<typeof bookOneTimeAppointmentSuccess>
  | ReturnType<typeof bookOneTimeAppointmentFailure>
  | ReturnType<typeof cancelAppointment>
  | ReturnType<typeof cancelAppointmentSuccess>
  | ReturnType<typeof cancelAppointmentFailure>
  | ReturnType<typeof chargeDigitalPracticeAppointment>
  | ReturnType<typeof chargeDigitalPracticeAppointmentSuccess>
  | ReturnType<typeof standardCheckoutChargeSuccess>
  | ReturnType<typeof chargeDigitalPracticeAppointmentFailure>
  | ReturnType<typeof payLaterRequest>
  | ReturnType<typeof payLaterRequestSuccess>
  | ReturnType<typeof payLaterRequestFailure>
  | ReturnType<typeof setAppointmentPrice>
  | ReturnType<typeof setAppointmentPriceSuccess>
  | ReturnType<typeof setAppointmentPriceFailure>
  | ReturnType<typeof bookGroupAppointment>
  | ReturnType<typeof bookGroupAppointmentSuccess>
  | ReturnType<typeof bookGroupAppointmentFailure>
  | ReturnType<typeof updateGroupCallAppointmentLabel>
  | ReturnType<typeof updateGroupCallAppointmentLabelSuccess>
  | ReturnType<typeof updateGroupCallAppointmentLabelFailure>
  | ReturnType<typeof getProviderRecurringAvailableSlots>
  | ReturnType<typeof getProviderRecurringAvailableSlotsSuccess>
  | ReturnType<typeof getProviderRecurringAvailableSlotsFailure>
  | ReturnType<typeof resetProviderAvailableSlots>
  | ReturnType<typeof bookRecurringAppointment>
  | ReturnType<typeof bookRecurringAppointmentSuccess>
  | ReturnType<typeof bookRecurringAppointmentFailure>
  | ReturnType<typeof getAvailableProviders>
  | ReturnType<typeof getAvailableProvidersSuccess>
  | ReturnType<typeof getAvailableProvidersFailure>
  | ReturnType<typeof getAvailableSlots>
  | ReturnType<typeof getAvailableSlotsSuccess>
  | ReturnType<typeof getAvailableSlotsFailure>
  | ReturnType<typeof getProviderDataForAppointment>
  | ReturnType<typeof getProviderDataForAppointmentSuccess>
  | ReturnType<typeof getProviderDataForAppointmentFailure>
  | ReturnType<typeof addAppointment>
  | ReturnType<typeof removeAppointment>
  | ReturnType<typeof sendPaymentRequest>
  | ReturnType<typeof sendPaymentRequestFailure>
  | ReturnType<typeof sendPaymentRequestSuccess>;

export const appointmentActions = {
  submitOnboardingData,
  submitOnboardingDataSuccess,
  submitOnboardingDataFailed,
  bookAppointment,
  bookAppointmentSuccess,
  bookAppointmentFailure,
  bookOneTimeAppointment,
  bookOneTimeAppointmentSuccess,
  bookOneTimeAppointmentFailure,
  cancelAppointment,
  cancelAppointmentSuccess,
  cancelAppointmentFailure,
  getCalendarAppointments,
  getProviderCalendarAppointments,
  chargeDigitalPracticeAppointment,
  chargeDigitalPracticeAppointmentFailure,
  chargeDigitalPracticeAppointmentSuccess,
  standardCheckoutChargeSuccess,
  payLaterRequest,
  payLaterRequestFailure,
  payLaterRequestSuccess,
  setAppointmentPrice,
  setAppointmentPriceSuccess,
  setAppointmentPriceFailure,
  bookGroupAppointment,
  bookGroupAppointmentSuccess,
  bookGroupAppointmentFailure,
  updateGroupCallAppointmentLabel,
  updateGroupCallAppointmentLabelSuccess,
  updateGroupCallAppointmentLabelFailure,
  getProviderRecurringAvailableSlots,
  getProviderRecurringAvailableSlotsSuccess,
  getProviderRecurringAvailableSlotsFailure,
  resetProviderAvailableSlots,
  bookRecurringAppointment,
  bookRecurringAppointmentSuccess,
  bookRecurringAppointmentFailure,
  getAvailableProviders,
  getAvailableProvidersFailure,
  getAvailableProvidersSuccess,
  getAvailableSlots,
  getAvailableSlotsFailure,
  getAvailableSlotsSuccess,
  getProviderDataForAppointment,
  getProviderDataForAppointmentFailure,
  getProviderDataForAppointmentSuccess,
  addAppointment,
  removeAppointment,
  sendPaymentRequest,
  sendPaymentRequestFailure,
  sendPaymentRequestSuccess,
};

export type AppointmentState = ReturnType<typeof appointmentReducer>;
