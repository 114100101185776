import React, {
  MouseEventHandler,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import {isIOS} from 'utils/responsiveUtils';

import {TStylesConfig} from '../models';

const DropdownMenu = styled.div``;

const FullScreenMenu = styled.div`
  background-color: white;
  overflow: hidden;
  inset: 0;
  position: fixed;
  z-index: 2;
`;

export const Menu = ({
  children,
  fullScreen,
  placeholder,
  onCloseClick,
  containerRef,
  styles,
  portalContainer,
}: {
  children: ReactNode;
  fullScreen?: boolean;
  placeholder?: string;
  onCloseClick: MouseEventHandler;
  containerRef: RefObject<HTMLElement>;
  styles: TStylesConfig;
  portalContainer?: Element | DocumentFragment;
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element || !containerRef.current) {
      return;
    }

    const containerRect = containerRef.current.getBoundingClientRect();

    const containerBottom = containerRect.bottom;
    const containerLeft = containerRect.left;

    const outsideOfRightScreenEdge =
      containerLeft! + element.getBoundingClientRect().width >
      window.innerWidth;
    const outsideOfBottomScreenEdge =
      containerBottom! + element.getBoundingClientRect().height >
      window.innerHeight;

    if (outsideOfRightScreenEdge) {
      element.style.right = 10 + 'px';
    } else {
      element.style.left = containerLeft + 'px';
    }

    if (outsideOfBottomScreenEdge) {
      element.style.bottom = 10 + 'px';
    } else {
      element.style.top = containerBottom + 'px';
    }
  }, []);

  const container = useMemo(
    () =>
      // in order to avoid z-index issues with modal render it within dialog
      document.querySelector('#headlessui-portal-root [role="dialog"]')! ||
      document.body,
    [],
  );

  return createPortal(
    fullScreen ? (
      <FullScreenMenu>
        <div className="font-inter text-base flex justify-between p-6 border-b border-gray-400">
          <span className="capitalize text-xl">{placeholder}</span>
          <a
            href=""
            onClick={e => {
              e.preventDefault();
              onCloseClick(e);
            }}
            className="text-blue-600 focus:outline-none underline underline-offset-[2px]"
          >
            Done
          </a>
        </div>
        <div className="font-inter text-base">{children}</div>
      </FullScreenMenu>
    ) : (
      <DropdownMenu ref={elementRef} style={styles.dropdownMenu!()}>
        {children}
      </DropdownMenu>
    ),
    portalContainer ?? isIOS() ? document.body : container,
  );
};
