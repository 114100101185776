import * as Yup from 'yup';

const BaseRegisterSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'validations.min')
    .max(50, 'validations.max')
    .required('validations.required')
    .test('invalid', 'validations.invalidFullName', name =>
      /^[a-zA-Z]+ [a-zA-Z]+/.test(name || ''),
    ),
  email: Yup.string()
    .trim()
    .min(2, 'validations.min')
    .max(50, 'validations.max')
    .email('validations.invalidEmail')
    .required('validations.required'),
  password: Yup.string()
    .min(8, 'validations.min')
    .max(50, 'validations.max')
    .required('validations.required'),
  phoneNumber: Yup.string()
    .required('validations.required')
    .test('invalid', 'validations.invalidPhoneNumber', phone =>
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
        phone || '',
      ),
    ),
  termsOfService: Yup.boolean()
    .required('validations.toc')
    .test('accepted', 'validations.toc', tos => !!tos),
});

export const RegisterSchema = BaseRegisterSchema.concat(
  Yup.object()
    .shape({
      stateOfResidence: Yup.string().required('validations.required'),
    })
    .required(),
);

export const RegisterOutsideUSASchema = BaseRegisterSchema.clone().shape({
  countryOfResidence: Yup.object().shape({
    code: Yup.string().required('validations.required'),
    name: Yup.string().required('validations.required'),
  }),
});
