export const icdCodes = [
  {value: 'G20', label: 'Parkinson’s Disease'},
  {value: 'G30', label: 'Alzheimer’s Disease'},
  {value: 'G90', label: 'Disorders Of Autonomic Nervous System'},
  {
    value: 'F06.1',
    label: 'Catatonic Disorder Due To Known Physiological Condition',
  },
  {value: 'F25.0', label: 'Schizoaffective disorder, bipolar type'},
  {
    value: 'F32.0',
    label: 'Major Depressive Disorder, Single Episode, Mild',
  },
  {
    value: 'F32.1',
    label: 'Major Depressive Disorder, Single Episode, Moderate',
  },
  {
    value: 'F32.81',
    label: 'Premenstrual dysphoric disorder',
  },
  {
    value: 'F32.9',
    label: 'Major Depressive Disorder, Single Episode, Unspecified',
  },
  {
    value: 'F33.0',
    label: 'Major Depressive Disorder, Recurrent, Mild',
  },
  {
    value: 'F33.1',
    label: 'Major Depressive Disorder, Recurrent, Moderate',
  },
  {
    value: 'F33.2',
    label:
      'Major Depressive Disorder, Recurrent Severe Without Psychotic Features',
  },
  {value: 'F34.1', label: 'Dysthymic Disorder'},
  {value: 'F40.228', label: 'Other Natural Environment Type Phobia'},
  {value: 'F41.0', label: 'Panic Disorder Without Agoraphobia'},
  {value: 'F41.1', label: 'Generalized Anxiety Disorder'},
  {value: 'F41.9', label: 'Anxiety Disorder, Unspecified'},
  {value: 'F42.2', label: 'Mixed Obsessional Thoughts And Acts'},
  {
    value: 'F43.10',
    label: 'Post-Traumatic Stress Disorder, Unspecified',
  },
  {value: 'F43.12', label: 'Post-Traumatic Stress Disorder, Chronic'},
  {value: 'F43.20', label: 'Adjustment Disorder, Unspecified'},
  {value: 'F43.21', label: 'Adjustment Disorder With Depressed Mood'},
  {value: 'F43.22', label: 'Adjustment Disorder With Anxiety'},
  {
    value: 'F43.23',
    label: 'Adjustment Disorder With Mixed Anxiety And Depressed Mood',
  },
  {
    value: 'F43.25',
    label: 'Adjustment Disorder W Mixed Disturb Of Emotions And Conduct',
  },
  {value: 'F50.81', label: 'Binge eating disorder'},
  {
    value: 'F52.8',
    label:
      'Other sexual dysfunction not due to a substance or known physiological condition',
  },
  {value: 'F60.9', label: 'Personality Disorder, Unspecified'},
  {value: 'F84.0', label: 'Childhood Autism'},
  {
    value: 'F90.0',
    label:
      'Attention-Deficit Hyperactivity Disorder, Predominantly Inattentive Type',
  },
  {
    value: 'F90.2',
    label: 'Attention-Deficit Hyperactivity Disorder, Combined Type',
  },
  {value: 'F1010', label: 'Alcohol Abuse, Uncomplicated'},
  {
    value: 'F10120',
    label: 'Alcohol Abuse With Intoxication, Uncomplicated',
  },
  {
    value: 'F10129',
    label: 'Alcohol Abuse With Intoxication, Unspecified',
  },
  {value: 'F1020', label: 'Alcohol Dependence, Uncomplicated'},
  {value: 'F1021', label: 'Alcohol Dependence, In Remission'},
  {
    value: 'F10220',
    label: 'Alcohol Dependence With Intoxication, Uncomplicated',
  },
  {
    value: 'F10229',
    label: 'Alcohol Dependence With Intoxication, Unspecified',
  },
  {
    value: 'F10230',
    label: 'Alcohol Dependence With Withdrawal, Uncomplicated',
  },
  {
    value: 'F10239',
    label: 'Alcohol Dependence With Withdrawal, Unspecified',
  },
  {
    value: 'F10920',
    label: 'Alcohol Use, Unspecified With Intoxication, Uncomplicated',
  },
  {
    value: 'F10929',
    label: 'Alcohol Use, Unspecified With Intoxication, Unspecified',
  },
  {value: 'F1110', label: 'Opioid Abuse, Uncomplicated'},
  {
    value: 'F11120',
    label: 'Opioid Abuse With Intoxication, Uncomplicated',
  },
  {
    value: 'F11129',
    label: 'Opioid Abuse With Intoxication, Unspecified',
  },
  {value: 'F1120', label: 'Opioid Dependence, Uncomplicated'},
  {value: 'F1121', label: 'Opioid Dependence, In Remission'},
  {
    value: 'F11220',
    label: 'Opioid Dependence With Intoxication, Uncomplicated',
  },
  {
    value: 'F11229',
    label: 'Opioid Dependence With Intoxication, Unspecified',
  },
  {value: 'F1123', label: 'Opioid Dependence With Withdrawal'},
  {value: 'F1190', label: 'Opioid Use, Unspecified, Uncomplicated'},
  {
    value: 'F11920',
    label: 'Opioid Use, Unspecified With Intoxication, Uncomplicated',
  },
  {
    value: 'F11929',
    label: 'Opioid Use, Unspecified With Intoxication, Unspecified',
  },
  {value: 'F1193', label: 'Opioid Use, Unspecified With Withdrawal'},
  {value: 'F1210', label: 'Cannabis Abuse, Uncomplicated'},
  {
    value: 'F12120',
    label: 'Cannabis Abuse With Intoxication, Uncomplicated',
  },
  {
    value: 'F12129',
    label: 'Cannabis Abuse With Intoxication, Unspecified',
  },
  {value: 'F1220', label: 'Cannabis Dependence, Uncomplicated'},
  {value: 'F1221', label: 'Cannabis Dependence, In Remission'},
  {
    value: 'F12220',
    label: 'Cannabis Dependence With Intoxication, Uncomplicated',
  },
  {
    value: 'F12229',
    label: 'Cannabis Dependence With Intoxication, Unspecified',
  },
  {value: 'F1290', label: 'Cannabis Use, Unspecified, Uncomplicated'},
  {
    value: 'F12920',
    label: 'Cannabis Use, Unspecified With Intoxication, Uncomplicated',
  },
  {
    value: 'F12929',
    label: 'Cannabis Use, Unspecified With Intoxication, Unspecified',
  },
  {
    value: 'F1310',
    label: 'Sedative, Hypnotic Or Anxiolytic Abuse, Uncomplicated',
  },
  {
    value: 'F13120',
    label:
      'Sedative, Hypnotic Or Anxiolytic Abuse With Intoxication, Uncomplicated',
  },
  {
    value: 'F13129',
    label:
      'Sedative, Hypnotic Or Anxiolytic Abuse With Intoxication, Unspecified',
  },
  {
    value: 'F1320',
    label: 'Sedative, Hypnotic Or Anxiolytic Dependence, Uncomplicated',
  },
  {
    value: 'F1321',
    label: 'Sedative, Hypnotic Or Anxiolytic Dependence, In Remission',
  },
  {
    value: 'F13220',
    label:
      'Sedative, Hypnotic Or Anxiolytic Dependence With Intoxication, Uncomplicated',
  },
  {
    value: 'F13229',
    label:
      'Sedative, Hypnotic Or Anxiolytic Dependence With Intoxication, Unspecified',
  },
  {
    value: 'F13230',
    label:
      'Sedative, Hypnotic Or Anxiolytic Dependence With Withdrawal, Uncomplicated',
  },
  {
    value: 'F13239',
    label:
      'Sedative, Hypnotic Or Anxiolytic Dependence With Withdrawal, Unspecified',
  },
  {
    value: 'F1390',
    label: 'Sedative, Hypnotic, Or Anxiolytic Use, Unspecified, Uncomplicated',
  },
  {
    value: 'F13920',
    label:
      'Sedative, Hypnotic Or Anxiolytic Use, Unspecified With Intoxication, Uncomplicated',
  },
];
