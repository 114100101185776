import {
  checkout,
  checkoutFailure,
  checkoutSuccess,
} from 'features/Payment/Checkout/checkoutActions';
import {
  resubscribe,
  resubscribeFailure,
  resubscribeSuccess,
} from 'features/Payment/ResubscriptionCheckout/resubscriptionCheckoutActions';
import {userActions} from 'features/User/userSlice';
import {
  CancellationValues,
  PayPalCardDetails,
  PaystackCardDetail,
  Receipt,
  SliceStatus,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  addCreditCard,
  addCreditCardFailure,
  addCreditCardSuccess,
  deletePaymentCardOnPayPal,
  deletePaymentCardOnPayPalFailure,
  deletePaymentCardOnPayPalSuccess,
  deletePaymentCardOnPaystack,
  deletePaymentCardOnPaystackFailure,
  deletePaymentCardOnPaystackSuccess,
  getPayPalPaymentCardsList,
  getPayPalPaymentCardsListFailure,
  getPayPalPaymentCardsListSuccess,
  getPaystackPaymentCardsList,
  getPaystackPaymentCardsListFailure,
  getPaystackPaymentCardsListSuccess,
  setDefaultCard,
  setDefaultCardFailure,
  setDefaultCardSuccess,
  setPayPalDefaultPaymentCard,
  setPayPalDefaultPaymentCardFailure,
  setPayPalDefaultPaymentCardSuccess,
  setPaystackDefaultPaymentCard,
  setPaystackDefaultPaymentCardFailure,
  setPaystackDefaultPaymentCardSuccess,
} from './CreditCardMgmt/creditCardMgmtActions';
import {
  saveInsuranceImage,
  saveInsuranceImageFailure,
  saveInsuranceImageSuccess,
} from './CurrentPlan/currentPlanActions';
import {
  digitalPracticeInsuranceCheckout,
  digitalPracticeInsuranceCheckoutFailure,
  digitalPracticeInsuranceCheckoutSuccess,
  digitalPracticeOutOfPocketCheckout,
  digitalPracticeOutOfPocketCheckoutFailure,
  digitalPracticeOutOfPocketCheckoutSuccess,
  initiatePaystackTransaction,
  initiatePaystackTransactionFailure,
  initiatePaystackTransactionSuccess,
} from './DigitalPracticeCheckout/DigitalPracticeCheckoutActions';
import {
  annualPlanInterest,
  annualPlanInterestFailure,
  annualPlanInterestSuccess,
  cancelSubscription,
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  pauseSubscription,
  pauseSubscriptionFailure,
  pauseSubscriptionSuccess,
  resetManageSubscriptionState,
  setManageSubscriptionState,
} from './ManageSubscription/manageSubscriptionActions';
import {
  onboardingOneTimePayment,
  onboardingOneTimePaymentFailure,
  onboardingOneTimePaymentSuccess,
} from './OnboardingOneTimePayment/onboardingOneTimePaymentActions';
import {
  fetchReceiptPdf,
  fetchReceiptPdfFailure,
  fetchReceiptPdfSuccess,
  fetchReceipts,
  fetchReceiptsFailure,
  fetchReceiptsSuccess,
} from './Receipts/receiptActions';
import {
  updatePlan,
  updatePlanFailure,
  updatePlanSuccess,
} from './UpdatePlan/updatePlanActions';

type PaymentSliceState = {
  status: SliceStatus;
  error: string;
  manageSubStatus: {
    subscriptionState:
      | 'upgrade'
      | 'pause'
      | 'confirmCancel'
      | 'cancel'
      | 'none';
    data?: number | string | CancellationValues;
  };
  paypalCards: PayPalCardDetails[];
  paystackCards: PaystackCardDetail[];
  paystackAuthorization: {
    status: SliceStatus;
    url?: string;
  };
  receipts: Receipt[];
};

const sortPaypalCardsByDefaultCard = (
  cards: PayPalCardDetails[],
  defaultCard?: string,
) =>
  defaultCard
    ? cards.reduce(
        (acc, card) =>
          card.cardDetails.paymentToken === defaultCard
            ? [card, ...acc]
            : [...acc, card],
        [] as PayPalCardDetails[],
      )
    : cards;

const sortPaystackCardsByDefaultCard = (
  cards: PaystackCardDetail[],
  defaultCard?: string,
) =>
  defaultCard
    ? cards.reduce(
        (acc, card) =>
          card.paymentToken === defaultCard ? [card, ...acc] : [...acc, card],
        [] as PaystackCardDetail[],
      )
    : cards;

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    status: SliceStatus.idle,
    error: '',
    paypalCards: [],
    paystackCards: [],
    receipts: [],
    paystackAuthorization: {status: SliceStatus.idle},
    manageSubStatus: {
      subscriptionState: 'none',
      data: undefined,
    } as {
      subscriptionState:
        | 'upgrade'
        | 'pause'
        | 'confirmCancel'
        | 'cancel'
        | 'none';
      data?: number | string | CancellationValues;
    },
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PaymentSliceState>) =>
    builder
      .addCase(checkout, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(checkoutFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(checkoutSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(resubscribe, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(resubscribeFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(resubscribeSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(addCreditCard, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(addCreditCardFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(addCreditCardSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(getPayPalPaymentCardsList, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(getPayPalPaymentCardsListFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(
        getPayPalPaymentCardsListSuccess,
        (state, {payload: {message, defaultCard}}) => {
          state.paypalCards = sortPaypalCardsByDefaultCard(
            message.creditCards,
            defaultCard,
          );
          state.status = SliceStatus.resolved;
        },
      )
      .addCase(
        setPayPalDefaultPaymentCardSuccess,
        (state, {payload: {newPaypalDefaultCard}}) => {
          state.paypalCards = sortPaypalCardsByDefaultCard(
            state.paypalCards,
            newPaypalDefaultCard,
          );
        },
      )
      .addCase(getPaystackPaymentCardsList, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(getPaystackPaymentCardsListFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(getPaystackPaymentCardsListSuccess, (state, action) => {
        state.paystackCards = action.payload.data;
        state.status = SliceStatus.resolved;
      })
      .addCase(setPaystackDefaultPaymentCardSuccess, (state, {payload}) => {
        state.paystackCards = sortPaystackCardsByDefaultCard(
          state.paystackCards,
          payload.newPaystackDefaultCard,
        );
      })
      .addCase(updatePlan, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(updatePlanFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(updatePlanSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(annualPlanInterest, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(annualPlanInterestFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(annualPlanInterestSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(pauseSubscription, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(pauseSubscriptionFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(pauseSubscriptionSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(cancelSubscription, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(cancelSubscriptionFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(cancelSubscriptionSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(saveInsuranceImage, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(saveInsuranceImageFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(saveInsuranceImageSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(onboardingOneTimePayment, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(onboardingOneTimePaymentFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(onboardingOneTimePaymentSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(fetchReceipts, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(fetchReceiptsFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(fetchReceiptsSuccess, (state, action) => {
        state.status = SliceStatus.resolved;
        state.receipts = action.payload;
      })
      .addCase(digitalPracticeInsuranceCheckout, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(digitalPracticeInsuranceCheckoutFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(digitalPracticeInsuranceCheckoutSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(digitalPracticeOutOfPocketCheckout, state => {
        state.status = SliceStatus.pending;
      })
      .addCase(digitalPracticeOutOfPocketCheckoutSuccess, state => {
        state.status = SliceStatus.resolved;
      })
      .addCase(digitalPracticeOutOfPocketCheckoutFailure, state => {
        state.status = SliceStatus.rejected;
      })
      .addCase(initiatePaystackTransaction, state => {
        state.paystackAuthorization = {
          status: SliceStatus.pending,
        };
      })
      .addCase(initiatePaystackTransactionFailure, state => {
        state.paystackAuthorization = {
          status: SliceStatus.rejected,
        };
      })

      .addCase(userActions.setAsyncError, (state, action) => {
        state.error =
          action.payload.filter === 'payment'
            ? action.payload.message
            : state.error;
      })
      .addCase(userActions.resetAsyncError, (state, action) => {
        state.error = action.payload === 'payment' ? '' : state.error;
      })
      .addCase(setManageSubscriptionState, (state, action) => ({
        ...state,
        manageSubStatus: action.payload,
      }))
      .addCase(resetManageSubscriptionState, (state, action) => ({
        ...state,
        manageSubStatus: action.payload,
      }))
      .addCase(deletePaymentCardOnPaystackSuccess, (state, action) => {
        state.paystackCards = state.paystackCards.filter(
          card => card.paymentToken !== action.payload,
        );
      })
      .addDefaultCase(() => {}),
});

export const {reducer: paymentReducer, name: paymentReducerName} = paymentSlice;

export type TPaymentActions =
  | ReturnType<typeof checkout>
  | ReturnType<typeof checkoutSuccess>
  | ReturnType<typeof checkoutFailure>
  | ReturnType<typeof digitalPracticeInsuranceCheckout>
  | ReturnType<typeof digitalPracticeInsuranceCheckoutSuccess>
  | ReturnType<typeof digitalPracticeInsuranceCheckoutFailure>
  | ReturnType<typeof digitalPracticeOutOfPocketCheckout>
  | ReturnType<typeof digitalPracticeOutOfPocketCheckoutSuccess>
  | ReturnType<typeof digitalPracticeOutOfPocketCheckoutFailure>
  | ReturnType<typeof initiatePaystackTransaction>
  | ReturnType<typeof initiatePaystackTransactionSuccess>
  | ReturnType<typeof initiatePaystackTransactionFailure>
  | ReturnType<typeof resubscribe>
  | ReturnType<typeof resubscribeSuccess>
  | ReturnType<typeof resubscribeFailure>
  | ReturnType<typeof addCreditCard>
  | ReturnType<typeof addCreditCardSuccess>
  | ReturnType<typeof addCreditCardFailure>
  | ReturnType<typeof updatePlan>
  | ReturnType<typeof updatePlanSuccess>
  | ReturnType<typeof updatePlanFailure>
  | ReturnType<typeof cancelSubscription>
  | ReturnType<typeof cancelSubscriptionSuccess>
  | ReturnType<typeof cancelSubscriptionFailure>
  | ReturnType<typeof setDefaultCard>
  | ReturnType<typeof setDefaultCardSuccess>
  | ReturnType<typeof setDefaultCardFailure>
  | ReturnType<typeof saveInsuranceImageFailure>
  | ReturnType<typeof saveInsuranceImageSuccess>
  | ReturnType<typeof saveInsuranceImage>
  | ReturnType<typeof onboardingOneTimePaymentFailure>
  | ReturnType<typeof onboardingOneTimePaymentSuccess>
  | ReturnType<typeof onboardingOneTimePayment>
  | ReturnType<typeof fetchReceiptsFailure>
  | ReturnType<typeof fetchReceiptsSuccess>
  | ReturnType<typeof fetchReceipts>
  | ReturnType<typeof annualPlanInterest>
  | ReturnType<typeof annualPlanInterestSuccess>
  | ReturnType<typeof annualPlanInterestFailure>
  | ReturnType<typeof pauseSubscription>
  | ReturnType<typeof pauseSubscriptionSuccess>
  | ReturnType<typeof pauseSubscriptionFailure>
  | ReturnType<typeof setManageSubscriptionState>
  | ReturnType<typeof resetManageSubscriptionState>
  | ReturnType<typeof getPayPalPaymentCardsList>
  | ReturnType<typeof getPayPalPaymentCardsListFailure>
  | ReturnType<typeof getPayPalPaymentCardsListSuccess>
  | ReturnType<typeof setPayPalDefaultPaymentCard>
  | ReturnType<typeof setPayPalDefaultPaymentCardFailure>
  | ReturnType<typeof setPayPalDefaultPaymentCardSuccess>
  | ReturnType<typeof deletePaymentCardOnPayPal>
  | ReturnType<typeof deletePaymentCardOnPayPalFailure>
  | ReturnType<typeof deletePaymentCardOnPayPalSuccess>
  | ReturnType<typeof getPaystackPaymentCardsList>
  | ReturnType<typeof getPaystackPaymentCardsListFailure>
  | ReturnType<typeof getPaystackPaymentCardsListSuccess>
  | ReturnType<typeof setPaystackDefaultPaymentCard>
  | ReturnType<typeof setPaystackDefaultPaymentCardFailure>
  | ReturnType<typeof setPaystackDefaultPaymentCardSuccess>
  | ReturnType<typeof deletePaymentCardOnPaystack>
  | ReturnType<typeof deletePaymentCardOnPaystackFailure>
  | ReturnType<typeof deletePaymentCardOnPaystackSuccess>;

export const paymentActions = {
  checkout,
  checkoutSuccess,
  checkoutFailure,
  digitalPracticeInsuranceCheckout,
  digitalPracticeInsuranceCheckoutSuccess,
  digitalPracticeInsuranceCheckoutFailure,
  digitalPracticeOutOfPocketCheckout,
  digitalPracticeOutOfPocketCheckoutFailure,
  digitalPracticeOutOfPocketCheckoutSuccess,
  initiatePaystackTransaction,
  initiatePaystackTransactionSuccess,
  initiatePaystackTransactionFailure,
  resubscribe,
  resubscribeSuccess,
  resubscribeFailure,
  addCreditCard,
  addCreditCardSuccess,
  addCreditCardFailure,
  updatePlan,
  updatePlanSuccess,
  updatePlanFailure,
  setDefaultCard,
  setDefaultCardFailure,
  setDefaultCardSuccess,
  saveInsuranceImageFailure,
  saveInsuranceImageSuccess,
  saveInsuranceImage,
  onboardingOneTimePaymentFailure,
  onboardingOneTimePaymentSuccess,
  onboardingOneTimePayment,
  fetchReceiptsFailure,
  fetchReceiptsSuccess,
  fetchReceipts,
  fetchReceiptPdf,
  fetchReceiptPdfSuccess,
  fetchReceiptPdfFailure,
  annualPlanInterest,
  annualPlanInterestFailure,
  annualPlanInterestSuccess,
  pauseSubscription,
  pauseSubscriptionFailure,
  pauseSubscriptionSuccess,
  cancelSubscription,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  setManageSubscriptionState,
  resetManageSubscriptionState,
  getPayPalPaymentCardsList,
  getPayPalPaymentCardsListFailure,
  getPayPalPaymentCardsListSuccess,
  setPayPalDefaultPaymentCard,
  setPayPalDefaultPaymentCardFailure,
  setPayPalDefaultPaymentCardSuccess,
  deletePaymentCardOnPayPal,
  deletePaymentCardOnPayPalFailure,
  deletePaymentCardOnPayPalSuccess,
  getPaystackPaymentCardsList,
  getPaystackPaymentCardsListFailure,
  getPaystackPaymentCardsListSuccess,
  setPaystackDefaultPaymentCard,
  setPaystackDefaultPaymentCardFailure,
  setPaystackDefaultPaymentCardSuccess,
  deletePaymentCardOnPaystack,
  deletePaymentCardOnPaystackFailure,
  deletePaymentCardOnPaystackSuccess,
};

export type PaymentState = ReturnType<typeof paymentReducer>;
