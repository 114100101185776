import React, {FC} from 'react';
import Logo from 'assets/images/logo-blue.png';
import LogoWebp from 'assets/images/logo-blue.webp';
import Logo2x from 'assets/images/logo-blue@2x.png';
import LogoWebp2x from 'assets/images/logo-blue@2x.webp';
import Logo3x from 'assets/images/logo-blue@3x.png';
import LogoWebp3x from 'assets/images/logo-blue@3x.webp';
import {LazyImage} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const logoLqip = lqip('../../assets/images/logo-blue.png');

const Image = styled(props => <LazyImage {...props} />)`
  img {
    object-fit: contain;
  }
`;
type Props = {
  classes?: string;
  imgClasses?: string;
};

export const BlueLogoHeader: FC<Props> = ({
  classes = 'pl-10',
  imgClasses = 'w-20 h-20',
}) => (
  <header>
    <nav className={classes}>
      <div className={imgClasses}>
        <Link to="/">
          <Image
            src={Logo}
            srcSet={`${Logo}, ${Logo2x} 2x, ${Logo3x}`}
            webp={`${LogoWebp}, ${LogoWebp2x} 2x, ${LogoWebp3x}`}
            type="image/png"
            className={imgClasses}
            aspectRatio={7.5}
            alt="Wellnite Logo links to home"
            lqip={logoLqip}
          />
        </Link>
      </div>
    </nav>
  </header>
);
