import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {WellniteExerciseState} from './wellniteExerciseSlice';

export function getWellniteExercise(state: RootState): WellniteExerciseState {
  return state.wellniteExercise;
}

export const selectWellniteGeneratedExercise = createSelector(
  getWellniteExercise,
  state => state.generatedExercise,
);

export const selectWellniteExercise = createSelector(
  getWellniteExercise,
  state => state.exercise,
);

export const selectWellniteRegeneratedQuestions = createSelector(
  getWellniteExercise,
  state => state.questions,
);

export const selectWellniteRegenerateExerciseStatus = createSelector(
  getWellniteExercise,
  state => state.regenerateExerciseStatus,
);

export const selectExerciseFormStep = createSelector(
  getWellniteExercise,
  state => state.formStep,
);

export const selectWellniteExercises = createSelector(
  getWellniteExercise,
  state => state.exercises,
);

export const selectWellniteExercisesStatus = createSelector(
  getWellniteExercise,
  state => state.exercisesStatus,
);
