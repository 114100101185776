import React, {FC} from 'react';
import classnames from 'classnames';
import {FormError, FormLabel} from 'components';
import {motion} from 'framer-motion';
import {useBooking} from 'hooks';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

type Props = {
  label?: string;
  unavailableText?: string;
} & Partial<ReturnType<typeof useBooking>>;

const TimeGridStyled = styled.section`
  grid-auto-rows: 23%;
`;

const now = new Date();

const twentyFourHours = new Date().getTime() + 24 * 60 * 60 * 1000;

export const TimeGrid: FC<Props> = ({
  time,
  errors,
  watch,
  register,
  selectedTime,
  label,
  unavailableText,
}: Props) => {
  if (!time) {
    return null;
  }

  const {t} = useTranslation();
  return (
    <motion.article
      className="my-5 pt-5 border-t transform ease-in-out duration-1000"
      animate={{opacity: 1, y: 0}}
      initial={{opacity: 0, y: 100}}
    >
      {label ? (
        <FormLabel
          htmlFor="time"
          className="font-medium text-center text-sm"
          error={errors?.time?.message}
        >
          {label}
        </FormLabel>
      ) : null}
      <TimeGridStyled
        className={classnames(
          'grid gap-4 grid-cols-4 h-56 pr-1 overflow-auto mt-5',
        )}
      >
        {time.length && now.toDateString() !== watch('date')?.toDateString() ? (
          time.reduce(
            (total: React.ReactNode[], current) =>
              Date.parse(`${current.rawTime}`) > twentyFourHours
                ? [
                    ...total,
                    <label
                      key={current.value}
                      className={classnames(
                        'leading-snug rounded-lg h-10 font-medium inline-flex items-center justify-center cursor-pointer text-xs whitespace-no-wrap',
                        {
                          'border border-gray-400': !current.disabled,
                          'bg-gray-500': current.disabled,
                          'bg-blue-600 text-white border border-blue-600':
                            current.value === selectedTime,
                        },
                      )}
                    >
                      <input
                        type="radio"
                        {...register?.('time')}
                        className="hidden"
                        value={current.value}
                        disabled={current.disabled}
                      />
                      <span>{current.value}</span>
                    </label>,
                  ]
                : total,
            [],
          )
        ) : (
          <p className="col-span-4 text-center pt-5">
            {unavailableText ??
              t('noAvailableTimeToday', 'No available times for this day.')}
          </p>
        )}
      </TimeGridStyled>
      {time.length ? <FormError error={errors?.time?.message} /> : null}
    </motion.article>
  );
};
