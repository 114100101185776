import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {AccountService} from 'services/api';

import {memberActions} from '../memberSlice';

export const changePasswordEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changePassword.match),
    mergeMap(({payload}) =>
      from(AccountService.changePassword(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          memberActions.changePasswordSuccess(),
          userActions.setUser(user),
          push({
            pathname: '/dashboard',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(memberActions.changePasswordFailure()),
            of(
              userActions.setAsyncError({
                filter: 'member',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const changeEmailEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changeEmail.match),
    mergeMap(({payload}) =>
      from(AccountService.changeEmail(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          memberActions.changeEmailSuccess(),
          userActions.setUser(user),
          push({
            pathname: '/dashboard',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(memberActions.changeEmailFailure()),
            of(
              userActions.setAsyncError({
                filter: 'member',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const changeAddressEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changeAddress.match),
    mergeMap(({payload}) =>
      from(AccountService.changeAddress(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          memberActions.changeAddressSuccess(),
          userActions.setUser(user),
          push({
            pathname: '/dashboard',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(memberActions.changeAddressFailure()),
            of(
              userActions.setAsyncError({
                filter: 'member',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const changePhoneEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changePhone.match),
    mergeMap(({payload}) =>
      from(AccountService.changePhone(payload)).pipe(
        mergeMap(() => [
          userActions.checkSession(UserRoles.member),
          memberActions.changePhoneSuccess(),
          push({
            pathname: '/dashboard',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(memberActions.changePhoneFailure()),
            of(
              userActions.setAsyncError({
                filter: 'member',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const changeProfilePictureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changeProfilePicture.match),
    mergeMap(({payload: {onError, onSuccess, image}}) =>
      from(AccountService.changeProfilePicture(image)).pipe(
        concatMap(({data: {message: user}}) => {
          onSuccess();
          return of(
            userActions.setUser(user),
            memberActions.changeProfilePictureSuccess(),
          );
        }),
        catchError((message: string) => {
          onError();
          return concat(of(memberActions.changeProfilePictureFailure(message)));
        }),
      ),
    ),
  );

export const changeProfilePictureFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changeProfilePictureFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const changePreferredLanguageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changePreferredLanguage.match),
    mergeMap(({payload}) =>
      from(AccountService.changePreferredLanguage(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          memberActions.changePreferredLanguageSuccess(),
          userActions.setUser(user),
          push({
            pathname: '/dashboard',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(memberActions.changePreferredLanguageFailure()),
            of(
              userActions.setAsyncError({
                filter: 'member',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const memberProfileEpics = [
  changePasswordEpic,
  changeEmailEpic,
  changePhoneEpic,
  changeAddressEpic,
  changeProfilePictureEpic,
  changeProfilePictureFailureEpic,
  changePreferredLanguageEpic,
];
