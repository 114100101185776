import {
  AppointmentTypes,
  StripePlanDurations,
  StripePlanNames,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';
import {StripeError, Token} from '@stripe/stripe-js';

export const checkout = createAction<{
  stripeResponse: {
    token?: Token | undefined;
    error?: StripeError | undefined;
  };
  couponCode: string;
  referralCode?: string;
  paymentPlan:
    | StripePlanNames.mindfulness
    | StripePlanNames.uninsured
    | StripePlanNames.together
    | StripePlanNames.therapy
    | StripePlanNames.medicalCarePlusTherapy
    | StripePlanNames.medicalCare
    | StripePlanNames.newTherapy;

  paymentPlanDuration: StripePlanDurations;
  appointmentData?: {
    appointmentDateTimeString: string;
    providerId: string;
    appointmentType: AppointmentTypes;
    patientTimezone: string;
    calendarId: number;
    appointmentTypeID: string;
  };
  isOneTimeAppointment?: boolean;
}>('Checkout');
export const checkoutSuccess = createAction('CheckoutSuccess');
export const checkoutFailure = createAction('CheckoutFailure');
