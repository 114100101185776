import {NoteFormProps, UpdateNoteFormProps} from 'definitions/Yup';
import {
  Medication,
  NotesType,
  OpenAIPrompt,
  OpenAIResponse,
  UserRoles,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const getNotes = createAction<{
  userRole: UserRoles;
  patientEmail: string;
  pageNo: number;
  size: number;
  roomMode?: string;
}>('getNotes');
export const getNotesFailure = createAction('getNotesFailure');
export const getNotesSuccess = createAction<{
  data: {[key: string]: NotesType};
  hasMore: boolean;
  totalNotes: number;
  nextPage: number;
  previousPage: number;
}>('getNotesSuccess');

export const createNotes = createAction<{
  note: NoteFormProps & {
    userRole: UserRoles;
    patientEmail: string;
    medicationList: Medication[];
    noteCreatorName: string;
    appointmentID?: string;
    roomMode: string;
  };
  onNoteCreateSuccess: (noteId: string) => void;
  onNoteCreateFailed: () => void;
}>('createNote');
export const createNotesFailure = createAction<string>('createNotesFailure');
export const updateNoteOnCMSFailed = createAction<{[key: string]: NotesType}>(
  'updateNoteOnCMSFailed',
);

export const createNotesSuccess = createAction<{[key: string]: NotesType}>(
  'createNotesSuccess',
);

export const updateNote = createAction<{
  note: UpdateNoteFormProps & {
    userRole: UserRoles;
    patientEmail: string;
    medicationList: Medication[];
    noteId: string;
    noteCreatorName: string;
  };
  goToNotesList?: () => void;
  isToast: boolean;
  toastMsg?: string;
}>('updateNote');
export const updateNoteFailure = createAction('updateNoteFailure');
export const updateNoteSuccess = createAction<{[key: string]: NotesType}>(
  'updateNoteSuccess',
);

export const generateNote = createAction<{
  prompt: OpenAIPrompt;
  userRole: UserRoles;
  patientId: string;
  appointmentID?: string;
  preferredLanguage?: string;
  goToCreatedNote: (content: OpenAIResponse) => void;
  onNoteGenerateFailed: () => void;
  isFailedToast: boolean;
  isToast: boolean;
  toastMsg?: string;
}>('generateNote');
export const generateNotesSuccess = createAction<{
  goToCreatedNote: (content: OpenAIResponse) => void;
  content: OpenAIResponse;
}>('generateNotesSuccess');
export const generateNotesFailure = createAction<{
  message: string;
  isFailedToast: boolean;
}>('generateNotesFailure');

export const getGeneratedNotePreviousInputs = createAction<{
  userRole: UserRoles;
  patientId: string;
  prefillInputs: (previousInputs: OpenAIPrompt) => void;
}>('getGeneratedNotePreviousInputs');

export const createCMS1500Form = createAction<{noteId: string}>(
  'createCMS1500Form',
);
export const createCMS1500FormFailure = createAction<string>(
  'createCMS1500FormFailure',
);
export const createCMS1500FormSuccess = createAction<{
  [key: string]: NotesType;
}>('createCMS1500FormSuccess');
