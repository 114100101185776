import React, {FC, useEffect, useState} from 'react';
import {detachedSearchBarStyles} from 'components/Basic/Form/Select/styles';
import {DetachedSearchSelect} from 'components/Basic/Form/V2';
import {AppointmentDurations, AppointmentTypes} from 'interfaces';
import Select from 'react-select';

import {useSessionTypes} from './useSessionTypes';

interface Props {
  onUpdateApptTypeAndDuration: (data: {
    type: AppointmentTypes;
    duration: AppointmentDurations;
  }) => void;
  appointmentDuration?: AppointmentDurations;
  appointmentType: AppointmentTypes;
}

export const Durations: FC<Props> = ({
  onUpdateApptTypeAndDuration,
  appointmentType,
}) => {
  const {getSessions} = useSessionTypes();
  const durations = getSessions(appointmentType).map(s => ({
    value: s.duration,
    label: s.duration + ' min',
  }));
  const [duration, setDuration] = useState(durations[0]);

  useEffect(() => {
    setDuration(durations[0]);
  }, [appointmentType]);

  return (
    <DetachedSearchSelect
      selectComponent={Select}
      isMulti={false}
      isDisabled={durations.length <= 1}
      value={duration}
      isSearchable={false}
      options={durations}
      styles={{
        ...detachedSearchBarStyles,
        detachedButton: (isOpen: boolean, hasValue: boolean) => {
          const styles = detachedSearchBarStyles.detachedButton!(
            isOpen,
            hasValue,
          );
          return {
            ...styles,
            borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
            color: hasValue ? 'black' : '#6B6B6B',
          };
        },
      }}
      onChange={session => {
        setDuration(session!);
        onUpdateApptTypeAndDuration({
          type: appointmentType,
          duration: session!.value,
        });
      }}
    />
  );
};
