import {ReleaseNotesGetResponse, ReleaseNotesPutResponse} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const GetAccountReleaseNotes = async (): Promise<
  Response<{message: ReleaseNotesGetResponse}>
> => {
  try {
    const res = await requestHandler<{message: ReleaseNotesGetResponse}>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetAccountReleaseNotes}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const UpdateAccountReleaseNotes = async (data: {
  releaseCode: number;
}): Promise<Response<{message: ReleaseNotesPutResponse}>> => {
  try {
    const res = await requestHandler<
      {message: ReleaseNotesPutResponse},
      typeof data
    >({
      method: HttpMethods.PUT,
      url: EndPoints.UpdateAccountReleaseNotes,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const ReleaseNoteService = {
  GetAccountReleaseNotes,
  UpdateAccountReleaseNotes,
};
