import {AppActions, RootState} from 'app/rootReducer';

import {Reducer} from '@reduxjs/toolkit';

export function outerReducer(appReducer: Reducer<RootState, AppActions>) {
  return (state: any, action: any) => {
    if (action?.type === 'StateLoadingDone') {
      return appReducer(action.payload.state, action);
    }
    return appReducer(state, action);
  };
}
