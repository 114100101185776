import {TFunction} from 'i18next';
import {UserRoles} from 'interfaces';

interface MenuItem {
  link: string;
  label: string;
  mobileLabel?: string;
}

const getCommonMenuItems = (
  t: TFunction<'translate', undefined>,
  isProviderFromUnitedStates: boolean,
): MenuItem[] => [
  {
    link: '/provider/profile/details',
    label: t('providerProfileMenuItems.myDetails', 'My Details'),
  },
  ...(isProviderFromUnitedStates
    ? [
        {
          link: '/provider/profile/in-network',
          label: t('providerProfileMenuItems.inNetwork', 'In-Network'),
        },
      ]
    : []),
  {
    link: '/provider/profile/settings',
    label: t('providerProfileMenuItems.settings', 'Settings'),
  },
  {
    link: '/provider/profile/revenue',
    label: t('providerProfileMenuItems.revenue.title', 'Revenue'),
  },
];

const getTherapistSpecificItems = (
  t: TFunction<'translate', undefined>,
): MenuItem[] => [
  {
    link: '/provider/profile/receipts',
    label: t('providerProfileMenuItems.receipts', 'Receipts'),
  },
  {
    link: '/provider/profile/resource-center',
    label: t('providerProfileMenuItems.resourceCenter', 'Resource Center'),
    mobileLabel: t('providerProfileMenuItems.faqs', 'FAQs'),
  },
];

const getPrescriberSpecificItems = (
  t: TFunction<'translate', undefined>,
): MenuItem[] => [
  {
    link: '/provider/profile/resource-center',
    label: t('providerProfileMenuItems.resourceCenter', 'Resource Center'),
    mobileLabel: t('providerProfileMenuItems.faqs', 'FAQs'),
  },
];

export const ProfileSideBarItems = (
  t: TFunction<'translate', undefined>,
  providerRole: UserRoles,
  isProviderFromUnitedStates: boolean,
): MenuItem[] => {
  const commonItems = getCommonMenuItems(t, isProviderFromUnitedStates);
  const therapistItems = getTherapistSpecificItems(t);
  const prescriberItems = getPrescriberSpecificItems(t);

  return providerRole === UserRoles?.therapist
    ? [...commonItems, ...therapistItems]
    : [...commonItems, ...prescriberItems];
};
