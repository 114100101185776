import React from 'react';

import {SvgIcon} from './SvgIcon';

export const ShareIconTransparent: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'white',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={` ${strokeColor}`}
  >
    <path
      strokeWidth={1}
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 4C18.5 5.38071 17.3807 6.5 16 6.5C14.6193 6.5 13.5 5.38071 13.5 4C13.5 2.61929 14.6193 1.5 16 1.5C17.3807 1.5 18.5 2.61929 18.5 4ZM20 4C20 6.20914 18.2091 8 16 8C14.704 8 13.5519 7.38363 12.821 6.42805L7.8506 8.91323C7.94793 9.25879 8 9.62331 8 10C8 10.3767 7.94793 10.7412 7.8506 11.0868L12.821 13.572C13.5519 12.6164 14.704 12 16 12C18.2091 12 20 13.7909 20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 15.6233 12.0521 15.2588 12.1494 14.9132L7.17904 12.428C6.44808 13.3836 5.29602 14 4 14C1.79086 14 0 12.2091 0 10C0 7.79086 1.79086 6 4 6C5.29602 6 6.44808 6.61637 7.17904 7.57196L12.1494 5.08678C12.0521 4.74122 12 4.37669 12 4C12 1.79086 13.7909 0 16 0C18.2091 0 20 1.79086 20 4ZM6.5 10C6.5 11.3807 5.38071 12.5 4 12.5C2.61929 12.5 1.5 11.3807 1.5 10C1.5 8.61929 2.61929 7.5 4 7.5C5.38071 7.5 6.5 8.61929 6.5 10ZM16 18.5C17.3807 18.5 18.5 17.3807 18.5 16C18.5 14.6193 17.3807 13.5 16 13.5C14.6193 13.5 13.5 14.6193 13.5 16C13.5 17.3807 14.6193 18.5 16 18.5Z"
      fill="white"
    />
  </svg>
);
