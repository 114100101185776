import {providerAvailabilityEpics} from '../Provider/Availability/availabilityEpics';

import {appointmentBookingEpics} from './Booking/bookingEpics';
import {chargeEpics} from './Charge/chargeEpics';
import {appointmentOnboardingEpics} from './Onboarding/onboardingEpics';
import {paymentRequestEpics} from './SendPaymentRequest/SendPaymentRequestEpics';

export const appointmentEpics = [
  providerAvailabilityEpics,
  appointmentBookingEpics,
  appointmentOnboardingEpics,
  chargeEpics,
  paymentRequestEpics,
].flatMap(epic => epic);
