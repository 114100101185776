import {UserRoles} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const rateVideoCall = createAction<{appointmentID: string}>(
  'rateVideoCall',
);

export const submitRatingRequest = createAction<{
  role: UserRoles;
  appointmentId: number;
  audioVideo: number;
  provider?: number;
}>('SUBMIT_RATING_REQUEST');
export const submitRatingRequestFailure = createAction<string>(
  'SUBMIT_RATING_FAILURE',
);
export const submitRatingRequestSuccess = createAction<string>(
  'SUBMIT_RATING_SUCCESS',
);
