import React, {useMemo, useRef} from 'react';
import {FieldPath, FieldPathValue, FieldValues} from 'react-hook-form';
import Select, {OnChangeValue, PropsValue} from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import {GroupBase} from 'react-select/dist/declarations/src/types';
import {AsyncPaginate} from 'react-select-async-paginate';

import {AbstractFormItem} from '../Base/AbstractFormItem';

import {DetachedSearchSelect} from './DetachedSearchSelect/DetachedSearchSelect';
import {
  FormSelectProps,
  TSelectGroup,
  TSelectOption,
  WithAsyncPaginateType,
} from './models';

export const FormSelect = <
  TFieldValues extends FieldValues,
  TContext extends object,
  IsMulti extends boolean,
  IsPaginated extends boolean,
  Option extends TSelectOption<TFieldValues>,
>({
  id,
  name = id as FieldPath<TFieldValues>,
  placeholder,
  options,
  styles,
  isMulti,
  isLoading,
  creatable,
  isAsync,
  isPaginated,
  loadingMessage,
  noOptionsMessage,
  inputClasses,
  isSearchable,
  extractValue = false,
  detachedSearchBar: detached = false,
  ...rest
}: FormSelectProps<
  TFieldValues,
  TContext,
  IsMulti,
  IsPaginated,
  TSelectOption<TFieldValues>,
  TSelectGroup<TFieldValues>
> & {
  portalContainer?: Element | DocumentFragment;
}) => {
  const dict = useRef({});
  const optionsDictionary: {[key: string]: Option} = useMemo(
    () =>
      options
        ?.flatMap(opt => (opt as GroupBase<Option>)?.options ?? opt)
        .reduce((dict, opt) => {
          dict[opt.value] = opt;
          return dict;
        }, dict.current) ?? {},
    [options],
  );

  const mapValueToOption = (
    value: FieldPathValue<TFieldValues, FieldPath<TFieldValues>>,
  ): Option =>
    optionsDictionary[value] ??
    ({
      label: value,
      value,
    } as Option);

  const getSelectOption = (
    value: FieldPathValue<TFieldValues, FieldPath<TFieldValues>>,
  ): PropsValue<Option> => {
    if (!value) {
      return null;
    }

    if (!extractValue) {
      return value;
    }

    if (isMulti) {
      return value.map(mapValueToOption);
    }

    return mapValueToOption(value);
  };

  const getValue = (value: OnChangeValue<Option, IsMulti>) => {
    if (!extractValue) {
      return value;
    }

    if (isMulti) {
      return (value as Array<Option>)?.map(v => v.value);
    }

    return (value as Option)?.value;
  };

  const AsyncPaginated = AsyncPaginate as WithAsyncPaginateType;
  const AsyncSelectComponent = creatable ? AsyncCreatableSelect : AsyncSelect;
  const SyncSelectComponent = creatable ? CreatableSelect : Select;
  const SelectComponent = isAsync ? AsyncSelectComponent : SyncSelectComponent;
  const SelectOrPaginated = isPaginated ? AsyncPaginated : SelectComponent;
  const DetachedSelectComponent = DetachedSearchSelect<
    TFieldValues,
    IsMulti,
    IsPaginated
  >;
  const Component = detached ? DetachedSelectComponent : SelectOrPaginated;

  return (
    <AbstractFormItem
      id={id}
      name={name}
      {...rest}
      render={({field: {value, onBlur, onChange}}) => (
        <section>
          <Component
            placeholder={placeholder}
            isMulti={isMulti}
            isPaginated={isPaginated}
            onBlur={onBlur}
            isLoading={
              typeof isLoading === 'function' ? isLoading(value) : isLoading
            }
            loadingMessage={loadingMessage}
            noOptionsMessage={noOptionsMessage}
            className={inputClasses}
            options={options}
            aria-label={id}
            aria-labelledby={id}
            loadOptions={rest.loadOptions as any}
            isSearchable={isSearchable}
            {...rest}
            value={getSelectOption(value) || rest.value}
            onChange={(val: any) =>
              rest.onChange
                ? rest.onChange(val, onChange)
                : onChange(getValue(val as OnChangeValue<Option, IsMulti>))
            }
            onInputChange={(val: string) => rest.onInputChange?.(val, onChange)}
            styles={styles}
            // detached select props
            selectComponent={SelectOrPaginated}
            selectionCountPrefix={rest.selectionCountPrefix}
            fullScreen={rest.fullScreen}
          />
        </section>
      )}
    />
  );
};
