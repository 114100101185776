import {ProfileImageSchema} from 'definitions/Yup';
import * as Yup from 'yup';

export enum ProfileTabs {
  medicalTeam = 'medicalTeam',
  treatment = 'treatment',
  account = 'account',
}

export enum DrugInfoKey {
  currentMedications = 'currentMedications',
  allergies = 'allergies',
}

export type ProfileImageFormData = Yup.InferType<typeof ProfileImageSchema>;
