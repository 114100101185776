import {
  EndPoints,
  HttpMethods,
  MoodTrackerData,
  MoodTrackerEntriesResponse,
  MoodTrackerEntry,
  MoodTrackerStatsResponse,
  StatsTimePeriod,
} from 'interfaces';
import {requestHandler} from 'services/api';
import {attachQueryParameters, unwrapAPIError} from 'utils';

const getMoodTrackerEntries = async (args?: {
  email: string;
  cursor?: string;
}): Promise<MoodTrackerEntriesResponse> => {
  try {
    const searchParams = attachQueryParameters('', args || {});

    const res = await requestHandler<{
      message: MoodTrackerEntriesResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetMoodTrackerEntries}${searchParams}` as unknown as EndPoints,
    });
    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const createMoodTrackerEntry = async (
  formData: MoodTrackerData,
): Promise<MoodTrackerEntry> => {
  try {
    const res = await requestHandler<
      {message: MoodTrackerEntry},
      typeof formData
    >({
      method: HttpMethods.POST,
      url: EndPoints.CreateMoodTrackerEntry as unknown as EndPoints,
      data: formData,
    });
    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getMoodTrackerStats = async (args?: {
  email: string;
  period?: StatsTimePeriod;
}): Promise<MoodTrackerStatsResponse> => {
  try {
    const searchParams = attachQueryParameters('', args || {});

    const res = await requestHandler<{
      message: MoodTrackerStatsResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetMoodTrackerStats}${searchParams}` as unknown as EndPoints,
    });
    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const MoodTrackerService = {
  getMoodTrackerEntries,
  getMoodTrackerStats,
  createMoodTrackerEntry,
};
