import React, {FC, Suspense, useMemo, useState} from 'react';
import {Button, FormError} from 'components';
import {FormTextInput} from 'components/Basic/Form/V2';
import Modal from 'components/Basic/Modal';
import dayjs from 'dayjs';
import {getDirectBookingSelectedProvider} from 'features/DirectBooking/directBookingSelectors';
import {selectUserNotification, selectUserProfile} from 'features/User';
import {useBooking} from 'hooks';
import {
  AppointmentDurations,
  AppointmentTypes,
  AvailableProvider,
  DigitalPracticeAccountTypes,
  MemberProfile,
  SliceStatus,
} from 'interfaces';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {isMember, isProvider} from 'utils';
import {isIOS} from 'utils/responsiveUtils';

import {BookingCalendarV2} from './BookingCalendarV2';
import {BookingFormButtons} from './BookingFormButtons';
import {ProviderChooser} from './ProviderChooser';
import {TimeSelect} from './TimeSelect';

type Props = {
  appointmentType: AppointmentTypes;
  member?: MemberProfile;
  duration: AppointmentDurations;
  onCancel?: () => void;
  onSuccess?: () => void;
  footerRef?: React.RefObject<HTMLDivElement>;
  selectedDate?: dayjs.Dayjs;
};

const MemberBookingForm: FC<Props> = ({
  appointmentType,
  member,
  duration,
  onCancel,
  onSuccess,
  footerRef,
  selectedDate,
}) => {
  const history = useHistory();
  const user = useSelector(selectUserProfile);
  const {message, messageType, navigateTo} = useSelector(
    selectUserNotification,
  );
  const directBookingProvider = useSelector(getDirectBookingSelectedProvider);
  const {
    onSubmit,
    time,
    control,
    watch,
    register,
    errors,
    memberState,
    zones,
    disabledDays,
    selectedTime,
    isLoading,
    bookingCharge,
    setValue,
    onConfirmBooking,
    closeExtraChargeModal,
    getProviderCalendarData,
    availability,
    providerLoadingStatus,
    slotLoadingStatus,
  } = useBooking(appointmentType, duration, member, selectedDate);

  const isPending =
    isLoading === SliceStatus.pending ||
    bookingCharge.status === SliceStatus.pending;

  const timezoneValue = watch('timezone');
  const providerInputValue = watch('providerId');

  const timezoneLabel = useMemo(() => {
    if (timezoneValue) {
      return zones.find(z => z.value === timezoneValue)?.label ?? '';
    }
    return '';
  }, [timezoneValue, zones]);

  const [providerType] = (() => {
    switch (appointmentType) {
      case AppointmentTypes.chat_with_coach:
        return ['Coach'];
      case AppointmentTypes.video_call_with_therapist:
        return ['Therapist'];
      default:
        return ['Doctor'];
    }
  })();

  const [allowFetchOnMenuOpen, setAllowFetchOnMenuOpen] = useState(
    !!selectedDate,
  );

  const modalProps = {
    message,
    messageType,
    isOpen: true,
    buttonFn: () => {
      onSuccess?.();
      if (navigateTo) {
        history.push(navigateTo);
      }
    },
  };

  const apptDetails = {
    provider: (Array.isArray(availability?.providers)
      ? availability.providers?.find(p => p?.therapistId === providerInputValue)
      : {}) as AvailableProvider,
    date: watch('date') as Date,
    time: watch('time') as string,
    duration: duration as unknown as number,
    isMember: isMember(user),
    isDPOOP:
      user?.digitalPracticeAccountType ===
      DigitalPracticeAccountTypes.outOfPocket,
  };

  return (
    <>
      {messageType === 'none' ? null : (
        <Suspense fallback={<div />}>
          <Modal {...modalProps} />
        </Suspense>
      )}
      <form
        onSubmit={onSubmit}
        className="mx-auto overflow-y-auto"
        id="booking-form"
      >
        <input
          type="text"
          className="hidden"
          {...register('appointmentType')}
        />
        <ProviderChooser
          control={control}
          appointmentType={appointmentType}
          directBookingProvider={directBookingProvider}
          providerType={providerType}
          availability={availability}
          providerLoadingStatus={providerLoadingStatus}
          slotLoadingStatus={slotLoadingStatus}
          portalContainer={isIOS() ? document.body : undefined}
          setValue={setValue}
        />
        <FormTextInput
          control={control}
          id="state"
          label={null}
          value={user?.stateOfResidence ?? memberState}
          classes="hidden"
        />
        <FormTextInput
          control={control}
          id="timezone"
          label={null}
          classes="hidden"
        />
        <div className="flex gap-2">
          <BookingCalendarV2
            onMenuOpen={isMenuOpen => {
              if (isMenuOpen && selectedDate && allowFetchOnMenuOpen) {
                setAllowFetchOnMenuOpen(false);
                // on date-picker open, fetch the availability for entire month rather than just the selected day
                getProviderCalendarData(selectedDate.toDate(), false);
              }
            }}
            isAvailabilityLoading={slotLoadingStatus === SliceStatus.pending}
            disabledDays={disabledDays}
            control={control}
            disabled={!providerInputValue}
            setValue={setValue}
            onMonthChange={month => {
              setAllowFetchOnMenuOpen(false);
              // on month change, fetch the availability for entire month rather than just the selected day
              getProviderCalendarData(month, false);
            }}
          />

          <TimeSelect
            time={time}
            loadingStatus={slotLoadingStatus}
            watch={watch}
            control={control}
            setValue={setValue}
            label="Select time"
            isProvider={isProvider(user)}
            errors={errors}
          ></TimeSelect>
        </div>
        {!providerInputValue &&
        providerLoadingStatus === SliceStatus.resolved ? (
          <FormError error={`Please choose ${providerType.toLowerCase()}`} />
        ) : null}
        {timezoneLabel ? (
          <p className="capitalize text-xs font-light mt-2">
            Time Zone &bull; {timezoneLabel}
          </p>
        ) : null}
        {footerRef?.current
          ? createPortal(
              <BookingFormButtons
                member={member}
                appointmentType={appointmentType}
                isPending={isPending}
                selectedTime={selectedTime}
                onCancel={onCancel}
                disabled={
                  providerLoadingStatus === SliceStatus.pending ||
                  slotLoadingStatus === SliceStatus.pending
                }
                apptDetails={apptDetails}
              />,
              footerRef.current,
            )
          : null}
      </form>
      {bookingCharge.showModal && (
        <Modal
          messageType="none"
          isOpen={bookingCharge.showModal}
          buttonFn={closeExtraChargeModal}
        >
          <article className="flex flex-col items-center justify-center px-5">
            <p className="text-lg text-justify">
              Hey there! Seems like you have run out of time. The price for the
              session selected is: ${bookingCharge.amount}. Once you click&nbsp;
              <span className="font-semibold">Complete Booking</span>, your card
              on file will be charged. If you have previously canceled any
              appointments with more than 24hr notice in the past 30 days,
              please Text: “Balance 24” to (415) 449-7796 for immediate
              resolution.
            </p>

            <section className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-12">
              <Button
                type="button"
                onClick={onConfirmBooking}
                borderColor="transparent"
                className="rounded-full px-8 py-3"
              >
                Complete Booking
              </Button>
              <Button
                type="button"
                onClick={closeExtraChargeModal}
                borderColor="transparent"
                bgColor="red-600"
                textColor="red-600"
                className="rounded-full px-8 py-3 font-semibold"
                btnType="danger"
                outline
              >
                NO, GO BACK
              </Button>
            </section>
          </article>
        </Modal>
      )}
    </>
  );
};

export default MemberBookingForm;
