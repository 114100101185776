import {Meeting} from 'interfaces/Meeting.types';

import {createAction} from '@reduxjs/toolkit';

// Send payment request actions
export const sendPaymentRequest = createAction<{
  charge: number;
  chargeCurrency: string;
  appointmentID: string;
  patientId: string;
  startDate: string;
  onSuccess: () => void;
  onError: () => void;
}>('SendPaymentRequest');
export const sendPaymentRequestSuccess = createAction<{message: Meeting}>(
  'SendPaymentRequestSuccess',
);
export const sendPaymentRequestFailure = createAction<{message: string}>(
  'SendPaymentRequestFailure',
);
