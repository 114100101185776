import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  inset: 0;
  position: fixed;
  z-index: 1;
`;

export const Backdrop = (props: HTMLAttributes<HTMLDivElement>) => (
  <StyledDiv {...props} />
);
