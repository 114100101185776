import React, {FC, Suspense, useEffect, useState} from 'react';
import classNames from 'classnames';
import {DesktopHeader, MobileTabletHeader} from 'components';
import Modal from 'components/Basic/Modal';
import dayjs from 'dayjs';
import {BookingModal} from 'features/Appointment/Booking/BookingModal';
import {MemberProfile} from 'interfaces';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';

import {BookButton} from './BookButton';

export const ProviderHeader: FC<{
  title: string;
  pageTitle?: string;
  mobileTitle?: string;
  participantId?: string;
  showBookButtonInMobile?: boolean;
  showBookButtonInDesktop?: boolean;
  showBookingForMember?: MemberProfile;
  onBookingModalClose?: () => void;
  backButtonFn?: () => void;
}> = ({
  title,
  pageTitle,
  mobileTitle,
  participantId,
  showBookButtonInMobile = false,
  showBookButtonInDesktop = true,
  showBookingForMember,
  onBookingModalClose,
  backButtonFn,
}) => {
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [successModal, setSuccessModal] = useState(showBookingModal);
  const {t} = useTranslation();

  useEffect(() => {
    setShowBookingModal(!!showBookingForMember);
  }, [showBookingForMember]);

  useEffect(() => {
    if (!showBookingModal) {
      onBookingModalClose?.();
    }
  }, [showBookingModal]);

  return (
    <div>
      <Helmet>
        <title>{pageTitle ?? title}</title>
      </Helmet>

      {/* Mobile and Tablet */}
      <MobileTabletHeader
        headerTitle={mobileTitle ?? title}
        backButton
        backButtonFn={backButtonFn}
        actionButton={
          showBookButtonInMobile ? (
            <BookButton setShowBookingModal={() => setShowBookingModal(true)} />
          ) : null
        }
        classes={classNames('ml-auto', {
          hidden: participantId,
        })}
      />

      {/* Desktop */}
      <DesktopHeader
        classes="!pt-3 !pb-2 border-b shrink-0 !h-[88px]"
        headerTitle={title}
        headerClasses="text-[22px] font-semibold pl-6"
        actionButton={
          showBookButtonInDesktop ? (
            <BookButton setShowBookingModal={() => setShowBookingModal(true)} />
          ) : null
        }
      />

      {/* Appointment Booking Modal */}
      <BookingModal
        show={showBookingModal}
        onClose={() => setShowBookingModal(false)}
        setShowSuccessModal={setSuccessModal}
        member={showBookingForMember}
        selectedDate={dayjs()}
        setShowBookingModal={setShowBookingModal}
      />

      {/* Group Booking Success Modal */}
      {successModal ? (
        <Suspense fallback={<div />}>
          <Modal
            messageType="success"
            message={t(
              'yourGroupAppointmentHasBeenBooked',
              'Your group appointment has been booked 📆',
            )}
            isOpen={successModal}
            showCloseIcon
            buttonFn={() => {
              setSuccessModal(false);
              setShowBookingModal(false);
            }}
          />
        </Suspense>
      ) : null}
    </div>
  );
};
