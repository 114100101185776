import {useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch} from 'app/store';
import {MessagingSchema} from 'definitions/Yup';
import {
  messagingActions,
  selectLoadingMediaMessages,
  selectMediaMessages,
  selectMessages,
} from 'features/Messaging';
import {
  CometChatMessageType,
  FileOptions,
  LastMessage,
  MessagingData,
  SliceStatus,
} from 'interfaces';
import debounce from 'lodash.debounce';
import {useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {MessagingService} from 'services/api';
import {
  audioExtensions,
  base64Img,
  getFileExtension,
  getVideoCover,
  imageExtensions,
  imageSize,
  videoExtensions,
} from 'utils';

import {CometChat} from '@cometchat-pro/chat';
import {yupResolver} from '@hookform/resolvers/yup';

import {useImageResizer} from './useImageResizer';

export const useMessaging = (participantUID?: string) => {
  const [cometUser, setCometUser] = useState<any>(); // set loggedin comet user
  const {getOptimizedImage} = useImageResizer();
  const [inputMessage, setInputMessage] = useState<string>('');

  const {hasMore, data: messages, lastMessageId} = useSelector(selectMessages);
  const {
    hasMore: mediaHasMore,
    data: mediaMessages,
    lastMessageId: mediaLastMessageId,
  } = useSelector(selectMediaMessages);
  const mediaMessagesLoading = useSelector(selectLoadingMediaMessages);
  const dispatch = useAppDispatch();

  // set information to inform the receiver that the loggedIn user has started typing
  const receiverId = participantUID;
  const receiverType = CometChat.RECEIVER_TYPE.USER;

  const [sendMessageStatus, setSendMessageStatus] = useState(SliceStatus.idle);
  const formMethods = useForm<MessagingData>({
    defaultValues: {message: '', files: {}},
    resolver: yupResolver(MessagingSchema),
  });

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files;
    const fileExtension = getFileExtension(file[0].name);
    if (
      file[0].type.includes('image') ||
      imageExtensions.includes(fileExtension)
    ) {
      const src = URL.createObjectURL(file[0]);
      const originalDimension = await imageSize(src);
      const placeholderSrc = await base64Img(src);

      const options = {
        originalDimension,
        placeholderSrc,
        type: 'image',
        ext: fileExtension,
      };
      const optimizedImage = await getOptimizedImage(file[0]);
      sendMediaMessage(participantUID!, optimizedImage, options);
    } else if (
      file[0].type.includes('video') ||
      videoExtensions.includes(fileExtension)
    ) {
      const placeholderSrc = await getVideoCover(file[0], 5);
      const options = {
        placeholderSrc,
        type: 'video',
        ext: fileExtension,
      };

      sendMediaMessage(participantUID!, file[0], options);
    } else if (
      file[0].type.includes('audio') ||
      audioExtensions.includes(fileExtension)
    ) {
      const options = {
        type: 'audio',
        ext: fileExtension,
      };
      sendMediaMessage(participantUID!, file[0], options);
    } else {
      const options = {
        type: file[0].type,
        ext: fileExtension,
      };
      sendMediaMessage(participantUID!, file[0], options);
    }
  };

  const sendTextMessage = async (receiverUID: string, messageText: string) => {
    try {
      if (!messageText) return;

      setSendMessageStatus(SliceStatus.pending);
      const message = await MessagingService.sendTextMessage(
        receiverUID,
        messageText,
      );
      dispatch(
        messagingActions.addMessage(message as unknown as CometChatMessageType),
      );
      dispatch(
        messagingActions.setLastMessage({
          uid: receiverUID,
          data: message as unknown as LastMessage,
        }),
      );
      setSendMessageStatus(SliceStatus.resolved);
    } catch (error) {
      setSendMessageStatus(SliceStatus.rejected);
      console.log('Message sending failed with error:', error);
    }
  };

  const sendMediaMessage = async (
    receiverUID: string,
    attachment: File,
    options: FileOptions,
  ) => {
    try {
      setSendMessageStatus(SliceStatus.pending);

      const message = await MessagingService.sendMediaMessage(
        receiverUID,
        attachment,
        options,
      );

      dispatch(
        messagingActions.addMessage(message as unknown as CometChatMessageType),
      );
      dispatch(
        messagingActions.setLastMessage({
          uid: receiverUID,
          data: message as unknown as LastMessage,
        }),
      );
      setSendMessageStatus(SliceStatus.resolved);
    } catch (error) {
      setSendMessageStatus(SliceStatus.rejected);
      console.log('Message sending failed with error:', error);
    }
  };
  // fetch meesages for select contact/participant
  const getMessageHistory = () => {
    if (participantUID)
      dispatch(messagingActions.getMessageHistory(participantUID));
  };

  // fetch meesages for select contact/participant
  const getMoreMessages = () => {
    if (lastMessageId && participantUID)
      dispatch(
        messagingActions.getMoreMessageHistory({participantUID, lastMessageId}),
      );
  };

  const getMediaMessageHistory = () => {
    if (participantUID)
      dispatch(messagingActions.getMediaMessageHistory(participantUID));
  };
  const getMoreMediaMessages = () => {
    if (mediaLastMessageId && participantUID) {
      dispatch(
        messagingActions.getMoreMediaMessageHistory({
          participantUID,
          lastMessageId: mediaLastMessageId,
        }),
      );
    }
  };

  const getCometLoggedInUser = async () => {
    try {
      const res = await MessagingService.getCometLoggedInUser();
      setCometUser(res);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = formMethods.handleSubmit(values => {
    if (values.message) {
      sendTextMessage(participantUID!, values.message);
      formMethods.reset();
    }
  });

  // ---------- emit typing status to selected contact/participant ------------
  const selfTyping = useRef<boolean>(false);
  const typingNotification = new CometChat.TypingIndicator(
    receiverId,
    receiverType,
  );

  // start typing
  const startTyping = useCallback(() => {
    if (!selfTyping.current) {
      CometChat.startTyping(typingNotification);
      selfTyping.current = true;
    }
  }, []);

  // stop typing
  const stopTyping = debounce(() => {
    selfTyping.current = false;
    CometChat.endTyping(typingNotification);
    setInputMessage(formMethods.watch('message'));
  }, 3000);

  // `enter` key event listener
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      const message = formMethods.watch('message');

      if (event.key === 'Enter' && event.shiftKey === false && participantUID) {
        if (message !== '') {
          event.preventDefault();
          sendTextMessage(participantUID!, message);
          formMethods.reset();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [participantUID]);

  useEffect(() => {
    getCometLoggedInUser();
  }, []);

  return {
    getCometLoggedInUser,
    cometUser,

    sendTextMessage,
    getMessageHistory,
    messages,
    getMoreMessages,
    hasMore,

    getMediaMessageHistory,
    mediaMessages,
    mediaHasMore,
    mediaMessagesLoading,
    getMoreMediaMessages,

    onSubmit,
    formMethods,
    handleFile,
    sendMessageStatus,

    startTyping,
    stopTyping,

    inputMessage,
  };
};
