import React, {FC} from 'react';
import {CaretDownIcon, LoadingSpinner} from 'components';
import {OptionType} from 'interfaces';
import ClickAwayListener from 'react-click-away-listener';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Hearts} from 'react-loader-spinner';

type Props = {
  onToggleClick: () => void;
  isSearchMembersLoading: boolean;
  isMemberDetailsLoading: boolean;
  inputValue: string;
  handleInputChange: (value: string) => void;
  onSearch: (value: string) => void;
  toggleOn: boolean;
  clientOptions: OptionType[];
  getValue: (value: string) => string | undefined;
  config: {
    dataLength: number;
    hasMore: boolean;
    next: () => void;
  };
};
const ChooseClient: FC<Props> = ({
  onToggleClick,
  inputValue,
  handleInputChange,
  clientOptions,
  getValue,
  onSearch,
  toggleOn,
  isMemberDetailsLoading,
  isSearchMembersLoading,
  config,
}) => {
  const {t} = useTranslation();
  return (
    <section className="relative pb-2">
      <p className="text-xs pt-4 pb-2 text-gray-500">{t('chooseClient')}</p>

      <div
        className="relative w-1/2"
        aria-hidden="true"
        onClick={onToggleClick}
      >
        <input
          type="text"
          autoComplete="off"
          value={inputValue ? getValue(inputValue) : inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onSearch(e.target.value);
            handleInputChange(e.target.value);
          }}
          className="h-10 pl-2 border border-gray-400  rounded text-black w-full"
        />
        {isSearchMembersLoading || isMemberDetailsLoading ? (
          <div className="absolute right-3 top-3">
            <LoadingSpinner type="Oval" width={18} height={18} />
          </div>
        ) : (
          <div className="absolute right-3 top-3">
            <CaretDownIcon strokeColor="text-gray-500" />
          </div>
        )}
      </div>

      {toggleOn ? (
        <ClickAwayListener onClickAway={onToggleClick}>
          <div
            id="scrollableDiv"
            className="overflow-y-auto h-64 hmax pt-2 mt-1.5 border absolute top6 bg-white z-30 w-1/2 shadow-md"
          >
            <InfiniteScroll
              style={{overflow: 'hidden'}}
              {...config}
              endMessage={
                <p className="text-center font-light py-3">
                  You&apos;re all caught up!
                  <span aria-label="celebration">🎊</span>
                </p>
              }
              loader={
                <div className="w-full flex items-center justify-center my-4">
                  <Hearts color="#315eff" height={50} width={50} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              <ul>
                {clientOptions.map((option: OptionType) => (
                  <li
                    aria-hidden="true"
                    onClick={() => {
                      handleInputChange(option.value);
                      onToggleClick();
                    }}
                    key={option.value}
                    className="py-2 pl-2 hover:bg-blue-100"
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          </div>
        </ClickAwayListener>
      ) : null}
    </section>
  );
};

export default ChooseClient;
