import {
  MoodTrackerEntriesResponse,
  MoodTrackerEntry,
  MoodTrackerStatsResponse,
  ProgressAssessmentStatsResponse,
  SliceStatus,
} from 'interfaces';
import {uniq} from 'ramda';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  createMoodTrackerEntry,
  createMoodTrackerEntryFailure,
  createMoodTrackerEntrySuccess,
  getAllProgressAssessmentStats,
  getAllProgressAssessmentStatsFailure,
  getAllProgressAssessmentStatsSuccess,
  getMoodTrackerEntries,
  getMoodTrackerEntriesFailure,
  getMoodTrackerEntriesSuccess,
  getMoodTrackerStats,
  getMoodTrackerStatsFailure,
  getMoodTrackerStatsSuccess,
  getProgressAssessmentStats,
  getProgressAssessmentStatsFailure,
  getProgressAssessmentStatsSuccess,
  updateProgressAssessment,
} from './progressActions';
import {getMoodTrackerStateKey, getProgressStateKey} from './utils';

type ProgressSliceState = {
  data: MoodTrackerEntriesResponse;
  status: SliceStatus;
  createStatus: SliceStatus;
  updateProgressAssessmentStatus: SliceStatus;
  moodTrackerStats: {
    [key: string]: {
      data: MoodTrackerStatsResponse;
      status: SliceStatus;
      isInitialized?: boolean;
    };
  };
  progressAssessmentStats: {
    [key: string]: {
      data: ProgressAssessmentStatsResponse;
      status: SliceStatus;
      isInitialized?: boolean;
    };
  };
  error: string | null;
};

const defaultMoodTrackerData = {
  entries: [],
  nextCursor: null,
};

const progressSlice = createSlice({
  name: 'memberProgress',
  initialState: {
    data: defaultMoodTrackerData,
    status: SliceStatus.idle,
    createStatus: SliceStatus.idle,
    moodTrackerStats: {},
    updateProgressAssessmentStatus: SliceStatus.idle,
    progressAssessmentStats: {},
    error: null,
  } as ProgressSliceState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ProgressSliceState>) =>
    builder
      .addCase(getMoodTrackerEntries, (state, {payload}) => ({
        ...state,
        data: {...(!payload?.reset ? state.data : defaultMoodTrackerData)},
        status: SliceStatus.pending,
        error: null,
      }))
      .addCase(getMoodTrackerEntriesSuccess, (state, {payload}) => ({
        ...state,
        status: SliceStatus.resolved,
        data: {
          entries: uniq([...state.data.entries, ...payload.entries]),
          nextCursor: payload.nextCursor || null,
        },
        error: null,
      }))
      .addCase(getMoodTrackerEntriesFailure, (state, {payload}) => ({
        ...state,
        status: SliceStatus.rejected,
        error: payload,
      }))
      .addCase(getMoodTrackerStats, (state, {payload}) => ({
        ...state,
        moodTrackerStats: {
          ...state.moodTrackerStats,
          [getMoodTrackerStateKey(payload)]: {
            data: [],
            status: SliceStatus.pending,
          },
        },
        error: null,
      }))
      .addCase(getMoodTrackerStatsSuccess, (state, {payload}) => ({
        ...state,
        moodTrackerStats: {
          ...state.moodTrackerStats,
          [getMoodTrackerStateKey(payload)]: {
            data: payload.data,
            status: SliceStatus.resolved,
            isInitialized: true,
          },
        },
        error: null,
      }))
      .addCase(getMoodTrackerStatsFailure, (state, {payload}) => ({
        ...state,
        moodTrackerStats: {
          ...state.moodTrackerStats,
          [getMoodTrackerStateKey(payload)]: {
            data: [],
            status: SliceStatus.rejected,
          },
        },
        error: payload.error,
      }))
      .addCase(createMoodTrackerEntry, (state, {payload}) => ({
        ...state,
        data: {
          ...state.data,
          entries: [
            payload as unknown as MoodTrackerEntry,
            ...state.data.entries,
          ],
        },
        createStatus: SliceStatus.pending,
        error: null,
      }))
      .addCase(createMoodTrackerEntryFailure, (state, {payload}) => ({
        ...state,
        createStatus: SliceStatus.rejected,
        error: payload,
        data: {
          ...state.data,
          entries: [...state.data.entries.slice(1)],
        },
      }))
      .addCase(createMoodTrackerEntrySuccess, (state, {payload}) => ({
        ...state,
        createStatus: SliceStatus.resolved,
        data: {
          entries: [payload, ...state.data.entries.slice(1)],
          nextCursor: state.data.nextCursor,
        },
        error: null,
        moodTrackerStats: {},
      }))
      .addCase(updateProgressAssessment.pending, state => ({
        ...state,
        updateProgressAssessmentStatus: SliceStatus.pending,
        error: null,
      }))
      .addCase(updateProgressAssessment.rejected, (state, action) => ({
        ...state,
        updateProgressAssessmentStatus: SliceStatus.rejected,
        error: action.payload as string,
      }))
      .addCase(
        updateProgressAssessment.fulfilled as unknown as string,
        state => ({
          ...state,
          updateProgressAssessmentStatus: SliceStatus.rejected,
          error: null,
          progressAssessmentStats: {},
        }),
      )
      .addCase(getProgressAssessmentStats, (state, {payload}) => ({
        ...state,
        progressAssessmentStats: {
          ...state.progressAssessmentStats,
          [getProgressStateKey(payload)]: {
            data: [],
            status: SliceStatus.pending,
          },
        },
        error: null,
      }))
      .addCase(getProgressAssessmentStatsSuccess, (state, {payload}) => ({
        ...state,
        progressAssessmentStats: {
          ...state.progressAssessmentStats,
          [getProgressStateKey(payload)]: {
            data: payload.data,
            status: SliceStatus.resolved,
            isInitialized: true,
          },
        },
        error: null,
      }))
      .addCase(getProgressAssessmentStatsFailure, (state, {payload}) => ({
        ...state,
        progressAssessmentStats: {
          ...state.progressAssessmentStats,
          [getProgressStateKey(payload)]: {
            data: [],
            status: SliceStatus.resolved,
          },
        },
        error: payload.error,
      }))
      .addCase(getAllProgressAssessmentStats, (state, {payload}) => {
        const gad7Key = getProgressStateKey({...payload, type: 'gad7'});
        const phq9Key = getProgressStateKey({...payload, type: 'phq9'});

        return {
          ...state,
          progressAssessmentStats: {
            ...state.progressAssessmentStats,
            [gad7Key]: {
              ...state.progressAssessmentStats[gad7Key],
              data: [],
              status: SliceStatus.pending,
            },
            [phq9Key]: {
              ...state.progressAssessmentStats[phq9Key],
              data: [],
              status: SliceStatus.pending,
            },
          },
          moodTrackerStats: {
            ...state.moodTrackerStats,
            [getMoodTrackerStateKey(payload)]: {
              ...state.moodTrackerStats[getMoodTrackerStateKey(payload)],
              data: [],
              status: SliceStatus.pending,
            },
          },
          error: null,
        };
      })
      .addCase(getAllProgressAssessmentStatsSuccess, (state, {payload}) => ({
        ...state,
        progressAssessmentStats: {
          ...state.progressAssessmentStats,
          [getProgressStateKey({...payload, type: 'gad7'})]: {
            data: payload.data.gad7,
            status: SliceStatus.resolved,
            isInitialized: true,
          },
          [getProgressStateKey({...payload, type: 'phq9'})]: {
            data: payload.data.phq9,
            status: SliceStatus.resolved,
            isInitialized: true,
          },
        },
        moodTrackerStats: {
          ...state.moodTrackerStats,
          [getMoodTrackerStateKey(payload)]: {
            data: payload.data.moodTracker,
            status: SliceStatus.resolved,
            isInitialized: true,
          },
        },
        error: null,
      }))
      .addCase(getAllProgressAssessmentStatsFailure, (state, {payload}) => {
        const gad7Key = getProgressStateKey({...payload, type: 'gad7'});
        const phq9Key = getProgressStateKey({...payload, type: 'phq9'});

        return {
          ...state,
          progressAssessmentStats: {
            ...state.progressAssessmentStats,
            [gad7Key]: {
              ...state.progressAssessmentStats[gad7Key],
              data: [],
              status: SliceStatus.rejected,
            },
            [phq9Key]: {
              ...state.progressAssessmentStats[phq9Key],
              data: [],
              status: SliceStatus.rejected,
            },
          },
          moodTrackerStats: {
            ...state.moodTrackerStats,
            [getMoodTrackerStateKey(payload)]: {
              ...state.moodTrackerStats[getMoodTrackerStateKey(payload)],
              data: [],
              status: SliceStatus.rejected,
            },
          },
          error: payload.error,
        };
      }),
});

export const {reducer: progressReducer, name: progressReducerName} =
  progressSlice;

export type TProgressActions =
  | ReturnType<typeof getMoodTrackerEntries>
  | ReturnType<typeof getMoodTrackerEntriesFailure>
  | ReturnType<typeof getMoodTrackerEntriesSuccess>
  | ReturnType<typeof getMoodTrackerStats>
  | ReturnType<typeof getMoodTrackerStatsFailure>
  | ReturnType<typeof getMoodTrackerStatsSuccess>
  | ReturnType<typeof getProgressAssessmentStats>
  | ReturnType<typeof getProgressAssessmentStatsFailure>
  | ReturnType<typeof getProgressAssessmentStatsSuccess>
  | ReturnType<typeof getAllProgressAssessmentStats>
  | ReturnType<typeof getAllProgressAssessmentStatsFailure>
  | ReturnType<typeof getAllProgressAssessmentStatsSuccess>
  | ReturnType<typeof createMoodTrackerEntry>
  | ReturnType<typeof createMoodTrackerEntryFailure>
  | ReturnType<typeof createMoodTrackerEntrySuccess>;

export const progressActions = {
  getMoodTrackerEntries,
  getMoodTrackerEntriesFailure,
  getMoodTrackerEntriesSuccess,
  getMoodTrackerStats,
  getMoodTrackerStatsFailure,
  getMoodTrackerStatsSuccess,
  getProgressAssessmentStats,
  getProgressAssessmentStatsFailure,
  getProgressAssessmentStatsSuccess,
  getAllProgressAssessmentStats,
  getAllProgressAssessmentStatsFailure,
  getAllProgressAssessmentStatsSuccess,
  updateProgressAssessment,
  createMoodTrackerEntry,
  createMoodTrackerEntryFailure,
  createMoodTrackerEntrySuccess,
};

export type ProgressState = ReturnType<typeof progressReducer>;
