import {SelfAwarenessResponse, SliceStatus} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  downloadSelfAwarenessPdfFailure,
  downloadSelfAwarenessPdfRequest,
  downloadSelfAwarenessPdfSuccess,
  getSelfAwarenessDataFailure,
  getSelfAwarenessDataRequest,
  getSelfAwarenessDataSuccess,
  getSelfAwarenessPdfFailure,
  getSelfAwarenessPdfRequest,
  getSelfAwarenessPdfSuccess,
  saveSelfAwarenessData,
  saveSelfAwarenessDataFailure,
  saveSelfAwarenessDataSuccess,
} from './selfAwarenessActions';

type SelfAwarenessSliceState = {
  feedback: {
    data: SelfAwarenessResponse;
    status: SliceStatus;
    patientId?: string;
  };
  status: SliceStatus;
  error: string;
};

const defaultSelfAwarenessFeedbackData = {
  result: [],
  hasMore: false,
  nextPage: 0,
  prevPage: 0,
  total: 0,
};

const selfAwarenessSlice = createSlice({
  name: 'selfAwareness',
  initialState: {
    feedback: {
      data: {...defaultSelfAwarenessFeedbackData},
      status: SliceStatus.idle,
    },
    status: SliceStatus.idle,
    error: '',
  } as SelfAwarenessSliceState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SelfAwarenessSliceState>) =>
    builder
      .addCase(getSelfAwarenessDataRequest, (state, action) => {
        let data: SelfAwarenessResponse = state.feedback.data;

        if (action.payload.patientId != state.feedback.patientId) {
          data = {...defaultSelfAwarenessFeedbackData};
        }

        return {
          ...state,
          feedback: {
            ...state.feedback,
            status: SliceStatus.pending,
            data,
            patientId: action.payload.patientId,
          },
        };
      })
      .addCase(getSelfAwarenessDataSuccess, (state, action) => {
        let data: SelfAwarenessResponse = {...defaultSelfAwarenessFeedbackData};

        if (typeof action.payload === 'object') {
          data = {
            ...action.payload,
            result: [...state.feedback.data.result, ...action.payload.result],
          };
        }

        return {
          ...state,
          feedback: {
            ...state.feedback,
            status: SliceStatus.resolved,
            data,
          },
        };
      })
      .addCase(getSelfAwarenessDataFailure, (state, action) => ({
        ...state,
        feedback: {
          ...state.feedback,
          status: SliceStatus.rejected,
        },
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(saveSelfAwarenessData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(saveSelfAwarenessDataSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(saveSelfAwarenessDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      })),
});

export const {reducer: selfAwarenessReducer, name: selfAwarenessReducerName} =
  selfAwarenessSlice;

export type TSelfAwarenessActions =
  | ReturnType<typeof getSelfAwarenessDataFailure>
  | ReturnType<typeof getSelfAwarenessDataRequest>
  | ReturnType<typeof getSelfAwarenessDataSuccess>
  | ReturnType<typeof saveSelfAwarenessData>
  | ReturnType<typeof saveSelfAwarenessDataSuccess>
  | ReturnType<typeof saveSelfAwarenessDataFailure>;

export const selfAwarenessActions = {
  getSelfAwarenessDataFailure,
  getSelfAwarenessDataRequest,
  getSelfAwarenessDataSuccess,
  saveSelfAwarenessData,
  saveSelfAwarenessDataSuccess,
  saveSelfAwarenessDataFailure,
  getSelfAwarenessPdfRequest,
  getSelfAwarenessPdfSuccess,
  getSelfAwarenessPdfFailure,
  downloadSelfAwarenessPdfRequest,
  downloadSelfAwarenessPdfSuccess,
  downloadSelfAwarenessPdfFailure,
};

export type SelfAwarenessState = ReturnType<typeof selfAwarenessReducer>;
