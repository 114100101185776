import * as Yup from 'yup';

import {DirectBookingSchema} from '../../definitions/Yup';
import {ProviderRole} from '../CurrentUser.types';

import {Thumbnail} from './Provider.types';

export type DirectBookingFormData = Yup.InferType<typeof DirectBookingSchema>;

// Filter Options Type
export type DirectBookingFilterType = {
  profession: string[];
  state: string[];
  conditions: string[];
  approvedInNetworkInsurances: {payerName: string; states: string[]}[];
};

// Searched Provider Type
export type DirectBookingSearchProviderParams = {
  loadMore?: boolean;
  conditions?: string;
  paymentType?: string;
  state?: string;
  country?: string;
  profession?: string;
  size?: string;
  offset?: string;
};

export type TProfessionalHeadshot = {
  id: string;
  width: number;
  height: number;
  url: string;
  fileName: string;
  size: number;
  type: string;
  thumbnails: {
    small: Thumbnail;
    large: Thumbnail;
    full: Thumbnail;
  };
};

export type DirectBookingProviderType = {
  userId: string;
  providerId: string;
  calendarId: number;
  educationAndProfessionalTraining?: string;
  acceptedInsurance?: string[];
  providerFullName?: string;
  introductoryBio?: string;
  titleProviderPerformingSpecialtyProviderLicenseType: string[];
  state: string[];
  conditions: string[];
  professionalHeadshot: TProfessionalHeadshot[];
  profession: string;
  country?: string;
  languages?: string[];
  ethnicity?: string[];
};

export type SearchedProvidersType = {
  result: DirectBookingProviderType[];
  offset?: string;
  hasMore: boolean;
};

export type DirectBookingSessionType = {
  paymentType?: string;
  appointmentType: string;
  appointmentTypeId: string;
  appointmentDuration: string;
  timezone: string;
  datetime: string;
  providerRole: ProviderRole;
  providerState: string;
};

export enum DirectBookingProfession {
  physician = 'Prescriber',
  therapist = 'Therapist',
}
