import React, {FC} from 'react';
import {motion} from 'framer-motion';

interface Props {
  children: React.ReactNode;
  classes?: string;
}
export const SlideIn: FC<Props> = ({children, classes}) => {
  return (
    <motion.div
      className={classes}
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0.8,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{
        delay: 0.2, // Add a 2-second delay
      }}
    >
      {children}
    </motion.div>
  );
};
