import {IntakeType} from 'definitions/Intake';
import {IntakeFormattedData, IntakeResponse} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const saveIntakeData = async (
  data: IntakeFormattedData,
): Promise<
  Response<{
    message: IntakeResponse;
  }>
> => {
  try {
    const res = await requestHandler<
      {
        message: IntakeResponse;
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.SaveIntakeData,
      data: data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getMemberIntakeData = async (data: {
  patientId: string;
  intakeType: IntakeType;
}): Promise<
  Response<{
    message: IntakeResponse;
  }>
> => {
  try {
    const res = await requestHandler<
      {
        message: IntakeResponse;
      },
      typeof data
    >({
      method: HttpMethods.GET,
      url: `${EndPoints.GetMemberIntakeData}?patientId=${data.patientId}&intakeType=${data.intakeType}` as EndPoints,
      data: data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const IntakeService = {saveIntakeData, getMemberIntakeData};
