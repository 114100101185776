import {SliceStatus, UserSubscriptionDetails} from 'interfaces';
import {UAParser} from 'ua-parser-js';
import {isMobile} from 'utils/helpers';

import {
  ActionReducerMapBuilder,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

const logData = createAction<any>('logData');
const checkSubscriptionStatus = createAction('CheckSubscriptionStatus');
const setSubscriptionStatus = createAction<boolean>('SetSubscriptionStatus');
const setSwInstallTimestamp = createAction<number>('setSwInstallTimestamp');
const subscribeUser = createAction<UserSubscriptionDetails>('SubscribeUser');

const parser = new UAParser();

type PushNotificationSliceState = {
  status: string;
  error: string;
  isSubscribedToPushNotification: boolean;
  swInstallTimestamp?: number;
  isMobile: boolean;
  browserName: string;
};

const pushNotificationSlice = createSlice({
  name: 'pushNotification',
  initialState: {
    status: SliceStatus.idle,
    error: '',
    isSubscribedToPushNotification: false,
    isMobile: isMobile,
    browserName: parser.getBrowser().name ?? '',
    swInstallTimestamp: Date.now(),
  },
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<PushNotificationSliceState>,
  ) =>
    builder
      .addCase(setSubscriptionStatus, (state, action) => ({
        ...state,
        isSubscribedToPushNotification: action.payload,
      }))
      .addCase(setSwInstallTimestamp, (state, action) => ({
        ...state,
        swInstallTimestamp: action.payload,
      }))
      .addDefaultCase(state => state),
});

export const {
  reducer: pushNotificationReducer,
  name: pushNotificationReducerName,
} = pushNotificationSlice;

export type TPushNotificationActions =
  | ReturnType<typeof checkSubscriptionStatus>
  | ReturnType<typeof setSubscriptionStatus>
  | ReturnType<typeof setSwInstallTimestamp>
  | ReturnType<typeof subscribeUser>
  | ReturnType<typeof logData>;

export const pushNotificationActions = {
  checkSubscriptionStatus,
  setSubscriptionStatus,
  setSwInstallTimestamp,
  subscribeUser,
  logData,
};

export type PushNotificationState = ReturnType<typeof pushNotificationReducer>;
