import {RootState} from 'app/rootReducer';
import {
  AppointmentDurations,
  AppointmentTypes,
  WherebyRoomModeTypes,
} from 'interfaces';

import {createSelector, OutputParametricSelector} from '@reduxjs/toolkit';

import {AppointmentState} from './appointmentSlice';

type AppointmentDurationAndType = {
  duration: AppointmentDurations;
  appointmentType: AppointmentTypes;
};

function getAppointment(state: RootState): AppointmentState {
  return state.appointment;
}

export const selectCalendarAppointments = createSelector(
  getAppointment,
  appointment => appointment.appointments,
);

export const selectDueAppointments = createSelector(
  getAppointment,
  appointment => appointment.dueAppointments,
);

export const selectCalendarLoadingState = createSelector(
  getAppointment,
  appointment => appointment.loading,
);

export const selectAppointmentStatus = createSelector(
  getAppointment,
  appointment => appointment.status,
);

function getAppointmentTypeId(
  state: RootState,
  appointmentDurationAndType: AppointmentDurationAndType,
): string {
  return state.appointment[appointmentDurationAndType.duration][
    appointmentDurationAndType.appointmentType
  ].appointmentTypeId;
}

export const makeAppointmentTypeId = (): OutputParametricSelector<
  RootState,
  AppointmentDurationAndType,
  string,
  (res: string) => string
> =>
  createSelector(
    [getAppointmentTypeId],
    appointmentTypeId => appointmentTypeId,
  );

export const selectMeetingDetailsById = (appointmentID: number) =>
  createSelector(
    getAppointment,
    appointments =>
      appointments.appointments.find(
        appt => appt?.appointmentID === appointmentID,
      )?.meeting,
  );

export const getGroupCallLabelByAppointmentID = (appointmentID: number) =>
  createSelector(
    getAppointment,
    appointments =>
      appointments.appointments.find(
        appt => appt?.appointmentID === appointmentID,
      )?.labels,
  );

export const selectAppointment = (
  roomMode: WherebyRoomModeTypes,
  appointmentID: number,
) => {
  if (roomMode === 'group') {
    return createSelector(getAppointment, appointment =>
      appointment.appointments.find(appt => appt.classID === appointmentID),
    );
  }
  return createSelector(getAppointment, appointment =>
    appointment.appointments.find(appt => appt.appointmentID === appointmentID),
  );
};

export const selectAvailabilitySlots = createSelector(
  getAppointment,
  appointment => appointment.availabilitySlots,
);

export const selectAvailability = createSelector(
  getAppointment,
  appointment => appointment.availability,
);
export const selectProviderAvailabilityStatus = createSelector(
  getAppointment,
  appointment => appointment.providersLoadingStatus,
);
export const selectSlotAvailabilityStatus = createSelector(
  getAppointment,
  appointment => appointment.slotsLoadingStatus,
);

export const getProvider = createSelector(
  getAppointment,
  appointment => appointment.provider,
);
