import React, {FC, Suspense} from 'react';

import {LayoutProps} from './Layout.types';

export const DefaultLayout: FC<LayoutProps> = ({
  children,
  bgColor,
  lgColumns,
}) => (
  <main
    className={`min-w-full h-full min-h-screen ${bgColor} grid grid-cols-1 ${lgColumns}`}
  >
    <Suspense>{children}</Suspense>
  </main>
);
