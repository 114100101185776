import React, {FC, lazy, useEffect} from 'react';
import {AnimatedRoutes, RouteTransition} from 'components/HOCs';
import {selectUserProfile} from 'features/User';
import {DigitalPracticeAccountTypes, StripePlanNames} from 'interfaces';
import {useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';
import {isMember, isProvider} from 'utils';

import {PrivateRoutes} from './PrivateRoutes';
import {PublicRoutes} from './PublicRoutes';

const ProtectedPlanRoutes = (pathname: string, paymentPlan: any) => {
  if (
    [
      StripePlanNames.medicalCare,
      StripePlanNames.medicalCareBiweekly,
      StripePlanNames.medicalCareBiannually,
      StripePlanNames.medicalCareAnnually,
      StripePlanNames.uninsured,
      StripePlanNames.uninsuredBiweekly,
      StripePlanNames.uninsuredBiweeklyReviewed,
      StripePlanNames.uninsuredBiannually,
      StripePlanNames.uninsuredAnnually,
    ].includes(paymentPlan) &&
    pathname === '/book-appointment/video_call_with_therapist'
  )
    return <Redirect to="/dashboard" />;
  else if (
    [
      StripePlanNames.therapy,
      StripePlanNames.therapyBiannually,
      StripePlanNames.therapyAnnually,
      StripePlanNames.newTherapy,
      StripePlanNames.newTherapyBiannually,
      StripePlanNames.newTherapyAnnually,
    ].includes(paymentPlan) &&
    pathname === '/book-appointment/doctor_consultation'
  )
    return <Redirect to="/dashboard" />;
  else return null;
};

const NoMatchPage = lazy(() =>
  import('../404Page').catch(() => import('../404Page')),
);
export const AllRoutes: FC = () => {
  const user = useSelector(selectUserProfile);
  const {pathname} = useLocation();

  const APP_ENV = process.env.REACT_APP_ENV ?? '';
  // intercom integration
  const intercomProvider = APP_ENV === 'production' && user && isProvider(user);
  const intercomMember =
    ['staging', 'production'].includes(APP_ENV) && user && isMember(user);

  useEffect(() => {
    if (intercomProvider) {
      window.Intercom('boot', {
        userId: user?._id,
        email: user?.email,
        name: user?.fullName,
        phone: user?.phoneNumber,
        avatar: {type: 'avatar', image_url: user.image || ''},
      });
    } else if (intercomMember) {
      const insured = {
        'Insurance Company Type': user?.insuranceDetails?.companyType,
        'Insurance Company': user?.insuranceDetails?.companyName,
        'Member Id': user?.insuranceDetails?.memberId,
        dependency: user?.insuranceDetails?.dependencyStatus,
        'Attr: Insurance Card Uploaded':
          user.insuranceImageFront && user.insuranceImageBack ? 'Yes' : 'No',
        'Insurance City': user.insuranceDetails?.primaryInsuredCity,
        'Insurance State': user.insuranceDetails?.primaryInsuredState,
        'Insurance Street Address':
          user.insuranceDetails?.primaryInsuredStreetAddress,
        'Insurance Zip Code': user.insuranceDetails?.primaryInsuredZipcode,
      };

      window.Intercom('boot', {
        userId: user?._id,
        email: user?.email,
        name: user?.fullName,
        phone: user?.phoneNumber,
        avatar: {type: 'avatar', image_url: user.image || ''},
        'Date Of Birth': user?.dateOfBirth
          ? new Date(user?.dateOfBirth).toLocaleDateString('en-US')
          : '',
        gender: user?.gender,
        'Attr: Credit Card Added':
          Array.isArray(user.stripeCustomerCards) &&
          user.stripeCustomerCards.length > 0
            ? 'Yes'
            : 'No',
        ...(user?.digitalPracticeAccountType ===
          DigitalPracticeAccountTypes.insurance ||
        user?.paymentPlan === 'together'
          ? insured
          : {}),
        ...(user.digitalPracticeAccountType ===
        DigitalPracticeAccountTypes.outOfPocket
          ? {'Out Of Pocket': 'Yes'}
          : {}),
      });
    } else {
      window.Intercom('shutdown');
    }
  }, [user]);

  return (
    <AnimatedRoutes>
      {PublicRoutes()}
      {ProtectedPlanRoutes(pathname, user?.paymentPlan)}
      {PrivateRoutes()}
      <RouteTransition exact path="/basic/register">
        <Redirect to="/uninsured/register" />
      </RouteTransition>
      <RouteTransition path="*">
        <NoMatchPage />
      </RouteTransition>
    </AnimatedRoutes>
  );
};
