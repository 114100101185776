import React from 'react';

import {SvgIcon} from './SvgIcon';

export const ConsultationSvg: SvgIcon = ({
  width = 28,
  height = 28,
  strokeWidth = 1,
  fillColor = '#2E62EC',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 28 28"
    strokeWidth={strokeWidth}
  >
    <circle cx="14" cy="14" r="14" fill="#D7DBF0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 10C16.5 11.3807 15.3807 12.5 14 12.5C12.6193 12.5 11.5 11.3807 11.5 10C11.5 8.61929 12.6193 7.5 14 7.5C15.3807 7.5 16.5 8.61929 16.5 10ZM16.1883 13.3489C17.2793 12.6345 18 11.4014 18 10C18 7.79086 16.2091 6 14 6C11.7909 6 10 7.79086 10 10C10 11.4014 10.7207 12.6345 11.8117 13.3489C9.01743 14.2677 7 16.8983 7 20V20.5V22H8.5H19.5H21V20.5V20C21 16.8983 18.9826 14.2677 16.1883 13.3489ZM19.5 20V20.5H8.5V20C8.5 16.9625 10.9624 14.5 14 14.5C17.0376 14.5 19.5 16.9625 19.5 20Z"
      fill="#2E62EC"
    />
  </svg>
);

export const WholesaleSvg: SvgIcon = ({
  width = 28,
  height = 28,
  strokeWidth = 1,
  fillColor = '#2E62EC',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 28 28"
    strokeWidth={strokeWidth}
  >
    <circle cx="14" cy="14" r="14" fill="#D7DBF0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 8.5H20.5V19.5H7.5V8.5H9.25V15V16.2135L10.3354 15.6708L12 14.8385L13.6646 15.6708L14.75 16.2135V15V8.5ZM13.25 8.5H10.75V13.7865L11.6646 13.3292L12 13.1615L12.3354 13.3292L13.25 13.7865V8.5ZM6 7H7.5H20.5H22V8.5V19.5V21H20.5H7.5H6V19.5V8.5V7Z"
      fill="#2E62EC"
    />
  </svg>
);

export const SessionSvg: SvgIcon = ({
  width = 28,
  height = 28,
  strokeWidth = 1,
  fillColor = '#2E62EC',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 28 28"
    strokeWidth={strokeWidth}
  >
    <circle cx="14" cy="14" r="14" fill="#D7DBF0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8H22V19.2982H11.8495L8.06452 22V19.2982H6V8ZM7.54839 9.47368V17.8246H9.6129V19.0526L11.3333 17.8246H20.4516V9.47368H7.54839Z"
      fill="#2E62EC"
    />
  </svg>
);

export const SupportSvg: SvgIcon = ({
  width = 28,
  height = 28,
  strokeWidth = 1,
  fillColor = '#2E62EC',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 28 28"
    strokeWidth={strokeWidth}
  >
    <circle cx="14" cy="14" r="14" fill="#D7DBF0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 7.5C10.4028 7.5 8.5 9.40279 8.5 11.75V12.9623H9.83312V18.1393H7.75V17.8539H7V17.1039V11.75C7 8.57436 9.57436 6 12.75 6H15.2487C18.4244 6 20.9987 8.57436 20.9987 11.75V17.1039V19.2457C20.9987 20.7646 19.7674 21.9955 18.2487 21.9955H13.9994H13.2494V20.4955H13.9994H18.2487C18.9392 20.4955 19.4987 19.9359 19.4987 19.2457V18.1393H18.1656V12.9623H19.4987V11.75C19.4987 9.40279 17.596 7.5 15.2487 7.5H12.75Z"
      fill="#2E62EC"
    />
  </svg>
);
