import React from 'react';
import {getCountryDetailsByIsoCode} from 'utils';

import {CurrencyExchangeRate} from './CurrencyExchangeRate';

export interface CurrencyExchangeRateForNonPaypalCountriesProps {
  countryCode?: string;
  value?: number;
}

export const CurrencyExchangeRateForNonPaypalCountries = ({
  countryCode,
  value,
}: CurrencyExchangeRateForNonPaypalCountriesProps) => {
  const countryExchangeCurrency =
    countryCode && getCountryDetailsByIsoCode(countryCode)?.currency;

  return countryExchangeCurrency ? (
    <CurrencyExchangeRate code={countryExchangeCurrency} value={value} />
  ) : null;
};
