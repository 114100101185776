import {ProviderOptionSchema, ProviderRegisterSchema} from 'definitions/Yup';
import {CountryOfResidenceType} from 'interfaces/Countries.types';
import {UserRoles} from 'interfaces/CurrentUser.types';
import * as Yup from 'yup';

export type ProviderRegisterFormData = Yup.InferType<
  typeof ProviderRegisterSchema
>;
export type ProviderOptionFormData = Yup.InferType<typeof ProviderOptionSchema>;

export type ProviderSignupFormData = {
  fullName: string;
  stateOfResidence: string;
  email: string;
  password: string;
  referralCode?: string;
  selectedProvider: string;
  selectedLicenseType: string;
  phoneNumber: string;
  countryOfResidence: CountryOfResidenceType;
};
export type ProviderUpdateForm = {
  formType: string;
  step?: number;
  role: UserRoles;
  formData: {};
  workflow?: string;
};

export enum ProviderFormType {
  verification = 'verification',
  profile = 'profile',
  onboarding = 'onboarding',
  caqh = 'caqh',
}

export enum ProviderFormWorkflow {
  onboarding = 'onboarding',
  dashboard = 'dashboard',
}
