// @ts-nocheck
import React, {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Logo from 'assets/images/logo-white@3x.webp';

type Props = {
  textSize: string;
  postText: string;
  setProcessedImage: (imageUrl: string) => void;
  postDimensions: {
    height: number;
    width: number;
    initX: number;
    initY: number;
    avatarRadius: number;
    logoSize: number;
    padding: number;
  };
  bgSource?: string;
};

type CanvasRef = HTMLCanvasElement;
const isOffScreenCanvasSupported = 'OffscreenCanvas' in window;
export const SharePostCanvas = forwardRef<CanvasRef, Props>(
  (
    {textSize, postText, postDimensions, bgSource, setProcessedImage},
    canvasref,
  ) => {
    const bgRef = useRef<HTMLImageElement>(null);
    const logoRef = useRef<HTMLImageElement>(null);
    const logoCanvas = useRef<HTMLCanvasElement>(null);
    const textCanvas = useRef<HTMLCanvasElement>(null);
    const [logoOffscreenCanvas, setLogoOffscreenCanvas] =
      useState<OffscreenCanvas | null>();
    const [textOffscreenCanvas, setTextOffscreenCanvas] =
      useState<OffscreenCanvas | null>();

    const textLines: {
      line: string;
      y: number;
    }[] = [];

    const {initX, initY, padding, width, logoSize, height} = postDimensions;
    const maxWidth = width - 240;
    const drawBox = useCallback(
      (ctx: CanvasRenderingContext2D, boxHeight: number) => {
        ctx.beginPath();
        ctx.moveTo(20, 0);
        ctx.lineTo(maxWidth - 20, 0);
        ctx.quadraticCurveTo(maxWidth, 0, maxWidth, 20);
        ctx.lineTo(maxWidth, boxHeight - 20);
        ctx.quadraticCurveTo(maxWidth, boxHeight, maxWidth - 20, boxHeight);
        ctx.lineTo(20, boxHeight);
        ctx.lineTo(0, boxHeight);
        ctx.lineTo(0, 20);
        ctx.quadraticCurveTo(0, 0, 20, 0);
        ctx.fillStyle = 'white';
        ctx.fill();
      },
      [maxWidth],
    );

    const wrapText = useCallback(() => {
      const lineHeight = 50;
      let boxHeight = 0;
      if (textCanvas.current) {
        const offscreenRef = isOffScreenCanvasSupported
          ? new OffscreenCanvas(0, 0)
          : textCanvas.current;
        const textContext = offscreenRef.getContext('2d');
        offscreenRef.width = maxWidth;
        return new Promise<boolean>(resolve => {
          let line = '';
          let positionY = 0;
          postText.split(' ').forEach((word, idx) => {
            const testLine = `${line}${word} `;
            const testWidth = textContext?.measureText(testLine).width;
            if (
              Math.floor(testWidth ?? 0) > Math.floor(maxWidth / 4 + 4) &&
              idx
            ) {
              textLines.push({line, y: positionY});
              line = `${word} `;
              positionY += lineHeight;
              boxHeight += lineHeight;
            } else {
              line = testLine;
            }
          });

          boxHeight += padding * 2;
          offscreenRef.height = boxHeight;
          textLines.push({line, y: positionY});

          drawBox(textContext as CanvasRenderingContext2D, boxHeight);
          if (textLines.length > 0) {
            textContext!.font = textSize;
            textContext!.fillStyle = '#4a4a4a';
            textLines.forEach(obj => {
              textContext!.fillText(
                obj.line, // text
                padding + 20, // starting point on horizontal axis
                obj.y + padding + 10, // starting point on vertical axis
                maxWidth, // max-width
              );
            });
          }
          if (
            isOffScreenCanvasSupported &&
            offscreenRef instanceof OffscreenCanvas
          )
            setTextOffscreenCanvas(offscreenRef);
          else setTextOffscreenCanvas(null);
          resolve(true);
        });
      }
      return;
    }, [drawBox, padding, postText, maxWidth, textSize, textLines]);

    const drawLogo = useCallback(() => {
      if (logoCanvas.current) {
        const offscreenRef = isOffScreenCanvasSupported
          ? new OffscreenCanvas(logoSize, logoSize)
          : logoCanvas.current;
        const logoContext = offscreenRef.getContext('2d');
        offscreenRef.width = logoSize;
        offscreenRef.height = logoSize;
        if (logoRef.current) {
          logoContext?.drawImage(logoRef.current, 0, 0, logoSize, logoSize);
        }
        setLogoOffscreenCanvas(
          isOffScreenCanvasSupported && offscreenRef instanceof OffscreenCanvas
            ? offscreenRef
            : null,
        );
      }
    }, [logoSize]);

    const drawCanvas = useCallback(() => {
      const {current} = canvasref as RefObject<HTMLCanvasElement>;
      if (current) {
        const ctx = current.getContext('2d');
        const textImage = textOffscreenCanvas || textCanvas.current;
        const logoImage = logoOffscreenCanvas || logoCanvas.current;
        if (ctx && bgRef.current && textImage && logoImage) {
          current.width = width;
          current.height = height;
          ctx.clearRect(0, 0, current.width, current.height);
          ctx.save();
          ctx.drawImage(bgRef.current, 0, 0, width, height);
          ctx.drawImage(textImage, initX + 20, initY);
          ctx.drawImage(
            logoImage,
            Math.floor((width - logoSize) * 0.5),
            Math.floor(height - logoSize * 1.5),
            logoSize,
            logoSize,
          );
          ctx.restore();
          setProcessedImage(current?.toDataURL('image/png') ?? '');
        }
      }
    }, [
      canvasref,
      height,
      initX,
      initY,
      logoOffscreenCanvas,
      logoSize,
      setProcessedImage,
      textOffscreenCanvas,
      width,
    ]);

    useEffect(() => {
      const handler = setTimeout(() => {
        wrapText();
        drawLogo();
      }, 1000);

      return (): void => {
        clearTimeout(handler);
      };
    }, [drawLogo, wrapText]);

    useEffect(() => {
      const {current} = canvasref as RefObject<HTMLCanvasElement>;
      if (
        current &&
        textOffscreenCanvas !== undefined &&
        logoOffscreenCanvas !== undefined
      ) {
        if (bgRef.current) {
          bgRef.current.onload = () => {
            drawCanvas();
          };
        }
      }
    }, [canvasref, drawCanvas, logoOffscreenCanvas, textOffscreenCanvas]);

    useEffect(() => {
      if (bgSource) drawCanvas();
    }, [bgSource, drawCanvas]);

    return (
      <>
        <canvas ref={canvasref} id="canvas" className="hidden" />
        <canvas ref={textCanvas} id="textCanvas" className="hidden" />
        <canvas ref={logoCanvas} id="logoCanvas" className="hidden" />
        <img ref={logoRef} alt="Wellnite Logo" src={Logo} className="hidden" />
        <img ref={bgRef} src={bgSource} alt="Background" className="hidden" />
      </>
    );
  },
);
