import {selectUserProfile} from 'features/User';
import Cookies from 'js-cookie';
import {useSelector} from 'react-redux';
import {getLanguageFromCountry} from 'utils/translationUtils';

export const useCalendarLocalization = (): {
  months: string[];
  weekdaysLong: string[];
  weekdaysShort: string[];
  locale: string;
  firstDayOfWeek: number;
} => {
  const user = useSelector(selectUserProfile);
  const cookieLanguage = Cookies.get('preferredLanguage');

  const getLanguage = () => {
    if (user?.preferredLanguage) {
      return user.preferredLanguage;
    }
    if (user?.countryOfResidence) {
      return getLanguageFromCountry(user.countryOfResidence.code) as string;
    }
    if (cookieLanguage) {
      return cookieLanguage;
    }
    const browserLanguage = navigator.language;
    if (browserLanguage) {
      const lng = browserLanguage.split('-');
      if (lng[0]) return lng[0];
    }
    return 'en';
  };

  const language = getLanguage();

  const languageMap = {
    en: {
      month: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      weekdaysLong: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      firstDayOfWeek: 0,
    },
    es: {
      month: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      weekdaysLong: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      firstDayOfWeek: 1,
    },
    pt: {
      month: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      weekdaysLong: [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
      ],
      weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      firstDayOfWeek: 0,
    },
  };

  return {
    months: languageMap[language]?.month,
    weekdaysLong: languageMap[language]?.weekdaysLong,
    weekdaysShort: languageMap[language]?.weekdaysShort,
    locale: language,
    firstDayOfWeek: languageMap[language]?.firstDayOfWeek,
  };
};
