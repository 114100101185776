import {
  AppointmentTypes,
  DirectBookingFilterType,
  DirectBookingProviderType,
  DirectBookingSearchProviderParams,
  DirectBookingSessionType,
  SearchedProvidersType,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

// Filter Options
export const getDirectBookingFilterOptions = createAction(
  'getDirectBookingFilterOptions',
);
export const getDirectBookingFilterOptionSuccess =
  createAction<DirectBookingFilterType>('getDirectBookingFilterOptionSuccess');
export const getDirectBookingFilterOptionsFailure = createAction(
  'getDirectBookingFilterOptionsFailure',
);

// Searched Providers
export const getDirectBookingSearchedProviders = createAction<
  DirectBookingSearchProviderParams | undefined
>('getDirectBookingSearchedProviders');
export const getDirectBookingSearchedProvidersSuccess =
  createAction<SearchedProvidersType>(
    'getDirectBookingSearchedProvidersSuccess',
  );
export const getDirectBookingSearchedProvidersFailure = createAction(
  'getDirectBookingSearchedProvidersFailure',
);
export const resetDirectBookingSearchedProviders = createAction(
  'resetDirectBookingSearchedProviders',
);
export const setDirectBookingSelectedProvider = createAction<
  DirectBookingProviderType | undefined
>('setDirectBookingSelectedProvider');
export const setDirectBookingScrollPosition = createAction<number>(
  'setDirectBookingScrollPosition',
);

// get Provider By Id
export const getProviderById = createAction<string>('getProviderById');
export const getProviderByIdSuccess = createAction<DirectBookingProviderType>(
  'getProviderByIdSuccess',
);
export const getProviderByIdFailure = createAction('getProviderByIdFailure');

export const setDirectBookingSelectedSession = createAction<
  DirectBookingSessionType | undefined
>('setDirectBookingSelectedSession');

export const bookAppointment = createAction<{
  appointmentDateTimeString: string;
  providerId: string;
  appointmentType: AppointmentTypes;
  patientTimezone: string;
  calendarId: number;
  appointmentTypeID: string;
  patientEmail?: string;
  providerFullName?: string;
  memberFullName?: string;
}>('DirectBookAppointment');
export const bookAppointmentSuccess = createAction(
  'DirectBookAppointmentSuccess',
);
export const bookAppointmentFailure = createAction<string>(
  'DirectBookAppointmentFailure',
);
