import React, {FC} from 'react';
import classNames from 'classnames';
import {Button, LeftNavIcon, LoadingSpinner, RightNavIcon} from 'components';
import {addMonths} from 'date-fns';
import {AppointmentCalendarContainer} from 'features/Appointment/Calendar2/CalendarContainer.styles';
import {twoMonthsFromCurrentMonth} from 'features/Provider/Calendar2/constants';
import {FormattedAppointmentType, useCalendar} from 'hooks';
import {useCalendarLocalization} from 'hooks/useCalendarLocalization';
import {SliceStatus} from 'interfaces';
import DayPicker, {NavbarElementProps} from 'react-day-picker';
import {useTranslation} from 'react-i18next';

import 'react-day-picker/lib/style.css';

type Props = {
  calendar: ReturnType<typeof useCalendar>;
  selectedAppt?: FormattedAppointmentType;
};

function Navbar({
  onPreviousClick,
  onNextClick,
  className,
  showPreviousButton,
  showNextButton,
}: NavbarElementProps) {
  return (
    <div className={className}>
      <button className="left" onClick={() => onPreviousClick()}>
        <LeftNavIcon
          width={20}
          height={20}
          strokeWidth={2}
          strokeColor={classNames({
            'text-strongGray': !showPreviousButton,
          })}
        />
      </button>
      <button className="right" onClick={() => onNextClick()}>
        <RightNavIcon
          width={20}
          height={20}
          strokeWidth={2}
          strokeColor={classNames({
            'text-strongGray': !showNextButton,
          })}
        />
      </button>
    </div>
  );
}

export const DayPickerCalendar: FC<Props> = ({
  // selectedAppt,
  calendar: {
    selectedDay,
    onDayClick,
    calendarAppointments,
    calendarLoadingState,
    now,
    modifiers,
    fetchAppointments,
    // appointmentDetails,
  },
}) => {
  const {t} = useTranslation();
  const {months, weekdaysLong, weekdaysShort, locale, firstDayOfWeek} =
    useCalendarLocalization();
  return (
    <AppointmentCalendarContainer className="flex flex-col justify-content items-center">
      <DayPicker
        showOutsideDays
        className="rounded-2xl border px-10 pt-10 pb-5 w-full"
        month={now}
        modifiers={modifiers}
        fromMonth={now}
        toMonth={addMonths(now, 1)}
        onDayClick={onDayClick}
        selectedDays={selectedDay}
        months={months}
        weekdaysLong={weekdaysLong}
        weekdaysShort={weekdaysShort}
        locale={locale}
        firstDayOfWeek={firstDayOfWeek}
        navbarElement={({className, ...props}: NavbarElementProps) => (
          <Navbar
            className="relative top-5 flex justify-between items-center"
            {...props}
          />
        )}
      />
      {calendarLoadingState === SliceStatus.pending &&
      !calendarAppointments.length ? (
        <div className="flex justify-center m-2">
          <LoadingSpinner type="ThreeDots" />
        </div>
      ) : null}
      {calendarLoadingState === SliceStatus.rejected ? (
        <Button
          type="button"
          borderRadius="full"
          borderColor="transparent"
          onClick={() => fetchAppointments(twoMonthsFromCurrentMonth)}
          className="py-2 px-4 mt-4"
        >
          {t('failedToLoadRetry')}
        </Button>
      ) : null}
      {/* {selectedAppt ? (
        <section className="pt-4 text-center">
          {appointmentDetails(selectedAppt)}
        </section>
      ) : null} */}
    </AppointmentCalendarContainer>
  );
};
