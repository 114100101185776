import {
  PrescriberProfile,
  TherapistProfile,
  UserRoles,
} from 'interfaces/CurrentUser.types';

enum WellbitStatus {
  cashed = 'cashed',
  notCashed = 'not-cashed',
}

enum WellbitType {
  supervision = 'supervision',
}

export type Wellbit = {
  status: WellbitStatus;
  providerId: string;
  role: UserRoles;
  amount: number;
  appointmentId?: string;
  wellbitType: WellbitType;
  connectedProviderId: string;
  connectedProviderRole: UserRoles;
  createdAt: string;
  updatedAt: string;
  __v: 0;
  dateCashed: number;
};

export type WellbitHistory = {
  amountCashedInWellbits: number;
  balance: number;
  dateCashed: number;
  cashingId: string;
};

export type WellbitWallet = {
  providerId: TherapistProfile | PrescriberProfile;
  role: UserRoles;
  totalWellbits: number;
  ownedWellbits: string;
  cashingHistory: WellbitHistory[];
  unpaidCashedWellbits: {
    amountCashedInWellbits: number;
    wellbitIds: string[];
    dateCashed: number;
    cashingId: string;
  }[];
  wellbitTypeTotalCount: {
    providerReferral: number;
    supervision: number;
    oopDp: number;
    inNetworkDp: number;
    oopWellnite: number;
    inNetworkWellnite: number;
  };
  paymentHistory: {
    amountIfPayedInDollars: number;
    perksOrRewards: string;
    cashingId: string[];
    dateReceived: number;
  }[];
  createdAt: string;
  updatedAt: string;
};
