export const TIMELINE_QUERY_LIMIT = 25;
export const USER_POSTS_QUERY_LIMIT = 25;
export const BOOKMARKED_POSTS_QUERY_LIMIT = 25;

export enum FirebaseRefs {
  timeline = 'timeline',
  bookmarksTimeline = 'bookmarksTimeline',
  userPosts = 'userPosts',
  postLikes = 'postLikes',
  postBookmarks = 'postBookmarks',
  users = 'users',
  channels = 'channels',
  mentalHealthMessages = 'mentalHealthMessages',
  mentalHealthCoach = 'mentalHealthCoach',
  unreadMessages = 'unreadMessages',
  lastSeen = 'lastSeen',
  presence = 'presence',
  typing = 'typing',
  notifications = 'notifications',
  videoCall = 'videoCall',
  videoWaitingRoom = 'videoWaitingRoom',
  videoAdmitRequest = 'videoAdmitRequest',
  connected = '.info/connected',
  serverOffset = '.info/serverTimeOffset',
  postShares = 'postShares',
  subscriptionData = 'subscriptionData',
}
