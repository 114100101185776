import React, {FC, ReactNode} from 'react';
import classnames from 'classnames';
import {useToggle} from 'hooks';
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  FieldPath,
  FieldValues,
  Merge,
  RegisterOptions,
  UseFormRegisterReturn,
} from 'react-hook-form';
import ReactQuill from 'react-quill';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {
  PasswordHideIcon,
  PasswordShowIcon,
  WarningIcon,
  WarningTriangle,
} from '../SvgIcon';

import EditToolbar, {formats, modules} from './EditorToolbar';

import 'react-quill/dist/quill.snow.css';

const RichTextAreaContainer = styled.section`
  background: #f9fafb;
  border-radius: 11px;
  border: 1px solid #e1e1e1;
  overflow: hidden;
  .quill {
    border: none;
    color: #000;
    background: #fafafa;
  }
  .ql-container.ql-snow {
    border: none;
  }
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 13px;
    color: #ccc;
    font-style: normal;
    font-weight: normal;
  }
  .ql-toolbar.ql-snow {
    border: 0px solid #e1e1e1;
    border-top-width: 1px;
    padding: 2px;
  }
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    height: 20px;
  }
  .ql-snow .ql-color-picker,
  .ql-snow .ql-icon-picker {
    position: absolute;
  }
`;
interface LabelProps {
  htmlFor: string;
  id?: string;
  className?: string;
  fontSize?: string;
  required?: boolean;
  children?: React.ReactNode;
  error?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
}

interface InputProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  className?: string;
  children?: React.ReactNode;
  bgColor?: string;
  type?: 'email' | 'password' | 'text' | 'tel' | 'date' | 'time';
  id: string;
  name: TFieldName;
  placeholder?: string;
  register?(
    name: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName>,
  ): UseFormRegisterReturn<TFieldName>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  error?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  value?: string;
  list?: string;
  defaultValue?: string | number;
  required?: boolean;
}

interface NumberInputProps {
  className?: string;
  children?: React.ReactNode;
  bgColor?: string;
  type?: 'email' | 'password' | 'text' | 'tel';
  id: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  error?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  value?: string;
  list?: string;
  name?: string;
  defaultValue?: string | number;
}

interface TextAreaProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  className?: string;
  children?: React.ReactNode;
  bgColor?: string;
  rows?: number;
  id: string;
  name: TFieldName;
  placeholder?: string;
  register?(
    name: TFieldName,
    options?: RegisterOptions<TFieldValues, TFieldName>,
  ): UseFormRegisterReturn<TFieldName>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  error?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  value?: string;
  defaultValue?: string | number;
}
interface RichTextAreaProps {
  className?: string;
  children?: React.ReactNode;
  bgColor?: string;
  name: string;
  placeholder?: string;
  control: any;
  onAutoSave?: () => void;
  error?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  value?: string;
  showToolbar?: boolean;
}

export function FormInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  bgColor = 'gray-100',
  type = 'text',
  name,
  className,
  id,
  register,
  placeholder,
  error,
  defaultValue,
  onChange,
  ...rest
}: InputProps<TFieldValues, TFieldName>) {
  const _rest =
    register === undefined && typeof onChange === 'function'
      ? {...rest, onChange}
      : rest; //this is for controlled component
  return (
    <input
      {...register?.(name, {onChange})}
      {..._rest}
      aria-label={name}
      placeholder={placeholder}
      id={id}
      type={type}
      className={classnames(
        `${className} appearance-none border rounded-lg w-full h-12 py-2 pr-3 leading-tight focus:outline-none bg-${bgColor}`,
        {
          'border-red-500': error,
          'text-red-500': error,
          'placeholder-red-500': error,
          'focus:border-red-500': error,
          'focus:shadow-outline-red': error,
          'focus:shadow-outline': !error,
          'pl-10': !placeholder,
          'pl-4': placeholder,
        },
      )}
      defaultValue={defaultValue}
    />
  );
}

export const NumberInput: React.FC<NumberInputProps> = ({
  bgColor = 'gray-100',
  type = 'text',
  name,
  className,
  id,
  placeholder,
  error,
  defaultValue,
  onChange,
  ...rest
}) => {
  return (
    <input
      {...rest}
      onChange={onChange}
      aria-label={name}
      placeholder={placeholder}
      id={id}
      type={type}
      className={classnames(
        `${className} appearance-none border rounded-lg w-full h-12 py-2 pr-3 leading-tight focus:outline-none bg-${bgColor}`,
        {
          'border-red-500': error,
          'text-red-500': error,
          'placeholder-red-500': error,
          'focus:border-red-500': error,
          'focus:shadow-outline-red': error,
          'focus:shadow-outline': !error,
          'pl-10': !placeholder,
          'pl-4': placeholder,
        },
      )}
      defaultValue={defaultValue}
    />
  );
};

export function FormCustomInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  bgColor = 'gray-100',
  type = 'text',
  name,
  className,
  id,
  register,
  placeholder,
  error,
  onChange,
  ...rest
}: InputProps<TFieldValues, TFieldName>) {
  const _rest =
    register === undefined && typeof onChange === 'function'
      ? {...rest, onChange}
      : rest; //this is for controlled component
  return (
    <input
      {...register?.(name, {onChange})}
      {..._rest}
      placeholder={placeholder}
      id={id}
      type={type}
      className={classnames(
        `${className} appearance-none border w-full py-2 pr-3 leading-tight focus:outline-none bg-${bgColor}`,
        {
          'border-red-500': error,
          'text-red-500': error,
          'placeholder-red-500': error,
          'focus:border-red-500': error,
          'focus:shadow-outline-red': error,
          'focus:shadow-outline': !error,
        },
      )}
    />
  );
}

export const FormTextArea: FC<TextAreaProps> = ({
  bgColor = 'white',
  rows = 5,
  name,
  className,
  id,
  register,
  placeholder,
  error,
  defaultValue,
  ...rest
}) => {
  return (
    <textarea
      {...rest}
      placeholder={placeholder}
      id={id}
      rows={rows}
      {...register?.(name, {onChange: rest.onChange})}
      className={classnames(
        `${className} appearance-none form-textarea border w-full p-2 leading-tight focus:outline-none bg-${bgColor}`,
        {
          'border-red-500': error,
          'text-red-500': error,
          'placeholder-red-500': error,
          'focus:border-red-500': error,
          'focus:shadow-outline-red': error,
          'focus:shadow-outline': !error,
        },
      )}
      defaultValue={defaultValue}
    />
  );
};

export const FormLabel: FC<LabelProps> = ({
  htmlFor,
  className = 'text-gray-600',
  required,
  fontSize = 'text-xs',
  children,
  error,
  id,
}) => {
  return (
    <section className="relative">
      <div
        className={classnames(
          'absolute inset-y-0 right-0 flex items-center cursor-pointer',
          {
            hidden: !error,
          },
        )}
      >
        <WarningIcon />
      </div>
      <label
        className={classnames(
          `${className} block text-left ${fontSize} leading-snug font-light mb-2`,
        )}
        id={id}
        htmlFor={htmlFor}
      >
        {children} {required ? <span className="text-red-500">*</span> : null}
      </label>
    </section>
  );
};

export const ModernFormLabel: FC<LabelProps> = ({
  htmlFor,
  className = 'text-gray-600',
  children,
  error,
  id,
}) => {
  return (
    <section className="relative">
      <div
        className={classnames(
          'absolute inset-y-0 right-0 flex items-center cursor-pointer',
          {
            hidden: !error,
          },
        )}
      ></div>
      <label
        className={classnames(
          `${className} block text-left text-xs leading-snug font-light mb-2`,
        )}
        id={id}
        htmlFor={htmlFor}
      >
        {children}
      </label>
    </section>
  );
};

export function FormPasswordInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  bgColor,
  className,
  id,
  name,
  placeholder,
  register,
  error,
  ...rest
}: InputProps<TFieldValues, TFieldName>) {
  const {toggleOn, onToggleClick} = useToggle();
  const renderPasswordVisibleIcon = () =>
    toggleOn ? (
      <PasswordHideIcon strokeColor="text-gray-600" />
    ) : (
      <PasswordShowIcon strokeColor="text-gray-600" />
    );
  return (
    <>
      <div
        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        onClick={onToggleClick}
      >
        {renderPasswordVisibleIcon()}
      </div>
      <FormInput
        bgColor={bgColor}
        name={name}
        placeholder={placeholder}
        className={className}
        register={register}
        error={error}
        {...rest}
        type={toggleOn ? 'text' : 'password'}
        id={id}
      />
    </>
  );
}

export const FormError: FC<{
  id?: string;
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  errorClasses?: string;
}> = ({error, id = 'form-error', errorClasses}) => {
  return (
    <p
      data-cy={id}
      data-testid={id}
      role="alert"
      className={classnames(
        errorClasses,
        'mt-2 text-sm text-center text-red-500',
        {
          hidden: !error,
        },
      )}
    >
      {error as ReactNode}
    </p>
  );
};

export const AuthFormError: FC<{
  id?: string;
  error?: string;
  showSignUpLink?: boolean;
}> = ({error, id = 'form-error', showSignUpLink = true}) => {
  if (!error) return null;
  return (
    <div className="w-full flex items-center justify-start bg-lightRed py-2 px-5 space-x-3">
      <div className="w-1/12">
        <WarningTriangle strokeColor="text-red-400" />
      </div>
      <div className="w-11/12">
        <p
          data-testid={id}
          role="alert"
          className={classnames(
            'text-sm text-left font-inter font-medium leading-6',
            {
              hidden: !error,
            },
          )}
        >
          {error}&nbsp;
          {showSignUpLink ? (
            <Link to="/get-started" className="text-primary hover:underline">
              Sign Up
            </Link>
          ) : null}
        </p>
      </div>
    </div>
  );
};

export const RichTextArea: FC<RichTextAreaProps & {minHeight: string}> = ({
  bgColor = 'white',
  name,
  className,
  control,
  placeholder,
  error,
  minHeight = '3.125rem',
  onAutoSave,
  showToolbar = true,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field: {onChange, onBlur, value}}) => (
        <RichTextAreaContainer>
          <ReactQuill
            theme="snow"
            placeholder={placeholder}
            onChange={e => {
              onChange(e);
              if (typeof onAutoSave === 'function') onAutoSave();
            }}
            onBlur={onBlur}
            value={value}
            modules={showToolbar ? modules(name) : {toolbar: false}}
            formats={formats}
            style={{
              height: minHeight,
              backgroundColor: 'white',
            }}
            className={classnames(
              `${className} appearance-none border-6 text-blue-600 w-full p-0 leading-tight focus:outline-none bg-${bgColor}`,
              {
                'border-red-500': error,
                'text-red-500': error,
                'placeholder-red-500': error,
                'focus:border-red-500': error,
                'focus:shadow-outline-red': error,
                'focus:shadow-outline': !error,
              },
            )}
          />

          <EditToolbar id={name} />
        </RichTextAreaContainer>
      )}
    />
  );
};
