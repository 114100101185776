import React, {FC, useEffect, useRef, useState} from 'react';
import Reactplayer from 'react-player';
import {OnProgressProps} from 'react-player/base';

import {VideoControls} from './VideoControls';

interface VideoProps {
  url: string;
  title?: string;
  poster?: string;
}

export const VideoPlayer: FC<VideoProps> = ({url, title, poster}) => {
  // Reference of Video Player
  const videoRef = useRef<Reactplayer>(null);
  // Reference of Video Player Wrapper
  const videoWrapperRef = useRef<HTMLElement>(null);
  // Reference of setTimeout
  const timerRef = useRef<NodeJS.Timeout>();

  // State if controls are visible or not
  const [controlsVisibility, setControlsVisibility] = useState(false);

  // State for all controls of video player
  const [videoControls, setVideoControls] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    speed: 1,
    played: 0,
    seeking: false,
    isFullScreen: false,
  });

  // Function to handle onHover on video player
  const handleOnHover = () => {
    if (!controlsVisibility) {
      setControlsVisibility(true);
      timerRef.current = setTimeout(() => setControlsVisibility(false), 3000);
    }
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  // Function to handle OnProgress of Video
  const handleOnProgress = (state: OnProgressProps) => {
    if (!videoControls.seeking) {
      setVideoControls({...videoControls, played: state.played});
    }
  };

  // Function to handle onClick on video player wrapper
  const handleOnClick = () => {
    if (!controlsVisibility)
      setVideoControls(current => ({
        ...current,
        playing: !current.playing,
      }));
  };

  const onEnded = () => {
    setVideoControls({...videoControls, playing: false});
  };

  return (
    <section
      className="bg-black w-full h-auto m-auto relative p-0"
      onMouseMove={handleOnHover}
      ref={videoWrapperRef}
      onClick={handleOnClick}
    >
      <Reactplayer
        ref={videoRef}
        url={url}
        width="100%"
        height="100%"
        light={poster}
        playing={videoControls.playing}
        muted={videoControls.muted}
        volume={videoControls.volume}
        playbackRate={videoControls.speed}
        onProgress={handleOnProgress}
        onEnded={onEnded}
        config={{
          youtube: {
            playerVars: {showinfo: 1, controls: 0, modestbranding: 1, rel: 1},
          },
        }}
      />
      {controlsVisibility ? (
        <VideoControls
          controls={videoControls}
          setControls={setVideoControls}
          title={title}
          videoRef={videoRef}
          videoWrapperRef={videoWrapperRef}
        />
      ) : null}
    </section>
  );
};
