import React, {FC} from 'react';
import Logo from 'assets/images/blue-white-logo.png';
import LogoWebp from 'assets/images/blue-white-logo.webp';
import {LazyImage} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';
import styled from 'styled-components';

const logoLqip = lqip('../../assets/images/blue-white-logo.png');

const Image = styled(props => <LazyImage {...props} />)`
  img {
    object-fit: contain;
    object-position: center;
    padding: 6px;
  }
`;
type Props = {
  classes?: string;
  imgClasses?: string;
};

export const LogoHeader: FC<Props> = ({
  classes = 'w-fit h-fit pt-5 xl:pl-2',
  imgClasses = 'w-12 h-[46px] bg-blue-600 rounded-2xl',
}) => (
  <div className={classes}>
    <Image
      src={Logo}
      srcSet={Logo}
      webp={LogoWebp}
      type="image/png"
      className={imgClasses}
      aspectRatio={6.5}
      alt="Wellnite Logo links to home"
      lqip={logoLqip}
    />
  </div>
);
