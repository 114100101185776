import React, {FC, useEffect, useRef} from 'react';
import {ForwardsRefFormTextArea} from 'components/Basic/Form/TextArea/ForwardRefTextArea';
import {useFormContext} from 'react-hook-form';

const AutoHeightTextArea: FC<{
  id: string;
  name: string;
  label?: React.ReactNode;
  labelClasses?: string;
  containerClasses?: string;
  classes?: string;
  bgColor?: string;
  rows?: number;
  placeholder?: string;
}> = ({
  id,
  name,
  label,
  labelClasses,
  containerClasses,
  classes,
  bgColor,
  rows,
  placeholder,
}) => {
  const {watch, control} = useFormContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const value = watch();

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight(textareaRef.current);
    }
  }, [textareaRef, value]);

  const adjustHeight = (textareaElem: HTMLTextAreaElement) => {
    textareaElem.style.height = 'auto';
    textareaElem.style.height = textareaElem.scrollHeight + 'px';
  };

  return (
    <ForwardsRefFormTextArea
      ref={textareaRef}
      id={id}
      name={name}
      control={control}
      placeholder={placeholder}
      label={label}
      labelClasses={labelClasses}
      containerClasses={containerClasses}
      classes={classes}
      bgColor={bgColor}
      rows={rows}
    />
  );
};

export default AutoHeightTextArea;
