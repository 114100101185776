import React, {FC, useCallback, useEffect} from 'react';
import {LoadingSpinner} from 'components';
import dayjs from 'dayjs';
import {providerActions} from 'features/Provider/index';
import {
  selectTherapyQuestionnaire,
  selectTherapyQuestionnaireStatus,
} from 'features/Provider/Members/membersSelectors';
import {TFunction} from 'i18next';
import {MemberProfile, SliceStatus} from 'interfaces';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {Ul} from '../styles';

type Props = {
  member: MemberProfile;
};

const translateOptions = (input: string, t: TFunction) => {
  const regex = /([^,()]+)(\s*\([^)]*\))?/g;
  let match;
  const translatedParts = [];

  while ((match = regex.exec(input)) !== null) {
    const option = match[1].trim();
    const bracketContent = match[2] || '';

    const translatedOption = `${t(option)}${bracketContent}`;
    translatedParts.push(translatedOption);
  }

  return translatedParts.join(', ');
};

const convertStringToTableData = (inputString: string) => {
  const entries = inputString?.split('medication: ')?.slice(1);

  return entries.map(entry => {
    const sections = entry?.split('; ');
    const fields = sections?.map(field => field?.split(': ')[1]);
    return {
      medication: sections[0],
      dosage: fields[1],
      frequency: fields[2],
      sinceWhen: fields[3],
      effective: fields[4],
    };
  });
};

const TherapyQuestionnaire: FC<Props> = ({member}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const getIntakeQTherapyQuestionnaire = useCallback(() => {
    dispatch(
      providerActions.getTherapyQuestionnaire({
        patientId: member.patientId,
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    getIntakeQTherapyQuestionnaire();
  }, [getIntakeQTherapyQuestionnaire]);

  const questions = useSelector(selectTherapyQuestionnaire) as {
    Id: string;
    Text: string;
    Answer: string;
  }[];
  const TherapyQuestionnaireLoadingStatus = useSelector(
    selectTherapyQuestionnaireStatus,
  );
  const isLoading = TherapyQuestionnaireLoadingStatus === SliceStatus.pending;
  const requestFailed =
    TherapyQuestionnaireLoadingStatus === SliceStatus.rejected;

  if (isLoading)
    return (
      <section className="py-5 px-2 flex justify-center flex-col items-center">
        <LoadingSpinner width={30} height={30} type="Oval" />
        <p className="pt-6 text-center">
          <Trans i18nKey="therapyQuestionnaire.loadingData" t={t}>
            Loading questionnaire data, please wait...
          </Trans>
        </p>
      </section>
    );
  else if (requestFailed)
    return (
      <section className="py-5 px-2 flex justify-center flex-col items-center">
        <p className="pt-6 text-center">
          <Trans i18nKey="therapyQuestionnaire.somethingWentWrong" t={t}>
            Something went wrong!
          </Trans>
        </p>
      </section>
    );
  else if (questions)
    return (
      <section className="py-7 mb-20">
        <Ul>
          {questions.map(question => {
            if (question.Text === 'Medications') {
              if (!question.Answer) {
                return (
                  <li>
                    <h4>{t('therapyQuestionnaire.medications')}</h4>
                    <p className="bg-lighterGray p-3 rounded-lg border border-lightGray">
                      {t('therapyQuestionnaire.notApplicable', 'N/A')}
                    </p>
                  </li>
                );
              }
              const tableData = convertStringToTableData(question?.Answer);
              return (
                <li key={question.Id}>
                  <h4>{t('therapyQuestionnaire.medications')}</h4>
                  <table className="table-fixed w-full">
                    <thead>
                      <tr>
                        <th>
                          <h4>{t('therapyQuestionnaire.medication')}</h4>
                        </th>
                        <th>
                          <h4>{t('therapyQuestionnaire.dosage')}</h4>
                        </th>
                        <th>
                          <h4>{t('therapyQuestionnaire.frequency')}</h4>
                        </th>
                        <th>
                          <h4>{t('therapyQuestionnaire.sinceWhen')}</h4>
                        </th>
                        <th>
                          <h4>{t('therapyQuestionnaire.effective')}</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>{row.medication}</td>
                          <td>{row.dosage}</td>
                          <td>{row.frequency}</td>
                          <td>
                            {dayjs(new Date(row.sinceWhen)).format(
                              'MMMM D, YYYY',
                            )}
                          </td>
                          <td>{row.effective}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </li>
              );
            }
            return (
              <li key={question.Id}>
                <h4>
                  {t(
                    `therapyQuestionnaire.${question?.Text?.replace(/:/g, '')}`,
                    question?.Text,
                  )}
                </h4>
                <p className="bg-lighterGray p-3 rounded-lg border border-lightGray">
                  {question?.Answer
                    ? translateOptions(question.Answer, t)
                    : t('therapyQuestionnaire.notApplicable', 'N/A')}
                </p>
              </li>
            );
          })}
        </Ul>
      </section>
    );
  else if (!questions)
    return (
      <section className="px-2">
        <p className="py-4 text-center bg-[#F5F6FB] rounded-lg w-full items-center">
          <Trans i18nKey="therapyQuestionnaire.unavailable" t={t}>
            Questionnaire unavailable
          </Trans>
        </p>
      </section>
    );
  else return null;
};

export default TherapyQuestionnaire;
