import React, {FC, memo} from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export interface LinkProps {
  pathname: string;
  linkName: string;
  current: boolean;
  classNames?: string;
  children?: React.ReactNode;
}

const LinkContainer = memo(styled.div`
  .group .stroke-current {
    color: rgb(55 65 81);
  }
  .group:hover .stroke-current {
    color: white;
  }
`);

const getLinkContainerClassName = (current: boolean, classNames: string) => {
  return classnames(
    `group flex items-center lg:justify-center xl:justify-start rounded-full gap-x-2 xl:px-4 lg:w-[50px] xl:w-full h-[50px] mb-1 mx-auto text-lg leading-snug ${classNames}`,
    {
      'text-white bg-blue-600': current,
      'text-gray-600 hover:text-blue-600': !current,
    },
  );
};

export const NavLinkV2: FC<LinkProps> = ({
  pathname,
  current,
  linkName,
  children,
  classNames = '',
}) => {
  return (
    <Link to={pathname} className="flex justify-center xl:justify-start">
      <LinkContainer className={getLinkContainerClassName(current, classNames)}>
        <div className="w-7 h-auto lg:flex justify-center items-center xl:block">
          {children}
        </div>
        <span
          className={`lg:hidden xl:block text-base font-normal ${
            linkName.toLowerCase() === 'share' ? 'text-white' : ''
          }`}
        >
          {linkName}
        </span>
      </LinkContainer>
    </Link>
  );
};
