import React, {FC, useMemo} from 'react';
import classNames from 'classnames';
import {selectUserProfile} from 'features/User';
import {useToggle} from 'hooks';
import {NotesType} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {titleCaseName} from 'utils';

import {CopyText} from '../CopyText';
import {
  CaretDownIcon,
  CaretRightIcon,
  CopyIcon,
  EditIcon,
  TAndCIcon,
} from '../SvgIcon';
import {Tooltip} from '../Tooltip';

interface Props {
  note: NotesType;
  onNoteSelect?: (note: NotesType) => void;
}

const DisclosureAccordion: FC<Props> = ({note, onNoteSelect}) => {
  const {toggleOn, onToggleClick} = useToggle();
  const {t, i18n} = useTranslation();
  const locale = i18n.language;

  const editNote = () => {
    if (onNoteSelect) onNoteSelect(note);
  };

  const user = useSelector(selectUserProfile);

  const isProvidersNote = useMemo(() => {
    return note?.noteCreator?.trim() === user?._id?.trim();
  }, [user?._id, note.noteCreator]);

  return (
    <section
      className={classNames('pt-2 px-2 ', {
        'hover:bg-gray-50': !toggleOn,
      })}
      key={note.noteId}
    >
      <div
        onClick={onToggleClick}
        className={classNames(
          'flex justify-between w-full px-4 py-3 focus:outline-none border-b cursor-pointer relative',
          {
            'border-transparent': toggleOn,
          },
        )}
      >
        <div className="flex">
          <TAndCIcon fillColor="#315eff" strokeColor="text-white" />
          <div className="ml-2 space-y-1">
            <p className="w-56 font-medium truncate">
              {note?.chiefComplaint?.replace(/<[^>]+>/g, '') || 'N/A'}
            </p>

            <p className="font-medium text-xs">
              {t('noteCreatedBy', {
                noteCreator: titleCaseName(note?.noteCreatorFullName),
              })}
            </p>
            <p className="text-xs text-blue-600">
              {new Date(String(note.createdAt || note.date)).toLocaleDateString(
                locale,
                {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                },
              )}
            </p>

            {note.roomMode === 'group' ? (
              <p className="w-fit bg-gray-600 text-white text-xs px-2 py-0.5 rounded-full">
                Group
              </p>
            ) : null}
          </div>
        </div>

        <div>
          {toggleOn ? (
            <CaretDownIcon strokeWidth={0} fillColor="#315eff" />
          ) : (
            <CaretRightIcon strokeWidth={0} fillColor="#6b6b6b" />
          )}
          {isProvidersNote ? (
            <Tooltip content="Edit Note" placement="bottom">
              <button
                className="absolute right-3 bottom-2 hover:bg-gray-200 rounded-full w-7 h-7 flex justify-center items-center focus:outline-none"
                onClick={editNote}
              >
                <EditIcon fillColor="#6b6b6b" strokeColor="text-white" />
              </button>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div
        className={classNames('px-4 pb-4 border rounded-lg mx-2', {
          hidden: !toggleOn,
        })}
      >
        <div className="flex justify-between items-center">
          <p className="mb-4 pt-5 font-semibold text-gray-700">
            Chief Complaint
          </p>
          <CopyText
            hoverText="Copy Chief complaint!"
            successText="Chief complaint copied to clipboard!"
            content={note.chiefComplaint}
          >
            <CopyIcon />
          </CopyText>
        </div>

        <p
          className="border rounded p-3 text-gray-600"
          dangerouslySetInnerHTML={{
            __html: note.chiefComplaint || 'N/A',
          }}
        />

        <div className="flex justify-between items-center">
          <p className="mb-4 font-semibold pt-5 text-gray-700">Patient notes</p>
          <CopyText
            hoverText="Copy Patient notes!"
            successText="Patient notes copied to clipboard!"
            content={note.patientNotes}
          >
            <CopyIcon />
          </CopyText>
        </div>
        <p
          className="border rounded p-3 text-gray-600"
          dangerouslySetInnerHTML={{
            __html: note.patientNotes || 'N/A',
          }}
        />

        <div className="flex justify-between items-center">
          <p className="mb-4 font-semibold pt-5 text-gray-700">Plan</p>
          <CopyText
            hoverText="Copy Plan!"
            successText="Plan copied to clipboard!"
            content={note.plan}
          >
            <CopyIcon />
          </CopyText>
        </div>
        <p
          className="border rounded p-3 text-gray-600"
          dangerouslySetInnerHTML={{
            __html: note.plan || 'N/A',
          }}
        />

        <div>
          <p className="mt-4 font-semibold pb-2 text-gray-700 text-sm">
            ICD Codes
          </p>
        </div>

        {note?.icdCodes &&
        Array.isArray(note.icdCodes) &&
        note.icdCodes.length ? (
          <div className="border rounded p-3">
            {note?.icdCodes?.map((x, idx) => (
              <button
                key={+idx}
                className="px-2 py-1 text-left rounded-md bg-blue-600 mb-1 text-white text-xs mr-1"
              >
                {x.label}
              </button>
            ))}
          </div>
        ) : (
          <p className="text-gray-700 border rounded p-3">N/A</p>
        )}

        <p className="mb-4 font-semibold pt-5 text-gray-700">
          Prescribed Medication
        </p>
        <article className="border rounded p-3">
          {note.medications.length ? (
            note.medications.map((medication, idx) =>
              medication.medicationName.length ? (
                <article key={+idx}>
                  <p className="my-4 text-sm underline font-semibold">
                    Medication {idx + 1}
                  </p>

                  <p className="mt-4 font-semibold text-gray-700 text-sm">
                    Medication Name
                  </p>
                  <p className="text-gray-600">
                    {medication?.medicationName
                      ?.map(x => x.medicationName)
                      .join() || 'N/A'}
                  </p>

                  <div className="flex justify-between items-center">
                    <p className="mt-4 font-semibold text-gray-700 text-sm">
                      Medication instruction
                    </p>

                    <CopyText
                      hoverText="Copy Medication instruction!"
                      successText="Medication instruction copied to clipboard!"
                      content={medication.medicationInstruction}
                    >
                      <CopyIcon />
                    </CopyText>
                  </div>
                  <p className="text-gray-600">
                    {medication.medicationInstruction || 'N/A'}
                  </p>

                  <div className="flex flex-wrap mt-4 items-center text-sm">
                    <div className="border border-blue-600 flex rounded-full overflow-hidden mt-2 mr-3">
                      <div className="px-2 bg-blue-600 text-white">Supply</div>
                      <div className="px-4">{medication.daySupply}</div>
                    </div>

                    <div className="border border-blue-600 flex rounded-full overflow-hidden mt-2 mr-3">
                      <div className="px-2 bg-blue-600 text-white">
                        Quantity
                      </div>
                      <div className="px-4">{medication.quantity}</div>
                    </div>
                    <div className="border border-blue-600 flex rounded-full overflow-hidden mt-2 mr-3">
                      <div className="px-2 bg-blue-600 text-white">Refill</div>
                      <div className="px-4">{medication.refillNumber}</div>
                    </div>
                  </div>
                </article>
              ) : (
                <p key={+idx} className="text-gray-600">
                  No Prescription
                </p>
              ),
            )
          ) : (
            <p className="text-gray-600">No Prescription</p>
          )}
        </article>

        <div className="flex justify-end pt-3 text-gray-600 text-sm">
          <p>{new Date(String(note.createdAt || note.date)).toDateString()}</p>
        </div>
      </div>
    </section>
  );
};

export default DisclosureAccordion;
