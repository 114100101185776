import React, {FC, ReactNode} from 'react';
import {AnimatePresence} from 'framer-motion';
import {Route, RouteProps, Switch, useLocation} from 'react-router-dom';

export const RouteTransition: FC<RouteProps> = ({render, ...rest}) => {
  return <Route {...rest} render={render} />;
};

export const AnimatedRoutes: FC<{children: ReactNode}> = ({children}) => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Switch key={location.pathname} location={location}>
        {children}
      </Switch>
    </AnimatePresence>
  );
};
