import {
  FormSubmissionStatus,
  OnboardingVideoStatus,
  VerificationStatusEnum,
} from 'definitions/Onboarding/provider';
import {
  AppointmentLabel,
  BillingHistoryTypes,
  InternationaProviderOnboardingStatusTypes,
  MemberProfile,
  NormalizedMedications,
  NotesType,
  ProfessionalHeadshotFormData,
  SliceStatus,
  UpdateMedicationsFormData,
  UserRoles,
  WidgetsPatientsDetails,
  WidgetsProviderDetails,
} from 'interfaces';
import {isProvider} from 'utils';

import {
  ActionReducerMapBuilder,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

import {
  sendAvailabilityUpdateNotification,
  sendAvailabilityUpdateNotificationFailure,
  sendAvailabilityUpdateNotificationSuccess,
  sendCalendarSettingsNotification,
  sendCalendarSettingsNotificationFailure,
  sendCalendarSettingsNotificationSuccess,
} from './Calendar/calendarActions';
import {
  getWidgetPatientsDetails,
  getWidgetPatientsDetailsFailure,
  getWidgetPatientsDetailsSuccess,
  getWidgetProviderDetails,
  getWidgetProviderDetailsFailure,
  getWidgetProviderDetailsSuccess,
} from './Dashboard/Widgets/widgetsActions';
import {
  getMember,
  getMemberById,
  getMemberFailure,
  getMemberSuccess,
} from './Member/memberActions';
import {
  getMembers,
  getMembersFailure,
  getMembersSuccess,
  resetMembers,
} from './Members/membersActions';
import {
  createCMS1500Form,
  createCMS1500FormFailure,
  createCMS1500FormSuccess,
  createNotes,
  createNotesFailure,
  createNotesSuccess,
  generateNote,
  generateNotesFailure,
  generateNotesSuccess,
  getGeneratedNotePreviousInputs,
  getNotes,
  getNotesFailure,
  getNotesSuccess,
  updateNote,
  updateNoteFailure,
  updateNoteOnCMSFailed,
  updateNoteSuccess,
} from './Notes/notesActions';
import {
  getAllProviderBills,
  getAllProviderBillsFailure,
  getAllProviderBillsSuccess,
  getBillingHistory,
  getBillingHistoryFailure,
  getBillingHistorySuccess,
  setPlatformFeePercentage,
  setPlatformFeePercentageFailure,
  setPlatformFeePercentageSuccess,
} from './Payments/paymentActions';
import {
  updateProviderPreferredLanguage,
  updateProviderPreferredLanguageFailure,
  updateProviderPreferredLanguageSuccess,
  updateProviderProfile,
  updateProviderProfileFailure,
  updateProviderProfileInfo,
  updateProviderProfileInfoFailure,
  updateProviderProfileInfoSuccess,
  updateProviderProfilePicture,
  updateProviderProfilePictureFailure,
  updateProviderProfilePictureSuccess,
  updateProviderProfileSuccess,
} from './Profile/profileActions';
import {
  changeOnboardingVideoStatus,
  changeOnboardingVideoStatusFailure,
  changeOnboardingVideoStatusSuccess,
  changeProviderFromFirstTime,
  changeProviderFromFirstTimeFailure,
  changeProviderFromFirstTimeSuccess,
  completeOnboarding,
  completeOnboardingFailure,
  completeOnboardingSuccess,
  getProviderData,
  getProviderDataFailure,
  getProviderDataSuccess,
  sendInternationalProviderAgreedToTerms,
  sendInternationalProviderAgreedToTermsFailure,
  sendInternationalProviderAgreedToTermsSuccess,
  signup,
  signupFailure,
  signupSuccess,
  skipCAQHForm,
  skipCAQHFormFailure,
  skipCAQHFormSuccess,
  unsubscribeOnboardingReminder,
  unsubscribeOnboardingReminderFailure,
  unsubscribeOnboardingReminderSuccess,
  updateInternationalProviderCrendentialsData,
  updateInternationalProviderCrendentialsDataFailure,
  updateInternationalProviderCrendentialsDataSuccess,
  updateInternationalProviderProfileData,
  updateInternationalProviderProfileDataFailure,
  updateInternationalProviderProfileDataSuccess,
  updateInternationalProviderProfileTwoData,
  updateInternationalProviderProfileTwoDataFailure,
  updateInternationalProviderProfileTwoDataSuccess,
  updateProviderData,
  updateProviderDataFailure,
  updateProviderDataSuccess,
  updateProviderNpi,
  updateProviderNpiFailure,
  updateProviderNpiSuccess,
  uploadInternationalProviderIdDocument,
  uploadInternationalProviderIdDocumentFailure,
  uploadInternationalProviderIdDocumentSuccess,
  uploadLicenseCopy,
  uploadLicenseCopyFailure,
  uploadLicenseCopySuccess,
  verifyHuman,
  verifyHumanFailure,
  verifyHumanSuccess,
} from './Register/registerActions';

export const getMedications = createAction('getMedications');
export const getMedicationsSuccess = createAction<NormalizedMedications>(
  'getMedicationsSuccess',
);
export const getMedicationsFailure = createAction('getMedicationsFailure');
export const updateMedications = createAction<
  UpdateMedicationsFormData & {patientId: string; role: UserRoles}
>('updateMedications');
export const updateMedicationsSuccess = createAction(
  'updateMedicationsSuccess',
);
export const updateMedicationsFailure = createAction<string>(
  'updateMedicationsFailure',
);

const searchMembers = createAction<{
  role: UserRoles;
  searchTerm: string;
  providerId?: string;
  orderBy: string;
}>('searchMembers');
const searchMembersSuccess = createAction<{
  normalizedMembers: {[key: string]: MemberProfile};
}>('searchMembersSuccess');
const searchMembersFailure = createAction('searchMembersFailure');
const resetMembersToSnapshot = createAction('resetMembersToSnapshot');

const setOnboardingStep = createAction<number>('setOnboardingStep');

const resetNotes = createAction('ResetNotes');
const resetNoteStatus = createAction<SliceStatus>('resetNoteStatus');

export const getTherapyQuestionnaire = createAction<{
  patientId: string;
}>('GetTherapyQuestionnaire');
export const getTherapyQuestionnaireSuccess = createAction<any>(
  'GetTherapyQuestionnaireSuccess',
);
export const getTherapyQuestionnaireFailure = createAction(
  'GetTherapyQuestionnaireFailure',
);

export const updateProfessionalHeadshot =
  createAction<ProfessionalHeadshotFormData>('UpdateProfessionalHeadshot');
export const updateProfessionalHeadshotSuccess = createAction(
  'UpdateProfessionalHeadshotSuccess',
);
export const updateProfessionalHeadshotFailure = createAction<string>(
  'UpdateProfessionalHeadshotFailure',
);
export const updateProviderResume = createAction<ProfessionalHeadshotFormData>(
  'UpdateProviderResume',
);
export const updateProviderResumeSuccess = createAction(
  'UpdateProviderResumeSuccess',
);
export const updateProviderResumeFailure = createAction<string>(
  'UpdateProviderResumeFailure',
);
type ProviderSliceState = {
  members: {
    members: {[key: string]: MemberProfile};
    lastFetchedMembers: {[key: string]: MemberProfile};
    hasMore: boolean;
  };
  snapshot: {
    members: {[key: string]: MemberProfile};
    hasMore: boolean;
  };
  notes: {
    data: {[key: string]: NotesType};
    hasMore: boolean;
    totalNotes: number;
    nextPage: number;
    previousPage: number;
    isNotesLoading: SliceStatus;
  };

  billing: {
    appointmentData: AppointmentLabel[];
    providerEmail: string;
    providerModelRemark: string[];
    status: SliceStatus;
  };
  billingHistory: {
    normal: {
      data: BillingHistoryTypes[];
      hasMore: boolean;
      totalCount: number;
      nextPage: number;
      previousPage: number;
      isLoading: SliceStatus;
    };
    group: {
      data: BillingHistoryTypes[];
      hasMore: boolean;
      totalCount: number;
      nextPage: number;
      previousPage: number;
      isLoading: SliceStatus;
    };
  };
  onboarding: {
    stage?: number;
    verificationFormSubmissionDate?: number;
    verificationStatus?: VerificationStatusEnum;
    firstTime?: boolean;
    currentStep?: number;
    formStatus?: {
      verification?: FormSubmissionStatus;
      profile?: FormSubmissionStatus;
      caqh?: FormSubmissionStatus;
      onboarding?: FormSubmissionStatus;
    };
    onboardingVideo?: OnboardingVideoStatus;
  };
  searchStatus: SliceStatus;
  medicationStatus: SliceStatus;
  noteUpdateStatus: SliceStatus;
  onboardingStatus: SliceStatus;
  onboardingStatusIsInitialized: boolean;
  internationalOnboardingStatus: InternationaProviderOnboardingStatusTypes | null;
  verifiedByWellniteTeam: boolean;
  status: SliceStatus;
  languageStatus: SliceStatus;
  expertSummaryStatus: SliceStatus;
  providerPicStatus: SliceStatus;
  providerResumeUploadStatus: SliceStatus;
  error: string;
  uploading: boolean;
  medications: NormalizedMedications;
  therapyQuestionnaire: any[];
  therapyQuestionnaireStatus: SliceStatus;
  paypalMerchantId: string;
  feePercentage?: number;
  hourlyRate?: number;
  setFeePercentageStatus?: SliceStatus;
  widgetsPatientsDetails?: WidgetsPatientsDetails | null;
  widgetsPatientsDetailsStatus?: SliceStatus;
  widgetsProviderDetails?: WidgetsProviderDetails | null;
  widgetsProviderDetailsStatus?: SliceStatus;
  cms1500: {
    status: SliceStatus;
  };
};

const providerSlice = createSlice({
  name: 'provider',
  initialState: {
    internationalOnboardingStatus: null,
    verifiedByWellniteTeam: false,
    paypalMerchantId: '',
    members: {
      members: {} as {[key: string]: MemberProfile},
      lastFetchedMembers: {} as {[key: string]: MemberProfile},
      hasMore: true,
    },
    snapshot: {
      members: {} as {[key: string]: MemberProfile},
      hasMore: false,
    },
    notes: {
      data: {} as {[key: string]: NotesType},
      hasMore: false,
      totalNotes: 0,
      nextPage: 0,
      previousPage: 0,
      isNotesLoading: SliceStatus.idle,
    },
    billing: {
      appointmentData: [] as AppointmentLabel[],
      providerEmail: '',
      providerModelRemark: [],
      status: SliceStatus.idle,
    },
    onboarding: {
      stage: 1,
      verificationFormSubmissionDate: 1694050846844,
      formStatus: {
        verification: FormSubmissionStatus.notSubmitted,
        profile: FormSubmissionStatus.notSubmitted,
        caqh: FormSubmissionStatus.notSubmitted,
        onboarding: FormSubmissionStatus.notSubmitted,
      },
      currentStep: 1,
      verificationStatus: VerificationStatusEnum.profileFormPending,
      firstTime: true,
      onboardingVideo: OnboardingVideoStatus.notStarted,
    },
    billingHistory: {
      normal: {
        data: [] as BillingHistoryTypes[],
        hasMore: false,
        totalCount: 0,
        nextPage: 0,
        previousPage: 0,
        isLoading: SliceStatus.idle,
      },
      group: {
        data: [] as BillingHistoryTypes[],
        hasMore: false,
        totalCount: 0,
        nextPage: 0,
        previousPage: 0,
        isLoading: SliceStatus.idle,
      },
    },
    searchStatus: SliceStatus.idle,
    medicationStatus: SliceStatus.idle,
    noteUpdateStatus: SliceStatus.idle,
    status: SliceStatus.idle,
    languageStatus: SliceStatus.idle,
    expertSummaryStatus: SliceStatus.idle,
    providerPicStatus: SliceStatus.idle,
    providerResumeUploadStatus: SliceStatus.idle,
    onboardingStatus: SliceStatus.idle,
    onboardingStatusIsInitialized: false,
    error: '',
    uploading: false,
    medications: {} as NormalizedMedications,
    therapyQuestionnaire: [],
    therapyQuestionnaireStatus: SliceStatus.idle,
    setFeePercentageStatus: SliceStatus.idle,
    feePercentage: 20,
    hourlyRate: 20,
    widgetsPatientsDetails: null,
    widgetsPatientsDetailsStatus: SliceStatus.idle,
    widgetsProviderDetails: null,
    widgetsProviderDetailsStatus: SliceStatus.idle,
    cms1500: {
      status: SliceStatus.idle,
    },
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ProviderSliceState>) =>
    builder
      .addCase(getNotes, state => ({
        ...state,
        notes: {
          ...state.notes,
          isNotesLoading: SliceStatus.pending,
        },
      }))
      .addCase(getNotesFailure, state => ({
        ...state,
        notes: {
          ...state.notes,
          isNotesLoading: SliceStatus.rejected,
        },
      }))
      .addCase(getNotesSuccess, (state, action) => ({
        ...state,
        notes: {
          ...state.notes,
          ...action.payload,
          isNotesLoading: SliceStatus.resolved,
        },
      }))
      .addCase(createNotes, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(createNotesFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(createNotesSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        notes: {
          ...state.notes,
          data: action.payload,
        },
      }))
      .addCase(updateNote, (state, action) => ({
        ...state,
        noteUpdateStatus: action.payload.isToast
          ? SliceStatus.idle
          : SliceStatus.pending,
        status: action.payload.isToast ? SliceStatus.pending : SliceStatus.idle,
      }))
      .addCase(updateNoteSuccess, (state, action) => ({
        ...state,
        noteUpdateStatus:
          state.status === SliceStatus.pending
            ? SliceStatus.idle
            : SliceStatus.resolved,
        status: SliceStatus.resolved,
        notes: {
          ...state.notes,
          data: action.payload,
        },
      }))
      .addCase(resetNotes, state => ({
        ...state,
        notes: {
          ...state.notes,
          data: {} as {[key: string]: NotesType},
          hasMore: false,
          totalNotes: 0,
          nextPage: 0,
          previousPage: 0,
          isNotesLoading: SliceStatus.idle,
        },
      }))
      .addCase(resetNoteStatus, (state, action) => ({
        ...state,
        noteUpdateStatus: action.payload,
        status: action.payload,
      }))
      .addCase(generateNote, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(generateNotesFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(generateNotesSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(getGeneratedNotePreviousInputs, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getMember, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getMemberById, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getMembers, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(resetMembers, state => ({
        ...state,
        members: {
          members: {},
          lastFetchedMembers: {},
          hasMore: true,
        },
        status: SliceStatus.idle,
      }))
      .addCase(getMembersFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(getMembersSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        members: {
          members: {
            ...state.members.members,
            ...action.payload.normalizedMembers,
          },
          lastFetchedMembers: action.payload.normalizedMembers,
          hasMore: action.payload.hasMore,
        },
        snapshot: {
          members: {
            ...state.members.members,
            ...action.payload.normalizedMembers,
          },
          hasMore: state.members.hasMore,
        },
      }))
      .addCase(getWidgetPatientsDetails, state => ({
        ...state,
        widgetsPatientsDetailsStatus: SliceStatus.pending,
      }))
      .addCase(getWidgetPatientsDetailsFailure, state => ({
        ...state,
        widgetsPatientsDetailsStatus: SliceStatus.rejected,
      }))
      .addCase(getWidgetPatientsDetailsSuccess, (state, action) => ({
        ...state,
        widgetsPatientsDetailsStatus: SliceStatus.resolved,
        widgetsPatientsDetails: action.payload,
      }))
      .addCase(getWidgetProviderDetails, state => ({
        ...state,
        widgetsProviderDetailsStatus: SliceStatus.pending,
      }))
      .addCase(getWidgetProviderDetailsFailure, state => ({
        ...state,
        widgetsProviderDetailsStatus: SliceStatus.rejected,
      }))
      .addCase(getWidgetProviderDetailsSuccess, (state, action) => ({
        ...state,
        widgetsProviderDetailsStatus: SliceStatus.resolved,
        widgetsProviderDetails: action.payload,
      }))
      .addCase(searchMembers, state =>
        !Object.keys(state.snapshot.members).length
          ? {
              ...state,
              snapshot: {
                members: state.members.members,
                hasMore: state.members.hasMore,
              },
              searchStatus: SliceStatus.pending,
            }
          : {
              ...state,
              searchStatus: SliceStatus.pending,
            },
      )
      .addCase(searchMembersFailure, state => ({
        ...state,
        searchStatus: SliceStatus.rejected,
      }))
      .addCase(searchMembersSuccess, (state, action) => {
        return {
          ...state,
          members: {
            hasMore: false,
            members: action.payload.normalizedMembers,
            lastFetchedMembers: action.payload.normalizedMembers,
          },
          searchStatus: SliceStatus.resolved,
        };
      })
      .addCase(setOnboardingStep, (state, action) => {
        return {
          ...state,
          onboarding: {
            ...state.onboarding,
            currentStep: action.payload,
          },
        };
      })
      .addCase(resetMembersToSnapshot, state => ({
        ...state,
        members: {
          ...state.members,
          members: {...state.snapshot.members},
          hasMore: state.snapshot.hasMore,
        },
      }))
      .addCase(getMemberSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        members: {
          ...state.members,
          members: {
            ...state.members.members,
            ...action.payload.normalizedMembers,
          },
        },
      }))
      .addCase(getMedications, state => ({
        ...state,
        medicationStatus: SliceStatus.pending,
      }))
      .addCase(getMedicationsFailure, state => ({
        ...state,
        medicationStatus: SliceStatus.rejected,
      }))
      .addCase(getMedicationsSuccess, (state, action) => ({
        ...state,
        medications: action.payload,
        medicationStatus: SliceStatus.resolved,
      }))
      .addCase(getTherapyQuestionnaire, state => ({
        ...state,
        therapyQuestionnaireStatus: SliceStatus.pending,
      }))
      .addCase(getTherapyQuestionnaireSuccess, (state, action) => ({
        ...state,
        therapyQuestionnaire: action.payload,
        therapyQuestionnaireStatus: SliceStatus.resolved,
      }))
      .addCase(getTherapyQuestionnaireFailure, state => ({
        ...state,
        therapyQuestionnaireStatus: SliceStatus.rejected,
      }))
      .addCase(updateProviderProfilePicture, state => ({
        ...state,
        uploading: true,
      }))
      .addCase(updateProviderProfilePictureSuccess, state => ({
        ...state,
        uploading: false,
      }))
      .addCase(updateProviderProfilePictureFailure, state => ({
        ...state,
        uploading: false,
      }))
      .addCase(updateProviderProfile, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updateProviderProfileSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(updateProviderProfileFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(updateProviderPreferredLanguage, state => ({
        ...state,
        languageStatus: SliceStatus.pending,
      }))
      .addCase(updateProviderPreferredLanguageSuccess, state => ({
        ...state,
        languageStatus: SliceStatus.resolved,
      }))
      .addCase(updateProviderPreferredLanguageFailure, state => ({
        ...state,
        languageStatus: SliceStatus.rejected,
      }))
      .addCase(updateProviderProfileInfo, state => ({
        ...state,
        expertSummaryStatus: SliceStatus.pending,
      }))
      .addCase(updateProviderProfileInfoSuccess, state => ({
        ...state,
        expertSummaryStatus: SliceStatus.resolved,
      }))
      .addCase(updateProviderProfileInfoFailure, state => ({
        ...state,
        expertSummaryStatus: SliceStatus.rejected,
      }))
      .addCase(updateMedications, state => ({
        ...state,
      }))
      .addCase(updateMedicationsSuccess, state => ({
        ...state,
      }))
      .addCase(updateMedicationsFailure, state => ({
        ...state,
      }))
      .addCase(getAllProviderBills, state => ({
        ...state,
        billing: {
          ...state.billing,

          status: SliceStatus.pending,
        },
      }))
      .addCase(getAllProviderBillsSuccess, (state, action) => ({
        ...state,
        billing: {
          ...state.billing,
          ...action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getAllProviderBillsFailure, state => ({
        ...state,
        billing: {
          ...state.billing,

          status: SliceStatus.rejected,
        },
      }))
      .addCase(setPlatformFeePercentage, state => ({
        ...state,
        setFeePercentageStatus: SliceStatus.pending,
      }))
      .addCase(setPlatformFeePercentageSuccess, (state, action) => ({
        ...state,
        setFeePercentageStatus: SliceStatus.resolved,
        feePercentage: action.payload.feePercentage,
      }))
      .addCase(setPlatformFeePercentageFailure, state => ({
        ...state,
        setFeePercentageStatus: SliceStatus.rejected,
      }))

      .addCase(getBillingHistory, (state, action) => ({
        ...state,
        billingHistory: {
          ...state.billingHistory,
          [action.payload.roomMode]: {
            ...state.billingHistory[action.payload.roomMode],
            isLoading: SliceStatus.pending,
          },
        },
      }))
      .addCase(getBillingHistoryFailure, (state, action) => ({
        ...state,
        billingHistory: {
          ...state.billingHistory,
          [action.payload.roomMode]: {
            ...state.billingHistory[action.payload.roomMode],
            isLoading: SliceStatus.rejected,
          },
        },
      }))
      .addCase(getBillingHistorySuccess, (state, action) => ({
        ...state,
        billingHistory: {
          ...state.billingHistory,
          [action.payload.roomMode]: {
            ...state.billingHistory[action.payload.roomMode],
            ...action.payload.result,
            isLoading: SliceStatus.resolved,
          },
        },
      }))
      .addCase(changeProviderFromFirstTime, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changeProviderFromFirstTimeSuccess, (state, action) => ({
        ...state,
        onboarding: {
          ...state.onboarding,
          firstTime: action.payload.onboarding?.firstTime,
        },
        status: SliceStatus.resolved,
      }))
      .addCase(changeProviderFromFirstTimeFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(changeOnboardingVideoStatus, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changeOnboardingVideoStatusSuccess, (state, action) => ({
        ...state,
        onboarding: {
          ...state.onboarding,
          onboardingVideo: action.payload.onboarding?.onboardingVideoStatus,
        },
        status: SliceStatus.resolved,
      }))
      .addCase(changeOnboardingVideoStatusFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(completeOnboarding, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(completeOnboardingSuccess, (state, action) => ({
        ...state,
        onboarding: {
          ...state.onboarding,
          onboardingStage: action.payload.onboarding?.onboardingStage,
          currentStep: action.payload.onboarding?.currentStep,
          formStatus: {
            ...state.onboarding.formStatus,
            onboarding: action.payload?.onboarding?.formStatus?.onboarding,
          },
        },
        status: SliceStatus.resolved,
      }))
      .addCase(completeOnboardingFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(signup, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(signupFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(signupSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(updateProviderData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updateProviderDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(updateProviderDataSuccess, (state, action) => ({
        ...state,
        onboarding: {
          ...state.onboarding,
          currentStep: action.payload.onboarding?.currentStep,
        },
        status: SliceStatus.resolved,
      }))
      .addCase(updateProfessionalHeadshot, state => ({
        ...state,
        providerPicStatus: SliceStatus.pending,
      }))
      .addCase(updateProfessionalHeadshotFailure, state => ({
        ...state,
        providerPicStatus: SliceStatus.rejected,
      }))
      .addCase(updateProfessionalHeadshotSuccess, state => ({
        ...state,
        providerPicStatus: SliceStatus.resolved,
      }))
      .addCase(updateProviderResume, state => ({
        ...state,
        providerResumeUploadStatus: SliceStatus.pending,
      }))
      .addCase(updateProviderResumeFailure, state => ({
        ...state,
        providerResumeUploadStatus: SliceStatus.rejected,
      }))
      .addCase(updateProviderResumeSuccess, state => ({
        ...state,
        providerResumeUploadStatus: SliceStatus.resolved,
      }))
      .addCase(getProviderData, state => ({
        ...state,
        onboardingStatus: SliceStatus.pending,
      }))
      .addCase(getProviderDataSuccess, (state, action) => {
        const user = action.payload;
        if (isProvider(user)) {
          return {
            ...state,
            internationalOnboardingStatus: user?.internationalOnboardingStatus,
            verifiedByWellniteTeam: user?.verifiedByWellniteTeam,
            paypalMerchantId: user?.paypalMerchantId,
            onboarding: {
              ...state.onboarding,
              firstTime: user?.onboarding?.firstTime,
              verificationFormSubmissionDate:
                user?.onboarding?.verificationFormDateSubmitted,
              currentStep: user?.onboarding?.currentStep,
              verificationStatus: user?.onboarding?.verificationStatus,
              stage: user?.onboarding?.onboardingStage,
              formStatus: {
                ...state.onboarding.formStatus,
                verification: user.onboarding?.formStatus?.verification,
                profile: user.onboarding?.formStatus?.profile,
                caqh: user.onboarding?.formStatus?.caqh,
                onboarding: user.onboarding?.formStatus?.onboarding,
              },
              onboardingVideo: user?.onboarding?.onboardingVideoStatus,
            },
            onboardingStatus: SliceStatus.resolved,
            onboardingStatusIsInitialized: true,
            feePercentage: user.feePercentage,
            hourlyRate: user.hourlyRate ?? 0,
          };
        } else {
          return {
            ...state,
            onboardingStatus: SliceStatus.resolved,
          };
        }
      })
      .addCase(getProviderDataFailure, state => ({
        ...state,
        onboardingStatus: SliceStatus.rejected,
      }))
      .addCase(updateProviderNpi, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updateProviderNpiSuccess, (state, action) => ({
        ...state,
        onboarding: {
          ...state.onboarding,
          verificationStatus: action?.payload?.onboarding?.verificationStatus,
        },
        status: SliceStatus.resolved,
      }))
      .addCase(updateProviderNpiFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(uploadLicenseCopy, state => ({
        ...state,
        providerPicStatus: SliceStatus.pending,
      }))
      .addCase(uploadLicenseCopySuccess, (state, action) => ({
        ...state,
        onboarding: {
          ...state.onboarding,
          verificationStatus: action?.payload?.onboarding?.verificationStatus,
        },
        providerPicStatus: SliceStatus.resolved,
      }))
      .addCase(uploadLicenseCopyFailure, state => ({
        ...state,
        providerPicStatus: SliceStatus.rejected,
      }))
      .addCase(skipCAQHForm, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(skipCAQHFormSuccess, (state, action) => ({
        ...state,
        onboarding: {
          ...state.onboarding,
          stage: action.payload?.onboarding?.onboardingStage,
          currentStep: action.payload.onboarding?.currentStep,
          formStatus: {
            ...state.onboarding.formStatus,
            caqh: action.payload?.onboarding?.formStatus?.caqh,
          },
        },
        status: SliceStatus.resolved,
      }))
      .addCase(skipCAQHFormFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(updateInternationalProviderCrendentialsData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updateInternationalProviderCrendentialsDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(
        updateInternationalProviderCrendentialsDataSuccess,
        (state, action) => ({
          ...state,
          status: SliceStatus.resolved,
          internationalOnboardingStatus:
            action.payload.internationalOnboardingStatus,
        }),
      )
      .addCase(updateInternationalProviderProfileData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updateInternationalProviderProfileDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(
        updateInternationalProviderProfileDataSuccess,
        (state, action) => ({
          ...state,
          status: SliceStatus.resolved,
          internationalOnboardingStatus:
            action.payload.internationalOnboardingStatus,
        }),
      )
      .addCase(updateInternationalProviderProfileTwoData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(updateInternationalProviderProfileTwoDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(
        updateInternationalProviderProfileTwoDataSuccess,
        (state, action) => ({
          ...state,
          status: SliceStatus.resolved,
          internationalOnboardingStatus:
            action.payload.internationalOnboardingStatus,
        }),
      )
      .addCase(uploadInternationalProviderIdDocument, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(
        uploadInternationalProviderIdDocumentSuccess,
        (state, action) => ({
          ...state,
          status: SliceStatus.resolved,
          internationalOnboardingStatus:
            action.payload.internationalOnboardingStatus,
        }),
      )
      .addCase(uploadInternationalProviderIdDocumentFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(sendInternationalProviderAgreedToTerms, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(
        sendInternationalProviderAgreedToTermsSuccess,
        (state, action) => ({
          ...state,
          status: SliceStatus.resolved,
          internationalOnboardingStatus:
            action.payload.internationalOnboardingStatus,
        }),
      )
      .addCase(sendInternationalProviderAgreedToTermsFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(verifyHuman, state => ({
        ...state,
        providerPicStatus: SliceStatus.pending,
      }))
      .addCase(verifyHumanSuccess, state => ({
        ...state,
        providerPicStatus: SliceStatus.resolved,
        internationalOnboardingStatus:
          InternationaProviderOnboardingStatusTypes.completedSelfieVerificationForm,
      }))
      .addCase(verifyHumanFailure, state => ({
        ...state,
        providerPicStatus: SliceStatus.rejected,
      }))
      .addDefaultCase(state => state),
});

export const {reducer: providerReducer, name: providerReducerName} =
  providerSlice;

export type TProviderActions =
  | ReturnType<typeof getNotes>
  | ReturnType<typeof getNotesSuccess>
  | ReturnType<typeof getNotesFailure>
  | ReturnType<typeof createNotes>
  | ReturnType<typeof createNotesSuccess>
  | ReturnType<typeof createNotesFailure>
  | ReturnType<typeof updateNote>
  | ReturnType<typeof updateNoteSuccess>
  | ReturnType<typeof updateNoteFailure>
  | ReturnType<typeof generateNote>
  | ReturnType<typeof generateNotesSuccess>
  | ReturnType<typeof generateNotesFailure>
  | ReturnType<typeof getGeneratedNotePreviousInputs>
  | ReturnType<typeof getMembers>
  | ReturnType<typeof resetMembers>
  | ReturnType<typeof getMember>
  | ReturnType<typeof getMemberById>
  | ReturnType<typeof getMembersSuccess>
  | ReturnType<typeof getMembersFailure>
  | ReturnType<typeof getWidgetPatientsDetails>
  | ReturnType<typeof getWidgetPatientsDetailsSuccess>
  | ReturnType<typeof getWidgetPatientsDetailsFailure>
  | ReturnType<typeof getWidgetProviderDetails>
  | ReturnType<typeof getWidgetProviderDetailsSuccess>
  | ReturnType<typeof getWidgetProviderDetailsFailure>
  | ReturnType<typeof getMedications>
  | ReturnType<typeof getMedicationsSuccess>
  | ReturnType<typeof getMedicationsFailure>
  | ReturnType<typeof updateProviderProfileSuccess>
  | ReturnType<typeof updateProviderProfileFailure>
  | ReturnType<typeof updateProviderProfilePictureFailure>
  | ReturnType<typeof updateProviderProfilePictureSuccess>
  | ReturnType<typeof updateProviderProfilePicture>
  | ReturnType<typeof searchMembersFailure>
  | ReturnType<typeof searchMembersSuccess>
  | ReturnType<typeof searchMembers>
  | ReturnType<typeof resetMembersToSnapshot>
  | ReturnType<typeof setOnboardingStep>
  | ReturnType<typeof updateMedications>
  | ReturnType<typeof updateMedicationsSuccess>
  | ReturnType<typeof updateMedicationsFailure>
  | ReturnType<typeof resetNoteStatus>
  | ReturnType<typeof resetNotes>
  | ReturnType<typeof getTherapyQuestionnaire>
  | ReturnType<typeof getTherapyQuestionnaireSuccess>
  | ReturnType<typeof getTherapyQuestionnaireFailure>
  | ReturnType<typeof createCMS1500Form>
  | ReturnType<typeof createCMS1500FormSuccess>
  | ReturnType<typeof createCMS1500FormFailure>
  | ReturnType<typeof updateNoteOnCMSFailed>
  | ReturnType<typeof getAllProviderBills>
  | ReturnType<typeof getAllProviderBillsFailure>
  | ReturnType<typeof getAllProviderBillsSuccess>
  | ReturnType<typeof setPlatformFeePercentage>
  | ReturnType<typeof setPlatformFeePercentageFailure>
  | ReturnType<typeof setPlatformFeePercentageSuccess>
  | ReturnType<typeof getBillingHistory>
  | ReturnType<typeof getBillingHistoryFailure>
  | ReturnType<typeof getBillingHistorySuccess>
  | ReturnType<typeof sendCalendarSettingsNotification>
  | ReturnType<typeof sendCalendarSettingsNotificationFailure>
  | ReturnType<typeof sendAvailabilityUpdateNotification>
  | ReturnType<typeof sendAvailabilityUpdateNotificationSuccess>
  | ReturnType<typeof sendAvailabilityUpdateNotificationFailure>
  | ReturnType<typeof signup>
  | ReturnType<typeof signupSuccess>
  | ReturnType<typeof signupFailure>
  | ReturnType<typeof updateProviderData>
  | ReturnType<typeof updateProviderDataFailure>
  | ReturnType<typeof updateProviderDataSuccess>
  | ReturnType<typeof updateProfessionalHeadshot>
  | ReturnType<typeof updateProfessionalHeadshotSuccess>
  | ReturnType<typeof updateProfessionalHeadshotFailure>
  | ReturnType<typeof getProviderData>
  | ReturnType<typeof getProviderDataSuccess>
  | ReturnType<typeof getProviderDataFailure>
  | ReturnType<typeof changeProviderFromFirstTime>
  | ReturnType<typeof changeProviderFromFirstTimeSuccess>
  | ReturnType<typeof changeProviderFromFirstTimeFailure>
  | ReturnType<typeof updateProviderNpi>
  | ReturnType<typeof updateProviderNpiFailure>
  | ReturnType<typeof updateProviderNpiSuccess>
  | ReturnType<typeof uploadLicenseCopy>
  | ReturnType<typeof uploadLicenseCopySuccess>
  | ReturnType<typeof uploadLicenseCopyFailure>
  | ReturnType<typeof skipCAQHForm>
  | ReturnType<typeof skipCAQHFormFailure>
  | ReturnType<typeof skipCAQHFormSuccess>
  | ReturnType<typeof changeOnboardingVideoStatus>
  | ReturnType<typeof changeOnboardingVideoStatusSuccess>
  | ReturnType<typeof changeOnboardingVideoStatusFailure>
  | ReturnType<typeof completeOnboarding>
  | ReturnType<typeof completeOnboardingSuccess>
  | ReturnType<typeof completeOnboardingFailure>
  | ReturnType<typeof verifyHuman>
  | ReturnType<typeof verifyHumanSuccess>
  | ReturnType<typeof verifyHumanFailure>
  | ReturnType<typeof updateInternationalProviderCrendentialsData>
  | ReturnType<typeof updateInternationalProviderCrendentialsDataSuccess>
  | ReturnType<typeof updateInternationalProviderCrendentialsDataFailure>
  | ReturnType<typeof uploadInternationalProviderIdDocument>
  | ReturnType<typeof uploadInternationalProviderIdDocumentSuccess>
  | ReturnType<typeof uploadInternationalProviderIdDocumentFailure>
  | ReturnType<typeof sendInternationalProviderAgreedToTerms>
  | ReturnType<typeof sendInternationalProviderAgreedToTermsSuccess>
  | ReturnType<typeof sendInternationalProviderAgreedToTermsFailure>
  | ReturnType<typeof updateInternationalProviderProfileData>
  | ReturnType<typeof updateInternationalProviderProfileDataFailure>
  | ReturnType<typeof updateInternationalProviderProfileDataSuccess>
  | ReturnType<typeof unsubscribeOnboardingReminder>
  | ReturnType<typeof unsubscribeOnboardingReminderSuccess>
  | ReturnType<typeof unsubscribeOnboardingReminderFailure>;

export const providerActions = {
  getNotes,
  getNotesSuccess,
  getNotesFailure,
  createNotes,
  createNotesSuccess,
  createNotesFailure,
  updateNote,
  updateNoteFailure,
  updateNoteSuccess,
  generateNote,
  generateNotesSuccess,
  generateNotesFailure,
  getGeneratedNotePreviousInputs,
  getMember,
  getMemberById,
  getMemberSuccess,
  getMemberFailure,
  getMembers,
  getMembersSuccess,
  getMembersFailure,
  getWidgetPatientsDetails,
  getWidgetPatientsDetailsSuccess,
  getWidgetPatientsDetailsFailure,
  getWidgetProviderDetails,
  getWidgetProviderDetailsSuccess,
  getWidgetProviderDetailsFailure,
  resetMembers,
  getMedications,
  getMedicationsSuccess,
  getMedicationsFailure,
  updateProviderProfile,
  updateProviderProfileFailure,
  updateProviderProfileSuccess,
  updateProviderProfilePictureFailure,
  updateProviderProfilePictureSuccess,
  updateProviderProfilePicture,
  searchMembers,
  searchMembersFailure,
  searchMembersSuccess,
  resetMembersToSnapshot: resetMembersToSnapshot,
  setOnboardingStep,
  updateMedicationsFailure,
  updateMedications,
  updateMedicationsSuccess,
  resetNoteStatus,
  resetNotes,
  getTherapyQuestionnaireFailure,
  getTherapyQuestionnaire,
  getTherapyQuestionnaireSuccess,
  createCMS1500Form,
  createCMS1500FormFailure,
  updateNoteOnCMSFailed,
  createCMS1500FormSuccess,
  getAllProviderBills,
  getAllProviderBillsFailure,
  getAllProviderBillsSuccess,
  setPlatformFeePercentage,
  setPlatformFeePercentageFailure,
  setPlatformFeePercentageSuccess,
  getBillingHistory,
  getBillingHistoryFailure,
  getBillingHistorySuccess,
  sendCalendarSettingsNotification,
  sendCalendarSettingsNotificationFailure,
  sendCalendarSettingsNotificationSuccess,
  sendAvailabilityUpdateNotification,
  sendAvailabilityUpdateNotificationSuccess,
  sendAvailabilityUpdateNotificationFailure,
  signup,
  signupSuccess,
  signupFailure,
  updateProviderData,
  updateProviderDataFailure,
  updateProviderDataSuccess,
  updateProfessionalHeadshot,
  updateProfessionalHeadshotSuccess,
  updateProfessionalHeadshotFailure,
  updateProviderResume,
  updateProviderResumeSuccess,
  updateProviderResumeFailure,
  getProviderData,
  getProviderDataSuccess,
  getProviderDataFailure,
  changeProviderFromFirstTime,
  changeProviderFromFirstTimeSuccess,
  changeProviderFromFirstTimeFailure,
  changeOnboardingVideoStatus,
  changeOnboardingVideoStatusSuccess,
  changeOnboardingVideoStatusFailure,
  completeOnboarding,
  completeOnboardingSuccess,
  completeOnboardingFailure,
  updateProviderNpi,
  updateProviderNpiSuccess,
  updateProviderNpiFailure,
  uploadLicenseCopy,
  uploadLicenseCopySuccess,
  uploadLicenseCopyFailure,
  skipCAQHForm,
  skipCAQHFormFailure,
  skipCAQHFormSuccess,
  updateProviderPreferredLanguage,
  updateProviderPreferredLanguageFailure,
  updateProviderPreferredLanguageSuccess,
  updateProviderProfileInfo,
  updateProviderProfileInfoFailure,
  updateProviderProfileInfoSuccess,
  updateInternationalProviderCrendentialsData,
  updateInternationalProviderCrendentialsDataFailure,
  updateInternationalProviderCrendentialsDataSuccess,
  uploadInternationalProviderIdDocument,
  uploadInternationalProviderIdDocumentFailure,
  uploadInternationalProviderIdDocumentSuccess,
  sendInternationalProviderAgreedToTerms,
  sendInternationalProviderAgreedToTermsFailure,
  sendInternationalProviderAgreedToTermsSuccess,
  updateInternationalProviderProfileData,
  updateInternationalProviderProfileDataSuccess,
  updateInternationalProviderProfileDataFailure,
  updateInternationalProviderProfileTwoData,
  updateInternationalProviderProfileTwoDataSuccess,
  updateInternationalProviderProfileTwoDataFailure,
  verifyHuman,
  verifyHumanSuccess,
  verifyHumanFailure,
  unsubscribeOnboardingReminder,
  unsubscribeOnboardingReminderSuccess,
  unsubscribeOnboardingReminderFailure,
};

export type ProviderState = ReturnType<typeof providerReducer>;
