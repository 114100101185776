import {
  GenerateWellniteContentArgs,
  GenerateWellniteContentResponse,
  SliceStatus,
  UnsplashImage,
  WellniteContentArticle,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  checkIfProviderReachedDailyWellniteContentLimit,
  checkIfProviderReachedDailyWellniteContentLimitFailure,
  checkIfProviderReachedDailyWellniteContentLimitSuccess,
  generateExpertSummary,
  generateExpertSummaryFailure,
  generateExpertSummarySuccess,
  generateWellniteContent,
  generateWellniteContentFailure,
  generateWellniteContentSuccess,
  getWellniteArticleBySlug,
  getWellniteArticleBySlugFailure,
  getWellniteArticleBySlugSuccess,
  getWellniteArticlesByProvider,
  getWellniteArticlesByProviderFailure,
  getWellniteArticlesByProviderSuccess,
  getWellniteContentDraft,
  getWellniteContentDraftFailure,
  getWellniteImages,
  getWellniteImagesFailure,
  getWellniteImagesSuccess,
  recoverWellniteContentDraft,
  reGenerateWellniteContent,
  reGenerateWellniteContentFailure,
  reGenerateWellniteContentSuccess,
  resetWellniteContent,
  saveWellniteArticle,
  saveWellniteArticleFailure,
  saveWellniteArticleSuccess,
  saveWellniteContentDraft,
  saveWellniteContentDraftFailure,
  saveWellniteContentDraftSuccess,
  setWellniteArticle,
  triggerDownloadUnsplashPhotoEvent,
  triggerDownloadUnsplashPhotoEventFailure,
  triggerDownloadUnsplashPhotoEventSuccess,
} from './wellniteContentActions';

export type WellniteContentSliceState = {
  status: SliceStatus;
  formStep?: string;
  generateExpertSummaryStatus?: SliceStatus;
  saveStatus: SliceStatus;
  draftStatus?: SliceStatus;
  content?: GenerateWellniteContentResponse | null;
  article?: WellniteContentArticle | null;
  articles?: WellniteContentArticle[];
  topicSelection?: GenerateWellniteContentArgs['topicSelection'] | null;
  regenerateStatus: {[key: string]: SliceStatus};
  images: UnsplashImage[];
  imagesCurrentPage?: number;
  imagesTotal?: number;
  imagesTotalPages?: number;
  imagesStatus: SliceStatus;
  providerCanCreate?: boolean;
  error: string;
};

export const wellniteContentSliceInitialState: WellniteContentSliceState = {
  status: SliceStatus.idle,
  generateExpertSummaryStatus: SliceStatus.idle,
  content: undefined,
  article: undefined,
  saveStatus: SliceStatus.idle,
  images: [],
  imagesStatus: SliceStatus.idle,
  regenerateStatus: {},
  error: '',
};

const wellniteContentSlice = createSlice({
  name: 'wellniteContent',
  initialState: wellniteContentSliceInitialState as WellniteContentSliceState,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<WellniteContentSliceState>,
  ) =>
    builder
      .addCase(recoverWellniteContentDraft, (state, {payload}) => ({
        ...state,
        draftStatus: SliceStatus.resolved,
        formStep: payload.formStep,
        content: payload.content,
        article: payload.article,
        topicSelection: payload.topicSelection,
      }))
      .addCase(generateExpertSummary, state => ({
        ...state,
        generateExpertSummaryStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(generateExpertSummarySuccess, state => ({
        ...state,
        generateExpertSummaryStatus: SliceStatus.resolved,
        error: '',
      }))
      .addCase(generateExpertSummaryFailure, (state, action) => ({
        ...state,
        generateExpertSummaryStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(setWellniteArticle, (state, action) => ({
        ...state,
        article: action.payload,
      }))
      .addCase(resetWellniteContent, state => ({
        ...state,
        content: undefined,
        article: undefined,
        topicSelection: undefined,
      }))
      .addCase(generateWellniteContent, (state, payload) => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
        topicSelection: payload.payload.topicSelection,
      }))
      .addCase(generateWellniteContentSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        content: action.payload,
        error: '',
      }))
      .addCase(generateWellniteContentFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(saveWellniteContentDraft, state => ({
        ...state,
        draftStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(saveWellniteContentDraftSuccess, state => ({
        ...state,
        draftStatus: SliceStatus.resolved,
        error: '',
      }))
      .addCase(saveWellniteContentDraftFailure, (state, action) => ({
        ...state,
        draftStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getWellniteContentDraft, state => ({
        ...state,
        draftStatus: SliceStatus.pending,
        error: '',
      }))
      .addCase(getWellniteContentDraftFailure, (state, action) => ({
        ...state,
        draftStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(saveWellniteArticle, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(saveWellniteArticleSuccess, (state, action) => ({
        ...state,
        article: action.payload,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addCase(saveWellniteArticleFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getWellniteImages, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.pending,
        imagesCurrentPage: action.payload.page,
        imagesTotal: 0,
        imagesTotalPages: 0,
        error: '',
      }))
      .addCase(getWellniteImagesSuccess, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.resolved,
        images: action.payload.results,
        imagesCurrentPage: action.payload.page,
        imagesTotal: action.payload.total,
        imagesTotalPages: action.payload.total_pages,
        error: '',
      }))
      .addCase(getWellniteImagesFailure, (state, action) => ({
        ...state,
        imagesStatus: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getWellniteArticleBySlug, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getWellniteArticleBySlugSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        article: action.payload,
        error: '',
      }))
      .addCase(getWellniteArticleBySlugFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(getWellniteArticlesByProvider, state => ({
        ...state,
        articles: [],
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(getWellniteArticlesByProviderSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        articles: action.payload,
        error: '',
      }))
      .addCase(getWellniteArticlesByProviderFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(checkIfProviderReachedDailyWellniteContentLimit, state => ({
        ...state,
        status: SliceStatus.pending,
        error: '',
      }))
      .addCase(
        checkIfProviderReachedDailyWellniteContentLimitSuccess,
        (state, action) => ({
          ...state,
          status: SliceStatus.resolved,
          providerCanCreate: action.payload,
          error: '',
        }),
      )
      .addCase(
        checkIfProviderReachedDailyWellniteContentLimitFailure,
        (state, action) => ({
          ...state,
          status: SliceStatus.rejected,
          providerCanCreate:
            action.payload === 'You have reached the daily post limit',
          error: action.payload,
        }),
      )
      .addCase(reGenerateWellniteContent, (state, action) => ({
        ...state,
        regenerateStatus: {
          ...state.regenerateStatus,
          [action.payload.element]: SliceStatus.pending,
        },
        error: '',
      }))
      .addCase(reGenerateWellniteContentSuccess, (state, action) => {
        const content = {
          ...((state.content ?? {}) as GenerateWellniteContentResponse),
        };
        const {element, ...data} = action.payload;
        Object.keys(data).forEach(key => {
          content[key] = data[key];
        });

        return {
          ...state,
          regenerateStatus: {
            ...state.regenerateStatus,
            [element]: SliceStatus.resolved,
          },
          content,
          error: '',
        };
      })
      .addCase(reGenerateWellniteContentFailure, (state, action) => ({
        ...state,
        regenerateStatus: {
          ...state.regenerateStatus,
          [action.payload.element]: SliceStatus.rejected,
        },
        error: action.payload.error,
      })),
});

export const {
  reducer: wellniteContentReducer,
  name: wellniteContentReducerName,
} = wellniteContentSlice;

export type TWellniteContentActions =
  | ReturnType<typeof recoverWellniteContentDraft>
  | ReturnType<typeof generateExpertSummary>
  | ReturnType<typeof generateExpertSummarySuccess>
  | ReturnType<typeof generateExpertSummaryFailure>
  | ReturnType<typeof setWellniteArticle>
  | ReturnType<typeof resetWellniteContent>
  | ReturnType<typeof generateWellniteContent>
  | ReturnType<typeof generateWellniteContentSuccess>
  | ReturnType<typeof generateWellniteContentFailure>
  | ReturnType<typeof saveWellniteContentDraft>
  | ReturnType<typeof saveWellniteContentDraftSuccess>
  | ReturnType<typeof saveWellniteContentDraftFailure>
  | ReturnType<typeof getWellniteContentDraft>
  | ReturnType<typeof getWellniteContentDraftFailure>
  | ReturnType<typeof reGenerateWellniteContent>
  | ReturnType<typeof reGenerateWellniteContentSuccess>
  | ReturnType<typeof reGenerateWellniteContentFailure>
  | ReturnType<typeof saveWellniteArticle>
  | ReturnType<typeof saveWellniteArticleSuccess>
  | ReturnType<typeof saveWellniteArticleFailure>
  | ReturnType<typeof getWellniteImages>
  | ReturnType<typeof getWellniteImagesSuccess>
  | ReturnType<typeof getWellniteImagesFailure>
  | ReturnType<typeof getWellniteArticleBySlug>
  | ReturnType<typeof getWellniteArticleBySlugSuccess>
  | ReturnType<typeof getWellniteArticleBySlugFailure>
  | ReturnType<typeof getWellniteArticlesByProvider>
  | ReturnType<typeof getWellniteArticlesByProviderSuccess>
  | ReturnType<typeof getWellniteArticlesByProviderFailure>
  | ReturnType<typeof checkIfProviderReachedDailyWellniteContentLimit>
  | ReturnType<typeof checkIfProviderReachedDailyWellniteContentLimitSuccess>
  | ReturnType<typeof checkIfProviderReachedDailyWellniteContentLimitFailure>
  | ReturnType<typeof triggerDownloadUnsplashPhotoEvent>
  | ReturnType<typeof triggerDownloadUnsplashPhotoEventSuccess>
  | ReturnType<typeof triggerDownloadUnsplashPhotoEventFailure>;

export const wellniteContentActions = {
  recoverWellniteContentDraft,
  generateExpertSummary,
  generateExpertSummarySuccess,
  generateExpertSummaryFailure,
  resetWellniteContent,
  setWellniteArticle,
  generateWellniteContent,
  generateWellniteContentSuccess,
  generateWellniteContentFailure,
  saveWellniteContentDraft,
  saveWellniteContentDraftSuccess,
  saveWellniteContentDraftFailure,
  getWellniteContentDraft,
  getWellniteContentDraftFailure,
  reGenerateWellniteContent,
  reGenerateWellniteContentSuccess,
  reGenerateWellniteContentFailure,
  saveWellniteArticle,
  saveWellniteArticleSuccess,
  saveWellniteArticleFailure,
  getWellniteImages,
  getWellniteImagesSuccess,
  getWellniteImagesFailure,
  getWellniteArticleBySlug,
  getWellniteArticleBySlugSuccess,
  getWellniteArticleBySlugFailure,
  getWellniteArticlesByProvider,
  getWellniteArticlesByProviderSuccess,
  getWellniteArticlesByProviderFailure,
  checkIfProviderReachedDailyWellniteContentLimit,
  checkIfProviderReachedDailyWellniteContentLimitSuccess,
  checkIfProviderReachedDailyWellniteContentLimitFailure,
  triggerDownloadUnsplashPhotoEvent,
  triggerDownloadUnsplashPhotoEventSuccess,
  triggerDownloadUnsplashPhotoEventFailure,
};

export type WellniteContentState = ReturnType<typeof wellniteContentReducer>;
