import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {directBookingActions} from 'features/DirectBooking/directBookingSlice';
import {userActions} from 'features/User';
import {AppointmentTypes, UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {StripeService} from 'services';
import {isOutsideWellniteMember} from 'utils';

import {paymentActions} from '../paymentSlice';

export const onboardingOneTimePaymentSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.onboardingOneTimePaymentSuccess.match),
    delayWhen(() => action$.pipe(filter(userActions.setUser.match))),

    withLatestFrom(state$),
    tap(([, state]) => {
      Toast({
        type: 'success',
        message: `${
          isOutsideWellniteMember(state?.user?.current)
            ? 'Credit Card'
            : 'Appointment'
        } Confirmed`,
      });
    }),
    switchMap(() => [
      push({
        pathname: '/dashboard',
      }),
    ]),
  );

export const onboardingOneTimePayment: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.onboardingOneTimePayment.match),
    switchMap(({payload: {providerType, token}}) =>
      from(
        StripeService.onboardingOneTimePayment({
          providerType,
          token,
        }),
      ).pipe(
        withLatestFrom(state$),
        mergeMap(([, state]) => {
          const {
            directBooking: {selectedProvider, selectedSession},
            user: {current},
          } = state;
          return [
            paymentActions.onboardingOneTimePaymentSuccess(),
            userActions.checkSession(UserRoles.member),
            ...(selectedSession && selectedProvider
              ? [
                  directBookingActions.bookAppointment({
                    appointmentDateTimeString: selectedSession.datetime,
                    appointmentType:
                      selectedSession.appointmentType as AppointmentTypes,
                    appointmentTypeID: selectedSession.appointmentTypeId,
                    providerFullName: selectedProvider.providerFullName,
                    calendarId: selectedProvider.calendarId,
                    providerId: selectedProvider.providerId,
                    patientTimezone: selectedSession.timezone,
                    patientEmail: current?.email,
                    memberFullName: current?.fullName,
                  }),
                ]
              : []),
          ];
        }),
        catchError((message: string) =>
          concat(
            of(paymentActions.onboardingOneTimePaymentFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const onboardingOneTimePaymentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.onboardingOneTimePaymentFailure.match),
    withLatestFrom(state$),
    tap(([, state]) => {
      Toast({
        type: 'error',
        message: `${
          isOutsideWellniteMember(state?.user?.current)
            ? 'Credit Card'
            : 'Appointment'
        } confirmation failed`,
      });
    }),
    ignoreElements(),
  );
export const paymentonboardingOneTimePaymentEpics = [
  onboardingOneTimePayment,
  onboardingOneTimePaymentSuccessEpic,
  onboardingOneTimePaymentFailureEpic,
];
