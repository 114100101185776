import React, {FC} from 'react';
import ShareYourStory from 'assets/images/share-story.png';
import ShareYourStoryWebp from 'assets/images/share-story.webp';
import ShareYourStory2x from 'assets/images/share-story@2x.png';
import ShareYourStoryWebp2x from 'assets/images/share-story@2x.webp';
import ShareYourStory3x from 'assets/images/share-story@3x.png';
import ShareYourStoryWebp3x from 'assets/images/share-story@3x.webp';
import {LazyImage} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';

const shareYourStoryLqip = lqip('../../../assets/images/share-story.png');

type Props = {
  classes?: string;
};

export const ShareYourStoryImg: FC<Props> = ({classes}) => (
  <LazyImage
    src={ShareYourStory}
    srcSet={`${ShareYourStory}, ${ShareYourStory2x} 2x, ${ShareYourStory3x}`}
    webp={`${ShareYourStoryWebp}, ${ShareYourStoryWebp2x} 2x, ${ShareYourStoryWebp3x}`}
    type="image/png"
    className={classes}
    aspectRatio={1.2}
    alt="Share Your Story"
    lqip={shareYourStoryLqip}
  />
);
