import {BrandsLogoType} from 'interfaces';

export const BrandLogos: BrandsLogoType[] = [
  {name: 'Adobe', filename: 'Adobe.webp'},
  {name: 'Amazon', filename: 'Amazon.webp'},
  {name: 'Dow', filename: 'Dow.webp'},
  {name: 'Hubspot', filename: 'Hubspot.webp'},
  {name: 'Walmart', filename: 'Walmart.webp'},
  {name: 'Sysco', filename: 'Sysco.webp'},
  {name: 'Disney', filename: 'Disney.webp'},
  {name: 'Lenovo', filename: 'Lenovo.webp'},
  {name: 'Red Cross', filename: 'Red-Cross.webp'},
  {name: 'Apple', filename: 'Apple-Inc-Logo-wine.webp'},
  {name: 'Accenture', filename: 'accenture-logo.webp'},
  {name: 'Pfizer', filename: 'Pfizer.webp'},
  {name: 'Airbnb', filename: 'airbnb.webp'},
  {name: 'Att', filename: 'att-logo-transparent.webp'},
  {name: 'Alcatel Lucent', filename: 'Alcatel-lucent.webp'},
  {name: 'Allianz Life', filename: 'Allianz-Life.webp'},
  {
    name: 'American Airlines',
    filename: 'american-airlines-logo-png-transparent.webp',
  },
  {name: 'American Express', filename: 'American-Express-Company.webp'},
  {
    name: 'Ameriprise Financial',
    filename: 'Ameriprise-Financial-logo-svg.webp',
  },
  {name: 'Analog Devices Inc.', filename: 'Analog-Devices-Inc.webp'},
  {name: 'Ancestry', filename: 'Ancestry.webp'},
  {name: 'Autodesk', filename: 'Autodesk.webp'},
  {name: 'AutoZone Inc.', filename: 'AutoZone-Inc.webp'},
  {name: 'BlackRock Inc.', filename: 'BlackRock-Inc.webp'},
  {name: 'BMW', filename: 'BMW-Group.webp'},
  {name: 'BNY Mellon', filename: 'BNY-Mellon.webp'},
  {name: 'Bristol Myers Squibb Co.', filename: 'Bristol-Myers-Squibb-Co.webp'},
  {
    name: 'Capital One Financial Co.',
    filename: 'Capital-One-Financial-Corporation.webp',
  },
  {name: 'Charles Schwab Co. Inc.', filename: 'Charles-Schwab-Co-Inc.webp'},
  {name: 'Cisco Systems', filename: 'Cisco-Systems.webp'},
  {name: 'Citi Group', filename: 'Citigroup.webp'},
  {name: 'Coca Cola', filename: 'Coca-Cola-Consolidated.webp'},
  {name: 'Colgate Palmolive', filename: 'Colgate-Palmolive.webp'},
  {name: 'CVS Health', filename: 'CVS-Health.webp'},
  {name: 'Danaher Corporation', filename: 'Danaher-Corporation.webp'},
  {name: 'Deloitte LLP', filename: 'Deloitte-LLP.webp'},
  {name: 'DHL', filename: 'DHL.webp'},
  {name: 'Ernst Young LLP EY', filename: 'Ernst-Young-LLP-EY.webp'},
  {name: 'Federal Reserve Banks', filename: 'Federal-Reserve-Banks.webp'},
  {name: 'FedEx Corporation', filename: 'FedEx-Corporation.webp'},
  {name: 'Foot Locker', filename: 'Foot-Locker.webp'},
  {name: 'GameStop', filename: 'GameStop.webp'},
  {name: 'GAP', filename: 'GAP.webp'},
  {name: 'Gates Corporation', filename: 'Gates-Corporation.webp'},
  {name: 'General Electric', filename: 'General-Electric.webp'},
  {
    name: 'Ghirardelli Chocolate Company',
    filename: 'Ghirardelli-Chocolate-Company.webp',
  },
  {name: 'GoDaddy com LLC', filename: 'GoDaddy-com-LLC.webp'},
  {name: 'Goldman Sachs Co.', filename: 'Goldman-Sachs-Co.webp'},
  {name: 'Harley Davidson Inc', filename: 'Harley-Davidson-Inc.webp'},
  {name: 'Hermès of Paris', filename: 'Hermès-of-Paris.webp'},
  {
    name: 'Hewlett Packard Enterprise',
    filename: 'Hewlett-Packard-Enterprise.webp',
  },
  {name: 'HSBC', filename: 'HSBC.webp'},
  {name: 'Intuit Inc', filename: 'Intuit-Inc.webp'},
  {name: 'Johnson Johnson', filename: 'Johnson-Johnson.webp'},
  {
    name: 'JPMorgan Chase and Company',
    filename: 'JPMorgan-Chase-and-Company.webp',
  },
  {name: 'Levi Strauss Co', filename: 'Levi-Strauss-Co.webp'},
  {name: 'Liberty Mutual', filename: 'Liberty-Mutual.webp'},
  {name: 'Macy s Inc', filename: 'Macy-s-Inc.webp'},
  {name: 'Maersk Inc', filename: 'Maersk-Inc.webp'},
  {name: 'Mastercard', filename: 'Mastercard.webp'},
  {name: 'McKesson Corporation', filename: 'McKesson-Corporation.webp'},
  {name: 'Nestle', filename: 'Nestle.webp'},
  {name: 'Nike Inc', filename: 'Nike-Inc.webp'},
  {name: 'Oracle', filename: 'Oracle.webp'},
  {name: 'Paypal', filename: 'PayPal.webp'},
  {name: 'PepsiCo', filename: 'PepsiCo.webp'},
  {
    name: 'PricewaterhouseCoopers PwC',
    filename: 'PricewaterhouseCoopers-PwC.webp',
  },
  {name: 'Procter Gamble', filename: 'Procter-Gamble.webp'},
  {name: 'Qualcomm', filename: 'Qualcomm.webp'},
  {name: 'Quest Diagnostics', filename: 'Quest-Diagnostics.webp'},
  {name: 'Ralph Lauren', filename: 'Ralph-Lauren.webp'},
  {name: 'Roche', filename: 'Roche.webp'},
  {name: 'Salesforce', filename: 'Salesforce.webp'},
  {name: 'Shell USA', filename: 'Shell-USA.webp'},
  {name: 'The Coca Cola Company', filename: 'The-Coca-Cola-Company.webp'},
  {name: 'Unilever', filename: 'Unilever.webp'},
  {
    name: 'Union Pacific Corporation',
    filename: 'Union-Pacific-Corporation.webp',
  },
  {
    name: 'United Parcel Services UPS',
    filename: 'United-Parcel-Services-UPS.webp',
  },
  {name: 'Verizon Communications', filename: 'Verizon-Communications.webp'},
  {name: 'Walgreens', filename: 'Walgreens.webp'},
  {name: 'Wells Fargo', filename: 'Wells-Fargo.webp'},
].sort((a, b) => a.name.localeCompare(b.name));
