import {
  MemberProfile,
  PayPalGetSellerOnboardingDetailsResponse,
  PayPalPaymentCardsListResponse,
  TherapistProfile,
  UserRoles,
} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

/**
 * Asynchronously retrieves the PayPal partner referral link for onboarding purposes.
 *
 * @returns {Promise<{ message: { referralLink: string } }>} A promise containing the response object with the PayPal partner referral link.
 * @throws If an error occurs during the request, the promise is rejected with the appropriate error.
 */
const payPalOnboardingReferralLink = async (): Promise<{
  message: {referralLink: string};
}> => {
  try {
    const res = await requestHandler<{
      message: {referralLink: string};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GeneratePaypalPartnerReferralLink as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

/**
 * Asynchronously generates a PayPal vault setup token for setting up payment details.
 *
 * @param {any} paymentDetails - Payment details required for token generation.
 * @returns {Promise<string>} A promise containing the PayPal vault setup token.
 * @throws If an error occurs during the request, the promise is rejected with the appropriate error.
 */
const generatePayPalVaultSetupToken = async (
  paymentDetails: any,
): Promise<string | number | boolean | null | Object> => {
  try {
    const res = await requestHandler<{
      message: {vaultSetupToken: string};
    }>({
      method: HttpMethods.POST,
      url: EndPoints.CreatePaypalVaultSetupToken as EndPoints,
      data: paymentDetails.card,
    });
    return res.data.message.vaultSetupToken;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

/**
 * Asynchronously creates a vault payment token using the provided vault setup token.
 *
 * @param {string} vaultSetupToken - The vault setup token for which a payment token is to be created.
 * @returns {Promise<string | undefined>} A promise containing the vault payment token, or undefined if unsuccessful.
 * @throws If an error occurs during the request, the promise is rejected with the appropriate error.
 */
const createVaultPaymentToken = async (
  vaultSetupToken: string = '',
  digitalPracticeAccountType?: string,
): Promise<string | undefined> => {
  console.log(`Vault setup token ${vaultSetupToken} was approved!`);
  console.group('Creating vault payment token...');

  try {
    const res = await requestHandler<{
      message: {vaultPaymentToken: string};
    }>({
      method: HttpMethods.POST,
      url: EndPoints.CreatePaypalVaultPaymentToken,
      data: {
        vaultSetupToken,
        ...(digitalPracticeAccountType && {digitalPracticeAccountType}),
      },
    });

    const {vaultPaymentToken} = res.data.message;

    console.log(`Vault payment token ${vaultPaymentToken} created!`);

    return vaultPaymentToken;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

/**
 * Error handler function for handling errors with the JS SDK.
 *
 * @param {any} data - The data associated with the error.
 */
const onError = (data: any) => {
  console.log('data:', data);
  console.error(
    'An error with the JS SDK occurred! Check the console for more information.',
  );
};

/**
 * Asynchronously retrieves the list of payment cards associated with PayPal.
 *
 * @async
 * @returns {Promise<{ message: PayPalPaymentCardsListResponse }>} A promise containing the response object with the PayPal payment cards list.
 * @throws If an error occurs during the request, the promise is rejected with the appropriate error.
 */
const getPayPalPaymentCardsList = async (): Promise<{
  message: PayPalPaymentCardsListResponse;
}> => {
  try {
    const res = await requestHandler<{
      message: PayPalPaymentCardsListResponse;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GetPayPalPaymentCardsList,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

/**
 * Asynchronously sets the specified PayPal payment card as the default payment card.
 *
 * @async
 * @param {PayPalPaymentToken} paymentToken - The PayPal payment token of the card to be set as default.
 * @returns {Promise<{message: string}>} A promise containing the response object with a success message.
 * @throws {Error} If an error occurs during the request, the promise is rejected with the appropriate error.
 */
const setPayPalDefaultPaymentCard = async (
  paymentToken: string,
): Promise<{message: {newPaypalDefaultCard: string}}> => {
  try {
    const res = await requestHandler<{
      message: {newPaypalDefaultCard: string};
    }>({
      method: HttpMethods.PUT,
      url: EndPoints.SetPayPalDefaultPaymentCard,
      data: {paymentToken},
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

/**
 * Asynchronously Deletes a payment card associated with PayPal using the provided payment token.
 *
 * @async
 * @param {PayPalPaymentToken} paymentToken - The PayPal payment token of the card to be deleted.
 * @returns {Promise<{message: string}>} A promise containing the response object with a success message.
 * @throws {Error} If an error occurs during the request, the promise is rejected with the appropriate error.
 */
const deletePaymentCardOnPayPal = async (
  paymentToken: string,
): Promise<{message: string}> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.DELETE,
      url: `${EndPoints.DeleteAPaymentCardOnPayPal}/?paymentToken=${paymentToken}` as unknown as EndPoints,
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateSellerInfo = async (data: {
  merchantId: string;
  role: UserRoles;
}): Promise<Response<{message: TherapistProfile}>> => {
  try {
    const {role, merchantId} = data;
    const res = await requestHandler<{
      message: TherapistProfile;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}/${EndPoints.UpdatePaypalSellerInfo}` as unknown as EndPoints,
      data: {merchantId},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getPayPalSellerOnboardingDetails = async (): Promise<{
  message: PayPalGetSellerOnboardingDetailsResponse;
}> => {
  try {
    const res = await requestHandler<{
      message: PayPalGetSellerOnboardingDetailsResponse;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GetPayPalSellerOnboardingDetails,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const executePayLaterRequest = async (data: {
  appointmentID: string;
  startDate: string;
}): Promise<Response<{message: MemberProfile}>> => {
  const {appointmentID, startDate} = data;
  try {
    const res = await requestHandler<{message: MemberProfile}>({
      method: HttpMethods.POST,
      url: `${EndPoints.PayLaterRequest}` as EndPoints,
      data: {
        appointmentID: appointmentID,
        startDate: startDate,
      },
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const PaypalService = {
  payPalOnboardingReferralLink,
  createVaultSetupToken: generatePayPalVaultSetupToken,
  createVaultPaymentToken,
  onError,
  getPayPalPaymentCardsList,
  setPayPalDefaultPaymentCard,
  deletePaymentCardOnPayPal,
  updateSellerInfo,
  getPayPalSellerOnboardingDetails,
  executePayLaterRequest,
};
