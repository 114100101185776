import {store} from 'app/store';
import {BillingLabels, ProviderProfile, ProviderRole} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

export const getAllProviderBills = async (
  calendarId: string,
): Promise<Response<{message: BillingLabels}>> => {
  try {
    const res = await requestHandler<{
      message: BillingLabels;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetAllProviderBills}/${calendarId}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getBillingHistory = async (data: {
  pageNo: number;
  size: number;
  roomMode: 'normal' | 'group';
}): Promise<{
  data: [];
  hasMore: boolean;
  totalCount: number;
  nextPage: number;
  previousPage: number;
}> => {
  try {
    const res = await requestHandler<{
      message: {
        result: [];
        hasMore: boolean;
        totalCount: number;
        nextPage: number;
        previousPage: number;
      };
    }>({
      method: HttpMethods.GET,
      url: `/api/billing/history?pageNo=${data.pageNo}&size=${data.size}&roomMode=${data.roomMode}` as unknown as EndPoints,
    });

    const {
      message: {result, hasMore, totalCount, previousPage, nextPage},
    } = res.data;

    const previousBillingHistory =
      store.getState()?.provider?.billingHistory[data.roomMode].data;

    return {
      data: [...previousBillingHistory, ...result] as any,
      hasMore,
      totalCount,
      previousPage,
      nextPage,
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const setPlatformFeePercentage = async (args: {
  role: ProviderRole;
  feePercentage: number;
}): Promise<Response<{message: ProviderProfile}>> => {
  try {
    const res = await requestHandler<{
      message: ProviderProfile;
    }>({
      method: HttpMethods.PATCH,
      url: `/api/${args.role}${EndPoints.SetPlatformFeePercentage}` as unknown as EndPoints,
      data: {feePercentage: args.feePercentage},
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const BillingService = {
  getAllProviderBills,
  getBillingHistory,
  setPlatformFeePercentage,
};
