import {AppActions, RootState} from 'app/rootReducer';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {AuthService} from 'services/api';

import {userActions} from '../userSlice';

const registerEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(userActions.register.match),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    switchMap(({payload: {duration, ...rest}}) =>
      from(
        AuthService.register({
          ...rest,
          email: rest.email.toLowerCase(),
          keepSignedIn: true,
        }),
      ).pipe(
        mergeMap(({data: {message: user}}) => [
          userActions.setUser(user),
          userActions.registerSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.registerFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const registerUserEpics = [registerEpic];
