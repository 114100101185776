import {useCallback, useEffect, useRef} from 'react';

export const useAnimationFrame = (callback: (deltaTime: number) => void) => {
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        callback(deltaTime);
      }
      previousTimeRef.current = time;
      requestRef.current = window.requestAnimationFrame(animate);
    },
    [callback],
  );

  useEffect(() => {
    requestRef.current = window.requestAnimationFrame(animate);
    return () => {
      if (requestRef.current !== undefined)
        cancelAnimationFrame(requestRef.current);
    };
  }, [animate]);
};
