import {useEffect, useState} from 'react';

import {loadScript, PayPalNamespace} from '@paypal/paypal-js';

export const usePaypal = () => {
  const [paypal, setPaypal] = useState<PayPalNamespace | null>(null);

  useEffect(() => {
    loadScript({
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
      components: ['card-fields', 'buttons'],
    })
      .then(result => {
        setPaypal(result);
      })
      .catch(error => {
        console.error('Failed to load the PayPal JS SDK script', error);
      });
  }, []);

  return {paypal};
};
