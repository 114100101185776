import {MessagingSchema} from 'definitions/Yup';
import * as Yup from 'yup';

import {ConversationWith} from './Conversation.types';

export type ChatAuth = {
  uid: string;
  photoURL: string;
  email: string;
  displayName: string;
} | null;

export type ChatUserDataSnapShot = {
  prescriberDetails: {
    prescriberId: string;
    fullName: string;
    email: string;
    image: string;
  };
  channelId: string;
  fullName: string;
  avatar: string;
  email: string;
  patientId: string;
};

export type ChatUserData = {
  channelId: string;
  avatar: string;
  email: string;
  fullName: string;
  patientId: string;
  status: 'online' | 'offline';
  uid: string;
  lastSeen: number;
} | null;

export type ChatChannel = {
  id: string;
  name: string;
} | null;

export type ChatMessage = {
  content?: string;
  timestamp: number;
  user: {[key: string]: string | null};
  image?: string | null;
};

export enum ChatReceiverType {
  user = 'user',
  group = 'group',
}

export enum ChatCategory {
  message = 'message',
  custom = 'custom',
}

export enum MessageType {
  text = 'text',
  image = 'image',
  file = 'file',
  audio = 'audio',
  video = 'video',
}

export type SendChatData = {
  receiver: string;
  receiverType: string;
  category: string;
  type: string;
  data: {
    text: string;
    metadata: Record<string, string>;
  };
  multipleReceivers: {
    uids: string[];
    guids: string[];
  };
  tags: string[];
};

export type CometEntity = {
  uid: string;
  name: string;
  role: string;
  avatar: string;
  status: string;
  createdAt: number;
  conversationId?: string;
};

export type CometChatMessageType = {
  id: string;
  conversationId: string;
  sender: CometChatParticipant;
  receiverType: string;
  receiver: CometChatParticipant;
  category: string;
  type: MessageType;
  data: {
    action?: string;
    type: MessageType;
    text: string;
    attachments?: Attachment[];
    entities: {
      sender: {
        entity: CometEntity;
        entityType: string;
      };
      receiver: {
        entity: CometEntity;
        entityType: string;
      };
    };
    metadata: FileOptions;
  };
  sentAt: number;
  updatedAt?: number;
  deletedAt?: number;
  deletedBy?: string;
  deliveredAt?: number;
  readAt?: number;
  metadata: FileOptions;
};
export type CometChatMessage = {
  data: CometChatMessageType[];
  meta: Record<string, any>;
};

export interface Attachment {
  url: string;
  name: string;
  size: number;
  mimeType: string;
  extension: string;
}

export type LastMessage = {
  id: string;
  text?: string;
  sentAt: number;
  deliveredAt?: number;
  readAt?: number;
  sender: ConversationWith;
  type: MessageType;
  attachments?: Attachment[];
  metadata?: FileOptions;
};

export type CometFriend = {
  uid: string;
  name: string;
  avatar: string;
  status: 'online' | 'offline';
  role: string;
  createdAt: number;
  conversationId: string;
  lastActiveAt: number;
  isSearched?: boolean; // this field added to verify the searched friends
} & {
  unreadMessageCount: number;
  lastMessage: LastMessage;
  typing?: boolean;
};

export type CometChatParticipant = {
  hasBlockedMe: boolean;
  blockedByMe: boolean;
  deactivatedAt: number;
  uid: string;
  name: string;
  avatar: string;
  lastActiveAt: number;
  role: string;
  status: string;
  metaData?: any;
};

export type ChatMessageHistory = {[key: string]: ChatMessage};

export type MessagingData = Yup.InferType<typeof MessagingSchema>;

export interface EmojiDataTypes {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string;
  emoticons: string[];
}

export type FileOptions = {
  originalDimension?: {
    width: number;
    height: number;
  };
  placeholderSrc?: {
    base64: string;
    dimension: {
      width: number;
      height: number;
    };
  };
  type: string;
  ext: string;
};

export type ChatParticipant = {
  conversationId: string;
  uid: string;
  isChatActive?: boolean;
} | null;
