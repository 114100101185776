import {StylesConfig} from 'react-select';
import {createGlobalStyle} from 'styled-components';
import styledNormalize from 'styled-normalize';

export const rsModalInputStyles: StylesConfig<any, any> = {
  menuPortal: base => ({...base, zIndex: 9999}),
};

export const rsInputStyle: StylesConfig<any, any> = {
  control: (base: any) => ({
    ...base,
    border: '1px solid #eee !important',
    borderRadius: '7px !important',
    boxShadow: '0 !important',
    background: '#f1f1f1 !important',
    textTransform: 'capitalize',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: '#6b6b6b',
  }),
};

export const rsInputStyleWithFocus: StylesConfig<any, any> = {
  control: (base: any, {isFocused}) => ({
    ...base,
    border: isFocused ? '1px solid #2E62EC' : '1px solid #B1B1B1',
    borderRadius: '7px',
    boxShadow: '0px 0px 1px 0px #B1B1B1',
    background: '#ffffff',
    textTransform: 'capitalize',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
  }),
  dropdownIndicator: (base: any, {isFocused}) => ({
    ...base,
    color: isFocused ? '#2E62EC' : '#6b6b6b',
  }),
};

export const rsInputStyleWithFocusLightBorder: StylesConfig<any, any> = {
  control: (base: any, {isFocused}) => ({
    ...base,
    border: isFocused ? '1px solid #2E62EC' : '1px solid #E1E1E1',
    borderRadius: '7px',
    boxShadow: 'none',
    background: '#ffffff',
    textTransform: 'capitalize',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
  }),
  dropdownIndicator: (base: any, {isFocused}) => ({
    ...base,
    color: isFocused ? '#2E62EC' : '#6b6b6b',
  }),
};

export const rsStyleV1: StylesConfig<any, any> = {
  control: (base: any, {isFocused}) => ({
    ...base,
    border: isFocused ? '1px solid #2E62EC' : '1px solid #ccc',
    borderRadius: '6px',
    boxShadow: 'none',
    background: '#ffffff',
    textTransform: 'capitalize',
    paddingTop: '0.3125rem',
    paddingBottom: '0.3125rem',
  }),
  dropdownIndicator: (base: any, {isFocused}) => ({
    ...base,
    color: isFocused ? '#2E62EC' : '#6b6b6b',
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#747474', // Placeholder text color
    fontSize: '14px', // Example: Italicize the placeholder text
  }),
};

// eslint-disable-next-line max-lines-per-function
export const GlobalStyles = createGlobalStyle`
    ${styledNormalize}

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;

    ::-moz-placeholder {
      color: #9c9b9b;
    }

    ::-webkit-input-placeholder {
      color: #9c9b9b;
    }

    ::selection {
      background: lighten(#315eff, 25);
    }

    ::-webkit-file-upload-button {
      font: inherit;
      -webkit-appearance: button;
    }

    [tabindex='-1']:focus:not(.DayPicker-Day) {
      outline: 0 !important;
    }

  .fontfamily-loaded {
  html,
  body {
    font-family: 'Avenir';
    }
  }

  html,
  body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    touch-action: pan-y; /*prevent user scaling*/
  }

  body {
  }

  .allison {
    font-family: 'Allison';
  }
  /* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin !important;
  scrollbar-color: #315eff white !important;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 0.67rem !important;
}
*::-webkit-scrollbar-track {
  background: white !important;
}
*::-webkit-scrollbar-thumb {
  background-color: #315eff !important;
  border-radius: 20px !important;
  border: 3px solid white !important;
}

    a {
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
    }

    a:not(.underline) {
      text-decoration: none !important;
    }
    
    a:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: none;

      &:focus {
        outline: 0;
      }
    }

    img {
      vertical-align: middle;
      border-style: none;
      width: 100%;
    }

    svg:not(:root) {
      overflow: hidden;
    }

    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
      height: auto;
    }

    [type='search'] {
      outline-offset: -2px;
      -webkit-appearance: none;
    }

    [type='search']::-webkit-search-cancel-button,
    [type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    [hidden] {
      display: none !important;
    }

    img {
      max-width: 100%;
      font-style: italic;
      vertical-align: middle;
    }

    img[width],
    img[height] {
      max-width: none;
    }

    #nprogress {
      pointer-events: none;
    }

    #nprogress .bar {
      background: #315eff;
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      -webkit-animation: shift-rightwards 1s ease-in-out infinite;
      animation: shift-rightwards 1s ease-in-out infinite;
      animation-delay: 0.3s;
    }

    /* Fancy blur effect */

    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px #315eff, 0 0 5px #315eff;
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0, -4px);
      -ms-transform: rotate(3deg) translate(0, -4px);
      transform: rotate(3deg) translate(0, -4px);
    }

    #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
    }

    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: #315eff;
      border-left-color: #315eff;
      border-radius: 50%;
      -webkit-animation: nprogress-spinner 400ms linear infinite;
      animation: nprogress-spinner 400ms linear infinite;
    }

    .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
    }

    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
      position: absolute;
    }

    @-webkit-keyframes nprogress-spinner {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes nprogress-spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .responsive-w {
      max-width:1400px;
      margin: 0 auto;
    }
  `;
