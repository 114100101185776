import {DigitalPracticeAccountTypes, ProviderRole} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';
import {Token} from '@stripe/stripe-js';

export const digitalPracticeInsuranceCheckout = createAction<{
  token?: Token;
  companyName?: string;
  employerName?: string;
}>('digitalPracticeInsuranceCheckout');
export const digitalPracticeInsuranceCheckoutSuccess = createAction(
  'digitalPracticeInsuranceCheckoutSuccess',
);
export const digitalPracticeInsuranceCheckoutFailure = createAction(
  'digitalPracticeInsuranceCheckoutFailure',
);

export const digitalPracticeOutOfPocketCheckout = createAction<{
  providerRole: ProviderRole;
  token: Token;
  digitalPracticeAccountType: DigitalPracticeAccountTypes;
}>('digitalPracticeOutOfPocketCheckout');
export const digitalPracticeOutOfPocketCheckoutSuccess = createAction<
  {paypalDefaultCard: string} | undefined
>('digitalPracticeOutOfPocketCheckoutSuccess');
export const digitalPracticeOutOfPocketCheckoutFailure = createAction(
  'digitalPracticeOutOfPocketCheckoutFailure',
);

export const initiatePaystackTransaction = createAction(
  'initiatePaystackTransaction',
);
export const initiatePaystackTransactionSuccess = createAction<{
  authorizationUrl: string;
}>('initiatePaystackTransactionSuccess');
export const initiatePaystackTransactionFailure = createAction(
  'initiatePaystackTransactionFailure',
);
