import React, {FC} from 'react';
import {Button, CaretLeftIcon, Drawer, GoBackIcon} from 'components';
import {LanguageSelector} from 'components/Basic/Language';
import SettingsNavigation from 'components/Navigation/SettingsNavigation';
import {useToggle} from 'hooks';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {history} from 'utils';

import HeaderProfileImage from './ProfileImage';

import 'styles/Animations.css';

const DesktopHeaderContainer = styled.section`
  padding-top: 5.9rem;
`;

const ChatDesktopHeaderContainer = styled.section`
  height: 11rem;
`;

interface Props {
  headerTitle: React.ReactNode;
  backButton?: boolean;
  backButtonFn?(): void;
  actionButton?: React.ReactNode;
  classNames?: string;
  classes?: string;
  headerClasses?: string;
  withProfilePicture?: boolean;
}

const goBack = () => {
  // Only run history.goBack() if referrer (prev page) is on same domain
  if (document.referrer.includes(window.location.host)) {
    history.goBack();
  } else {
    history.push('/dashboard');
  }
};

export const MobileTabletHeader: FC<Props & {version?: 'v1' | 'v2'}> = ({
  headerTitle,
  actionButton,
  backButtonFn,
  backButton = false,
  classes = '',
  headerClasses,
  version,
  withProfilePicture = false,
}) => {
  const {toggleOn, onToggleClick} = useToggle();
  const toggleNavigationView = () => {
    onToggleClick();
  };
  const {t} = useTranslation();

  if (version === 'v2') {
    return (
      <section
        className={`w-full flex justify-between items-center p-3 md:p-5 lg:hidden ${classes} border-b  border-gray-200`}
      >
        {withProfilePicture ? (
          <div className="inline-flex items-center">
            <HeaderProfileImage className="w-11 h-11" />
          </div>
        ) : null}
        <div
          data-testid="headerTitle"
          className="font-bold md:font-normal leading-snug text-lg md:text-4xl my-auto whitespace-no-wrap line-clamp-1"
        >
          {headerTitle}
        </div>

        <div className="flex justify-center items-center space-x-3">
          <LanguageSelector classes="relative" />
          {actionButton ? (
            actionButton
          ) : (
            <span className="cursor-pointer">
              <HeaderProfileImage />
            </span>
          )}
        </div>
      </section>
    );
  }

  if (!backButton)
    return (
      <>
        <section
          className={`w-full flex items-center justify-between px-3 md:px-5 h-16 lg:hidden ${classes}`}
        >
          <div className="inline-flex items-center">
            <HeaderProfileImage className="w-11 h-11" />
          </div>
          <div
            data-testid="headerTitle"
            className="grow font-bold md:font-normal leading-snug text-center text-lg md:text-4xl my-auto whitespace-no-wrap line-clamp-1"
          >
            {headerTitle}
          </div>
          <div className="h-auto relative text-right inline-block">
            {actionButton ? (
              actionButton
            ) : (
              <>
                <p
                  data-testid="account"
                  onClick={toggleNavigationView}
                  role="button"
                  className="text-right text-gray-700 text-sm md:text-lg leading-snug my-auto cursor-pointer"
                >
                  {t('account')}
                </p>
              </>
            )}
          </div>
        </section>
        <Drawer show={toggleOn} onClose={onToggleClick}>
          <SettingsNavigation closeSettings={onToggleClick} />
        </Drawer>
      </>
    );

  return (
    <section
      className={`w-full flex justify-between items-center gap-1 px-5 py-5 border-b  border-gray-200 font-inter lg:hidden ${classes}`}
    >
      <div className="flex justify-start items-center">
        <span
          onClick={backButtonFn ? backButtonFn : goBack}
          className="inline-flex items-center justify-start cursor-pointer h-8 w-6 rounded-full"
        >
          <CaretLeftIcon fillColor="#000" width={18} height={18} />
        </span>
        <p
          data-testid="headerTitle"
          className={`text-[22px] font-semibold flex-1 md:font-normal whitespace-no-wrap text-left leading-snug text-black text-lg md:text-4xl my-auto ${headerClasses}`}
        >
          {headerTitle}
        </p>
      </div>

      <div className="w-auto h-auto relative text-right">
        {actionButton ? actionButton : null}
      </div>
    </section>
  );
};

export const DesktopHeader: FC<Props> = ({
  headerTitle,
  actionButton,
  backButton,
  backButtonFn,
  classes = '',
  headerClasses = '',
}) => {
  if (!backButton)
    return (
      <DesktopHeaderContainer
        className={`hidden lg:flex flex-row justify-between items-center ${classes}`}
      >
        <h1
          data-testid="headerTitle"
          className={`leading-snug text-3xl mr-auto pl-8 ${headerClasses}`}
        >
          {headerTitle}
        </h1>
        {actionButton ? actionButton : null}
      </DesktopHeaderContainer>
    );

  return (
    <DesktopHeaderContainer
      className={`hidden lg:flex border-b  border-gray-200 items-center ${classes}`}
    >
      <span
        onClick={backButtonFn ? backButtonFn : goBack}
        className="inline-flex items-center justify-left cursor-pointer w-8 h-12 rounded-full"
      >
        <CaretLeftIcon fillColor="#000" width={18} height={18} />
      </span>
      <h1
        data-testid="headerTitle"
        className={`leading-snug flex-1 text-3xl text-left pr-16 ${headerClasses}`}
      >
        {headerTitle}
      </h1>
      {actionButton ? actionButton : null}
    </DesktopHeaderContainer>
  );
};

export const NormalHeader: FC<Props> = ({
  headerTitle,
  actionButton,
  backButton,
  backButtonFn,
  classNames = '',
}) => {
  if (!backButton)
    return (
      <DesktopHeaderContainer
        className={`lg:flex items-baseline ${classNames}`}
      >
        <h1
          data-testid="headerTitle"
          className="leading-snug text-3xl mr-auto pl-8"
        >
          {headerTitle}
        </h1>
        {actionButton ? actionButton : null}
      </DesktopHeaderContainer>
    );

  return (
    <DesktopHeaderContainer className={`lg:flex items-baseline ${classNames}`}>
      <span
        onClick={backButtonFn ? backButtonFn : goBack}
        className="inline-flex items-center justify-center cursor-pointer ml-5 md:ml-8 lg:ml-4 h-8 w-8 md:w-12 md:h-12 rounded-full"
      >
        <GoBackIcon fillColor="#000" width={18} height={18} />
      </span>
      <h1
        data-testid="headerTitle"
        className="leading-snug flex-1 text-3xl text-center pr-16"
      >
        {headerTitle}
      </h1>
    </DesktopHeaderContainer>
  );
};

export const ChatHeader: FC<Props> = ({
  headerTitle,
  actionButton,
  backButton,
  backButtonFn,
  classNames = '',
}) => {
  if (!backButton)
    return (
      <ChatDesktopHeaderContainer className={`lg:flex items-end ${classNames}`}>
        <h1
          data-testid="headerTitle"
          className="leading-snug text-3xl mr-auto pl-8"
        >
          {headerTitle}
        </h1>
        {actionButton ? actionButton : null}
      </ChatDesktopHeaderContainer>
    );

  return (
    <ChatDesktopHeaderContainer
      className={`lg:flex items-baseline ${classNames}`}
    >
      <Button
        type="button"
        onClick={backButtonFn ? backButtonFn : goBack}
        className="inline-flex items-center justify-center cursor-pointer ml-5 md:ml-8 lg:ml-4 h-8 w-8 md:w-12 md:h-12 rounded-full"
      >
        <GoBackIcon fillColor="#000" width={18} height={18} />
      </Button>
      <h1
        data-testid="headerTitle"
        className="leading-snug flex-1 text-2xl text-center -mb-6 pr-16 font-medium"
      >
        {headerTitle}
      </h1>
    </ChatDesktopHeaderContainer>
  );
};
