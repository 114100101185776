import * as Yup from 'yup';

const InternationalProviderUploadIdDocumentSchema = Yup.object()
  .shape({
    idType: Yup.string().required('ID type is required'),
    governmentIdFront: Yup.mixed<File>().required('ID front image is required'),
    governmentIdBack: Yup.mixed().when('idType', {
      is: idType => idType !== 'passport',
      then: Yup.mixed().required('ID back image is required'),
      otherwise: Yup.mixed().notRequired(),
    }),
  })
  .required();

export default InternationalProviderUploadIdDocumentSchema;
