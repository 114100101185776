import {EndPoints, HttpMethods, UserRoles} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';

export interface SendDigitalPracticeLinkToContactsData {
  role: UserRoles;
  emails: string[];
  phones: string[];
}

const sendDigitalPracticeLinkToContacts = async ({
  role,
  ...data
}: SendDigitalPracticeLinkToContactsData): Promise<
  Response<{
    message: string;
  }>
> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}${EndPoints.SendDigitalPracticeLinkToContacts}` as unknown as EndPoints,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    throw errorValue;
  }
};

export const DigitalPracticeService = {
  sendDigitalPracticeLinkToContacts,
};
