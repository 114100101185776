import {AppActions, RootState} from 'app/rootReducer';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {providerActions} from '../../providerSlice';

export const getWidgetPatientsDetailsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getWidgetPatientsDetails.match),
    mergeMap(({payload}) =>
      from(ProviderService.getWidgetPatientsDetails(payload)).pipe(
        mergeMap(({message}) => [
          providerActions.getWidgetPatientsDetailsSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getWidgetPatientsDetailsFailure()),
          ),
        ),
      ),
    ),
  );

export const getWidgetProviderDetailsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getWidgetProviderDetails.match),
    mergeMap(({payload}) =>
      from(ProviderService.getWidgetProviderDetails(payload)).pipe(
        mergeMap(({message}) => [
          providerActions.getWidgetProviderDetailsSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getWidgetProviderDetailsFailure()),
          ),
        ),
      ),
    ),
  );

export const WidgetsEpics = [
  getWidgetPatientsDetailsEpic,
  getWidgetProviderDetailsEpic,
];
