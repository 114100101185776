import {useState} from 'react';
import {downloadFile} from 'utils';

export const useFileDownload = (url?: string, name?: string) => {
  const [status, setStatus] = useState(false);

  const download = async () => {
    try {
      if (url && name) {
        setStatus(true);
        await downloadFile(url, name);
      }
      setStatus(false);
    } catch (error) {
      setStatus(false);
    }
  };

  return {
    status,
    download,
  };
};
