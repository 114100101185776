import * as Yup from 'yup';

export const MoodTrackerSchema = Yup.object()
  .shape({
    emojis: Yup.array()
      .of(Yup.string().nullable(true))
      .required('Emojis are required'),
    emotions: Yup.array()
      .of(Yup.string().required('Emotion is required'))
      .required('Emotions array is required'),
    topic: Yup.string().required('Topic is required'),
    thoughts: Yup.string().required('Thoughts are required'),
  })
  .required();
