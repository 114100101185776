import React, {Fragment, useState} from 'react';
import {CloseIcon} from 'components';
import {selectUserProfile} from 'features/User';
import {selectRateVideo, videoActions} from 'features/Video';
import {useRequesting} from 'hooks';
import {SliceStatus, UserRoles} from 'interfaces';
import {useDispatch, useSelector} from 'react-redux';

import {Dialog, Transition} from '@headlessui/react';

import {FormNavigationButtons} from './FormNavigationButtons';
import {LogoHeader} from './LogoHeader';
import {formSteps} from './steps';

import {ratingActions} from 'Rating';

const initialValues = {
  rating: {
    audioVideo: 0,
    provider: 0,
  },
};

export default function RatingModal() {
  const user = useSelector(selectUserProfile);
  const {appointmentID} = useSelector(selectRateVideo);
  const ratingStatus = useRequesting('rating');
  const [currentStep, setCurrentStep] = useState(1);
  const [formState, setFormState] = useState(initialValues);
  const CurrentTab = formSteps[currentStep];

  const isLastStep = () => currentStep === Object.keys(formSteps).length;
  const isFirstStep = () => currentStep === 1;

  const isLast = isLastStep();
  const isFirst = isFirstStep();

  const dispatch = useDispatch();

  const handleBackClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    currentStep > 1 && setCurrentStep(prevStep => prevStep - 1);
  };

  const isSubmitting = ratingStatus === SliceStatus.pending;

  const onSubmit = (formData: {
    rating: {audioVideo: number; provider: number};
  }) => {
    if (!user?.role) return;
    const rating = {...formState.rating, ...formData.rating};
    setFormState({rating});

    const ratingData = {
      role: user?.role,
      appointmentId: Number(appointmentID),
      audioVideo: rating.audioVideo,
      ...(user?.role === UserRoles.member && {provider: rating.provider}),
    };

    if (user?.role === UserRoles.member) {
      if (isLast) {
        dispatch(ratingActions.submitRatingRequest(ratingData));
        !isSubmitting &&
          dispatch(videoActions.rateVideoCall({appointmentID: ''}));
      } else {
        setCurrentStep(prev => prev + 1);
      }
    } else {
      dispatch(ratingActions.submitRatingRequest(ratingData));
      !isSubmitting &&
        dispatch(videoActions.rateVideoCall({appointmentID: ''}));
    }
  };

  const closeModal = () =>
    dispatch(videoActions.rateVideoCall({appointmentID: ''}));

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-999"
        onClose={() => {}}
        data-testid="rating-modal"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform rounded-2xl bg-white p-0 text-left align-middle shadow-xl transition-all">
                <div className="h-full flex flex-col p-6">
                  <div className="w-full flex items-center justify-between">
                    <LogoHeader classes="mx-auto pt-4 pb-3" />

                    <button
                      onClick={closeModal}
                      className="absolute top-5 right-5 w-8 h-8 rounded-full bg-gray-100 flex justify-center items-center"
                    >
                      <CloseIcon strokeColor="text-gray-700" strokeWidth={40} />
                    </button>
                  </div>

                  <div className="w-full py-4">
                    <CurrentTab onSubmit={onSubmit} store={formState} />
                  </div>

                  <FormNavigationButtons
                    user={user}
                    isLast={isLast}
                    isFirst={isFirst}
                    handleBackClick={handleBackClick}
                    isSubmitting={isSubmitting}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
