import React, {FC, useEffect} from 'react';
import {RootState} from 'app/rootReducer';
import {AccordionPanel} from 'components/Basic/Accordion';
import format from 'date-fns-tz/format';
import {providerActions} from 'features/Provider';
import MedicalQuestionnaire from 'features/Provider/MedicalQuestionnaire';
import TherapyQuestionnaire from 'features/Provider/Member/MemberTabs/Clinicalntakes/TherapyQuestionnaire';
import {selectMemberById} from 'features/Provider/Members/membersSelectors';
import {selectUserProfile} from 'features/User';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  formattedDOB,
  isProvider,
  isUserFromUnitedStates,
  titleCaseName,
} from 'utils';

type Props = {
  patientId: string;
};

export const isNonEmptyArrayStrings = (arr: string[]) => {
  return (
    Array.isArray(arr) &&
    arr.length > 0 &&
    arr.every(item => typeof item === 'string' && item.trim() !== '')
  );
};

export const MemberCallData: FC<Props> = ({patientId}) => {
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();
  const member = useSelector((state: RootState) => {
    return selectMemberById(state, patientId);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && isProvider(user)) {
      dispatch(
        providerActions.getMemberById({
          patientId,
          role: user.role,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, user!.role]);

  return (
    <section className="w-full h-full border-l grow px-5 py-6 overflow-y-auto space-y-5 text-[0.9rem]">
      <section className="font-semibold border p-4 space-y-5">
        <p>
          {t('name')}:&nbsp;
          <span className="font-normal">
            {titleCaseName(member?.fullName ?? '')}
          </span>
        </p>
        <p>
          {t('gender')}:&nbsp;
          <span className="font-normal">
            {member?.gender ? titleCaseName(member.gender) : 'N/A'}
          </span>
        </p>
        {member?.dateOfBirth ? (
          <p>
            {t('dateOfBirth')}:&nbsp;
            <span className="font-normal">
              {typeof member?.dateOfBirth === 'string'
                ? format(
                    formattedDOB(member?.dateOfBirth as Date),
                    'MM/dd/yyyy',
                  )
                : null}
            </span>
          </p>
        ) : null}
        <p>
          {t('address')}:&nbsp;
          <span className="font-normal">
            &nbsp;
            {member?.shippingAddress?.streetAddress
              ? `${member?.shippingAddress?.streetAddress} ${member?.shippingAddress?.city}, ${member?.shippingAddress?.state}, ${member?.shippingAddress?.postalCode}, ${member?.shippingAddress?.country}`
              : 'N/A'}
          </span>
        </p>
        {isUserFromUnitedStates(user) ? (
          <p>
            {t('allergies')}:&nbsp;
            <span className="font-normal">
              {isNonEmptyArrayStrings(member?.allergies)
                ? member?.allergies?.map(all => <span key={all}>{all}</span>)
                : 'N/A'}
            </span>
          </p>
        ) : null}
        <p>
          <Trans i18nKey="medicationsPriorToWellnite">
            Medications prior to Wellnite
            <span className="font-normal text-gray-700">
              check medications prescribed at Wellnite in the notes section if
              any
            </span>
            :
          </Trans>
          &nbsp;
          <span className="font-normal">
            {Array.isArray(member?.currentMedications) &&
            member.currentMedications.length
              ? member?.currentMedications?.map(cm => (
                  <span key={cm}>{cm}</span>
                ))
              : 'N/A'}
          </span>
        </p>
      </section>
      {/* Medical Questionnaire */}
      {isUserFromUnitedStates(user) ? (
        <AccordionPanel title={t('medicalQuestionnaire.title')}>
          <MedicalQuestionnaire isOnLeft={true} />
        </AccordionPanel>
      ) : null}
      {/* Therapy Questionnaire */}
      <AccordionPanel title={t('therapyQuestionnaire.title')}>
        <TherapyQuestionnaire member={member} />
      </AccordionPanel>
    </section>
  );
};
