import Toast from 'components/Basic/Toast';
import {UserRoles, WherebyRoomModeTypes} from 'interfaces';
import {VideoService} from 'services/api';

export const getWherebyRoomUrl = async (
  participant: {
    role: UserRoles;
    id: string;
  },
  userRole: UserRoles,
  appointmentTime: string,
  appointmentID: string,
  roomMode: WherebyRoomModeTypes,
): Promise<
  | {
      roomUrl: string;
    }
  | undefined
> => {
  try {
    const {
      data: {message: videoCredentials},
    } = await VideoService.getWherebyRoomUrl({
      ...participant,
      appointmentTime,
      userRole,
      appointmentID,
      roomMode,
    });
    return videoCredentials;
  } catch (err) {
    if (err) {
      Toast({
        type: 'error',
        message: err,
        position: 'bottom-left',
        draggable: false,
        pauseOnHover: true,
      });
    }
    return err;
  }
};
