import {useEffect} from 'react';
import {CurrentUser} from 'interfaces';
import Smartlook from 'smartlook-client';

/**
 *
 * @param user - logged in user: CurrentUser
 */
export const useSmartLook = (user: CurrentUser) => {
  useEffect(() => {
    if (!Smartlook.initialized()) {
      Smartlook.init('18d2e7b42e4dbaaac2e245fd9ef867725d6a5f3d');
    }
    if (user) {
      Smartlook.identify(user._id, {
        email: user.email,
        role: user.role,
      });
    }
  }, [user]);
};
