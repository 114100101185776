import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {memberActions} from 'features/Member/memberSlice';
import {userActions} from 'features/User/userSlice';
import i18next from 'i18next';
import {
  DigitalPracticeAccountTypes,
  SliceStatus,
  StripePlanNames,
  UserAccountType,
  UserRoles,
} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
//import {push} from 'connected-react-router'
import {AuthService} from 'services/api';
import {SocialOnboardingService} from 'services/api/SocialOnboarding';

export const requestOneTimePasswordEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.requestOneTimePassword.match),
    switchMap(() =>
      from(AuthService.requestOtpCode()).pipe(
        mergeMap(() => [userActions.requestOneTimePasswordSuccess()]),
        catchError((message: string) =>
          concat(
            of(userActions.requestOneTimePasswordFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const requestPhoneOTPEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.requestPhoneOTP.match),
    switchMap(({payload}) =>
      from(AuthService.requestOtpCode(payload)).pipe(
        mergeMap(() => [userActions.requestOneTimePasswordSuccess()]),
        catchError((message: string) =>
          concat(
            of(userActions.requestOneTimePasswordFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const changePhoneVerificationStageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.changePhoneVerificationStage.match),
    switchMap(({payload: {stage, cb}}) =>
      from(
        SocialOnboardingService.changeOnboardingStatusOrStage({
          onboardStage: stage,
        }),
      ).pipe(
        concatMap(({data: {message: member}}) => {
          if (
            member?.paymentPlan === StripePlanNames.together ||
            (member?.accountType === UserAccountType.scale &&
              member?.digitalPracticeAccountType ===
                DigitalPracticeAccountTypes.insurance)
          ) {
            cb(SliceStatus.resolved, true);
          } else {
            cb(SliceStatus.resolved, false);
          }
          return [userActions.setUser(member)];
        }),
        catchError((message: string) => {
          cb(SliceStatus.rejected, false);

          return concat(
            of(userActions.setAsyncError({filter: 'user', message})),
          );
        }),
      ),
    ),
  );

export const updatePhoneAndrequestOneTimePasswordEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updatePhoneAndrequestOneTimePassword.match),
    switchMap(({payload: {phoneNumber}}) =>
      from(AuthService.updatePhoneAndResendOtpCode(phoneNumber)).pipe(
        mergeMap(() => [
          userActions.checkSession(UserRoles.member),
          userActions.updatePhoneAndrequestOneTimePasswordSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.updatePhoneAndrequestOneTimePasswordFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const updatePhoneAndrequestOneTimePasswordFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updatePhoneAndrequestOneTimePasswordFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: i18next.t('phoneVerificationStatus.updatingPhoneNumFailed'),
      });
    }),
    ignoreElements(),
  );

export const verifyOneTimePasswordSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.verifyOneTimePasswordSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('phoneVerificationStatus.verificationSuccessful'),
      });
    }),
    ignoreElements(),
  );

export const verifyOneTimePasswordFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.verifyOneTimePasswordFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: i18next.t('phoneVerificationStatus.verificationFailed'),
      });
    }),
    ignoreElements(),
  );

export const verifyOneTimePasswordEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.verifyOneTimePassword.match),
    switchMap(({payload: {verificationCode, newPhoneNumber, onboardStage}}) =>
      from(
        AuthService.verifyOtpCode(
          verificationCode,
          newPhoneNumber,
          onboardStage,
        ),
      ).pipe(
        mergeMap(({data: {message: user}}) => {
          if (newPhoneNumber.startsWith('+')) {
            return [userActions.verifyOneTimePasswordSuccess()];
          }
          return [
            userActions.checkSession(user.role),
            userActions.verifyOneTimePasswordSuccess(),
            userActions.setSocialOnboardStage(2),
          ];
        }),
        catchError((message: string) =>
          concat(
            of(userActions.verifyOneTimePasswordFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const phoneVerificationEpics = [
  requestOneTimePasswordEpic,
  updatePhoneAndrequestOneTimePasswordEpic,
  verifyOneTimePasswordEpic,
  verifyOneTimePasswordSuccessEpic,
  verifyOneTimePasswordFailureEpic,
  updatePhoneAndrequestOneTimePasswordFailureEpic,
  changePhoneVerificationStageEpic,
  requestPhoneOTPEpic,
];
