import {AppActions, RootState} from 'app/rootReducer';
import {FirebaseRefs} from 'definitions/Firebase';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {combineLatest, from, Observable, of} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {createObservableFromFirebase, isMember} from 'utils';

import {videoActions} from '../videoSlice';

// TODO: Change videoCall ref to participantJoined

export const emitVideoPresenceNotificationEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: Observable<firebase.app.App>}
> = (action$, _state$, {firebase}) =>
  action$.pipe(
    filter(videoActions.emitVideoPresenceNotification.match),
    withLatestFrom(_state$),
    filter(([, state]) => isMember(state.user.current)),
    mergeMap(([action]) =>
      combineLatest([firebase, from([action.payload])]).pipe(
        map(([firebaseApp, payload]) => ({
          firebaseApp,
          ...payload,
        })),
      ),
    ),
    mergeMap(({firebaseApp, patientId, providerId, value}) => {
      const ref = firebaseApp
        .database()
        .ref(FirebaseRefs.videoWaitingRoom)
        .child(providerId)
        .child(patientId);
      return createObservableFromFirebase(ref.set(value)).pipe(
        map(val => userActions.logData(val)),
        catchError(err => of(userActions.logData(err))),
      );
    }),
  );

export const emitAdmitRequestNotificationEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: Observable<firebase.app.App>}
> = (action$, _state$, {firebase}) =>
  action$.pipe(
    filter(videoActions.emitAdmitRequestNotification.match),
    mergeMap(action =>
      combineLatest([firebase, from([action.payload])]).pipe(
        map(([firebaseApp, payload]) => ({
          firebaseApp,
          ...payload,
        })),
      ),
    ),
    mergeMap(({firebaseApp, patientId, value}) => {
      const ref = firebaseApp
        .database()
        .ref(FirebaseRefs.videoAdmitRequest)
        .child(patientId);
      return createObservableFromFirebase(ref.set(value)).pipe(
        map(val => userActions.logData(val)),
        catchError(err => of(userActions.logData(err))),
      );
    }),
  );

export const videoCallNotificationsEpics = [
  emitVideoPresenceNotificationEpic,
  emitAdmitRequestNotificationEpic,
];
