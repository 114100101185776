import {
  Attachment,
  CometChatMessageType,
  CometConversationTypes,
  CometFriend,
  ConversationWith,
  FileOptions,
  MessageType,
  PrescriberProfile,
  TherapistProfile,
  UnreadConversation,
} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {CometChat} from '@cometchat-pro/chat';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

import User = CometChat.User;

const MESSAGES_LIMIT = 30;
const USERS_LIMIT = 30;

let usersRequest: CometChat.UsersRequest | undefined;
let recentUsersDict = {} as {[key: string]: CometChat.Conversation};

const getCometLoggedInUser = async () => {
  try {
    const res = await CometChat.getLoggedinUser();
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getLastConversation = async (uid: string) => {
  try {
    const res = await CometChat.getConversation(uid, 'user');
    return mapConversation(res);
  } catch (error) {
    return {
      unreadMessageCount: 0,
      lastMessage: {} as {
        id: string;
        text: string;
        sentAt: number;
        readAt: number | undefined;
        sender: ConversationWith;
        type: MessageType;
        attachments: Attachment[] | undefined;
        metadata: FileOptions | undefined;
      },
    };
  }
};

const mapConversation = (res: CometChat.Conversation) => {
  const {
    unreadMessageCount,
    lastMessage: {
      text,
      sentAt,
      readAt,
      deliveredAt,
      sender,
      type,
      id,
      data: {attachments, metadata},
    },
  } = res as unknown as CometConversationTypes;
  return {
    unreadMessageCount,
    lastMessage: {
      text,
      sentAt,
      readAt,
      deliveredAt,
      sender,
      type,
      attachments,
      metadata,
      id,
    },
  };
};

const getUserList = async (next = false) => {
  try {
    let recentUsers: CometFriend[] = [];

    if (!next) {
      recentUsers = await getRecentUserConversations();
      recentUsersDict = recentUsers.reduce(
        (dict, user) => (dict[user.uid] = user && dict),
        {},
      );
    }

    usersRequest =
      next && usersRequest
        ? usersRequest
        : new CometChat.UsersRequestBuilder()
            .setLimit(USERS_LIMIT)
            .friendsOnly(true)
            .build();

    const users: User[] = (await usersRequest.fetchNext()).filter(
      user => !recentUsersDict[user.getUid()],
    );
    const conversations = await Promise.all(
      users.map(user => getLastConversation(user.getUid())),
    );
    const otherUsers = users.map((user, idx) => ({
      ...(user as unknown as CometFriend),
      ...conversations[idx],
    }));

    return [...recentUsers, ...otherUsers];
  } catch (error) {
    console.log('User list fetching failed:', error);
    throw error;
  }
};

const getRecentUserConversations = async () => {
  try {
    const conversationRequest = new CometChat.ConversationsRequestBuilder()
      .setLimit(USERS_LIMIT)
      .setConversationType('user')
      .build();

    let conversations: CometChat.Conversation[] =
      await conversationRequest.fetchNext();
    if (!conversations.length) {
      return [];
    }

    const unreadConversations = (await getUnreadConversations()).data.message;
    const recentConversations = conversations.reduce(
      (dict, c) => (dict[c.getConversationId()] = c && dict),
      {},
    );

    conversations = [
      ...conversations,
      ...unreadConversations
        .filter(u => !recentConversations[u.conversationId])
        .map(
          u =>
            new CometChat.Conversation(
              u.conversationId,
              u.conversationType,
              u.lastMessage,
              u.conversationWith,
              u.unreadMessageCount,
              u.tags,
            ),
        ),
    ];

    return conversations.map(c => ({
      ...(c.getConversationWith() as unknown as CometFriend),
      ...mapConversation(c),
    }));
  } catch (error) {
    console.log('User list fetching failed:', error);
    throw error;
  }
};

/**
 * @param keyword search text i.e. string
 * @param next
 */
const searchFriends = async (keyword: string, next = false) => {
  const searchIn: Array<String> = ['name'];
  const sortBy: string = 'name';

  try {
    usersRequest =
      next && usersRequest
        ? usersRequest
        : new CometChat.UsersRequestBuilder()
            .setLimit(USERS_LIMIT)
            .setSearchKeyword(keyword)
            .searchIn(searchIn)
            .sortBy(sortBy)
            .friendsOnly(true)
            .build();

    const users = (await usersRequest.fetchNext()) as unknown as CometFriend[];

    const conversations = await Promise.all(
      users.map(user => getLastConversation(user.uid)),
    );
    return users.map((user, idx) => ({
      ...user,
      ...conversations[idx],
      isSearched: true,
    }));
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param receiverUID participant uid i.e. string
 * @param messageText i.e. string
 * @returns
 */
const sendTextMessage = async (receiverUID: string, messageText: string) => {
  const receiverType = CometChat.RECEIVER_TYPE.USER;
  const textMessage = new CometChat.TextMessage(
    receiverUID,
    messageText,
    receiverType,
  );
  try {
    const message = (await CometChat.sendMessage(
      textMessage,
    )) as unknown as CometChatMessageType;

    return message;
  } catch (error) {
    console.log('Message sending failed:', error);
    throw error;
  }
};

/**
 *
 * @param participantUID comet uid of the participant/contact/user
 * @returns
 */
const getMediaMessageHistory = async (participantUID: string) => {
  try {
    const categories: Array<String> = ['message', 'custom'];
    const types: Array<String> = ['image', 'video', 'audio', 'file'];
    const messagesRequest: CometChat.MessagesRequest =
      new CometChat.MessagesRequestBuilder()
        .setUID(participantUID!)
        .setCategories(categories)
        .setTypes(types)
        .setLimit(MESSAGES_LIMIT)
        .build();

    const res =
      (await messagesRequest.fetchPrevious()) as unknown as CometChatMessageType[];
    if (res.length < MESSAGES_LIMIT) {
      return {
        hasMore: false,
        lastMessageId: '',
        data: res.reverse(),
      };
    } else {
      return {
        hasMore: true,
        lastMessageId: (res[0] as any).id,
        data: res.reverse(),
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const getMoreMediaMessageHistory = async (
  participantUID: string,
  lastMessageId: string,
) => {
  try {
    const categories: Array<String> = ['message', 'custom'];
    const types: Array<String> = ['image', 'video', 'audio', 'file'];
    const messagesRequest: CometChat.MessagesRequest =
      new CometChat.MessagesRequestBuilder()
        .setUID(participantUID!)
        .setMessageId(Number(lastMessageId))
        .setCategories(categories)
        .setTypes(types)
        .setLimit(MESSAGES_LIMIT)
        .build();
    const res =
      (await messagesRequest.fetchPrevious()) as unknown as CometChatMessageType[];
    if (res.length && res.length < MESSAGES_LIMIT) {
      return {
        hasMore: false,
        lastMessageId: '',
        data: res,
      };
    } else if (res.length) {
      return {
        hasMore: true,
        lastMessageId: (res[res.length - 1] as any).id,
        data: res,
      };
    } else {
      return {
        hasMore: false,
        lastMessageId: '',
        data: [],
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getMessageHistory = async (participantUID: string) => {
  try {
    const messagesRequest = new CometChat.MessagesRequestBuilder()
      .setUID(participantUID!)
      .setLimit(MESSAGES_LIMIT)
      .build();

    const res =
      (await messagesRequest.fetchPrevious()) as unknown as CometChatMessageType[];

    markAsRead(res, participantUID);
    if (res.length < MESSAGES_LIMIT) {
      return {
        hasMore: false,
        lastMessageId: '',
        data: res.reverse(),
      };
    } else {
      return {
        hasMore: true,
        lastMessageId: (res[0] as any).id,
        data: res.reverse(),
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
/**
 *
 * @param participantUID comet uid of the participant/contact/user
 * @param lastMessageId id of the last message
 * @returns
 */
const getMoreMessageHistory = async (
  participantUID: string,
  lastMessageId: string,
) => {
  try {
    const messagesRequest = new CometChat.MessagesRequestBuilder()
      .setUID(participantUID!)
      .setMessageId(Number(lastMessageId))
      .setLimit(MESSAGES_LIMIT)
      .build();
    const res =
      (await messagesRequest.fetchPrevious()) as unknown as CometChatMessageType[];
    markAsRead(res, participantUID);
    if (res.length && res.length < MESSAGES_LIMIT) {
      return {
        hasMore: false,
        lastMessageId: '',
        data: res.reverse(),
      };
    } else if (res.length) {
      return {
        hasMore: true,
        lastMessageId: (res[0] as any).id,
        data: res.reverse(),
      };
    } else {
      return {
        hasMore: false,
        lastMessageId: '',
        data: [],
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 *
 * @param messages Array of message
 */
const markAsRead = (
  messages: CometChatMessageType[],
  participantUID: string,
) => {
  messages.forEach((message: any) =>
    message.receiverId === participantUID ||
    (message?.readAt && typeof message.readAt === 'number')
      ? null
      : CometChat.markAsRead(message),
  );
};

/**
 *
 * @param messages Array of message
 */
const markAsDelivered = (message: CometChatMessageType) => {
  CometChat.markAsDelivered(
    message.id,
    message.receiver.uid,
    message.receiverType,
    message.sender.uid,
  ).then(
    () => {
      console.log('mark as delivered success.');
    },
    (error: CometChat.CometChatException) => {
      console.log(
        'An error occurred when marking the message as delivered.',
        error,
      );
    },
  );
};

const getUnreadMessageCount = async () => {
  try {
    const res = await CometChat.getUnreadMessageCountForAllUsers();

    return Object.values(res)[0] || 0;
  } catch (error) {
    console.log('getAllUnreadMessagesCount error', error);
    throw error;
  }
};

const logOutUserOnCometChat = async () => {
  try {
    await CometChat.logout();
    return;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const reactivateCometUser = async (): Promise<
  Response<{message: TherapistProfile | PrescriberProfile}>
> => {
  try {
    const res = await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.PUT,
      url: EndPoints.ReactivateCometUser,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getUnreadConversations = async (): Promise<
  Response<{message: UnreadConversation[]}>
> => {
  try {
    const res = await requestHandler<{
      message: UnreadConversation[];
    }>({
      method: HttpMethods.GET,
      url: EndPoints.UnreadConversations,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const deactivateCometUser = async (): Promise<
  Response<{message: TherapistProfile | PrescriberProfile}>
> => {
  try {
    const res = await requestHandler<{
      message: TherapistProfile | PrescriberProfile;
    }>({
      method: HttpMethods.DELETE,
      url: EndPoints.DeactivateCometUser,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendMediaMessage = async (
  receiverUID: string,
  attachment: File,
  options: FileOptions,
) => {
  let _fileType;

  if (options.type === 'image') _fileType = CometChat.MESSAGE_TYPE.IMAGE;
  else if (options.type === 'video') _fileType = CometChat.MESSAGE_TYPE.VIDEO;
  else if (options.type === 'audio') _fileType = CometChat.MESSAGE_TYPE.AUDIO;
  else _fileType = CometChat.MESSAGE_TYPE.FILE;

  const receiverType: string = CometChat.RECEIVER_TYPE.USER;
  const mediaMessage = new CometChat.MediaMessage(
    receiverUID,
    attachment,
    _fileType,
    receiverType,
  );
  try {
    mediaMessage.setMetadata(options);
    const message = (await CometChat.sendMessage(
      mediaMessage,
    )) as unknown as CometChatMessageType;
    markAsDelivered(message);
    return message;
  } catch (error) {
    console.log('Media Message Error', error);
    throw error;
  }
};

const getUnreadMessages = (uid: string) => {
  const messagesRequest: CometChat.MessagesRequest =
    new CometChat.MessagesRequestBuilder()
      .setUID(uid)
      .setUnread(true)
      .setLimit(100)
      .build();

  messagesRequest.fetchPrevious().then(
    (messages: CometChat.BaseMessage[]) => {
      const res = messages as unknown as CometChatMessageType[];

      res.forEach(message => {
        markAsDelivered(message);
      });
    },
    (error: CometChat.CometChatException) => {
      console.log('Message fetching failed with error:', error);
    },
  );
};

export const MessagingService = {
  getCometLoggedInUser,
  getLastConversation,
  getUserList,
  searchFriends,
  getMessageHistory,
  getMediaMessageHistory,
  getMoreMediaMessageHistory,
  getMoreMessageHistory,
  getUnreadMessages,
  getUnreadMessageCount,
  markAsDelivered,
  markAsRead,
  sendTextMessage,
  sendMediaMessage,
  logOutUserOnCometChat,
  reactivateCometUser,
  deactivateCometUser,
};
