export function isProviderProfilePage(pathname: string) {
  return [
    '/provider/profile',
    '/provider/profile/details',
    '/provider/profile/in-network',
    '/provider/profile/settings',
    '/provider/profile/revenue',
    '/provider/profile/receipts',
    '/provider/profile/resource-center',
  ].includes(pathname);
}
