import {useEffect} from 'react';
import {InsuranceImageSchema} from 'definitions/Yup';
import {paymentActions} from 'features/Payment';
import {InsuranceImageFormData, SliceStatus} from 'interfaces';
import {Control, FieldErrorsImpl, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';

import {yupResolver} from '@hookform/resolvers/yup';

import {useError} from './useError';
import {useRequesting} from './useRequesting';

export function useInsuranceImage(fromSocialOnboarding = false): {
  control: Control<InsuranceImageFormData>;
  onSubmit: any;
  isLoading: boolean;
  apiError: string;
  errors: FieldErrorsImpl<{
    insuranceImageFront: File[];
    insuranceImageBack: File[];
  }>;
  setValue: any;
  watch: any;
} {
  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm<InsuranceImageFormData>({
    defaultValues: {insuranceImageFront: [], insuranceImageBack: []},
    resolver: yupResolver(InsuranceImageSchema),
  });

  const dispatch = useDispatch();

  const onSubmit = handleSubmit(values => {
    dispatch(
      paymentActions.saveInsuranceImage({
        insuranceImageData: values,
        onboardStage: 6,
        fromSocialOnboarding,
      }),
    );
  });

  const sliceStatus = useRequesting('payment');
  const isPending = sliceStatus === SliceStatus.pending;
  const {apiError, resetAsyncError} = useError('payment');

  useEffect(() => {
    return (): void => {
      if (apiError) dispatch(resetAsyncError('payment'));
    };
  }, [apiError, dispatch, resetAsyncError]);

  return {
    control,
    onSubmit,
    errors,
    isLoading: isPending,
    apiError,
    setValue,
    watch,
  };
}
