/* eslint-disable import/no-duplicates */
import {format as formatDate, isDate, setDefaultOptions} from 'date-fns';
import {enUS, es, pt} from 'date-fns/locale';
import dayjs from 'dayjs';
import i18n from 'i18next';
import LanguageDetector, {
  CustomDetector,
} from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import Cookies from 'js-cookie';
import {initReactI18next} from 'react-i18next';
import {getLanguageFromCountry} from 'utils';

// eslint-disable-next-line
import pkg from '../../package.json';

import {store} from './store';

import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';

const dateFnsLocales = {en: enUS, es, pt};

const getLanguageFromStore = () => {
  const preferredLanguage = store.getState()?.user?.current?.preferredLanguage;
  const cookieLanguage = Cookies.get('preferredLanguage');
  const countryOfResidence =
    store.getState()?.user?.current?.countryOfResidence;

  if (preferredLanguage) {
    return preferredLanguage;
  }
  if (countryOfResidence?.code) {
    const lang = getLanguageFromCountry(countryOfResidence.code);
    return lang;
  }
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const browserLanguage = navigator.language;
  if (browserLanguage) {
    const [lng] = browserLanguage.split('-');
    if (lng) return lng;
  }
  return 'en';
};

const storeLanguageDetector: CustomDetector = {
  name: 'store',
  lookup() {
    const detectedLanguage = getLanguageFromStore();
    if (detectedLanguage) {
      dayjs.locale(detectedLanguage);
      setDefaultOptions({
        locale: dateFnsLocales[detectedLanguage],
      });
    }

    return detectedLanguage;
  },
  cacheUserLanguage: () => {},
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(storeLanguageDetector);

export const initializeI18next = () =>
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(languageDetector)
    .use(HttpApi)
    .init(
      {
        saveMissing: true,
        fallbackLng: 'en',
        detection: {
          // order and from where user language should be detected
          order: ['store', 'cookie', 'sessionStorage'],

          // cache user language on
          caches: ['cookie', 'sessionStorage'],

          // optional expire and domain for set cookie
          cookieDomain: 'myDomain',
          // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
          cookieOptions: {path: '/', sameSite: 'strict'},
        },
        backend: {
          loadPath: `/lang/locales/{{lng}}/translation.json?v=${pkg.version}`,
        },
        interpolation: {
          escapeValue: false,
          format: (value, format, lng) => {
            if (lng && format && isDate(value)) {
              const locale = dateFnsLocales[lng];
              return formatDate(value, format, {locale});
            }

            return value;
          },
        },
      },
      err => {
        if (err) return console.error(err);
        console.log('i18n initialized');
      },
    );
