import styled from 'styled-components';

export const Content = styled.div<{
  modalType: 'normal' | 'release_notes';
  maxHeight?: string;
}>`
  ${props => (props.maxHeight ? `max-height: ${props.maxHeight}` : '')};
  ${props =>
    props.modalType === 'release_notes' &&
    `ol {
    margin: 8px 0px;
    padding-top: 5px;
    margin-left: 1.5rem;
  }

  ol > li > p {
    font-weight: bold;
    color: #1d1e1f;
  }

  ol > li > ol > li {
    list-style: disc;
    color: #5c5c5c;
    margin-bottom: 0.5rem;
  }

  #footer {
    margin-top: 3rem;
  }

  p {
    margin-bottom: 0.3rem;
  }

  #footer p {
    font-size: 0.875;
    margin-bottom: 0.625rem;
  }

  #team {
    font-weight: 600;
    text-align: center;
    margin-top: 24px;
  }`}
`;
