import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import i18next from 'i18next';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {RatingService} from 'services';

import {ratingActions} from './ratingSlice';

export const submitRatingEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(ratingActions.submitRatingRequest.match),
    switchMap(({payload}) =>
      from(RatingService.submitRatingDetails(payload)).pipe(
        mergeMap(({data: {message}}) => {
          Toast({type: 'success', message: i18next.t(message)});
          return concat(of(ratingActions.submitRatingRequestSuccess(message)));
        }),
        catchError((message: string) => {
          Toast({type: 'error', message: i18next.t(message)});
          return concat(of(ratingActions.submitRatingRequestFailure(message)));
        }),
      ),
    ),
  );

export const ratingEpics = [submitRatingEpic];
