import {countriesTimeZones} from 'definitions/SupportedCountries';
import {replaceAllCharacters} from 'utils';

type Zone = {
  region: string;
  zone: string;
  id: string;
  sort: string;
  item: {
    offset: string;
    text: string;
  };
};

export const getTimezoneOptions = ({
  region,
  zone,
}: {
  region: string;
  zone: string;
}): {
  text: string;
  offset: string;
} => {
  return {
    text: `${zone} - ${region}`,
    offset: '',
  };
};

export const getTimezonesArray = (countryCode: string): Zone[] => {
  const zones = countriesTimeZones[countryCode].timezones
    .map((identifier: string) => {
      const timezone = identifier['timezone'];
      const array = timezone.split('/');
      const region = array[0];
      const zone = replaceAllCharacters(array[array.length - 1], '_', ' ');
      const dropdownValues = getTimezoneOptions({
        region,
        zone,
      });
      /*
          id needs to be in this format:
          https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        */
      return {
        region,
        zone,
        id: timezone,
        sort: dropdownValues.text,
        item: dropdownValues,
      };
    })
    .sort((a: any, b: any) => {
      let sort = 0;

      if (a.sort < b.sort) {
        sort = -1;
      }

      if (a.sort > b.sort) {
        sort = 1;
      }

      return sort;
    });

  return zones;
};
