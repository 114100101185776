import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {bookOneTimeAppointment} from 'features/Appointment/Booking/bookingActions';
import {checkout} from 'features/Payment/Checkout/checkoutActions';
import {onboardingOneTimePayment} from 'features/Payment/OnboardingOneTimePayment/onboardingOneTimePaymentActions';
import {
  AppointmentTypes,
  StripePlanDurations,
  StripePlanNames,
} from 'interfaces';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {getPlanNameFromLocation} from 'services/Stripe/helpers';

import {
  CardElementComponent,
  CardNumberElement,
  CardNumberElementComponent,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import {useError} from './useError';
import {useQuery} from './useQuery';
import {useToggle} from './useToggle';

export function useCheckout(
  duration: StripePlanDurations,
  appointmentData?: {
    appointmentDateTimeString: string;
    providerId: string;
    appointmentType: AppointmentTypes;
    patientTimezone: string;
    calendarId: number;
    appointmentTypeID: string;
  },
  isOneTimeAppointment?: boolean,
  providerType: 'doctor' | 'therapist' = 'therapist',
  cardElementType:
    | CardNumberElementComponent
    | CardElementComponent = CardNumberElement,
): {
  errorMsg: string;
  resetErrorMsg: () => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  isCreatingToken: boolean;
  couponCode: string;
  handleCouponChange: (event: ChangeEvent<HTMLInputElement>) => void;
  cardHolderName: string;
  handleCardHolderNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
} {
  const [couponCode, setCouponCode] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const {toggleOn, onToggleClick} = useToggle();
  const [errorMsg, setErrorMsg] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const referralCode = useQuery().get('referral') || undefined;
  const location = useLocation();
  const planType = getPlanNameFromLocation(location.pathname);

  const {apiError, resetAsyncError} = useError('payment');

  const resetErrorMsg = () => {
    if (errorMsg) {
      setErrorMsg('');
    }
  };
  const handleCouponChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    resetErrorMsg();
    setCouponCode(e.currentTarget.value);
  };
  const handleCardHolderNameChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => setCardHolderName(e.currentTarget.value);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    // Abort if form isn't valid

    if (apiError) dispatch(resetAsyncError('payment'));
    resetErrorMsg();
    if (!e.currentTarget.reportValidity()) return;
    onToggleClick();
    const cardElement = elements?.getElement(cardElementType as any);
    if (cardElement && stripe) {
      const stripeResponse = await stripe.createToken(cardElement as any, {
        name: cardHolderName,
      });
      if (stripeResponse?.error || !stripeResponse?.token) {
        setErrorMsg(
          stripeResponse.error?.message ?? 'Failed to generate token',
        );
      } else {
        if (
          providerType &&
          /(doctor|therapist)/.test(providerType) &&
          isOneTimeAppointment
        ) {
          await dispatch(
            onboardingOneTimePayment({
              providerType,
              token: stripeResponse.token,
            }),
          );
        } else if (isOneTimeAppointment && appointmentData) {
          await dispatch(
            bookOneTimeAppointment({
              ...appointmentData,
              token: stripeResponse.token,
            }),
          );
        } else {
          await dispatch(
            checkout({
              stripeResponse,
              couponCode,
              referralCode,
              paymentPlan: StripePlanNames[planType],
              paymentPlanDuration: duration,
              appointmentData,
            }),
          );
        }
      }
    }
    onToggleClick();
  };

  useEffect(() => {
    setErrorMsg(apiError);
    return (): void => {
      if (apiError) dispatch(resetAsyncError('payment'));
    };
  }, [apiError, dispatch, resetAsyncError]);

  return {
    isCreatingToken: toggleOn,
    errorMsg,
    resetErrorMsg,
    handleSubmit,
    couponCode,
    handleCouponChange,
    cardHolderName,
    handleCardHolderNameChange,
  };
}
