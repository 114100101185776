import {OptionType, PrescriberProfile, TherapistProfile} from 'interfaces';

export enum DigitalPracticeAccountTypes {
  outOfPocket = 'out_of_pocket',
  insurance = 'insurance',
}

export enum ProviderLicenseCategory {
  associate = 'associate',
  licensed = 'licensed',
}

export enum ProviderRegistrationSource {
  admin = 'admin',
  provider = 'provider',
}

export type DigitalPracticeProvider = (PrescriberProfile | TherapistProfile) & {
  approvedInNetworkInsurances: string[];
};

// Digital practice onboarding types & constants
export type EmployerType = {
  value: string;
  label: string;
};

export type PlansType = {
  insurance: boolean;
  employer: boolean;
};

export type DataType = {
  type: string;
  planName: string;
};

export type BrandsLogoType = {
  name: string;
  filename: string;
};

export type VisibleSlidesType = {
  slideIndex?: number;
  isFullyVisible?: boolean;
};

export type SlideStateType = {
  visibleSlides?: VisibleSlidesType[];
  isFirstSlideVisible?: boolean;
  isLastSlideVisible?: boolean;
};

export type FormData = {
  insurance: string;
  employer: string;
};

// constant
export enum OptionNames {
  employer = 'eap',
  insurance = 'in-network',
}

export interface ResultEmployersType {
  result: OptionType[];
  offset?: string;
  hasMore: boolean;
}
