import React, {useEffect, useState} from 'react';
import {Button} from 'components/Basic/Button';
import {LoadingSpinner} from 'components/Basic/Loader';
import {useCalendarLocalization} from 'hooks/useCalendarLocalization';
import DayPicker, {DayModifiers} from 'react-day-picker';
import {Modifier, Modifiers} from 'react-day-picker/types/Modifiers';

import {detachedSearchBarStyles} from '../styles';

import {ChevronDown} from './ChevronDown';
import {DropdownContainer} from './DropdownContainer';

export const DetachedDateSelect = ({
  placeholder,
  onChange,
  styles = detachedSearchBarStyles,
  isDisabled,
  styledContainer: StyledContainer,
  onMenuOpen,
  ...props
}: {
  placeholder?: string;
  onChange?: (day: Date, modifiers: DayModifiers) => void;
  styles?: any;
  disabledDays?: Modifier | Modifier[];
  month?: Date;
  fromMonth?: Date;
  isDisabled?: boolean;
  toMonth?: Date;
  modifiers?: Partial<Modifiers>;
  onBlur?: Function;
  selectedDays?: Modifier | Modifier[];
  value?: Date;
  styledContainer: ({
    children,
  }: {
    children: React.ReactNode;
  }) => React.ReactElement;
  onMonthChange?: (month: Date) => void;
  onMenuOpen?: (isOpen: boolean) => void;
  isLoading?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(props?.value);
  const {months, weekdaysLong, weekdaysShort, locale, firstDayOfWeek} =
    useCalendarLocalization();

  useEffect(() => {
    setValue(props?.value);
  }, [props?.value]);

  const toggleOpen = () => setIsOpen(prev => !prev);

  const onClose = () => {
    toggleOpen();
  };

  const hasValue = () => !!value;

  const getButtonText = () => {
    if (!hasValue()) {
      return placeholder ?? '';
    }

    return value?.toLocaleDateString(locale, {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };

  useEffect(() => {
    onMenuOpen?.(isOpen);
  }, [isOpen]);

  return (
    <DropdownContainer
      isOpen={isOpen}
      onClose={onClose}
      placeholder={placeholder}
      styles={styles}
      target={
        <Button
          onClick={toggleOpen}
          btnType="custom"
          bgColor="white"
          borderRadius="xl"
          disabled={isDisabled}
          textColor="black"
          style={styles.detachedButton?.(isOpen, hasValue())}
        >
          <span className="truncate">{getButtonText()}</span> <ChevronDown />
        </Button>
      }
    >
      <StyledContainer>
        {props.isLoading ? (
          <div className="w-full h-full bg-white bg-opacity-70 z-999 absolute top-0 left-0 flex flex-col justify-center items-center">
            <LoadingSpinner type="Oval" width={20} height={20} />
          </div>
        ) : null}
        <DayPicker
          showOutsideDays
          month={props.month}
          fromMonth={props.fromMonth}
          disabledDays={props.disabledDays}
          toMonth={props.toMonth}
          onDayClick={(day, modifiers) => {
            if (modifiers.disabled || modifiers.selected) {
              return;
            }
            onChange?.(day, modifiers);
            setValue(day);
            toggleOpen();
          }}
          modifiers={props.modifiers}
          onBlur={() => props.onBlur?.()}
          selectedDays={value as Modifier}
          months={months}
          weekdaysLong={weekdaysLong}
          weekdaysShort={weekdaysShort}
          locale={locale}
          firstDayOfWeek={firstDayOfWeek}
          onMonthChange={props.onMonthChange}
        />
      </StyledContainer>
    </DropdownContainer>
  );
};
