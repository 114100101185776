import React from 'react';

// Undo and redo functions for Custom Toolbar
function undoChange() {
  // quill.history.undo();
}
function redoChange() {
  // quill.history.redo();
}

// Modules object for setting up the Quill editor
export const modules = (toolbarId: string) => ({
  toolbar: {
    container: '#' + toolbarId,
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
});

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

// Quill Toolbar component
export const QuillToolbar = ({id = 'toolbar'}: {id: string}) => (
  <div className="relative" id={id}>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-strike" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <select className="ql-color" />
    {/* <button className="ql-code-block" /> */}
  </div>
);

export default QuillToolbar;
