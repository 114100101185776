import {CurrencyRate, EndPoints, HttpMethods} from 'interfaces';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';

const getCurrencyRateByCode = async (code: string): Promise<CurrencyRate> => {
  try {
    const res = await requestHandler<{
      message: CurrencyRate;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.CurrencyRateByCode}/${code}` as unknown as EndPoints,
    });

    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const CurrencyService = {
  getCurrencyRateByCode,
};
