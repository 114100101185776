import React, {FC, memo} from 'react';
import classNames from 'classnames';
import useAudioPlayer from 'hooks/useAudioPlayer';
import {Attachment} from 'interfaces';
import Slider from 'rc-slider';
import styled from 'styled-components';

import {PauseIcon, PlayIcon} from '../SvgIcon';

import 'rc-slider/assets/index.css';

const SeekBar = styled(Slider)<{isFullScreen: boolean}>`
  .rc-slider-handle {
    width: 4px !important;
    height: 4px !important;
    margin-top: 0px !important;
    border: none !important;
    opacity: 1;
  }
`;

const _AudioPlayer: FC<{
  audioData: Attachment;
  isSelf?: boolean;
}> = memo(({audioData, isSelf}) => {
  const {playing, setPlaying, duration, curTime} = useAudioPlayer(
    audioData.url,
  );

  const toggleAction = () => {
    setPlaying(!playing);
  };
  const color = isSelf ? 'text-gray-50' : 'text-gray-500';

  const onChange = (tm: number | number[]) => {
    const audio = document.getElementById(audioData.url) as HTMLAudioElement;
    if (audio && tm) {
      audio.currentTime = tm as number;
    }
  };

  return (
    <article className="flex w-full max-w-[270px]">
      <article
        className={classNames('p-3 flex items-center justify-center', {
          'bg-blue-300 bg-opacity-20': isSelf,
          'bg-gray-100': !isSelf,
        })}
      >
        <audio id={audioData.url}>
          <source src={audioData.url} />
          Your browser does not support the <code>audio</code> element.
        </audio>
        {playing ? (
          <button onClick={toggleAction}>
            <PauseIcon classes={`fill-current ${color}`} />
          </button>
        ) : (
          <button onClick={toggleAction}>
            <PlayIcon classes={`fill-current ${color}`} />
          </button>
        )}
      </article>

      <div className="relative">
        <p className="text-sm p-2">{audioData.name}</p>
        {playing || curTime > 0 ? (
          <SeekBar
            min={1}
            max={duration}
            value={curTime}
            onChange={onChange}
            trackStyle={{
              background: isSelf ? '#ececec' : '#333',
              height: 4,
              borderRadius: 0,
            }}
            railStyle={{
              height: 4,
              borderRadius: 0,
              background: isSelf ? '#e9e9e985' : '#ccc',
            }}
            handleStyle={{
              background: isSelf ? '#999' : '#eee',
              width: 4,
              height: 4,
            }}
            draggableTrack
            isFullScreen={false}
            step={0.01}
            style={{padding: 0, height: 4, position: 'absolute', bottom: 0}}
          />
        ) : null}
      </div>
    </article>
  );
});

export const AudioPlayer = _AudioPlayer;
