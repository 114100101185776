import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {VideoState} from './videoSlice';

function getVideo(state: RootState): VideoState {
  return state.video;
}

export const selectVideoParticipantJoined = createSelector(
  getVideo,
  video => video.participantJoined,
);

export const selectVideoAdmissionStatus = createSelector(
  getVideo,
  video => video.admissionStatus,
);

export const selectRateVideo = createSelector(
  getVideo,
  video => video.rateVideoCall,
);
