import React from 'react';

import {SvgIcon} from './SvgIcon';

export const Progress: SvgIcon = ({width = 20, height = 20}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M106.585 37.5377V15.8371C106.585 9.12962 101.456 4 94.7483 4L15.8371 4.00121C9.12962 4.00121 4 9.13084 4 15.8383V94.7495C4 101.457 9.12962 106.587 15.8371 106.587H41.4828"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.6191 39.5127H78.9659"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.6191 63.1865H55.2925"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M82.5172 112.106L67.1295 115.263C64.3673 115.658 61.6061 113.29 62.3944 110.528L65.5514 95.1404L101.456 59.2362C103.034 57.6583 105.401 57.6583 106.979 59.2362L118.816 71.0732C120.394 72.6512 120.394 75.0186 118.816 76.5966L82.5172 112.106Z"
      stroke="#D9D9D9"
      strokeWidth="7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FolderIcon: SvgIcon = ({width = 20, height = 20}) => (
  <>
    <svg
      width={width}
      height={height}
      className="text-blue hover:fill-blue hover:blue cursor-pointer"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="2.5 4.5 19 15"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.2}
        d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
      ></path>
    </svg>
  </>
);

export const RoundCheckIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <circle cx="10" cy="10" r="9.25" stroke="#1D8E13" strokeWidth="1.5" />
    <path d="M6.5 10L9 12.5L14 7.5" stroke="#1D8E13" strokeWidth="1.5" />
  </svg>
);

export const SquareDraftIcon: SvgIcon = ({
  width = 21,
  height = 20,
  strokeColor = 'text-[#FFB000]',
  strokeWidth = 0.2,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      d="M4.5 1.5H16.5C17.8807 1.5 19 2.61929 19 4V16C19 17.3807 17.8807 18.5 16.5 18.5H4.5C3.11929 18.5 2 17.3807 2 16V4C2 2.61929 3.11929 1.5 4.5 1.5ZM0.5 4C0.5 1.79086 2.29086 0 4.5 0H16.5C18.7091 0 20.5 1.79086 20.5 4V16C20.5 18.2091 18.7091 20 16.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4ZM5.95913 14.7835L6.46112 12.1602L13.4574 5.16388C14.044 4.57734 14.9943 4.57673 15.5801 5.16252C16.1659 5.74831 16.1653 6.69866 15.5788 7.2852L8.58244 14.2815L5.95913 14.7835ZM5.01586 11.7287C5.05357 11.5316 5.14962 11.3504 5.29155 11.2084L12.3968 4.10322C13.5699 2.93015 15.4706 2.92893 16.6421 4.1005C17.8137 5.27208 17.8125 7.17279 16.6394 8.34586L9.53419 15.4511C9.39226 15.593 9.211 15.6891 9.01394 15.7268L5.51619 16.3961C4.82221 16.5289 4.21373 15.9204 4.34653 15.2265L5.01586 11.7287Z"
      fill="#FFB000"
    />
  </svg>
);

export const SquareViewIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-[#C0922A]',
  strokeWidth = 0.2,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      d="M4 1.5H16C17.3807 1.5 18.5 2.61929 18.5 4V6H1.5V4C1.5 2.61929 2.61929 1.5 4 1.5ZM1.5 7.5V16C1.5 17.3807 2.61929 18.5 4 18.5H16C17.3807 18.5 18.5 17.3807 18.5 16V7.5H1.5ZM0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
      fill="#C0922A"
    />
  </svg>
);
