import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {ProviderState} from '../providerSlice';

function getProvider(state: RootState): ProviderState {
  return state.provider;
}

export const selectAllMembers = createSelector(getProvider, provider => {
  return Object.values(provider.members.members);
});

export const selectLastFetchedMembers = createSelector(
  getProvider,
  provider => {
    return Object.values(provider.members.lastFetchedMembers);
  },
);

export const selectMemberById = createSelector(
  getProvider,
  (_: RootState, patientId: string) => patientId,
  (provider, patientId) => {
    return provider.members?.members[patientId];
  },
);
export const selectHasMoreMembers = createSelector(
  getProvider,
  provider => provider?.members?.hasMore,
);

export const selectTherapyQuestionnaire = createSelector(
  getProvider,
  provider => provider?.therapyQuestionnaire,
);

export const selectTherapyQuestionnaireStatus = createSelector(
  getProvider,
  provider => provider.therapyQuestionnaireStatus,
);
