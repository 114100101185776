import {useState} from 'react';
import {DPInsuranceEmployerSchema} from 'definitions/Yup';
import {DataType, FormData, OptionNames, PlansType} from 'interfaces';
import {
  Control,
  FieldErrorsImpl,
  useForm,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';

import {yupResolver} from '@hookform/resolvers/yup';

// Toggling plans
const Plans: PlansType[] = [
  {insurance: true, employer: true},
  {insurance: true, employer: false},
  {insurance: false, employer: true},
];

export const useDigitalPracticeOnboarding = (): {
  watch: any;
  onSubmit: any;
  errors: FieldErrorsImpl<FormData>;
  control: Control<FormData>;
  setValue: UseFormSetValue<FormData>;
  reset: UseFormReset<FormData>;
  onboardingState: any;
  onSelectInsurance: () => void;
  onSelectEmployer: () => void;
  onGoBack: () => void;
} => {
  // state
  const [onboardingState, setOnboardingState] = useState({
    showOptions: true,
    menuIsOpen: false,
    selectPlan: Plans[0],
    confirmPage: false,
    formValue: {type: '', planName: ''} as DataType,
  });

  // use form
  const {
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    reset,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      insurance: '',
      employer: '',
    },
    resolver: yupResolver(DPInsuranceEmployerSchema),
  });

  // Handle form submission
  const onSubmit = handleSubmit(values => {
    try {
      setOnboardingState(prevState => ({
        ...prevState,
        menuIsOpen: false,
        showOptions: false,
        confirmPage: true,
        formValue: {
          type: values.insurance ? OptionNames.insurance : OptionNames.employer,
          planName: values.insurance ? values.insurance : values.employer,
        },
      }));
    } catch (error) {
      console.error(error);
    }
  });

  // onSelect Insurance
  const onSelectInsurance = () => {
    setOnboardingState(prevState => ({
      ...prevState,
      showOptions: false,
      selectPlan: Plans[1],
    }));
  };

  // onSelect Employer
  const onSelectEmployer = () => {
    setOnboardingState(prevState => ({
      ...prevState,
      showOptions: false,
      menuIsOpen: true,
      selectPlan: Plans[2],
    }));
  };

  // onClick Go Back Button
  const onGoBack = () => {
    setOnboardingState(prevState => ({
      ...prevState,
      showOptions: false,
      menuIsOpen: onboardingState.formValue.type !== OptionNames.insurance,
      selectPlan:
        onboardingState.formValue.type === OptionNames.insurance
          ? Plans[1]
          : Plans[2],
      confirmPage: false,
    }));
  };

  return {
    watch,
    onSubmit,
    errors,
    control,
    reset,
    setValue,
    onboardingState,
    onSelectEmployer,
    onSelectInsurance,
    onGoBack,
  };
};
