import React, {FC} from 'react';
import classnames from 'classnames';
import {BlueWhiteLogoHeader, LoadingSpinner, LogoutIcon} from 'components';
import {isProviderProfilePage} from 'features/Provider/Profile/utils';
import {selectUserProfile} from 'features/User';
import {useLogout, useUserNotifications} from 'hooks';
import {SliceStatus, UserRoles} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import styled from 'styled-components';
import {isUserFromUnitedStates} from 'utils';

import {filterNavMenuItems, NavMenuItem} from './navigationConstants';
import {NavLinkV2} from './NavLinkV2';

const NavigationContainer = styled.section`
  height: calc(100% - 5rem);
`;
export const LeftNavigationV2: FC = () => {
  const location = useLocation();
  const {pathname} = location;
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();

  const {logout, logoutStatus} = useLogout(true);

  const userRole = user?.role;
  const isUserFromUS = isUserFromUnitedStates(user);
  const filteredNavMenuItems = filterNavMenuItems(userRole!, isUserFromUS);
  const {unreadNotifsLength} = useUserNotifications();

  return (
    <>
      <BlueWhiteLogoHeader />
      <NavigationContainer className="pt-6 w-full flex flex-col lg:justify-center lg:items-center xl:items-start">
        <nav className="w-full overflow-y-auto">
          {userRole
            ? filteredNavMenuItems?.map(
                ({
                  name,
                  route,
                  icon: Icon,
                  iconClasses,
                  routeRegex,
                }: NavMenuItem) => (
                  <div key={route}>
                    <NavLinkV2
                      key={route}
                      pathname={route}
                      current={
                        pathname === route || !!routeRegex?.test(pathname)
                      }
                      linkName={t(`navigationName.${name.toLowerCase()}`)}
                    >
                      <div className="relative">
                        <Icon
                          strokeColor={pathname === route ? 'text-white' : ''}
                          classes={iconClasses}
                        />
                        {route === '/notifications' && unreadNotifsLength ? (
                          <span
                            className={classnames(
                              'absolute -top-2 right-0 inline-flex items-center rounded-full px-1.5 h-4 text-[10px] font-semibold text-blue-600 ring-1 ring-inset ring-blue-600/50',
                              {
                                hidden: pathname === route,
                                'bg-blue-600/[15%]': pathname !== route,
                              },
                            )}
                          >
                            {unreadNotifsLength}
                          </span>
                        ) : null}
                      </div>
                    </NavLinkV2>
                  </div>
                ),
              )
            : null}
          {userRole == UserRoles.member ? (
            <NavLinkV2
              pathname="/account"
              current={[
                '/profile',
                '/membership',
                '/terms-and-conditions',
                '/payment',
              ].includes(pathname)}
              linkName={t('navigationName.profile', 'Profile')}
            >
              <img
                src={user?.image}
                className="w-6 h-6 bg-gray-300 rounded-full object-cover"
                alt="profile-photo"
              />
            </NavLinkV2>
          ) : null}
          {[UserRoles.therapist, UserRoles.prescriber].includes(
            (userRole ?? '') as unknown as UserRoles,
          ) ? (
            <NavLinkV2
              pathname="/provider/profile"
              current={isProviderProfilePage(pathname)}
              linkName={t('navigationName.profile', 'Profile')}
            >
              <img
                src={user?.image}
                className="w-6 h-6 bg-gray-300 rounded-full"
                alt="profile-photo"
              />
            </NavLinkV2>
          ) : null}
        </nav>
        <button
          onClick={logout}
          className={classnames(
            'flex items-center gap-5 text-red-500 px-5 py-6 text-lg w-full leading-snug mt-auto cursor-pointer',
            {
              'pointer-events-none opacity-50':
                logoutStatus === SliceStatus.pending,
            },
          )}
        >
          {logoutStatus === SliceStatus.pending ? (
            <LoadingSpinner
              type="Oval"
              width={20}
              height={20}
              color="#EF4444"
            />
          ) : (
            <LogoutIcon
              width={25}
              height={25}
              strokeWidth={25}
              strokeColor="text-red-500"
            />
          )}
          <span className="lg:hidden xl:block">{t('logout')}</span>
        </button>
      </NavigationContainer>
    </>
  );
};
