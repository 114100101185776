import {MedicationNameList, PaymentService, UserRoles} from 'interfaces';

export const transformNoteMedicationsData = (
  medications: MedicationNameList[],
  role: UserRoles,
): MedicationNameList[] => {
  const result: MedicationNameList[] = [];
  for (const {
    medicationName,
    medicationInstruction,
    quantity,
    daySupply,
    refillNumber,
    ...rest
  } of medications) {
    let data: MedicationNameList = {
      medicationName,
      medicationInstruction,
      quantity,
      daySupply,
      refillNumber,
    };

    if (role === UserRoles.admin) {
      data = {...data, ...rest};
    }
    result.push(data);
  }
  return result;
};

const processingFee: {
  [key: string]: {
    [key: string]: {
      fee: string;
      pricing_link: string;
    };
  };
} = {
  us: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 USD',
      pricing_link: 'https://www.paypal.com/us/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '2.90% + 0.30 USD',
      pricing_link: 'https://stripe.com/pricing',
    },
  },
  br: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.60 BRL',
      pricing_link: 'https://www.paypal.com/br/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '3.99% + 0.39 BRL',
      pricing_link: 'https://stripe.com/en-br/pricing',
    },
  },
  au: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 AUD',
      pricing_link: 'https://www.paypal.com/au/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '2.90% + 0.30 AUD',
      pricing_link: 'https://stripe.com/au/pricing',
    },
  },
  ca: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 CAD',
      pricing_link: 'https://www.paypal.com/ca/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '2.90% + 0.30 CAD',
      pricing_link: 'https://stripe.com/en-ca/pricing',
    },
  },
  cl: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 CLP',
      pricing_link: 'https://www.paypal.com/cl/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '2.90% + 0.30 CLP',
      pricing_link: 'https://stripe.com/connect/pricing',
    },
  },
  co: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 COP',
      pricing_link: 'https://www.paypal.com/co/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '2.90% + 0.30 COP',
      pricing_link: 'https://stripe.com/connect/pricing',
    },
  },
  mx: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 MXN',
      pricing_link: 'https://www.paypal.com/mx/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '3.6% + 3.00 MXN',
      pricing_link: 'https://stripe.com/mx/pricing',
    },
  },
  nz: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 NZD',
      pricing_link: 'https://www.paypal.com/nz/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '3.7% + 0.30 NZD',
      pricing_link: 'https://stripe.com/nz/pricing',
    },
  },
  za: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 ZAR',
      pricing_link: 'https://www.paypal.com/za/webapps/mpp/paypal-fees',
    },
    [PaymentService.PAYSTACK]: {
      fee: '2.90% + 1.00 ZAR',
      pricing_link: 'https://paystack.com/pricing',
    },
    [PaymentService.STRIPE]: {
      fee: '2.90% + 0.30 ZAR',
      pricing_link: 'https://paystack.com/pricing',
    },
  },
  es: {
    [PaymentService.PAYPAL]: {
      fee: '2.90% + 0.30 EUR',
      pricing_link: 'https://www.paypal.com/es/webapps/mpp/paypal-fees',
    },
    [PaymentService.STRIPE]: {
      fee: '2.90% + 0.25 EUR',
      pricing_link: 'https://stripe.com/es/pricing',
    },
  },
};

export function getPaymentServiceFee(
  countryCode: string | undefined,
  serviceName: PaymentService,
): {fee: string; pricing_link: string} | null {
  if (!countryCode || !serviceName) return null;

  const countryFees =
    processingFee[countryCode.toLowerCase() as keyof typeof processingFee];

  if (!countryFees) {
    // `No fees found for country code: ${countryCode}`;
    return null;
  }

  const serviceFee = countryFees[serviceName as keyof typeof countryFees];

  if (!serviceFee) {
    // `No fees found for service: ${serviceName} in country code: ${countryCode}`;
    return null;
  }

  return serviceFee;
}
