import {RootState} from 'app/rootReducer';
import {selectMemberById} from 'features/Provider/Members/membersSelectors';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';

export function useGetMemberThroughParams() {
  const {patientId} = useParams<{patientId: string}>();
  const member = useSelector((state: RootState) => {
    return selectMemberById(state, patientId);
  });

  return {member};
}
