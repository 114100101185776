import {RootState} from 'app/rootReducer';
import {SliceStatus} from 'interfaces';

import {createSelector} from '@reduxjs/toolkit';

import {PaymentState} from './paymentSlice';

function getPayment(state: RootState): PaymentState {
  return state.payment;
}

export const selectManageSubStatus = createSelector(
  getPayment,
  payment => payment.manageSubStatus,
);

export const selectPaystackAuthorizationStatus = createSelector(
  getPayment,
  payment => payment.paystackAuthorization.status,
);

export const selectPaypalCardList = createSelector(
  getPayment,
  payment => payment.paypalCards,
);

export const selectPaystackCardList = createSelector(
  getPayment,
  payment => payment.paystackCards,
);

export const selectReceipts = createSelector(
  getPayment,
  payment => payment.receipts,
);
export const selectFetchReceiptsIsLoading = createSelector(
  getPayment,
  payment => payment.status === SliceStatus.pending,
);
