import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {from, of} from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import {ReferralService} from 'services/api';

import {memberActions} from '../memberSlice';

export const postMarketingChannelSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.postMarketingChannelSuccess.match),
    delayWhen(() => action$.pipe(filter(userActions.setUser.match))),
    switchMap(() => [
      push({
        pathname: '/welcome',
      }),
    ]),
  );

export const postMarketingChannelEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(memberActions.postMarketingChannel.match),
    mergeMap(({payload: {marketingChannel: whereFrom}}) =>
      from(ReferralService.postMarketingChannel({whereFrom})).pipe(
        mergeMap(() => [
          memberActions.postMarketingChannelSuccess(),
          userActions.checkSession(UserRoles.member),
        ]),
        catchError(error => of(userActions.logData(error))),
      ),
    ),
  );

export const memberMarketingChannelEpic = [
  postMarketingChannelEpic,
  postMarketingChannelSuccessEpic,
];
