import {
  DirectBookingFilterType,
  DirectBookingProviderType,
  DirectBookingSearchProviderParams,
  DirectBookingSessionType,
  SliceStatus,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  bookAppointment,
  bookAppointmentFailure,
  bookAppointmentSuccess,
  getDirectBookingFilterOptions,
  getDirectBookingFilterOptionsFailure,
  getDirectBookingFilterOptionSuccess,
  getDirectBookingSearchedProviders,
  getDirectBookingSearchedProvidersFailure,
  getDirectBookingSearchedProvidersSuccess,
  getProviderById,
  getProviderByIdFailure,
  getProviderByIdSuccess,
  resetDirectBookingSearchedProviders,
  setDirectBookingScrollPosition,
  setDirectBookingSelectedProvider,
  setDirectBookingSelectedSession,
} from './directBookingActions';

export type DirectBookingSliceState = {
  filterOptions: {
    data: DirectBookingFilterType;
    status: SliceStatus;
  };
  providers: {
    data: DirectBookingProviderType[];
    offset: string;
    hasMore: boolean;
    searchParams?: DirectBookingSearchProviderParams;
    scrollPosition: number;
    status: SliceStatus;
  };
  selectedProvider?: DirectBookingProviderType;
  selectedSession?: DirectBookingSessionType;
  provider: DirectBookingProviderType;
  status: SliceStatus;
  error: string;
};

export const initialState: DirectBookingSliceState = {
  filterOptions: {
    data: {} as DirectBookingFilterType,
    status: SliceStatus.idle,
  },
  providers: {
    data: [] as DirectBookingProviderType[],
    offset: '',
    hasMore: false,
    scrollPosition: 0,
    status: SliceStatus.idle,
  },
  provider: {} as DirectBookingProviderType,
  status: SliceStatus.idle,
  error: '',
};

const directBookingSlice = createSlice({
  name: 'directBooking',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DirectBookingSliceState>) =>
    builder
      .addCase(getDirectBookingFilterOptions, state => ({
        ...state,
        filterOptions: {
          ...state.filterOptions,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getDirectBookingFilterOptionSuccess, (state, {payload}) => ({
        ...state,
        filterOptions: {
          ...state.filterOptions,
          data: payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getDirectBookingFilterOptionsFailure, state => ({
        ...state,
        filterOptions: {
          ...state.filterOptions,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(getProviderById, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(getProviderByIdSuccess, (state, {payload}) => ({
        ...state,
        provider: payload,
        status: SliceStatus.resolved,
      }))
      .addCase(getProviderByIdFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(getDirectBookingSearchedProviders, (state, {payload}) => ({
        ...state,
        providers: {
          ...state.providers,
          status: SliceStatus.pending,
          searchParams: payload,
        },
      }))
      .addCase(
        getDirectBookingSearchedProvidersSuccess,
        (state, {payload: {result, ...rest}}) => ({
          ...state,
          providers: {
            ...state.providers,
            data: [...(state.providers.data ?? []), ...result],
            ...rest,
            status: SliceStatus.resolved,
          },
        }),
      )
      .addCase(getDirectBookingSearchedProvidersFailure, state => ({
        ...state,
        providers: {
          ...state.providers,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(resetDirectBookingSearchedProviders, state => ({
        ...state,
        providers: {
          ...state.providers,
          data: [] as DirectBookingProviderType[],
          offset: '',
          searchParams: undefined,
          scrollPosition: 0,
          hasMore: false,
          status: SliceStatus.idle,
        },
      }))
      .addCase(setDirectBookingSelectedProvider, (state, {payload}) => ({
        ...state,
        selectedProvider: payload,
      }))
      .addCase(setDirectBookingScrollPosition, (state, {payload}) => ({
        ...state,
        providers: {
          ...state.providers,
          scrollPosition: payload,
        },
      }))
      .addCase(setDirectBookingSelectedSession, (state, {payload}) => ({
        ...state,
        selectedSession: payload,
      }))
      .addDefaultCase(state => state),
});

export const {reducer: directBookingReducer, name: directBookingReducerName} =
  directBookingSlice;

export type TDirectBookingActions =
  | ReturnType<typeof getDirectBookingFilterOptions>
  | ReturnType<typeof getDirectBookingFilterOptionSuccess>
  | ReturnType<typeof getDirectBookingFilterOptionsFailure>
  | ReturnType<typeof getDirectBookingSearchedProviders>
  | ReturnType<typeof getDirectBookingSearchedProvidersSuccess>
  | ReturnType<typeof getDirectBookingSearchedProvidersFailure>
  | ReturnType<typeof resetDirectBookingSearchedProviders>
  | ReturnType<typeof setDirectBookingSelectedProvider>
  | ReturnType<typeof setDirectBookingScrollPosition>
  | ReturnType<typeof bookAppointment>
  | ReturnType<typeof bookAppointmentSuccess>
  | ReturnType<typeof bookAppointmentFailure>;

export const directBookingActions = {
  getDirectBookingFilterOptions,
  getDirectBookingFilterOptionSuccess,
  getDirectBookingFilterOptionsFailure,
  getDirectBookingSearchedProviders,
  getDirectBookingSearchedProvidersSuccess,
  getDirectBookingSearchedProvidersFailure,
  resetDirectBookingSearchedProviders,
  setDirectBookingSelectedProvider,
  setDirectBookingScrollPosition,
  getProviderById,
  getProviderByIdFailure,
  getProviderByIdSuccess,
  setDirectBookingSelectedSession,
  bookAppointment,
  bookAppointmentFailure,
  bookAppointmentSuccess,
};

export type DirectBookingState = ReturnType<typeof directBookingReducer>;
