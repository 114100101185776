import {WherebyRoomModeTypes} from 'interfaces/Appointment.types';

export type Medication = {
  medicationId: string;
  medicationName: string;
};

export enum CMSFormSubmissionStatus {
  notSubmitted = 'not-submitted',
  submitted = 'submitted',
  failed = 'failed',
}

export type MedicationsDictionary = {
  [key: string]: Medication;
};

export type MedicationsIdList = string[];

export type NormalizedMedications = {
  entities: {
    medications: MedicationsDictionary;
  };
  result: MedicationsIdList;
  filter: string;
};

export type MedicationNameList = {
  medicationName: Medication[] | string[];
  medicationInstruction: string;
  quantity: number;
  daySupply: number;
  refillNumber: number;
  paymentType?: string | undefined;
  cardHolderId?: string | undefined;
  rxGroup?: string | undefined;
  rxBin?: string | undefined;
  pcn?: string | undefined;
};

export type NotesType = {
  createdAt?: Date;
  date?: Date;
  chiefComplaint: string;
  patientNotes: string;
  noteCreator: string;
  noteCreatorFullName: string;
  noteCreatorImage?: string;
  plan: string;
  medications: {
    medicationName: Medication[];
    medicationInstruction: string;
    quantity: number;
    daySupply: number;
    refillNumber: number;
  }[];
  noteId: string;
  orderId?: string;
  orderStatus?: {
    status: string;
    error: boolean;
    message: string;
  };
  prescribeMedication: boolean;
  pharmacyService: string;
  icdCodes: {value: string; label: string; _id?: string}[];
  duration?: number;
  appointmentType?: string;
  appointmentID?: string;
  isCmsFormSubmitted?: CMSFormSubmissionStatus;
  roomMode: WherebyRoomModeTypes;
  wellniteNote?: boolean;
};

export type NotesDictionary = {
  [key: string]: NotesType;
};

export type NotesIdList = string[];

export type NormalizedNotes = {
  entities: {
    notes: NotesDictionary;
  };
  result: NotesIdList;
};
