import {MemberProfile, UserRoles} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const getMember = createAction<{
  patientEmail: string;
  role: UserRoles;
}>('getMember');

export const getMemberById = createAction<{
  patientId: string;
  role: UserRoles;
}>('getMemberById');

export const getMemberSuccess = createAction<{
  normalizedMembers: {[key: string]: MemberProfile};
}>('getMemberSuccess');

export const getMemberFailure = createAction('getMemberFailure');
