/* eslint-disable simple-import-sort/imports */
import React, {FC, useCallback, useState} from 'react';
import {OnboardingShippingSchema} from 'definitions/Yup';
import {memberActions} from 'features/Member';
import {useEnhancedForm} from 'hooks';
import {OnboardingShippingProps, SliceStatus} from 'interfaces';
import log from 'loglevel';
import Geocode from 'react-geocode';
import {OnChangeValue} from 'react-select';
import {ShippingForm} from 'components/Maps/ShippingForm';

type GeocodeResult = {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
};

enum AddressComp {
  streetAddress = 'streetAddress',
  city = 'city',
  state = 'state',
  postalCode = 'postalCode',
  country = 'country',
}

type AddressComponents = {
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
};

type AutocompleteResult = {
  terms: {offset: number; value: string}[];
};

type OptionType = {label: string; value: AutocompleteResult};

export function useAddressMap(): {
  mapPosition: {
    lat: number;
    lng: number;
  };
  markerPosition: {
    lat: number;
    lng: number;
  };
  getAddressComponent: (
    addressArray: {
      long_name: string;
      short_name: string;
      types: string[];
    }[],
    componentType: string,
  ) => string;
  getAddressObject: (
    terms: {
      offset: number;
      value: string;
    }[],
  ) => AddressComponents;
  handleNewLocation: (newLat: string, newLng: string) => Promise<void>;
  onPlacesChange: (val: OnChangeValue<OptionType, boolean>) => void;
  onMarkerDragEnd: (event: google.maps.MapMouseEvent) => void;
  streetAddress: string;
  ShippingDetailsFallback: FC;
} {
  const {
    apiErrorMsg,
    isLoading,
    onSubmit,
    formMethods: {
      control,
      formState: {errors},
      setValue,
      getValues,
    },
  } = useEnhancedForm<OnboardingShippingProps>({
    defaultValues: {
      shippingAddress: {
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
    },
    schema: OnboardingShippingSchema,
    slice: 'member',
    action: memberActions.changeAddress,
    extraSubmitData: {},
    shouldUnregister: false,
  });

  const [mapPosition, setMapPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });

  const getAddressComponent = (
    addressArray: {
      long_name: string;
      short_name: string;
      types: string[];
    }[],
    componentType: string,
  ) => {
    let state = '';
    for (let j = 0; j < addressArray.length; j++) {
      if (
        addressArray[j].types[0] &&
        addressArray[j].types[0] === componentType
      ) {
        state = addressArray[j].long_name;
        return state;
      }
    }
    return state;
  };

  const getAddressObject = (terms: {offset: number; value: string}[]) => {
    const newAddress: AddressComponents = {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    };
    terms.reverse().forEach(({value}, index) => {
      switch (index) {
        case 0: {
          newAddress.country = value;
          break;
        }
        case 1: {
          if (/\d/.test(value)) newAddress.postalCode = value;
          else newAddress.state = value;
          break;
        }
        case 2: {
          if (newAddress.state) newAddress.city = value;
          else newAddress.state = value;
          break;
        }
        case 3: {
          if (newAddress.city) newAddress.streetAddress = value;
          else newAddress.city = value;
          break;
        }
        default: {
          newAddress.streetAddress =
            `${value} ${newAddress.streetAddress}`.trim();
        }
      }
    });
    return newAddress;
  };

  const setFormValues = useCallback(
    (newAddress: AddressComponents) => {
      Object.entries(newAddress).forEach(([key, val]) =>
        setValue(`shippingAddress.${key as AddressComp}`, val),
      );
    },
    [setValue],
  );

  const handleNewLocation = useCallback(
    async (newLat: string, newLng: string): Promise<void> => {
      try {
        const response: {
          results: GeocodeResult[];
        } = await Geocode.fromLatLng(newLat, newLng);
        const addressArray = response.results[0].address_components;
        const newAddress: AddressComponents = {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        };
        newAddress.city = getAddressComponent(
          addressArray,
          'administrative_area_level_2',
        );
        const locality = getAddressComponent(addressArray, 'locality');
        const [streetAddress] = response.results[0].formatted_address.split(
          locality || newAddress.city,
        );
        newAddress.streetAddress = streetAddress.trim();
        newAddress.state = getAddressComponent(
          addressArray,
          'administrative_area_level_1',
        );
        newAddress.country = getAddressComponent(addressArray, 'country');
        newAddress.postalCode = getAddressComponent(
          addressArray,
          'postal_code',
        );
        setFormValues(newAddress);
        setMapPosition({
          lat: Number(newLat),
          lng: Number(newLng),
        });
        setMarkerPosition({
          lat: Number(newLat),
          lng: Number(newLng),
        });
      } catch (error) {
        log.warn(error, 'mapError');
      }
    },
    [setFormValues],
  );

  const onPlaceSelected = (place: AutocompleteResult) => {
    const newAddress = getAddressObject(place.terms);
    Geocode.fromAddress(
      `${newAddress.streetAddress} ${newAddress.city} ${newAddress.state} ${newAddress.postalCode} ${newAddress.country}`,
    ).then(
      response => {
        const {lat, lng} = response.results[0].geometry.location;
        handleNewLocation(lat, lng).then(() => {
          setMapPosition({
            lat,
            lng,
          });
          setMarkerPosition({
            lat,
            lng,
          });
        });
      },
      error => {
        console.error(error, 'error');
      },
    );
    setFormValues(newAddress);
  };

  const onPlacesChange = (val: OnChangeValue<OptionType, boolean>) => {
    onPlaceSelected((val as OptionType).value);
  };

  const onMarkerDragEnd = useCallback(
    async (event: any) => {
      const newLat = event.latLng.lat();
      const newLng = event.latLng.lng();
      await handleNewLocation(newLat, newLng);
    },
    [handleNewLocation],
  );

  const ShippingDetailsFallback: FC = () => (
    <ShippingForm
      onSubmit={onSubmit}
      errors={errors}
      control={control}
      apiError={apiErrorMsg}
      isPending={isLoading === SliceStatus.pending}
    />
  );

  return {
    mapPosition,
    markerPosition,
    getAddressComponent,
    getAddressObject,
    handleNewLocation,
    onPlacesChange,
    onMarkerDragEnd,
    streetAddress: getValues()?.shippingAddress?.streetAddress ?? '',
    ShippingDetailsFallback,
  };
}
