import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {
  InsuranceCompanyType,
  MemberProfile,
  PverifyInfoType,
  SocialOnboardingStatusEnum,
  // SocialOnboardingStatusEnum,
  UserAccountType,
} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {SocialOnboardingService} from 'services/api/SocialOnboarding';

import {memberActions} from '../../memberSlice';

export const saveSocialOnboardingDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(memberActions.saveSocialOnboardingData.match),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: {data, onboardStage, socialOnboardingExtraFlow},
        },
        currState,
      ]) =>
        from(
          SocialOnboardingService.saveSocialOnboardingData({
            data: {
              ...(currState?.user?.current?.insuranceDetails ?? {}),
              ...data,
            },
            onboardStage,
          }),
        ).pipe(
          mergeMap(
            ({
              data: {
                message: {member, pverifyInfo},
              },
            }) => {
              if (member?.insuranceDetails?.onboardStage === 6) {
                return [
                  memberActions.updatePverifyInfo(
                    pverifyInfo as PverifyInfoType,
                  ),
                  memberActions.saveSocialOnboardingDataSuccess(),
                  userActions.updateSocialOnboardingData(
                    member as MemberProfile,
                  ),
                ];
              }
              if (
                (member?.insuranceDetails?.onboardStage === 6 &&
                  member.insuranceDetails?.onboardingStatus ===
                    (SocialOnboardingStatusEnum.accepted ||
                      SocialOnboardingStatusEnum.complete)) ||
                member?.insuranceDetails?.companyType ===
                  InsuranceCompanyType.none
              ) {
                return [
                  userActions.updateSocialOnboardingData(
                    member as MemberProfile,
                  ),
                  push({
                    pathname:
                      (socialOnboardingExtraFlow === 'zocdoc' ||
                        currState.user.current?.accountType ===
                          UserAccountType.scale) &&
                      !Array.isArray(currState.user.current?.patientCards)
                        ? '/onboarding-one-time-payment'
                        : '/dashboard',
                    state: {
                      providerType: 'therapist',
                    },
                  }),
                  memberActions.saveSocialOnboardingDataSuccess(),
                ];
              }
              return [
                userActions.updateSocialOnboardingData(member as MemberProfile),
                memberActions.saveSocialOnboardingDataSuccess(),
              ];
            },
          ),
          catchError((message: string) =>
            concat(
              of(
                userActions.setAsyncError({
                  filter: 'appointment',
                  message,
                }),
                memberActions.saveSocialOnboardingDataFailure(),
              ),
            ),
          ),
        ),
    ),
  );

export const fetchInsuranceNamesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(memberActions.fetchInsuranceNames.match),
    withLatestFrom(state$),
    switchMap(([_action, state]) => {
      if (state.member.insuranceNames.length !== 0) {
        return [];
      }

      return from(SocialOnboardingService.fetchInsuranceNames()).pipe(
        mergeMap(
          ({
            data: {
              message: {modifiedInsuranceNames},
            },
          }) => {
            return [
              memberActions.fetchInsuranceNamesSuccess(modifiedInsuranceNames),
            ];
          },
        ),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
              memberActions.fetchInsuranceNamesFailure(),
            ),
          ),
        ),
      );
    }),
  );

const changeSocialOnboardingStatusEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(memberActions.changeSocialOnboardingStatus.match),
    withLatestFrom(state$),
    mergeMap(([{payload}, currState]) =>
      from(SocialOnboardingService.changeOnboardingStatusOrStage(payload)).pipe(
        mergeMap(({data: {message: member}}) => {
          const actions = [
            memberActions.changeSocialOnboardingStatusSuccess(member),
            userActions.setUser(member),
            userActions.setSocialOnboardStage(
              member?.insuranceDetails?.onboardStage || 6,
            ),
          ];
          if (
            member?.insuranceDetails?.onboardStage === 6 &&
            [
              SocialOnboardingStatusEnum.accepted,
              SocialOnboardingStatusEnum.complete,
            ].includes(member.insuranceDetails.onboardingStatus)
          ) {
            return [
              ...actions,
              push({
                pathname:
                  (member.socialOnboardingExtraFlow === 'zocdoc' ||
                    currState.user.current?.accountType ===
                      UserAccountType.scale) &&
                  !Array.isArray(currState.user.current?.patientCards)
                    ? '/onboarding-one-time-payment'
                    : '/dashboard',
                state: {
                  providerType: 'therapist',
                },
              }),
            ];
          }
          return actions;
        }),
        catchError(() => {
          return concat(
            of(memberActions.changeSocialOnboardingStatusFailure()),
          );
        }),
      ),
    ),
  );

export const SocialOnboardingEpics = [
  saveSocialOnboardingDataEpic,
  fetchInsuranceNamesEpic,
  changeSocialOnboardingStatusEpic,
];
