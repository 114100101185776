import {UserRoles} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const sendGreetings = createAction<{
  data: {
    fullName: string; // receiver's fullName
    email: string; // receiver's email
    secondaryEmail: string; // receiver's secondary email
    role: UserRoles; // receiver's role
    message: string;
  };
  onSuccess: () => void;
  onError: () => void;
}>('sendGreetings');
export const sendGreetingsSuccess = createAction('sendGreetingsSuccess');
export const sendGreetingsFailure = createAction<string>(
  'sendGreetingsFailure',
);
