import {
  CurrentUser,
  DigitalPracticeAccountTypes,
  MemberProfile,
  PrescriberProfile,
  ProviderLicenseCategory,
  ProviderRegistrationSource,
  SocialOnboardingStatusEnum,
  StripePlanNames,
  TherapistProfile,
  UserAccountType,
  UserRoles,
} from 'interfaces';

export function isMember(user: CurrentUser): user is MemberProfile {
  return (user as MemberProfile)?.role === UserRoles.member;
}

export function isTherapist(user: CurrentUser): user is TherapistProfile {
  return (user as TherapistProfile)?.role === UserRoles.therapist;
}

export function isInsuranceZocdocMember(user: CurrentUser) {
  return (
    (user as MemberProfile)?.paymentPlan === StripePlanNames.together &&
    user?.socialOnboardingExtraFlow === 'zocdoc'
  );
}

export function isPrescriber(user: CurrentUser): user is PrescriberProfile {
  return (user as PrescriberProfile)?.role === UserRoles.prescriber;
}
export function isDigitalPracticeOutOfPocketMember(
  user?: MemberProfile,
): user is MemberProfile {
  if (
    user?.role === UserRoles.member &&
    user?.digitalPracticeAccountType ===
      DigitalPracticeAccountTypes.outOfPocket &&
    user?.socialOnboardingExtraFlow !== 'zocdoc'
  ) {
    return true;
  } else {
    return false;
  }
}

export function isInNetworkDigitalPracticeMember(
  user?: MemberProfile,
): boolean {
  if (
    user?.digitalPracticeAccountType ===
      DigitalPracticeAccountTypes.insurance ||
    (user?.accountType === 'scale' && !user?.digitalPracticeAccountType)
  ) {
    return true;
  } else {
    return false;
  }
}

export function isDigitalPracticeWithPaypalMember(
  user?: MemberProfile,
): boolean {
  return (
    user?.accountType === UserAccountType.scale &&
    !!user.paypalCustomerId?.length
  );
}

export function isProvider(
  user: CurrentUser,
): user is TherapistProfile | PrescriberProfile {
  return isTherapist(user) || isPrescriber(user);
}

export function isOutsideWellniteMember(user: CurrentUser): boolean {
  return user?.accountType === UserAccountType.scale;
}

export function isDigitalPracticeProviderYetToSetUpStripe(
  user: CurrentUser,
): boolean {
  return (
    isProvider(user) &&
    user.accountType === UserAccountType.scale &&
    !(user.stripeAccountId && user.stripeConnectOnboardingComplete)
  );
}

export function isProviderLicensed(provider: CurrentUser): boolean {
  if (
    provider &&
    (provider.role === UserRoles.prescriber ||
      provider.role === UserRoles.therapist) &&
    'providerLicenseCategory' in provider &&
    provider?.providerLicenseCategory === ProviderLicenseCategory.licensed
  ) {
    return true;
  } else {
    return false;
  }
}

export function isProviderAssociate(provider: CurrentUser): boolean {
  if (
    provider &&
    provider.role === UserRoles.therapist &&
    'providerLicenseCategory' in provider &&
    provider?.providerLicenseCategory === ProviderLicenseCategory.associate
  ) {
    return true;
  } else {
    return false;
  }
}

export function isUserFromUnitedStates(user: CurrentUser): boolean {
  if (user?.countryOfResidence?.code?.trim()?.toUpperCase() === 'US') {
    return true;
  } else {
    return false;
  }
}

export function isProviderFullyOnboarded(provider: CurrentUser): boolean {
  if (
    isProvider(provider) &&
    provider?.source === ProviderRegistrationSource.provider
  ) {
    if (isProviderLicensed(provider)) {
      if (
        provider?.deelContractId &&
        provider?.onboarding?.onboardingStage !== undefined &&
        provider?.onboarding?.onboardingStage > 4
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        provider?.deelContractId &&
        provider?.onboarding?.onboardingStage !== undefined &&
        provider?.onboarding?.onboardingStage > 3
      ) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    return true;
  }
}

/**
 * Checks if a user's phone is verified.
 *
 * @param user - The user's member profile.
 * @returns True if the user's phone is verified, false otherwise.
 */
export const isPhoneVerified = (user: MemberProfile): boolean => {
  if (
    (user?.insuranceDetails?.onboardStage ?? 1) > 2 &&
    user?.isPhoneVerified === true
  ) {
    return true;
  } else {
    return false;
  }
};

export const isInsuranceOnboardingCompleted = (user: MemberProfile) => {
  if (user?.insuranceDetails?.onboardStage === 6) {
    return true;
  } else {
    return false;
  }
};

export const isOboardingStatusAcceptedOrCompleted = (user: MemberProfile) => {
  if (
    user?.insuranceDetails?.onboardingStatus ===
      SocialOnboardingStatusEnum.accepted ||
    user?.insuranceDetails?.onboardingStatus ===
      SocialOnboardingStatusEnum.complete
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Checks if the user is an old insurance member.
 * @param user - The user's member profile.
 * @returns True if the user is an old insurance member, false otherwise.
 */
export const isOldInsuranceMember = (user: MemberProfile): boolean => {
  if (
    user?.paymentPlan === StripePlanNames.together &&
    (user?.insuranceDetails?.onboardStage ?? 1) > 4 &&
    user?.insuranceDetails?.onboardingStatus === undefined
  ) {
    return true;
  } else {
    return false;
  }
};

export const isInsuranceCompanyTypeNone = (user: MemberProfile): boolean => {
  if (
    user?.insuranceDetails?.companyType === 'none' &&
    user?.insuranceDetails.onboardStage > 3
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Checks if the user is an old DP insurance member.
 *
 * @param user - The user's member profile.
 * @returns - True if the user is an old DP insurance member, false otherwise.
 */
export const isOldDPInsuranceMember = (user: MemberProfile): boolean => {
  if (
    user?.accountType === UserAccountType.scale &&
    user?.digitalPracticeAccountType ===
      DigitalPracticeAccountTypes.insurance &&
    (user?.insuranceDetails?.onboardStage ?? 1) > 4 &&
    user?.insuranceDetails?.onboardingStatus === undefined
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Checks if the insurance member onboarding is completed for a given user.
 * @param user The member profile of the user.
 * @returns True if the insurance member onboarding is completed, false otherwise.
 */
export const isInsuranceMemberOnboardingCompleted = (
  user: MemberProfile,
): boolean => {
  return (
    isPhoneVerified(user) &&
    isInsuranceOnboardingCompleted(user) &&
    isInNetworkDigitalPracticeMember(user) &&
    isOboardingStatusAcceptedOrCompleted(user)
  );
};
