import React, {FC, lazy, Suspense} from 'react';
import {Button, FormError} from 'components';
import {SendPaymentRequestSchema} from 'definitions/Yup';
import {CurrencyExchangeRateForNonPaypalCountries} from 'features/Currency/components';
import {CurrentUser} from 'interfaces';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {componentLoader, isTherapist} from 'utils';

import {yupResolver} from '@hookform/resolvers/yup';

import {sendPaymentRequest} from './SendPaymentRequestActions';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

type IProps = {
  user: CurrentUser;
  patientId: string;
  patientName: string;
  appointmentID: string;
  price: number;
  currency: string;
  startDate: string;
  isLoading?: boolean;
  showRequestModal: boolean;
  onSuccessModal: boolean;
  setShowRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOnSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SendPaymentRequestModal: FC<IProps> = ({
  user,
  patientId,
  patientName,
  appointmentID,
  startDate,
  price = 0,
  currency,
  isLoading,
  showRequestModal,
  onSuccessModal,
  setShowRequestModal,
  setOnSuccessModal,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: {errors},
    watch,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      price,
    },
    resolver: yupResolver(SendPaymentRequestSchema),
  });

  const onSuccess = () => {
    setShowRequestModal(false);
    setOnSuccessModal(true);
  };

  const onError = () => {
    setShowRequestModal(false);
  };

  const closeModal = () => {
    setShowRequestModal(false);
  };

  const onPaymentRequestSubmit = ({price}: {price: number}) => {
    const reqData = {
      charge: price,
      chargeCurrency: currency,
      appointmentID,
      patientId,
      startDate,
      onSuccess,
      onError,
    };

    dispatch(sendPaymentRequest(reqData));
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          btnType="custom"
          outline={true}
          borderRadius="lg"
          fontSize="base"
          fontWeight="semibold"
          textColor="gray-700"
          borderColor="gray-400"
          className="px-6 h-12 hover:bg-gray-700 hover:text-white hover:border-transparent"
          type="button"
          onClick={closeModal}
          disabled={isLoading}
        >
          {t('cancel')}
        </Button>
        <Button
          btnType="primary"
          borderRadius="lg"
          fontSize="base"
          fontWeight="semibold"
          className="min-w-[90px] h-12 flex justify-center items-center"
          type="submit"
          form="send-payment-request"
          isSubmitting={isLoading}
          loaderType="Oval"
        >
          {t('paymentRequest.send')}
        </Button>
      </>
    );
  };

  return (
    <>
      {showRequestModal && (
        <Suspense fallback={<div>{t('loading')}...</div>}>
          <Modal
            messageType="none"
            isOpen={showRequestModal}
            showCloseIcon
            title={t('paymentRequest.reqPayment')}
            buttonFn={closeModal}
            floatingTitle={false}
            titleClasses="font-semibold px-2 text-lg block"
            headerClasses="w-full h-16 px-4 flex justify-end items-center"
            containerClasses="w-full w-[445px] max-h-[90vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-2xl text-left align-middle transition-all shadow-lg"
            footer={renderFooter()}
            footerClasses="py-5"
            backdropClasses="bg-black/30"
            isInProgress={isLoading}
          >
            <form
              id="send-payment-request"
              onSubmit={handleSubmit(onPaymentRequestSubmit)}
            >
              <div className="flex flex-col justify-center items-center gap-3 pt-6 pb-8">
                <p className="font text-sm font-normal text-[#585858] leading-4">
                  {t('paymentRequest.sendRequestTo', {patientName})}
                </p>
                <div className="flex flex-col gap-2">
                  <div className="relative w-full">
                    <label
                      htmlFor="paymentAmount"
                      className="absolute top-4 left-20 font-inter text-base font-semibold leading-tight text-black"
                    >
                      {currency}
                    </label>
                    <input
                      id="paymentAmount"
                      type="text"
                      {...register('price')}
                      className="w-60 h-12 pl-[120px] pr-4 rounded-xl bg-[#f6f6f6] text-base font-semibold leading-tight text-left border border-gray-50 focus:outline-none focus:border-blue-600 focus:ring-blue-600"
                    />
                  </div>
                  <FormError error={errors?.price?.message} />
                </div>
              </div>
              <div className="px-2 pb-3">
                <CurrencyExchangeRateForNonPaypalCountries
                  countryCode={user?.countryOfResidence?.code}
                  value={watch('price')}
                />
              </div>
            </form>
          </Modal>
        </Suspense>
      )}

      {isTherapist(user) && onSuccessModal ? (
        <Suspense fallback={<div />}>
          <Modal
            message={t('paymentRequest.requestSentTo', {patientName})}
            messageType="success"
            isOpen={true}
            containerClasses="w-full w-[445px] max-h-[90vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-2xl"
            backdropClasses="bg-black/30"
            buttonFn={() => setOnSuccessModal(false)}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default SendPaymentRequestModal;
