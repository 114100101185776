import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {compare} from 'compare-versions';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const CacheBusterContext = createContext({checkCacheStatus: () => {}});

interface IProps {
  children: ReactNode;
  currentVersion: string;
  isEnabled: boolean;
  isVerboseMode: boolean;
  loadingComponent: any;
  metaFileDirectory: string;
  reloadOnDowngrade?: boolean;
  onCacheClear?: () => void;
}

const CacheBuster: FC<IProps> = ({
  children = null,
  currentVersion,
  isEnabled = false,
  isVerboseMode = false,
  loadingComponent = null,
  metaFileDirectory = null,
  reloadOnDowngrade = false,
  onCacheClear,
}) => {
  const [cacheStatus, setCacheStatus] = useState({
    loading: true,
    isLatestVersion: false,
  });

  const log = (message: string, isError: boolean = false) => {
    isVerboseMode && (isError ? console.error(message) : console.log(message));
  };

  useEffect(() => {
    isEnabled ? checkCacheStatus() : log('Cache Buster is disabled.');
  }, []);

  const getMetaFileDirectory = () => {
    return !metaFileDirectory || metaFileDirectory === '.'
      ? ''
      : metaFileDirectory;
  };

  const checkCacheStatus = useCallback(async () => {
    try {
      const res = await fetch(`${getMetaFileDirectory()}/meta.json`);
      const {version: metaVersion} = await res.json();
      const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion);
      log(`v${currentVersion}`);
      if (shouldForceRefresh) {
        log(`There is a new version (v${metaVersion}). Should force refresh.`);
        setCacheStatus({
          loading: false,
          isLatestVersion: false,
        });
        localStorage.setItem('appVersion', metaVersion);
      } else {
        log('There is no new version. No cache refresh needed.');
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        });
        localStorage.setItem('appVersion', currentVersion);
      }
    } catch (error) {
      log('An error occurred while checking cache status.', true);
      log(error, true);
      localStorage.setItem('appVersion', currentVersion);

      //Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
      !isVerboseMode &&
        setCacheStatus({
          loading: false,
          isLatestVersion: true,
        });
    }
  }, [currentVersion, isVerboseMode, metaFileDirectory]);

  const isThereNewVersion = (metaVersion: string, currentVersion: string) => {
    if (reloadOnDowngrade) {
      return !compare(metaVersion, currentVersion, '=');
    }
    return compare(metaVersion, currentVersion, '>');
  };

  const refreshCacheAndReload = async () => {
    try {
      if (window?.caches) {
        const {caches} = window;
        const cacheNames = await caches.keys();
        const cacheDeletionPromises = cacheNames.map(n => caches.delete(n));

        await Promise.all(cacheDeletionPromises);

        log('The cache has been deleted.');
        // @ts-ignore: Firefox still has a `forceReload` parameter.
        window.location.reload(true);
      }
    } catch (error) {
      log('An error occurred while deleting the cache.', true);
      log(error, true);
    }
  };

  if (!isEnabled) {
    return children;
  } else {
    if (cacheStatus.loading) {
      return loadingComponent;
    }

    if (!cacheStatus.loading && !cacheStatus.isLatestVersion) {
      if (onCacheClear) {
        onCacheClear();
      } else {
        refreshCacheAndReload();
      }
      return null;
    }

    return React.createElement(
      CacheBusterContext.Provider,
      {
        value: {checkCacheStatus},
      },
      children,
    );
  }
};

const useCacheBuster = () => {
  const context = useContext(CacheBusterContext);
  if (context === undefined || context === null) {
    throw new Error(
      'useCacheBuster must be used within a CacheBuster component.',
    );
  }
  return context;
};

export {CacheBuster, useCacheBuster};
