import {RootState} from 'app/rootReducer';
import {RegenerateWellniteContentType, SliceStatus} from 'interfaces';

import {createSelector} from '@reduxjs/toolkit';

import {WellniteContentState} from './wellniteContentSlice';

export function getWellniteContent(state: RootState): WellniteContentState {
  return state.wellniteContent;
}

export const selectWellniteContentFormStep = createSelector(
  getWellniteContent,
  state => state.formStep,
);

export const selectWellniteContentStatus = createSelector(
  getWellniteContent,
  state => state.status,
);

export const selectWellniteContentGenerateExpertSummaryStatus = createSelector(
  getWellniteContent,
  state => state.generateExpertSummaryStatus,
);

export const selectWellniteContentGenerateExpertSummaryIsPending =
  createSelector(
    getWellniteContent,
    state => state.generateExpertSummaryStatus === SliceStatus.pending,
  );

export const selectWellniteContentStatusIsPending = createSelector(
  getWellniteContent,
  state => state.status === SliceStatus.pending,
);

export const selectWellniteContent = createSelector(
  getWellniteContent,
  state => state.content,
);

export const selectWellniteContentTopicSelection = createSelector(
  getWellniteContent,
  state => state.topicSelection,
);

export const selectWellniteContentIsRegenerating = (args: {
  element: RegenerateWellniteContentType;
}) =>
  createSelector(
    getWellniteContent,
    progress => progress.regenerateStatus[args.element] === SliceStatus.pending,
  );

export const selectWellniteArticle = createSelector(
  getWellniteContent,
  state => state.article,
);

export const selectWellniteArticles = createSelector(
  getWellniteContent,
  state => state.articles,
);

export const selectWellniteImages = createSelector(
  getWellniteContent,
  state => state.images,
);

export const selectWellniteImagesCurrentPage = createSelector(
  getWellniteContent,
  state => state.imagesCurrentPage ?? 1,
);

export const selectWellniteImagesHasMore = createSelector(
  getWellniteContent,
  state => (state.imagesCurrentPage ?? 1) < (state.imagesTotalPages ?? 1),
);

export const selectWellniteImagesStatusIsPending = createSelector(
  getWellniteContent,
  state => state.imagesStatus === SliceStatus.pending,
);

export const selectWellniteContentDraftStatusIsPending = createSelector(
  getWellniteContent,
  state => state.draftStatus === SliceStatus.pending,
);

export const selectProviderCanCreateWellniteContent = createSelector(
  getWellniteContent,
  state => state.providerCanCreate,
);

export const selectHasWellniteContentDraft = createSelector(
  getWellniteContent,
  state => !!state.article?.content?.length || !!state.content,
);
