import {
  GetColleaguesParamsType,
  GetColleaguesTargetType,
  ResultColleaguesType,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const getColleagues =
  createAction<GetColleaguesParamsType>('getColleagues');

export const getColleaguesFailure = createAction<{
  target: GetColleaguesTargetType;
  error: Error | string;
}>('getColleaguesFailure');

export const getColleaguesSuccess = createAction<{
  target: GetColleaguesTargetType;
  data: ResultColleaguesType;
}>('getColleaguesSuccess');

export const searchColleagues =
  createAction<GetColleaguesParamsType>('SearchColleagues');
export const searchColleaguesFailure = createAction<{
  target: GetColleaguesTargetType;
  error: Error | string;
}>('SearchColleaguesFailure');
export const searchColleaguesSuccess = createAction<{
  target: GetColleaguesTargetType;
  data: ResultColleaguesType;
}>('SearchColleaguesSuccess');

export const resetSearchColleagues = createAction<GetColleaguesTargetType>(
  'ResetSearchColleagues',
);
