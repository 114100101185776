import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {directBookingActions} from 'features/DirectBooking/directBookingSlice';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {AppointmentTypes} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {StripeService} from 'services';

import {PaystackService} from '../../../services/api/Paystack';
import {paymentActions} from '../paymentSlice';

export const digitalPracticeInsuranceCheckoutEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.digitalPracticeInsuranceCheckout.match),
    switchMap(({payload}) =>
      from(StripeService.digitalPracticeInsuranceCheckout(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          userActions.setUser(user),
          paymentActions.digitalPracticeInsuranceCheckoutSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.digitalPracticeInsuranceCheckoutFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );
export const digitalPracticeInsuranceCheckoutSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.digitalPracticeInsuranceCheckoutSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t(
          'cardsInfo.creditCardConfirmed',
          'Credit Card Confirmed',
        ),
      });
    }),
    switchMap(() => [
      push({
        pathname: '/dashboard',
      }),
    ]),
  );

export const digitalPracticeInsuranceCheckoutFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.digitalPracticeInsuranceCheckoutFailure.match),
    tap(() => {
      Toast({
        type: 'default',
        message: i18next.t(
          'cardsInfo.creditCardConfirmedFailed',
          'Credit Card confirmation failed',
        ),
      });
    }),
    ignoreElements(),
  );

export const digitalPracticeOutOfPocketCheckoutEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.digitalPracticeOutOfPocketCheckout.match),
    switchMap(({payload}) =>
      from(StripeService.digitalPracticeOutOfPocketCheckout(payload)).pipe(
        mergeMap(({data: {message: user}}) => [
          userActions.setUser(user),
          paymentActions.digitalPracticeOutOfPocketCheckoutSuccess(),
          push({
            pathname: '/dashboard',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.digitalPracticeOutOfPocketCheckoutFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );
export const digitalPracticeOutOfPocketCheckoutSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.digitalPracticeOutOfPocketCheckoutSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t(
          'cardsInfo.creditCardConfirmed',
          'Credit Card Confirmed',
        ),
      });
    }),
    withLatestFrom(state$),
    switchMap(([{payload}, state]) => {
      const {
        directBooking: {selectedProvider, selectedSession},
        user: {current},
      } = state;
      return [
        ...(payload?.paypalDefaultCard
          ? [userActions.setPaypalDefaultCard(payload.paypalDefaultCard)]
          : []),
        push({
          pathname: '/dashboard',
        }),
        ...(selectedSession && selectedProvider
          ? [
              directBookingActions.bookAppointment({
                appointmentDateTimeString: selectedSession.datetime,
                appointmentType:
                  selectedSession.appointmentType as AppointmentTypes,
                appointmentTypeID: selectedSession.appointmentTypeId,
                providerFullName: selectedProvider.providerFullName,
                calendarId: selectedProvider.calendarId,
                providerId: selectedProvider.providerId,
                patientTimezone: selectedSession.timezone,
                patientEmail: current?.email,
                memberFullName: current?.fullName,
              }),
            ]
          : []),
      ];
    }),
  );

export const digitalPracticeOutOfPocketCheckoutFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.digitalPracticeOutOfPocketCheckoutFailure.match),
    tap(() => {
      Toast({
        type: 'default',
        message: i18next.t(
          'cardsInfo.creditCardConfirmedFailed',
          'Credit Card confirmation failed',
        ),
      });
    }),
    ignoreElements(),
  );

export const initiatePaystackTransactionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.initiatePaystackTransaction.match),
    switchMap(() =>
      from(PaystackService.initiatePaystackTransaction()).pipe(
        mergeMap(({data: {message}}) => [
          paymentActions.initiatePaystackTransactionSuccess({
            authorizationUrl: message,
          }),
        ]),
        catchError(() =>
          concat(of(paymentActions.initiatePaystackTransactionFailure())),
        ),
      ),
    ),
  );

export const initiatePaystackTransactionSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.initiatePaystackTransactionSuccess.match),
    tap(({payload}) => {
      if (payload?.authorizationUrl) {
        window.location.href = payload.authorizationUrl;
      }
    }),
    ignoreElements(),
  );

export const initiatePaystackTransactionFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.initiatePaystackTransactionFailure.match),
    tap(() => {
      Toast({
        type: 'default',
        message: i18next.t(
          'cardsInfo.creditCardConfirmedFailed',
          'Credit Card confirmation failed',
        ),
      });
    }),
    ignoreElements(),
  );

export const digitalPracticeCheckoutEpics = [
  digitalPracticeInsuranceCheckoutEpic,
  digitalPracticeInsuranceCheckoutSuccessEpic,
  digitalPracticeInsuranceCheckoutFailureEpic,
  digitalPracticeOutOfPocketCheckoutEpic,
  digitalPracticeOutOfPocketCheckoutSuccessEpic,
  digitalPracticeOutOfPocketCheckoutFailureEpic,
  initiatePaystackTransactionEpic,
  initiatePaystackTransactionSuccessEpic,
  initiatePaystackTransactionFailureEpic,
];
