import {CallHistoryMethodAction, connectRouter} from 'connected-react-router';
import {
  appointmentReducer,
  appointmentReducerName,
  availabilityReducer,
  availabilityReducerName,
  TAppointmentActions,
  TAvailabilityActions,
} from 'features/Appointment';
import {
  currencyReducer,
  currencyReducerName,
  TCurrencyActions,
} from 'features/Currency';
import {
  directBookingReducer,
  directBookingReducerName,
  TDirectBookingActions,
} from 'features/DirectBooking/directBookingSlice';
import {
  intakeReducer,
  intakeReducerName,
  TIntakeActions,
} from 'features/Intake/intakeSlice';
import {
  memberReducer,
  memberReducerName,
  TMemberActions,
} from 'features/Member';
import {
  progressReducer,
  progressReducerName,
  TProgressActions,
} from 'features/Member/Progress/progressSlice';
import {
  selfAwarenessReducer,
  selfAwarenessReducerName,
  TSelfAwarenessActions,
} from 'features/Member/SelfAwareness/selfAwarenessSlice';
import {
  messagingReducer, // TMessagingActions,
  messagingReducerName,
} from 'features/Messaging';
import {
  notificationReducer,
  notificationReducerName,
  TNotificationActions,
} from 'features/Notification';
import {
  paymentReducer,
  paymentReducerName,
  TPaymentActions,
} from 'features/Payment';
import {
  providerReducer,
  providerReducerName,
  TProviderActions,
} from 'features/Provider';
import {
  airtableReducer,
  airtableReducerName,
  TAirtableActions,
} from 'features/Provider/Airtable';
import {
  digitalPracticeReducer,
  digitalPracticeReducerName,
  TDigitalPracticeActions,
} from 'features/Provider/DigitalPractice/digitalPracticeSlice';
import {
  TWellniteContentActions,
  wellniteContentReducer,
  wellniteContentReducerName,
} from 'features/Provider/Marketing/WellniteContent/wellniteContentSlice';
import {
  pushNotificationReducer,
  pushNotificationReducerName,
  TPushNotificationActions,
} from 'features/PushNotification';
import {TUserActions, userReducer, userReducerName} from 'features/User';
import {TVideoActions, videoReducer, videoReducerName} from 'features/Video';
import {
  TWellniteExerciseActions,
  wellniteExerciseReducer,
  wellniteExerciseReducerName,
} from 'features/WellniteExercise/wellniteExerciseSlice';
import {LocationDescriptorObject} from 'history';
import {history} from 'utils';

import {combineReducers} from '@reduxjs/toolkit';

import {uiReducer, uiReducerName} from './uiReducer';

import {ratingReducer, ratingReducerName, TRatingActions} from 'Rating';

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  messaging: messagingReducer,
  video: videoReducer,
  payment: paymentReducer,
  member: memberReducer,
  progress: progressReducer,
  appointment: appointmentReducer,
  notification: notificationReducer,
  pushNotification: pushNotificationReducer,
  provider: providerReducer,
  airtable: airtableReducer,
  digitalPractice: digitalPracticeReducer,
  router: connectRouter(history),
  availability: availabilityReducer,
  wellniteContent: wellniteContentReducer,
  selfAwareness: selfAwarenessReducer,
  directBooking: directBookingReducer,
  currency: currencyReducer,
  intake: intakeReducer,
  rating: ratingReducer,
  wellniteExercise: wellniteExerciseReducer,
});

export type AppActions =
  | TUserActions
  | TPaymentActions
  | TMemberActions
  | TProgressActions
  // | TMessagingActions
  | TAppointmentActions
  | TAvailabilityActions
  | TWellniteContentActions
  | TSelfAwarenessActions
  | TNotificationActions
  | TVideoActions
  | TRatingActions
  | TPushNotificationActions
  | TProviderActions
  | TAirtableActions
  | TDigitalPracticeActions
  // eslint-disable-next-line @typescript-eslint/ban-types
  | CallHistoryMethodAction<[LocationDescriptorObject<unknown>]>
  | TDirectBookingActions
  | TCurrencyActions
  | TIntakeActions
  | TWellniteExerciseActions;

export type StateSlices =
  | typeof userReducerName
  | typeof uiReducerName
  | typeof paymentReducerName
  | typeof memberReducerName
  | typeof progressReducerName
  | typeof appointmentReducerName
  | typeof availabilityReducerName
  | typeof wellniteContentReducerName
  | typeof selfAwarenessReducerName
  | typeof notificationReducerName
  | typeof messagingReducerName
  | typeof videoReducerName
  | typeof ratingReducerName
  | typeof pushNotificationReducerName
  | typeof providerReducerName
  | typeof airtableReducerName
  | typeof digitalPracticeReducerName
  | typeof directBookingReducerName
  | typeof currencyReducerName
  | typeof intakeReducerName
  | typeof wellniteExerciseReducerName;

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
