import {
  CountryOfResidenceType,
  RegisterFormData,
  ResultEmployersType,
  StripePlanDurations,
  StripePlanNames,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const outSideWellniteRegister = createAction<
  RegisterFormData & {
    referralSource?: string;
    paymentPlan: StripePlanNames;
    duration: StripePlanDurations;
    socialOnboardingExtraFlow?: string;
    providerId: string;
    countryOfResidence: CountryOfResidenceType;
  }
>('OutsideWellniteRegister');
export const outsideWellniteRegisterSuccess = createAction(
  'OutsideWellniteRegisterSuccess',
);
export const outSideWellniteRegisterFailure = createAction(
  'OutsideWellniteRegisterFailure',
);

export const getEmployers = createAction<{
  size: number;
  offset?: string;
}>('getEmployers');
export const getEmployersSuccess = createAction<{
  data: ResultEmployersType;
}>('getEmployersSuccess');
export const getEmployersFailure = createAction('getEmployersFailure');

export const searchEmployers = createAction<{
  size: number;
  offset?: string;
  searchKeyword: string;
}>('searchEmployers');
export const searchEmployersSuccess = createAction<{
  data: ResultEmployersType;
}>('searchEmployersSuccess');
export const searchEmployersFailure = createAction('searchEmployersFailure');
export const resetSearchEmployers = createAction('resetSearchEmployers');
