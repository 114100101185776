import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {DirectBookingState} from './directBookingSlice';

function getDirectBookingState(state: RootState): DirectBookingState {
  return state.directBooking;
}

// Direct Booking Options
export const selectDirectBookingOptions = createSelector(
  getDirectBookingState,
  directBooking => directBooking.filterOptions,
);

// Direct Booking Searched Providers
export const selectDirectBookingSearchedProviders = createSelector(
  getDirectBookingState,
  directBooking => directBooking.providers,
);

export const getDirectBookingSelectedProvider = createSelector(
  getDirectBookingState,
  directBooking => directBooking.selectedProvider,
);

export const selectProvider = (providerId: string) =>
  createSelector(
    getDirectBookingState,
    directBooking =>
      directBooking.providers.data.find(p => p.userId === providerId) ??
      directBooking.provider,
  );

export const getDirectBookingSession = createSelector(
  getDirectBookingState,
  directBooking => directBooking.selectedSession,
);
