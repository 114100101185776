import * as Yup from 'yup';

export const SelfAwarenessFormSchema = Yup.object().shape({
  name: Yup.string().required('Please Enter your name'),
  phoneNumber: Yup.string()
    .required('Required')
    .test('invalid', 'Phone number must be valid.', phone =>
      /^\+?\d{0,4}\s?-?\s?\(?\d{3}\)?\s?-?\s?\(?\d{3}\)?\s?-?\s?\(?\d{4}\)?$/.test(
        phone ?? '',
      ),
    ),
  relationshipType: Yup.string().required('Please choose an Option'),
  howWellDoYouKnowMe: Yup.string().required('Please choose an Option'),
  wouldYouConsiderMeAnIntrovert: Yup.string().required(
    'Please choose an Option',
  ),
  stabilityChoice: Yup.string().required('Please choose an Option'),
  sensitivityOption: Yup.string().required('Please choose an Option'),
  abilityToCommunicate: Yup.string().required('Please choose an Option'),
  lifestyleQuestion: Yup.string().required('Please choose an Option'),
  adaptabilityQuestion: Yup.string().required('Please choose an Option'),
  askingForHelp: Yup.string().required('Please choose an Option'),
  confidenceLevel: Yup.string().required('Please choose an Option'),
});
