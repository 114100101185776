import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {SliceStatus} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import {NotesService} from 'services/api/Notes';

import {providerActions} from '../providerSlice';

export const getNotesEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.getNotes.match),
    mergeMap(({payload}) =>
      from(NotesService.getNotes(payload)).pipe(
        mergeMap(res => {
          return [providerActions.getNotesSuccess(res)];
        }),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getNotesFailure()),
          ),
        ),
      ),
    ),
  );

const getNotesFailureEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(providerActions.getNotesFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.provider.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.provider.error}`});
    }),
    map(() => userActions.resetAsyncError('provider')),
  );

export const createNotesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.createNotes.match),
    switchMap(({payload: {onNoteCreateFailed, onNoteCreateSuccess, note}}) =>
      from(NotesService.createNote(note)).pipe(
        concatMap(({normalizedNotes}) => {
          onNoteCreateSuccess(Object.keys(normalizedNotes)[0]);
          return of(normalizedNotes).pipe(
            mergeMap(() => [
              providerActions.createNotesSuccess(normalizedNotes),
            ]),
          );
        }),
        catchError((message: string) =>
          concat(of(providerActions.createNotesFailure(message))).pipe(
            tap(() => onNoteCreateFailed()),
          ),
        ),
      ),
    ),
  );

const createNotesFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.createNotesFailure.match),

    tap(({payload: message}) => {
      Toast({type: 'error', message});
    }),
    ignoreElements(),
  );

export const updateNotesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateNote.match),
    switchMap(({payload: {note, goToNotesList, isToast, toastMsg}}) =>
      from(NotesService.updateNote(note)).pipe(
        concatMap(({normalizedNotes}) => {
          if (isToast && typeof goToNotesList === 'function') {
            goToNotesList();

            if (toastMsg) {
              Toast({
                type: 'success',
                message: toastMsg,
                position: 'bottom-left',
                autoClose: 3000,
                closeOnClick: true,
              });
            }
          }
          return of(normalizedNotes).pipe(
            mergeMap(() => [
              providerActions.updateNoteSuccess(normalizedNotes),
            ]),
          );
        }),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.updateNoteFailure()),
          ),
        ),
      ),
    ),
  );

const updateNotesSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateNoteSuccess.match),
    delay(4000),
    map(() => providerActions.resetNoteStatus(SliceStatus.idle)),
  );

export const generateNotesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.generateNote.match),
    switchMap(
      ({
        payload: {
          prompt,
          userRole,
          patientId,
          appointmentID,
          preferredLanguage,
          goToCreatedNote,
          onNoteGenerateFailed,
          isFailedToast,
          isToast,
          toastMsg,
        },
      }) =>
        from(
          NotesService.generateNote({
            prompt,
            userRole,
            patientId,
            appointmentID,
            preferredLanguage,
          }),
        ).pipe(
          concatMap(({message}) => {
            const {content} = message;
            if (isToast) {
              if (toastMsg) {
                Toast({
                  type: 'success',
                  message: toastMsg,
                  position: 'bottom-left',
                  autoClose: 3000,
                  closeOnClick: true,
                });
              }
            }
            return of(message).pipe(
              mergeMap(() => [
                providerActions.generateNotesSuccess({
                  goToCreatedNote,
                  content,
                }),
              ]),
            );
          }),
          catchError((message: string) =>
            concat(
              of(userActions.setAsyncError({filter: 'provider', message})),
              of(
                providerActions.generateNotesFailure({message, isFailedToast}),
              ),
            ).pipe(tap(() => onNoteGenerateFailed())),
          ),
        ),
    ),
  );

const generateNotesSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.generateNotesSuccess.match),
    map(({payload}) => {
      const {goToCreatedNote, content} = payload;
      goToCreatedNote(content);
      return providerActions.resetNoteStatus(SliceStatus.idle);
    }),
  );

const generateNotesFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.generateNotesFailure.match),
    tap(({payload: {message, isFailedToast}}) => {
      if (isFailedToast) {
        Toast({
          type: 'info',
          message: "Patient's issues text has been copied to clipboard",
          position: 'bottom-right',
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const generateNotesPreviousInputsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getGeneratedNotePreviousInputs.match),
    mergeMap(({payload: {userRole, patientId, prefillInputs}}) =>
      from(
        NotesService.getGeneratedNotePreviousInputs({
          userRole,
          patientId,
        }),
      ).pipe(
        timeout(2500),
        map(({message}) => {
          const {previousInputs} = message;
          prefillInputs(previousInputs);
          if (previousInputs.mentalHealthConditions) {
            Toast({
              type: 'info',
              message: 'Retrieved Previous Inputs',
              position: 'bottom-right',
              autoClose: 3000,
              closeOnClick: true,
              pauseOnHover: true,
            });
          }
          return providerActions.resetNoteStatus(SliceStatus.resolved);
        }),
        catchError(error => {
          console.error(
            'API request timed out or encountered an error:',
            error,
          );
          return of(providerActions.resetNoteStatus(SliceStatus.resolved));
        }),
      ),
    ),
  );

export const createCMS1500FormEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(providerActions.createCMS1500Form.match),
    withLatestFrom(state$),
    mergeMap(([{payload}, currState]) =>
      from(
        NotesService.createCMS1500Form({
          userRole: currState.user.current!.role,
          noteId: payload.noteId,
        }),
      ).pipe(
        mergeMap(({normalizedNotes}) => {
          return [providerActions.createCMS1500FormSuccess(normalizedNotes)];
        }),
        catchError((message: string) =>
          concat(of(providerActions.createCMS1500FormFailure(message))),
        ),
      ),
    ),
  );

const createCMS1500FormFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.createCMS1500FormFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

const createCMS1500FormSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.createCMS1500FormSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: 'CMS1500 Form Submitted Successfully',
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );
export const notesEpics = [
  getNotesEpic,
  getNotesFailureEpic,
  createNotesEpic,
  createNotesFailureEpic,
  updateNotesEpic,
  updateNotesSuccessEpic,
  generateNotesEpic,
  generateNotesSuccessEpic,
  generateNotesFailureEpic,
  generateNotesPreviousInputsEpic,
  createCMS1500FormEpic,
  createCMS1500FormFailureEpic,
  createCMS1500FormSuccessEpic,
];
