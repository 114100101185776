import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {
  catchError,
  concat,
  concatMap,
  filter,
  from,
  ignoreElements,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import {SelfAwarenessService} from 'services/api';

import {selfAwarenessActions} from './selfAwarenessSlice';

export const saveSelfAwarenessDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.saveSelfAwarenessData.match),
    mergeMap(({payload: {callback, ...rest}}) =>
      from(SelfAwarenessService.saveSelfAwarenessData(rest)).pipe(
        concatMap(() => {
          callback();
          return of(selfAwarenessActions.saveSelfAwarenessDataSuccess());
        }),
        catchError((message: string) =>
          concat(
            of(selfAwarenessActions.saveSelfAwarenessDataFailure(message)),
          ),
        ),
      ),
    ),
  );

export const saveSelfAwaarenessDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.saveSelfAwarenessDataFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const saveSelfAwarenessDataSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.saveSelfAwarenessDataSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: 'Your feedback has been submitted successfully',
      });
    }),
    ignoreElements(),
  );
export const getSelfAwarenessDataRequestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.getSelfAwarenessDataRequest.match),
    mergeMap(({payload}) =>
      from(SelfAwarenessService.getSelfAwarenessData(payload)).pipe(
        mergeMap(data => [
          selfAwarenessActions.getSelfAwarenessDataSuccess(data),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'selfAwareness',
                message,
              }),
            ),
            of(selfAwarenessActions.getSelfAwarenessDataFailure(message)),
          ),
        ),
      ),
    ),
  );

const getSelfAwarenessDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.getSelfAwarenessDataFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const getSelfAwarenessPdfRequestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.getSelfAwarenessPdfRequest.match),
    mergeMap(({payload: {docId, onSuccess, onError}}) =>
      from(SelfAwarenessService.getSelfAwarenessPdf(docId, false)).pipe(
        concatMap(data => {
          onSuccess(data);
          return of(selfAwarenessActions.getSelfAwarenessPdfSuccess());
        }),
        catchError((message: string) => {
          onError();
          return concat(
            of(
              userActions.setAsyncError({
                filter: 'selfAwareness',
                message,
              }),
            ),
            of(selfAwarenessActions.getSelfAwarenessPdfFailure(message)),
          );
        }),
      ),
    ),
  );

const getSelfAwarenessPdfFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.getSelfAwarenessPdfFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const downloadSelfAwarenessPdfRequestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.downloadSelfAwarenessPdfRequest.match),
    mergeMap(({payload: {docId, password, onSuccess, onError}}) =>
      from(
        SelfAwarenessService.getSelfAwarenessPdf(docId, true, password),
      ).pipe(
        concatMap(data => {
          onSuccess(data);
          return of(selfAwarenessActions.downloadSelfAwarenessPdfSuccess());
        }),
        catchError((message: string) => {
          onError();
          return concat(
            of(
              userActions.setAsyncError({
                filter: 'selfAwareness',
                message,
              }),
            ),
            of(selfAwarenessActions.downloadSelfAwarenessPdfFailure(message)),
          );
        }),
      ),
    ),
  );

const downloadSelfAwarenessPdfFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(selfAwarenessActions.downloadSelfAwarenessPdfFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const selfAwarenessEpics = [
  saveSelfAwarenessDataEpic,
  saveSelfAwaarenessDataFailureEpic,
  saveSelfAwarenessDataSuccessEpic,
  getSelfAwarenessDataRequestEpic,
  getSelfAwarenessDataFailureEpic,
  getSelfAwarenessPdfRequestEpic,
  getSelfAwarenessPdfFailureEpic,
  downloadSelfAwarenessPdfRequestEpic,
  downloadSelfAwarenessPdfFailureEpic,
];
