import React, {FC, Fragment} from 'react';
import {languages} from 'definitions/Languages';
import {memberActions} from 'features/Member';
import {providerActions} from 'features/Provider';
import {selectUserProfile} from 'features/User';
import {useDispatch, useSelector} from 'react-redux';
import {isTherapist, isUserFromUnitedStates} from 'utils';

import {Popover, Transition} from '@headlessui/react';

import {CaretDownIcon, GlobeIcon} from '../SvgIcon/Pointers';

export const LanguageSelector: FC<{classes?: string}> = ({classes = ''}) => {
  const user = useSelector(selectUserProfile);
  const dispatch = useDispatch();
  const onLanguageSelect = (lang: string) => {
    if (isTherapist(user)) {
      dispatch(
        providerActions.updateProviderPreferredLanguage({
          preferredLanguage: lang,
          role: user.role,
        }),
      );
    } else {
      dispatch(
        memberActions.changePreferredLanguage({
          preferredLanguage: lang,
        }),
      );
    }
  };

  if (user?.countryOfResidence && !isUserFromUnitedStates(user)) {
    return (
      <Popover>
        <div className={classes}>
          <Popover.Button className="group inline-flex items-center rounded-full px-3 py-2 text-base font-medium gap-1 bg-blue-600/10 group">
            <GlobeIcon
              strokeColor="text-gray-700"
              classes="group-hover:text-blue-600"
            />
            <CaretDownIcon
              fillColor="#6c6c6c"
              strokeWidth={0.2}
              strokeColor="text-gray-700"
              classes="fill-current group-hover:text-blue-600"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              {({close}) => (
                <div className="overflow-hidden rounded-lg px-3 py-2 shadow-lg ring-1 ring-black/5 bg-white">
                  {languages.map(lang => (
                    <button
                      key={lang.label}
                      className="cursor-pointer my-2 w-full p-2 hover:bg-blue-600/10 text-left rounded-lg"
                      onClick={() => {
                        onLanguageSelect(lang.value);
                        close();
                      }}
                    >
                      {lang.label}
                    </button>
                  ))}
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </div>
      </Popover>
    );
  }

  return null;
};
