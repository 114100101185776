import {format, getDate, getYear, isAfter, isEqual} from 'date-fns';
import {zonedTimeToUtc} from 'date-fns-tz';
import {countriesTimeZones} from 'definitions/SupportedCountries';
import {Appointment} from 'interfaces';
import {Modifier} from 'react-day-picker';
import {convertDateToDiffTimeZone, dateTimeStringCompatibility} from 'utils';

type ProviderCalenderType = {
  availabilityDate: string;
  availabilityTimes: string[];
  _id?: string;
};

type TimeZone = {
  timezone: string;
  utcOffset: string;
  gmtoffset: string;
  default: boolean;
};

export const americaTimeZones = [
  'america/adak', // unknown
  'pacific/honolulu',
  'america/anchorage',
  'america/juneau',
  'america/metlakatla',
  'america/nome',
  'america/sitka',
  'america/yakutat',
  'america/los_angeles',
  'america/boise',
  'america/denver',
  'america/phoenix',
  'america/chicago',
  'america/indiana/knox',
  'america/indiana/tell_city',
  'america/menominee',
  'america/north_dakota/beulah',
  'america/north_dakota/center',
  'america/north_dakota/new_salem',
  'america/detroit',
  'america/indiana/indianapolis',
  'america/indiana/marengo',
  'america/indiana/petersburg',
  'america/indiana/vevay',
  'america/indiana/vincennes',
  'america/indiana/winamac',
  'america/kentucky/louisville',
  'america/kentucky/monticello',
  'america/new_york',
];

const getYearFromDate = (date: Date): number => getYear(date);
const getDayOfMonthFromDate = (date: Date): number => getDate(date);
const getFormattedMonthNumber = (date: Date): number =>
  Number(format(date, 'M'));

export const getDefaultNow = (): {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  millisecond: number;
} => {
  const now = new Date();
  return {
    year: getYearFromDate(now),
    month: getFormattedMonthNumber(now),
    day: getDayOfMonthFromDate(now),
    hour: now.getHours(),
    minute: now.getMinutes(),
    second: now.getSeconds(),
    millisecond: now.getMilliseconds(),
  };
};

export const roundDownToNearestFive = (value: number): number =>
  Math.floor(value / 5) * 5;

export const getFormattedOffset = (
  value: string,
  index: number,
  char: string,
): string => `${value.substring(0, index)}${char}${value.substring(index)}`;

export const getCountryDefaultTimeZone = (countryCode: string): string => {
  let defaultTimeZone: string = '';

  const countryTimeZones = countriesTimeZones[countryCode].timezones;

  countryTimeZones.map((element: TimeZone) => {
    if (element.default) {
      defaultTimeZone = element.timezone;
      return true;
    } else return false;
  });
  return defaultTimeZone.toLowerCase();
};

export const isAmericanRegion = (region: string): boolean => {
  const name = region.toLowerCase();
  if (americaTimeZones.indexOf(name) === -1) {
    return false;
  }
  return true;
};

export const isCountryOfResidenceRegion = (
  region: string,
  countryOfResidenceCode: string,
): boolean => {
  const countryTimeZones = countriesTimeZones[countryOfResidenceCode].timezones;

  const timezones = countryTimeZones?.map((element: TimeZone) => {
    return element.timezone.toLowerCase();
  });

  if (timezones?.indexOf(region?.toLowerCase()) === -1) return false;
  else return true;
};

export const removePastOpeningsFromCalendar = (
  availableDays: {
    availabilityDate: string;
    availabilityTimes: string[];
  }[],
): {
  availabilityDate: string;
  availabilityTimes: string[];
}[] => {
  const now = new Date(new Date().setHours(0, 0, 0, 0));
  const filteredAvailableDays = availableDays.filter(({availabilityDate}) => {
    const formattedDate = dateTimeStringCompatibility(availabilityDate);
    return (
      isEqual(new Date(formattedDate), now) ||
      isAfter(new Date(formattedDate), now)
    );
  });
  if (filteredAvailableDays.length) {
    const formattedFirstDate = dateTimeStringCompatibility(
      filteredAvailableDays[0].availabilityDate,
    );
    if (isEqual(new Date(formattedFirstDate), now)) {
      const filteredTodayTimes =
        filteredAvailableDays[0].availabilityTimes.filter(t => {
          let nth = 0;
          const safariFix = t
            .replace(/-/g, match => {
              nth += 1;
              return nth < 3 ? '/' : match;
            })
            .replace('T', ' ');
          return isAfter(new Date(safariFix), new Date());
        });

      const finalRes = filteredAvailableDays.slice(1);
      finalRes.unshift({
        ...filteredAvailableDays[0],
        availabilityTimes: filteredTodayTimes,
      });
      return finalRes;
    }
  }
  return filteredAvailableDays;
};

export const getDisabledDays = (
  providerCalender: ProviderCalenderType[],
  isProvider: boolean,
  limitTo?: Date,
): Modifier | Modifier[] => {
  let divider: Date;
  if (isProvider) {
    divider = new Date(new Date().setHours(0, 0, 0, 0));
  } else {
    divider = new Date(new Date().setHours(24, 0, 0, 0));
  }
  //Disable days outside of current day and end dates
  const getOutsideRange = (
    providerCalendar: ProviderCalenderType[],
  ): Modifier => {
    if (providerCalendar.length === 0) {
      return {
        before: divider,
        after: divider,
      };
    }
    const formattedEndDay = dateTimeStringCompatibility(
      providerCalendar.slice(-1)[0]?.availabilityDate,
    );

    return {
      before: divider,
      after: limitTo ? limitTo : new Date(formattedEndDay),
    };
  };
  const rangeDates = getOutsideRange(providerCalender);
  //function to generate array of datestrings that fall within start and end dates
  const generateDatesArray = (
    providerCalendar: ProviderCalenderType[],
  ): string[] => {
    if (!providerCalendar[0]?.availabilityDate) return [];

    const firstDate = new Date(providerCalendar[0]?.availabilityDate);
    const lastDate = new Date(providerCalendar.slice(-1)[0]?.availabilityDate);

    // Set to the first day of the month
    firstDate.setDate(1);
    // Set to the last day of the month
    lastDate.setMonth(lastDate.getMonth() + 1, 0);

    let arr: string[] = [];
    for (
      let date = new Date(firstDate);
      date.getTime() <= lastDate.getTime();
      date.setDate(date.getDate() + 1)
    ) {
      arr = [...arr, new Date(date).toDateString()];
    }
    return arr;
  };

  //Get all available dates in datestring
  const getAvailableDatesArray = (
    providerCalender: ProviderCalenderType,
  ): string => {
    const formattedAvailabilityDate = dateTimeStringCompatibility(
      providerCalender.availabilityDate,
    );
    return new Date(formattedAvailabilityDate).toDateString();
  };
  const datesArray = generateDatesArray(providerCalender);
  const availableDatesArray = providerCalender.map(getAvailableDatesArray);

  // Filter all dates for dates not included in all available dates.
  const unavailableDates = datesArray
    .filter(date => !availableDatesArray.includes(date))
    .map(date => new Date(date));
  //return the modifier
  return [rangeDates, ...unavailableDates];
};

export const getAvailableTimeData = (
  memberTimeZone: string,
  availableTimes: string[],
): {value: string; rawTime: string; disabled: boolean}[] =>
  availableTimes.map(time => {
    const num = convertDateToDiffTimeZone(time, memberTimeZone);
    return {
      value: num,
      rawTime: time,
      disabled: false,
    };
  });

export const getAppointmentDateTimeString = (
  values: {
    timezone: string;
    date: Date;
    time: string;
  },
  providerCalendar: {
    availabilityDate: string;
    availabilityTimes: string[];
  }[],
): {error?: string; datetime?: string} => {
  const selectedDate = format(new Date(values.date as Date), 'yyyy-MM-dd');
  const dateAvailabilityInfo =
    Array.isArray(providerCalendar) &&
    providerCalendar.find(day => day.availabilityDate === selectedDate);

  if (!dateAvailabilityInfo) {
    return {
      error: 'Please select a date and time marked as available.',
    };
  }
  const {availabilityTimes} = dateAvailabilityInfo;
  const appointmentDateTimeString = availabilityTimes.find(
    time => convertDateToDiffTimeZone(time, values.timezone) === values.time,
  );
  if (!appointmentDateTimeString) {
    return {
      error: 'Please select a date and time marked as available.',
    };
  }

  return {
    datetime: appointmentDateTimeString,
  };
};

export const getFormattedMemberAppointmentDate = ({
  timeOfAppointment,
  dateOfAppointment,
  timeZone,
}: Appointment): Date => {
  const isPM = timeOfAppointment.endsWith('pm');
  let localTime: Date;
  const [hour, minute] = timeOfAppointment.slice(0, -2).split(':');
  let twentyFourHour: string | number = '';
  if (Number(hour) === 12) {
    twentyFourHour = isPM ? hour : '00';
  } else {
    twentyFourHour = isPM ? Number(hour) + 12 : hour;
  }

  try {
    localTime = zonedTimeToUtc(
      new Date(`${dateOfAppointment} ${twentyFourHour}:${minute}`),
      timeZone,
    );
  } catch (error) {
    const pad = (number: number, length: number) => {
      let str = `${number}`;
      while (str.length < length) {
        str = `0${str}`;
      }
      return str;
    };

    const offset = new Date().getTimezoneOffset();
    const formattedOffset =
      (offset < 0 ? '+' : '-') + // Note the reversed sign!
      pad(Math.floor(Math.abs(offset / 60)), 2) +
      pad(Math.abs(offset % 60), 2);
    localTime = new Date(
      `${dateOfAppointment} ${twentyFourHour}:${minute}${formattedOffset}`,
    );
  }

  return localTime;
};
