import {
  MemberProfile,
  OnboardingShippingProps,
  ProfileImageFormData,
} from 'interfaces';
import {Response} from 'redaxios';
import {toFormData, unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const verifyPhone = async ({
  phoneNumber,
}: {
  phoneNumber: string;
}): Promise<void> => {
  try {
    await requestHandler<{message: string}>({
      method: HttpMethods.GET,
      url: `${EndPoints.VerifyPhone}?phoneNumber=${phoneNumber}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const verifyPassword = async (data: {password: string}): Promise<void> => {
  try {
    await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.VerifyPassword,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changePassword = async (data: {
  password: string;
  confirmPassword: string;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.UpdateAccount,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changeEmail = async (data: {
  email: string;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.UpdateAccount,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changeAddress = async (
  data: OnboardingShippingProps,
): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.UpdateAccount,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changePhone = async ({
  verificationCode,
  phoneNumber,
}: {
  verificationCode: string;
  phoneNumber: string;
}): Promise<void> => {
  try {
    await requestHandler<{message: string}>({
      method: HttpMethods.GET,
      url: `${EndPoints.VerifyPhoneToken}?phoneNumber=${phoneNumber}&verificationCode=${verificationCode}` as unknown as EndPoints,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changeProfilePicture = async (
  data: ProfileImageFormData,
): Promise<Response<{message: MemberProfile}>> => {
  const formData = toFormData(data);

  try {
    const res = await requestHandler<{message: MemberProfile}, typeof formData>(
      {
        method: HttpMethods.POST,
        url: EndPoints.UpdateAccount,
        data: formData,
      },
    );
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changePreferredLanguage = async (data: {
  preferredLanguage: string;
}): Promise<Response<{message: MemberProfile}>> => {
  try {
    const res = await requestHandler<{message: MemberProfile}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.UpdateAccount,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const AccountService = {
  verifyPassword,
  changePassword,
  verifyPhone,
  changeEmail,
  changePhone,
  changeAddress,
  changeProfilePicture,
  changePreferredLanguage,
};
