import {UserRoles} from './CurrentUser.types';
import {PaymentService} from './Payment.types';

type ParticipantActivities = {
  email: string;
  role: string;
  activity: string;
  activityTime: Date;
};

type SessionActivities = {
  activity: string;
  activityTime: Date;
};

export enum ChargeStatus {
  charged = 'charged',
  scheduled = 'scheduled',
  systemFailure = 'system-failure',
  clientFailure = 'client-failure',
  notCharged = 'not-charged',
}

export type Meeting = {
  patientId: string;
  meetingName: string;
  providerId: string;
  providerRole: UserRoles;
  startDate: Date;
  endDate: Date;
  roomUrl: string;
  roomMode: string;
  meetingId: string;
  hostRoomUrl: string;
  appointmentID: number;
  appointmentLabel: {
    label: string;
    value: number;
  };
  appointmentCharge: number;
  appointmentChargeCurrency: string;
  chargeStatus: ChargeStatus;
  participantActivities: ParticipantActivities[];
  sessionActivities: SessionActivities[];
  createdAt: string;
  updatedAt: string;
  chargeError?: {
    errorCode: string;
    paymentMethod: PaymentService;
    message: string;
  };
  paymentRequestedAt?: string;
};
