import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {AirtableState} from '.';

function getAirtableProviderCreds(state: RootState): AirtableState {
  return state.airtable;
}

export const selectAirtableProviderData = createSelector(
  getAirtableProviderCreds,
  airtable => airtable.providerData,
);

export const selectAirtableProviderFieldsOptions = createSelector(
  getAirtableProviderCreds,
  airtable => airtable.providerFieldsOptions,
);

export const selectAirtableStatus = createSelector(
  getAirtableProviderCreds,
  airtable => airtable.status,
);

export const selectInsuranceAndColleagues = createSelector(
  getAirtableProviderCreds,
  airtable => airtable.insuranceAndColleagues,
);
