import React, {FC, ReactNode, useState} from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxLabel = styled.label<{fontSize?: string}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
`;

const CheckboxInput = styled.input`
  display: none;
`;

const Checkmark = styled.div<{checked: boolean; checkBoxSize?: string}>`
  width: ${props => (props.checkBoxSize ? props.checkBoxSize : '20px')};
  height: ${props => (props.checkBoxSize ? props.checkBoxSize : '20px')};
  border: 2px solid #0074d9;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background-color: ${props => (props.checked ? '#0074d9' : 'transparent')};
  transition: background-color 0.2s;
  position: relative;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

interface Props {
  label: string;
  children?: ReactNode;
  onChange: (isChecked: boolean) => void;
  defaultChecked?: boolean;
  className?: string;
  checkmarkClassName?: string | ((isChecked: boolean) => string);
  checkBoxSize?: string;
  fontSize?: string;
}

export const Checkbox: FC<Props> = ({
  label,
  children,
  onChange,
  defaultChecked = false,
  className,
  checkmarkClassName,
  checkBoxSize,
  fontSize,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(!isChecked);
    onChange(newCheckedState);
  };

  return (
    <CheckboxContainer className={`pt-6 ${className}`}>
      <CheckboxLabel fontSize={fontSize}>
        <CheckboxInput
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <Checkmark
          checked={isChecked}
          checkBoxSize={checkBoxSize}
          className={
            typeof checkmarkClassName === 'function'
              ? checkmarkClassName(isChecked)
              : checkmarkClassName
          }
        >
          {isChecked && (
            <Icon viewBox="0 0 24 24" className="absolute">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          )}
        </Checkmark>
        {label}
        {children}
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

export const CheckboxElements = {
  Icon,
  Checkmark,
  Label: CheckboxLabel,
  Container: CheckboxContainer,
  Input: CheckboxInput,
};
