/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {Options, Response} from 'redaxios';

import {EndPoints, HttpMethods} from './HTTP.types';

const hostname = window?.location?.hostname || '';

export function getBaseUrl(): string {
  if (process.env.NODE_ENV === 'development' || hostname.includes('localhost'))
    return 'http://localhost:3001';

  if (hostname.includes('stage')) return 'https://stage.wellnite.co';

  if (hostname.includes('wellnite.co')) return 'https://www.wellnite.co';

  return '';
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = getBaseUrl();

export function requestHandler<T, D = Record<string, unknown>>({
  method,
  url,
  data,
  options = {},
}: {
  method: HttpMethods;
  url: EndPoints;
  data?: D;
  options?: Options;
}): Promise<Response<T>> {
  if (method === 'PATCH') {
    return axios({method, url, data, ...options});
  }

  const httpMethod = method.toLowerCase();
  const hasData = ['post', 'put', 'patch'].indexOf(httpMethod) >= 0;
  const settings = hasData ? options : data;
  const request = hasData
    ? axios[httpMethod](url, data ? data : {}, settings)
    : axios[httpMethod](url, settings);

  return request;
}
