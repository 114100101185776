import {RegisterSchema} from 'definitions/Yup';
import {ProfessionalHeadshotSchema} from 'definitions/Yup/updateProfessionalHeadshotSchema';
import {LicenseCopySchema} from 'definitions/Yup/uploadLicenseCopySchema';
import {CountryOfResidenceType} from 'interfaces';
import * as Yup from 'yup';

export type RegisterFormData = Yup.InferType<typeof RegisterSchema>;
export type RegisterOutsideUSAFormData = {
  fullName: string;
  email: string;
  password: string;
  phoneNumber: string;
  termsOfService: boolean;
  stateOfResidence?: string;
  countryOfResidence?: CountryOfResidenceType;
};

export type ProfessionalHeadshotFormData = Yup.InferType<
  typeof ProfessionalHeadshotSchema
>;
export type LicenseCopyFormData = Yup.InferType<typeof LicenseCopySchema>;

export enum UploadOptions {
  professionalHeadshot = 'professionalHeadshot',
  resume = 'resume',
}
