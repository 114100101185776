import React, {FC} from 'react';
import {Button, PlusIcon} from 'components/Basic';
import {useTranslation} from 'react-i18next';

type IProps = {
  setShowBookingModal: () => void;
  borderColor?: string;
  btnType?: 'primary' | 'secondary' | 'danger' | 'black' | 'custom';
  classes?: string;
};

export const BookButton: FC<IProps> = ({
  setShowBookingModal,
  borderColor = 'transparent',
  btnType,
  classes,
}) => {
  const {t} = useTranslation();
  return (
    <Button
      type="button"
      onClick={setShowBookingModal}
      borderColor={borderColor}
      btnType={btnType}
      borderRadius="lg"
      fontWeight="semibold"
      className={`px-6 py-3 !text-base leading-tight lg:mr-6 ml-auto rounded-full flex items-center gap-2 ${classes}`}
    >
      <PlusIcon /> {t('book', 'Book')}
    </Button>
  );
};
