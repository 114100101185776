import React, {FC, useEffect, useRef} from 'react';
import classnames from 'classnames';
import {InstagramIcon, LinkIcon, SendIcon, TwitterIcon} from 'components';
import {LoadingSpinner, Tooltip} from 'components/Basic';
import {useToggle} from 'hooks';
import {SliceStatus} from 'interfaces';
import log from 'loglevel';
import {Link} from 'react-router-dom';
import {getBaseUrl} from 'services/api/HTTP/axios-config';

type Props = {
  isOpen: boolean;
  messageConfig?: {
    styles: string;
    handleShare(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
    requestStatus: SliceStatus;
    requestMessage: string;
  };
  postConfig?: {
    styles: string;
    posterId: string;
    postId: string;
  };
};

const baseUrl = getBaseUrl();

export const ShareContentMenu: FC<Props> = ({
  isOpen,
  messageConfig,
  postConfig,
}) => {
  const postShareUrl = `/community/share-post/${postConfig?.posterId ?? ''}/${
    postConfig?.postId ?? ''
  }`;
  const postUrl = `/community/${postConfig?.posterId ?? ''}/${
    postConfig?.postId ?? ''
  }`;
  const timerRef = useRef<NodeJS.Timeout>();
  const {toggleOn, onToggleClick} = useToggle();
  const copyTextToClipboard = async (): Promise<void> => {
    if (!navigator.clipboard) {
      const copyText = document.getElementById(postUrl) as HTMLInputElement;
      if (copyText) {
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        try {
          document.execCommand('copy');
        } catch (error) {
          log.warn(error);
        }
      }
      return;
    }
    try {
      await navigator.clipboard.writeText(`${baseUrl}${postUrl}`);
    } catch (error) {
      log.warn(error);
    }
  };
  const handleCopyLink = () => {
    copyTextToClipboard();
    onToggleClick();
    timerRef.current = setTimeout(() => onToggleClick(), 1300);
  };

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <>
      {isOpen && (messageConfig || postConfig) ? (
        messageConfig ? (
          <div
            onClick={messageConfig.handleShare}
            className={classnames(
              'z-20 absolute bg-white w-fit mx-auto border rounded-lg shadow-xl px-2 flex justify-around items-center',
              {
                'text-red-500':
                  messageConfig.requestStatus === SliceStatus.rejected,
                'text-blue-600':
                  messageConfig.requestStatus === SliceStatus.resolved,
                'text-gray-600 ':
                  messageConfig.requestStatus === SliceStatus.idle,
                [messageConfig.styles]: true,
              },
            )}
          >
            {messageConfig.requestStatus === SliceStatus.pending ? (
              <LoadingSpinner type="Oval" height={15} />
            ) : messageConfig.requestStatus === SliceStatus.resolved ? (
              <SendIcon
                strokeColor="text-blue-600"
                strokeWidth={35}
                fillColor="#315eff"
              />
            ) : null}
            <p className="text-xs leading-snug font-medium p-4 whitespace-nowrap cursor-pointer">
              {messageConfig.requestStatus === SliceStatus.idle
                ? messageConfig.requestMessage
                : messageConfig.requestStatus === SliceStatus.pending
                ? 'Requesting'
                : messageConfig.requestStatus === SliceStatus.resolved
                ? 'Request Sent!'
                : 'Try again'}
            </p>
          </div>
        ) : (
          <div
            className={classnames(
              'z-10 absolute bg-white text-center w-fit mx-auto border rounded-lg shadow-xl p-2 flex flex-col justify-around items-center',
              {
                [postConfig!.styles]: true,
              },
            )}
          >
            <p className="font-medium text-sm text-gray-700 mb-4">
              Share this Post
            </p>
            {toggleOn ? (
              <Tooltip content="Copied to clipboard!">
                <p className="mb-4 text-sm text-gray-700">
                  Copied to clipboard!
                </p>
              </Tooltip>
            ) : null}
            <section className="grid grid-cols-3">
              <section className="px-1">
                <Link
                  to={`${postShareUrl}/?shareTo=instagram`}
                  className="w-8 h-8 p-1 mx-2 justify-center items-center flex rounded-full bg-gradient-to-b from-purple-700  to-orange-500"
                >
                  <InstagramIcon
                    strokeColor="text-white"
                    strokeWidth={0.3}
                    fillColor="#fff"
                  />
                </Link>
                <p className="text-xs mt-1">Instagram</p>
              </section>
              <section className="px-1">
                <Link
                  className="w-8 h-8 mx-auto bg-light-blue-500 rounded-full flex justify-center items-center"
                  to={`${postShareUrl}/?shareTo=twitter`}
                >
                  <TwitterIcon strokeColor="#fff" fillColor="#fff" />
                </Link>
                <p className="text-xs mt-1">Twitter</p>
              </section>
              <section className="px-1">
                <button
                  onClick={handleCopyLink}
                  className="w-8 h-8 mx-auto bg-gray-100 rounded-full flex justify-center items-center"
                >
                  <LinkIcon
                    strokeColor="text-gray-700"
                    strokeWidth={0.3}
                    fillColor="#4a4a4a"
                  />
                </button>
                <p className="text-xs mt-1">Copy Link</p>
              </section>
            </section>
            <input
              id={postUrl}
              className="hidden"
              value={`${baseUrl}${postUrl}`}
            />
          </div>
        )
      ) : null}
    </>
  );
};
