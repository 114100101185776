import React, {FC} from 'react';
import CreditCards from 'assets/images/creditcard.png';
import CreditCardsWebp from 'assets/images/creditcard.webp';
import CreditCards2x from 'assets/images/creditcard@2x.png';
import CreditCardsWebp2x from 'assets/images/creditcard@2x.webp';
import CreditCards3x from 'assets/images/creditcard@3x.png';
import CreditCardsWebp3x from 'assets/images/creditcard@3x.webp';
import Secure from 'assets/images/secure-server.jpg';
import SecureWebp from 'assets/images/secure-server.webp';
import Secure2x from 'assets/images/secure-server@2x.jpg';
import SecureWebp2x from 'assets/images/secure-server@2x.webp';
import Secure3x from 'assets/images/secure-server@3x.jpg';
import SecureWebp3x from 'assets/images/secure-server@3x.webp';
import {LazyImage} from 'hooks';
//@ts-ignore
import lqip from 'lqip.macro';
import styled from 'styled-components';

const creditCardLqip = lqip('../../assets/images/creditcard.png');

const StyledSection = styled.section`
  &&&&&& img {
    object-fit: none;
  }
`;

export const SecurePayment: FC = () => {
  return (
    <section className="max-w-xs mx-auto pt-12">
      <picture>
        <source
          srcSet={`${SecureWebp}, ${SecureWebp2x} 2x, ${SecureWebp3x}`}
          type="image/webp"
        />
        <source
          srcSet={`${Secure}, ${Secure2x} 2x, ${Secure3x}`}
          type="image/jpg"
        />
        <img
          src={Secure}
          alt="Secure Server"
          className="ml-auto w-24 object-contain"
        />
      </picture>
      <StyledSection className="border rounded border-gray-600">
        <LazyImage
          className="w-32 mx-auto py-1 object-contain"
          aspectRatio={14 / 3}
          lqip={creditCardLqip}
          src={CreditCards}
          srcSet={`${CreditCards}, ${CreditCards2x} 2x, ${CreditCards3x}`}
          webp={`${CreditCardsWebp}, ${CreditCardsWebp2x} 2x, ${CreditCardsWebp3x}`}
          type="image/jpg"
          alt="Credit Cards"
        />
      </StyledSection>
    </section>
  );
};
