import {useCallback, useEffect, useState} from 'react';
import Logo from 'assets/images/logo-blue@3x.png';
import Toast from 'components/Basic/Toast';
import {
  pushNotificationActions,
  selectPushNotification,
} from 'features/PushNotification';
import {useDispatch, useSelector} from 'react-redux';
import shortid from 'shortid';
import {UAParser} from 'ua-parser-js';
import {isMobile, urlBase64ToUint8Array} from 'utils';

const subscribeText = 'Subscribe to Notifications';
const unsubscribeText = 'Unsubscribe from Notifications';

const toastId = shortid.generate();

const parser = new UAParser();

export function usePushSubscriptionStatus(): {
  subscriptionText: string;
  onSubscriptionButtonClicked(event: any): Promise<void>;
} {
  const dispatch = useDispatch();
  const [subscriptionText, setSubscriptionText] = useState('');
  const pushNotification = useSelector(selectPushNotification);

  const displayNotification = (swReg: ServiceWorkerRegistration) => {
    const options = {
      body: 'Thanks for subscribing!',
      badge: Logo,
      //dir: 'ltr',
      tag: 'Welcome-Notification',
      lang: 'en-US',
    };
    try {
      swReg.showNotification('Successfully subscribed!', options);
    } catch (error) {
      console.warn(error);
    }
  };

  const subscribeServiceWorker = async (
    registration: ServiceWorkerRegistration,
  ): Promise<PushSubscriptionJSON | void> => {
    try {
      const pushDetails = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.REACT_APP_VAPID_PUBLIC_KEY || '',
        ),
      });
      return pushDetails.toJSON();
    } catch (error) {
      console.warn(error);
    }
  };

  const configurePushSubscription = useCallback(
    async (isClicked = false) => {
      try {
        const swReg = await navigator.serviceWorker.ready;
        const userSubscriptionData = await subscribeServiceWorker(swReg);
        if (userSubscriptionData && userSubscriptionData.keys) {
          dispatch(
            pushNotificationActions.subscribeUser({
              keys: userSubscriptionData.keys,
              endpoint: userSubscriptionData.endpoint || '',
              isMobile,
              browserName: parser.getBrowser().name || '',
            }),
          );
        }
        // new subscription would be sent to firebase
        if (isClicked) await displayNotification(swReg);
      } catch (error) {
        console.warn(error);
      }
    },
    [dispatch],
  );
  //let check = null
  const onSubscriptionButtonClicked = useCallback(
    async (event: any) => {
      // Let's check if the browser supports notifications
      if (!('Notification' in window)) {
        Toast({
          type: 'info',
          message: 'This browser does not support notification',
          toastId,
        });
      }
      //check = true
      // Let's check whether notification permissions have already been granted
      if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        configurePushSubscription(true);
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== 'denied') {
        try {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            configurePushSubscription(true);
          }
        } catch (error) {}
      }
      event.preventDefault();
    },
    [configurePushSubscription],
  );

  const getSubscriptionState = useCallback(async () => {
    try {
      // Let's check if the browser supports notifications
      if (!('Notification' in window)) {
        Toast({
          type: 'info',
          message: 'This browser does not support notification',
          toastId,
        });
        return;
      }
      if (Notification?.permission) {
        const isPushEnabled = (await Notification.permission) === 'granted';
        if (isPushEnabled && !pushNotification.isSubscribedToPushNotification) {
          configurePushSubscription();
        }
      }
    } catch (error) {}
  }, [
    configurePushSubscription,
    pushNotification.isSubscribedToPushNotification,
  ]);

  useEffect(() => {
    dispatch(pushNotificationActions.checkSubscriptionStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSubscriptionState();
    setSubscriptionText(
      'Notification' in window && Notification.permission !== 'granted'
        ? subscribeText
        : unsubscribeText,
    );
  }, [getSubscriptionState]);

  return {subscriptionText, onSubscriptionButtonClicked};
}
