import React, {FC} from 'react';
import {FormError, FormLabel, LoadingSpinner} from 'components';
import {detachedSearchBarStyles} from 'components/Basic/Form/Select/styles';
import {DetachedDateSelect} from 'components/Basic/Form/V2';
import {addMonths} from 'date-fns';
import {useBooking} from 'hooks';
import {Modifier} from 'react-day-picker';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {AppointmentCalendarContainer} from '../../Calendar2/CalendarContainer.styles';

type Props = {
  isAvailabilityLoading: boolean;
  disabled?: boolean;
  onMonthChange?: (month: Date) => void;
  onMenuOpen?: (isOpen: boolean) => void;
} & Partial<ReturnType<typeof useBooking>>;

const now = new Date();

export const BookingCalendarV2: FC<Props> = ({
  control,
  isAvailabilityLoading,
  disabledDays,
  disabled,
  onMenuOpen,
  ...props
}: Props) => {
  const {t} = useTranslation();

  return (
    <div className="w-full overflow-hidden">
      <FormLabel htmlFor="date">&nbsp;</FormLabel>
      <Controller
        control={control}
        name="date"
        render={({field: {onChange, onBlur, value}}) => {
          return (
            <DetachedDateSelect
              onMenuOpen={onMenuOpen}
              onChange={onChange}
              placeholder={t('selectDate', 'Select Date')}
              month={value as Date}
              fromMonth={now}
              toMonth={addMonths(now, 2)}
              {...(disabled || !value ? {} : {value: value as Date})}
              isDisabled={disabled}
              disabledDays={disabledDays}
              onBlur={onBlur}
              styles={{
                ...detachedSearchBarStyles,
                detachedButton: (isOpen: boolean, hasValue: boolean) => {
                  const styles = detachedSearchBarStyles.detachedButton!(
                    isOpen,
                    hasValue,
                  );
                  return {
                    ...styles,
                    borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
                    color: hasValue ? 'black' : '#6B6B6B',
                  };
                },
              }}
              styledContainer={AppointmentCalendarContainer}
              selectedDays={value as Modifier}
              onMonthChange={props.onMonthChange}
              isLoading={isAvailabilityLoading}
            />
          );
        }}
      />
      {isAvailabilityLoading ? (
        <div className="w-full h-full bg-white bg-opacity-70 z-999 absolute top-0 left-0 flex flex-col justify-center items-center">
          <LoadingSpinner type="Oval" width={20} height={20} />
          <p className="text-sm text-gray-700 pt-3">
            {t('loadingAvailability')}
          </p>
        </div>
      ) : null}
      <FormError />
    </div>
  );
};
