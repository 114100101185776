import React, {ReactText} from 'react';
import i18next from 'i18next';
import {Oval} from 'react-loader-spinner';
import {toast, ToastPosition} from 'react-toastify';
import shortid from 'shortid';
import styled from 'styled-components';
import {getFirstLetterOfNames, ThumbnailColors} from 'utils';

import {
  CloseIcon,
  ToastCheckIcon,
  ToastCloseIcon,
  ToastInfoIcon,
  ToastWarningIcon,
} from '../SvgIcon';

interface IProps {
  type:
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'default'
    | 'promise'
    | 'chat_notification';
  photoUrl?: string;
  title?: string;
  message: string;
  position?: ToastPosition;
  draggable?: boolean;
  autoClose?: number | false;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  toastId?: string;
  updateId?: ReactText;
  callback?: () => void;
  showClose?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
`;
/**
 *
 * @param type toast type i.e. 'info'|'success' |'warning'|'error'|'default'
 * @param title text i.e. string (optional)
 * @param message text i.e. string
 * @param position (optional) toast position
 * @param draggable (optional)
 * @param autoClose (optional)
 * @param closeOnClick (optional)
 * @param pauseOnHover (optional)
 * @param toastId (optional)
 * @param updateId (optional) - Used for updating an existing toast
 * @param photoUrl (optional)
 * @param callback (optional)
 * @returns
 */
const Toast = ({
  type,
  photoUrl,
  title,
  message,
  position,
  draggable,
  autoClose = 5000,
  closeOnClick,
  pauseOnHover,
  toastId = shortid.generate(),
  updateId,
  callback,
  showClose = true,
}: IProps) => {
  if (!position) position = 'bottom-right';

  const toastOptions = {
    type,
    position,
    draggable,
    autoClose,
    closeOnClick,
    pauseOnHover,
    toastId,
    closeButton: false,
    hideProgressBar: true,
  };

  if (type === 'chat_notification' && title) {
    const firstLetterOfName = getFirstLetterOfNames(title);
    const colors =
      ThumbnailColors[
        firstLetterOfName
          .charAt(0)
          .toLowerCase() as keyof typeof ThumbnailColors
      ];
    return toast(
      <Wrapper className="pointer-events-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 mb-3 flex w-full">
        <div className="w-0 flex-1 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              {photoUrl ? (
                <img
                  className="h-10 w-10 rounded-full"
                  src={photoUrl}
                  alt={title}
                />
              ) : (
                <div
                  className="h-10 w-10 rounded-full flex items-center justify-center uppercase"
                  style={{background: colors.bg, color: colors.text}}
                >
                  {firstLetterOfName}
                </div>
              )}
            </div>
            <div className="ml-3 w-0 flex-1">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-600">{message}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col border-l border-gray-200">
          <button
            type="button"
            className="flex w-full items-center justify-center rounded-none rounded-rt-lg border-b border-gray-200 px-4 py-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={() => {
              toast.dismiss(toastId);
              if (typeof callback === 'function') callback();
            }}
          >
            {i18next.t('other.reply')}
          </button>
          <button
            type="button"
            className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent px-4 py-3 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={() => {
              toast.dismiss(toastId);
            }}
          >
            {i18next.t('other.close')}
          </button>
        </div>
      </Wrapper>,
      {
        ...toastOptions,
        type: 'default',
      },
    );
  }

  if (type === 'promise') {
    return toast(
      <Wrapper className="pointer-events-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 mb-3">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <Oval
                color="#315eff"
                secondaryColor="#ccc"
                height={17}
                width={17}
                strokeWidth={5}
              />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900 capitalize">
                {title}
              </p>
              <p className="mt-1 text-sm text-gray-600">{message}</p>
            </div>
          </div>
        </div>
      </Wrapper>,
      {
        ...toastOptions,
        type: 'default',
        autoClose: false,
        closeOnClick: false,
        pauseOnHover: false,
      },
    );
  }

  // If updateId is provided, update the existing toast
  if (updateId && ['success', 'info', 'warning', 'error'].includes(type)) {
    return toast.update(updateId, {
      ...toastOptions,
      type: 'default',
      render: () => {
        const _title = title || type;

        return (
          <Wrapper className="pointer-events-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 mb-3">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {type === 'success' ? (
                    <ToastCheckIcon width={25} height={25} />
                  ) : null}
                  {type === 'error' ? (
                    <ToastCloseIcon width={25} height={25} />
                  ) : null}
                  {type === 'warning' ? <ToastWarningIcon /> : null}
                  {type === 'info' ? (
                    <ToastInfoIcon width={25} height={25} />
                  ) : null}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-gray-900 capitalize">
                    {_title}
                  </p>
                  <div
                    className="mt-1 text-sm text-gray-600"
                    dangerouslySetInnerHTML={{__html: message}}
                  />
                </div>
                {showClose ? (
                  <div className="ml-4 -mt-2 -mr-2 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => toast.dismiss(toastId)}
                    >
                      <span className="sr-only">Close</span>
                      <CloseIcon
                        strokeColor="text-gray-500 hover:text-gray-600"
                        classes="h-5 w-5"
                        strokeWidth={30}
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Wrapper>
        );
      },
    });
  }

  if (['success', 'info', 'warning', 'error'].includes(type)) {
    const _title = title || type;
    return toast(
      <Wrapper className="pointer-events-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 mb-3">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type === 'success' ? (
                <ToastCheckIcon width={25} height={25} />
              ) : null}
              {type === 'error' ? (
                <ToastCloseIcon width={25} height={25} />
              ) : null}
              {type === 'warning' ? <ToastWarningIcon /> : null}
              {type === 'info' ? (
                <ToastInfoIcon width={25} height={25} />
              ) : null}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900 capitalize">
                {_title}
              </p>
              <div
                className="mt-1 text-sm text-gray-600"
                dangerouslySetInnerHTML={{__html: message}}
              />
            </div>
            {showClose ? (
              <div className="ml-4 -mt-2 -mr-2 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => toast.dismiss(toastId)}
                >
                  <span className="sr-only">Close</span>
                  <CloseIcon
                    strokeColor="text-gray-500 hover:text-gray-600"
                    classes="h-5 w-5"
                    strokeWidth={30}
                    aria-hidden="true"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </Wrapper>,
      {
        ...toastOptions,
        type: 'default',
      },
    );
  }
  if (type === 'default') {
    return toast(
      <Wrapper className="pointer-events-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 mb-3">
        <div className="p-4">
          <div className="flex items-start">
            <div className="w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900 capitalize">
                {title}
              </p>
              <div
                className="mt-1 text-sm text-gray-600"
                dangerouslySetInnerHTML={{__html: message}}
              />
            </div>
            {showClose ? (
              <div className="ml-4 -mt-2 -mr-2 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => toast.dismiss(toastId)}
                >
                  <span className="sr-only">Close</span>
                  <CloseIcon
                    strokeColor="text-gray-500 hover:text-gray-600"
                    classes="h-5 w-5"
                    strokeWidth={30}
                    aria-hidden="true"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </Wrapper>,
      {
        ...toastOptions,
        type: 'default',
      },
    );
  }
  return null;
};

export default Toast;
