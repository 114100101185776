import {useEffect, useRef} from 'react';
import {useAppDispatch} from 'app/store';
import Toast from 'components/Basic/Toast';
import {messagingActions, selectParticipant} from 'features/Messaging';
import {
  CometChatMessageType,
  CometFriend,
  CurrentUser,
  MessageReceipt,
  TypingIndicatorTypes,
} from 'interfaces';
import {useSelector} from 'react-redux';
import {MessagingService} from 'services/api';
import {history, isProvider} from 'utils';

import {CometChat} from '@cometchat-pro/chat';

const messageListenerId = 'messageListener' + new Date().getTime();
const userStatusListenerId = 'userStatusListener' + new Date().getTime();

/**
 *
 * @param user - logged in user: CurrentUser
 */
export function useCometChatListener(user: CurrentUser) {
  const dispatch = useAppDispatch();
  const hasUserLoggedInOnCometChat = useRef<boolean>(false);

  const participant = useSelector(selectParticipant);
  const messageRoute = isProvider(user) ? '/provider/message' : '/message';

  const openMessage = (message: CometChatMessageType) => {
    history.push({pathname: messageRoute, state: message});
  };

  const updateMessage = (message: CometChatMessageType) => {
    if (!message) return;

    const {
      data: {text, attachments, metadata},
      sentAt,
      deliveredAt,
      readAt,
      sender,
      type,
      id,
    } = message;
    const lastMessage = {
      text,
      attachments,
      metadata,
      sentAt,
      deliveredAt,
      readAt,
      sender,
      type,
      id,
    };

    dispatch(
      messagingActions.setLastMessage({
        uid: message.sender.uid,
        data: lastMessage,
      }),
    );

    /** if the message window is already  opened, then push the incoming message at the last index */
    if (participant && participant.uid === message.sender.uid) {
      dispatch(messagingActions.addMessage(message));
      MessagingService.markAsRead([message], participant.uid);
    } else {
      /** if the message window isn't opened for the conversation, then increase the unread message count */
      dispatch(messagingActions.setUnreadCount(message.sender.uid));
    }

    MessagingService.markAsDelivered(message);
  };

  const playNotificationSound = () => {
    try {
      new Audio('/notification.mp3').play();
    } catch (error) {
      console.error(error);
    }
  };

  // comet chat listener
  useEffect(() => {
    CometChat.addMessageListener(
      messageListenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage: CometChat.TextMessage) => {
          const message = textMessage as unknown as CometChatMessageType;
          if (message.receiver.uid !== user?._id) return;

          if (window.location.pathname !== messageRoute) {
            dispatch(messagingActions.setUnreadMessageCount(1));

            Toast({
              type: 'chat_notification',
              title: message.sender!.name,
              message: message.data!.text,
              toastId: message.id,
              callback: () => openMessage(message),
              autoClose: false,
            });

            playNotificationSound();
          } else {
            updateMessage(message);
          }
        },
        onMediaMessageReceived: (mediaMessage: CometChat.TextMessage) => {
          const message = mediaMessage as unknown as CometChatMessageType;

          if (message.receiver.uid !== user?._id) return;

          if (window.location.pathname !== messageRoute) {
            dispatch(messagingActions.setUnreadMessageCount(1));

            Toast({
              type: 'chat_notification',
              title: message.sender!.name,
              message: `${message.sender!.name} sent an attachment`,
              toastId: message.id,
              callback: () => openMessage(message),
            });

            playNotificationSound();
          } else updateMessage(message);
        },
        onCustomMessageReceived: (customMessage: CometChat.CustomMessage) => {
          console.log('Custom message received successfully', customMessage);
        },
        onMessagesDelivered: (messageReceipt: CometChat.MessageReceipt) => {
          const receipt = messageReceipt as unknown as MessageReceipt;
          if (receipt) {
            dispatch(
              messagingActions.markMessageAsDelivered({
                uid: receipt.sender.uid,
                messageId: receipt.messageId,
                deliveredAt: receipt.deliveredAt!,
              }),
            );
          }
        },
        onMessagesRead: (messageReceipt: CometChat.MessageReceipt) => {
          const receipt = messageReceipt as unknown as MessageReceipt;
          if (receipt) {
            dispatch(
              messagingActions.markMessageAsRead({
                uid: receipt.sender.uid,
                messageId: receipt.messageId,
                readAt: receipt?.readAt,
              }),
            );
          }
        },
        onTypingStarted: (typingIndicator: CometChat.TypingIndicator) => {
          const typingData = typingIndicator as unknown as TypingIndicatorTypes;

          if (typingData) {
            dispatch(
              messagingActions.setUserTypingStatus({
                uid: typingData.sender.uid,
                typing: true,
              }),
            );
          }
        },
        onTypingEnded: (typingIndicator: CometChat.TypingIndicator) => {
          const typingData = typingIndicator as unknown as TypingIndicatorTypes;

          if (typingData) {
            dispatch(
              messagingActions.setUserTypingStatus({
                uid: typingData.sender.uid,
                typing: false,
              }),
            );
          }
        },
      }),
    );

    /** update user presence status */
    CometChat.addUserListener(
      userStatusListenerId,
      new CometChat.UserListener({
        onUserOnline: (onlineUser: CometChat.User) => {
          const userOnlineData = onlineUser as unknown as CometFriend;

          if (userOnlineData) {
            dispatch(
              messagingActions.setUserPresenceStatus({
                uid: userOnlineData.uid,
                status: userOnlineData.status,
                lastActiveAt: userOnlineData.lastActiveAt,
              }),
            );
          }
        },
        onUserOffline: (offlineUser: CometChat.User) => {
          const userOfflineData = offlineUser as unknown as CometFriend;
          if (userOfflineData) {
            dispatch(
              messagingActions.setUserPresenceStatus({
                uid: userOfflineData.uid,
                status: userOfflineData.status,
                lastActiveAt: userOfflineData.lastActiveAt,
              }),
            );
          }
        },
      }),
    );

    return () => {
      CometChat.removeMessageListener(messageListenerId);
      CometChat.removeMessageListener(userStatusListenerId);
    };
  }, [user, participant]);

  useEffect(() => {
    if (!user || hasUserLoggedInOnCometChat.current) return;

    //logs in user on cometchat if user isn't already logged in
    CometChat.getLoggedinUser().then(
      loggedInUser => {
        if (!loggedInUser && user?.cometChatData?.authToken) {
          dispatch(
            messagingActions.loginUserOnCometChat({
              authToken: user.cometChatData.authToken,
              cb: () => {
                hasUserLoggedInOnCometChat.current = true;
              },
            }),
          );
        } else if (loggedInUser?.getUid() === user?.cometChatData?.uid) {
          dispatch({
            type: 'loginUserOnCometChat/fulfilled',
            payload: loggedInUser,
          });
          hasUserLoggedInOnCometChat.current = true;
        } else if (loggedInUser?.getUid() !== user?.cometChatData?.uid) {
          CometChat.logout().then(() => {
            if (user?.cometChatData?.authToken)
              dispatch(
                messagingActions.loginUserOnCometChat({
                  authToken: user.cometChatData.authToken,
                  cb: () => {
                    hasUserLoggedInOnCometChat.current = true;
                  },
                }),
              );
          });
        }
      },
      error => {
        console.error('Something went wrong', error);
        hasUserLoggedInOnCometChat.current = false;
      },
    );

    return () => {
      hasUserLoggedInOnCometChat.current = false;
    };
  }, [user]);
}
