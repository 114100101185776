import React, {FC} from 'react';
import Doctor from 'assets/images/doctor.png';
import classnames from 'classnames';
import {Button, CaretDownIcon, CaretUpIcon} from 'components';
import {LanguageSelector} from 'components/Basic/Language';
import {Calendar} from 'features/Appointment/Calendar';
import {selectUserProfile} from 'features/User';
import {useToggle} from 'hooks';
import {UserRoles} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import styled from 'styled-components';
import {isMember, isProvider, isUserFromUnitedStates} from 'utils';

import {RightLinkProps, RightNavMenuItems} from './navigationConstants';
import {NavLink} from './NavLink';
import UserInfo from './UserInfo';

const CalendarSection = styled.section`
  height: calc(100% - 6rem);
`;

export const DesktopRightNavigation: FC = () => {
  const location = useLocation();
  const {pathname} = location;
  const validPaths = [
    '/provider/digital-practice/collaboration',
    '/dashboard',
    '/provider/view-members',
  ];
  const user = useSelector(selectUserProfile);
  const isAccountPagePath = validPaths.includes(pathname) && isProvider(user);
  const {toggleOn, onToggleClick} = useToggle(isAccountPagePath);
  const {toggleOn: notifInfo, onToggleClick: onNotifInfoClick} =
    useToggle(true);
  const {t} = useTranslation();

  const handleNotifInfoClick = () => {
    if (notifInfo) {
      onNotifInfoClick();
    }
  };
  const toggleNavigationView = () => {
    onToggleClick();
    handleNotifInfoClick();
  };

  const renderCaret = () =>
    toggleOn ? (
      <CaretUpIcon
        fillColor="#6c6c6c"
        strokeColor="text-blue-600"
        strokeWidth={0.2}
      />
    ) : (
      <CaretDownIcon
        fillColor="#6c6c6c"
        strokeColor="text-blue-600"
        strokeWidth={0.2}
      />
    );

  const renderNavigation = (
    {
      pathname,
      linkName,
      first,
      last,
      current,
      classNames,
      icon: Icon,
    }: RightLinkProps,
    idx: number,
  ) => (
    <NavLink
      pathname={pathname}
      first={first}
      last={last}
      classNames={classNames}
      current={current}
      linkName={t(`rightNavigation.${linkName.toLowerCase()}`)}
      key={idx}
    >
      <Icon
        strokeColor={
          pathname === '/share'
            ? 'text-white'
            : current
            ? 'text-blue-600'
            : undefined
        }
      />
    </NavLink>
  );

  return (
    <>
      <section
        data-cy="desktop-r-nav"
        className="py-5 border-b border-gray-200 flex justify-end items-center"
      >
        <LanguageSelector classes="relative" />

        <Button
          fontSize="lg"
          textColor="gray-700"
          borderWidth="0"
          btnType="custom"
          className={classnames('relative focus:outline-none pl-4 py-3 pr-6', {
            'w-56': toggleOn,
            'w-40': !toggleOn,
          })}
          onClick={toggleNavigationView}
        >
          {toggleOn ? t('appointments') : t('account')}
          <div className="absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none">
            {renderCaret()}
          </div>
        </Button>
      </section>
      {toggleOn ? (
        <>
          <UserInfo />
          <section>
            {isMember(user) ? (
              <nav>
                {RightNavMenuItems(pathname)[UserRoles.member].map(
                  (
                    {
                      pathname,
                      linkName,
                      first,
                      last,
                      current,
                      classNames,
                      icon: Icon,
                    },
                    index,
                  ) => (
                    <NavLink
                      key={index}
                      pathname={pathname}
                      first={first}
                      last={last}
                      classNames={classNames}
                      current={current}
                      linkName={t(`rightNavigation.${linkName.toLowerCase()}`)}
                    >
                      <Icon
                        strokeColor={current ? 'text-blue-600' : undefined}
                      />
                    </NavLink>
                  ),
                )}
              </nav>
            ) : user?.role === UserRoles.therapist ||
              user?.role === UserRoles.prescriber ? (
              <nav>
                {isUserFromUnitedStates(user)
                  ? RightNavMenuItems(pathname)[user?.role].map(
                      (navItems, idx) => renderNavigation(navItems, idx),
                    )
                  : RightNavMenuItems(pathname)
                      [user?.role].filter(
                        navItems => navItems.linkName !== 'Payments',
                      )
                      .map((navItems, idx) => renderNavigation(navItems, idx))}

                <a
                  href="https://intercom.help/wellnite-health/en/"
                  className="group flex items-center py-6 text-xl pl-24 text-gray-700 leading-snug border-b border-t border-gray-200"
                  target="_blank"
                >
                  <div className="w-5">
                    <img src={Doctor} alt="Doctor_Image" className="h-5 w-5" />
                  </div>

                  <span className="pt-1 pl-5 text-lg">
                    {t('provider_resource_center')}
                  </span>
                </a>
              </nav>
            ) : null}
          </section>
        </>
      ) : (
        <CalendarSection className="px-10 py-16 overflow-y-auto">
          <Calendar />
        </CalendarSection>
      )}
    </>
  );
};
