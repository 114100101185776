import {AppActions, RootState} from 'app/rootReducer';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {providerActions} from '../providerSlice';

export const getMembersEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getMembers.match),
    mergeMap(({payload}) =>
      from(ProviderService.getMembers(payload)).pipe(
        mergeMap(({normalizedMembers, hasMore}) => [
          providerActions.getMembersSuccess({normalizedMembers, hasMore}),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getMembersFailure()),
          ),
        ),
      ),
    ),
  );

export const searchMembersEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.searchMembers.match),
    switchMap(({payload}) =>
      from(ProviderService.searchMembers(payload)).pipe(
        mergeMap(({normalizedMembers}) => [
          providerActions.searchMembersSuccess({normalizedMembers}),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.searchMembersFailure()),
          ),
        ),
      ),
    ),
  );

export const MembersEpics = [getMembersEpic, searchMembersEpic];
