import {
  GenerateWellniteContentArgs,
  GenerateWellniteContentResponse,
  RegenerateWellniteContentType,
  UnsplashSearchResults,
  WellniteContentArticle,
  WellniteContentDraftState,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const recoverWellniteContentDraft =
  createAction<WellniteContentDraftState>('recoverWellniteContentDraft');

export const getWellniteContentDraft = createAction('getWellniteContentDraft');
export const getWellniteContentDraftFailure = createAction<string>(
  'GetWellniteContentDraftFailure',
);

export const saveWellniteContentDraft = createAction<WellniteContentDraftState>(
  'saveWellniteContentDraft',
);
export const saveWellniteContentDraftSuccess =
  createAction<WellniteContentDraftState>('SaveWellniteContentDraftSuccess');
export const saveWellniteContentDraftFailure = createAction<string>(
  'SaveWellniteContentDraftFailure',
);

export const generateExpertSummary = createAction<{
  onSuccess: (result: string) => void;
}>('generateExpertSummary');
export const generateExpertSummarySuccess = createAction<string>(
  'GenerateExpertSummarySuccess',
);
export const generateExpertSummaryFailure = createAction<string>(
  'GenerateExpertSummaryFailure',
);

export const generateWellniteContent = createAction<
  GenerateWellniteContentArgs & {
    onSuccess?: () => void;
  }
>('generateWellniteContent');
export const generateWellniteContentSuccess =
  createAction<GenerateWellniteContentResponse>(
    'GenerateWellniteContentSuccess',
  );
export const generateWellniteContentFailure = createAction<string>(
  'GenerateWellniteContentFailure',
);

export const reGenerateWellniteContent = createAction<
  GenerateWellniteContentArgs & {
    element: RegenerateWellniteContentType;
    onSuccess?: (payload: Partial<GenerateWellniteContentResponse>) => void;
  }
>('reGenerateWellniteContent');
export const reGenerateWellniteContentSuccess = createAction<
  Partial<GenerateWellniteContentResponse> & {
    element: RegenerateWellniteContentType;
  }
>('ReGenerateWellniteContentSuccess');
export const reGenerateWellniteContentFailure = createAction<{
  error: string;
  element: RegenerateWellniteContentType;
}>('ReGenerateWellniteContentFailure');

export const setWellniteArticle =
  createAction<WellniteContentArticle>('SetWellniteArticle');

export const resetWellniteContent = createAction('ResetWellniteContent');

export const saveWellniteArticle = createAction<
  WellniteContentArticle & {
    onSuccess?: (payload: WellniteContentArticle) => void;
  }
>('saveWellniteArticle');
export const saveWellniteArticleSuccess = createAction<WellniteContentArticle>(
  'SaveWellniteArticleSuccess',
);
export const saveWellniteArticleFailure = createAction<string>(
  'SaveWellniteArticleFailure',
);

export const getWellniteImages = createAction<{
  count: number;
  query: string;
  page?: number;
}>('getWellniteImages');
export const getWellniteImagesSuccess = createAction<UnsplashSearchResults>(
  'GetWellniteImagesSuccess',
);
export const getWellniteImagesFailure = createAction<string>(
  'GetWellniteImagesFailure',
);

export const getWellniteArticleBySlug = createAction<{
  slug: string;
}>('getWellniteArticleBySlug');
export const getWellniteArticleBySlugSuccess =
  createAction<WellniteContentArticle>('GetWellniteArticleBySlugSuccess');
export const getWellniteArticleBySlugFailure = createAction<string>(
  'GetWellniteArticleBySlugFailure',
);

export const getWellniteArticlesByProvider = createAction<{
  providerId: string;
}>('getWellniteArticlesByProvider');
export const getWellniteArticlesByProviderSuccess = createAction<
  WellniteContentArticle[]
>('GetWellniteArticlesByProviderSuccess');
export const getWellniteArticlesByProviderFailure = createAction<string>(
  'GetWellniteArticlesByProviderFailure',
);

export const checkIfProviderReachedDailyWellniteContentLimit = createAction(
  'CheckIfProviderReachedDailyWellniteContentLimit',
);
export const checkIfProviderReachedDailyWellniteContentLimitSuccess =
  createAction<boolean>(
    'CheckIfProviderReachedDailyWellniteContentLimitSuccess',
  );
export const checkIfProviderReachedDailyWellniteContentLimitFailure =
  createAction<string>(
    'CheckIfProviderReachedDailyWellniteContentLimitFailure',
  );

export const triggerDownloadUnsplashPhotoEvent = createAction<{url: string}>(
  'TriggerDownloadUnsplashPhotoEvent',
);
export const triggerDownloadUnsplashPhotoEventSuccess = createAction(
  'TriggerDownloadUnsplashPhotoEventSuccess',
);
export const triggerDownloadUnsplashPhotoEventFailure = createAction<string>(
  'TriggerDownloadUnsplashPhotoEventFailure',
);
