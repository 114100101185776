import React, {FC, useState} from 'react';
import classNames from 'classnames';
import {
  Forward10Icon,
  FullScreenExitIcon,
  FullScreenIcon,
  PauseIcon,
  PlayIcon,
  Replay10Icon,
  VolumeHighIcon,
  VolumeLowIcon,
  VolumeMidIcon,
  VolumeMuteIcon,
} from 'components';
import Slider from 'rc-slider';
import Reactplayer from 'react-player';
import screenfull from 'screenfull';
import styled from 'styled-components';
import {formatTime} from 'utils';

import 'rc-slider/assets/index.css';
// Styles
import './video.css';

// Overriding the default color of Slider with White
const SeekBar = styled(Slider)<{isFullScreen: boolean}>`
  @media (min-width: 1281px) {
    .rc-slider-handle {
      width: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      height: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      margin-top: ${props => (props.isFullScreen ? '-5' : '-3')}px !important;
      border: none !important;
      opacity: 1;
    }
  }

  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1025px) and (max-width: 1280px) {
    .rc-slider-handle {
      width: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      height: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      margin-top: ${props => (props.isFullScreen ? '-5' : '-3')}px !important;
      border: none !important;
      opacity: 1;
    }
  }

  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) {
    /* CSS */
    .rc-slider-handle {
      width: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      height: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      margin-top: ${props => (props.isFullScreen ? '-5' : '-3')}px !important;
      border: none !important;
      opacity: 1;
    }
  }

  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* CSS */
    .rc-slider-handle {
      width: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      height: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      margin-top: ${props => (props.isFullScreen ? '-5' : '-3')}px !important;
      border: none !important;
      opacity: 1;
    }
  }

  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

  @media (min-width: 481px) and (max-width: 767px) {
    /* CSS */
  }

  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 480px) {
    .rc-slider-handle {
      width: 7px !important;
      height: 7px !important;
      margin-top: -3.5px !important;
      border: none !important;
      opacity: 1;
    }
  }
`;

const VolumeBar = styled(Slider)<{isFullScreen: boolean}>`
  @media (min-width: 1281px) {
    .rc-slider-handle {
      width: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      height: ${props => (props.isFullScreen ? '15' : '7')}px !important;
      margin-top: ${props => (props.isFullScreen ? '-5' : '-40')}px !important;
      border: none !important;
      opacity: 1;
    }
  }

  /* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1025px) and (max-width: 1280px) {
    .rc-slider-handle {
      width: 12px !important;
      height: 12px !important;
      margin-top: -8.5px !important;
      border: none !important;
      opacity: 1;
    }
  }

  /* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) {
    /* CSS */
  }

  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* CSS */
  }

  /* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

  @media (min-width: 481px) and (max-width: 767px) {
    /* CSS */
  }

  /* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 480px) {
    .rc-slider-handle {
      width: 7px !important;
      height: 7px !important;
      margin-top: -8px !important;
      border: none !important;
      opacity: 1;
    }
  }
`;

type ControlsProps = {
  isFullScreen: boolean;
  muted: boolean;
  played: number;
  playing: boolean;
  seeking: boolean;
  speed: number;
  volume: number;
};

interface VideoControlsProps {
  controls: ControlsProps;
  title?: string;
  setControls: React.Dispatch<React.SetStateAction<ControlsProps>>;
  videoRef: React.RefObject<Reactplayer>;
  videoWrapperRef: React.RefObject<HTMLElement>;
}

export const VideoControls: FC<VideoControlsProps> = ({
  controls,
  title,
  setControls,
  videoRef,
  videoWrapperRef,
}) => {
  // Volume Slider Visible on Hover
  const [volumeSlider, setVolumeSlider] = useState(false);

  // Number of seconds video already played
  const currentSeconds = videoRef.current
    ? videoRef.current.getCurrentTime()
    : 0;
  // Total number of seconds in video
  const totalSeconds = (
    videoRef.current ? videoRef.current.getDuration() : 0
  ) as number;

  // In hh:mm:ss format
  const currentTime = formatTime(currentSeconds);
  const totalTime = formatTime(totalSeconds);

  // Funtion to Handle Play/Pause Button
  const handlePlayPause = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();

    setControls({
      ...controls,
      playing: !controls.playing,
    });
  };

  // Funtion to Handle Forward10 Button
  const handleForward10 = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();

    if (videoRef.current) {
      videoRef.current.seekTo(videoRef.current.getCurrentTime() + 10);
    }
  };

  // Funtion to Handle Replay10 Button
  const handleReplay10 = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();

    if (videoRef.current) {
      videoRef.current.seekTo(videoRef.current.getCurrentTime() - 10);
    }
  };

  // Funtion to Mute the Volume
  const handleMuted = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();

    setControls({
      ...controls,
      muted: !controls.muted,
    });
  };

  // Function to Handle Change in Volume
  const handleVolumeChange = (newVolume: any) => {
    setControls({
      ...controls,
      volume: newVolume / 100,
      muted: newVolume == 0,
    });
  };

  // Function to Handle Commit in Volume
  const handleVolumeCommited = (newVolume: any) => {
    setControls({
      ...controls,
      volume: newVolume / 100,
      muted: newVolume == 0,
    });
  };

  // Function to Handle Speed of Video
  const handleSpeed = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();

    const newSpeed = controls.speed == 2 ? 0 : controls.speed;
    setControls({
      ...controls,
      speed: newSpeed + 0.25,
    });
  };

  // Function to Handle FullScreen Button
  const handleFullScreen = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();

    screenfull.toggle(videoWrapperRef.current!);
    setControls({
      ...controls,
      isFullScreen: !controls.isFullScreen,
    });
  };

  // Function to Handle Video Seekbar Slider Change
  const handleSeekbarChange = (newPlayed: number | number[]) => {
    if (typeof newPlayed === 'number' && videoRef.current) {
      setControls({
        ...controls,
        played: newPlayed / 100,
      });
      videoRef.current.seekTo(newPlayed / 100, 'fraction');
    }
  };

  // Function to Handle Mouse Down on Video Seekbar Slider
  const handleSeekbarMouseDown = () => {
    setControls({
      ...controls,
      seeking: true,
    });
  };

  // Function to Handle Mouse Up on Video Seekbar Slider
  const handleSeekbarMouseUp = (newPlayed: any) => {
    if (videoRef.current) {
      setControls({
        ...controls,
        seeking: false,
      });
      videoRef.current.seekTo(newPlayed / 100, 'fraction');
    }
  };

  // Function to Handle onClick on controls-overlay
  const handleOverlayOnClick = (e: any) => {
    e.stopPropagation();
    // setControls((current: any) => ({...current, playing: !current.playing}))
  };

  const railHeight = controls.isFullScreen ? 4 : 2;
  const svgClasses = classNames(
    'transition-all cursor-pointer fill-current text-gray-400 hover:text-white',
    {
      'scale-75 hover:scale-100': !controls.isFullScreen,
      'scale-[1.7] hover:scale-[1.9]': controls.isFullScreen,
    },
  );

  const iconClasses = controls.isFullScreen ? 'w-12' : 'w-6';

  return (
    <section
      className="w-full h-full flex flex-col justify-between items-center p-0 absolute top-0"
      onClick={handleOverlayOnClick}
      onDoubleClick={handleFullScreen}
    >
      {/* TOP: Title */}
      <article className="w-full title">
        {!controls.playing && title ? (
          <div
            className={classNames('tracking-wider', {
              'text-sm': !controls.isFullScreen,
              'text-xl': controls.isFullScreen,
            })}
          >
            {title}
          </div>
        ) : null}
      </article>

      {/* BOTTOM: Controls */}
      <article
        className={classNames('w-full controls', {
          'px-3': !controls.isFullScreen,
          'px-8': controls.isFullScreen,
        })}
      >
        {volumeSlider ? null : (
          <SeekBar
            min={1}
            max={100}
            value={controls.played * 100}
            onChange={handleSeekbarChange}
            onFocus={handleSeekbarMouseDown}
            onBlur={handleSeekbarMouseUp}
            trackStyle={{
              background: 'red',
              height: railHeight,
              borderRadius: 0,
            }}
            railStyle={{
              height: railHeight,
              borderRadius: 0,
              background: '#e9e9e985',
            }}
            handleStyle={{background: 'red', width: 15, height: 15}}
            draggableTrack
            isFullScreen={controls.isFullScreen}
            step={0.01}
          />
        )}

        <div
          className={classNames('w-full flex justify-between', {
            'h-6 pb-2': !controls.isFullScreen,
            'h-24': controls.isFullScreen,
          })}
        >
          <div
            className={classNames('flex items-center', {
              'space-x-1 -ml-1.5': !controls.isFullScreen,
              'space-x-7': controls.isFullScreen,
            })}
          >
            {/* CONTROL: Play/Pause */}
            <span className={iconClasses} onClick={handlePlayPause}>
              {controls.playing ? (
                <PauseIcon classes={svgClasses} />
              ) : (
                <PlayIcon classes={svgClasses} />
              )}
            </span>
            {/* CONTROL: Replay10 */}
            <button className={iconClasses} onClick={handleReplay10}>
              <Replay10Icon classes={svgClasses} />
            </button>
            {/* CONTROL: Forward10 */}
            <button className={iconClasses} onClick={handleForward10}>
              <Forward10Icon classes={svgClasses} />
            </button>
            {/* CONTROL: Volume */}
            <span
              className="flex items-center relative"
              onMouseEnter={() => setVolumeSlider(true)}
              onMouseLeave={() => setVolumeSlider(false)}
            >
              <button className={iconClasses} onClick={handleMuted}>
                {controls.muted ? (
                  <VolumeMuteIcon classes={svgClasses} />
                ) : controls.volume < 0.33 ? (
                  <VolumeLowIcon classes={svgClasses} />
                ) : controls.volume < 0.66 ? (
                  <VolumeMidIcon classes={svgClasses} />
                ) : (
                  <VolumeHighIcon classes={svgClasses} />
                )}
              </button>
              {volumeSlider && controls.isFullScreen ? (
                <div className="bg-gray-600 p-2 h-24 absolute bottom-7 -ml-1.5 z-999 transform">
                  <VolumeBar
                    min={0}
                    max={100}
                    value={controls.muted ? 0 : controls.volume * 100}
                    onChange={handleVolumeChange}
                    onAfterChange={handleVolumeCommited}
                    trackStyle={{
                      background: 'red',
                      borderRadius: 0,
                    }}
                    railStyle={{
                      borderRadius: 0,
                      background: '#e9e9e985',
                    }}
                    handleStyle={{background: 'red', width: 15, height: 15}}
                    vertical={true}
                    isFullScreen={controls.isFullScreen}
                  />
                </div>
              ) : null}
            </span>
            <span
              className={classNames('text-white text-2xl', {
                hidden: !controls.isFullScreen,
                block: controls.isFullScreen,
              })}
            >{`${currentTime} / ${totalTime}`}</span>
          </div>

          <div
            className={classNames('flex justify-end items-center', {
              'space-x-1 -mr-2': !controls.isFullScreen,
              'space-x-7 -mr-6': controls.isFullScreen,
            })}
          >
            {/* CONTROL: Speed */}
            <div
              onClick={handleSpeed}
              className={classNames(
                'speed-control cursor-pointer text-gray-300 hover:text-white text-2xl',
                {
                  hidden: !controls.isFullScreen,
                  block: controls.isFullScreen,
                },
              )}
            >
              {controls.speed}x
            </div>
            {/* CONTROL: FullScreen */}
            <button className={iconClasses} onClick={handleFullScreen}>
              {controls.isFullScreen ? (
                <FullScreenExitIcon classes={svgClasses} />
              ) : (
                <FullScreenIcon classes={svgClasses} />
              )}
            </button>
          </div>
        </div>
      </article>
    </section>
  );
};
