import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {ProviderState} from '../providerSlice';

function getProvider(state: RootState): ProviderState {
  return state.provider;
}

export const selectMedicationsStatus = createSelector(
  getProvider,
  provider => provider.medicationStatus,
);
export const selectNotesStatus = createSelector(
  getProvider,
  provider => provider.notes.isNotesLoading,
);
export const getNotesList = createSelector(getProvider, provider => {
  return Object.values(provider.notes.data);
});

export const selectNoteById = createSelector(
  getProvider,
  (_: RootState, noteId: string) => noteId,
  (provider, noteId) => {
    return provider.notes.data[noteId];
  },
);

export const selectCMS1500 = createSelector(getProvider, provider => {
  return provider.cms1500;
});
