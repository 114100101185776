import {MemberProfile, UserRoles} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

import {OrderMembersBy} from '../../../services/api/Provider';

export type GetMembersPayload = {
  limit: number;
  orderBy: OrderMembersBy;
  role: UserRoles;
};

export const getMembers = createAction<GetMembersPayload>('getMembers');

export const resetMembers = createAction('resetMembers');

export const getMembersSuccess = createAction<{
  normalizedMembers: {[key: string]: MemberProfile};
  hasMore: boolean;
}>('getMembersSuccess');
export const getMembersFailure = createAction('getMembersFailure');
