export enum CreditCardTabs {
  creditCard = 'Credit Card',
  addCreditCard = 'Add Credit Card',
}

export enum StripeCreditCardBrandTypes {
  'Amex' = 'American Express',
  'DinersClub' = 'Diners Club',
  'Discover' = 'Discover',
  'JCB' = 'JCB',
  'MasterCard' = 'MasterCard',
  'UnionPay' = 'UnionPay',
  'Visa' = 'Visa',
  'Unknown' = 'Unknown',
}

export type CreditCardTypes =
  | 'visa'
  | 'mastercard'
  | 'amex'
  | 'discover'
  | 'diners'
  | 'jcb'
  | 'unionpay'
  | 'unknown';

export enum PaypalCreditCardBrandTypes {
  AmericanExpress = 'AMEX',
  Discover = 'DISCOVER',
  JCB = 'JCB',
  MasterCard = 'MASTERCARD',
  ChinaUnionPay = 'CHINA_UNION_PAY',
  Visa = 'VISA',
  Solo = 'SOLO',
  Star = 'STAR',
  Delta = 'DELTA',
  Switch = 'SWITCH',
  Maestro = 'MAESTRO',
  CB_Nationale = 'CB_NATIONALE',
  Configoga = 'CONFIGOGA',
  Confidis = 'CONFIDIS',
  Electron = 'ELECTRON',
  Cetelem = 'CETELEM',
}
