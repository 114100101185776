import * as Yup from 'yup';

export const SetAppointmentPriceSchema = Yup.object()
  .shape({
    charge: Yup.number()
      .typeError('Amount must be a number')
      .min(1, 'Amount must be greater than 0')
      .required('Add an Amount'),
    chargeCurrency: Yup.string().required('Required!'),
  })
  .required();

export const SendPaymentRequestSchema = Yup.object()
  .shape({
    price: Yup.number()
      .typeError('Amount must be a number')
      .min(1, 'Amount must be greater than 0')
      .required('Add an Amount'),
  })
  .required();
