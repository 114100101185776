import {selectStyle as formSelectStyle} from 'components/Basic/Form/V2';
import styled from 'styled-components';

export const ActionContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  background-color: white;
`;
export const NotesContainer = styled.div<{isInVideoCall?: boolean}>`
  width: 100%;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-touch-callout: default;
  height: ${props =>
    props.isInVideoCall ? 'calc(100vh - 8rem)' : 'calc(100vh - 4rem)'};
  overflow-y: auto;
`;

export const selectStyle = {
  control: (base: any) => ({
    ...base,
    border: '0 !important',
    boxShadow: '0 !important',
    background: 'transparent !important',
    '&:hover': {},
  }),
};

export const selectStyleV2 = {
  ...formSelectStyle,
  control: (base: any) => ({
    ...base,
    border: 0,
    borderRadius: '0.35rem',
    boxShadow: '0px 0px 1px 0px #ccc',
    background: '#ffffff',
    textTransform: 'capitalize',
    paddingTop: '0.2rem',
    marginTop: '0.6rem',
    paddingBottom: '0.2rem',
  }),
};
