/* eslint-disable import/no-duplicates */
import {format, parse} from 'date-fns';
import {enUS} from 'date-fns/locale';
import {
  MoodTrackerStatsResponse,
  ProgressAssessmentStatsResponse,
  StatsTimePeriod,
} from 'interfaces';

import {SeverityRange} from '../constants';

export function getSeverityRangeFromValue(
  value: number,
  severityRanges: SeverityRange[],
): SeverityRange | undefined {
  return severityRanges.find(item => item.values.includes(Math.floor(value)));
}

export type SelectProgressAssessmentType = {
  email: string;
  type?: string;
  period: string;
};
export function getProgressStateKey(args: SelectProgressAssessmentType) {
  return `${args.email}${args.type}${args.period}`;
}

export function getMoodTrackerStateKey(args: SelectProgressAssessmentType) {
  return `${args.email}${args.period}`;
}

export function calculateProgressAssessmentAverageScore(
  stats: ProgressAssessmentStatsResponse,
  period?: StatsTimePeriod,
): number {
  if (!stats?.length) return 0;

  if (period && !['day', 'week'].includes(period)) {
    return stats[stats.length - 1].totalScore;
  }

  const filteredStats = stats.filter(stat => !stat.isNullValue);

  const totalSum = filteredStats.reduce(
    (sum, currentStat) => sum + currentStat.totalScore,
    0,
  );
  const averageScore = totalSum / filteredStats.length;
  return isNaN(averageScore) ? 0 : Math.floor(averageScore);
}

export function calculateMoodTrackerAverageScore(
  stats: MoodTrackerStatsResponse,
  period?: StatsTimePeriod,
): number {
  if (!stats?.length) return 0;

  if (period && !['day', 'week'].includes(period)) {
    return stats[stats.length - 1].averageScore;
  }

  const filteredStats = stats.filter(stat => !stat.isNullValue);
  const totalSum = filteredStats.reduce(
    (sum, currentStat) => sum + currentStat.averageScore,
    0,
  );
  const averageScore = totalSum / filteredStats.length;
  return isNaN(averageScore) ? 0 : Math.floor(averageScore);
}

export function translateProgressChartLabels(
  period: string,
  item: {
    year: number;
    month: string;
  },
) {
  const dateString = `${item.year}-${item.month || 'January'}-01`;

  try {
    const date = parse(dateString, 'yyyy-MMMM-dd', new Date(), {
      locale: enUS,
    });

    return period === 'all' ? format(date, 'yyyy') : format(date, 'LLL');
  } catch (error) {
    return '';
  }
}
