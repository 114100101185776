import * as Yup from 'yup';

export const InsuranceImageSchema = Yup.object()
  .shape({
    insuranceImageFront: Yup.array<File>()
      .of<File>(Yup.mixed<File>())
      .required('Front image is required')
      .nullable(),
    insuranceImageBack: Yup.array<File>()
      .of<File>(Yup.mixed<File>())
      .required('Back image is required')
      .nullable(),
  })
  .required();
