import React, {FC, useCallback} from 'react';
import LogoWhite from 'assets/images/wellbits-icon.png';
import {UserWellbitWallet} from 'interfaces';
import {Link, useLocation} from 'react-router-dom';

import {ChevronRightIcon} from '../Basic';

interface Props {
  data?: UserWellbitWallet;
  closeActionForMobile?: () => void;
}

const WellbitsBadge: FC<Props> = ({data, closeActionForMobile = () => {}}) => {
  const to = {
    pathname: '/provider/digital-practice/collaboration',
    state: {fromAccount: true},
  };
  const {pathname} = useLocation();

  const handleOnClick = useCallback(() => {
    if (pathname === to.pathname) closeActionForMobile();
  }, [pathname]);

  return (
    <>
      {data ? (
        <Link to={to} onClick={handleOnClick}>
          <div className="inline-block border border-gray-300 hover:bg-blue-50 rounded-full p-1">
            <div className="flex items-center justify-center">
              <img className="h-9 w-9" src={LogoWhite} alt="Wellnite logo" />
              <p className="text-lg text-gray-900 font-bold pl-4 pr-2">
                {`${data?.totalWellbits}`}
              </p>
              <ChevronRightIcon
                strokeColor="text-gray-700"
                aria-hidden="true"
                height={11}
              />
            </div>
          </div>
        </Link>
      ) : null}
    </>
  );
};

export default WellbitsBadge;
