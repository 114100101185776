import {
  EndPoints,
  HttpMethods,
  UserRoles,
  WherebyRoomModeTypes,
} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';

const getVideoCallCredentials = async (
  url: string,
): Promise<Response<{message: {roomName: string; token: string}}>> => {
  try {
    const res = await requestHandler<{
      message: {roomName: string; token: string};
    }>({
      method: HttpMethods.GET,
      url: url as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWherebyRoomUrl = async (data: {
  role: UserRoles;
  id: string;
  userRole: UserRoles;
  appointmentTime: string;
  appointmentID: string;
  roomMode: WherebyRoomModeTypes;
}): Promise<Response<{message: {roomUrl: string}}>> => {
  try {
    const res = await requestHandler<
      {
        message: {roomUrl: string};
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `/api/${data.userRole}/${EndPoints.GetWherebyRoomUrl}` as unknown as EndPoints,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const VideoService = {
  getVideoCallCredentials,
  getWherebyRoomUrl,
};
