import React, {FC, useEffect, useRef, useState} from 'react';
import {Instance, Props} from 'tippy.js';
import {copyToClipboard} from 'utils';

import {Tooltip} from '../Tooltip';

interface IProps {
  children: React.ReactNode;
  classes?: string;
  hoverText: string;
  successText: string;
  content: string;
}

export const CopyText: FC<IProps> = ({
  children,
  hoverText,
  successText,
  content,
  classes,
}) => {
  const tooltipRef = useRef<Element>(null);
  const tippyInstance = useRef<Instance<Props>>();
  const [success, setSuccess] = useState(false);

  const copyText = () => {
    if (!content?.length) return;

    const strippedHtml = content.replace(/<[^>]+>/g, '');
    copyToClipboard(strippedHtml);
    setSuccess(true);
  };

  useEffect(() => {
    if (!tooltipRef.current) return;
    tooltipRef.current.addEventListener('mouseleave', () => {
      if (tippyInstance.current) {
        tippyInstance.current.hide();
        setTimeout(() => {
          setSuccess(false);
        }, 500);
      }
    });
  });
  return (
    <Tooltip
      ref={tooltipRef}
      trigger="mouseenter"
      hideOnClick={false}
      content={success ? successText : hoverText}
      onShow={instance => {
        tippyInstance.current = instance;
        instance.setProps({trigger: 'click'});
      }}
      onHide={instance => {
        instance.setProps({trigger: 'mouseenter'});
      }}
    >
      <button className={classes} onClick={copyText}>
        {children}
      </button>
    </Tooltip>
  );
};
