import * as Yup from 'yup';

export const SocialOnboardingDOBAndInsuranceSchema = Yup.object()
  .shape({
    dateOfBirth: Yup.object()
      .shape({
        day: Yup.string().required('Select a day'),
        month: Yup.string().required('Select a month'),
        year: Yup.string().required('Select a year'),
      })
      .required('Please enter date'),
    companyType: Yup.string().required('Please select an insurance type'),
    gender: Yup.string().required('Select a gender'),
    otherGender: Yup.string().when(['gender'], {
      is: (gender: string) => gender === 'other',
      then: Yup.string().required('Required, Type your gender'),
    }),
  })
  .required();

export const DigitalPracticeOnboardingDOBAndInsuranceSchema = Yup.object()
  .shape({
    dateOfBirth: Yup.object()
      .shape({
        day: Yup.string().required('Select a day'),
        month: Yup.string().required('Select a month'),
        year: Yup.string().required('Select a year'),
      })
      .required('Please enter date'),
    companyName: Yup.string().required('You must provide a value'),
    companyId: Yup.string(),
    gender: Yup.string().required('Select a gender'),
    otherGender: Yup.string().when(['gender'], {
      is: (gender: string) => gender === 'other',
      then: Yup.string().required('Required, Type your gender'),
    }),
  })
  .required();

export const SocialOnboardingInsuranceDetailsSchema = Yup.object()
  .shape({
    companyId: Yup.string(),
    companyName: Yup.string().required('You must provide a value'),
    memberId: Yup.string().required('You must provide a value'),
    dependencyStatus: Yup.string().required('You must provide a value'),
  })
  .required();

export const SocialOnboardingInsurancePersonalDetailsSchema = Yup.object()
  .shape({
    primaryInsuredZipcode: Yup.string().required('You must provide a zip code'),
    primaryInsuredState: Yup.string().required('You must provide a state'),
    primaryInsuredCity: Yup.string().required('You must provide a city'),
    primaryInsuredStreetAddress: Yup.string().required(
      'You must provide your address',
    ),
  })
  .required();

export const SocialOnboardingInsurancePrimaryPersonalDetailsSchema =
  Yup.object()
    .shape({
      primaryInsuredZipcode: Yup.string().required(
        'You must provide a zip code',
      ),
      primaryInsuredState: Yup.string().required('You must provide a state'),
      primaryInsuredCity: Yup.string().required('You must provide a city'),
      primaryInsuredStreetAddress: Yup.string().required(
        'You must provide your address',
      ),
      primaryInsuredDateOfBirth: Yup.object()
        .shape({
          day: Yup.string().required('Select a day'),
          month: Yup.string().required('Select a month'),
          year: Yup.string().required('Select a year'),
        })
        .required('You must provide a primary insured date of birth'),
      primaryInsuredFirstName: Yup.string().required(
        'You must provide a primary insured first name',
      ),
      primaryInsuredLastName: Yup.string().required(
        'You must provide a primary insured last name',
      ),
      primaryInsuredMiddleName: Yup.string(),
      primaryInsuredGender: Yup.string().required(
        'You must provide a primary insured gender',
      ),
    })
    .required();

export const DigitalPracticeOnboardingInsuranceDetailsSchema = Yup.object()
  .shape({
    memberId: Yup.string().required('You must provide a value'),
    eapCode: Yup.string().notRequired(),
    dependencyStatus: Yup.string().required('You must provide a value'),
  })
  .required();

export const OnboardingDOBAndInsuranceSchema = {
  social: SocialOnboardingDOBAndInsuranceSchema,
  digitalPractice: DigitalPracticeOnboardingDOBAndInsuranceSchema,
};

export const OnboardingInsuranceDetailsSchema = {
  social: SocialOnboardingInsuranceDetailsSchema,
  digitalPractice: DigitalPracticeOnboardingInsuranceDetailsSchema,
};
