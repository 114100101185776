import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {CollaborationService} from 'services/api';
import {ProviderService} from 'services/api/Provider';

import {digitalPracticeActions} from '../../digitalPracticeSlice';

const getWellbitsDataEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getWellbitsData.match),
    mergeMap(({payload: {role}}) =>
      from(ProviderService.getWellbitsData(role)).pipe(
        mergeMap(wellbitData => [
          digitalPracticeActions.getWellbitsDataSuccess(wellbitData),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(digitalPracticeActions.getWellbitsDataFailure()),
          ),
        ),
      ),
    ),
  );

const getWellbitsDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getWellbitsDataFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const getProviderColleagueWellbitFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getProviderColleagueWellbitFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    map(() => push({pathname: '/provider/digital-practice/collaboration'})),
    ignoreElements(),
  );

export const getProviderColleagueWellbitEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getProviderColleagueWellbit.match),
    mergeMap(({payload}) =>
      from(CollaborationService.getProviderColleagueWellbit(payload)).pipe(
        mergeMap(({message: {hasMore, result, nextPage, total, prevPage}}) => [
          digitalPracticeActions.getProviderColleagueWellbitSuccess({
            result,
            hasMore,
            nextPage,
            total,
            prevPage,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(digitalPracticeActions.getProviderColleagueWellbitFailure()),
          ),
        ),
      ),
    ),
  );

export const progressEpics = [
  getWellbitsDataEpic,
  getWellbitsDataFailureEpic,
  getProviderColleagueWellbitEpic,
  getProviderColleagueWellbitFailureEpic,
];
