import {AppActions, RootState} from 'app/rootReducer';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {CollaborationService} from 'services/api';

import {digitalPracticeActions} from '../../digitalPracticeSlice';

export const getColleaguesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.getColleagues.match),
    switchMap(({payload}) => {
      return from(CollaborationService.getColleagues(payload)).pipe(
        mergeMap(({data: {message}}) => {
          return [
            digitalPracticeActions.getColleaguesSuccess({
              target: payload.target,
              data: message,
            }),
          ];
        }),
        catchError((message: string) => {
          return concat(
            of(
              digitalPracticeActions.getColleaguesFailure({
                target: payload.target,
                error: message,
              }),
            ),
          );
        }),
      );
    }),
  );

export const searchColleaguesEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.searchColleagues.match),
    switchMap(({payload}) => {
      return from(CollaborationService.searchColleagues(payload)).pipe(
        mergeMap(({data: {message}}) => {
          return [
            digitalPracticeActions.searchColleaguesSuccess({
              target: payload.target,
              data: message,
            }),
          ];
        }),
        catchError((message: string) => {
          return concat(
            of(
              digitalPracticeActions.searchColleaguesFailure({
                target: payload.target,
                error: message,
              }),
            ),
          );
        }),
      );
    }),
  );

export const colleaguesEpic = [getColleaguesEpic, searchColleaguesEpic];
