import {useEffect, useState} from 'react';

export default function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches,
  );

  useEffect(() => {
    const queryList = window.matchMedia(query);
    const updateMatch = () => setMatches(queryList.matches);
    queryList.addListener(updateMatch);
    return () => queryList.removeListener(updateMatch);
  }, [query]);

  return matches;
}
