import React from 'react';

export const Star = ({
  filled,
  className = '',
}: {
  filled: boolean;
  className?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill={filled ? '#FFD700' : 'none'}
    stroke="#FFD700"
    strokeWidth="2"
    className={className}
  >
    <path d="M12 .587l3.668 7.425L23.536 9.36l-5.5 5.366L19.8 22.914 12 18.896l-7.8 4.018L6.964 14.726l-5.5-5.366 7.868-1.348L12 .587z" />
  </svg>
);

export const HalfStar = ({className = ''}: {className?: string}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    className={className}
  >
    <defs>
      <linearGradient id="halfGradient">
        <stop offset="50%" stopColor="#FFD700" />
        <stop offset="50%" stopColor="white" stopOpacity="1" />
      </linearGradient>
      <clipPath id="halfClip">
        <rect x="0" y="0" width="12" height="24" />
      </clipPath>
    </defs>
    <path
      fill="url(#halfGradient)"
      stroke="#FFD700"
      strokeWidth="2"
      d="M12 .587l3.668 7.425L23.536 9.36l-5.5 5.366L19.8 22.914 12 18.896l-7.8 4.018L6.964 14.726l-5.5-5.366 7.868-1.348L12 .587z"
    />
    <path
      fill="none"
      stroke="#FFD700"
      strokeWidth="2"
      d="M12 .587l3.668 7.425L23.536 9.36l-5.5 5.366L19.8 22.914 12 18.896l-7.8 4.018L6.964 14.726l-5.5-5.366 7.868-1.348L12 .587z"
      clipPath="url(#halfClip)"
    />
  </svg>
);
