import {useCallback, useState} from 'react';
import {selectManageSubStatus} from 'features/Payment/paymentSelectors';
import {paymentActions} from 'features/Payment/paymentSlice';
import {selectUserProfile} from 'features/User/userSelectors';
import {userActions} from 'features/User/userSlice';
import {CancellationValues, SliceStatus, StripePlanTypes} from 'interfaces';
import {useDispatch, useSelector} from 'react-redux';

import {useBreakpoint} from './useBreakpoint';
import {useError} from './useError';
import {useRequesting} from './useRequesting';
import {useRouterPush} from './useRouterPush';

export const useManageSubscription = (): {
  startManageSubFlow: () => void;
  closeManageSubFlow: () => void;
  startPauseSubFlow: () => void;
  navigateToCancelSubSurvey: () => void;
  startCancelSubFlow: () => void;
  startConfirmCancelSubFlow: () => void;
  handleAnnualPlanInterest: () => void;
  handlePauseSubscription: () => void;
  closeCancelSubSurvey: () => void;
  setCancelSurveyValues: (cancelValues: CancellationValues) => void;
  handleCancelSubscription: (cancelValues: CancellationValues) => void;
  subscriptionState: 'none' | 'upgrade' | 'pause' | 'confirmCancel' | 'cancel';
  subscriptionStateData?: string | number | CancellationValues;
  requesting: boolean;
  apiError: string;
  openCancelSubscriptionModal: boolean;
  closeCancelSubscriptionModal: (val: boolean) => void;
} => {
  const dispatch = useDispatch();
  const {subscriptionState, data: subscriptionStateData} = useSelector(
    selectManageSubStatus,
  );
  const {onRoutePush} = useRouterPush();
  const {isBelowLg} = useBreakpoint('lg');

  const user = useSelector(selectUserProfile);
  const userCurrentPlan =
    user?.__subPaymentPlan || user?.paymentPlan || StripePlanTypes.uninsured;
  const sliceStatus = useRequesting('payment');
  const isPending = sliceStatus === SliceStatus.pending;
  const {apiError} = useError('payment');
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] =
    useState<boolean>(false);

  const startManageSubFlow = useCallback(() => {
    dispatch(
      paymentActions.setManageSubscriptionState({
        subscriptionState: 'upgrade',
      }),
    );
  }, [dispatch]);

  const closeManageSubFlow = useCallback(() => {
    dispatch(userActions.resetAsyncError('payment'));
    dispatch(
      paymentActions.resetManageSubscriptionState({
        subscriptionState: 'none',
      }),
    );
  }, [dispatch]);

  const handleAnnualPlanInterest = useCallback(() => {
    dispatch(
      paymentActions.annualPlanInterest({
        paymentPlan: userCurrentPlan,
      }),
    );
  }, [dispatch, userCurrentPlan]);

  const startPauseSubFlow = useCallback(() => {
    dispatch(userActions.resetAsyncError('payment'));
    dispatch(
      paymentActions.setManageSubscriptionState({
        subscriptionState: 'pause',
      }),
    );
  }, [dispatch]);

  const handlePauseSubscription = useCallback(() => {
    dispatch(paymentActions.pauseSubscription());
  }, [dispatch]);

  const navigateToCancelSubSurvey = () => {
    dispatch(
      paymentActions.resetManageSubscriptionState({
        subscriptionState: 'none',
      }),
    );

    if (isBelowLg) {
      onRoutePush('/cancel-subscription');
    } else {
      setOpenCancelSubscriptionModal(true);
    }
  };

  const closeCancelSubscriptionModal = useCallback((val: boolean) => {
    setOpenCancelSubscriptionModal(val);
  }, []);

  const startCancelSubFlow = useCallback(() => {
    dispatch(
      paymentActions.setManageSubscriptionState({
        subscriptionState: 'cancel',
      }),
    );
  }, [dispatch]);

  const startConfirmCancelSubFlow = useCallback(() => {
    dispatch(
      paymentActions.setManageSubscriptionState({
        subscriptionState: 'confirmCancel',
      }),
    );
  }, [dispatch]);

  const setCancelSurveyValues = useCallback(
    (cancelSurveyData: CancellationValues) => {
      dispatch(
        paymentActions.setManageSubscriptionState({
          subscriptionState: 'confirmCancel',
          data: cancelSurveyData,
        }),
      );
    },
    [dispatch],
  );

  const handleCancelSubscription = useCallback(
    (cancelSurveyData: CancellationValues) => {
      dispatch(paymentActions.cancelSubscription(cancelSurveyData));
      if (!isBelowLg) {
        setOpenCancelSubscriptionModal(false);
      }
    },
    [dispatch, isBelowLg],
  );

  const closeCancelSubSurvey = () => {
    dispatch(userActions.resetAsyncError('payment'));
    dispatch(
      paymentActions.resetManageSubscriptionState({
        subscriptionState: 'none',
      }),
    );
    onRoutePush('/dashboard');
  };

  return {
    startManageSubFlow,
    closeManageSubFlow,
    startPauseSubFlow,
    navigateToCancelSubSurvey,
    subscriptionState,
    subscriptionStateData,
    requesting: isPending,
    apiError,
    handleAnnualPlanInterest,
    handlePauseSubscription,
    startCancelSubFlow,
    startConfirmCancelSubFlow,
    setCancelSurveyValues,
    handleCancelSubscription,
    closeCancelSubSurvey,
    openCancelSubscriptionModal,
    closeCancelSubscriptionModal,
  };
};
