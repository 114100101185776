import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {UserRoles} from 'interfaces';
import {Epic, ofType} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {IntakeService, UserService} from 'services/api';

import {intakeActions} from './intakeSlice';

export const checkUserExistsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(intakeActions.checkUserExists.match),
    switchMap(({payload: {intakeType, ...rest}}) =>
      from(UserService.checkUserExists(rest)).pipe(
        mergeMap(({data}) => {
          const intakeLink = `/onboarding-${intakeType}-intake`;
          if (data.message.exists) {
            return [
              intakeActions.checkUserExistsSuccess({exists: true}),
              push('/login', {from: intakeLink}),
            ];
          } else {
            localStorage.setItem('from', intakeLink);
            return [
              intakeActions.checkUserExistsSuccess({exists: false}),
              push({
                pathname: '/get-started',
              }),
            ];
          }
        }),
        catchError(message =>
          concat(of(intakeActions.checkUserExistsFailure(message))),
        ),
      ),
    ),
  );

export const checkUserExistsSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(intakeActions.checkUserExistsSuccess.match),
    tap(({payload}) => {
      const message = payload.exists
        ? 'Please log in to proceed'
        : 'Please register to proceed';
      Toast({type: 'success', message});
    }),
    ignoreElements(),
  );

export const checkUserExistsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(intakeActions.checkUserExistsFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const saveIntakeDataEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(intakeActions.saveIntakeData.match),
    withLatestFrom(state$),
    switchMap(([{payload}, currentState]) =>
      from(IntakeService.saveIntakeData(payload)).pipe(
        mergeMap(({data: {message}}) => {
          if (
            message?.hasCompletedIntake === true &&
            message.intakeStatus === 'completed'
          ) {
            return concat(
              of(userActions.checkSession(UserRoles.member)),
              of(
                intakeActions.saveIntakeDataSuccess({
                  intakeResponse: message,
                  user: currentState.user.current,
                }),
              ).pipe(
                delayWhen(() =>
                  action$.pipe(
                    ofType(userActions.checkSessionSuccess.type),
                    take(1),
                  ),
                ),
              ),
            );
          } else {
            return of(
              intakeActions.saveIntakeDataSuccess({
                intakeResponse: message,
                user: currentState.user.current,
              }),
            );
          }
        }),
        catchError(error =>
          of(intakeActions.saveIntakeDataFailure(error.message)),
        ),
      ),
    ),
  );

export const getMemberIntakeDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(intakeActions.getMemberIntakeData.match),
    withLatestFrom(state$),
    switchMap(([{payload}, currentState]) =>
      from(IntakeService.getMemberIntakeData(payload)).pipe(
        mergeMap(({data: {message}}) => {
          return [
            intakeActions.getMemberIntakeDataSuccess({
              intakeResponse: message,
              user: currentState.user.current,
            }),
          ];
        }),
        catchError(error =>
          concat(of(intakeActions.getMemberIntakeDataFailure(error.message))),
        ),
      ),
    ),
  );

export const intakeEpics = [
  checkUserExistsEpic,
  checkUserExistsFailureEpic,
  checkUserExistsSuccessEpic,
  saveIntakeDataEpic,
  getMemberIntakeDataEpic,
];
