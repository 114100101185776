import {createAction} from '@reduxjs/toolkit';

export const participantJoinedNotification = createAction<boolean>(
  'participantJoinedNotification',
);

export const emitVideoPresenceNotification = createAction<{
  patientId: string;
  providerId: string;
  value: string | null;
}>('emitVideoPresenceNotification');

export const emitAdmitRequestNotification = createAction<{
  patientId: string;
  value: string | null;
}>('emitAdmitRequestNotification');

export const admitRequestStatusNotification = createAction<string>(
  'admitRequestStatusNotification',
);

export const setAdmitRequestStatus = createAction<string>('setRequestStatus');
