import {store} from 'app/store';
import {NoteFormProps, UpdateNoteFormProps} from 'definitions/Yup';
import {
  CMSFormSubmissionStatus,
  Medication,
  MedicationNameList,
  NotesType,
  OpenAIPrompt,
  OpenAIResponse,
  UserRoles,
} from 'interfaces';
import {normalize, schema} from 'normalizr';
import {transformNoteMedicationsData, unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const note = new schema.Entity<NotesType>(
  'notes',
  {},
  {
    idAttribute: (value): string => {
      return value.noteId;
    },
  },
);

const arrayOfNotes = new schema.Array(note);

const getNotes = async (data: {
  userRole: UserRoles;
  patientEmail: string;
  pageNo: number;
  size: number;
  roomMode?: string; // applicable only for group call
}): Promise<{
  data: {[key: string]: NotesType};
  hasMore: boolean;
  totalNotes: number;
  nextPage: number;
  previousPage: number;
}> => {
  try {
    const res = await requestHandler<{
      message: {
        notes: NotesType[];
        hasMore: boolean;
        totalNotes: number;
        nextPage: number;
        previousPage: number;
      };
    }>({
      method: HttpMethods.GET,
      url: `/api/${data.userRole}/notes/${data.patientEmail}?pageNo=${data.pageNo}&size=${data.size}&roomMode=${data.roomMode}` as unknown as EndPoints,
    });

    const {
      message: {notes, hasMore, totalNotes, previousPage, nextPage},
    } = res.data;
    const normalizedNotes = normalize(notes, arrayOfNotes);
    // TODO: Bad codes, pull the previous notes and pass them as arguements. So we dont access store directly.
    const previousNotes = store.getState()?.provider?.notes?.data;

    return {
      data: {...previousNotes, ...normalizedNotes.entities?.notes},
      hasMore,
      totalNotes,
      previousPage,
      nextPage,
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const createNote = async (
  data: NoteFormProps & {
    userRole: UserRoles;
    patientEmail: string;
    medicationList: Medication[];
    noteCreatorName: string;
    appointmentID?: string;
    roomMode: string;
  },
): Promise<{
  normalizedNotes: {[key: string]: NotesType};
}> => {
  try {
    const res = await requestHandler<{
      message: {
        note: NotesType;
      };
    }>({
      method: HttpMethods.POST,
      url: `/api/${data.userRole}/notes/${data.patientEmail}` as unknown as EndPoints,
      data: {
        ...data,
        date: Date.now(),
        noteCreatorName: data.noteCreatorName,
        noteCreatorRole: data.userRole,
        ...(data.medications
          ? {
              medications: transformNoteMedicationsData(
                data.medications as MedicationNameList[],
                data.userRole,
              ),
            }
          : {}),
      },
    });
    const {message} = res.data;
    const normalizedNotes = normalize(message.note, note);

    const previousNotes = store.getState()?.provider?.notes?.data;
    return {
      normalizedNotes: {
        ...normalizedNotes.entities?.notes,
        ...previousNotes,
      },
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateNote = async (
  data: UpdateNoteFormProps & {
    userRole: UserRoles;
    patientEmail: string;
    medicationList: Medication[];
    noteId: string;
    noteCreatorName: string;
  },
): Promise<{
  normalizedNotes: {[key: string]: NotesType};
}> => {
  try {
    const res = await requestHandler<{
      message: {
        note: NotesType;
      };
    }>({
      method: HttpMethods.PUT,
      url: `/api/${data.userRole}/notes/${data.patientEmail}?noteId=${data.noteId}` as unknown as EndPoints,
      data: {
        ...data,
        date: Date.now(),
        noteCreatorName: data.noteCreatorName,
        noteCreatorRole: data.userRole,
        ...(data.medications
          ? {
              medications: transformNoteMedicationsData(
                data.medications as MedicationNameList[],
                data.userRole,
              ),
            }
          : {}),
      },
    });
    const {message} = res.data;
    const normalizedNotes = normalize(message.note, note);

    const previousNotes = store.getState()?.provider?.notes?.data;

    return {
      normalizedNotes:
        {
          ...previousNotes,
          [normalizedNotes.result]: (normalizedNotes.entities?.notes as any)[
            normalizedNotes.result
          ],
        } ?? {},
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateNote = async (data: {
  prompt: OpenAIPrompt;
  userRole: UserRoles;
  patientId: string;
  appointmentID?: string;
  preferredLanguage?: string;
}): Promise<{
  message: {content: OpenAIResponse};
}> => {
  try {
    const res = await requestHandler<{
      message: {
        content: string;
      };
    }>({
      method: HttpMethods.POST,
      url: `/api/${data.userRole}/notes/openai/generate` as unknown as EndPoints,
      data: {
        prompt: JSON.stringify(data.prompt),
        patientId: data.patientId,
        appointmentID: data.appointmentID,
        preferredLanguage: data.preferredLanguage,
      },
    });
    const {message} = res.data;
    return {
      message: {
        content: JSON.parse(message.content),
      },
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getGeneratedNotePreviousInputs = async (data: {
  userRole: UserRoles;
  patientId: string;
}): Promise<{
  message: {previousInputs: OpenAIPrompt};
}> => {
  try {
    const res = await requestHandler<{
      message: {
        previousInputs: OpenAIPrompt;
      };
    }>({
      method: HttpMethods.POST,
      url: `/api/${data.userRole}/notes/openai/retrieve` as unknown as EndPoints,
      data: {
        patientId: data.patientId,
      },
    });
    const {message} = res.data;
    return {
      message: {
        previousInputs: message.previousInputs,
      },
    };
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const createCMS1500Form = async (data: {
  userRole: UserRoles;
  noteId: string;
}): Promise<{
  normalizedNotes: {[key: string]: NotesType};
}> => {
  const previousNotes = store.getState()?.provider?.notes?.data;

  const copyNote = {...previousNotes[data.noteId]};
  try {
    await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.POST,
      url: `/api/${data.userRole}/credentialing/generate-cms/${data.noteId}` as unknown as EndPoints,
    });
    copyNote.isCmsFormSubmitted = CMSFormSubmissionStatus.submitted;

    return {normalizedNotes: {...previousNotes, [data.noteId]: copyNote} ?? {}};
  } catch (error) {
    copyNote.isCmsFormSubmitted = CMSFormSubmissionStatus.failed;
    store.dispatch({
      type: 'updateNoteOnCMSFailed',
      payload:
        {
          ...previousNotes,
          [data.noteId]: copyNote,
        } ?? {},
    });
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const NotesService = {
  getNotes,
  createNote,
  updateNote,
  generateNote,
  getGeneratedNotePreviousInputs,
  createCMS1500Form,
};
