import {TFunction} from 'i18next';

export const OtherClinicalConditionsOptions = (t: TFunction) => [
  {
    label: t('otherClinicalConditionsOptions.diabetes', 'Diabetes'),
    value: 'Diabetes',
  },
  {
    label: t('otherClinicalConditionsOptions.hypertension', 'Hypertension'),
    value: 'Hypertension',
  },
  {
    label: t('otherClinicalConditionsOptions.asthma', 'Asthma'),
    value: 'Asthma',
  },
  {
    label: t('otherClinicalConditionsOptions.allergies', 'Allergies'),
    value: 'Allergies',
  },
  {
    label: t('otherClinicalConditionsOptions.arthritis', 'Arthritis'),
    value: 'Arthritis',
  },
  {
    label: t('otherClinicalConditionsOptions.depression', 'Depression'),
    value: 'Depression',
  },
  {
    label: t('otherClinicalConditionsOptions.anxiety', 'Anxiety'),
    value: 'Anxiety',
  },
  {
    label: t('otherClinicalConditionsOptions.heartDisease', 'Heart Disease'),
    value: 'Heart Disease',
  },
  {
    label: t('otherClinicalConditionsOptions.obesity', 'Obesity'),
    value: 'Obesity',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.thyroidDisorders',
      'Thyroid Disorders',
    ),
    value: 'Thyroid Disorders',
  },
  {
    label: t('otherClinicalConditionsOptions.migraines', 'Migraines'),
    value: 'Migraines',
  },
  {
    label: t('otherClinicalConditionsOptions.chronicPain', 'Chronic Pain'),
    value: 'Chronic Pain',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.gastrointestinalDisorders',
      'Gastrointestinal Disorders',
    ),
    value: 'Gastrointestinal Disorders',
  },
  {
    label: t('otherClinicalConditionsOptions.cancer', 'Cancer'),
    value: 'Cancer',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.autoimmuneDiseases',
      'Autoimmune Diseases',
    ),
    value: 'Autoimmune Diseases',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.infectiousDiseases',
      'Infectious Diseases',
    ),
    value: 'Infectious Diseases',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.respiratoryInfections',
      'Respiratory Infections',
    ),
    value: 'Respiratory Infections',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.skinConditions',
      'Skin Conditions',
    ),
    value: 'Skin Conditions',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.neurologicalDisorders',
      'Neurological Disorders',
    ),
    value: 'Neurological Disorders',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.pregnancyRelatedConditions',
      'Pregnancy-related Conditions',
    ),
    value: 'Pregnancy-related Conditions',
  },
  {
    label: t('otherClinicalConditionsOptions.kidneyDisease', 'Kidney Disease'),
    value: 'Kidney Disease',
  },
  {
    label: t('otherClinicalConditionsOptions.liverDisease', 'Liver Disease'),
    value: 'Liver Disease',
  },
  {
    label: t('otherClinicalConditionsOptions.osteoporosis', 'Osteoporosis'),
    value: 'Osteoporosis',
  },
  {
    label: t('otherClinicalConditionsOptions.eyeDisorders', 'Eye Disorders'),
    value: 'Eye Disorders',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.sleepDisorders',
      'Sleep Disorders',
    ),
    value: 'Sleep Disorders',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.substanceAbuse',
      'Substance Abuse',
    ),
    value: 'Substance Abuse',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.sexualHealthIssues',
      'Sexual Health Issues',
    ),
    value: 'Sexual Health Issues',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.eatingDisorders',
      'Eating Disorders',
    ),
    value: 'Eating Disorders',
  },
  {
    label: t('otherClinicalConditionsOptions.stroke', 'Stroke'),
    value: 'Stroke',
  },
  {
    label: t(
      'otherClinicalConditionsOptions.inflammatoryBowelDiseaseIBD',
      'Inflammatory Bowel Disease (IBD)',
    ),
    value: 'Inflammatory Bowel Disease (IBD)',
  },
];

export const PromptRelatedQuestions = [
  {
    prompt: 'Patient Goals From Session In Their Own Words Is',
    questionPartialText: 'gain from Counseling',
  },
  {
    prompt: 'Patient Sex',
    questionPartialText: 'sex',
  },
  {
    prompt: 'Patient Preferred Pronoun',
    questionPartialText: 'Preferred Pronoun',
  },
  {
    prompt: 'Patient Has Dealt With',
    questionPartialText: 'dealt with',
  },
  {
    prompt: 'Patient Has These Difficulties',
    questionPartialText: 'difficulties with alcohol',
  },
  {
    prompt: 'Patient Has These Difficulties As Child',
    questionPartialText: 'As a child',
  },
  {
    prompt: 'Patient Use Or Used',
    questionPartialText: 'currently use or have used',
  },
  {
    prompt: 'Patient Current Employment Status',
    questionPartialText: 'employment situation',
  },
  {
    prompt: 'Patient Social Interaction',
    questionPartialText: 'social interaction',
  },
  {
    prompt: 'Patient Current Financial Situation',
    questionPartialText: 'financial situation',
  },
  {
    prompt: 'Patient Current Legal Situation',
    questionPartialText: 'legal issues',
  },
  {
    prompt: 'Patient previously had suicidal or homicidal thoughts',
    questionPartialText: 'had any suicidal or homicidal',
  },
  {
    prompt: 'Patient currently has suicidal or homicidal thoughts',
    questionPartialText: 'currently having suicidal or homicidal',
  },
];

export const MoodDisordersOptions = (t: TFunction) => [
  {
    value: 'Major Depressive Disorder (MDD)',
    label: t(
      'moodDisordersOptions.majorDepressiveDisorderMDD',
      'Major Depressive Disorder (MDD)',
    ),
  },

  {
    value: 'Bipolar Disorder',
    label: t('moodDisordersOptions.bipolarDisorder', 'Bipolar Disorder'),
  },

  {
    value: 'Dysthymia (Persistent Depressive Disorder)',
    label: t(
      'moodDisordersOptions.dysthmiaPersistentDepressiveDisorder',
      'Dysthymia (Persistent Depressive Disorder)',
    ),
  },

  {
    value: 'Cyclothymic Disorder',
    label: t(
      'moodDisordersOptions.cyclothymicDisorder',
      'Cyclothymic Disorder',
    ),
  },

  {
    value: 'Seasonal Affective Disorder (SAD)',
    label: t(
      'moodDisordersOptions.seasonalAffectiveDisorderSAD',
      'Seasonal Affective Disorder (SAD)',
    ),
  },
  {
    value: 'General Anxiety Disorder (GAD)',
    label: t(
      'moodDisordersOptions.generalAnxietyDisorderGAD',
      'General Anxiety Disorder (GAD)',
    ),
  },
];

export const ObsessiveCompulsiveandRelatedDisordersOptions = (t: TFunction) => [
  {
    value: 'Obsessive-Compulsive Disorder (OCD)',
    label: t(
      'obsessiveCompulsiveandRelatedDisordersOptions.obsessiveCompulsiveDisorderOCD',
      'Obsessive-Compulsive Disorder (OCD)',
    ),
  },

  {
    value: 'Body Dysmorphic Disorder',
    label: t(
      'obsessiveCompulsiveandRelatedDisordersOptions.bodyDysmorphicDisorder',
      'Body Dysmorphic Disorder',
    ),
  },

  {
    value: 'Hoarding Disorder',
    label: t(
      'obsessiveCompulsiveandRelatedDisordersOptions.hoardingDisorder',
      'Hoarding Disorder',
    ),
  },

  {
    value: 'Trichotillomania (Hair-Pulling Disorder)',
    label: t(
      'obsessiveCompulsiveandRelatedDisordersOptions.trichotillomaniaHairPullingDisorder',
      'Trichotillomania (Hair-Pulling Disorder)',
    ),
  },

  {
    value: 'Excoriation (Skin-Picking) Disorder',
    label: t(
      'obsessiveCompulsiveandRelatedDisordersOptions.excoriationSkinPickingDisorder',
      'Excoriation (Skin-Picking) Disorder',
    ),
  },
];

export const TraumaandStressorRelatedDisordersOptions = (t: TFunction) => [
  {
    value: 'Post-Traumatic Stress Disorder (PTSD)',
    label: t(
      'traumaandStressorRelatedDisordersOptions.postTraumaticStressDisorderPTSD',
      'Post-Traumatic Stress Disorder (PTSD)',
    ),
  },

  {
    value: 'Acute Stress Disorder',
    label: t(
      'traumaandStressorRelatedDisordersOptions.acuteStressDisorder',
      'Acute Stress Disorder',
    ),
  },

  {
    value: 'Adjustment Disorders',
    label: t(
      'traumaandStressorRelatedDisordersOptions.adjustmentDisorder',
      'Adjustment Disorders',
    ),
  },
];

export const DissociativeDisordersOptions = (t: TFunction) => [
  {
    value: 'Dissociative Identity Disorder',
    label: t(
      'dissociativeDisordersOptions.dissociativeIdentityDisorder',
      'Dissociative Identity Disorder',
    ),
  },

  {
    value: 'Dissociative Amnesia',
    label: t(
      'dissociativeDisordersOptions.dissociativeAmnesia',
      'Dissociative Amnesia',
    ),
  },

  {
    value: 'Depersonalization-Derealization Disorder',
    label: t(
      'dissociativeDisordersOptions.depersonalizationDerealizationDisorder',
      'Depersonalization-Derealization Disorder',
    ),
  },
];

export const SomaticSymptomandRelatedDisordersOptions = (t: TFunction) => [
  {
    value: 'Somatic Symptom Disorder',
    label: t(
      'somaticSymptomandRelatedDisordersOptions.somaticSymptomDisorder',
      'Somatic Symptom Disorder',
    ),
  },

  {
    value: 'Illness Anxiety Disorder',
    label: t(
      'somaticSymptomandRelatedDisordersOptions.illnessAnxietyDisorder',
      'Illness Anxiety Disorder',
    ),
  },

  {
    value: 'Conversion Disorder',
    label: t(
      'somaticSymptomandRelatedDisordersOptions.conversionDisorder',
      'Conversion Disorder',
    ),
  },

  {
    value: 'Factitious Disorder',
    label: t(
      'somaticSymptomandRelatedDisordersOptions.factitiousDisorder',
      'Factitious Disorder',
    ),
  },
];

export const FeedingandEatingDisordersOptions = (t: TFunction) => [
  {
    value: 'Anorexia Nervosa',
    label: t(
      'feedingandEatingDisordersOptions.anorexiaNervosa',
      'Anorexia Nervosa',
    ),
  },

  {
    value: 'Bulimia Nervosa',
    label: t(
      'feedingandEatingDisordersOptions.bulimiaNervosa',
      'Bulimia Nervosa',
    ),
  },

  {
    value: 'Binge-Eating Disorder',
    label: t(
      'feedingandEatingDisordersOptions.bingeEatingDisorder',
      'Binge-Eating Disorder',
    ),
  },

  {
    value: 'Avoidant/Restrictive Food Intake Disorder (ARFID)',
    label: t(
      'feedingandEatingDisordersOptions.avoidantRestrictiveFoodIntakeDisorderARFID',
      'Avoidant/Restrictive Food Intake Disorder (ARFID)',
    ),
  },

  {value: 'Pica', label: t('feedingandEatingDisordersOptions.pica', 'Pica')},

  {
    value: 'Rumination Disorder',
    label: t(
      'feedingandEatingDisordersOptions.ruminationDisorder',
      'Rumination Disorder',
    ),
  },
];

export const SleepWakeDisordersOptions = (t: TFunction) => [
  {
    value: 'Insomnia Disorder',
    label: t('sleepWakeDisordersOptions.insomniaDisorder', 'Insomnia Disorder'),
  },

  {
    value: 'Hypersomnolence Disorder',
    label: t(
      'sleepWakeDisordersOptions.hypersomnolenceDisorder',
      'Hypersomnolence Disorder',
    ),
  },

  {
    value: 'Narcolepsy',
    label: t('sleepWakeDisordersOptions.narcolepsy', 'Narcolepsy'),
  },

  {
    value: 'Breathing-Related Sleep Disorders',
    label: t(
      'sleepWakeDisordersOptions.breathingRelatedSleepDisorders',
      'Breathing-Related Sleep Disorders',
    ),
  },
];

export const SexualDysfunctionsOptions = (t: TFunction) => [
  {
    value: 'Erectile Disorder',
    label: t('sexualDysfunctionsOptions.erectileDisorder', 'Erectile Disorder'),
  },

  {
    value: 'Female Sexual Interest/Arousal Disorder',
    label: t(
      'sexualDysfunctionsOptions.femaleSexualInterestArousalDisorder',
      'Female Sexual Interest/Arousal Disorder',
    ),
  },

  {
    value: 'Male Hypoactive Sexual Desire Disorder',
    label: t(
      'sexualDysfunctionsOptions.maleHypoactiveSexualDesireDisorder',
      'Male Hypoactive Sexual Desire Disorder',
    ),
  },

  {
    value: 'Premature (Early) Ejaculation',
    label: t(
      'sexualDysfunctionsOptions.prematureEarlyEjaculation',
      'Premature (Early) Ejaculation',
    ),
  },

  {
    value: 'Gender Dysphoria',
    label: t('sexualDysfunctionsOptions.genderDysporia', 'Gender Dysphoria'),
  },
];

export const DisruptiveImpulseControlandConductDisordersOptions = (
  t: TFunction,
) => [
  {
    value: 'Oppositional Defiant Disorder',
    label: t(
      'disruptiveImpulseControlandConductDisordersOptions.oppositionalDefiantDisorder',
      'Oppositional Defiant Disorder',
    ),
  },

  {
    value: 'Conduct Disorder',
    label: t(
      'disruptiveImpulseControlandConductDisordersOptions.conductDisorder',
      'Conduct Disorder',
    ),
  },

  {
    value: 'Intermittent Explosive Disorder',
    label: t(
      'disruptiveImpulseControlandConductDisordersOptions.intermittentExplosiveDisorder',
      'Intermittent Explosive Disorder',
    ),
  },

  {
    value: 'Pyromania',
    label: t(
      'disruptiveImpulseControlandConductDisordersOptions.pyromania',
      'Pyromania',
    ),
  },

  {
    value: 'Kleptomania',
    label: t(
      'disruptiveImpulseControlandConductDisordersOptions.kleptomania',
      'Kleptomania',
    ),
  },
];

export const NeurodevelopmentalDisordersOptions = (t: TFunction) => [
  {
    value: 'Attention-Deficit/Hyperactivity Disorder (ADHD)',
    label: t(
      'neurodevelopmentalDisordersOptions.attentionDeficitHyperactivityDisorderADHD',
      'Attention-Deficit/Hyperactivity Disorder (ADHD)',
    ),
  },

  {
    value: 'Autism Spectrum Disorder',
    label: t(
      'neurodevelopmentalDisordersOptions.autismSpectrumDisorder',
      'Autism Spectrum Disorder',
    ),
  },

  {
    value: 'Learning Disorders',
    label: t(
      'neurodevelopmentalDisordersOptions.learningDisorder',
      'Learning Disorders',
    ),
  },
];

export const PersonalityDisordersOptions = (t: TFunction) => [
  {
    value: 'Borderline Personality Disorder',
    label: t(
      'personalityDisordersOptions.borderlinePersonalityDisorder',
      'Borderline Personality Disorder',
    ),
  },

  {
    value: 'Obsessive-Compulsive Personality Disorder',
    label: t(
      'personalityDisordersOptions.obsessiveCompulsivePersonalityDisorder',
      'Obsessive-Compulsive Personality Disorder',
    ),
  },

  {
    value: 'Avoidant Personality Disorder',
    label: t(
      'personalityDisordersOptions.avoidantPersonalityDisorder',
      'Avoidant Personality Disorder',
    ),
  },

  {
    value: 'Dependent Personality Disorder',
    label: t(
      'personalityDisordersOptions.dependentPersonalityDisorder',
      'Dependent Personality Disorder',
    ),
  },

  {
    value: 'Paranoid Personality Disorder',
    label: t(
      'personalityDisordersOptions.paranoidPersonalityDisorder',
      'Paranoid Personality Disorder',
    ),
  },
];

export const AdjustmentDisordersOptions = (t: TFunction) => [
  {
    value: 'Adjustment Disorder with Depressed Mood',
    label: t(
      'adjustmentDisordersOptions.adjustmentDisorderWithDepressedMood',
      'Adjustment Disorder with Depressed Mood',
    ),
  },

  {
    value: 'Adjustment Disorder with Anxiety',
    label: t(
      'adjustmentDisordersOptions.adjustmentDisorderWithAnxiety',
      'Adjustment Disorder with Anxiety',
    ),
  },

  {
    value: 'Adjustment Disorder with Mixed Anxiety and Depressed Mood',
    label: t(
      'adjustmentDisordersOptions.adjustmentDisorderWithMixedAnxietyDepressedMood',
      'Adjustment Disorder with Mixed Anxiety and Depressed Mood',
    ),
  },

  {
    value: 'Adjustment Disorder with Disturbance of Conduct',
    label: t(
      'adjustmentDisordersOptions.adjustmentDisorderWithDisturbanceConduct',
      'Adjustment Disorder with Disturbance of Conduct',
    ),
  },

  {
    value: 'Adjustment Disorder with Mixed Disturbance of Emotions and Conduct',
    label: t(
      'adjustmentDisordersOptions.adjustmentDisorderWithDisturbanceEmotionsConduct',
      'Adjustment Disorder with Mixed Disturbance of Emotions and Conduct',
    ),
  },

  {
    value: 'Adjustment Disorder, Unspecified',
    label: t(
      'adjustmentDisordersOptions.adjustmentDisorderUnspecified',
      'Adjustment Disorder, Unspecified',
    ),
  },
];

export const GriefandLossRelatedIssuesOptions = (t: TFunction) => [
  {
    value: 'Bereavement',
    label: t('griefandLossRelatedIssuesOptions.bereavement', 'Bereavement'),
  },

  {
    value: 'Complicated Grief Disorder',
    label: t(
      'griefandLossRelatedIssuesOptions.complicatedGriefDisorder',
      'Complicated Grief Disorder',
    ),
  },

  {
    value: 'Loss of a loved one',
    label: t(
      'griefandLossRelatedIssuesOptions.lossLovedOne',
      'Loss of a loved one',
    ),
  },

  {
    value: 'Loss of a pet',
    label: t('griefandLossRelatedIssuesOptions.lossPet', 'Loss of a pet'),
  },

  {
    value: 'Loss related to major life changes (e.g., job loss, divorce)',
    label: t(
      'griefandLossRelatedIssuesOptions.lossRelatedMajorLifeChange',
      'Loss related to major life changes (e.g., job loss, divorce)',
    ),
  },
];

export const SelfEsteemandSelfImageIssuesOptions = (t: TFunction) => [
  {
    value: 'Low Self-Esteem',
    label: t(
      'selfEsteemandSelfImageIssuesOptions.lowSelfEsteem',
      'Low Self-Esteem',
    ),
  },

  {
    value: 'Body Image Issues',
    label: t(
      'selfEsteemandSelfImageIssuesOptions.bodyImageIssues',
      'Body Image Issues',
    ),
  },

  {
    value: 'Chronic Self-Doubt',
    label: t(
      'selfEsteemandSelfImageIssuesOptions.chronicSelfDoubt',
      'Chronic Self-Doubt',
    ),
  },

  {
    value: 'Perfectionism',
    label: t(
      'selfEsteemandSelfImageIssuesOptions.perfectionism',
      'Perfectionism',
    ),
  },

  {
    value: 'Feelings of Worthlessness',
    label: t(
      'selfEsteemandSelfImageIssuesOptions.feelingWorthlessness',
      'Feelings of Worthlessness',
    ),
  },

  {
    value: 'Inadequacy',
    label: t('selfEsteemandSelfImageIssuesOptions.inadeqacy', 'Inadequacy'),
  },
];

export const RelationshipIssuesOptions = (t: TFunction) => [
  {
    value: 'Marital and Premarital Concerns',
    label: t(
      'relationshipIssuesOptions.maritalPremaritalConcern',
      'Marital and Premarital Concerns',
    ),
  },

  {
    value: 'Family Conflict',
    label: t('relationshipIssuesOptions.familyConflict', 'Family Conflict'),
  },

  {
    value: 'Parenting Issues',
    label: t('relationshipIssuesOptions.parentingIssues', 'Parenting Issues'),
  },

  {
    value: 'Relationship and Dating Issues',
    label: t(
      'relationshipIssuesOptions.relationshipDatingIssues',
      'Relationship and Dating Issues',
    ),
  },

  {value: 'Divorce', label: t('relationshipIssuesOptions.divorce', 'Divorce')},

  {
    value: 'Infidelity',
    label: t('relationshipIssuesOptions.infidelity', 'Infidelity'),
  },

  {
    value: 'Communication Problems',
    label: t(
      'relationshipIssuesOptions.communicationProbs',
      'Communication Problems',
    ),
  },

  {
    value: 'Trust Issues',
    label: t('relationshipIssuesOptions.trustIssues', 'Trust Issues'),
  },

  {
    value: 'Conflict Resolution Problems',
    label: t(
      'relationshipIssuesOptions.conflictResolutionProbs',
      'Conflict Resolution Problems',
    ),
  },
];

export const LifeTransitionChallengesOptions = (t: TFunction) => [
  {
    value: 'Career Changes',
    label: t('lifeTransitionChallengesOptions.careerChange', 'Career Changes'),
  },

  {
    value: 'Retirement',
    label: t('lifeTransitionChallengesOptions.retirement', 'Retirement'),
  },

  {
    value: 'Empty Nest Syndrome',
    label: t(
      'lifeTransitionChallengesOptions.emptyNestSyndrome',
      'Empty Nest Syndrome',
    ),
  },

  {
    value: 'Aging-Related Issues',
    label: t(
      'lifeTransitionChallengesOptions.agingRelatedIssues',
      'Aging-Related Issues',
    ),
  },

  {
    value: 'Relocation Stress',
    label: t(
      'lifeTransitionChallengesOptions.relocationStress',
      'Relocation Stress',
    ),
  },

  {
    value: 'Identity Crises',
    label: t(
      'lifeTransitionChallengesOptions.identityCrises',
      'Identity Crises',
    ),
  },

  {
    value: 'Midlife Crisis',
    label: t('lifeTransitionChallengesOptions.midlifeCrisis', 'Midlife Crisis'),
  },

  {
    value: 'Transitioning to Parenthood',
    label: t(
      'lifeTransitionChallengesOptions.transitioningParenthood',
      'Transitioning to Parenthood',
    ),
  },

  {
    value: 'School or Work Transitions',
    label: t(
      'lifeTransitionChallengesOptions.schoolWorkTransitions',
      'School or Work Transitions',
    ),
  },
];

export const OtherPsychologicalIssuesOptions = (t: TFunction) => [
  {
    value: 'Chronic Pain and Illness',
    label: t(
      'otherPsychologicalIssuesOptions.chronicPainIllness',
      'Chronic Pain and Illness',
    ),
  },

  {
    value: 'Stress Management',
    label: t(
      'otherPsychologicalIssuesOptions.stressManagement',
      'Stress Management',
    ),
  },

  {
    value: 'Anger Management',
    label: t(
      'otherPsychologicalIssuesOptions.angerManagement',
      'Anger Management',
    ),
  },

  {
    value: 'Sleep Disturbances',
    label: t(
      'otherPsychologicalIssuesOptions.sleepDisturbances',
      'Sleep Disturbances',
    ),
  },

  {
    value: 'Coping with Medical Illness',
    label: t(
      'otherPsychologicalIssuesOptions.copingMedicalIllness',
      'Coping with Medical Illness',
    ),
  },

  {
    value: 'Substance Abuse (including Alcohol and Drug Abuse)',
    label: t(
      'otherPsychologicalIssuesOptions.substanceAbuseAlcoholDrugAbuse',
      'Substance Abuse (including Alcohol and Drug Abuse)',
    ),
  },

  {
    value: 'Academic Concerns',
    label: t(
      'otherPsychologicalIssuesOptions.academicConcerns',
      'Academic Concerns',
    ),
  },

  {
    value: 'Work-Related Stress',
    label: t(
      'otherPsychologicalIssuesOptions.workRelatedStress',
      'Work-Related Stress',
    ),
  },

  {
    value: 'Acculturation Issues',
    label: t(
      'otherPsychologicalIssuesOptions.acculturationIssues',
      'Acculturation Issues',
    ),
  },

  {
    value: 'Spiritual and Existential Concerns',
    label: t(
      'otherPsychologicalIssuesOptions.spiritualExistentialConcerns',
      'Spiritual and Existential Concerns',
    ),
  },

  {
    value: 'Phase of Life Problems',
    label: t(
      'otherPsychologicalIssuesOptions.phaseLifeProblems',
      'Phase of Life Problems',
    ),
  },
];

export const PsychosomaticDisordersOptions = (t: TFunction) => [
  {
    value: 'Chronic Fatigue Syndrome',
    label: t(
      'psychosomaticDisordersOptions.chronicFatigueSyndrome',
      'Chronic Fatigue Syndrome',
    ),
  },

  {
    value: 'Irritable Bowel Syndrome',
    label: t(
      'psychosomaticDisordersOptions.irritableBowelSyndrome',
      'Irritable Bowel Syndrome',
    ),
  },

  {
    value: 'Non-Epileptic Seizures',
    label: t(
      'psychosomaticDisordersOptions.nonEpilepticSeizures',
      'Non-Epileptic Seizures',
    ),
  },

  {
    value: 'Fibromyalgia',
    label: t('psychosomaticDisordersOptions.fibromyalgia', 'Fibromyalgia'),
  },

  {
    value: 'Tinnitus',
    label: t('psychosomaticDisordersOptions.tinnitus', 'Tinnitus'),
  },
];

export const LGBTQIARelatedIssuesOptions = (t: TFunction) => [
  {
    value: 'Coming Out Stress',
    label: t(
      'lgbtqIARelatedIssuesOptions.comingOutStress',
      'Coming Out Stress',
    ),
  },

  {
    value: 'Identity Acceptance',
    label: t(
      'lgbtqIARelatedIssuesOptions.identityAcceptance',
      'Identity Acceptance',
    ),
  },

  {
    value: 'Internalized Homophobia/Transphobia',
    label: t(
      'lgbtqIARelatedIssuesOptions.internalizedHomophobiaTransphobia',
      'Internalized Homophobia/Transphobia',
    ),
  },

  {
    value: 'LGBTQIA+ Relationship Challenges',
    label: t(
      'lgbtqIARelatedIssuesOptions.lgbtqiaRelationshipChallenges',
      'LGBTQIA+ Relationship Challenges',
    ),
  },

  {
    value: 'Minority Stress',
    label: t('lgbtqIARelatedIssuesOptions.minorityStress', 'Minority Stress'),
  },
];

export const StigmaandDiscriminationOptions = (t: TFunction) => [
  {
    value: 'Racial and Ethnic Discrimination',
    label: t(
      'stigmaandDiscriminationOptions.racialEthnicDiscrimination',
      'Racial and Ethnic Discrimination',
    ),
  },

  {
    value: 'Mental Health Stigma',
    label: t(
      'stigmaandDiscriminationOptions.mentalHealthStigma',
      'Mental Health Stigma',
    ),
  },

  {
    value: 'Weight Stigma',
    label: t('stigmaandDiscriminationOptions.weightStigma', 'Weight Stigma'),
  },

  {
    value: 'Disability Stigma',
    label: t(
      'stigmaandDiscriminationOptions.disabilityStigma',
      'Disability Stigma',
    ),
  },
];

export const HistoricalTraumaOptions = (t: TFunction) => [
  {
    value: 'Intergenerational Trauma',
    label: t(
      'historicalTraumaOptions.intergenerationalTrauma',
      'Intergenerational Trauma',
    ),
  },

  {
    value: 'Holocaust Survivor Syndrome',
    label: t(
      'historicalTraumaOptions.holocaustSurvivorSyndrome',
      'Holocaust Survivor Syndrome',
    ),
  },

  {
    value: 'Native American Historical Trauma',
    label: t(
      'historicalTraumaOptions.nativeUSHistoricalTrauma',
      'Native American Historical Trauma',
    ),
  },

  {
    value: 'Slavery and Post-Slavery Trauma Syndrome',
    label: t(
      'historicalTraumaOptions.slaveryPostSlaveryTraumaSyndrome',
      'Slavery and Post-Slavery Trauma Syndrome',
    ),
  },

  {
    value: 'War and Conflict-Related Trauma',
    label: t(
      'historicalTraumaOptions.warConflictRelatedTrauma',
      'War and Conflict-Related Trauma',
    ),
  },

  {
    value: 'Refugee and Displacement Trauma',
    label: t(
      'historicalTraumaOptions.refugeeDisplacementTrauma',
      'Refugee and Displacement Trauma',
    ),
  },

  {
    value: 'Residential School Syndrome (specific to Indigenous Peoples)',
    label: t(
      'historicalTraumaOptions.residentialSchoolSyndrome',
      'Residential School Syndrome (specific to Indigenous Peoples)',
    ),
  },

  {
    value: 'Colonialism and Post-Colonialism Trauma',
    label: t(
      'historicalTraumaOptions.colonialismPostColonialismTrauma',
      'Colonialism and Post-Colonialism Trauma',
    ),
  },

  {
    value: 'Cultural Genocide Trauma',
    label: t(
      'historicalTraumaOptions.culturalGenocideTrauma',
      'Cultural Genocide Trauma',
    ),
  },

  {
    value: 'Trauma Related to Religious Persecution',
    label: t(
      'historicalTraumaOptions.traumaRelatedReligiousPersecution',
      'Trauma Related to Religious Persecution',
    ),
  },
];

export const CodependencyOptions = (t: TFunction) => [
  {
    value: 'Relationship Codependency',
    label: t(
      'codependencyOptions.relationshipCodependency',
      'Relationship Codependency',
    ),
  },

  {
    value: 'Parental Codependency',
    label: t(
      'codependencyOptions.parentalCodependency',
      'Parental Codependency',
    ),
  },

  {
    value: 'Codependency in Friendship',
    label: t(
      'codependencyOptions.codependencyFriendship',
      'Codependency in Friendship',
    ),
  },

  {
    value: 'Work-Related Codependency',
    label: t(
      'codependencyOptions.workRelatedCodependency',
      'Work-Related Codependency',
    ),
  },

  {
    value:
      'Substance Abuse Codependency (where a person is codependent with someone engaged in substance abuse)',
    label: t(
      'codependencyOptions.substanceAbuseCodependency',
      'Substance Abuse Codependency (where a person is codependent with someone engaged in substance abuse)',
    ),
  },

  {
    value: 'Emotional Codependency',
    label: t(
      'codependencyOptions.emotionalCodependency',
      'Emotional Codependency',
    ),
  },

  {
    value: 'Codependency in Caregiving Relationships',
    label: t(
      'codependencyOptions.codependencyCaregivingRelationships',
      'Codependency in Caregiving Relationships',
    ),
  },

  {
    value: 'Codependency with a Narcissistic Partner',
    label: t(
      'codependencyOptions.codependencyNarcissisticPartner',
      'Codependency with a Narcissistic Partner',
    ),
  },
];

export const ProcrastinationandMotivationIssuesOptions = (t: TFunction) => [
  {
    value: 'Chronic Procrastination',
    label: t(
      'procrastinationandMotivationIssuesOptions.chronicProcrastination',
      'Chronic Procrastination',
    ),
  },

  {
    value: 'Lack of Motivation',
    label: t(
      'procrastinationandMotivationIssuesOptions.lackMotivation',
      'Lack of Motivation',
    ),
  },

  {
    value: 'Decisional Procrastination',
    label: t(
      'procrastinationandMotivationIssuesOptions.decisionalProcrastination',
      'Decisional Procrastination',
    ),
  },

  {
    value: 'Academic Procrastination',
    label: t(
      'procrastinationandMotivationIssuesOptions.academicProcrastination',
      'Academic Procrastination',
    ),
  },

  {
    value: 'Perfectionist Procrastination',
    label: t(
      'procrastinationandMotivationIssuesOptions.perfectionistProcrastination',
      'Perfectionist Procrastination',
    ),
  },

  {
    value: 'Fear-of-Failure Procrastination',
    label: t(
      'procrastinationandMotivationIssuesOptions.fearFailureProcrastination',
      'Fear-of-Failure Procrastination',
    ),
  },

  {
    value:
      'Action Procrastination (delay in taking necessary steps even when decisions are made)',
    label: t(
      'procrastinationandMotivationIssuesOptions.actionProcrastination',
      'Action Procrastination (delay in taking necessary steps even when decisions are made)',
    ),
  },

  {
    value:
      'Attentional Procrastination (procrastinating due to lack of focus or attention)',
    label: t(
      'procrastinationandMotivationIssuesOptions.attentionalProcrastination',
      'Attentional Procrastination (procrastinating due to lack of focus or attention)',
    ),
  },

  {
    value:
      'Arousal Procrastination (waiting for the right mood or right time to start a task)',
    label: t(
      'procrastinationandMotivationIssuesOptions.arousalProcrastination',
      'Arousal Procrastination (waiting for the right mood or right time to start a task)',
    ),
  },

  {
    value:
      'Revenge Procrastination (deliberately procrastinating to regain control over ones time or life)',
    label: t(
      'procrastinationandMotivationIssuesOptions.revengeProcrastination',
      'Revenge Procrastination (deliberately procrastinating to regain control over ones time or life)',
    ),
  },
];

export const GroupedOptions = (t: TFunction) => [
  {
    label: t('disorders.mood', 'Mood Disorders'),
    options: MoodDisordersOptions(t),
  },
  {
    label: t(
      'disorders.obsessiveCompulsiveRelated',
      'Obsessive-Compulsive and Related Disorders',
    ),
    options: ObsessiveCompulsiveandRelatedDisordersOptions(t),
  },
  {
    label: t(
      'disorders.traumaStressorRelated',
      'Trauma and Stressor-Related Disorders',
    ),
    options: TraumaandStressorRelatedDisordersOptions(t),
  },
  {
    label: t('disorders.dissociative', 'Dissociative Disorders'),
    options: DissociativeDisordersOptions(t),
  },
  {
    label: t(
      'disorders.somaticSymptomRelated',
      'Somatic Symptom and Related Disorders',
    ),
    options: SomaticSymptomandRelatedDisordersOptions(t),
  },
  {
    label: t('disorders.feedingEating', 'Feeding and Eating Disorders'),
    options: FeedingandEatingDisordersOptions(t),
  },
  {
    label: t('disorders.sleepWake', 'Sleep-Wake Disorders'),
    options: SleepWakeDisordersOptions(t),
  },
  {
    label: t('disorders.sexualDysfunctions', 'Sexual Dysfunctions'),
    options: SexualDysfunctionsOptions(t),
  },
  {
    label: t(
      'disorders.disruptiveImpulseControlConduct',
      'Disruptive, Impulse-Control, and Conduct Disorders',
    ),
    options: DisruptiveImpulseControlandConductDisordersOptions(t),
  },
  {
    label: t('disorders.neuroDevelopmental', 'Neurodevelopmental Disorders'),
    options: NeurodevelopmentalDisordersOptions(t),
  },
  {
    label: t('disorders.personality', 'Personality Disorders'),
    options: PersonalityDisordersOptions(t),
  },
  {
    label: t('disorders.adjustment', 'Adjustment Disorders'),
    options: AdjustmentDisordersOptions(t),
  },
  {
    label: t('disorders.griefLossRelated', 'Grief and Loss-Related Issues'),
    options: GriefandLossRelatedIssuesOptions(t),
  },
  {
    label: t(
      'disorders.selfEsteemSelfImageIssues',
      'Self-Esteem and Self-Image Issues',
    ),
    options: SelfEsteemandSelfImageIssuesOptions(t),
  },
  {
    label: t('disorders.relationshipIssues', 'Relationship Issues'),
    options: RelationshipIssuesOptions(t),
  },
  {
    label: t(
      'disorders.lifeTransitionChallenges',
      'Life Transition Challenges',
    ),
    options: LifeTransitionChallengesOptions(t),
  },
  {
    label: t(
      'disorders.otherPsychologicalIssues',
      'Other Psychological Issues',
    ),
    options: OtherPsychologicalIssuesOptions(t),
  },
  {
    label: t('disorders.psychosomatic', 'Psychosomatic Disorders'),
    options: PsychosomaticDisordersOptions(t),
  },
  {
    label: t('disorders.lgbtqiaRelated', 'LGBTQIA+ Related Issues'),
    options: LGBTQIARelatedIssuesOptions(t),
  },
  {
    label: t('disorders.stigmaDiscrimination', 'Stigma and Discrimination'),
    options: StigmaandDiscriminationOptions(t),
  },
  {
    label: t('disorders.historicalTrauma', 'Historical Trauma'),
    options: HistoricalTraumaOptions(t),
  },
  {
    label: t('disorders.codependency', 'Codependency'),
    options: CodependencyOptions(t),
  },
  {
    label: t(
      'disorders.procrastinationMotivationIssues',
      'Procrastination and Motivation Issues',
    ),
    options: ProcrastinationandMotivationIssuesOptions(t),
  },
];
