import {createAction} from '@reduxjs/toolkit';
import {Token} from '@stripe/stripe-js';

export const onboardingOneTimePayment = createAction<{
  providerType: 'doctor' | 'therapist';
  token: Token;
}>('onboardingOneTimePayment');

export const onboardingOneTimePaymentFailure = createAction(
  'onboardingOneTimePaymentFailure',
);
export const onboardingOneTimePaymentSuccess = createAction(
  'onboardingOneTimePaymentSuccess',
);
