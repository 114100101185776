import {
  EndPoints,
  HttpMethods,
  ProgressAssessmentAllStatsResponse,
  ProgressAssessmentData,
  ProgressAssessmentStatsResponse,
  StatsTimePeriod,
} from 'interfaces';
import {requestHandler} from 'services/api';
import {attachQueryParameters, unwrapAPIError} from 'utils';

const updateProgressAssessmentEntry = async (
  formData: ProgressAssessmentData,
): Promise<boolean> => {
  try {
    await requestHandler<{message: boolean}, typeof formData>({
      method: HttpMethods.POST,
      url: EndPoints.UpdateProgressAssessment as unknown as EndPoints,
      data: formData,
    });
    return true;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getProgressAssessmentStats = async (args?: {
  email: string;
  type: string;
  period?: StatsTimePeriod;
}): Promise<ProgressAssessmentStatsResponse> => {
  try {
    const searchParams = attachQueryParameters('', args || {});

    const res = await requestHandler<{
      message: ProgressAssessmentStatsResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetProgressAssessmentStats}${searchParams}` as unknown as EndPoints,
    });
    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getProgressAssessmentAllStats = async (args?: {
  email: string;
  period?: StatsTimePeriod;
}): Promise<ProgressAssessmentAllStatsResponse> => {
  try {
    const searchParams = attachQueryParameters('', args || {});

    const res = await requestHandler<{
      message: ProgressAssessmentAllStatsResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetProgressAssessmentAllStats}${searchParams}` as unknown as EndPoints,
    });
    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const ProgressAssessmentService = {
  getProgressAssessmentStats,
  getProgressAssessmentAllStats,
  updateProgressAssessmentEntry,
};
