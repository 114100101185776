import {useEffect, useState} from 'react';
import {format} from 'date-fns';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {FormattedAppointmentType, useCalendar} from 'hooks';
import {SliceStatus} from 'interfaces';

dayjs.extend(timezone);
dayjs.extend(utc);

const now = new Date(new Date().setHours(0, 0, 0, 0));
const formattedNow = format(now, 'yyyy-MM-dd');
const userTimeZone = dayjs.tz.guess();

export function useDashboardAppts() {
  const {calendarLoadingState, formattedAppts, ...rest} = useCalendar();

  const [upcomingAppointments, setAppointments] = useState<
    FormattedAppointmentType[]
  >([]);

  // function createFormattedDateString(
  //   date: string,
  //   time: string,
  //   tz: string,
  //   targetTz: string,
  // ) {
  //   // Combine the date and time, and parse it using dayjs with the custom format
  //   const combinedDateTime = `${date} ${time}`;
  //   // Convert the datetime to the specified timezone
  //   const dateTimeInTz = dayjs.tz(combinedDateTime, 'YYYY-MM-DD hh:mm A', tz);
  //   const dateTimeInTargetTz = dateTimeInTz.tz(targetTz);
  //   return dateTimeInTargetTz;
  // }

  function createFormattedDateString(date: string, time: string) {
    // Combine the date and time, and parse it using dayjs with the custom format
    const combinedDateTime = `${date} ${time}`;
    // Convert the datetime to the specified timezone
    const dateTimeInTz = dayjs.tz(
      combinedDateTime,
      'YYYY-MM-DD hh:mm A',
      userTimeZone,
    );
    return dateTimeInTz;
  }

  const processAppointments = (
    formattedNow: string,
    appointments: FormattedAppointmentType[],
  ): FormattedAppointmentType[] => {
    if (!appointments?.length) {
      return [];
    }

    const sortedAppointments = [...appointments].sort((a, b) => {
      const timeA = createFormattedDateString(formattedNow, a.startTime);
      const timeB = createFormattedDateString(formattedNow, b.startTime);
      if (timeA.isBefore(timeB)) return -1;
      if (timeA.isAfter(timeB)) return 1;
      return 0;
    });

    const now = dayjs().tz(userTimeZone);

    const filteredAppointments = sortedAppointments
      .filter(appointment => {
        const startTime = createFormattedDateString(
          formattedNow,
          appointment.startTime,
        );

        const endTime = startTime.add(Number(appointment.duration), 'minutes');

        return endTime.isAfter(now);
      })
      .filter(x => x.canceled === false);

    return filteredAppointments;
  };

  const onSetAppointments = () => {
    const appts = formattedAppts[formattedNow] || [];

    if (Array.isArray(appts) && appts.length > 0) {
      const updatedAppts = processAppointments(formattedNow, appts);
      setAppointments(updatedAppts);
    }
  };

  useEffect(() => {
    let intervalRef: NodeJS.Timer;

    onSetAppointments();

    if (calendarLoadingState === SliceStatus.resolved) {
      intervalRef = setInterval(() => onSetAppointments(), 10000);
    }
    return () => {
      clearInterval(intervalRef);
    };
  }, [calendarLoadingState]);

  return {
    upcomingAppointments,
    calendarLoadingState,
    ...rest,
  };
}
