import React, {FC, useState} from 'react';
import {Button} from 'components';
import Modal from 'components/Basic/Modal';
import {ReleaseNotesCompType, ReleaseNotesType, SliceStatus} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {userActions} from '../userSlice';

const typeDetails = {
  major: {
    label: 'FEATURE',
    colors: 'bg-green-600',
  },
  minor: {
    label: 'CHANGED',
    colors: 'bg-yellow-500',
  },
  patch: {
    label: 'BUG FIX',
    colors: 'bg-red-600',
  },
};

export const ShowReleaseNotes: FC<ReleaseNotesCompType> = ({listData}) => {
  const {t} = useTranslation();
  const {label, colors} = typeDetails[listData[0].type];

  return (
    <div>
      <div
        className={`${colors} rounded-full w-fit px-5 py-1 text-white text-sm uppercase tracking-wider font-medium mb-5`}
      >
        {label}
      </div>
      <p className="text-2xl md:text-4xl font-semibold">
        {t('releaseNote.versionRelease', {
          version: `${listData[0].version}`,
        })}
      </p>
      <p className="text-gray-600 my-5">
        {t('releaseNote.constantlyImproving')}
      </p>
      <div className="mt-5">
        {listData.map(item => (
          <div key={item.code}>
            <div dangerouslySetInnerHTML={{__html: item.content}} />
          </div>
        ))}
      </div>
    </div>
  );
};

const ReleaseNotesInfo: FC<{
  releaseNotes: {
    status: SliceStatus;
    data: ReleaseNotesType;
  };
}> = ({releaseNotes}) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const onOpenModal = () => setShowModal(true);
  const onCloseBanner = () => {
    if (releaseNotes.data[0]?.code)
      dispatch(
        userActions.updateReleaseNotes({
          releaseCode: releaseNotes.data[0].code,
        }),
      );
  };

  return (
    <>
      <section className="w-full h-24 z-999 flex flex-col justify-center fixed bottom-16 lg:bottom-0">
        <div className="w-11/12 h-16 mx-auto flex items-center justify-between flex-wrap bg-white border px-4 rounded-xl shadow-2xl">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-blue-600">
              <svg
                className="w-4 h-4 md:w-5 md:h-5 text-white"
                data-todo-x-description="Heroicon name: speakerphone"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                />
              </svg>
            </span>
            <p className="ml-3 font-normal text-gray-600 truncate">
              <span className="md:hidden">
                {t('releaseNote.weReleaseanUpdate')}
              </span>
              <span className="hidden md:inline">
                {t('releaseNote.newVersionAvailable')}
              </span>
            </p>
          </div>
          <div className="flex items-center space-x-3">
            <Button
              onClick={onOpenModal}
              className="flex items-center justify-center px-4 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium"
            >
              {t('releaseNote.view')}
            </Button>

            <button
              data-testid="close-banner"
              type="button"
              onClick={onCloseBanner}
              className="-mr-1 flex px-2 py-1 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-gray-300"
            >
              <span className="sr-only">{t('releaseNote.dismiss')}</span>
              <svg
                className="h-5 w-5 text-gray-600"
                data-todo-x-description="Heroicon name: x"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>
      {showModal ? (
        <Modal
          modalType="release_notes"
          messageType="none"
          isOpen={showModal}
          showCloseIcon
          buttonFn={() => {
            setShowModal(false);
          }}
        >
          <ShowReleaseNotes listData={releaseNotes.data} />
        </Modal>
      ) : null}
    </>
  );
};

export default ReleaseNotesInfo;
