/* eslint-disable @typescript-eslint/ban-ts-comment */
import Toast from 'components/Basic/Toast';
import {routerMiddleware} from 'connected-react-router';
import {userActions} from 'features/User';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import logger from 'redux-logger';
import {createEpicMiddleware} from 'redux-observable';
import {ReplaySubject} from 'rxjs';
import {FirebaseService, IndexedDBService} from 'services';
import {history} from 'utils';

import {
  Action,
  configureStore,
  Middleware,
  ThunkAction,
} from '@reduxjs/toolkit';

import ActionsListener from './actionsListener';
import {rootLogoutState} from './initStore';
import rootEpic from './rootEpic';
import rootReducer, {AppActions, RootState} from './rootReducer';

const epicMiddleware = createEpicMiddleware<
  AppActions,
  AppActions,
  RootState,
  {firebase: ReplaySubject<firebase.app.App>}
>({
  dependencies: {firebase: FirebaseService.app},
});

const appReducer = (
  state: RootState | undefined,
  action: AppActions,
): RootState => {
  if (action.type === 'ResetUser') {
    state = {
      ...rootLogoutState,
      user: {
        ...rootLogoutState.user,
        isCacheAvailable: state?.user?.isCacheAvailable || false,
      },
      messaging: {
        ...rootLogoutState.messaging,
        // timestamp: state?.messaging?.timestamp || {},
        // serverTimeOffset: state?.messaging?.serverTimeOffset || 0,
      },
    };
  }

  return rootReducer(state, action);
};

const EXPIRED_SESSION_ERRORS = [
  'Something does not seem right, reload your browser and login back',
  'Please reload this page and resend the request again',
  'Try reloading your browser to relogin',
  'Try reloading this page and resend the request after that',
  'Please reload this page and resend the request again',
  'Session has expired',
];

const errorHandlerMiddleware: Middleware = store => next => action => {
  if (
    action &&
    action.payload &&
    typeof action.payload === 'string' &&
    EXPIRED_SESSION_ERRORS.includes(action.payload)
  ) {
    Toast({
      type: 'warning',
      message: 'Your session has expired. Please sign in again.',
    });
    store.dispatch(userActions.resetUser(null));
    return;
  }

  return next(action);
};

export const store =
  process.env.NODE_ENV === 'development'
    ? configureStore({
        reducer: IndexedDBService.OuterReducer(appReducer),
        middleware: getDefaultMiddleware =>
          getDefaultMiddleware({
            serializableCheck: false,
          })
            .prepend(ActionsListener.middleware)
            .concat(IndexedDBService.Middleware())
            .concat(epicMiddleware)
            .concat(routerMiddleware(history))
            .concat(logger)
            .concat(errorHandlerMiddleware),
      })
    : configureStore({
        reducer: IndexedDBService.OuterReducer(appReducer),
        middleware: getDefaultMiddleware =>
          getDefaultMiddleware({
            serializableCheck: false,
          })
            .prepend(ActionsListener.middleware)
            .concat(IndexedDBService.Middleware())
            .concat(epicMiddleware)
            .concat(routerMiddleware(history))
            .concat(errorHandlerMiddleware),
      });

epicMiddleware.run(rootEpic);
// @ts-ignore
if (process.env.NODE_ENV === 'development' && module.hot) {
  // @ts-ignore
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export const dispatch: AppDispatch = store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppStore = typeof store;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;
