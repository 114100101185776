import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  AvailabilityData,
  AvailabilityDay,
  AvailabilityInString,
  AvailabilityInTimeRange,
  RawAvailabilityData,
  SpecificDayAvailability,
  Time,
  TimeRange,
} from 'interfaces';

dayjs.extend(isToday);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

function splitTime(timeStr: string) {
  const [time, period] = timeStr.split(/(am|pm)/i);
  const [hour, minute] = time.split(':').map(Number);
  return {
    hour,
    minute,
    period: period.toUpperCase(),
  };
}

function deserializeTimeRange(timeRangeStr: string) {
  const [from, to] = timeRangeStr.split('-');
  if (!to) return [];
  return [
    {
      from: splitTime(from),
      to: splitTime(to),
    },
  ];
}

// Map the data from acuity to the format we want
export function deserializeAcuityData(
  input: AvailabilityInString,
): AvailabilityInTimeRange {
  const data = {};

  for (const [weekday, timeRanges] of Object.entries(input)) {
    data[weekday] = timeRanges.flatMap(deserializeTimeRange);
  }

  return data as AvailabilityInTimeRange;
}

export function deserializeGetAvailabilityData(
  input: RawAvailabilityData,
): AvailabilityData {
  const data = {
    recurring: {} as Record<AvailabilityDay, TimeRange[]>,
    specificDay: [] as SpecificDayAvailability<
      Record<AvailabilityDay, TimeRange[]>
    >[],
    blocked: input.blocked,
  };

  for (const [weekday, timeRanges] of Object.entries(input.recurring)) {
    data.recurring[weekday] = timeRanges.flatMap(deserializeTimeRange);
  }

  input.specificDay.forEach(av => {
    const hours: Partial<Record<AvailabilityDay, TimeRange[]>> = {};
    for (const [weekday, timeRanges] of Object.entries(av.hours)) {
      hours[weekday] = timeRanges.flatMap(deserializeTimeRange);
    }
    data.specificDay.push({...av, hours: hours as any});
  });

  return data;
}

export function serializeAcuityData(
  data: AvailabilityInTimeRange,
): AvailabilityInString {
  // Map to acuity types
  const updateData: Partial<AvailabilityInString> = {};

  function joinTime(time: Time) {
    return `${time.hour}:${time.minute
      .toString()
      .padStart(2, '0')}${time.period.toLowerCase()}`;
  }

  for (const [weekday, timeRanges] of Object.entries(data)) {
    // @ts-ignore
    updateData[weekday] = timeRanges.map(range => {
      return [joinTime(range.from), joinTime(range.to)].join('-');
    });
  }

  return updateData as AvailabilityInString;
}

export const formatTimeSlotsOptions = (slots: string[]) => {
  return slots.map(slot => {
    const [time] = slot.split('-');
    return time.slice(0, -3);
  });
};

export const generateTimeArray = (limit: number, prefix = '') =>
  new Array(limit)
    .fill(0)
    .map((_, index) => (index < 10 ? `${prefix}${index}` : String(index)));

export const Weekdays: readonly string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const generateDateWithWeekDayTimeRange = (
  day: string = 'Sunday',
  initialTime: Time = {hour: 9, minute: 0, period: 'AM'},
): Date => {
  const currentDate = new Date();

  const {hour, minute, period} = initialTime;
  const currentDayIndex = currentDate.getDay();
  const specifiedDayIndex = Weekdays.indexOf(day);

  currentDate.setDate(
    currentDate.getDate() + ((specifiedDayIndex + 7 - currentDayIndex) % 7),
  );
  currentDate.setHours((hour % 12) + (period === 'PM' ? 12 : 0), minute, 0, 0);

  return currentDate;
};

// date format: YYYY-MM-DD
export const getDateWithoutTime = (dateString: string): Date => {
  const split = dateString.split('-');
  return new Date(Number(split[0]), Number(split[1]) - 1, Number(split[2]));
};

export const isDayToday = (date: dayjs.Dayjs | null | undefined): boolean => {
  return date?.isToday() || false;
};

export const convertToDayjs = (timeStr: string, timeZone: string) =>
  dayjs.tz(timeStr, 'h:mm A', timeZone);
