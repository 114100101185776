import {
  CurrentUser,
  MemberProfile,
  StripePlanDurations,
  StripePlanNames,
  StripePlanTypes,
} from 'interfaces';
import {isMember} from 'utils';

export const getMonthlyAndTotalPlanPrice = (
  planType: StripePlanTypes,
  planDuration: StripePlanDurations,
  //firstMonthDiscount?: boolean,
): {
  total: string;
} => {
  switch (planDuration) {
    case StripePlanDurations.weekly:
      return {total: '0'};
    case StripePlanDurations.biweekly:
      return [StripePlanTypes.uninsured, StripePlanTypes.medicalcare].includes(
        planType,
      )
        ? {total: '95.00'}
        : {total: '95.00'};
    case StripePlanDurations.monthly:
      return [
        StripePlanTypes.mindfulness,
        StripePlanTypes.medicalcare_plus_therapy,
      ].includes(planType)
        ? {total: '245.00'}
        : {total: '450.00'};
    case StripePlanDurations.biannually:
      return [
        StripePlanTypes.mindfulness,
        StripePlanTypes.medicalcare_plus_therapy,
      ].includes(planType)
        ? {total: '196.00'}
        : [StripePlanTypes.uninsured, StripePlanTypes.medicalcare].includes(
            planType,
          )
        ? {total: '76.00'}
        : {total: '360.00'};
    case StripePlanDurations.annually:
      return [
        StripePlanTypes.mindfulness,
        StripePlanTypes.medicalcare_plus_therapy,
      ].includes(planType)
        ? {total: '159.25'}
        : [StripePlanTypes.uninsured, StripePlanTypes.medicalcare].includes(
            planType,
          )
        ? {total: '61.75'}
        : {total: '292.50'};
    default:
      return {total: '0'};
  }
};

export const getPlanPriceMeta = (planDuration: StripePlanDurations): string => {
  switch (planDuration) {
    case StripePlanDurations.biannually:
      return '6 months commitment. FSA/HSA accepted.';
    case StripePlanDurations.annually:
      return '1 year commitment. FSA/HSA accepted.';
    default:
      return 'FSA/HSA accepted.';
  }
};

export const getChargeDurationMeta = (
  planDuration: StripePlanDurations,
): string => {
  switch (planDuration) {
    case StripePlanDurations.weekly:
      return '';
    case StripePlanDurations.biweekly:
      return 'Charged bi-weekly.';
    case StripePlanDurations.monthly:
      return 'Charged monthly.';
    case StripePlanDurations.biannually:
      return 'Charged upfront every 6 months.';
    case StripePlanDurations.annually:
      return 'Charged upfront yearly.';
    default:
      return '';
  }
};

// eslint-disable-next-line complexity
export const getPlanPageName = (user: CurrentUser): string => {
  if (!isMember(user)) return '';
  let myPlanName = '';
  switch (user.paymentPlan) {
    case StripePlanNames.basic:
    case StripePlanNames.basicMarketing:
    case StripePlanNames.uninsured:
    case StripePlanNames.uninsuredBiweekly:
    case StripePlanNames.uninsuredAnnually:
    case StripePlanNames.uninsuredBiannually:
      myPlanName = 'uninsured';
      break;
    case StripePlanNames.medicalCareBiweekly:
    case StripePlanNames.medicalCareBiannually:
    case StripePlanNames.medicalCareAnnually:
      myPlanName = 'medicalcare';
      break;

    case StripePlanNames.mindfulness:
    case StripePlanNames.mindfulnessAnnually:
    case StripePlanNames.mindfulnessBiannually:
    case StripePlanNames.mindfulnessMarketing:
      myPlanName = 'mindfulness';
      break;

    case StripePlanNames.medicalCarePlusTherapy:
    case StripePlanNames.medicalCarePlusTherapyBiannually:
    case StripePlanNames.medicalCarePlusTherapyAnnually:
      myPlanName = 'medicalcare_plus_therapy';
      break;

    case StripePlanNames.therapy:
    case StripePlanNames.therapyBiannually:
    case StripePlanNames.therapyAnnually:
      myPlanName = 'therapy';
      break;
    case StripePlanNames.newTherapy:
    case StripePlanNames.newTherapyBiannually:
    case StripePlanNames.newTherapyAnnually:
      myPlanName = 'new_therapy';
      break;
    case StripePlanNames.insured:
    case StripePlanNames.insuredBiannually:
    case StripePlanNames.insuredAnnually:
      myPlanName = 'insured';
      break;
    case StripePlanNames.curandero:
      myPlanName = 'curandero';
      break;
    case StripePlanNames.together:
    case StripePlanNames.togetherWeekly:
      myPlanName = 'together';
      break;
    case StripePlanNames.privateInsurance:
      myPlanName = 'private insurance';
      break;
    case StripePlanNames.medicaidMedicare:
      myPlanName = 'medicaid';
      break;
    case StripePlanNames.basicOld:
      myPlanName = 'old basic';
      break;
    default:
      break;
  }
  return myPlanName;
};

const routeWithQueryData = (pathname: string, paymentPlan: string) => {
  if (paymentPlan.includes(StripePlanDurations.annually))
    return {
      pathname,
      search: `?duration=${StripePlanDurations.annually}`,
    };
  else if (paymentPlan.includes(StripePlanDurations.biannually))
    return {
      pathname,
      search: `?duration=${StripePlanDurations.biannually}`,
    };
  else if (paymentPlan.includes(StripePlanDurations.biweekly))
    return {
      pathname,
      search: `?duration=${StripePlanDurations.biweekly}`,
    };
  else if (paymentPlan.includes(StripePlanDurations.weekly))
    return {
      pathname,
      search: `?duration=${StripePlanDurations.weekly}`,
    };
  else
    return {
      pathname,
      search: `?duration=${StripePlanDurations.monthly}`,
    };
};

export const getPaymentOrUpdatePlanRoute = (
  user: MemberProfile,
): string | {pathname: string; search: string} => {
  switch (user.paymentPlan) {
    case StripePlanNames.basic:
    case StripePlanNames.uninsured:
    case StripePlanNames.uninsuredBiweekly:
    case StripePlanNames.uninsuredAnnually:
    case StripePlanNames.uninsuredBiannually:
      return user.verified
        ? '/resubscribe-plan'
        : routeWithQueryData('/uninsured/register/stripe', user.paymentPlan);

    case StripePlanNames.medicalCareBiweekly:
    case StripePlanNames.medicalCareBiannually:
    case StripePlanNames.medicalCareAnnually:
      return user.verified
        ? '/resubscribe-plan'
        : routeWithQueryData('/medicalcare/register/stripe', user.paymentPlan);

    case StripePlanNames.mindfulness:
    case StripePlanNames.mindfulnessAnnually:
    case StripePlanNames.mindfulnessBiannually:
      return user.verified
        ? '/resubscribe-plan'
        : routeWithQueryData('/mindfulness/register/stripe', user.paymentPlan);

    case StripePlanNames.medicalCarePlusTherapy:
    case StripePlanNames.medicalCarePlusTherapyBiannually:
    case StripePlanNames.medicalCarePlusTherapyAnnually:
      return user.verified
        ? '/resubscribe-plan'
        : routeWithQueryData(
            '/medicalcare_plus_therapy/register/stripe',
            user.paymentPlan,
          );

    case StripePlanNames.therapy:
    case StripePlanNames.therapyBiannually:
    case StripePlanNames.therapyAnnually:
      return user.verified
        ? '/resubscribe-plan'
        : routeWithQueryData('/therapy/register/stripe', user.paymentPlan);

    case StripePlanNames.newTherapy:
    case StripePlanNames.newTherapyBiannually:
    case StripePlanNames.newTherapyAnnually:
      return user.verified
        ? '/resubscribe-plan'
        : routeWithQueryData('/new_therapy/register/stripe', user.paymentPlan);

    case StripePlanNames.together:
    case StripePlanNames.togetherWeekly:
      return user.verified
        ? '/resubscribe-plan'
        : routeWithQueryData('/together/register/stripe', user.paymentPlan);

    default:
      return '/resubscribe-plan';
  }
};

export const getPlanNameFromLocation = (pathname: string) => {
  return pathname?.includes(StripePlanTypes.medicalcare_plus_therapy)
    ? StripePlanTypes.medicalcare_plus_therapy
    : pathname?.includes(StripePlanTypes.medicalcare)
    ? StripePlanTypes.medicalcare
    : pathname?.includes(StripePlanTypes.new_therapy)
    ? StripePlanTypes.new_therapy
    : pathname?.includes('mindfulness')
    ? StripePlanTypes.mindfulness
    : pathname?.includes('uninsured')
    ? StripePlanTypes.uninsured
    : pathname?.includes('therapy')
    ? StripePlanTypes.therapy
    : StripePlanTypes.together;
};
