import {isToday} from 'date-fns';
import * as Yup from 'yup';

export const BookAppointmentSchema = Yup.object()
  .shape({
    appointmentType: Yup.string().required('Required'),
    providerId: Yup.string().required('Required'),
    timezone: Yup.string().required('Required'),
    date: Yup.date().required('Required'),
    time: Yup.string().required('Required'),
    client: Yup.string(),
    state: Yup.string(),
  })
  .required();

export const BookGroupAppointmentSchema = Yup.object()
  .shape({
    date: Yup.date()
      .required('Date is required')
      .test('not-today', 'Date should not be today', function (value) {
        // Check if the date is not today's date
        return !isToday(value!);
      }),
    time: Yup.string()
      .required('Required')
      .matches(
        /^([1-9]|1[0-2]):([0-5]\d)([ap]m)$/i,
        'Invalid time format, should be hh:mmAM/PM',
      ),
    title: Yup.string()
      .required('Required')
      .max(100, 'Title must be at most 100 characters'),
  })
  .required();

export const RescheduleAppointmentSchema = Yup.object()
  .shape({
    appointmentType: Yup.string().required('Required'),
    timezone: Yup.string().required('Required'),
    date: Yup.date().required('Required'),
    time: Yup.string().required('Required'),
    client: Yup.string(),
  })
  .required();
