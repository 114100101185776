import React, {FC, lazy, useState} from 'react';
import ModalCheck from 'assets/images/modal-check.png';
import {AccountIcon, Button, CloseIcon, LoadingSpinner} from 'components';
import ChargeModal from 'features/Appointment/Charge/ChargeModal';
import {ChargeStatus} from 'interfaces/Meeting.types';
import {useTranslation} from 'react-i18next';
import {
  componentLoader,
  isDigitalPracticeOutOfPocketMember,
  isMember,
  isProvider,
  isTherapist,
  showPayLaterOptions,
  titleCaseName,
} from 'utils';

import PaymentRequestModal from '../Charge/PaymentRequestModal';

import {CalendarItemProps, currencies, labelType} from './CalendarItemProps';

import 'react-day-picker/lib/style.css';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

export const CalendarItemCardV1: FC<CalendarItemProps> = ({
  startTime,
  duration,
  patientFirstName,
  patientId,
  user,
  patientLastName,
  calendar,
  appointmentID,
  patientDetails,
  canceled,
  participants,
  notes,
  localTime,
  ...props
}) => {
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [skip, setSkip] = useState(false);

  const {t} = useTranslation();

  return (
    <section data-testid="calendar-item" className="grid grid-cols-3 mb-4">
      <div className="font-medium text-gray-700 leading-snug text-xs px-6 md:px-8 border-r-2 border-blue-600 flex flex-col justify-between py-2">
        <p>{startTime}</p>
        {props.shouldShowCancelButton && !canceled ? (
          <button
            onClick={props.handleCancel}
            className="text-red-500 font-bold flex items-center justify-center"
          >
            <div>
              <CloseIcon
                classes="inline-block"
                strokeColor="text-red-500"
                strokeWidth={40}
              />
            </div>
            <span>{t('cancel')}</span>
          </button>
        ) : null}
      </div>
      <div className="col-span-2 text-left pl-5 md:pl-6 font-medium text-gray-700 leading-snug text-xs md:text-sm">
        {props.isGroupCall && isMember(user) ? (
          <>
            <p className="pb-1 cursor-pointer">
              {t('groupSessionWith')} {calendar}
            </p>
            <p className="pb-3 font-light">{notes}</p>
          </>
        ) : props.isGroupCall && isTherapist(user) ? (
          <>
            <p
              className="pb-1 cursor-pointer"
              role="button"
              onClick={() => setShowParticipantsModal(true)}
            >
              {t('groupSessionWith')}&nbsp;
              <span className="text-blue-600 hover:underline">
                {participants?.length || 0} {t('participants')}
              </span>
            </p>
            <p className="pb-3 font-light">{notes}</p>
          </>
        ) : (
          <p className="pb-3">
            {t('appointmentWith')}&nbsp;
            {titleCaseName(
              isProvider(user)
                ? `${patientFirstName} ${patientLastName}`
                : calendar,
            )}
          </p>
        )}

        {isProvider(user) ? (
          <div className="pb-3">
            <p>
              {/* <Trans i18nKey="sessionDuration" values={{duration: dur}}>
                Session Duration: {{duration}} minutes
              </Trans> */}
              {t('sessionDuration', {duration: duration})}
            </p>
          </div>
        ) : null}
        <section>
          {isProvider(user) && !patientId ? (
            <span className="text-red-600 mb-3">
              {t('Patient Account Registration Pending')}
            </span>
          ) : canceled && isMember(user) ? (
            <p className="text-red-500 font-bold ">{t('canceled')}</p>
          ) : (
            <div className="max-w-[230px] grid grid-cols-2 gap-x-2">
              {props.isGroupCall && isMember(user) ? (
                <Button
                  type="button"
                  borderRadius="full"
                  borderColor="transparent"
                  fontSize="xs"
                  className="w-full py-2 flex justify-center items-center"
                  onClick={props.updateCallInterest}
                  disabled={
                    props.isJoinedInGroupCall || props.loadingState.interest
                  }
                >
                  {props.loadingState.interest ? (
                    <LoadingSpinner
                      color="#999"
                      type="Oval"
                      width={12}
                      height={12}
                    />
                  ) : props.isInterestedInGroupCall ? (
                    t('notInterested')
                  ) : (
                    t('interested')
                  )}
                </Button>
              ) : null}

              <Button
                type="button"
                borderRadius="full"
                borderColor="transparent"
                fontSize="xs"
                className="w-full py-2 flex justify-center items-center"
                data-testid="join"
                disabled={
                  props.isGroupCallNotAllowed || props.loadingState.joining
                }
                onClick={props.onJoinClick}
                tooltipContent={
                  props.isGroupCallNotAllowed ? t('submitInterest') : ''
                }
              >
                {props.loadingState.joining ? (
                  <LoadingSpinner
                    color="#999"
                    type="Oval"
                    width={12}
                    height={12}
                  />
                ) : (
                  t('joinCall').toUpperCase()
                )}
              </Button>

              {/* adding the set price button */}
              {isTherapist(user) &&
              isDigitalPracticeOutOfPocketMember(patientDetails) ? (
                props.price && props.chargeStatus !== ChargeStatus.charged ? (
                  <Button
                    type="button"
                    borderRadius="full"
                    outline
                    btnType="custom"
                    borderColor="lime-600"
                    bgColor="white"
                    fontSize="xs"
                    className="w-full py-2 bg-white text-lime-600 hover:bg-lime-600 hover:text-white border-lime-600"
                    onClick={props.onShowChargeModal}
                    data-testid="set-price"
                  >
                    {t('charge').toUpperCase()}
                  </Button>
                ) : props.price &&
                  props.chargeStatus === ChargeStatus.charged ? (
                  <div className="w-10 h-10 relative">
                    <img
                      src={ModalCheck}
                      alt="charge modal check"
                      width="30"
                      height="30"
                    />
                  </div>
                ) : (
                  <Button
                    type="button"
                    borderRadius="full"
                    borderColor="blue-600"
                    bgColor="white"
                    fontSize="xs"
                    outline
                    className="w-full py-2 bg-white text-blue-600 hover:bg-blue-600 hover:text-white"
                    data-testid="charge"
                    onClick={props.onShowChargeModal}
                  >
                    {t('setPrice').toUpperCase()}
                  </Button>
                )
              ) : null}
            </div>
          )}
        </section>

        {/* modals  */}
        <ChargeModal
          user={user}
          currencies={currencies}
          price={Number(props.price)}
          showChargeModal={props.showChargeModal}
          setShowChargeModal={props.setShowChargeModal}
          appointmentID={appointmentID.toString()}
          onChargeClick={props.onChargeClick}
          charged={props.chargeStatus}
          isLoading={props.isLoading}
          setSkip={setSkip}
          skip={skip}
          setOnCallPricingSkip={props.setOnCallPricingSkip}
          setShowSuccessModal={props.setShowSuccessModal}
          showSuccessModal={props.showSuccessModal}
          patientId={patientId || ''}
          localTime={localTime}
        />
        <PaymentRequestModal
          user={user}
          currencies={currencies}
          price={Number(props.price)}
          showPaymentRequestModal={props.showPaymentRequestModal}
          setShowPaymentRequestModal={props.setShowPaymentRequestModal}
          appointmentID={appointmentID.toString()}
          charged={props.chargeStatus}
          duration={duration}
          isLoading={props.isLoading}
          setShowSuccessModal={props.setShowSuccessPaymentModal}
          showSuccessModal={props.showSuccessPaymentModal}
          patientId={patientId || ''}
          localTime={localTime}
          videoLink={props.videoLink}
          {...(localTime && duration && props.meeting?.appointmentCharge
            ? {
                showPayLater: showPayLaterOptions(
                  localTime.toISOString(),
                  duration!,
                ),
              }
            : {})}
        />

        {props.isGroupCall &&
        isTherapist(user) &&
        Array.isArray(participants) ? (
          <Modal
            title={t('groupAppointmentParticipants')}
            isOpen={showParticipantsModal}
            buttonFn={() => setShowParticipantsModal(false)}
            messageType="none"
            showCloseIcon
          >
            {participants.map((participant: any, idx: number) => (
              <div key={+idx} className="w-full flex justify-between py-4">
                <div className="flex items-center space-x-3">
                  <AccountIcon />
                  <p className="text-lg">
                    {participant.firstName} {participant.lastName}
                  </p>
                </div>
                {participant.labels === null ? (
                  <p className="text-gray-600">N/A</p>
                ) : (
                  labelType(t)[participant.labels[0]?.name]
                )}
              </div>
            ))}
          </Modal>
        ) : null}

        {props.showApptError.show ? (
          <Modal
            title="time warning"
            messageType="warning"
            isOpen={props.showApptError.show}
            buttonFn={props.onCloseApptTooEarlyModal}
            showCloseIcon
          >
            <div className="flex flex-col items-center justify-center space-y-10 max-w-lg mx-auto mb-8">
              <div
                className="px-5 text-center text-xl"
                dangerouslySetInnerHTML={{__html: props.showApptError.message}}
              />
              <Button
                btnType="primary"
                borderRadius="full"
                className="w-full max-w-[250px] mx-auto py-3 leading-snug"
                onClick={props.onCloseApptTooEarlyModal}
              >
                {t('close').toUpperCase()}
              </Button>
            </div>
          </Modal>
        ) : null}
      </div>
    </section>
  );
};
