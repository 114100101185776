import React, {FC} from 'react';
import {Controller, useForm} from 'react-hook-form';
import StarPicker from 'react-star-picker';
import * as Yup from 'yup';

import {yupResolver} from '@hookform/resolvers/yup';

type props = {
  onSubmit: (formData: {rating: {audioVideo: number}}) => void;
  store: {
    rating: {audioVideo: number; provider?: number};
  };
};

const schema = Yup.object()
  .shape({
    rating: Yup.object().shape({
      AudioVideo: Yup.number(),
    }),
  })
  .required();

const RateAudioVideo: FC<props> = ({store, onSubmit}) => {
  const {handleSubmit, control} = useForm({
    defaultValues: store,
    resolver: yupResolver(schema),
  });

  return (
    <form id="rating-form" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="px-4 font-inter text-base font-semibold leading-6 text-black text-center">
        How was your audio and video experience?
      </h3>
      <div className="w-full text-center">
        <Controller
          control={control}
          name="rating.audioVideo"
          rules={{
            validate: rating => rating > 0,
          }}
          render={({field: {value, onChange}}) => (
            <StarPicker
              className="star-picker"
              value={value}
              onChange={onChange}
              halfStars={true}
              size={36}
            />
          )}
        />
      </div>
    </form>
  );
};

export default RateAudioVideo;
