import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {digitalPracticeActions} from 'features/Provider/DigitalPractice/digitalPracticeSlice';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {StripeService} from 'services';
import {isUserFromUnitedStates} from 'utils';

import {PaypalService} from '../../../services/api';
import {appointmentActions} from '../appointmentSlice';

const setAppointmentPriceFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.setAppointmentPriceFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message: message.message});
      }
    }),
    ignoreElements(),
  );

const setAppointmentPriceEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.setAppointmentPrice.match),
    switchMap(({payload: {callback, closeModal, ...rest}}) =>
      from(StripeService.setAppointmentPrice(rest)).pipe(
        concatMap(({data: message}) => {
          callback();
          return of(
            appointmentActions.setAppointmentPriceSuccess(message),
            userActions.setNotification({
              message: i18next.t(
                'setChargeStatus.setSessionsPriceSuccess',
                "You have successfully set this session's price",
              ),
              messageType: 'success',
            }),
          );
        }),
        catchError((message: string) => {
          closeModal();
          return concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(
              appointmentActions.setAppointmentPriceFailure({
                message: i18next.t(
                  'setChargeStatus.setSessionsPriceError',
                  'An error occured while setting the price',
                ),
              }),
            ),
          );
        }),
      ),
    ),
  );

const chargeDigitalPracticeAppointmentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.chargeDigitalPracticeAppointmentFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message: message.message});
      }
    }),
    ignoreElements(),
  );

const chargeDigitalPracticeAppointmentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(appointmentActions.chargeDigitalPracticeAppointment.match),
    withLatestFrom(state$),

    switchMap(
      ([
        {
          payload: {
            callback,
            closeModal,
            isDigitalPracticeOutOfPocketMember,
            ...rest
          },
        },
        currentState,
      ]) =>
        from(StripeService.chargeDigitalPracticeAppointment(rest)).pipe(
          concatMap(({data: message}) => {
            callback();
            const isUserFromUS = isUserFromUnitedStates(
              currentState?.user?.current,
            );
            return of(
              appointmentActions.chargeDigitalPracticeAppointmentSuccess(
                message,
              ),
              digitalPracticeActions.getWellbitsData({role: rest.providerType}),
              userActions.setNotification({
                message:
                  message?.message?.appointmentCharge >= 50 &&
                  isUserFromUS &&
                  !isDigitalPracticeOutOfPocketMember
                    ? i18next.t(
                        'setChargeStatus.chargedSessionAndWellbitRewardedSuccess',
                      )
                    : isDigitalPracticeOutOfPocketMember
                    ? i18next.t('chargeAppointments.outOfPocketChargeSuccess')
                    : i18next.t('setChargeStatus.chargedSessionSuccess'),
                messageType: 'success',
              }),
            );
          }),
          catchError((message: string) => {
            closeModal(); // Close the modal on error
            return concat(
              of(userActions.setAsyncError({filter: 'provider', message})),
              of(
                appointmentActions.chargeDigitalPracticeAppointmentFailure({
                  message:
                    message || i18next.t('setChargeStatus.sessionChargeError'),
                }),
              ),
            );
          }),
        ),
    ),
  );

const payLaterRequestEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(appointmentActions.payLaterRequest.match),
    withLatestFrom(state$),

    switchMap(
      ([
        {
          payload: {callback, closeModal, ...rest},
        },
      ]) =>
        from(PaypalService.executePayLaterRequest(rest)).pipe(
          concatMap(({data: message}) => {
            callback();
            return of(appointmentActions.payLaterRequestSuccess(message));
          }),
          catchError((message: string) => {
            closeModal(); // Close the modal on error
            return concat(
              of(userActions.setAsyncError({filter: 'provider', message})),
              of(
                appointmentActions.payLaterRequestFailure({
                  message:
                    message || i18next.t('chargeAppointments.payLaterError'),
                }),
              ),
            );
          }),
        ),
    ),
  );

const payLaterRequestFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.payLaterRequestFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message: message.message});
      }
    }),
    ignoreElements(),
  );

export const chargeEpics = [
  setAppointmentPriceEpic,
  setAppointmentPriceFailureEpic,
  chargeDigitalPracticeAppointmentEpic,
  chargeDigitalPracticeAppointmentFailureEpic,
  payLaterRequestEpic,
  payLaterRequestFailureEpic,
];
