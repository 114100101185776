import React from 'react';
import {LogoWhite} from 'components';
import {Link} from 'react-router-dom';

const stateLinks = [
  {name: 'Alabama', code: 'AL'},
  {name: 'Alaska', code: 'AK'},
  {name: 'Arizona', code: 'AZ'},
  {name: 'Arkansas', code: 'AR'},
  {name: 'California', code: 'CA'},
  {name: 'Colorado', code: 'CO'},
  {name: 'Connecticut', code: 'CT'},
  {name: 'Delaware', code: 'DE'},
  {name: 'District of Columbia', code: 'DC'},
  {name: 'Florida', code: 'FL'},
  {name: 'Georgia', code: 'GA'},
  {name: 'Hawaii', code: 'HI'},
  {name: 'Illinois', code: 'IL'},
  {name: 'Indiana', code: 'IN'},
  {name: 'Iowa', code: 'IA'},
  {name: 'Kansas', code: 'KS'},
  {name: 'Kentucky', code: 'KY'},
  {name: 'Louisiana', code: 'LA'},
  {name: 'Maine', code: 'ME'},
  {name: 'Maryland', code: 'MD'},
  {name: 'Massachusetts', code: 'MA'},
  {name: 'Michigan', code: 'MI'},
  {name: 'Minnesota', code: 'MN'},
  {name: 'Mississippi', code: 'MS'},
  {name: 'Missouri', code: 'MO'},
  {name: 'Montana', code: 'MT'},
  {name: 'Nevada', code: 'NV'},
  {name: 'New Hampshire', code: 'NH'},
  {name: 'New Jersey', code: 'NJ'},
  {name: 'New Mexico', code: 'NM'},
  {name: 'New York', code: 'NY'},
  {name: 'North Carolina', code: 'NC'},
  {name: 'Ohio', code: 'OH'},
  {name: 'Oklahoma', code: 'OK'},
  {name: 'Oregon', code: 'OR'},
  {name: 'Pennsylvania', code: 'PA'},
  {name: 'Rhode Island', code: 'RI'},
  {name: 'South Carolina', code: 'SC'},
  {name: 'Tennessee', code: 'TN'},
  {name: 'Texas', code: 'TX'},
  {name: 'Utah', code: 'UT'},
  {name: 'Vermont', code: 'VT'},
  {name: 'Virginia', code: 'VA'},
  {name: 'Washington', code: 'WA'},
  {name: 'West Virginia', code: 'WV'},
  {name: 'Wisconsin', code: 'WI'},
  {name: 'Wyoming', code: 'WY'},
];

const firstNav = [
  {name: 'Home', link: '/'},
  {name: 'Providers', link: '/providers'},
  {name: 'Conditions', link: '/conditions'},
  {name: 'Your Practice', link: '/your-practice'},
];

const secondNav = [
  {name: 'Benefits', link: '/benefits'},
  {name: 'Why Us', link: '/whyus'},
  {name: 'Articles', link: '/articles'},
  {name: 'FAQs', link: '/faq'},
];

const thirdNav = [
  {name: 'Terms & Conditions', link: '/terms'},
  {name: 'Teleconsent', link: '/teleconsent'},
  {name: 'Privacy policy', link: '/privacy'},
];

const Footer: React.FC = () => {
  return (
    <footer className="bg-blue-600 text-white w-full px-4 md:px-8">
      <div className="max-w-6xl mx-auto py-[4.69rem] lg:py-36">
        <div className="flex flex-col md:flex-row justify-between items-start max-w-[68rem]">
          <a
            href="https://wellnite.com"
            className="inline-block mb-8 lg:mb-0"
            rel="noopener noreferrer"
          >
            <LogoWhite />
          </a>

          <nav className="flex flex-col gap-4">
            {firstNav.map(item => (
              <Link
                key={item.name}
                to={item.link}
                className="block hover:underline"
              >
                {item.name}
              </Link>
            ))}
          </nav>

          <div className="">
            <nav className="flex flex-col gap-4">
              {secondNav.map(item => (
                <Link
                  key={item.name}
                  to={item.link}
                  className="block hover:underline"
                >
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>

          <div className="flex flex-col gap-4">
            <p className="">(341) 888-7637</p>

            <div className="">
              <p className="">National Suicide Prevention line:</p>

              <p className="">1-800-273-8255</p>
            </div>

            <div className="">
              <p className="">Crisis Text Line:</p>

              <p className="">Text "Home" to 741-741</p>
            </div>

            <div className="">
              <p className="">If you are having an emergency:</p>

              <p className="">call 911 or go to nearest ER</p>
            </div>
          </div>

          <nav className="flex flex-col gap-4">
            {thirdNav.map(item => (
              <Link
                key={item.name}
                to={item.link}
                className="block hover:underline"
              >
                {item.name}
              </Link>
            ))}
          </nav>
        </div>

        <div className="py-14">
          <hr className="border-[#FFFFFF40]" />
        </div>

        {/* Find Therapists by State */}

        <div className="">
          <h3 className="text-xl font-semibold mb-10">
            Find Therapists by State
          </h3>

          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {stateLinks.map(({name, code}) => (
              <a
                key={code}
                href={`https://www.wellnite.co/en-us/direct-booking/providers?profession=&state=${code}&conditions=&paymentType=&country=United+States`}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline block"
              >
                {name}
              </a>
            ))}
          </div>
        </div>

        <div className="pt-20">
          Copyright ©{new Date().getFullYear()} Welnite, Inc
        </div>
      </div>
    </footer>
  );
};

export default Footer;
