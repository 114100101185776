import React, {FC, useEffect} from 'react';
import {useAppSelector} from 'app/store';
import {TAndCIcon} from 'components';
import DisclosureAccordion from 'components/Basic/Disclosure';
import {selectUserProfile} from 'features/User';
import {useQuery} from 'hooks';
import {NotesType, SliceStatus} from 'interfaces';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Hearts} from 'react-loader-spinner';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {isMobile, isTherapist} from 'utils';

import {providerActions} from '../providerSlice';

import {getNotesList, selectNotesStatus} from './notesSelectors';

const NOTES_LIMIT = 15;

const ActionContainer = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
`;
const NotesContainer = styled.div<{isMobile: boolean}>`
  width: 100%;
  height: ${props =>
    props.isMobile ? 'calc(100vh - 12rem)' : 'calc(100vh - 8rem)'};
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-touch-callout: default;
  overflow: hidden;
  ul {
    list-style: inside;
  }

  ol {
    list-style: decimal inside;
  }

  @media only screen and (max-width: 1200px) {
    height: calc(100vh - 13rem);
  }
`;

type Props = {
  togglePage: () => void;
  toggleGenerateNotePage?: () => void;
  patientEmail?: string;
  isInVideoCall?: boolean;
  onNoteSelect?: (note: NotesType) => void;
};

const PatientNotes: FC<Props> = ({
  togglePage,
  toggleGenerateNotePage,
  patientEmail,
  isInVideoCall = false,
  onNoteSelect,
}) => {
  const {t} = useTranslation();
  const urlParams = useQuery();
  const dispatch = useDispatch();
  const notes = useSelector(getNotesList);
  const isLoading = useSelector(selectNotesStatus);
  const user = useSelector(selectUserProfile);
  const {
    notes: {hasMore, nextPage},
  } = useAppSelector(state => state.provider);

  const roomMode = urlParams.get('roomMode') || '';

  const fetchNotes = () => {
    dispatch(
      providerActions.getNotes({
        userRole: user!.role,
        pageNo: nextPage,
        size: NOTES_LIMIT,
        patientEmail: patientEmail ?? '',
        roomMode,
      }),
    );
  };

  useEffect(() => {
    if (notes.length === 0) {
      dispatch(
        providerActions.getNotes({
          userRole: user!.role,
          pageNo: 1,
          size: NOTES_LIMIT,
          patientEmail: patientEmail ?? '',
          roomMode,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientEmail]);
  return (
    <section className="h-full">
      {!isInVideoCall ? (
        <ActionContainer className="border-b">
          <p className="font-bold text-lg pl-3 mt-0">{t('Patient Notes')}</p>
        </ActionContainer>
      ) : null}
      <NotesContainer isMobile={isMobile}>
        <InfiniteScroll
          next={fetchNotes}
          hasMore={hasMore}
          dataLength={notes.length}
          loader={
            <div className="w-full flex items-center justify-center my-4">
              <Hearts color="#315eff" height={50} width={50} />
            </div>
          }
          endMessage={
            isLoading === SliceStatus.resolved && notes.length === 0 ? (
              <div className="w-full flex flex-col justify-center items-center my-10">
                <TAndCIcon
                  fillColor="#315eff"
                  strokeColor="text-white"
                  width={40}
                  height={40}
                />
                <p className="mt-3 text-lg capitalize">{t('noNotesFound')}</p>
              </div>
            ) : isLoading === SliceStatus.rejected ? (
              <p className="text-center my-5">{t('oopsSomethingWentWrong')}</p>
            ) : isLoading === SliceStatus.pending ? (
              <div className="w-full flex justify-center items-center p-10">
                <Hearts color="#315eff" width={50} height={50} />
                <p className="ml-3">{t('loadingNotes...')}</p>
              </div>
            ) : (
              <p className="text-center font-light py-3">
                {t('youreAllCaughtUp')}&nsbp;
                <span role="img" aria-label="celebration">
                  🎊
                </span>
              </p>
            )
          }
          height="calc(100vh - 8rem)"
        >
          {notes.length
            ? notes.map(note => (
                <DisclosureAccordion
                  key={note.noteId}
                  note={note}
                  onNoteSelect={onNoteSelect}
                />
              ))
            : null}
        </InfiniteScroll>
      </NotesContainer>
      <ActionContainer className="border-t">
        <button
          className="border border-blue-500 px-8 py-3 rounded-full absolute right-4 focus:outline-none hover:bg-blue-600 hover:text-white"
          onClick={togglePage}
        >
          {t('addNotes')}
        </button>
        {toggleGenerateNotePage && isTherapist(user) ? (
          <button
            className="border border-blue-500 px-8 py-3 rounded-full absolute left-4 focus:outline-none hover:bg-blue-600 hover:text-white"
            onClick={toggleGenerateNotePage}
          >
            {t('wellniteNotes')}
          </button>
        ) : null}
      </ActionContainer>
    </section>
  );
};

export default PatientNotes;
