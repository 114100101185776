import {useEffect, useRef} from 'react';

export const useUnload = (
  fn: (this: Window, ev: BeforeUnloadEvent) => void,
): void => {
  const cb = useRef(fn);
  useEffect(() => {
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return (): void => window.removeEventListener('beforeunload', onUnload);
  }, [cb]);
};
