import React, {useEffect, useState} from 'react';
import {components, OptionProps} from 'react-select';
import {GroupBase} from 'react-select/dist/declarations/src/types';

export const OptionWithCheckbox = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  isSelected,
  isFocused,
  children,
  innerProps,
  ...rest
}: OptionProps<Option, IsMulti, Group>) => {
  const [focused, setFocused] = useState(isFocused);

  useEffect(() => setFocused(isFocused), [isFocused]);
  useEffect(() => setFocused(false), []);

  return (
    <components.Option
      isFocused={focused}
      isSelected={false}
      innerProps={{
        ...innerProps,
        onMouseOver: () => setFocused(true),
      }}
      {...rest}
    >
      <input
        type="checkbox"
        className="mr-3 align-middle scale-110"
        checked={isSelected}
        defaultChecked={isSelected}
      />
      {children}
    </components.Option>
  );
};
