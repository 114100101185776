import * as Yup from 'yup';

export const DPInsuranceEmployerSchema = Yup.object()
  .shape(
    {
      insurance: Yup.string().when('employer', {
        is: val => !val || val.length === 0,
        then: Yup.string().required('You must select an insurance'),
        otherwise: Yup.string(),
      }),
      employer: Yup.string().when('insurance', {
        is: val => !val || val.length === 0,
        then: Yup.string().required('You must select an employer'),
        otherwise: Yup.string(),
      }),
    },
    [['insurance', 'employer']],
  )
  .required();
