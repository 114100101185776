import React, {FC} from 'react';
import {CaretLeftIcon} from 'components';
import {history} from 'utils';

import 'styles/Animations.css';

interface Props {
  headerTitle: React.ReactNode;
  actionButton?: React.ReactNode;
  titleClasses?: string;
  classes?: string;
  backButton?: boolean;
  backButtonFn?: () => void;
}

const goBack = () => {
  // Only run history.goBack() if referrer (prev page) is on same domain
  if (document.referrer.includes(window.location.host)) {
    history.goBack();
  } else {
    history.push('/dashboard');
  }
};

export const MobileTabletHeader: FC<Props> = ({
  headerTitle,
  actionButton,
  classes = '',
  titleClasses = '',
  backButtonFn,
  // backButton = false,
}) => {
  return (
    <section
      className={`w-full flex justify-between items-center gap-1 px-3 py-5 border-b font-inter lg:hidden ${classes}`}
    >
      <div className="flex gap-1 items-center">
        <span
          onClick={backButtonFn ? backButtonFn : goBack}
          className="inline-flex items-center justify-start cursor-pointer h-8 w-6 rounded-full"
        >
          <CaretLeftIcon fillColor="#000" width={18} height={18} />
        </span>
        <div
          data-testid="headerTitle"
          className={`text-[22px] font-semibold md:font-normal whitespace-no-wrap text-left leading-snug text-black text-lg md:text-4xl my-auto ${titleClasses}`}
        >
          {headerTitle}
        </div>
      </div>
      {actionButton ? actionButton : null}
    </section>
  );
};
