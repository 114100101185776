import {
  AirtableProviderDataTypes,
  InsuranceAndColleagues,
  SliceStatus,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  getAirtableProviderData,
  getAirtableProviderDataFailure,
  getAirtableProviderDataSuccess,
  getAirtableProviderFieldsOptions,
  getAirtableProviderFieldsOptionsFailure,
  getAirtableProviderFieldsOptionsSuccess,
  getInsuranceAndColleagues,
  getInsuranceAndColleaguesFailure,
  getInsuranceAndColleaguesSuccess,
  updateAirtableProviderData,
  updateAirtableProviderDataFailure,
  updateAirtableProviderDataSuccess,
} from './airtableActions';

type AirtableSliceState = {
  status: SliceStatus;
  error: string;
  providerData: {
    data: AirtableProviderDataTypes;
    isInitialized?: boolean;
    status: SliceStatus;
  };
  providerFieldsOptions: {
    data: {[key: string]: string[]};
    status: SliceStatus;
  };
  insuranceAndColleagues: {
    data: InsuranceAndColleagues;
    status: SliceStatus;
  };
};

const airtableSlice = createSlice({
  name: 'airtable',
  initialState: {
    providerData: {
      data: {} as AirtableProviderDataTypes,
      isInitialized: false,
      status: SliceStatus.idle,
    },
    providerFieldsOptions: {
      data: {} as {[key: string]: string[]},
      status: SliceStatus.idle,
    },
    status: SliceStatus.idle,
    insuranceAndColleagues: {
      data: {} as InsuranceAndColleagues,
      status: SliceStatus.idle,
    },
    error: '',
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AirtableSliceState>) =>
    builder
      .addCase(getAirtableProviderFieldsOptions, state => ({
        ...state,
        providerFieldsOptions: {
          ...state.providerFieldsOptions,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getInsuranceAndColleagues, state => ({
        ...state,
        insuranceAndColleagues: {
          ...state.insuranceAndColleagues,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getInsuranceAndColleaguesSuccess, (state, action) => ({
        ...state,
        insuranceAndColleagues: {
          ...state.insuranceAndColleagues,
          data: action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getInsuranceAndColleaguesFailure, state => ({
        ...state,
        insuranceAndColleagues: {
          ...state.insuranceAndColleagues,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(getAirtableProviderFieldsOptionsSuccess, (state, action) => ({
        ...state,
        providerFieldsOptions: {
          ...state.providerFieldsOptions,
          data: action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getAirtableProviderFieldsOptionsFailure, state => ({
        ...state,
        providerFieldsOptions: {
          ...state.providerFieldsOptions,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(getAirtableProviderData, state => ({
        ...state,
        providerData: {
          ...state.providerData,
          isInitialized: true,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getAirtableProviderDataSuccess, (state, action) => ({
        ...state,
        providerData: {
          ...state.providerData,
          data: action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getAirtableProviderDataFailure, state => ({
        ...state,
        providerData: {
          ...state.providerData,
          status: SliceStatus.rejected,
        },
      }))
      .addCase(updateAirtableProviderData, state => {
        return {
          ...state,
          status: SliceStatus.pending,
        };
      })
      .addCase(updateAirtableProviderDataSuccess, (state, action) => ({
        ...state,
        providerData: {
          ...state.providerData,
          data: {
            ...state.providerData.data,
            ...(action.payload as any),
          },
        },
        status: SliceStatus.resolved,
      }))
      .addCase(updateAirtableProviderDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addDefaultCase(state => state),
});

export const {reducer: airtableReducer, name: airtableReducerName} =
  airtableSlice;

export type TAirtableActions =
  | ReturnType<typeof getAirtableProviderFieldsOptions>
  | ReturnType<typeof getAirtableProviderFieldsOptionsSuccess>
  | ReturnType<typeof getAirtableProviderFieldsOptionsFailure>
  | ReturnType<typeof getAirtableProviderData>
  | ReturnType<typeof getAirtableProviderDataSuccess>
  | ReturnType<typeof getAirtableProviderDataFailure>
  | ReturnType<typeof updateAirtableProviderData>
  | ReturnType<typeof updateAirtableProviderDataSuccess>
  | ReturnType<typeof updateAirtableProviderDataFailure>
  | ReturnType<typeof getInsuranceAndColleagues>
  | ReturnType<typeof getInsuranceAndColleaguesSuccess>
  | ReturnType<typeof getInsuranceAndColleaguesFailure>;

export const airtableActions = {
  getAirtableProviderFieldsOptions,
  getAirtableProviderFieldsOptionsSuccess,
  getAirtableProviderFieldsOptionsFailure,
  getAirtableProviderData,
  getAirtableProviderDataSuccess,
  getAirtableProviderDataFailure,
  updateAirtableProviderData,
  updateAirtableProviderDataSuccess,
  updateAirtableProviderDataFailure,
  getInsuranceAndColleagues,
  getInsuranceAndColleaguesSuccess,
  getInsuranceAndColleaguesFailure,
};

export type AirtableState = ReturnType<typeof airtableReducer>;
