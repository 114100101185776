import {AppActions, RootState} from 'app/rootReducer';
import {FirebaseRefs} from 'definitions/Firebase';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {combineLatest, from, Observable, of} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {createObservableFromFirebase, isProvider} from 'utils';

import {videoActions} from '../videoSlice';

export const providerStartCallEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: Observable<firebase.app.App>}
> = (action$, _state$, {firebase}) =>
  action$.pipe(
    filter(videoActions.providerStartCall.match),
    withLatestFrom(_state$),
    filter(([, state]) => isProvider(state.user.current)),
    mergeMap(([action]) =>
      combineLatest([firebase, from([action.payload])]).pipe(
        map(([firebaseApp, payload]) => ({
          firebaseApp,
          patientIdAndCallStarted: payload,
        })),
      ),
    ),
    mergeMap(({firebaseApp, patientIdAndCallStarted}) => {
      const ref = firebaseApp
        .database()
        .ref(FirebaseRefs.videoCall)
        .child(patientIdAndCallStarted.patientId);
      return createObservableFromFirebase(ref.set(true)).pipe(
        map(val => userActions.logData(val)),
        catchError(err => of(userActions.logData(err))),
      );
    }),
  );

export const providerVideoCallEpics = [providerStartCallEpic];
