import {SliceStatus} from 'interfaces';

import {createSelector, OutputParametricSelector} from '@reduxjs/toolkit';

import {RootState, StateSlices} from './rootReducer';
import {UiState} from './uiReducer';

function getSliceRequesting(
  state: RootState,
  filter: StateSlices,
): SliceStatus {
  return state[filter].status;
}

function getSliceError(state: RootState, filter: StateSlices): string {
  return state[filter].error;
}

export const makeSliceRequesting = (): OutputParametricSelector<
  RootState,
  StateSlices,
  SliceStatus,
  (res: SliceStatus) => SliceStatus
> => createSelector([getSliceRequesting], isLoading => isLoading);

export const makeSliceError = (): OutputParametricSelector<
  RootState,
  StateSlices,
  string,
  (res: string) => string
> => createSelector([getSliceError], error => error);

function getUi(state: RootState): UiState {
  return state.ui;
}

export const selectDigitalPracticeProvider = createSelector(
  getUi,
  ui => ui.digitalPracticeProvider?.data,
);
export const selectDigitalPracticeProviderStatus = createSelector(
  getUi,
  ui => ui.digitalPracticeProvider?.status,
);
