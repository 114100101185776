import {useEffect} from 'react';
import {selectUserProfile, userActions} from 'features/User';
import {useLocale} from 'hooks';
import {MemberProfile} from 'interfaces';
import {useDispatch, useSelector} from 'react-redux';

export const useSetPreferredLanguage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserProfile) as MemberProfile;
  const {locale} = useLocale();
  const isInternationalUser = locale !== 'en-us';
  const preferredLanguage = locale?.split('-')[0] ?? 'en';

  useEffect(() => {
    if (isInternationalUser) {
      dispatch(
        userActions.setUser({
          ...user,
          preferredLanguage: preferredLanguage ?? 'en',
        }),
      );
    }
  }, []);
};
