export const SELECTION_OPTIONS = [
  {
    label: 'Not at all',
    value: 'notAtAll',
  },
  {
    label: 'Several Days',
    value: 'severalDays',
  },
  {
    label: 'More than half the days',
    value: 'moreThanHalfTheDays',
  },
  {
    label: 'Nearly every day',
    value: 'nearlyEveryDay',
  },
];

export const ANXIETY_QUESTIONS = [
  {label: 'Feeling nervous, anxious, or on edge', value: 'nervous'},
  {
    label: 'Not being able to stop or control worrying',
    value: 'controlWorrying',
  },
  {
    label: 'Worrying too much about different things',
    value: 'worryingTooMuch',
  },
  {label: 'Trouble relaxing', value: 'troubleRelaxing'},
  {
    label: 'Being so restless that it is hard to sit still',
    value: 'restlessness',
  },
  {
    label: 'Becoming easily annoyed or irritable',
    value: 'easilyAnnoyed',
  },
  {
    label: 'Feeling afraid, as if something awful might happen',
    value: 'fear',
  },
];

export const DEPRESSION_QUESTIONS = [
  {
    label: 'Little interest or pleasure in doing things?',
    value: 'littleInterest',
  },
  {
    label: 'Feeling down, depressed, or hopeless?',
    value: 'feelingDown',
  },
  {
    label: 'Trouble falling or staying asleep, or sleeping too much?',
    value: 'sleepingTroubles',
  },
  {
    label: 'Feeling tired or having little energy?',
    value: 'feelingTired',
  },
  {
    label: 'Poor appetite or overeating?',
    value: 'underOrOverEating',
  },
  {
    label:
      'Feeling bad about yourself - or that you are a failure or have let yourself or your family down',
    value: 'feelBadAboutSelf',
  },
  {
    label:
      'Trouble concentrating on things, such as reading the newspaper or watching television',
    value: 'troubleConcentrating',
  },
  {
    label:
      'Moving or speaking so slowly that other people could have noticed? Or so fidgety or restless that you have been moving a lot more than usual',
    value: 'slowMovtOrFidgety',
  },
  {
    label:
      'Thoughts that you would be better off dead, or thoughts of hurting yourself in some way?',
    value: 'thoughtsOfHurtingSelf',
  },
];
