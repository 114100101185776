import React from 'react';

import {SvgIcon} from './SvgIcon';

export const OpenChatIcon: SvgIcon = ({
  width = 35,
  height = 35,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = '#315eff',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <g>
      <path
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m84.699 21.5c-11.398 11.5-22.898 22.898-34.301 34.398-0.39844 0.39844-0.80078 0.80078-1.3008 1.1992-1.1016 0.80078-2.6992 1-4 0.60156-1.1992-0.39844-2.3008-1.5-2.6992-2.6992-0.5-1.5-0.10156-3.1992 1-4.3008 11.398-11.398 22.801-22.801 34.102-34.102 0.60156-0.60156 1.1992-1.3008 1.8984-1.6992 0.5-0.30078 1-0.39844 1.5-0.5 1.1016-0.19922 2.3984 0.19922 3.3008 0.89844 0.89844 0.69922 1.5 1.6992 1.6016 2.8984 0.30078 1.1055-0.19922 2.4062-1.1016 3.3047z"
      />
      <path
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m82.398 68.801c-0.19922 3-1.1016 5.8984-2.6992 8.5-1.5 2.3984-3.5 4.3984-5.8984 5.8984-2.3984 1.5-5.1992 2.3984-8 2.6992-0.60156 0-1.1992 0-1.8984 0.10156-4.6992 0.5-9.3984 0.80078-14 0.89844-1.3984 0-2.6992 0.10156-4.1016 0-4.3984-0.10156-8.8008-0.30078-13.199-0.80078-0.60156-0.10156-1.1992-0.19922-1.8008-0.19922-4.6992-0.19922-9.1992-2.3008-12.398-5.6992-2.3008-2.3984-3.8984-5.3984-4.6016-8.6992-0.39844-1.6016-0.39844-3.3008-0.60156-4.8984-0.60156-4.5-0.69922-9.1016-0.80078-13.699v-2.9023c0.10156-4.5 0.19922-9 0.69922-13.5 0.10156-1 0.19922-2.1016 0.30078-3.1016 0.19922-2.1992 0.80078-4.3984 1.8008-6.3984 1.6992-3.3984 4.3984-6.1992 7.6992-8 2.6016-1.5 5.6016-2.1992 8.6016-2.1992h18.898c0.5 0 0.89844 0.39844 0.89844 0.89844v6.8984c0 0.5-0.39844 0.89844-0.89844 0.89844l-18.797 0.003906c-1.8984 0-3.6992 0.60156-5.3008 1.6016-2 1.3008-3.5 3.3984-4 5.6992-0.19922 0.89844-0.30078 1.8008-0.30078 2.6992-0.10156 1.1016-0.30078 2.1992-0.39844 3.3008-0.39844 4-0.5 7.8984-0.5 11.898 0 4.3008 0.10156 8.6016 0.5 12.898 0.10156 1.1016 0.30078 2.3008 0.39844 3.3984 0.10156 1 0.10156 2.1016 0.39844 3.1016 0.80078 2.8008 2.8984 5.1992 5.6016 6.3008 1.1992 0.5 2.6016 0.80078 3.8984 0.69922 4.1016 0.60156 8.3008 0.89844 12.5 0.89844 6.3008 0.10156 12.602-0.19922 18.801-0.89844 0.80078-0.10156 1.6016 0 2.3984-0.19922 2.8984-0.39844 5.3984-2.1992 6.8984-4.6992 0.69922-1.1992 1.1016-2.5 1.1992-3.8008v-1.6016l0.003907-16.797c0-0.60156 0.5-1.1016 1.1016-1.1016h6.5c0.60156 0 1.1016 0.5 1.1016 1.1016v14.801c-0.003906 1.3008 0.097656 2.6992-0.003906 4z"
      />
    </g>
  </svg>
);

export const ChatIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-[#747474]',
  fillColor = 'none',
  strokeWidth = 1.5, // Added strokeWidth prop to control stroke width
}) => (
  <svg
    id="Isolation_Mode"
    data-name="Isolation Mode"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-0.5 0 20.5 16.21"
    strokeWidth={strokeWidth}
    className={`stroke-current ${strokeColor}`}
    fill={fillColor}
  >
    <path
      id="svg_1"
      strokeWidth={strokeWidth}
      data-name="svg 1"
      d="M18.99,7.9h0v3.17h0c0,1.17-.95,2.11-2.12,2.11h-.96s-.01,2.53-.01,2.53l-3.07-2.33-10.21-.2c-1.17,0-2.12-.95-2.12-2.11H.5v-3.17h0V2.61c0-.56,.22-1.1,.62-1.49,.4-.4,.94-.62,1.5-.62H11.29s4.62,0,4.62,0h.96c1.17,0,2.12,.95,2.12,2.11h0V7.9Z"
    />
  </svg>
);

export const ChatSendIcon: SvgIcon = ({
  width = 15,
  height = 15,
  strokeColor = 'white',
  strokeWidth = 2,
  fillColor = 'white',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current white ${strokeColor} ${classes}`}
    viewBox="0 0 337.1 336.2"
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M322,13.6C313,4.9,300.9,0.1,288.4,0c-5.9,0-11.7,1.1-17.1,3.1L33.7,82.3c-12.9,4.2-23.5,13.6-29.2,25.9
	c-5.7,12.3-6,26.5-0.8,39.1s15.3,22.4,28.1,27.2l88.1,33.6l0,0c1.9,0.8,3.7,1.9,5.2,3.4c1.6,1.6,2.7,3.5,3.5,5.6l33.6,88.3v0.4
	c3.7,9,10.1,16.8,18.2,22.2s17.7,8.3,27.5,8.2h0.4c10.2,0,20.1-3.3,28.4-9.4c8.2-6,14.4-14.5,17.5-24.2L334.4,65
	c3.1-8.8,3.6-18.4,1.4-27.5C333.6,28.4,328.8,20.1,322,13.6L322,13.6z M302.6,54.1l-80.4,238.3c-1,3-2.8,5.6-5.4,7.4
	c-2.5,1.8-5.6,2.8-8.7,2.8s-6.1-0.9-8.7-2.7c-2.6-1.7-4.6-4.2-5.7-7.1l-33.6-88.3c-0.4-1.1-1-2.2-1.5-3.2l66.6-66.8l-23.7-23.7
	l-66.9,67c-1-0.4-1.8-0.9-2.9-1.3l-88.3-33.6l0,0c-3-1.1-5.5-3.1-7.3-5.7c-1.8-2.6-2.7-5.7-2.6-8.9c0-3.2,1-6.2,2.9-8.8
	s4.5-4.4,7.5-5.3l238.3-79.5c3.7-1.5,7.8-1.5,11.4,0c3.7,1.5,6.6,4.3,8.3,7.9S303.9,50.3,302.6,54.1L302.6,54.1z"
    />
  </svg>
);

export const SearchIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    className={`text-white ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m91.203 88.547l-23.273-23.27c5.3867-6.1992 8.6562-14.285 8.6562-23.125 0-19.473-15.844-35.316-35.316-35.316-19.469 0-35.309 15.844-35.309 35.316s15.84 35.316 35.312 35.316c8.8398 0 16.922-3.2695 23.121-8.6562l23.273 23.273c0.48828 0.48828 1.1289 0.73047 1.7695 0.73047s1.2773-0.24219 1.7656-0.73047c0.97656-0.98047 0.97656-2.5625 0-3.5391zm-80.242-46.395c0-16.715 13.598-30.316 30.312-30.316 16.715 0 30.316 13.602 30.316 30.316s-13.598 30.312-30.316 30.312c-16.715 0-30.312-13.598-30.312-30.312z"
    />
  </svg>
);

export const AttachmentIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M216.08,192V335.85a40.08,40.08,0,0,0,80.15,0l.13-188.55a67.94,67.94,0,1,0-135.87,0V337.12a95.51,95.51,0,1,0,191,0V159.74"
    />
  </svg>
);

export const SendIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M470.3,271.15,43.16,447.31a7.83,7.83,0,0,1-11.16-7V327a8,8,0,0,1,6.51-7.86l247.62-47c17.36-3.29,17.36-28.15,0-31.44l-247.63-47a8,8,0,0,1-6.5-7.85V72.59c0-5.74,5.88-10.26,11.16-8L470.3,241.76A16,16,0,0,1,470.3,271.15Z"
    />
  </svg>
);

export const EllipseIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      strokeWidth={strokeWidth}
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export const OfflineIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      d="M93.72 183.25C49.49 198.05 16 233.1 16 288c0 66 54 112 120 112h184.37M467.82 377.74C485.24 363.3 496 341.61 496 312c0-59.82-53-85.76-96-88-8.89-89.54-71-144-144-144-26.16 0-48.79 6.93-67.6 18.14"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M448 448L64 64"
    />
  </svg>
);

export const SocialShareIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <title>Share Social</title>
    <circle
      cx="128"
      cy="256"
      r="48"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="384"
      cy="112"
      r="48"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="384"
      cy="400"
      r="48"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M169.83 279.53l172.34 96.94M342.17 135.53l-172.34 96.94"
    />
  </svg>
);

export const CloseIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
  title = 'Close',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <title>{title}</title>
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M368 368L144 144M368 144L144 368"
    />
  </svg>
);

export const EmojiIcon: SvgIcon = ({
  width = 16,
  height = 16,
  strokeColor = 'text-black',
  strokeWidth = 1.5,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    strokeWidth={strokeWidth}
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
  </svg>
);

export const PhotoIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  strokeWidth = 1.5,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    strokeWidth={strokeWidth}
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
  </svg>
);

export const VideoIcon: SvgIcon = ({
  width = 20,
  height = 20,
  fillColor = 'none',
  strokeColor = 'text-black',
  strokeWidth = 1.5,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    strokeWidth={strokeWidth}
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      fillRule="evenodd"
      d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
    />
  </svg>
);

export const AudioIcon: SvgIcon = ({
  width = 20,
  height = 20,
  fillColor = 'none',
  strokeColor = 'text-black',
  strokeWidth = 1.5,
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    strokeWidth={strokeWidth}
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
    <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
    <path d="M10.025 8a4.486 4.486 0 0 1-1.318 3.182L8 10.475A3.489 3.489 0 0 0 9.025 8c0-.966-.392-1.841-1.025-2.475l.707-.707A4.486 4.486 0 0 1 10.025 8zM7 4a.5.5 0 0 0-.812-.39L3.825 5.5H1.5A.5.5 0 0 0 1 6v4a.5.5 0 0 0 .5.5h2.325l2.363 1.89A.5.5 0 0 0 7 12V4zM4.312 6.39 6 5.04v5.92L4.312 9.61A.5.5 0 0 0 4 9.5H2v-3h2a.5.5 0 0 0 .312-.11z" />
  </svg>
);
