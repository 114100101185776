import React from 'react';
import classNames from 'classnames';
import {MemberNavLink} from 'components/Navigation/MemberNavLink';
import {selectUserProfile} from 'features/User';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isUserFromUnitedStates} from 'utils';

import {ProfileSideBarItems} from './constants';

type Props = {
  className?: string;
};

const ProfileSideBar = ({className = ''}: Props) => {
  const {t} = useTranslation();
  const {pathname} = location;
  const user = useSelector(selectUserProfile);
  const isProviderFromUnitedStates = isUserFromUnitedStates(user);

  return (
    <div className={classNames('lg:block lg:col-span-2 flex-col', className)}>
      <nav className="border sticky top-4 rounded-3xl m-5 px-4">
        {ProfileSideBarItems(t, user!.role, isProviderFromUnitedStates).map(
          (item, index) => {
            const isSelected =
              pathname === item.link ||
              (pathname === '/provider/profile' &&
                item.link.includes('/details'));

            return (
              <MemberNavLink
                pathname={item.link}
                key={`provider-sidebar-item-${index}`}
                classNames={classNames('pl-2 w-auto')}
                current={isSelected}
                linkName={item.label}
              />
            );
          },
        )}
      </nav>
    </div>
  );
};

export default ProfileSideBar;
