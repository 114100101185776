type IntercomFunction = (...args: any[]) => void;

function initializeIntercom(appId: string): void {
  window.intercomSettings = {app_id: appId, vertical_padding: 40};
  (function () {
    const w = window as any;
    const ic = w.Intercom as IntercomFunction;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', window.intercomSettings);
    } else {
      const d = document;
      const i = function (...args: any[]) {
        i.c(args);
      } as any;
      i.q = [];
      i.c = function (args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + appId;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode?.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

export default initializeIntercom;
