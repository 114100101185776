import {FormEvent, useCallback, useEffect, useState} from 'react';
import {
  addCreditCard,
  setDefaultCard,
} from 'features/Payment/CreditCardMgmt/creditCardMgmtActions';
import {selectUserProfile} from 'features/User';
import {MemberProfile} from 'interfaces';
import {useDispatch, useSelector} from 'react-redux';

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {StripeCardNumberElementChangeEvent} from '@stripe/stripe-js';

import {useError} from './useError';
import {useToggle} from './useToggle';

export function useStripeCard(): {
  patientCards: string | {brand: string; last4: string; id: string}[];
  errorMsg: string;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  setCardAsDefault: (cardData: {cardId: string; last4: string}) => void;
  isCreatingToken: boolean;
  onCardNumberChange: (event: StripeCardNumberElementChangeEvent) => void;
} {
  const {patientCards} = useSelector(selectUserProfile) as MemberProfile;
  const {toggleOn, onToggleClick} = useToggle();
  const [errorMsg, setErrorMsg] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const {apiError, resetAsyncError} = useError('payment');

  const setCardAsDefault = useCallback(
    (cardData: {cardId: string; last4: string}) => {
      dispatch(setDefaultCard(cardData));
    },
    [dispatch],
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    // Abort if form isn't valid
    if (!e.currentTarget.reportValidity()) return;
    onToggleClick();
    const cardElement = elements?.getElement(CardNumberElement);
    if (cardElement && stripe) {
      try {
        const stripeResponse = await stripe.createToken(cardElement);
        if (stripeResponse?.error || !stripeResponse?.token) {
          setErrorMsg(
            stripeResponse.error?.message ?? 'Failed to generate token',
          );
        } else {
          dispatch(
            addCreditCard({
              stripeResponse,
            }),
          );
        }
      } finally {
        onToggleClick();
      }
    }
  };

  useEffect(() => {
    setErrorMsg(apiError);
    return (): void => {
      if (apiError) dispatch(resetAsyncError('payment'));
    };
  }, [apiError, dispatch, resetAsyncError]);

  return {
    patientCards,
    isCreatingToken: toggleOn,
    errorMsg,
    handleSubmit,
    setCardAsDefault,
    onCardNumberChange: (event: StripeCardNumberElementChangeEvent) => {
      if (event.complete) {
        setErrorMsg('');
      }
    },
  };
}
