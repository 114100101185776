import React, {FC, ReactNode, useState} from 'react';
import classNames from 'classnames';

import {CaretDownIcon} from '../SvgIcon';

interface Props {
  title: string;
  children: ReactNode;
}

export const AccordionPanel: FC<Props> = ({title, children}) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <section className="border">
      <div
        className={classNames(
          'flex justify-between items-center p-3 cursor-pointer hover:bg-gray-50',
          {
            'border-b': isActive,
          },
        )}
        onClick={() => setIsActive(!isActive)}
      >
        <h2 className="font-bold m-0 leading-snug">{title}</h2>
        <CaretDownIcon
          strokeWidth={0}
          fillColor="#6b6b6b"
          classes={classNames('transition-all', {
            '-rotate-180': isActive,
          })}
        />
      </div>
      {isActive ? <div>{children}</div> : null}
    </section>
  );
};
