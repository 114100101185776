import {ControlProps, defaultTheme, OptionProps} from 'react-select';
import {CSSObjectWithLabel} from 'react-select/dist/declarations/src/types';

import {TStylesConfig} from './models';

const {colors} = defaultTheme;

export const selectStyle: TStylesConfig<any, any, any> = {
  control: (base, {isFocused}) => ({
    ...base,
    border: isFocused ? '1px solid #2E62EC' : '1px solid #E1E1E1',
    borderRadius: '0.65rem',
    boxShadow: '0px 0px 1px 0px #ccc',
    background: '#ffffff',
    textTransform: 'capitalize',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
  }),
  dropdownIndicator: (base, {isFocused}) => ({
    ...base,
    color: isFocused ? '#2E62EC' : '#6b6b6b',
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: 'rgba(46, 98, 236, 0.05)',
      padding: 5,
      borderRadius: 15,
    };
  },
  multiValueRemove: styles => ({
    ...styles,
    ':hover': {
      backgroundColor: '#ec7e7e26',
      borderRadius: 50,
      color: '#d72727',
    },
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderRadius: 10,
    overflow: 'hidden',
    background: 'white',
    padding: 10,
    border: '1px solid rgba(46, 98, 236, 0.25)',
  }),
  option: (
    provided: CSSObjectWithLabel,
    {isSelected, isFocused}: OptionProps,
  ) => {
    provided.background = isFocused ? '#2E62EC14' : provided.background;
    provided.background = isSelected ? '#2E62EC' : provided.background;
    return {
      ...provided,
      borderRadius: 10,
      marginBottom: 10,
      padding: 13,
      textWrap: 'nowrap',
      paddingTop: 15,
      paddingBottom: 15,
    };
  },
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '20px',
  }),
  loadingIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    position: 'absolute',
    right: 0,
  }),
};

export const selectStyleV2: TStylesConfig<any, any, any> = {
  ...selectStyle,
  control: (base: CSSObjectWithLabel, state) => ({
    ...(selectStyle.control ? selectStyle.control(base, state) : base),
    borderRadius: '11px',
    padding: '6px 2px 6px 16px',
    height: '52px',
    boxShadow: 'none !important',
  }),
  menu: () => ({
    boxShadow: 'none',
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    '&::-webkit-scrollbar': {
      height: '10px',
    },
    maxHeight: 240,
    scrollbarWidth: 'thin',
    position: 'absolute',
    left: '0px',
    width: '100%',
    backgroundColor: 'white',
    zIndex: '999',
    boxShadow: 'none',
    border: '1px solid #e1e1e1', //lightGray
    padding: '8px',
    borderRadius: '8px',
    marginTop: '8px',
  }),
  option: (base: CSSObjectWithLabel, {isSelected, isFocused}: OptionProps) => {
    base.background = isFocused ? '#2E62EC14' : base.background;
    base.background = isSelected ? '#2E62EC' : base.background;
    return {
      ...base,
      borderRadius: 10,
      marginBottom: 10,
      padding: '12px 16px',
      textWrap: 'nowrap',
      position: 'relative',
    };
  },
};

export const reactSelectStylesBorderlessControl: TStylesConfig<any, any> = {
  control: (base: any, {isFocused}) => ({
    ...base,
    border: isFocused ? '0px solid #2E62EC' : '0px solid #ccc',
    borderRadius: '8px',
  }),
  multiValue: styles => {
    return {
      ...styles,
      borderRadius: '9999px',
    };
  },
};

const duplicateStyles: TStylesConfig<any, any, any> = {
  control: (provided: CSSObjectWithLabel, {isFocused}: ControlProps) => ({
    ...provided,
    margin: 10,
    padding: 9,
    backgroundColor: '#D9D9D925',
    border: isFocused ? provided.border : 'none',
    borderColor: isFocused ? 'black' : 'none',
    '&:hover': {
      border: isFocused ? provided.border : 'none',
      borderColor: isFocused ? 'black' : 'none',
    },
    color: isFocused ? 'black' : colors.neutral70,
    boxShadow: 'none',
    borderRadius: 10,
    flexDirection: 'row-reverse',
  }),
  menu: () => ({
    border: 'none',
    margin: 10,
    marginBottom: 0,
    scrollbarGutter: 'stable',
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    '&::-webkit-scrollbar': {
      height: '10px',
    },
    maxHeight: 240,
    scrollbarWidth: 'thin',
  }),
};

export const detachedSearchBarStyles: TStylesConfig<any, any, any> = {
  ...duplicateStyles,
  option: (
    provided: CSSObjectWithLabel,
    {isSelected, isFocused}: OptionProps,
  ) => {
    provided.background = isFocused ? '#2E62EC14' : provided.background;
    provided.background = isSelected ? '#2E62EC' : provided.background;
    return {
      ...provided,
      borderRadius: 10,
      marginBottom: 10,
      padding: 13,
      textWrap: 'nowrap',
      paddingTop: 15,
      paddingBottom: 15,
    };
  },
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '20px',
  }),
  detachedButton: (isOpen: boolean, hasValue: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    padding: '0.75rem',
    border: '1px solid',
    borderColor: isOpen || hasValue ? '#2E62EC' : 'rgb(204 204 204)',
    color: hasValue ? 'black' : '#6B6B6B',
    boxShadow: isOpen ? '0px 0px 5px #2E62EC80' : 'none',
  }),
  loadingIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    position: 'absolute',
    right: 0,
  }),
  dropdownMenu: () => ({
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 0 1px #2E62EC40, 0 4px 11px #2E62EC40',
    width: 'auto',
    overflow: 'hidden',
    marginTop: '8px',
    minWidth: '240px',
    maxWidth: `${window.innerWidth - 30}px`,
    position: 'fixed',
    zIndex: '99999',
  }),
};

export const slimDetachedSearchStyles: TStylesConfig<any, any, any> = {
  ...duplicateStyles,
  option: (
    provided: CSSObjectWithLabel,
    {isSelected, isFocused}: OptionProps,
  ) => {
    provided.background = isFocused ? '#2E62EC14' : provided.background;
    provided.background = isSelected ? '#2E62EC' : provided.background;
    return {
      ...provided,
      borderRadius: 10,
      marginBottom: 2,
      padding: 7,
      textWrap: 'nowrap',
      paddingTop: 5,
      paddingBottom: 5,
    };
  },
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '20px',
  }),
  detachedButton: (isOpen: boolean, hasValue: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    borderColor: isOpen || hasValue ? '#2E62EC' : 'rgb(204 204 204)',
    color: hasValue ? 'black' : '#6B6B6B',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    padding: 0,
    background: 'transparent',
  }),
  loadingIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    position: 'absolute',
    right: 0,
  }),
  dropdownMenu: () => ({
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 0 0 1px #2E62EC40, 0 4px 11px #2E62EC40',
    width: 'auto',
    overflow: 'hidden',
    marginTop: '8px',
    minWidth: '140px',
    maxWidth: `${window.innerWidth - 30}px`,
    position: 'fixed',
    zIndex: '999',
  }),
};
