import {
  RegisterFormData,
  StripePlanDurations,
  StripePlanNames,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const register = createAction<
  RegisterFormData & {
    referralCode: string | undefined;
    referralSource: string;
    paymentPlan: StripePlanNames;
    duration: StripePlanDurations;
    socialOnboardingExtraFlow?: string;
  }
>('Register');
export const registerSuccess = createAction('RegisterSuccess');
export const registerFailure = createAction('RegisterFailure');
