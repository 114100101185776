import React, {useEffect} from 'react';

export function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  onClick: (event: MouseEvent) => void,
  ignoreSelector?: string,
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const clickedInside = ref.current?.contains(target);

      if (clickedInside) {
        return;
      }

      if (!ignoreSelector || !target.closest(ignoreSelector)) {
        onClick(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref]);
}
