import React, {Dispatch, FC, useRef} from 'react';
import Modal from 'components/Basic/Modal';
import dayjs from 'dayjs';
import {MemberProfile} from 'interfaces';
import {useTranslation} from 'react-i18next';

import {BookAppointment} from './BookAppointment/index2';

type Props = {
  show: boolean;
  onClose: () => void;
  selectedDate?: dayjs.Dayjs;
  setShowBookingModal: Dispatch<React.SetStateAction<boolean>>;
  setShowSuccessModal?: Dispatch<React.SetStateAction<boolean>>;
  member?: MemberProfile;
};

export const BookingModal: FC<Props> = ({
  show,
  onClose,
  selectedDate,
  setShowBookingModal,
  setShowSuccessModal,
  member,
}: Props) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation();

  return (
    <Modal
      messageType="none"
      isOpen={show}
      showCloseIcon
      headerClasses="w-full h-16 px-4 flex justify-end items-center"
      containerClasses="w-full max-h-[90vh] bg-white max-w-[445px] overflow-hidden transform rounded-lg text-left align-middle transition-all shadow-lg"
      title={t('bookAppointment')}
      footerClasses="sticky bottom-0 bg-white"
      footerHeight="94px"
      floatingTitle={false}
      buttonFn={onClose}
      footer={<div ref={footerRef} />}
      zIndex={1000}
    >
      <BookAppointment
        selectedDate={selectedDate}
        setShowBookingModal={setShowBookingModal}
        onSuccessModal={setShowSuccessModal}
        footerRef={footerRef}
        member={member}
      />
    </Modal>
  );
};
