import React, {FC, useState} from 'react';
import classNames from 'classnames';
import {selectUserProfile} from 'features/User';
import {
  AppointmentDurations,
  AppointmentTypes,
  MemberProfile,
} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isProvider} from 'utils';

import RecurringBookingCard from './RecurrinBooking/RecurringBookingCard';
import {Durations} from './AppointmentDurations';
import {BookingForm} from './BookingForm';
import {GroupBookingForm} from './GroupBookingForm';
import {useAppointmentTypes} from './useAppointmentTypes';

type Props = {
  appointmentType?: AppointmentTypes;
  member?: MemberProfile;
  onSuccessModal?: (arg: boolean) => void | undefined;
};

export const BookAppointment: FC<Props> = ({
  appointmentType,
  member,
  onSuccessModal,
}) => {
  const user = useSelector(selectUserProfile);
  const [currAppointment, setCurrAppointment] = useState<{
    type: AppointmentTypes;
    duration: AppointmentDurations;
  }>();
  const {t} = useTranslation();
  const appointmentTypes = useAppointmentTypes(appointmentType);

  /**
   *
   * @param type
   * @param duration
   */
  const onUpdateApptTypeAndDuration = (data: {
    type: AppointmentTypes;
    duration: AppointmentDurations;
  }) => {
    setCurrAppointment(data);
  };

  const resetCurrAppointment = () => {
    setCurrAppointment(undefined);
  };

  const showBookingFormAndDuration = () => {
    if (
      currAppointment?.type &&
      currAppointment?.duration &&
      currAppointment.type.includes('recurring')
    )
      return (
        <section className="my-8">
          <RecurringBookingCard />
        </section>
      );
    if (
      currAppointment?.type &&
      currAppointment?.duration &&
      currAppointment.type.includes('group')
    )
      return (
        <>
          <p className="text-2xl my-8 text-center font-medium">
            {t('book_group_appointment')}
          </p>
          <GroupBookingForm
            resetCurrAppointment={resetCurrAppointment}
            onSuccessModal={onSuccessModal}
          />
        </>
      );
    else if (
      currAppointment?.type &&
      currAppointment?.duration &&
      !currAppointment.type.includes('group')
    )
      return (
        <>
          {isProvider(user) ? (
            <p className="text-2xl my-8 text-center font-medium">
              {t('book_an_appointment')}
            </p>
          ) : null}
          <BookingForm
            appointmentType={currAppointment.type}
            duration={currAppointment.duration}
            member={member}
          />
        </>
      );
    else
      return appointmentTypes.map((appointmentType, idx) => (
        <section
          key={+idx}
          className={classNames({
            'pt-8': !isProvider(user),
            'pt-7':
              isProvider(user) &&
              appointmentType === AppointmentTypes.recurring_session,
          })}
        >
          {appointmentType === AppointmentTypes.group_call_with_therapist ? (
            <p className="text-2xl my-8 text-center font-medium">
              {t('book_group_appointment')}
            </p>
          ) : isProvider(user) &&
            appointmentType !== AppointmentTypes.recurring_session ? (
            <p className="text-2xl my-8 text-center font-medium">
              {t('book_an_appointment')}
            </p>
          ) : null}
          <Durations
            key={appointmentType}
            onUpdateApptTypeAndDuration={onUpdateApptTypeAndDuration}
            appointmentDuration={currAppointment?.duration}
            appointmentType={appointmentType}
          />
        </section>
      ));
  };

  return <section className="w-full">{showBookingFormAndDuration()}</section>;
};
