import {ProviderRole} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const sendCalendarSettingsNotification = createAction(
  'sendCalendarSettingsNotification',
);
export const sendCalendarSettingsNotificationSuccess = createAction(
  'sendCalendarSettingsNotificationSuccess',
);
export const sendCalendarSettingsNotificationFailure = createAction(
  'sendCalendarSettingsNotificationFailure',
);

export const sendAvailabilityUpdateNotification = createAction<{
  role: ProviderRole;
}>('sendAvailabilityUpdateNotification');
export const sendAvailabilityUpdateNotificationSuccess = createAction(
  'sendAvailabilityUpdateNotificationSuccess',
);
export const sendAvailabilityUpdateNotificationFailure = createAction(
  'sendAvailabilityUpdateNotificationFailure',
);
