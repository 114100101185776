import {
  Appointment,
  AppointmentDurations,
  AppointmentTypes,
  AvailableProvider,
  AvailableProviders,
  AvailableSlots,
  BookGroupAppointmentTypes,
  EndPoints,
  HttpMethods,
  UserRoles,
} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {attachQueryParameters, unwrapAPIError} from 'utils';

import {Token} from '@stripe/stripe-js';

import {
  getAppointmentDateTimeString,
  getAvailableTimeData,
  getCountryDefaultTimeZone,
  getDefaultNow,
  getDisabledDays,
  getFormattedMemberAppointmentDate,
  isAmericanRegion,
  isCountryOfResidenceRegion,
  removePastOpeningsFromCalendar,
} from './helpers';
import {getTimezonesArray} from './timezone';

const getAvailableProviders = async (data: {
  appointmentType: AppointmentTypes;
  duration: AppointmentDurations;
  email: string;
  role: UserRoles;
}): Promise<Response<{message: AvailableProviders}>> => {
  try {
    const res = await requestHandler<{
      message: AvailableProviders;
    }>({
      method: HttpMethods.GET,
      url: `/api/${data.role}/${EndPoints.GetAvailableProviders}?appointmentType=${data.appointmentType}&email=${data.email}&duration=${data.duration}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getAvailableSlots = async (data: {
  providerId: string;
  calendarId: number;
  yearAndMonth: string;

  // {appointmentType, duration, state} required for direct booking, and provider booking appointment
  appointmentType?: AppointmentTypes;
  duration?: AppointmentDurations;
  state?: string;
  day?: number;
}): Promise<Response<{message: AvailableSlots}>> => {
  let url = `${EndPoints.GetAvailableSlots}/${data.calendarId}?yearAndMonth=${data.yearAndMonth}&duration=${data.duration}&appointmentType=${data.appointmentType}`;
  if (data.state) url += `&state=${data.state}`;
  if (data.day) url += `&day=${data.day}`;

  try {
    const res = await requestHandler<{
      message: AvailableSlots;
    }>({
      method: HttpMethods.GET,
      url: url as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const bookAppointment = async (data: {
  appointmentDateTimeString: string;
  therapistId?: string;
  prescriberId?: string;
  appointmentType: AppointmentTypes;
  patientTimezone: string;
  calendarId: number;
  appointmentTypeID: string;
  patientEmail?: string;
  providerFullName?: string;
  memberFullName?: string;
}): Promise<Appointment> => {
  try {
    const result = await requestHandler<{message: Appointment}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.BookAppointment,
      data,
    });

    return result.data?.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const cancelAppointment = async (data: {
  appointmentID: number;
  reasonForCancellation?: string;
}): Promise<void> => {
  try {
    await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.CancelAppointment,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const bookOneTimeAppointment = async (data: {
  appointmentDateTimeString: string;
  therapistId?: string;
  prescriberId?: string;
  appointmentType: AppointmentTypes;
  patientTimezone: string;
  calendarId: number;
  appointmentTypeID: string;
  token?: Token;
}): Promise<void> => {
  try {
    await requestHandler<{message: string}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.BookOneTimeAppointment,
      data,
    });
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getBookingCharge = async (data: {
  role: UserRoles;
  patientEmail: string;
  appointmentType: AppointmentTypes;
  appointmentTypeID?: string;
  appointmentDuration?: AppointmentDurations;
  isRecurring?: string;
  numberOfWeeks?: string;
}): Promise<
  Response<{
    message: {
      charge: number;
    };
  }>
> => {
  const {role, ...rest} = data;
  const url = attachQueryParameters(
    `/api/${role}/${EndPoints.BookingCharge}` as unknown as EndPoints,
    rest,
  );

  try {
    const res = await requestHandler<{message: {charge: number}}, typeof data>({
      method: HttpMethods.GET,
      url: url as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const bookGroupAppointment = async (
  data: BookGroupAppointmentTypes,
): Promise<
  Response<{
    message: Appointment;
  }>
> => {
  try {
    const res = await requestHandler<{message: Appointment}>({
      method: HttpMethods.POST,
      url: EndPoints.BookGroupAppointment,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getProviderRecurringAvailableSlots = async (data: {
  startDate: string;
  duration: string;
  numberOfWeeks: string;
  patientEmail?: string;
}): Promise<
  Response<{
    message: string[];
  }>
> => {
  const url = attachQueryParameters(EndPoints.GetRecurringAvailableSlots, data);
  try {
    const res = await requestHandler<{
      message: string[];
    }>({
      method: HttpMethods.GET,
      url: url as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
const bookRecurringAppointment = async (data: {
  numberOfWeeks: number;
  appointmentStartDateTimeString: string;
  therapistId: string;
  patientTimezone: string;
  appointmentDuration: string;
  patientEmail?: string;
  noteMessage: string;
}): Promise<Appointment[]> => {
  try {
    const result = await requestHandler<{message: Appointment[]}, typeof data>({
      method: HttpMethods.POST,
      url: EndPoints.BookRecurringAppointment,
      data,
    });

    return result.data?.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getProviderDataForAppointment = async (
  providerMongoId: string,
): Promise<Response<{message: AvailableProvider}>> => {
  try {
    const res = await requestHandler<{
      message: AvailableProvider;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetProviderDataForAppointment}/${providerMongoId}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};
export const BookingService = {
  getDefaultNow,
  getTimezonesArray,
  getCountryDefaultTimeZone,
  isAmericanRegion,
  isCountryOfResidenceRegion,
  removePastOpeningsFromCalendar,
  getDisabledDays,
  getAvailableTimeData,
  getAppointmentDateTimeString,
  getFormattedMemberAppointmentDate,
  bookAppointment,
  cancelAppointment,
  bookOneTimeAppointment,
  getBookingCharge,
  bookGroupAppointment,
  getProviderRecurringAvailableSlots,
  bookRecurringAppointment,
  getAvailableProviders,
  getAvailableSlots,
  getProviderDataForAppointment,
};
