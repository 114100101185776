export const theme = {
  fontFamily: {
    avenir: ['Avenir', 'sans-serif'],
  },
  colors: {
    blue: {
      '200': '#c1ceff',
      '600': '#315eff',
    },
    lightBlue: {
      '500': '#00acee',
    },
    gray: {
      '100': '#f1f1f1',
      '200': '#f2f2f2',
      '300': '#e6e6e6',
      '500': '#9b9b9b',
      '600': '#6c6c6c',
      '700': '#4a4a4a',
    },
    orange: {
      '500': '#ea572b',
    },
    yellow: {
      '200': '#ffc200',
    },
    red: {
      '500': '#f02849',
    },
    green: {
      '200': '#84c793',
      '300': '#81c644',
      '900': '#00cc00',
    },
  },
};
