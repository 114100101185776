import {
  ChangeEmailSchema,
  ChangePasswordSchema,
  changePreferredLanguageSchema,
  VerifyPasswordSchema,
  VerifyPhoneSchema,
  VerifyPhoneTokenSchema,
} from 'definitions/Yup';
import * as Yup from 'yup';

export type VerifyPasswordData = Yup.InferType<typeof VerifyPasswordSchema>;
export type ChangePasswordData = Yup.InferType<typeof ChangePasswordSchema>;
export type ChangeEmailData = Yup.InferType<typeof ChangeEmailSchema>;
export type VerifyPhoneData = Yup.InferType<typeof VerifyPhoneSchema>;
export type VerifyPhoneTokenData = Yup.InferType<typeof VerifyPhoneTokenSchema>;
export type ChangePreferredLanguageData = Yup.InferType<
  typeof changePreferredLanguageSchema
>;

export enum AccountTabs {
  overview = 'account',
  changeAddress = 'changeAddress',
  changePassword = 'changePassword',
  changePhone = 'changePhoneNumber',
  changeEmail = 'changeEmailAddress',
  changePreferredLanguage = 'changeLanguage',
}
