import {
  InternationalProviderCredentialFormType,
  InternationalProviderProfileFormType,
  InternationalProviderProfileTwoFormType,
  InternationalProviderUploadIdDocumentFormType,
  LicenseCopyFormData,
  MemberProfile,
  PrescriberProfile,
  ProviderSignupFormData,
  ProviderUpdateForm,
  TherapistProfile,
  UserRoles,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const changeProviderFromFirstTime = createAction<UserRoles>(
  'changeProviderFromFirstTime',
);
export const changeProviderFromFirstTimeSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('changeProviderFromFirstTimeSuccess');
export const changeProviderFromFirstTimeFailure = createAction(
  'changeProviderFromFirstTimeFailure',
);
export const signup = createAction<ProviderSignupFormData>('signup');
export const signupSuccess = createAction('signupSuccess');
export const signupFailure = createAction<string>('signupFailure');
export const getProviderData = createAction<UserRoles>('getProviderData');
export const getProviderDataSuccess = createAction<
  TherapistProfile | PrescriberProfile | MemberProfile
>('getProviderDataSuccess');
export const getProviderDataFailure = createAction('getProviderDataFailure');

export const updateProviderNpi = createAction<{
  providerNpi: string;
  role: UserRoles;
}>('updateProviderNpi');
export const updateProviderNpiSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('updateProviderNpiSuccess');
export const updateProviderNpiFailure = createAction<string>(
  'updateProviderNpiFailure',
);
export const updateProviderData =
  createAction<ProviderUpdateForm>('updateProvider');
export const updateProviderDataSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('updateProviderSuccess');
export const updateProviderDataFailure = createAction<string>(
  'updateProviderFailure',
);

export const uploadLicenseCopy = createAction<LicenseCopyFormData>(
  'UploadLicenseCertificate',
);
export const uploadLicenseCopySuccess = createAction<
  TherapistProfile | PrescriberProfile
>('UploadLicenseCertificateSuccess');
export const uploadLicenseCopyFailure = createAction<string>(
  'UploadLicenseCertificateFailure',
);

export const skipCAQHForm = createAction<UserRoles>('skipCAQHForm');
export const skipCAQHFormSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('skipCAQHFormSuccess');
export const skipCAQHFormFailure = createAction<string>('skipCAQHFormFailure');

export const changeOnboardingVideoStatus = createAction<UserRoles>(
  'changeOnboardingVideoStatus',
);
export const changeOnboardingVideoStatusSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('changeOnboardingVideoStatusSuccess');
export const changeOnboardingVideoStatusFailure = createAction(
  'changeOnboardingVideoStatusFailure',
);
export const completeOnboarding = createAction<UserRoles>('completeOnboarding');
export const completeOnboardingSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('completeOnboardingSuccess');
export const completeOnboardingFailure = createAction(
  'completeOnboardingFailure',
);

export const updateInternationalProviderCrendentialsData = createAction<{
  role: UserRoles;
  formData: InternationalProviderCredentialFormType;
}>('UpdateProviderCredentialsData');
export const updateInternationalProviderCrendentialsDataSuccess =
  createAction<TherapistProfile>('UpdateProviderCredentialsDataSuccess');
export const updateInternationalProviderCrendentialsDataFailure =
  createAction<string>('UpdateProviderCredentialsDataFailure');

export const updateInternationalProviderProfileData = createAction<{
  role: UserRoles;
  formData: InternationalProviderProfileFormType;
}>('UpdateInternationalProviderProfileData');
export const updateInternationalProviderProfileDataSuccess =
  createAction<TherapistProfile>(
    'UpdateInternationalProviderProfileDataSuccess',
  );
export const updateInternationalProviderProfileDataFailure =
  createAction<string>('UpdateInternationalProviderProfileDataFailure');
export const uploadInternationalProviderIdDocument = createAction<{
  role: UserRoles;
  formData: InternationalProviderUploadIdDocumentFormType;
}>('UploadInternationalProviderIdDocument');

export const updateInternationalProviderProfileTwoData = createAction<{
  role: UserRoles;
  formData: InternationalProviderProfileTwoFormType;
  cb?: () => void;
}>('UpdateInternationalProviderTwoProfileData');
export const updateInternationalProviderProfileTwoDataSuccess =
  createAction<TherapistProfile>(
    'UpdateInternationalProviderProfileTwoDataSuccess',
  );
export const updateInternationalProviderProfileTwoDataFailure =
  createAction<string>('UpdateInternationalProviderProfileTwoDataFailure');

export const uploadInternationalProviderIdDocumentSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('UploadInternationalProviderIdDocumentSuccess');
export const uploadInternationalProviderIdDocumentFailure =
  createAction<string>('UploadInternationalProviderIdDocumentFailure');
export const verifyHuman = createAction<{role: UserRoles; imageUrl: string}>(
  'verifyHuman',
);
export const sendInternationalProviderAgreedToTerms = createAction<UserRoles>(
  'SendInternationalProviderAgreedToTerms',
);
export const sendInternationalProviderAgreedToTermsSuccess = createAction<
  TherapistProfile | PrescriberProfile
>('SendInternationalProviderAgreedToTermsSuccess');
export const sendInternationalProviderAgreedToTermsFailure =
  createAction<string>('SendInternationalProviderAgreedToTermsFailure');
export const verifyHumanSuccess = createAction('verifyHumanSuccess');
export const verifyHumanFailure = createAction<string>('verifyHumanFailure');

export const unsubscribeOnboardingReminder = createAction<{
  role: UserRoles;
  email: string;
}>('unsubscribeOnboardingReminder');
export const unsubscribeOnboardingReminderSuccess = createAction(
  'unsubscribeOnboardingReminderSuccess',
);
export const unsubscribeOnboardingReminderFailure = createAction(
  'unsubscribeOnboardingReminderFailure',
);
