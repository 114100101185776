import {
  EndPoints,
  GenerateWellniteContentArgs,
  GenerateWellniteContentResponse,
  HttpMethods,
  UnsplashSearchResults,
  WellniteContentArticle,
  WellniteContentDraftState,
} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';

const generateExpertSummary = async (): Promise<
  Response<{message: {expertSummary: string}}>
> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {expertSummary: string};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GenerateExpertSummary,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const generateWellniteContent = async (
  data: GenerateWellniteContentArgs,
): Promise<Response<{message: {content: GenerateWellniteContentResponse}}>> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {content: GenerateWellniteContentResponse};
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.GenerateWellniteContent,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const reGenerateWellniteContent = async ({
  element,
  ...data
}: GenerateWellniteContentArgs & {element: string}): Promise<
  Response<{message: {content: GenerateWellniteContentResponse}}>
> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {content: GenerateWellniteContentResponse};
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.ReGenerateWellniteContent}/${element}` as unknown as EndPoints,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const saveWellniteArticle = async (
  data: WellniteContentArticle,
): Promise<Response<{message: {article: WellniteContentArticle}}>> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {article: WellniteContentArticle};
      },
      {articleData: WellniteContentArticle}
    >({
      method: HttpMethods.POST,
      url: EndPoints.SaveWellniteArticle,
      data: {articleData: data},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteImages = async ({
  count,
  query,
  page = 1,
}: {
  count: number;
  query: string;
  page?: number;
}): Promise<Response<{message: {photos: UnsplashSearchResults}}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {photos: UnsplashSearchResults};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetWellniteImages}?query=${query}&page=${page}&per_page=${count}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteArticleBySlug = async ({
  slug,
}: {
  slug: string;
}): Promise<Response<{message: {article: WellniteContentArticle}}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {article: WellniteContentArticle};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetWellniteArticleBySlug}/${slug}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteArticlesByProvider = async ({
  providerId,
}: {
  providerId: string;
}): Promise<Response<{message: {articles: WellniteContentArticle[]}}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {articles: WellniteContentArticle[]};
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetWellniteArticlesByProvider}/${providerId}` as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const checkIfProviderReachedDailyWellniteContentLimit = async (): Promise<
  Response<{message: string}>
> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: string;
    }>({
      method: HttpMethods.GET,
      url: EndPoints.CheckIfProviderReachedDailyWellniteContentLimit as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const triggerDownloadUnsplashPhotoEvent = async (params: {
  url: string;
}): Promise<Response<{message: string}>> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: string;
    }>({
      method: HttpMethods.POST,
      url: EndPoints.TriggerDownloadUnsplashPhotoEvent as unknown as EndPoints,
      data: {url: params.url},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteContentDraft = async (): Promise<
  Response<{message: {draft: WellniteContentDraftState}}>
> => {
  try {
    const res = await requestHandler<{
      status: number;
      message: {draft: WellniteContentDraftState};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.WellniteContentDraft,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const saveWellniteContentDraft = async (
  data: WellniteContentDraftState,
): Promise<Response<{message: {draft: WellniteContentDraftState}}>> => {
  try {
    const res = await requestHandler<
      {
        status: number;
        message: {draft: WellniteContentDraftState};
      },
      {draftData: typeof data}
    >({
      method: HttpMethods.POST,
      url: EndPoints.WellniteContentDraft,
      data: {draftData: data},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const WellniteContentService = {
  generateExpertSummary,
  generateWellniteContent,
  reGenerateWellniteContent,
  saveWellniteArticle,
  getWellniteImages,
  getWellniteArticleBySlug,
  getWellniteArticlesByProvider,
  checkIfProviderReachedDailyWellniteContentLimit,
  triggerDownloadUnsplashPhotoEvent,
  getWellniteContentDraft,
  saveWellniteContentDraft,
};
