import React, {FC} from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';

export interface LinkProps {
  pathname: string;
  linkName: string;
  current: boolean;
  first?: boolean;
  last?: boolean;
  count?: number;
  classNames?: string;
  activeClassNames?: string;
  children?: React.ReactNode;
}

export const NavLink: FC<LinkProps> = ({
  pathname,
  current,
  linkName,
  first = false,
  last = false,
  children,
  count = 0,
  classNames = 'pl-24',
  activeClassNames = '',
}) => {
  return (
    <Link to={pathname}>
      <div
        className={classnames(
          `group flex items-center py-3 md:py-6 text-xl leading-snug border-gray-200 ${classNames} ${
            activeClassNames && current ? activeClassNames : ''
          }`,
          {
            'text-blue-600': current,
            'text-gray-700': !current,
            'border-t': !first,
            'border-b': last,
          },
        )}
      >
        {children}
        <span
          className={`${
            linkName.toLowerCase() === 'share' ? 'text-white' : ''
          } pt-1 pl-5 `}
        >
          {linkName}
        </span>

        {/(notifications|messages)/.test(linkName.toLowerCase()) &&
        count > 0 ? (
          <span className="inline-flex items-center justify-center ml-auto mr-4 h-6 w-6 rounded-full bg-red-500">
            <span className="text-sm leading-snug text-white">{count}</span>
          </span>
        ) : null}
      </div>
    </Link>
  );
};
