import {AppActions, RootState} from 'app/rootReducer';
import {userActions} from 'features/User';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {StripeService} from 'services';

import {paymentActions} from '../paymentSlice';

export const annualPlanInterestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.annualPlanInterest.match),
    mergeMap(({payload: {paymentPlan}}) =>
      from(StripeService.annualPlanInterest({paymentPlan})).pipe(
        mergeMap(({data: {message}}) => [
          paymentActions.annualPlanInterestSuccess(),
          paymentActions.setManageSubscriptionState({
            subscriptionState: 'upgrade',
            data: message,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.annualPlanInterestFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const setPauseSubscriptionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.pauseSubscription.match),
    mergeMap(() =>
      from(StripeService.pauseSubscription()).pipe(
        mergeMap(({data: {message}}) => [
          userActions.checkSession(UserRoles.member),
          paymentActions.pauseSubscriptionSuccess(),
          paymentActions.setManageSubscriptionState({
            subscriptionState: 'pause',
            data: message * 1000,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.pauseSubscriptionFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const cancelSubscriptionEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.cancelSubscription.match),
    switchMap(({payload: data}) =>
      from(StripeService.cancelSubscription(data)).pipe(
        mergeMap(() => [
          paymentActions.cancelSubscriptionSuccess(),
          userActions.checkSession(UserRoles.member),
          userActions.resetAsyncError('payment'),
          paymentActions.resetManageSubscriptionState({
            subscriptionState: 'none',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.cancelSubscriptionFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const manageSubscriptionEpics = [
  annualPlanInterestEpic,
  setPauseSubscriptionEpic,
  cancelSubscriptionEpic,
];
