import React, {FC} from 'react';

import {SvgIcon} from './SvgIcon';

export const MessagesIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M87.48,380c1.2-4.38-1.43-10.47-3.94-14.86A42.63,42.63,0,0,0,81,361.34a199.81,199.81,0,0,1-33-110C47.64,139.09,140.72,48,255.82,48,356.2,48,440,117.54,459.57,209.85A199,199,0,0,1,464,251.49c0,112.41-89.49,204.93-204.59,204.93-18.31,0-43-4.6-56.47-8.37s-26.92-8.77-30.39-10.11a31.14,31.14,0,0,0-11.13-2.07,30.7,30.7,0,0,0-12.08,2.43L81.5,462.78A15.92,15.92,0,0,1,76.84,464a9.61,9.61,0,0,1-9.58-9.74,15.85,15.85,0,0,1,.6-3.29Z"
    />
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="160"
      cy="256"
      r="32"
    />
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="256"
      cy="256"
      r="32"
    />
    <circle
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      cx="352"
      cy="256"
      r="32"
    />
  </svg>
);

export const ChatBubblesIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      d="M431,320.6c-1-3.6,1.2-8.6,3.3-12.2a33.68,33.68,0,0,1,2.1-3.1A162,162,0,0,0,464,215c.3-92.2-77.5-167-173.7-167C206.4,48,136.4,105.1,120,180.9a160.7,160.7,0,0,0-3.7,34.2c0,92.3,74.8,169.1,171,169.1,15.3,0,35.9-4.6,47.2-7.7s22.5-7.2,25.4-8.3a26.44,26.44,0,0,1,9.3-1.7,26,26,0,0,1,10.1,2L436,388.6a13.52,13.52,0,0,0,3.9,1,8,8,0,0,0,8-8,12.85,12.85,0,0,0-.5-2.7Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.46,232a146.23,146.23,0,0,0,6.39,152.67c2.31,3.49,3.61,6.19,3.21,8s-11.93,61.87-11.93,61.87a8,8,0,0,0,2.71,7.68A8.17,8.17,0,0,0,72,464a7.26,7.26,0,0,0,2.91-.6l56.21-22a15.7,15.7,0,0,1,12,.2c18.94,7.38,39.88,12,60.83,12A159.21,159.21,0,0,0,284,432.11"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserGroupIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M402,168c-2.93,40.67-33.1,72-66,72s-63.12-31.32-66-72c-3-42.31,26.37-72,66-72S405,126.46,402,168Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M336,304c-65.17,0-127.84,32.37-143.54,95.41-2.08,8.34,3.15,16.59,11.72,16.59H467.83c8.57,0,13.77-8.25,11.72-16.59C463.85,335.36,401.18,304,336,304Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M200,185.94C197.66,218.42,173.28,244,147,244S96.3,218.43,94,185.94C91.61,152.15,115.34,128,147,128S202.39,152.77,200,185.94Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M206,306c-18.05-8.27-37.93-11.45-59-11.45-52,0-102.1,25.85-114.65,76.2C30.7,377.41,34.88,384,41.72,384H154"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CollabMenuIcon: SvgIcon = ({
  width = '20px',
  height = '20px',
  strokeColor = 'text-black',
  strokeWidth = 0.5,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`stroke-current ${strokeColor}`}
      d="M14 13a3.954 3.954 0 0 0 .142 1H9.858A3.954 3.954 0 0 0 10 13zm-3.5-4h3a2.486 2.486 0 0 1 1.945.949 3.992 3.992 0 0 1 .839-.547A3.485 3.485 0 0 0 13.5 8h-3a3.485 3.485 0 0 0-2.784 1.402 3.992 3.992 0 0 1 .84.547A2.486 2.486 0 0 1 10.5 9zM9 4a3 3 0 1 1 3 3 3.003 3.003 0 0 1-3-3zm1 0a2 2 0 1 0 2-2 2.002 2.002 0 0 0-2 2zM4.5 17h3a3.504 3.504 0 0 1 3.5 3.5V23H1v-2.5A3.504 3.504 0 0 1 4.5 17zm0 1A2.503 2.503 0 0 0 2 20.5V22h8v-1.5A2.503 2.503 0 0 0 7.5 18zM6 16a3 3 0 1 1 3-3 3.003 3.003 0 0 1-3 3zm0-1a2 2 0 1 0-2-2 2.002 2.002 0 0 0 2 2zm17 5.5V23H13v-2.5a3.504 3.504 0 0 1 3.5-3.5h3a3.504 3.504 0 0 1 3.5 3.5zm-1 0a2.503 2.503 0 0 0-2.5-2.5h-3a2.503 2.503 0 0 0-2.5 2.5V22h8zM21 13a3 3 0 1 1-3-3 3.003 3.003 0 0 1 3 3zm-1 0a2 2 0 1 0-2 2 2.002 2.002 0 0 0 2-2z"
    />
  </svg>
);

export const StethoscopeIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 0.5,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    className={`stroke-current ${strokeColor}`}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.843,8.669c-0.907-0.542-2.619-0.031-4.944,1.476c-1.507,0.977-2.618,1.269-3.215,0.846
		c-0.871-0.616-0.709-2.674-0.498-3.862h0.179c0.122,0,0.222-0.099,0.222-0.223V6.467C5.192,6,7.47,4.183,7.786,3.013
		c0.361-1.327-1.672-2.181-2.264-2.399c0.01-0.127-0.061-0.249-0.188-0.29l-0.22-0.072C4.967,0.204,4.809,0.285,4.76,0.433
		L4.63,0.831C4.582,0.978,4.663,1.138,4.811,1.185l0.222,0.072c0.08,0.026,0.161,0.011,0.228-0.028
		c0.742,0.266,2.06,0.957,1.884,1.609C6.907,3.713,4.932,5.367,4.121,5.984H3.71C2.9,5.366,0.924,3.713,0.687,2.838
		c-0.175-0.645,1.116-1.329,1.86-1.602c0.069,0.056,0.159,0.084,0.25,0.058l0.225-0.061c0.149-0.041,0.237-0.195,0.195-0.345
		l-0.11-0.404c-0.042-0.15-0.196-0.238-0.346-0.196l-0.225,0.06C2.416,0.381,2.34,0.487,2.333,0.604
		c-0.553,0.2-2.657,1.06-2.291,2.409C0.348,4.14,2.475,5.869,3.17,6.411v0.495c0,0.124,0.099,0.223,0.222,0.223h0.116
		c-0.205,1.189-0.429,3.543,0.79,4.406c0.296,0.212,0.646,0.316,1.051,0.316c0.767,0,1.731-0.381,2.913-1.146
		c2.975-1.928,3.998-1.606,4.241-1.462c0.187,0.11,0.27,0.305,0.249,0.576c-0.03,0.38-0.284,0.863-0.708,1.177
		c-0.249-0.324-0.641-0.535-1.08-0.535c-0.751,0-1.36,0.611-1.36,1.361s0.609,1.361,1.36,1.361s1.361-0.611,1.361-1.361
		c0-0.067-0.007-0.133-0.016-0.198c0.608-0.394,1.053-1.076,1.106-1.753C13.456,9.348,13.247,8.909,12.843,8.669z M10.963,12.517
		c-0.383,0-0.694-0.313-0.694-0.695s0.312-0.694,0.694-0.694c0.216,0,0.405,0.101,0.533,0.255c0.14,0.115,0.106,0.252,0.159,0.431
		c0,0.003,0.003,0.005,0.003,0.009C11.659,12.204,11.347,12.517,10.963,12.517z"
      />
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const CloudIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M400,240c-8.89-89.54-71-144-144-144-69,0-113.44,48.2-128,96C68,198,16,235.59,16,304c0,66,54,112,120,112H396c55,0,100-27.44,100-88C496,268.18,443,242.24,400,240Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CreditIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 0.5,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      strokeWidth={strokeWidth}
      d="M11,16.755V19H9v-2.143c-1.712-0.1-3.066-0.589-4.241-1.797l1.718-1.74c0.859,0.87,2.023,1.16,3.282,1.16  c1.565,0,2.405-0.599,2.405-1.702c0-0.483-0.133-0.889-0.42-1.16c-0.267-0.251-0.572-0.387-1.202-0.483L8.9,10.903  c-1.164-0.174-2.022-0.541-2.634-1.141C5.618,9.105,5.293,8.216,5.293,7.055c0-2.155,1.382-3.743,3.707-4.1V1h2v1.932  c1.382,0.145,2.465,0.62,3.415,1.551l-1.679,1.682c-0.859-0.832-1.889-0.947-2.787-0.947c-1.412,0-2.099,0.792-2.099,1.74  c0,0.348,0.115,0.716,0.401,0.986c0.267,0.252,0.706,0.464,1.26,0.541l1.602,0.232c1.241,0.174,2.023,0.522,2.596,1.063  c0.726,0.696,1.05,1.702,1.05,2.92C14.759,14.95,13.192,16.362,11,16.755z"
    />
  </svg>
);

export const StarOIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 0.5,
  fillColor = 'blue',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"
    />
  </svg>
);

export const StarIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 0.5,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
    />
  </svg>
);

const DoctorTherapistDuplicateSvg: FC = () => {
  return (
    <>
      <path
        d="M37 38H49V49.9289C49 51.255 49.5268 52.5268 50.4645 53.4645L52 55L46 69H40L34 55L35.5355 53.4645C36.4732 52.5268 37 51.255 37 49.9289V38Z"
        fill="#EAEFFD"
        stroke="#2E62EC"
        strokeWidth="2"
      />
      <path
        d="M14.7611 62.9558C15.5166 59.9337 17.9576 57.625 21.017 57.0389L34 54.5518L41.3379 65.5164C42.1299 66.6999 43.8701 66.6999 44.6621 65.5164L52 54.5518L64.983 57.0389C68.0424 57.625 70.4834 59.9337 71.2389 62.9558L74.7575 77.0299C75.3886 79.5545 73.4792 82 70.8769 82H15.1231C12.5208 82 10.6114 79.5545 11.2425 77.0299L14.7611 62.9558Z"
        fill="white"
        stroke="#2E62EC"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8885 17C19.7788 17.3882 19.6894 17.7857 19.6218 18.1916L16.9551 34.1916C15.8376 40.8965 21.0081 47 27.8055 47H58.1962C64.9936 47 70.1641 40.8965 69.0466 34.1916L66.3799 18.1916C66.3123 17.7857 66.2229 17.3882 66.1132 17H64.0158C64.1878 17.4875 64.3196 17.9955 64.4071 18.5204L67.0738 34.5204C67.9881 40.0062 63.7577 45 58.1962 45H27.8055C22.244 45 18.0136 40.0062 18.9279 34.5204L21.5946 18.5204C21.6821 17.9955 21.8139 17.4875 21.9859 17H19.8885Z"
        fill="#2E62EC"
      />
      <path
        d="M27 15H59V34C59 42.8366 51.8366 50 43 50C34.1634 50 27 42.8366 27 34V15Z"
        fill="white"
        stroke="#2E62EC"
        strokeWidth="2"
      />
      <path
        d="M43 13C43 11.3431 41.6569 10 40 10H30.4713C25.5829 10 21.411 13.5341 20.6073 18.356L19.5 25H32C38.0751 25 43 20.0751 43 14V13Z"
        fill="white"
        stroke="#2E62EC"
        strokeWidth="2"
      />
      <path
        d="M43 13C43 11.3431 44.3431 10 46 10H55.5287C60.4171 10 64.589 13.5341 65.3927 18.356L66.5 25H54C47.9249 25 43 20.0751 43 14V13Z"
        fill="white"
        stroke="#2E62EC"
        strokeWidth="2"
      />
      <path
        d="M43 33L45.6382 38.2764C45.8044 38.6088 45.5627 39 45.191 39H42"
        stroke="#2E62EC"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.5 82V70.5"
        stroke="#2E62EC"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </>
  );
};

export const DoctorIcon: SvgIcon = ({width = 86, height = 92}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 86 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={width}
      height={height}
      fill="#2E62EC"
      fillOpacity="0.1"
      rx="20"
    />
    <DoctorTherapistDuplicateSvg />
    <path
      d="M63.5 82V70.5"
      stroke="#2E62EC"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M64 62.4999C64 56.8374 57.6745 52.0543 49 50.5176M22 62.4999C22 56.8374 28.3255 52.0543 37 50.5176"
      stroke="#2E62EC"
      strokeWidth="2"
    />
    <circle cx="22" cy="65" r="3" stroke="#2E62EC" strokeWidth="2" />
    <circle
      cx="35"
      cy="31"
      r="5"
      fill="#EAEFFD"
      stroke="#2E62EC"
      strokeWidth="2"
    />
    <circle
      cx="51"
      cy="31"
      r="5"
      fill="#EAEFFD"
      stroke="#2E62EC"
      strokeWidth="2"
    />
    <path d="M40 31H46" stroke="#2E62EC" strokeWidth="2" />
    <path
      d="M69 75V67.5C69 64.4624 66.5376 62 63.5 62V62C60.4624 62 58 64.4624 58 67.5V75"
      stroke="#2E62EC"
      strokeWidth="2"
    />
  </svg>
);

export const TherapyIcon: SvgIcon = ({width = 86, height = 92}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 86 92"
  >
    <rect
      width={width}
      height={height}
      rx="20"
      fill="#2E62EC"
      fillOpacity="0.1"
    />
    <DoctorTherapistDuplicateSvg />
    <path
      d="M48 72C48 70.8954 48.8954 70 50 70H57C58.1046 70 59 70.8954 59 72V76C59 77.1046 58.1046 78 57 78H50C48.8954 78 48 77.1046 48 76V72Z"
      fill="#EAEFFD"
      stroke="#2E62EC"
      strokeWidth="2"
    />
    <path
      d="M63.5 82V70.5"
      stroke="#2E62EC"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
