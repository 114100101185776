export enum VerificationStatusEnum {
  profileFormPending = 'Profile Form Pending',
  sendContractIndependentProvider = 'Send Contract (Independent Provider)',
  sendProfileForm = 'Send Profile Form',
  clean = 'Clean',
  notInterested = 'Not interested',
  verify = 'Verify',
  contractSigned = 'Contract Signed',
  notClean = 'Not clean',
  signaturePending = 'Signature Pending',
  sendContractAssociateProvider = 'Send Contract (Associate Provider)',
  sendProfileFormAssociateProvider = 'Send Profile Form (Associate Provider)',
  sendProfileFormIndependentProvider = 'Send Profile Form (Independent Provider)',
  npi = 'NPI',
  unsure = 'Unsure',
  notAccepting = 'Not Accepting',
  missingDetails = 'Missing details',
  signatureFollowUpOne = 'Signature Follow Up 1',
  signatureFollowUpTwo = 'Signature Follow Up 2',
  signatureFollowUpThree = 'Signature Follow Up 3',
  lastCall = 'Last Call',
  unresponsive = 'Unresponsive',
}

export enum FormSubmissionStatus {
  submitted = 'submitted',
  notSubmitted = 'not-submitted',
  skipped = 'skipped',
  active = 'active',
}

export enum OnboardingVideoStatus {
  completed = 'completed',
  notStarted = 'not-started',
  ongoing = 'ongoing',
}
