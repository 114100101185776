import RateAudioVideo from './RateAudioVideo';
import RateProvider from './RateProvider';

// import Feedback from './Feedback'

export const formSteps = {
  1: RateAudioVideo,
  2: RateProvider,
  //2: Feedback,
};
