import React, {MouseEventHandler, ReactNode, useRef} from 'react';

import {TStylesConfig} from '../models';

import {Backdrop} from './Backdrop';
import {Menu} from './Menu';

type DropdownContainerProps = {
  children: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: MouseEventHandler;
  styles: TStylesConfig;
  isMulti?: boolean;
  required?: boolean;
  fullScreen?: boolean;
  placeholder?: string;
  className?: string;
  portalContainer?: Element | DocumentFragment;
};

export const DropdownContainer = ({
  children,
  isOpen,
  target,
  onClose,
  isMulti,
  required,
  fullScreen,
  styles,
  className,
  placeholder,
  portalContainer,
}: DropdownContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={className ?? 'relative'}>
      {target}
      {isOpen ? (
        <Menu
          fullScreen={fullScreen}
          placeholder={placeholder}
          styles={styles}
          onCloseClick={onClose}
          containerRef={containerRef}
          portalContainer={portalContainer}
        >
          {isMulti && !required ? (
            <div className="m-3 text-gray-600 text-xs">OPTIONAL</div>
          ) : null}
          {children}
        </Menu>
      ) : null}
      {isOpen ? <Backdrop onClick={onClose} /> : null}
    </div>
  );
};
