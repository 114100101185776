import * as Yup from 'yup';

export const ProviderOptionSchema = Yup.object().shape({
  selectedProvider: Yup.string().when('selectedCountry', {
    is: 'US',
    then: Yup.string().required('Please select a Provider'),
    otherwise: Yup.string(),
  }),
  selectedLicenseType: Yup.string().when('selectedCountry', {
    is: 'US',
    then: Yup.string().required('Please select a License Type'),
    otherwise: Yup.string(),
  }),
  email: Yup.string()
    .trim()
    .required('Required')
    .email('Invalid email')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!'),
  password: Yup.string()
    .required('Required')
    .min(8, 'Too Short!')
    .max(50, 'Too Long!'),
  selectedCountry: Yup.string().required('Required'),
});
