import React, {FC} from 'react';
import Doctor from 'assets/images/doctor.png';
import {MobileTabletHeader} from 'components';
import {selectUserProfile} from 'features/User';
import {UserRoles} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import styled from 'styled-components';
import {isMember} from 'utils';

import LogoutLink from './LogoutLink';
import {RightNavMenuItems} from './navigationConstants';
import {NavLink} from './NavLink';
import UserInfo from './UserInfo';

const NavigationContainer = styled.section`
  height: calc(100% - 4rem);
  @media only screen and (max-width: 768px) {
    height: calc(100% - 6rem);
  }
`;
const SettingsNavigation: FC<{closeSettings: () => void}> = ({
  closeSettings,
}) => {
  const location = useLocation();
  const {pathname} = location;
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();

  return (
    <section className="fixed z-50 flex inset-0 lg:hidden">
      <section className="absolute inset-y-0 w-screen  h-screen right-0">
        <MobileTabletHeader
          headerTitle={t('account')}
          backButton
          backButtonFn={closeSettings}
        />
        <NavigationContainer className="flex flex-col border-t pb-4 sm:pb-0  overflow-y-auto  bg-white">
          <UserInfo />
          <section className="flex-1 flex flex-col">
            {isMember(user) ? (
              <nav className="lg:border-t">
                {RightNavMenuItems(pathname)[UserRoles.member].map(
                  (
                    {
                      pathname,
                      linkName,
                      first,
                      last,
                      current,
                      classNames,
                      icon: Icon,
                    },
                    idx,
                  ) => (
                    <NavLink
                      pathname={pathname}
                      first={first}
                      last={last}
                      classNames={classNames}
                      current={current}
                      linkName={t(`rightNavigation.${linkName.toLowerCase()}`)}
                      key={idx}
                    >
                      <Icon
                        strokeColor={
                          pathname === '/share'
                            ? 'text-white'
                            : current
                            ? 'text-blue-600'
                            : undefined
                        }
                      />
                    </NavLink>
                  ),
                )}
                <LogoutLink />
              </nav>
            ) : user?.role === UserRoles.therapist ||
              user?.role === UserRoles.prescriber ? (
              <nav className="lg:border-t">
                {RightNavMenuItems(pathname)[user?.role].map(
                  (
                    {
                      pathname,
                      linkName,
                      first,
                      last,
                      current,
                      classNames,
                      icon: Icon,
                    },
                    idx,
                  ) => (
                    <NavLink
                      pathname={pathname}
                      first={first}
                      last={last}
                      classNames={classNames}
                      current={current}
                      linkName={t(`rightNavigation.${linkName.toLowerCase()}`)}
                      key={idx}
                    >
                      <Icon
                        strokeColor={
                          pathname === '/share'
                            ? 'text-white'
                            : current
                            ? 'text-blue-600'
                            : undefined
                        }
                      />
                    </NavLink>
                  ),
                )}

                <a
                  href="https://intercom.help/wellnite-health/en/"
                  className="group flex items-center py-3 md:py-6 text-xl pl-10 md:pl-24 text-gray-700 leading-snug border-b border-t"
                  target="_blank"
                >
                  <div>
                    <img src={Doctor} alt="Doctor_Image" className="h-5 w-5" />
                  </div>

                  <span className="pt-1 pl-5 text-lg">
                    {t('provider_resource_center')}
                  </span>
                </a>
                {/* Logout Link */}
                <LogoutLink />
              </nav>
            ) : null}
          </section>
        </NavigationContainer>
      </section>
    </section>
  );
};

export default SettingsNavigation;
