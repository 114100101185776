import {useMemo} from 'react';
import {RootState, StateSlices} from 'app/rootReducer';
import {makeSliceRequesting} from 'app/selectors';
import {SliceStatus} from 'interfaces';
import {useSelector} from 'react-redux';

export function useRequesting(filter: StateSlices): SliceStatus {
  const selectModelLoading = useMemo(makeSliceRequesting, []);
  return useSelector((state: RootState) => selectModelLoading(state, filter));
}
