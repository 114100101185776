import React, {FC} from 'react';
import classNames from 'classnames';
import {CalendarOutlineIcon} from 'components';
import {AppointmentDurations, AppointmentTypes} from 'interfaces';
import {useTranslation} from 'react-i18next';

import {useSessionTypes} from './useSessionTypes';

interface Props {
  onUpdateApptTypeAndDuration: (data: {
    type: AppointmentTypes;
    duration: AppointmentDurations;
  }) => void;
  appointmentDuration?: AppointmentDurations;
  appointmentType?: AppointmentTypes;
}

export const Durations: FC<Props> = ({
  onUpdateApptTypeAndDuration,
  appointmentDuration,
  appointmentType,
}) => {
  const {t} = useTranslation();
  const {getSessions} = useSessionTypes();

  return (
    <ul className="flex flex-col w-full">
      {getSessions(appointmentType, true)?.map((value, idx) => {
        return (
          <li key={+idx}>
            <button
              onClick={() => {
                onUpdateApptTypeAndDuration({
                  type: value.type,
                  duration: value.duration,
                });
              }}
              className={classNames(
                'group flex items-start rounded-md max-w-sm mx-auto px-4 py-3 border cursor-pointer',
                {
                  'mt-6': idx !== 0,
                  'bg-blue-600 border-blue-600':
                    appointmentDuration === value.duration,
                  'bg-gray-50 hover:bg-blue-600 hover:border-blue-600':
                    appointmentDuration !== value.duration,
                },
              )}
            >
              <div className="w-2/12">
                <span
                  className={classNames(
                    'w-10 h-10 bg-gray-200 group-hover:bg-blue-400 group-hover:bg-opacity-20 rounded-full flex justify-center items-center',
                    {
                      'bg-blue-400 bg-opacity-20':
                        appointmentDuration === value.duration,
                    },
                  )}
                >
                  <CalendarOutlineIcon
                    classes={classNames(
                      'fill-current text-black group-hover:text-white',
                      {
                        'text-white': appointmentDuration === value.duration,
                      },
                    )}
                  />
                </span>
              </div>
              <div className="w-5/6 text-left">
                <p
                  className={classNames('font-medium group-hover:text-white', {
                    'text-white': appointmentDuration === value.duration,
                  })}
                >
                  {t(value.label)}
                </p>
                <p
                  className={classNames('text-xs leading-4 mt-2', {
                    'text-gray-300': appointmentDuration === value.duration,
                    'text-gray-600 group-hover:text-gray-300':
                      appointmentDuration !== value.duration,
                  })}
                >
                  {value?.desc}
                </p>
              </div>
            </button>
          </li>
        );
      })}
    </ul>
  );
};
