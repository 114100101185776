import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {AirtableProviderService} from 'services/api/Airtable';

import {airtableActions} from './airtableSlice';

export const getAirtableProviderDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(airtableActions.getAirtableProviderData.match),
    switchMap(({payload}) =>
      from(AirtableProviderService.getAirtableProviderData()).pipe(
        mergeMap(({data: {data}}) => [
          airtableActions.getAirtableProviderDataSuccess(data),
          userActions.checkSession(payload),
        ]),
        catchError((message: string) =>
          concat(
            of(airtableActions.getAirtableProviderDataFailure()).pipe(
              tap(() => {
                Toast({type: 'error', message});
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const getAirtableProviderFieldsOptionsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(airtableActions.getAirtableProviderFieldsOptions.match),
    switchMap(() =>
      from(AirtableProviderService.getAirtableProviderFieldsOptions()).pipe(
        mergeMap(({data: {message}}) => [
          airtableActions.getAirtableProviderFieldsOptionsSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(airtableActions.getAirtableProviderFieldsOptionsFailure()).pipe(
              tap(() => {
                Toast({type: 'error', message});
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const getInsuranceAndColleagues: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(airtableActions.getInsuranceAndColleagues.match),
    switchMap(() =>
      from(AirtableProviderService.getInsuranceAndColleagues()).pipe(
        mergeMap(({data: {message}}) => [
          airtableActions.getInsuranceAndColleaguesSuccess(message),
        ]),
        catchError((message: string) =>
          concat(
            of(airtableActions.getInsuranceAndColleaguesFailure()).pipe(
              tap(() => {
                Toast({type: 'error', message});
              }),
            ),
          ),
        ),
      ),
    ),
  );

const updateAirtableProviderEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(airtableActions.updateAirtableProviderData.match),
    switchMap(({payload: {data, cb}}) =>
      from(AirtableProviderService.updateAirtableProviderCredential(data)).pipe(
        concatMap(() => [cb()]),
        mergeMap(() => [
          airtableActions.updateAirtableProviderDataSuccess(data),
        ]),
        catchError((message: string) =>
          concat(
            of(airtableActions.updateAirtableProviderDataFailure(message)),
          ),
        ),
      ),
    ),
  );

export const updateAirtableProviderSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(airtableActions.updateAirtableProviderDataSuccess.match),
    tap(() => {
      Toast({type: 'success', message: 'Your information has been updated.'});
    }),
    ignoreElements(),
  );

export const updateAirtableProviderFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(airtableActions.updateAirtableProviderDataFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const airtableProviderEpics = [
  getAirtableProviderDataEpic,
  getAirtableProviderFieldsOptionsEpic,
  getInsuranceAndColleagues,
  updateAirtableProviderEpic,
  updateAirtableProviderSuccessEpic,
  updateAirtableProviderFailureEpic,
].flatMap(epic => epic);
