import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useQuery} from 'hooks';
import Cookies from 'js-cookie';

export const useCookie = (): {
  referralSource: string;
  setReferralSource: Dispatch<SetStateAction<string>>;
} => {
  const [referralSource, setReferralSource] = useState('');
  const entityReferral = useQuery().get('utm_source') || '';
  const referralEntity = Cookies.get('referralEntity');

  const setReferralCookie = useCallback((entity: string) => {
    Cookies.set('referralEntity', entity, {
      expires: 2,
    });
    setReferralSource(entity);
  }, []);

  useEffect(() => {
    if (entityReferral) {
      setReferralCookie(entityReferral);
    }
  }, [entityReferral, setReferralCookie]);

  useEffect(() => {
    if (referralEntity) {
      setReferralSource(referralEntity);
    }
  }, [referralEntity]);

  return {
    referralSource,
    setReferralSource,
  };
};
