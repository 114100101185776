import styled from 'styled-components';

export const BookingCalendarContainer = styled.section`
  .DayPicker {
    width: 100%;
  }

  .DayPicker-Day--today {
    color: ${props => props.theme.colors.blue[600]};
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    background-color: #eff1f1;
  }
  &&&& .DayPicker-Month {
    width: 100%;
    margin: 0px 0px;
  }
  &&&& .DayPicker-Day {
    padding: 1rem;
  }
  .DayPicker-Caption > div {
    font-size: 0.75rem;
    padding: 0px;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  .DayPicker-NavButton {
    top: 0px;
    right: 0px;
  }
  .DayPicker-Day--disabled {
    background: transparent;
  }
  .DayPicker-Day.DayPicker-Day--selected {
    background-color: ${props => props.theme.colors.blue[600]};

    &:hover {
      background-color: ${props => props.theme.colors.blue[600]};
    }
  }
`;

export const GroupBookingCalendarContainer = styled.section`
  .DayPicker {
    width: 100%;
    background-color: ${props => props.theme.colors.gray[200]};
    padding: 1rem;
  }

  .DayPicker-Day--today {
    color: ${props => props.theme.colors.blue[600]};
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    background-color: #eff1f1;
  }
  &&&& .DayPicker-Month {
    width: 100%;
    margin: 0px 0px;
  }
  &&&& .DayPicker-Day {
    padding: 1rem;
  }
  .DayPicker-Caption > div {
    font-size: 1rem;
    color: #6b6b6b;
    padding: 0px;
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }
  .DayPicker-NavButton {
    top: 0px;
    right: 0px;
  }
  .DayPicker-Day--disabled {
    background: transparent;
  }
  .DayPicker-Day.DayPicker-Day--selected {
    background-color: ${props => props.theme.colors.blue[600]};

    &:hover {
      background-color: ${props => props.theme.colors.blue[600]};
    }
  }
`;

export const AppointmentCalendarContainer = styled.section`
  .DayPicker {
    background-color: ${props => props.theme.colors.gray[200]};
    width: 100%;
  }
  .DayPicker-Day--today {
    color: #fff;
    background-color: ${props => props.theme.colors.blue[600]};

    &:hover {
      color: #000;
    }
  }

  .DayPicker-Day.DayPicker-Day--selected {
    outline: ${props => `2px solid ${props.theme.colors.blue[600]}`};
    border-radius: 0;
    background-color: transparent;
    color: black;

    &:hover {
      background-color: transparent;
    }
  }

  .DayPicker-Day.DayPicker-Day--appointments {
    background-color: transparent;
    color: black;
    padding-bottom: 0;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      background-color: transparent;
    }

    &:after {
      display: block;
      content: '\u25CF';
      font-size: 9px;
      color: ${props => props.theme.colors.blue[600]};
      white-space: pre-wrap;
    }
  }
`;
