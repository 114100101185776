import {statesInUSA} from 'definitions/States';
import {
  AcuityLabelsId,
  AppointmentDurationTypes,
  Label,
  ModelRemarks,
  UserRoles,
} from 'interfaces';

export function isBillable(
  acuityLabel: Label,
  modelRemark: string[] = [],
  memberType: string,
  appointmentType: string,
  canceled: boolean,
  duration: string,
  userRole: UserRoles,
  isInitialTherapyBillable?: boolean,
): String {
  let _acuityLabel;
  let _modelRemark;
  memberType = memberType.toLowerCase();
  appointmentType = appointmentType.toLowerCase();

  const isBillableLabel = (label: Label) => {
    if (
      [
        AcuityLabelsId.completed,
        AcuityLabelsId.canceled3hrs,
        AcuityLabelsId.dontChargeNoShow,
        AcuityLabelsId.freeSession,
        AcuityLabelsId.innetwork,
        AcuityLabelsId.mSmessup,
        AcuityLabelsId.noShow,
        AcuityLabelsId.paperworkAppt,
        AcuityLabelsId.techIssue,
        AcuityLabelsId.techIssueFree,
        AcuityLabelsId.dpInNetworkCompleted,
      ].includes(label.id)
    ) {
      return 'a';
    }
    if (
      [
        AcuityLabelsId.providerCancel,
        AcuityLabelsId.providerFree,
        AcuityLabelsId.providerMissed,
        AcuityLabelsId.rescheduled,
      ].includes(label.id) ||
      canceled
    ) {
      return 'b';
    }
    return 'Invalid acuity label';
  };

  const isMatchingDuration = (
    appType: AppointmentDurationTypes | string,
    dur: string,
  ): AppointmentDurationTypes | string => {
    if (appType === AppointmentDurationTypes.prescriber10 && dur === '10') {
      return AppointmentDurationTypes.prescriber10;
    }
    if (appType === AppointmentDurationTypes.prescriber15 && dur === '15') {
      return AppointmentDurationTypes.prescriber15;
    }
    if (appType === AppointmentDurationTypes.prescriber30 && dur === '30') {
      return AppointmentDurationTypes.prescriber30;
    }
    if (
      appType === AppointmentDurationTypes.prescriber45 &&
      (dur === '30' || dur === '45')
    ) {
      return AppointmentDurationTypes.prescriber45;
    }
    return 'Non-matching Duration';
  };

  const isAcuityAppointmentTypeMatchingDuration = (appType: string) => {
    let appointType = '';
    if (
      statesInUSA.map(states => states.toLowerCase()).includes(appType) ||
      appType === 'St Jude Refill Appointment'.toLowerCase()
    ) {
      appointType = AppointmentDurationTypes.prescriber10;
    } else if (appType === 'St Jude Doctor Appointment'.toLowerCase()) {
      appointType = AppointmentDurationTypes.prescriber30;
    } else if (appType === 'SI evaluation/assessment'.toLowerCase()) {
      appointType = AppointmentDurationTypes.prescriber15;
    } else if (appType === 'Doctor Consultation'.toLowerCase()) {
      appointType = AppointmentDurationTypes.prescriber45;
    } else {
      return 'Invalid appointment type';
    }
    return isMatchingDuration(appointType, duration);
  };

  switch (userRole) {
    case UserRoles.therapist:
      _acuityLabel = isBillableLabel(acuityLabel);
      if (memberType === 'scale') {
        memberType = 'e';
      } else if (memberType === 'wellnite') {
        memberType = 'f';
      } else {
        return 'Invalid member type';
      }
      if (appointmentType.includes('initial therapy')) {
        appointmentType = 'g';
      } else {
        appointmentType = 'h';
      }

      if (
        modelRemark.includes(ModelRemarks.Old_40_60) ||
        modelRemark.includes(ModelRemarks.StJude)
      ) {
        _modelRemark = 'c';
      } else if (
        modelRemark.includes(ModelRemarks.Scale) ||
        modelRemark.includes(ModelRemarks.Hybrid) ||
        modelRemark.includes(ModelRemarks.Combo) ||
        modelRemark.includes(ModelRemarks.ComboAssociates)
      ) {
        _modelRemark = 'd';
      } else {
        return 'Invalid model remark';
      }
      if (
        _acuityLabel === 'a' &&
        _modelRemark === 'c' &&
        memberType === 'e' &&
        appointmentType === 'g'
      )
        return 'billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'c' &&
        memberType === 'e' &&
        appointmentType === 'h'
      )
        return 'billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'c' &&
        memberType === 'f' &&
        appointmentType === 'g'
      )
        return 'billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'c' &&
        memberType === 'f' &&
        appointmentType === 'h'
      )
        return 'billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'd' &&
        memberType === 'e' &&
        appointmentType === 'g'
      )
        return 'billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'd' &&
        memberType === 'e' &&
        appointmentType === 'h'
      )
        return 'billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'd' &&
        memberType === 'f' &&
        appointmentType === 'g' &&
        isInitialTherapyBillable
      )
        return 'billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'd' &&
        memberType === 'f' &&
        appointmentType === 'g' &&
        !isInitialTherapyBillable
      )
        return 'non-billable';
      else if (
        _acuityLabel === 'a' &&
        _modelRemark === 'd' &&
        memberType === 'f' &&
        appointmentType === 'h'
      )
        return 'billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'c' &&
        memberType === 'e' &&
        appointmentType === 'g'
      )
        return 'billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'c' &&
        memberType === 'e' &&
        appointmentType === 'h'
      )
        return 'non-billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'c' &&
        memberType === 'f' &&
        appointmentType === 'g'
      )
        return 'non-billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'c' &&
        memberType === 'f' &&
        appointmentType === 'h'
      )
        return 'non-billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'd' &&
        memberType === 'e' &&
        appointmentType === 'g'
      )
        return 'non-billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'd' &&
        memberType === 'e' &&
        appointmentType === 'h'
      )
        return 'non-billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'd' &&
        memberType === 'f' &&
        appointmentType === 'g'
      )
        return 'non-billable';
      else if (
        _acuityLabel === 'b' &&
        _modelRemark === 'd' &&
        memberType === 'f' &&
        appointmentType === 'h'
      )
        return 'non-billable';
      else return 'contact team';

    case UserRoles.prescriber:
      const labelMatcher = isBillableLabel(acuityLabel);
      const appointmentTypeMatcher =
        isAcuityAppointmentTypeMatchingDuration(appointmentType);
      if (
        labelMatcher === 'a' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber10
      ) {
        return 'billable';
      }
      if (
        labelMatcher === 'b' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber10
      ) {
        return 'non-billable';
      }
      if (
        labelMatcher === 'a' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber15
      ) {
        return 'billable';
      }
      if (
        labelMatcher === 'b' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber15
      ) {
        return 'non-billable';
      }
      if (
        labelMatcher === 'a' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber30
      ) {
        return 'billable';
      }
      if (
        labelMatcher === 'b' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber30
      ) {
        return 'non-billable';
      }
      if (
        labelMatcher === 'a' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber45
      ) {
        return 'billable';
      }
      if (
        labelMatcher === 'b' &&
        appointmentTypeMatcher === AppointmentDurationTypes.prescriber45
      ) {
        return 'non-billable';
      }
      return labelMatcher;
    default:
      return 'Contact team';
  }
}
