import {paymentCheckoutEpics} from './Checkout/checkoutEpics';
import {paymentCardsEpics} from './CreditCardMgmt/creditCardMgmtEpics';
import {paymentCurrentPlanEpics} from './CurrentPlan/currentPlanEpics';
import {digitalPracticeCheckoutEpics} from './DigitalPracticeCheckout/DigitalPracticeCheckoutEpics';
import {manageSubscriptionEpics} from './ManageSubscription/manageSubscriptionEpics';
import {paymentonboardingOneTimePaymentEpics} from './OnboardingOneTimePayment/onboardingOneTimePaymentEpics';
import {receiptEpics} from './Receipts/receiptEpics';
import {paymentResubscriptionEpics} from './ResubscriptionCheckout/resubscriptionCheckoutEpics';
import {paymentUpdatePlanEpics} from './UpdatePlan/updatePlanEpics';

export const paymentEpics = [
  paymentCheckoutEpics,
  paymentCardsEpics,
  paymentUpdatePlanEpics,
  paymentCurrentPlanEpics,
  paymentResubscriptionEpics,
  manageSubscriptionEpics,
  paymentonboardingOneTimePaymentEpics,
  digitalPracticeCheckoutEpics,
  receiptEpics,
].flatMap(epic => epic);
