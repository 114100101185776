import {useEffect, useState} from 'react';
import {paymentActions} from 'features/Payment';
import {DigitalPracticeAccountTypes, UserRoles} from 'interfaces';
import {useDispatch} from 'react-redux';

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import {useError} from './useError';
import {useQuery} from './useQuery';
import {useToggle} from './useToggle';

export function useDigitalPracticeCheckout() {
  const digitalPracticeAccountType = useQuery().get(
    'digitalPracticeAccountType',
  ) as DigitalPracticeAccountTypes;

  const companyName = useQuery().get('companyName') ?? '';
  const employerName = useQuery().get('employerName') ?? '';

  const [couponCode, setCouponCode] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const {toggleOn, onToggleClick} = useToggle();
  const [errorMsg, setErrorMsg] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const {apiError, resetAsyncError} = useError('payment');

  const handleCouponChange: React.ChangeEventHandler<HTMLInputElement> = e =>
    setCouponCode(e.currentTarget.value);
  const handleCardHolderNameChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => setCardHolderName(e.currentTarget.value);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    // Abort if form isn't valid
    if (!e.currentTarget.reportValidity()) return;
    onToggleClick();
    const cardElement = elements?.getElement(CardNumberElement);
    if (cardElement && stripe) {
      const stripeResponse = await stripe.createToken(cardElement, {
        name: cardHolderName,
      });
      if (stripeResponse?.error || !stripeResponse?.token) {
        setErrorMsg(
          stripeResponse.error?.message ?? 'Failed to generate token',
        );
      } else {
        if (
          digitalPracticeAccountType === DigitalPracticeAccountTypes.outOfPocket
        ) {
          dispatch(
            paymentActions.digitalPracticeOutOfPocketCheckout({
              token: stripeResponse.token,
              providerRole: UserRoles.therapist,
              digitalPracticeAccountType:
                DigitalPracticeAccountTypes.outOfPocket,
            }),
          );
        } else {
          dispatch(
            paymentActions.digitalPracticeInsuranceCheckout({
              token: stripeResponse.token,
              companyName: companyName,
              employerName: employerName,
            }),
          );
        }
      }
    }
    onToggleClick();
  };

  useEffect(() => {
    setErrorMsg(apiError);
    return (): void => {
      if (apiError) dispatch(resetAsyncError('payment'));
    };
  }, [apiError, dispatch, resetAsyncError]);

  return {
    isCreatingToken: toggleOn,
    errorMsg,
    handleSubmit,
    couponCode,
    handleCouponChange,
    cardHolderName,
    handleCardHolderNameChange,
  };
}
