import React, {FC, useEffect, useState} from 'react';
import classNames from 'classnames';
import {Button} from 'components';
import dayjs from 'dayjs';
import {selectUserProfile} from 'features/User/userSelectors';
import {
  AppointmentDurations,
  AppointmentTypes,
  MemberProfile,
} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import RecurringBookingCard from './RecurrinBookingV2/RecurringBookingCard';
import {Durations} from './AppointmentDurations2';
import MemberBookingForm from './MemberBookingForm';
import {useAppointmentTypes} from './useAppointmentTypes';
import {useSessionTypes} from './useSessionTypes';

type Props = {
  appointmentType: AppointmentTypes;
  member: MemberProfile;
  setShowBookingModal?: React.Dispatch<React.SetStateAction<boolean>>;
  footerRef?: React.RefObject<HTMLDivElement>;
  selectedDate?: dayjs.Dayjs;
  defaultAppointmentTypeTab?: AppointmentTypes;
};

export const BookAppointment: FC<Props> = ({
  appointmentType,
  member,
  setShowBookingModal,
  footerRef,
  selectedDate,
  defaultAppointmentTypeTab,
}) => {
  const {getSessions} = useSessionTypes();
  const appointmentTypes = useAppointmentTypes(appointmentType);
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();

  const [currAppointment, setCurrAppointment] = useState<{
    duration: AppointmentDurations;
    type: AppointmentTypes;
  }>({
    type: appointmentTypes[0],
    duration: getSessions(appointmentTypes[0])[0]?.duration,
  });

  const onUpdateApptTypeAndDuration = (data: {
    type: AppointmentTypes;
    duration: AppointmentDurations;
  }) => {
    setCurrAppointment(data);
  };

  const onTabClick = (supportedTypes: AppointmentTypes[]) => {
    const selectedAppt = appointmentTypes.find(type =>
      supportedTypes.includes(type),
    );

    if (selectedAppt) {
      setCurrAppointment({
        type: selectedAppt,
        duration: getSessions(selectedAppt)[0].duration,
      });
    }
  };

  useEffect(() => {
    if (defaultAppointmentTypeTab) {
      onTabClick([defaultAppointmentTypeTab]);
    }
  }, [defaultAppointmentTypeTab]);

  const renderAppointmentTypeButton = (
    supportedTypes: AppointmentTypes[],
    text: string,
  ) => {
    const selected = supportedTypes.includes(currAppointment?.type);
    return (
      <Button
        btnType="custom"
        className={classNames(
          'group flex items-start max-w-sm px-4 py-2 cursor-pointer font-inter',
          {
            'text-gray-500': !selected,
            'text-primary': selected,
          },
        )}
        outline
        bgColor={selected ? 'primary/10' : 'white'}
        borderRadius="full"
        textColor="auto"
        borderColor="transparent"
        onClick={() => onTabClick(supportedTypes)}
      >
        <p className={classNames('font-medium')}>{text}</p>
      </Button>
    );
  };

  const showBookingForm = () => {
    if (
      currAppointment?.type &&
      currAppointment?.duration &&
      currAppointment.type.includes('recurring')
    )
      return (
        <RecurringBookingCard
          member={member}
          duration={currAppointment.duration}
          onCancel={() =>
            setShowBookingModal ? setShowBookingModal(false) : null
          }
          footerRef={footerRef}
        />
      );
    if (
      currAppointment?.type &&
      currAppointment?.duration &&
      !currAppointment.type.includes('recurring')
    )
      return (
        <MemberBookingForm
          selectedDate={selectedDate}
          appointmentType={currAppointment.type}
          member={member}
          duration={currAppointment.duration}
          onCancel={() =>
            setShowBookingModal ? setShowBookingModal(false) : null
          }
          onSuccess={() =>
            setShowBookingModal ? setShowBookingModal(false) : null
          }
          footerRef={footerRef}
        />
      );

    return null;
  };

  return (
    <section className="w-full py-6">
      {appointmentType !== AppointmentTypes.doctor_consultation && (
        <div className="flex flex-row items-center justify-between mb-6">
          <ul className="flex flex-row">
            {renderAppointmentTypeButton(
              [
                AppointmentTypes.video_call_with_therapist,
                AppointmentTypes.chat_with_coach,
              ],
              t('single'),
            )}
            {user?.therapist_Id
              ? renderAppointmentTypeButton(
                  [AppointmentTypes.recurring_session],
                  t('recurring'),
                )
              : null}
          </ul>

          <Durations
            onUpdateApptTypeAndDuration={onUpdateApptTypeAndDuration}
            appointmentDuration={currAppointment?.duration}
            appointmentType={currAppointment.type}
          />
        </div>
      )}
      {showBookingForm()}
      <p className="bg-[#ffcb58]/[.15] text-sm rounded-xl p-5 text-center mt-4 text-gray-600">
        {getSessions(currAppointment.type)[0].desc}
      </p>
    </section>
  );
};
