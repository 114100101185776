import {
  Appointment,
  AppointmentLabels,
  EndPoints,
  GroupCallAppointmentLabelReqData,
  HttpMethods,
  RequestPaymentForAppointment,
  UserRoles,
} from 'interfaces';
import {Meeting} from 'interfaces/Meeting.types';
import {Response} from 'redaxios';
import {objectToQueryParams, unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';

const getAppointments = async (): Promise<
  Response<{message: {appointments: Appointment[]}}>
> => {
  try {
    const res = await requestHandler<{
      message: {appointments: Appointment[]};
    }>({
      method: HttpMethods.GET,
      url: EndPoints.GetAppointments,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getMeetingDetails = async (data: {
  appointmentID: number;
  providerId: string;
  providerType: UserRoles;
}): Promise<Response<{message: {message: Meeting}}>> => {
  const {providerType, ...rest} = data;
  const queryParams = objectToQueryParams(rest);
  try {
    const res = await requestHandler<{
      message: {message: Meeting};
    }>({
      method: HttpMethods.GET,
      url: `/api/${providerType}/${EndPoints.GetMeetingDetails}?${queryParams}` as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getProviderAppointments = async (
  yearAndMonth: string,
  userRole: 'therapist' | 'prescriber',
): Promise<Response<{message: {appointments: Appointment[]}}>> => {
  try {
    const res = await requestHandler<{
      message: {appointments: Appointment[]};
    }>({
      method: HttpMethods.GET,
      url: `/api/${userRole}/${EndPoints.GetProviderAppointments}&yearAndMonth=${yearAndMonth}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateGroupCallAppointmentLabelByID = async (
  data: GroupCallAppointmentLabelReqData,
): Promise<Response<{message: AppointmentLabels[]}>> => {
  try {
    const res = await requestHandler<{
      message: AppointmentLabels[];
    }>({
      method: HttpMethods.POST,
      url: `/api/${UserRoles.member}/${EndPoints.UpdateGroupCallAppintmentLabel}` as unknown as EndPoints,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const fetchDigitalPracticeAppointmentDetails = async (
  appointmentID: string,
): Promise<Response<{message: RequestPaymentForAppointment}>> => {
  try {
    const res = await requestHandler<{
      message: RequestPaymentForAppointment;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.fetchDPAppointmentDetails}?appointmentID=${appointmentID}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const AppointmentService = {
  getAppointments,
  getProviderAppointments,
  getMeetingDetails,
  updateGroupCallAppointmentLabelByID,
  fetchDigitalPracticeAppointmentDetails,
};
