import {useCallback, useEffect, useMemo, useState} from 'react';
import {RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {addDays, format} from 'date-fns';
import {
  selectAppointmentStatus,
  selectAvailabilitySlots,
} from 'features/Appointment';
import {
  bookRecurringAppointment,
  getProviderRecurringAvailableSlots,
  resetProviderAvailableSlots,
} from 'features/Appointment/Booking/bookingActions';
import {providerActions, selectProvider} from 'features/Provider';
import {
  selectAllMembers,
  selectHasMoreMembers,
  selectMemberById,
} from 'features/Provider/Members/membersSelectors';
import {selectUserProfile} from 'features/User';
import {
  AppointmentDurations,
  AppointmentTypes,
  OptionType,
  SliceStatus,
} from 'interfaces';
import debounce from 'lodash.debounce';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {BookingService} from 'services';
import {
  calculateStartDate,
  convertTimeToDiffTimeZone,
  isProvider,
  isTherapist,
} from 'utils';

import {OrderMembersBy} from '../services/api/Provider';

import {useRequesting} from './useRequesting';
import {useToggle} from './useToggle';

const Members_Limit = 20;

export function useRecurringBooking() {
  const user = useSelector(selectUserProfile);
  const memberTimezone = BookingService.isCountryOfResidenceRegion(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    user!.countryOfResidence.code,
  )
    ? Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
    : BookingService.getCountryDefaultTimeZone(user!.countryOfResidence.code);

  const defaultOption: OptionType = {value: '30', label: '30 mins'};
  const defaultOptionRecurrence: OptionType = {value: '6', label: '6 weeks'};
  const [duration, setDuration] = useState<OptionType>(defaultOption);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [recurrence, setRecurrence] = useState<OptionType>(
    defaultOptionRecurrence,
  );
  const [noteMessage, setNoteMessage] = useState('');

  const [bookingCharge, setBookingCharge] = useState({
    status: SliceStatus.idle,
    showModal: false,
    amount: 0,
  });

  const availabilitySlots = useSelector(selectAvailabilitySlots);
  const availabilitySlotsToMemberTimezone = availabilitySlots?.slots?.map(
    time => convertTimeToDiffTimeZone(time, memberTimezone),
  );
  const appointmentStatus = useSelector(selectAppointmentStatus);

  const dispatch = useDispatch();

  const isLoading = availabilitySlots.loading === SliceStatus.pending;
  const isLoadingBooking =
    appointmentStatus === SliceStatus.pending ||
    bookingCharge.status === SliceStatus.pending;

  const availabilityRequestResolved =
    availabilitySlots.loading === SliceStatus.resolved;

  const location = useLocation<{id: string}>();
  const id = location?.state?.id;

  const member = useSelector((state: RootState) => {
    return selectMemberById(state, id);
  });
  const effectiveStartDate = useMemo(() => {
    return calculateStartDate(selectedDay);
  }, [selectedDay]);

  const [memberEmail, setMemberEmail] = useState('');

  useEffect(() => {
    return () => {
      dispatch(resetProviderAvailableSlots());
    };
  }, []);
  useEffect(() => {
    const handleGetAvailability = () => {
      if (selectedDay) {
        dispatch(
          getProviderRecurringAvailableSlots({
            duration: duration.value,
            startDate: effectiveStartDate as string,
            numberOfWeeks: recurrence.value,
            ...(isTherapist(user) && {
              patientEmail: memberEmail,
            }),
          }),
        );
      }
    };
    handleGetAvailability();
  }, [duration.value, recurrence.value, selectedDay]);

  const computeEndDate = useCallback(() => {
    if (effectiveStartDate) {
      const endDate = addDays(
        new Date(effectiveStartDate),
        7 * (Number(recurrence.value) - 1),
      );
      return format(endDate, 'EEEE, d LLL yyyy');
    }
    return '';
  }, [recurrence.value, selectedDay]);

  const memberPresentInState = Boolean(location?.state?.id);
  const {toggleOn, onToggleClick} = useToggle();
  const {searchStatus: isMembersLoading} = useSelector(selectProvider);
  const members = useSelector(selectAllMembers);
  const memoizedMembers = useMemo(() => members, [members]);
  const isMemberDetailsLoading =
    useRequesting('provider') === SliceStatus.pending;

  const hasMoreMembers = useSelector(selectHasMoreMembers);

  const history = useHistory();

  useEffect(() => {
    if (isProvider(user) && memberPresentInState) {
      dispatch(
        providerActions.getMemberById({
          patientId: id,
          role: user!.role,
        }),
      );
    }
  }, [id, user!.role, memberPresentInState]);

  useEffect(() => {
    if (isProvider(user) && memberPresentInState) {
      setMemberEmail(member?.email);
    }
  }, [member, memberPresentInState]);

  const onSearch = useCallback(
    debounce((value: string) => {
      if (value === '') dispatch(providerActions.resetMembersToSnapshot());
      else if (value.length > 2) {
        dispatch(
          providerActions.searchMembers({
            role: user!.role,
            searchTerm: value?.toLowerCase(),
            providerId: user?._id,
            orderBy: OrderMembersBy.AlphaAz,
          }),
        );
      }
    }, 1000),
    [],
  );

  const isSearchMembersLoading = isMembersLoading === SliceStatus.pending;
  const fetchMoreMembers = () => {
    fetchMembers({limit: Members_Limit + memoizedMembers.length});
  };

  const fetchMembers = ({limit}: {limit: number}) => {
    dispatch(
      providerActions.getMembers({
        limit,
        role: user!.role,
        orderBy: OrderMembersBy.AlphaZa,
      }),
    );
  };

  useEffect(() => {
    if (!memberPresentInState && isProvider(user))
      fetchMembers({limit: Members_Limit});
  }, [memberPresentInState]);

  const clientOptions = useCallback((): OptionType[] => {
    return memoizedMembers.map(member => {
      return {
        value: member.email,
        label: member.fullName,
      };
    });
  }, [memoizedMembers])();
  const config = {
    dataLength: memoizedMembers.length,
    hasMore: hasMoreMembers,
    next: fetchMoreMembers,
  };

  const handleInputChange = (value: any) => {
    setMemberEmail(value);
  };
  const getValue = useCallback(
    (value: string) => {
      if (clientOptions.length) {
        return clientOptions.find(
          (client: OptionType) => client.value === value,
        )?.label;
      }
      return value;
    },
    [clientOptions],
  );

  const timeInProviderTimeZone = availabilitySlots?.slots?.find(
    time => convertTimeToDiffTimeZone(time, memberTimezone) === selectedTime,
  );
  const appointmentDateTimeString = `${effectiveStartDate}T${timeInProviderTimeZone}`;

  const bookAppointment = () => {
    const appointmentData = {
      numberOfWeeks: Number(recurrence.value),
      therapistId: isTherapist(user)
        ? user?.therapistId
        : (user?.therapistDetails.therapistId as string),
      appointmentStartDateTimeString: appointmentDateTimeString,
      patientTimezone: memberTimezone,
      appointmentDuration: duration.value as AppointmentDurations,
      noteMessage,
      ...(isTherapist(user) && {
        patientEmail: memberEmail,
      }),
    };

    dispatch(bookRecurringAppointment(appointmentData));
  };

  const checkbookingChargeAndbookAppointment = async () => {
    try {
      setBookingCharge({...bookingCharge, status: SliceStatus.pending});
      const res = await BookingService.getBookingCharge({
        role: user!.role,
        patientEmail: isTherapist(user) ? memberEmail : user!.email,
        appointmentType: AppointmentTypes.video_call_with_therapist,
        numberOfWeeks: recurrence.value,
        isRecurring: 'true',
        appointmentDuration: duration.value as AppointmentDurations,
      });
      if (res.data.message.charge > 0) {
        setBookingCharge({
          ...bookingCharge,
          status: SliceStatus.resolved,
          amount: res.data.message.charge,
          showModal: true,
        });
      } else {
        setBookingCharge({
          ...bookingCharge,
          status: SliceStatus.resolved,
          showModal: false,
        });
        bookAppointment();
      }
    } catch (error) {
      setBookingCharge({
        ...bookingCharge,
        status: SliceStatus.rejected,
        showModal: false,
      });
      Toast({
        type: 'error',
        message: 'oops! something went wrong, please try booking again.',
      });
    }
  };

  const closeExtraChargeModal = () => {
    setBookingCharge({
      ...bookingCharge,
      showModal: false,
    });
  };

  const onConfirmBooking = () => {
    bookAppointment();
    setBookingCharge({...bookingCharge, showModal: false});
  };
  return {
    isLoading,
    timeSlots: availabilitySlotsToMemberTimezone,
    duration,
    setDuration,
    selectedTime,
    setSelectedTime,
    selectedDay,
    setSelectedDay,
    recurrence,
    setRecurrence,
    availabilityRequestResolved,
    computeEndDate,
    checkbookingChargeAndbookAppointment,
    bookingCharge,
    closeExtraChargeModal,
    onConfirmBooking,
    isLoadingBooking,
    member,
    memberEmail,
    setMemberEmail,
    noteMessage,
    setNoteMessage,

    config,
    onSearch,
    isSearchMembersLoading,
    clientOptions,
    handleInputChange,
    getValue,
    toggleOn,
    onToggleClick,
    isMemberDetailsLoading,
    isMembersLoading,
    fetchMoreMembers,
    fetchMembers,
    memberPresentInState,
    id,
    user,
    history,
    memoizedMembers,
    hasMoreMembers,
    effectiveStartDate,
  };
}
