export const LicenseTypeOptions = [
  {
    label: 'LCSW',
    value: 'LCSW',
  },
  {label: 'LPC', value: 'LPC'},
  {label: 'LMHC', value: 'LMHC'},
  {label: 'LCSW-R', value: 'LCSW-R'},
  {label: 'PhD', value: 'PhD'},
  {label: 'LMFT', value: 'LMFT'},
  {label: 'PsyD', value: 'PsyD'},
  {label: 'LMSW', value: 'LMSW'},
  {label: 'Licensed Psychologist', value: 'Licensed Psychologist'},
  {label: 'AMFT', value: 'AMFT'},
  {label: 'LPC-MHSP', value: 'LPC-MHSP'},
  {label: 'MD', value: 'MD'},
  {label: 'APCC', value: 'APCC'},
  {label: 'LICSW', value: 'LICSW'},
  {label: 'LPC- S', value: 'LPC- S'},
  {label: 'Psy.D', value: 'Psy.D'},
  {label: 'LPC-S', value: 'LPC-S'},
  {label: 'LCPC', value: 'LCPC'},
  {label: 'DO', value: 'DO'},
  {label: 'LCMHC', value: 'LCMHC'},
  {label: 'MSW', value: 'MSW'},
  {label: 'LADC', value: 'LADC'},
  {label: 'CHMC', value: 'CHMC'},
  {label: 'LPCC', value: 'LPCC'},
  {label: 'LPC-A', value: 'LPC-A'},
  {label: 'LSW', value: 'LSW'},
  {label: 'LCSW-C', value: 'LCSW-C'},
  {label: 'LAC', value: 'LAC'},
  {label: 'Psychologist', value: 'Psychologist'},
  {label: 'LISW', value: 'LISW'},
  {label: 'LISW-S', value: 'LISW-S'},
  {label: 'LCDCIII', value: 'LCDCIII'},
  {label: 'ACSW', value: 'ACSW'},
  {label: 'LCP', value: 'LCP'},
  {label: 'LMFTS', value: 'LMFTS'},
  {label: 'LMHP', value: 'LMHP'},
  {label: 'LCPC-A', value: 'LCPC-A'},
  {label: 'LMHC and BCBA', value: 'LMHC and BCBA'},
  {label: 'PLPC', value: 'PLPC'},
  {
    label: 'Licensed Clinical Psychologist (LCP)',
    value: 'Licensed Clinical Psychologist (LCP)',
  },
  {label: 'LMHCA', value: 'LMHCA'},
  {label: 'Psychiatrist', value: 'Psychiatrist'},
  {label: 'LPCC-S', value: 'LPCC-S'},
  {label: 'Lcswc', value: 'Lcswc'},
  {label: 'LCSW-A', value: 'LCSW-A'},
  {label: 'LPC & LCMHC', value: 'LPC & LCMHC'},
  {label: 'CMHC', value: 'CMHC'},
  {label: 'LCMHCA', value: 'LCMHCA'},
  {label: 'LLMSW', value: 'LLMSW'},
  {label: 'LPCA', value: 'LPCA'},
  {label: 'LCPC-IL LPC-WI', value: 'LCPC-IL LPC-WI'},
  {label: 'P-LPC', value: 'P-LPC'},
  {label: 'LMHC-A', value: 'LMHC-A'},
  {label: 'LMFTA SUDP', value: 'LMFTA SUDP'},
  {label: 'CMHC-LP', value: 'CMHC-LP'},
  {label: 'LCPC and LPC', value: 'LCPC and LPC'},
  {label: 'LISW-CP', value: 'LISW-CP'},
  {label: 'MFT-LP', value: 'MFT-LP'},
  {label: 'LP', value: 'LP'},
  {label: 'LAMFT', value: 'LAMFT'},
  {label: 'SAC', value: 'SAC'},
  {label: 'LCSW supervision', value: 'LCSW supervision'},
  {label: 'LCISWA', value: 'LCISWA'},
  {label: 'LCAT', value: 'LCAT'},
  {label: 'LPA-A', value: 'LPA-A'},
  {label: 'LPCC CA', value: 'LPCC CA'},
  {label: 'LPC CO', value: 'LPC CO'},
  {label: 'APC', value: 'APC'},
  {
    label: 'Doctor of Education/Counseling Psychology',
    value: 'Doctor of Education/Counseling Psychology',
  },
  {label: 'LCSW-R NY LCSW TX', value: 'LCSW-R NY LCSW TX'},
  {label: 'NCC', value: 'NCC'},
  {label: 'CPCS', value: 'CPCS'},
  {label: 'CCMHC', value: 'CCMHC'},
  {label: 'LSOTP', value: 'LSOTP'},
  {label: 'LGSW', value: 'LGSW'},
  {label: 'LCAS', value: 'LCAS'},
  {label: 'CRC', value: 'CRC'},
  {label: 'LCDC', value: 'LCDC'},
  {label: 'LMCH', value: 'LMCH'},
  {label: 'LPC and LMHC', value: 'LPC and LMHC'},
  {label: 'LCHMC', value: 'LCHMC'},
  {label: 'LCADC', value: 'LCADC'},
  {label: 'MHC-LP', value: 'MHC-LP'},
  {label: 'LCSW-S', value: 'LCSW-S'},
  {label: 'SQP', value: 'SQP'},
  {label: 'MS', value: 'MS'},
  {label: 'CCTP', value: 'CCTP'},
  {label: 'LPC LPCC', value: 'LPC LPCC'},
  {label: 'EMPTY', value: 'EMPTY'},
  {label: 'MA', value: 'MA'},
  {label: 'CASAC-T', value: 'CASAC-T'},
  {label: 'SAP', value: 'SAP'},
  {label: 'CASAC-M', value: 'CASAC-M'},
  {label: 'MSEd', value: 'MSEd'},
  {label: 'MFT', value: 'MFT'},
  {label: 'LPC-Associate', value: 'LPC-Associate'},
  {label: 'LICDC', value: 'LICDC'},
  {label: 'TN - LPC', value: 'TN - LPC'},
  {label: 'LBSW', value: 'LBSW'},
  {label: 'LPE-I', value: 'LPE-I'},
  {label: 'TLHT-LMHC', value: 'TLHT-LMHC'},
  {label: 'LCSWC', value: 'LCSWC'},
  {label: 'LSCSW', value: 'LSCSW'},
  {label: 'CPC', value: 'CPC'},
  {label: 'IMHC', value: 'IMHC'},
  {label: 'LAPC', value: 'LAPC'},
  {label: 'LCMHC-A', value: 'LCMHC-A'},
  {label: 'LCAS-A', value: 'LCAS-A'},
  {label: 'LIDC', value: 'LIDC'},
  {label: 'LPC-IT', value: 'LPC-IT'},
  {label: 'AMHP', value: 'AMHP'},
  {label: 'IMH', value: 'IMH'},
  {label: 'LADC1', value: 'LADC1'},
  {label: 'ATR', value: 'ATR'},
  {label: 'TLHT', value: 'TLHT'},
  {label: 'Registered Play Therapist', value: 'Registered Play Therapist'},
  {label: 'TPMC', value: 'TPMC'},
  {label: 'LIM', value: 'LIM'},
  {label: 'LIMHP', value: 'LIMHP'},
  {label: 'LMHC SUPV', value: 'LMHC SUPV'},
  {label: 'LCSW supv.', value: 'LCSW supv.'},
  {label: 'LMHC supv.', value: 'LMHC supv.'},
  {label: 'LCSW-R supv.', value: 'LCSW-R supv.'},
  {label: 'MHC', value: 'MHC'},
  {label: 'LCMHCH. LPC', value: 'LCMHCH. LPC'},
  {label: 'BC-TMH', value: 'BC-TMH'},
  {label: 'ALC', value: 'ALC'},
  {label: 'PMHC', value: 'PMHC'},
  {label: 'LMHCI', value: 'LMHCI'},
  {label: 'RMHCI', value: 'RMHCI'},
  {label: 'RMHC', value: 'RMHC'},
  {label: 'RCSWI', value: 'RCSWI'},
  {label: 'LMHC P', value: 'LMHC P'},
  {label: 'LCSW P', value: 'LCSW P'},
  {label: 'LMFTA', value: 'LMFTA'},
  {label: 'FL', value: 'FL'},
  {label: 'MCAP', value: 'MCAP'},
  {label: 'LCMHCS', value: 'LCMHCS'},
  {label: 'MAC', value: 'MAC'},
  {label: 'LMHC-S', value: 'LMHC-S'},
  {label: 'LMHC-QS', value: 'LMHC-QS'},
  {label: 'TLHT-LCSW', value: 'TLHT-LCSW'},
  {label: 'CA', value: 'CA'},
  {label: 'RIMFT', value: 'RIMFT'},
  {label: 'LPCMH', value: 'LPCMH'},
  {label: 'LCSW-BAC', value: 'LCSW-BAC'},
  {label: 'LPC-R', value: 'LPC-R'},
  {label: 'LIMFT', value: 'LIMFT'},
  {label: 'LSWAA', value: 'LSWAA'},
  {label: 'LSWAIC', value: 'LSWAIC'},
  {label: 'LSWA', value: 'LSWA'},
  {label: 'Melissa Wergland', value: 'Melissa Wergland'},
  {label: 'LLMFT', value: 'LLMFT'},
  {label: 'LPA', value: 'LPA'},
  {label: 'GA', value: 'GA'},
  {label: 'RMFTI', value: 'RMFTI'},
  {label: 'LCP-A', value: 'LCP-A'},
  {label: 'LPCA', value: 'LPCA'},
  {label: 'CMSW', value: 'CMSW'},
  {label: 'LLC', value: 'LLC'},
  {label: 'LMFT-LP', value: 'LMFT-LP'},
  {label: 'APSW', value: 'APSW'},
  {label: 'LMFT-A', value: 'LMFT-A'},
  {label: 'CSW', value: 'CSW'},
  {label: 'LLPC', value: 'LLPC'},
  {label: 'LACMH', value: 'LACMH'},
  {label: 'LCMFT', value: 'LCMFT'},
  {label: 'PCSW', value: 'PCSW'},
  {label: 'LICSW-PIP', value: 'LICSW-PIP'},
  {label: 'NY', value: 'NY'},
  {label: 'QMHP', value: 'QMHP'},
  {label: 'PMHNP', value: 'PMHNP'},
];

export enum SupervisionStatusType {
  licensedToSupervise = 'Licensed to supervise',
  notLicensedToSupervise = 'Not licensed to supervise',
  needCurrentlyUnderSupervision = 'Need/currently under supervision',
}

export enum StepsEnum {
  stepOne = 'Step 1.',
  stepTwo = 'Step 2.',
  stepThree = 'Step 3.',
  stepFour = 'Step 4.',
  stepFive = 'Step 5.',
}
