import {AppActions, RootState} from 'app/rootReducer';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {providerActions} from '../../../../providerSlice';

export const getTherapyQuestionnaireEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getTherapyQuestionnaire.match),
    mergeMap(({payload: {patientId}}) =>
      from(ProviderService.getTherapyQuestionnaire(patientId)).pipe(
        mergeMap(({data}) => {
          return [
            providerActions.getTherapyQuestionnaireSuccess(
              data?.message?.data?.Questions,
            ),
          ];
        }),
        catchError((message: string) =>
          concat(
            of(providerActions.getTherapyQuestionnaireFailure()),
            of(userActions.setAsyncError({filter: 'provider', message})),
          ),
        ),
      ),
    ),
  );
