import React, {FC, lazy, Suspense, useState} from 'react';
import {Button, GoBackIcon, LoadingSpinner} from 'components';
import {addMonths, format} from 'date-fns';
import {AppointmentCalendarContainer} from 'features/Appointment/Calendar/CalendarContainer.styles';
import {ProviderAppointmentCancellationSuccess} from 'features/Provider/Calendar/CancelAppointment/AppointmentCancellationSuccess';
import {ProviderCancelAppointment} from 'features/Provider/Calendar/CancelAppointment/ProviderCancelAppointment';
import {twoMonthsFromCurrentMonth} from 'features/Provider/Calendar2/constants';
import {selectUserProfile} from 'features/User';
import {useCalendar} from 'hooks';
import {useCalendarLocalization} from 'hooks/useCalendarLocalization';
import {SliceStatus} from 'interfaces';
import DayPicker from 'react-day-picker';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {componentLoader, isMember, isProvider} from 'utils';

import 'react-day-picker/lib/style.css';

const Confirm = lazy(() =>
  componentLoader(() => import('components/Basic/Confirm')),
);
type Props = {
  closeCalendar?: () => void;
};
export const Calendar: FC<Props> = ({closeCalendar}) => {
  const user = useSelector(selectUserProfile);
  const {
    selectedDay,
    onDayClick,
    calendarAppointments,
    calendarLoadingState,
    now,
    appointmentCount,
    modifiers,
    fetchAppointments,
    appointmentDetails,
    idToCancel,
    localTime,
    resetAppointmentIdToCancel,
    cancelAppointment,
  } = useCalendar();
  const handleBackArrow = () => {
    if (closeCalendar) closeCalendar();
  };
  const {months, weekdaysLong, weekdaysShort, locale, firstDayOfWeek} =
    useCalendarLocalization();

  const [showCancelSuccessModal, setShowCancelSuccessModal] = useState(false);
  const {t} = useTranslation();
  const apCount = appointmentCount();

  return (
    <>
      {idToCancel && isMember(user) ? (
        <Suspense fallback={<div />}>
          <Confirm
            isOpen
            title={t('cancelAppointment')}
            message={t('areYouSureCancelAppointment')}
            onConfirm={cancelAppointment}
            onCancel={resetAppointmentIdToCancel}
          />
        </Suspense>
      ) : null}
      {idToCancel && localTime && isProvider(user) ? (
        <Suspense fallback={<div />}>
          <ProviderCancelAppointment
            setShowCancelSuccessModal={setShowCancelSuccessModal}
            resetAppointmentIdToCancel={resetAppointmentIdToCancel}
            cancelAppointment={cancelAppointment}
            localTime={localTime}
          />
        </Suspense>
      ) : null}
      <Suspense fallback={<div />}>
        <ProviderAppointmentCancellationSuccess
          showCancelSuccessModal={showCancelSuccessModal}
          setShowCancelSuccessModal={setShowCancelSuccessModal}
        />
      </Suspense>
      <button className="my-2 lg:hidden pl-2" onClick={handleBackArrow}>
        <GoBackIcon fillColor="#315eff" strokeColor="text-blue-600" />
      </button>

      <section className="text-center">
        <h1 className="text-lg md:text-2xl leading-snug my-0">
          <Trans i18nKey="appointmentToday" apCount={apCount}>
            You have {{apCount}} today.
          </Trans>
        </h1>
      </section>
      <AppointmentCalendarContainer className="flex flex-col justify-content items-center pt-12">
        <DayPicker
          showOutsideDays
          month={now}
          modifiers={modifiers}
          fromMonth={now}
          toMonth={addMonths(now, 1)}
          onDayClick={onDayClick}
          selectedDays={selectedDay}
          locale={locale}
          weekdaysLong={weekdaysLong}
          weekdaysShort={weekdaysShort}
          months={months}
          firstDayOfWeek={firstDayOfWeek}
        />
        {calendarLoadingState === SliceStatus.pending &&
        !calendarAppointments.length ? (
          <div className="flex justify-center m-2">
            <LoadingSpinner type="ThreeDots" />
          </div>
        ) : null}
        {calendarLoadingState === SliceStatus.rejected ? (
          <Button
            type="button"
            borderRadius="full"
            borderColor="transparent"
            onClick={() => fetchAppointments(twoMonthsFromCurrentMonth)}
            className="py-2 px-4 mt-4"
          >
            {t('failedToLoadRetry')}
          </Button>
        ) : null}
        {selectedDay ? (
          <>
            <p className="font-bold text-gray-700 text-left px-4 py-2 bg-gray-100 md:text-lg mt-8 w-full leading-snug md:mt-12">
              {format(selectedDay, 'EEE MMM dd')}
            </p>
            <section className="pt-4 w-full text-center">
              {appointmentDetails()}
            </section>
          </>
        ) : null}
      </AppointmentCalendarContainer>
    </>
  );
};
