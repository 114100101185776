import React, {useEffect, useState} from 'react';
import {Button} from 'components/Basic/Button';
import {OptionType} from 'interfaces';
import {FieldValues} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ThreeDots} from 'react-loader-spinner';
import {components, OnChangeValue, SingleValue} from 'react-select';
import {ActionMeta, GroupBase} from 'react-select/dist/declarations/src/types';

import {SelectComponentProps, SelectType, TSelectOption} from '../models';
import {detachedSearchBarStyles} from '../styles';

import {ChevronDown} from './ChevronDown';
import {DropdownContainer} from './DropdownContainer';
import {OptionWithCheckbox} from './OptionWithCheckbox';
import {SearchIcon} from './SearchIcon';

export const DetachedSearchSelect = <
  TFieldValues extends FieldValues,
  IsMulti extends boolean,
  IsPaginated extends boolean,
  Option extends TSelectOption<TFieldValues> = TSelectOption<TFieldValues>,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  placeholder,
  onChange,
  selectComponent: SelectComponent,
  styles = detachedSearchBarStyles,
  ...props
}: SelectComponentProps<TFieldValues, Option, IsMulti, IsPaginated, Group> & {
  selectComponent: SelectType;
  portalContainer?: Element | DocumentFragment;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<OnChangeValue<Option, IsMulti> | null>();
  const {t} = useTranslation();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue(props.value as OnChangeValue<Option, IsMulti> | null);
  }, [props.value]);

  const toggleOpen = () => setIsOpen(prev => !prev);

  const onSelectChange = (
    value: OnChangeValue<Option, IsMulti> | null,
    actionMeta: ActionMeta<Option>,
  ) => {
    setValue(value);
    if (!props.isMulti) {
      close();
      onChange?.(value!, actionMeta);
    }
  };

  const close = () => {
    toggleOpen();
    props.onMenuClose?.();
    if (props.isMulti && value) {
      onChange?.(value, {} as ActionMeta<Option>);
    }
  };

  const hasValue = () => !!(value && (!props.isMulti || (value as []).length));

  const noOptionsAvailable = () =>
    !props.isLoading && !props.options?.length && !props.loadOptions;

  const getButtonText = () => {
    if (props.loadingMessage && props.isLoading && !hasValue()) {
      return props.loadingMessage();
    }

    if (props.noOptionsMessage && noOptionsAvailable()) {
      return props.noOptionsMessage({
        inputValue: (value as SingleValue<OptionType>)?.value ?? '',
      });
    }

    if ((props.value as Option)?.label) {
      return (props.value as Option)?.label;
    }

    if (!hasValue()) {
      return placeholder ?? '';
    }

    if (props.isMulti) {
      const prefix = props.selectionCountPrefix
        ? props.selectionCountPrefix
        : t('selected');
      return `${prefix} (${(value as []).length})`;
    }

    return (value as SingleValue<Option>)!.label;
  };

  return (
    <DropdownContainer
      isOpen={isOpen}
      onClose={close}
      placeholder={placeholder}
      styles={styles as any}
      {...props}
      target={
        <Button
          onClick={toggleOpen}
          btnType="custom"
          disabled={props.isDisabled || props.isLoading || noOptionsAvailable()}
          bgColor="white"
          borderRadius="xl"
          textColor="black"
          style={styles.detachedButton?.(isOpen, hasValue())}
        >
          <span className="truncate">{getButtonText()}</span>
          {props.isLoading ? (
            <ThreeDots width={20} height={25} color="#2E62EC" />
          ) : (
            <ChevronDown />
          )}
        </Button>
      }
    >
      <SelectComponent
        autoFocus={false}
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator: SearchIcon,
          IndicatorSeparator: null,
          Option: props.isMulti ? OptionWithCheckbox : components.Option,
          Control: props.isSearchable ? components.Control : () => null,
        }}
        controlShouldRenderValue={false}
        isClearable={false}
        menuIsOpen
        inputValue={inputValue}
        className="text-left text-sm font-inter"
        loadOptions={props.loadOptions as any}
        placeholder={t('search')}
        styles={styles}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        tabSelectsValue={false}
        {...props}
        onChange={(val, action) =>
          onSelectChange(val as OnChangeValue<Option, IsMulti>, action)
        }
        onInputChange={(textInput, x) => {
          if (x.action === 'input-blur' || x.action === 'input-change') {
            setInputValue(textInput);
          }
          props.onInputChange?.(textInput, x);
        }}
        value={value}
      />
    </DropdownContainer>
  );
};
