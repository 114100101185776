import React from 'react';
import {Control, Controller, FieldPath, FieldValues} from 'react-hook-form';

type RadioGroupProps<T extends FieldValues> = {
  options: string[];
  control: Control<T>;
  name: FieldPath<T>;
  gridType: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
};

const RadioGroup = <T extends FieldValues>({
  options,
  control,
  name,
  gridType,
  onChange: _onChange,
  onBlur: _onBlur,
}: RadioGroupProps<T>) => {
  return (
    <div className={`grid lg:grid-cols-${gridType} grid-cols-1 gap-4 mt-5`}>
      {options.map(option => (
        <Controller
          key={option}
          control={control}
          name={name}
          defaultValue={'' as any}
          render={({field: {onChange, onBlur, value}}) => (
            <label
              className={`max-w-full h-[3.25rem] rounded-xl border-2 ${
                value === option ? 'border-blue-500' : ''
              } flex items-center justify-start gap-2 2xs:pl-4`}
            >
              <input
                type="radio"
                name={name}
                value={option}
                checked={value === option}
                onChange={e => {
                  onChange(option);
                  if (typeof _onChange === 'function') {
                    _onChange(e);
                  }
                }}
                onBlur={e => {
                  onBlur();
                  if (typeof _onBlur === 'function') {
                    _onBlur(e);
                  }
                }}
                className="w-4 h-4"
              />
              <span className="text-black text-center font-inter font-medium text-[0.875rem] pr-10">
                {option}
              </span>
            </label>
          )}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
