import {AppDispatch, AppThunk} from 'app/store';
import {userActions} from 'features/User';
import {
  AccountTabs,
  ChangeEmailData,
  ChangePasswordData,
  OnboardingShippingProps,
  ProfileImageFormData,
  VerifyPasswordData,
  VerifyPhoneData,
  VerifyPhoneTokenData,
} from 'interfaces';
import {AccountService} from 'services/api';

import {createAction} from '@reduxjs/toolkit';

export const verifyPasswordStart = createAction('VerifyPasswordStart');
export const verifyPasswordSuccess = createAction('VerifyPasswordSuccess');
export const verifyPasswordFailure = createAction('VerifyPasswordFailure');

export const verifyPhoneStart = createAction('VerifyPhoneStart');
export const verifyPhoneSuccess = createAction('VerifyPhoneSuccess');
export const verifyPhoneFailure = createAction('VerifyPhoneFailure');

export const changePassword =
  createAction<ChangePasswordData>('ChangePassword');
export const changePasswordSuccess = createAction('ChangePasswordSuccess');
export const changePasswordFailure = createAction('ChangePasswordFailure');

export const changeEmail = createAction<ChangeEmailData>('ChangeEmail');
export const changeEmailSuccess = createAction('ChangeEmailSuccess');
export const changeEmailFailure = createAction('ChangeEmailFailure');

export const changePhone = createAction<VerifyPhoneTokenData>('ChangePhone');
export const changePhoneSuccess = createAction('ChangePhoneSuccess');
export const changePhoneFailure = createAction('ChangePhoneFailure');

export const setAccountTab = createAction<AccountTabs | null>('SetAccountTab');

export const verifyPassword =
  (data: VerifyPasswordData): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(verifyPasswordStart());
      await AccountService.verifyPassword(data);
      dispatch(verifyPasswordSuccess());
    } catch (err) {
      dispatch(verifyPasswordFailure());
      dispatch(userActions.setAsyncError({filter: 'member', message: err}));
      return err;
    }
    return true;
  };

export const verifyPhone =
  (data: VerifyPhoneData): AppThunk<Promise<boolean>> =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(verifyPhoneStart());
      await AccountService.verifyPhone(data);
      dispatch(verifyPhoneSuccess());
    } catch (err) {
      dispatch(verifyPhoneFailure());
      dispatch(userActions.setAsyncError({filter: 'member', message: err}));
      return err;
    }
    return true;
  };

export const changeAddress =
  createAction<OnboardingShippingProps>('ChangeAddress');
export const changeAddressSuccess = createAction('ChangeAddressSuccess');
export const changeAddressFailure = createAction('ChangeAddressFailure');

export const changeProfilePicture = createAction<{
  image: ProfileImageFormData;
  onSuccess: () => void;
  onError: () => void;
}>('ChangeProfilePicture');
export const changeProfilePictureFailure = createAction<string>(
  'ChangeProfilePictureFailure',
);
export const changeProfilePictureSuccess = createAction(
  'ChangeProfilePictureSuccess',
);

export const changePreferredLanguage = createAction<{
  preferredLanguage: string;
}>('ChangePreferredLanguage');
export const changePreferredLanguageSuccess = createAction(
  'ChangePreferredLanguageSuccess',
);
export const changePreferredLanguageFailure = createAction(
  'ChangePreferredLanguageFailure',
);
