import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {
  BROWSER_ENUM,
  MicrophonePermissionDescriptor,
  MicrophonePermissionResult,
} from 'interfaces';
import {BROWSER} from 'utils';

export function useAudioRecorder(
  handleFile: (e: React.ChangeEvent<HTMLInputElement>) => void,
) {
  const [utils, setUtils] = useState<{
    error?: boolean;
    show?: boolean;
  }>({
    error: false,
    show: false,
  });
  const [info, setInfo] = useState<{title: string; content: ReactNode}>({
    title: '',
    content: '',
  });

  const [status, setStatus] = useState<'recording' | 'paused' | 'idle'>('idle');

  const [url, setUrl] = useState('');
  const [audioRecorder, setAudioRecorder] = useState<{
    audioBlobs?: BlobPart[];
    mediaRecorder?: MediaRecorder;
    streamBeingCaptured?: MediaStream;
  }>();

  const [counter, setCounter] = useState(0);
  const [elapsedTime, setElapsedTime] = useState('00:00');
  const elapsedTimerRef = useRef<NodeJS.Timeout>();

  const mimeType = (() => {
    if (MediaRecorder.isTypeSupported('audio/mp4')) return 'audio/mp4'; // supports safari
    return 'audio/webm'; // supports chrome, firefox, edge, brave
  })();

  /**
   *
   * @param title
   * @param content
   */
  const onError = (title?: string, content?: string) => {
    setInfo({
      title: title ?? 'Allow microphone',
      content: content ?? (
        <>
          To record Voice Messages, Wellnite needs access to your microphone.
          Click&nbsp;
          <span className="w-6 h-6 inline-block align-middle chrome-media-icon" />
          &nbsp; in the URL bar and choose "Always allow {window.location.host}
          &nbsp;to access your microphone."
        </>
      ),
    });
    setUtils({
      error: true,
      show: true,
    });
  };

  function requestMicrophone(
    permissionDesc: MicrophonePermissionDescriptor,
  ): Promise<MicrophonePermissionResult> {
    return new Promise((resolve, reject) => {
      const {constraints, peerIdentity} = permissionDesc;
      navigator.mediaDevices
        .getUserMedia({audio: constraints ? constraints : true, peerIdentity})
        .then(stream => {
          return resolve({state: 'granted', stream});
        })
        .catch(err => {
          if (err.name === 'PermissionDeniedError') {
            resolve({state: 'denied'});
          }
          onError();
          reject(err);
        });
    });
  }

  const startRecording = async () => {
    if (!(navigator.mediaDevices && navigator.mediaDevices?.getUserMedia)) {
      setInfo({
        title: 'Not Supported',
        content: 'MediaDevices API is not supported in this browser.',
      });
      setUtils({...utils, error: true, show: true});
    } else {
      if ([BROWSER_ENUM.FIRE_FOX].includes(BROWSER))
        await requestMicrophone({name: 'microphone'});
      else {
        const permission = await navigator.permissions.query({
          // @ts-ignore
          name: 'microphone',
        });
        if (permission.state === 'prompt') {
          setUtils({...utils, show: true});
          setInfo({
            title: 'Allow microphone',
            content:
              'To record Audio Messages, click "Allow" above to give Wellnite access to your computer\'s microphone.',
          });
        }
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        const mediaRecorder = new MediaRecorder(stream, {mimeType});
        setAudioRecorder({
          ...audioRecorder,
          mediaRecorder,
          streamBeingCaptured: stream,
          audioBlobs: [],
        });
        setStatus('recording');
        mediaRecorder.start(1000);
      } catch (error) {
        switch (error.name) {
          case 'AbortError':
          case 'TrackStartError':
          case 'NotReadableError':
            onError(
              'Already In Use',
              'Mic in use by another application (Zoom, Webex) or tab (Google Meet, Messenger Video)',
            );
            break;
          case 'PermissionDeniedError':
          case 'NotAllowedError': // permission denied in browser
            onError();
            break;
          case 'DevicesNotFoundError':
          case 'NotFoundError': // track is missing
            onError(
              'Not Available',
              "Browser doesn't have System Preferences access to mic",
            );
            break;

          // case 'SecurityError': //error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
          //   onError('Access denied', 'A SecurityError has occured.')
          //   break
          // case 'TypeError': //error from navigator.mediaDevices.getUserMedia
          //   onError('Access denied', 'A TypeError has occured.')
          //   break
          // case 'InvalidStateError': //error from the MediaRecorder.start
          //   onError('Access denied', 'An InvalidStateError has occured.')
          //   break
          // case 'UnknownError': //error from the MediaRecorder.start
          //   onError('Access denied', 'An UnknownError has occured.')
          //   break
          default:
            onError(
              'Not Available',
              'To record audio, use browsers like Chrome, Firefox and Safari.',
            );
        }
      }
    }
  };

  /**
   * @description stop all the tracks on the active stream in order to stop the stream
   */
  const stopStream = () => {
    if (audioRecorder?.streamBeingCaptured)
      audioRecorder.streamBeingCaptured
        .getTracks() //get all tracks from the stream
        .forEach(track => track.stop()); //stop each one
  };

  /**
   * @description reset recording state
   */
  const resetRecording = () => {
    if (
      audioRecorder?.mediaRecorder &&
      audioRecorder.mediaRecorder.state === 'recording'
    )
      audioRecorder.mediaRecorder.stop();

    stopStream();
    setAudioRecorder(undefined);
    setStatus('idle');
    setElapsedTime('00:00');
    setCounter(0);
    if (elapsedTimerRef.current) clearInterval(elapsedTimerRef.current);
  };

  const stopRecording = () => {
    if (audioRecorder?.mediaRecorder) {
      //listen to the stop event in order to capture blob
      audioRecorder.mediaRecorder.addEventListener('stop', () => {
        const file = new File(
          audioRecorder.audioBlobs!,
          'AUDIO-REC-' + new Date().getTime(),
          {
            lastModified: new Date().getTime(),
            type: 'audio/mp3',
          },
        );
        const ev: React.ChangeEvent<HTMLInputElement> = {
          target: {files: [file]},
        } as any;

        handleFile(ev);
      });

      resetRecording();
    }
  };

  const cancelRecording = () => {
    resetRecording();
  };

  const pauseRecording = () => {
    if (audioRecorder?.mediaRecorder && status === 'recording') {
      audioRecorder.mediaRecorder.pause();
      setStatus('paused');
      clearInterval(elapsedTimerRef.current);

      const audioBlob = new Blob(audioRecorder.audioBlobs, {type: mimeType});
      const audioUrl = URL.createObjectURL(audioBlob);
      setUrl(audioUrl);
    }
  };

  const resumeRecording = () => {
    if (audioRecorder?.mediaRecorder && status === 'paused') {
      audioRecorder.mediaRecorder.resume();
      setStatus('recording');
    }
  };

  const gotIt = () => {
    setUtils({
      show: false,
      error: false,
    });
    setInfo({title: '', content: ''});
  };

  useEffect(() => {
    if (status === 'recording') {
      elapsedTimerRef.current = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : '' + secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : '' + minuteCounter;
        const elapsedTime$ = `${computedMinute}:${computedSecond}`;
        setElapsedTime(elapsedTime$);

        setCounter(counter => counter + 1);
      }, 1000);
    }

    return () => clearInterval(elapsedTimerRef.current);
  }, [status, counter]);

  useEffect(() => {
    if (audioRecorder?.mediaRecorder) {
      const localAudioChunks: Blob[] = [];

      audioRecorder.mediaRecorder.ondataavailable = event => {
        localAudioChunks.push(event.data);
      };
      setAudioRecorder({...audioRecorder, audioBlobs: localAudioChunks});
    }
  }, [audioRecorder?.mediaRecorder]);

  return {
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    cancelRecording,
    gotIt,
    status,
    elapsedTime,
    utils,
    info,
    url,
  };
}
