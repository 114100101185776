import {RefObject} from 'react';

import {useElementRefSize} from './useElementRefSize';

/**
 * Hook that calculates how many items of elements of certain size can fit
 * in a ref element.
 *
 * @usage
 * const {ref, items} = useElementRefFitsItems(56);
 * console.log('number of items that can fit', items)
 */
export function useElementRefFitsItems(
  itemSizeInPixels: number,
  defaultItems = 4,
): {
  ref: RefObject<HTMLDivElement> | undefined;
  items: number;
} {
  const {ref, width} = useElementRefSize();
  const items = width ? Math.floor(width / itemSizeInPixels) : defaultItems;

  return {ref, items};
}
