import {ProviderRole, UserRoles, WellbitTypeTotal} from 'interfaces';
import {
  Wellbit,
  WellbitHistory,
  WellbitWallet,
} from 'interfaces/Pages/Provider/Wellbit.types';

import {createAction} from '@reduxjs/toolkit';

export const getWellbitsData = createAction<{
  role: UserRoles;
}>('getWellbitsType');
export const getWellbitsDataSuccess = createAction<{
  currentAvailableWellbits: number;
  wellbitsTypeCount: WellbitTypeTotal;
  totalWellbits: number;
}>('getWellbitsTypeSuccess');
export const getWellbitsDataFailure = createAction('getWellbitsTypeFailure');
export const getProviderColleagueWellbit = createAction<{
  pageNo: number;
  size: number;
  email: string;
  date?: number;
}>('getProviderColleagueWellbit');
export const getProviderColleagueWellbitSuccess = createAction<{
  result: Wellbit[];
  total: number;
  nextPage: number;
  prevPage: number;
  hasMore: boolean;
}>('getProviderColleagueWellbitSuccess');
export const getProviderColleagueWellbitFailure = createAction(
  'getProviderColleagueWellbitFailure',
);

export const clearWellbitsState = createAction('clearWellbitsState');

export const getWellbitWithdrawalHistory = createAction<{
  role?: UserRoles;
  pageNo: number;
  size: number;
  from?: Date | null;
  to?: Date | null;
}>('getWellbitWithdrawalHistory');
export const getWellbitWithdrawalHistorySuccess = createAction<{
  cashingHistory: WellbitHistory[];
  hasMore: boolean;
  nextPage: number;
  balanceHistory: number;
}>('getWellbitWithdrawalHistorySuccess');
export const getWellbitWithdrawalHistoryFailure = createAction(
  'getWellbitWithdrawalHistoryFailure',
);

export const resetTransactionHistory = createAction('resetTransactionHistory');

export const withdrawWellbits = createAction<{
  role: ProviderRole;
  cb: () => void;
}>('withdrawWellbits');
export const withdrawWellbitsSuccess = createAction<{message: WellbitWallet}>(
  'withdrawWellbitsSuccess',
);
export const withdrawWellbitsFailure = createAction<string>(
  'withdrawWellbitsFailure',
);
