import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {StripePlanDurations, StripePlanNames} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {StripeService} from 'services';

import {paymentActions} from '../paymentSlice';

export const resubscribeCheckoutEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.resubscribe.match),
    switchMap(
      ({
        payload: {
          stripeResponse: {token},
          paymentPlanDuration,
          paymentPlan,
        },
      }) =>
        from(
          StripeService.resubscribe({
            token,
            paymentPlan:
              paymentPlanDuration === StripePlanDurations.monthly
                ? paymentPlan
                : (`${paymentPlan}_${paymentPlanDuration}` as StripePlanNames),
            __subPaymentPlan: paymentPlan,
          }),
        ).pipe(
          mergeMap(({data: {message: user}}) => [
            userActions.setUser(user),
            paymentActions.resubscribeSuccess(),
            userActions.setNotification({
              messageType: 'success',
              message: 'Your payment was successful 🎉',
            }),
            push({
              pathname: '/dashboard',
            }),
          ]),
          catchError((message: string) =>
            concat(
              of(paymentActions.resubscribeFailure()),
              of(
                userActions.setAsyncError({
                  filter: 'payment',
                  message,
                }),
              ),
            ),
          ),
        ),
    ),
  );

export const paymentResubscriptionEpics = [resubscribeCheckoutEpic];
