import {
  GetColleaguesParamsType,
  ResultColleaguesType,
  UserRoles,
} from 'interfaces';
import {Wellbit} from 'interfaces/Pages/Provider/Wellbit.types';
import {Response} from 'redaxios';
import {
  attachQueryParameters,
  objectToQueryParams,
  unwrapAPIError,
} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

const getColleagues = async (
  params: GetColleaguesParamsType,
): Promise<Response<{message: ResultColleaguesType}>> => {
  try {
    let url = `${EndPoints.GetColleagues}`;
    const args: any = {size: params.size, offset: params.offset};

    if (params.searchText?.length) {
      url = EndPoints.SearchColleagues;
      args.target = params.target;
      args.searchText = params.searchText;
    } else {
      args.target = params.target;
    }

    url = attachQueryParameters(url, {...args});
    const res = await requestHandler<{message: ResultColleaguesType}>({
      method: HttpMethods.GET,
      url: url as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const searchColleagues = async (
  params: GetColleaguesParamsType,
): Promise<Response<{message: ResultColleaguesType}>> => {
  try {
    const args: any = {
      target: params.target,
      searchText: params.searchText,
      size: params.size,
      offset: params.offset,
    };
    const url = attachQueryParameters(`${EndPoints.SearchColleagues}`, {
      ...args,
    });
    const res = await requestHandler<{message: ResultColleaguesType}>({
      method: HttpMethods.GET,
      url: url as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendGreetings = async (data: {
  email: string;
  secondaryEmail: string;
  role: UserRoles;
  message: string;
}): Promise<
  Response<{
    message: string;
  }>
> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.POST,
      url: EndPoints.SendGreetings,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getProviderColleagueWellbit = async (data: {
  pageNo: number;
  size: number;
  email: string;
  date?: number;
}): Promise<{
  message: {
    result: Wellbit[];
    total: number;
    nextPage: number;
    prevPage: number;
    hasMore: boolean;
  };
}> => {
  const queryParams = objectToQueryParams(data);
  try {
    const res = await requestHandler<{
      message: {
        result: Wellbit[];
        total: number;
        nextPage: number;
        prevPage: number;
        hasMore: boolean;
      };
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetProviderColleagueWellbit}?${queryParams}` as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const CollaborationService = {
  getColleagues,
  searchColleagues,
  sendGreetings,
  getProviderColleagueWellbit,
};
