import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast/index';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, concatMap, from, of} from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {ReceiptService} from 'services/api';

import {paymentActions} from '../paymentSlice';

export const fetchReceiptEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(paymentActions.fetchReceipts.match),
    mergeMap(({payload}) =>
      from(ReceiptService.fetchReceipts(payload)).pipe(
        mergeMap(payload => [
          paymentActions.fetchReceiptsSuccess(payload.message),
        ]),
        catchError((message: string) =>
          concat(
            of(paymentActions.fetchReceiptsFailure()),
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const fetchReceiptFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(paymentActions.fetchReceiptsFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.payment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.payment.error}`});
    }),
    map(() => userActions.resetAsyncError('payment')),
    ignoreElements(),
  );

export const fetchPdfEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(paymentActions.fetchReceiptPdf.match),
    mergeMap(({payload: {onSuccess, onError, ...payload}}) =>
      from(ReceiptService.fetchReceiptPdf(payload)).pipe(
        concatMap(data => {
          onSuccess(data);
          return of(paymentActions.fetchReceiptPdfSuccess());
        }),
        catchError((message: string) => {
          if (onError) {
            onError();
          }
          return concat(
            of(
              userActions.setAsyncError({
                filter: 'payment',
                message,
              }),
            ),
            of(paymentActions.fetchReceiptPdfFailure(message)),
          );
        }),
      ),
    ),
  );

export const receiptEpics = [
  fetchReceiptEpic,
  fetchReceiptFailureEpic,
  fetchPdfEpic,
];
