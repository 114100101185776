import React, {FC} from 'react';
import classNames from 'classnames';
import {LoadingSpinner, LogoutIcon} from 'components/Basic';
import {useLogout} from 'hooks';
import {SliceStatus} from 'interfaces';

const LogoutLink: FC = () => {
  const {logout, logoutStatus} = useLogout(true);

  return (
    <button
      onClick={logout}
      className={classNames(
        'flex items-center w-full text-red-500 pl-10 md:pl-24 py-3 md:py-6 text-xl leading-snug mt-auto border-b bg-transparent',
        {
          'pointer-events-none opacity-50':
            logoutStatus === SliceStatus.pending,
        },
      )}
    >
      {logoutStatus === SliceStatus.pending ? (
        <LoadingSpinner type="Oval" width={20} height={20} color="#EF4444" />
      ) : (
        <LogoutIcon strokeColor="text-red-500" />
      )}
      <span className="pt-1 pl-5">Logout</span>
    </button>
  );
};

export default LogoutLink;
