import {useEffect} from 'react';
import {StateSlices} from 'app/rootReducer';
import {SliceStatus} from 'interfaces';
import {DeepPartial, FieldValues, useForm} from 'react-hook-form';
// eslint-disable-next-line
import {UseFormReturn} from 'react-hook-form/dist/types/form';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';

import {yupResolver} from '@hookform/resolvers/yup';

import {useError} from './useError';
import {useRequesting} from './useRequesting';

// import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

export function useEnhancedForm<
  T extends FieldValues = FieldValues,
  E = unknown,
>({
  defaultValues,
  schema,
  slice,
  action,
  extraSubmitData,
  shouldUnregister,
}: {
  defaultValues: DeepPartial<T> | undefined;
  schema: Yup.ObjectSchema<(T & object) | undefined>;
  slice: StateSlices;
  action: any;
  extraSubmitData: E;
  shouldUnregister?: boolean;
}): {
  apiErrorMsg: string;
  isLoading: SliceStatus;
  onSubmit: any;
  formMethods: UseFormReturn<T>;
} {
  //@ts-ignore
  const formMethods = useForm<T>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister,
  });

  const dispatch = useDispatch();
  const isLoading = useRequesting(slice);
  const {apiError, resetAsyncError} = useError(slice);

  const onSubmit = formMethods.handleSubmit(values => {
    const payload = {...values, ...extraSubmitData};
    dispatch(action(payload as any));
  });

  // is the dep array correct??????
  useEffect(() => {
    return (): void => {
      if (apiError) dispatch(resetAsyncError(slice));
    };
  }, [apiError, dispatch, resetAsyncError, slice]);

  return {
    apiErrorMsg: apiError,
    onSubmit,
    isLoading,
    formMethods,
  };
}
