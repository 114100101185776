import {LoginFormData, UserRoles} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const login = createAction<
  LoginFormData & {
    redirectTo: string;
  }
>('Login');
export const loginSuccess = createAction('LoginSuccess');
export const loginFailure = createAction('LoginFailure');
export const logoutOtherRole = createAction<UserRoles>('LogoutOtherRole');
