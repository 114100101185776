import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {BillingService, ProviderService} from 'services/api';

import {providerActions} from '../providerSlice';

const getProviderBillsEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.getAllProviderBills.match),
    mergeMap(({payload}) =>
      from(BillingService.getAllProviderBills(payload)).pipe(
        mergeMap(({data}) => [
          providerActions.getAllProviderBillsSuccess(data.message),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getAllProviderBillsFailure()),
          ),
        ),
      ),
    ),
  );

export const getBillingHistoryEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getBillingHistory.match),
    mergeMap(({payload}) =>
      from(BillingService.getBillingHistory(payload)).pipe(
        mergeMap(res => [
          providerActions.getBillingHistorySuccess({
            roomMode: payload.roomMode,
            result: res,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(
              providerActions.getBillingHistoryFailure({
                roomMode: payload.roomMode,
              }),
            ),
          ),
        ),
      ),
    ),
  );

const getNotesFailureEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(providerActions.getBillingHistoryFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.provider.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.provider.error}`});
    }),
    map(() => userActions.resetAsyncError('provider')),
  );

const setPlatformFeePercentageEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.setPlatformFeePercentage.match),
    mergeMap(({payload}) =>
      from(BillingService.setPlatformFeePercentage(payload)).pipe(
        mergeMap(({data}) => {
          Toast({
            type: 'success',
            message: i18next.t('splitFee.platformFee', {
              feePercentage: data.message.feePercentage,
            }),
          });

          return [
            providerActions.setPlatformFeePercentageSuccess(data.message),
            userActions.setPlatformFeePercentage(payload.feePercentage),
          ];
        }),
        catchError((message: string) => {
          Toast({type: 'error', message: `${message}`});
          return concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.setPlatformFeePercentageFailure()),
          );
        }),
      ),
    ),
  );

export const setDigitalPracticeSessionRateEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.setDigitalPracticeSessionRate.match),
    mergeMap(({payload}) =>
      from(ProviderService.setDigitalPracticeSessionRate(payload)).pipe(
        mergeMap(({message}) => {
          Toast({
            type: 'success',
            message: message,
          });

          return concat(
            of(
              userActions.setDigitalPracticeSessionRateSuccess({
                min: payload.min,
                max: payload.max,
              }),
            ),
          );
        }),
        catchError(error => {
          Toast({type: 'error', message: error});
          return concat(of(userActions.setDigitalPracticeSessionRateFailure()));
        }),
      ),
    ),
  );

export const PaymentEpics = [
  getProviderBillsEpic,
  getBillingHistoryEpic,
  getNotesFailureEpic,
  setPlatformFeePercentageEpic,
  setDigitalPracticeSessionRateEpic,
];
