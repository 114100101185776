import React, {FC, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  & picture,
  & img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
  }
`;

type Props = {
  className?: string;
  src: string;
  srcSet: string;
  webp?: string;
  alt: string;
  lqip: string;
  type: string;
  aspectRatio: number;
};

export const LazyImage: FC<Props> = ({
  className = '',
  src,
  srcSet,
  alt,
  lqip,
  webp,
  type,
  aspectRatio = 2 / 3,
}) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    if (imgRef.current?.complete) {
      setLoaded(true);
    }
  }, []);
  return (
    <ImageWrapper className={classnames('relative overflow-hidden', className)}>
      <div style={{paddingBottom: `${100 / aspectRatio}%`}} />
      {lqip && (
        <img
          src={lqip}
          alt=""
          aria-hidden="true"
          className={classnames({
            'opacity-0 transition-opacity duration-1000 ease-in-out': loaded,
            'opacity-1': !loaded,
          })}
        />
      )}
      <picture
        className={classnames({
          'opacity-0 transition-opacity duration-1000 ease-in-out': !loaded,
          'opacity-1': loaded,
        })}
      >
        {webp ? <source srcSet={webp} type="image/webp" /> : null}
        <source srcSet={srcSet} type={type} />
        <img
          loading="lazy"
          src={src}
          alt={alt}
          ref={imgRef}
          onLoad={() => setLoaded(true)}
          className={classnames({
            'opacity-0 transition-opacity duration-1000 ease-in-out': !loaded,
            'opacity-1': loaded,
          })}
        />
      </picture>
    </ImageWrapper>
  );
};
