import * as Yup from 'yup';

export const VerifyPhoneSchema = Yup.object()
  .shape({
    phoneNumber: Yup.string().required('Required'),
    // .test("invalid", "Phone number must be valid.", (phone) =>
    //   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
    //     phone
    //   )
    // ),
  })
  .required();
