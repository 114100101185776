import React from 'react';
import {FieldValues} from 'react-hook-form';
import NumberFormat from 'react-number-format';

import {AbstractFormItem} from '../Base/AbstractFormItem';
import {getTextInputClasses, TextInputProps} from '../Base/Models/TextInput';
import {NumberInput} from '../index';

type NumberInputProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = TextInputProps<TFieldValues, TContext> & {
  format?: string;
  mask?: string;
  allowEmptyFormatting?: boolean;
};

export function FormNumberInput<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  type = 'tel',
  placeholder,
  bgColor = 'white',
  inputClasses,
  format,
  mask = '_',
  ariaLabel,
  allowEmptyFormatting = false,
  ...rest
}: NumberInputProps<TFieldValues, TContext> & {
  type?: 'text' | 'tel' | 'password';
}) {
  return (
    <AbstractFormItem
      id={id}
      {...rest}
      render={({field: {value, onChange, onBlur}, fieldState: {error}}) => (
        <NumberFormat
          id="phoneNumber"
          name="phoneNumber"
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          className={getTextInputClasses(error, inputClasses, bgColor)}
          bgColor={bgColor}
          customInput={NumberInput}
          format={format}
          mask={mask}
          error={error?.message}
          aria-label={ariaLabel}
          allowEmptyFormatting={allowEmptyFormatting}
        />
      )}
    ></AbstractFormItem>
  );
}
