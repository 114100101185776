import {format} from 'date-fns';
import {IntercomEventTypes, MemberProfile, PverifyInfoType} from 'interfaces';

function formatDateTime(date: Date) {
  // Format date as MM-DD-YYYY
  const formattedDate = format(date, 'MM-dd-yyyy');
  const formattedDay = format(date, 'iiii');
  // Format time as 9:05PM
  const formattedTime = format(date, 'h:mmaaa');

  return `${formattedDate} ${formattedDay} ${formattedTime}`;
}

function findKeyDifferences(obj1?: Object, obj2?: Object) {
  if (!obj1 || !obj2) return [];

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  const allKeys = new Set([...keys1, ...keys2]);

  const keyDifferences = [];

  for (const key of allKeys) {
    if (obj1[key] !== obj2[key]) {
      keyDifferences.push(key);
    }
  }

  return keyDifferences;
}

// Function to track Intercom events
/**
 *
 * @param eventName IntercomEventTypes
 * @param data Object
 */
const trackIntercomEvent = (eventName: IntercomEventTypes, data?: Object) => {
  if (data)
    window.Intercom('trackEvent', eventName, {
      ...data,
      createdAt: formatDateTime(new Date()),
    });
  else
    window.Intercom('trackEvent', eventName, {
      createdAt: formatDateTime(new Date()),
    });
};

const insuranceInformationFields = [
  'companyId',
  'companyName',
  'companyType',
  'dependencyStatus',
  'memberId',
];

const insuranceAddressFields = [
  'primaryInsuredState',
  'primaryInsuredCity',
  'primaryInsuredZipcode',
  'primaryInsuredStreetAddress',
];

/**
 *
 * @param prev
 * @param curr
 */

const emitIntercomEventForInsuranceOnboarding = (
  prev: MemberProfile,
  curr: MemberProfile,
  pverify?: PverifyInfoType | MemberProfile,
) => {
  try {
    const keysChanged = findKeyDifferences(
      prev?.insuranceDetails,
      curr?.insuranceDetails,
    );

    const isThrDiff = Array.isArray(keysChanged) && keysChanged.length;
    const isDOBChanged =
      findKeyDifferences(
        prev.insuranceDetails?.dob ?? {year: '', month: '', day: ''},
        (curr.insuranceDetails as any)?.dateOfBirth,
      ).length > 0;

    if (pverify && 'errorDescription' in pverify && !pverify.errorDescription) {
      trackIntercomEvent(
        IntercomEventTypes.PVERIFY_VERIFICATION_SUCCESS,
        pverify,
      );
      window.Intercom('update', {'Attr: PVerify Status': 'Verified'});
    }

    if (
      pverify &&
      'errorDescription' in pverify &&
      !!pverify.errorDescription
    ) {
      trackIntercomEvent(
        IntercomEventTypes.PVERIFY_VERIFICATION_FAILED,
        pverify,
      );
      window.Intercom('update', {'Attr: PVerify Status': 'Failed'});
    }

    if (isDOBChanged && (curr.insuranceDetails as any)?.dateOfBirth?.day) {
      trackIntercomEvent(IntercomEventTypes.DATE_OF_BIRTH_ADDED, {
        dateOfBirth: curr.dateOfBirth,
      });
    }

    if (isThrDiff) {
      const isInsuranceInformationChanged = keysChanged.some(k =>
        insuranceInformationFields.includes(k),
      );

      const isInsuranceInAddressChanged = keysChanged.some(k =>
        insuranceAddressFields.includes(k),
      );

      if (isInsuranceInformationChanged && curr.insuranceDetails?.companyName) {
        trackIntercomEvent(IntercomEventTypes.INSURANCE_INFORMATION_ADDED, {
          companyId: curr.insuranceDetails?.companyId,
          companyName: curr.insuranceDetails?.companyName,
          companyType: curr.insuranceDetails?.companyType,
          dependencyStatus: curr.insuranceDetails?.dependencyStatus,
          memberId: curr.insuranceDetails?.memberId,
        });
      }

      if (
        isInsuranceInAddressChanged &&
        curr.insuranceDetails?.primaryInsuredCity
      ) {
        trackIntercomEvent(IntercomEventTypes.INSURANCE_ADDRESS_DETAILS_ADDED, {
          stateOfResidence: curr.insuranceDetails?.primaryInsuredState,
          city: curr.insuranceDetails?.primaryInsuredCity,
          zipCode: curr.insuranceDetails?.primaryInsuredZipcode,
          streetAddress: curr.insuranceDetails?.primaryInsuredStreetAddress,
        });
      }
    }
  } catch (error) {
    console.error('intercom event emit error');
  }
};

export {emitIntercomEventForInsuranceOnboarding, trackIntercomEvent};
