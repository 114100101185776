import React, {ReactNode, SVGProps} from 'react';

export const Svg = (props: {children: ReactNode} & SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    {...props}
  />
);
