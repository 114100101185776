import Toast from 'components/Basic/Toast';

export const fileType = (val: string) => {
  switch (val) {
    case 'application/pdf':
      return 'pdf';
    case 'text/plain':
      return 'text';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xlsx';
    case 'application/msword':
      return 'doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/zip':
      return 'zip';
    default:
      return val;
  }
};
export function imageSize(url: string) {
  const img = document.createElement('img');

  const promise: Promise<{width: number; height: number}> = new Promise(
    (resolve, reject) => {
      img.onload = () => {
        // Natural size is the actual image size regardless of rendering.
        // The 'normal' `width`/`height` are for the **rendered** size.
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Resolve promise with the width and height
        resolve({width, height});
      };

      // Reject promise on error
      img.onerror = reject;
    },
  );

  // Setting the source makes it start downloading and eventually call `onload`
  img.src = url;

  return promise;
}

export const calculateDimesions = (width: number, height: number) => {
  if (width < 272) {
    return {width, height};
  } else {
    const widthPercentage = (272 * 100) / width; //percentage of width of 272px wrt actual width
    let newHeight = (height * widthPercentage) / 100;

    if (newHeight > 200) {
      newHeight = 200;
      const heightPercentage = (newHeight * 100) / height; //percentage of height of 272px wrt actual height
      const newWidth = (width * heightPercentage) / 100;
      return {width: newWidth, height: 200};
    }

    return {width: 272, height: newHeight};
  }
};

const toDataURL = (url: string): Promise<string> =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export async function base64Img(url: string): Promise<{
  base64: string;
  dimension: {
    width: number;
    height: number;
  };
}> {
  const srcUrl = await toDataURL(url);
  const dimensions = await imageSize(url);
  const newDimensions = calculateDimesions(dimensions.width, dimensions.height);

  return new Promise(resolve => {
    const canvas = document.createElement('canvas');
    canvas.width = newDimensions.width;
    canvas.height = newDimensions.height;
    const context = canvas.getContext('2d');
    const img = document.createElement('img');
    img.src = srcUrl;
    img.onload = () => {
      context?.scale(
        newDimensions.width / img.width,
        newDimensions.height / img.height,
      );
      context?.drawImage(img, 0, 0);
      resolve({
        base64: canvas.toDataURL('image/jpeg', 0.1),
        dimension: newDimensions,
      });
    };
  });
}

export function getVideoCover(
  file: File,
  seekTo = 0.0,
): Promise<{
  base64: string;
  dimension: {
    width: number;
    height: number;
  };
}> {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', () => {
      reject('error when loading video file');
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      const timeoutRef = setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      clearTimeout(timeoutRef);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        console.log('video is now paused at %ss.', seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;

        const newDimensions = calculateDimesions(
          videoPlayer.width,
          videoPlayer.height,
        );

        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

        resolve({
          base64: ctx!.canvas.toDataURL('image/jpeg', 0.05),
          dimension: newDimensions,
        });
      });
    });
  });
}

export const videoExtensions = [
  '3g2',
  '3gp',
  'aaf',
  'asf',
  'avchd',
  'avi',
  'drc',
  'flv',
  'm2v',
  'm3u8',
  'm4p',
  'm4v',
  'mkv',
  'mng',
  'mov',
  'mp2',
  'mp4',
  'mpe',
  'mpeg',
  'mpg',
  'mpv',
  'mxf',
  'nsv',
  'ogg',
  'ogv',
  'qt',
  'rm',
  'rmvb',
  'roq',
  'svi',
  'vob',
  'webm',
  'wmv',
  'yuv',
];

export const audioExtensions = [
  'wav',
  'bwf',
  'raw',
  'aiff',
  'flac',
  'm4a',
  'pac',
  'tta',
  'wv',
  'ast',
  'aac',
  'mp2',
  'mp3',
  'mp4',
  'amr',
  's3m',
  '3gp',
  'act',
  'au',
  'dct',
  'dss',
  'gsm',
  'm4p',
  'mmf',
  'mpc',
  'ogg',
  'oga',
  'opus',
  'ra',
  'sln',
  'vox',
];

export const imageExtensions = [
  'ase',
  'art',
  'bmp',
  'blp',
  'cd5',
  'cit',
  'cpt',
  'cr2',
  'cut',
  'dds',
  'dib',
  'djvu',
  'egt',
  'exif',
  'gif',
  'gpl',
  'grf',
  'icns',
  'ico',
  'iff',
  'jng',
  'jpeg',
  'jpg',
  'jfif',
  'jp2',
  'jps',
  'lbm',
  'max',
  'miff',
  'mng',
  'msp',
  'nef',
  'nitf',
  'ota',
  'pbm',
  'pc1',
  'pc2',
  'pc3',
  'pcf',
  'pcx',
  'pdn',
  'pgm',
  'PI1',
  'PI2',
  'PI3',
  'pict',
  'pct',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'pdd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'qfx',
  'raw',
  'rle',
  'sct',
  'sgi',
  'rgb',
  'int',
  'bw',
  'tga',
  'tiff',
  'tif',
  'vtf',
  'xbm',
  'xcf',
  'xpm',
  '3dv',
  'amf',
  'ai',
  'awg',
  'cgm',
  'cdr',
  'cmx',
  'dxf',
  'e2d',
  'egt',
  'eps',
  'fs',
  'gbr',
  'odg',
  'svg',
  'stl',
  'vrml',
  'x3d',
  'sxd',
  'v2d',
  'vnd',
  'wmf',
  'emf',
  'art',
  'xar',
  'png',
  'webp',
  'jxr',
  'hdp',
  'wdp',
  'cur',
  'ecw',
  'iff',
  'lbm',
  'liff',
  'nrrd',
  'pam',
  'pcx',
  'pgf',
  'sgi',
  'rgb',
  'rgba',
  'bw',
  'int',
  'inta',
  'sid',
  'ras',
  'sun',
  'tga',
  'heic',
  'heif',
];

export function getFileExtension(filename: string) {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
}

export const ThumbnailColors = {
  a: {bg: '#FECACA', text: ''},
  b: {bg: '#FED7AA', text: ''},
  c: {bg: '#FDE68A', text: ''},
  d: {bg: '#FEF08A', text: ''},
  e: {bg: '#D9F99D', text: ''},
  f: {bg: '#BBF7D0', text: ''},
  g: {bg: '#A7F3D0', text: ''},
  h: {bg: '#99F6E4', text: ''},
  i: {bg: '#A5F3FC', text: ''},
  j: {bg: '#BAE6FD', text: ''},
  k: {bg: '#C7D2FE', text: ''},
  l: {bg: '#DDD6FE', text: ''},
  m: {bg: '#E9D5FF', text: ''},
  n: {bg: '#F5D0FE', text: ''},
  o: {bg: '#FBCFE8', text: ''},
  p: {bg: '#FECDD3', text: ''},
  q: {bg: '#93C5FD', text: ''},
  r: {bg: '#E7E5E4', text: ''},
  s: {bg: '#E5E5E5', text: ''},
  t: {bg: '#E4E4E7', text: ''},
  u: {bg: '#E5E7EB', text: ''},
  v: {bg: '#E2E8F0', text: ''},
  w: {bg: '#D8B4FE', text: ''},
  x: {bg: '#F9A8D4', text: ''},
  y: {bg: '#FDBA74', text: ''},
  z: {bg: '#7DD3FC', text: ''},
};

export const downloadFile = async (path: string, filename: string) => {
  try {
    if (typeof path !== 'string') throw new Error('Url missing');
    const res = await fetch(path);
    const data = await res.arrayBuffer();
    const contentType = res.headers.get('Content-Type') || undefined;
    const blob = new Blob([data], {type: contentType});
    // Create blob link to download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  } catch (err) {
    Toast({type: 'error', message: `Download Failed: ${err.message}`});
    throw new Error();
  }
};

export const isYoutubeUrl = (url: string) => {
  const reg = new RegExp(
    /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm,
  );
  return reg.test(url);
};
