import React, {FC, ReactNode, Suspense} from 'react';
import classNames from 'classnames';
import {
  BlueLogoHeader,
  IconsNavigation,
  MobileTabletHeader,
  ProviderVideoRightNavigation,
} from 'components';
import Modal from 'components/Basic/Modal';
import {selectAppointment} from 'features/Appointment';
import {selectUserNotification} from 'features/User';
import {useQuery} from 'hooks';
import {WherebyRoomModeTypes} from 'interfaces';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {isMobile} from 'utils';

import {MemberCallData} from './MemberData';
import RightView from './RightView';

const ActionContainer = styled.div`
  width: 100%;
  height: 4rem;
  align-items: center;
`;
const MainContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  height: ${props =>
    props.isMobile ? 'calc(100vh - 8rem)' : 'calc(100vh - 4rem)'};
`;

const WherebyProviderVideoPage: FC<{
  patientId: string;
  patientName: string;
  children: ReactNode;
}> = ({children, patientId, patientName}) => {
  const urlParams = useQuery();
  const appointmentID = urlParams.get('appointmentID') ?? '';
  const roomMode = (urlParams.get('roomMode') ?? '') as WherebyRoomModeTypes;
  const isGroupCall = roomMode === 'group';
  const {t} = useTranslation();

  const appointmentDetails = useSelector(
    selectAppointment(roomMode, Number(appointmentID)),
  );

  const {message, messageType} = useSelector(selectUserNotification);

  const appointmentTitle = isGroupCall
    ? appointmentDetails?.notes || t('groupAppointment')
    : t('talkToProvider', {provider: patientName});

  return (
    <>
      <Helmet>
        <title>
          {isGroupCall ? t('title.groupCall') : t('title.patientCall')}
        </title>
      </Helmet>
      {messageType === 'none' ? null : (
        <Suspense fallback={<div />}>
          <Modal message={message} messageType={messageType} isOpen={true} />
        </Suspense>
      )}
      {/* Mobile and Tablet */}
      <section className="w-full h-screen grid grid-cols-14">
        <div
          className={classNames('hidden lg:block', {
            'col-span-1': isGroupCall,
            'col-span-4': !isGroupCall,
          })}
        >
          <ActionContainer className="border-b flex">
            <BlueLogoHeader classes="pl-4" imgClasses="w-16 h-16" />
          </ActionContainer>
          <MainContainer
            className="flex relative break-words"
            isMobile={isMobile}
          >
            <IconsNavigation
              classes={classNames('relative', {'w-full border-r': isGroupCall})}
            />
            {isGroupCall ? null : <MemberCallData patientId={patientId} />}
          </MainContainer>
        </div>
        <div
          className={classNames('col-span-14 border-r relative', {
            'lg:col-span-9': isGroupCall,
            'lg:col-span-6 border-l': !isGroupCall,
          })}
        >
          <ActionContainer className="border-b flex">
            <MobileTabletHeader headerTitle={appointmentTitle} />
            <h1 className="text-3xl font-medium capitalize text-center w-full lg:line-clamp-1 hidden lg:block">
              {appointmentTitle}
            </h1>
          </ActionContainer>
          <MainContainer
            isMobile={isMobile}
            className="relative overflow-y-auto"
          >
            {children}
          </MainContainer>
        </div>
        <div className="hidden lg:block col-span-4">
          {isGroupCall ? (
            <RightView />
          ) : (
            <ProviderVideoRightNavigation patientId={patientId} />
          )}
        </div>
      </section>
    </>
  );
};

export default WherebyProviderVideoPage;
