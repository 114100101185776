import React from 'react';
import {FieldValues} from 'react-hook-form';
import PhoneInput, {CountryData} from 'react-phone-input-2';

import {AbstractFormItem} from '../Base/AbstractFormItem';
import {TextInputProps} from '../Base/Models/TextInput';

type PhoneInputProps<
  TFieldValues extends FieldValues,
  TContext extends object,
> = TextInputProps<TFieldValues, TContext> & {
  onlyCountries?: string[];
  disableCountryGuess?: boolean;
  onCountryChange?: (countryCode: string) => void;
};

export function FormPhoneInputWithFlag<
  TFieldValues extends FieldValues,
  TContext extends object,
>({
  id,
  onlyCountries,
  disableCountryGuess,
  onCountryChange,
  ...rest
}: PhoneInputProps<TFieldValues, TContext> & {
  type?: 'text' | 'tel' | 'password';
}) {
  return (
    <AbstractFormItem
      id={id}
      {...rest}
      render={({field: {value, onChange, onBlur}}) => (
        <PhoneInput
          country="us"
          onlyCountries={onlyCountries}
          disableCountryGuess={disableCountryGuess}
          value={value}
          onChange={(phone: string, countryData: CountryData) => {
            onChange(phone);
            if (countryData && onCountryChange) {
              const countryCode = (countryData as CountryData)?.countryCode;
              onCountryChange(countryCode.toUpperCase());
            }
          }}
          countryCodeEditable={false}
          onBlur={onBlur}
          inputProps={{
            name: id,
            required: true,
          }}
          inputStyle={{
            width: '100%',
            height: '40px',
            border: '1px solid #E1E1E1',
            borderRadius: '7px',
            paddingLeft: '3rem',
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 500,
            lineHeight: '17px',
          }}
          buttonStyle={{
            borderRight: 'none',
            borderColor: '#E1E1E1',
            background: 'none',
          }}
        />
      )}
    ></AbstractFormItem>
  );
}
