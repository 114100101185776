import {createAction} from '@reduxjs/toolkit';

export const validatePasswordToken = createAction<string>(
  'ValidatePasswordToken',
);
export const validatePasswordTokenSuccess = createAction(
  'ValidatePasswordTokenSuccess',
);
export const validatePasswordTokenFailure = createAction(
  'ValidatePasswordTokenFailure',
);
