import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {userActions} from 'features/User/userSlice';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {AuthService} from 'services/api';

const requestPasswordTokenEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.requestPasswordToken.match),
    switchMap(({payload}) =>
      from(
        AuthService.requestPasswordToken({
          email: payload.email.toLowerCase(),
        }),
      ).pipe(
        mergeMap(() => [
          userActions.requestPasswordTokenSuccess(),
          userActions.setNotification({
            message: `A mail has been sent to ${payload.email.toLowerCase()} 📬`,
            messageType: 'success',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.requestPasswordTokenFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

const validateResetTokenEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.validatePasswordToken.match),
    switchMap(({payload}) =>
      from(
        AuthService.validatePasswordToken({
          token: payload,
        }),
      ).pipe(
        mergeMap(() => [
          userActions.validatePasswordTokenSuccess(),
          push({
            pathname: '/reset-password',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.validatePasswordTokenFailure()),
            of(userActions.setNotification({messageType: 'error', message})),
          ),
        ),
      ),
    ),
  );

const changePasswordEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(userActions.changePassword.match),
    switchMap(({payload}) =>
      from(
        AuthService.changePassword({
          newPassword: payload.password,
          confirmPassword: payload.confirmPassword,
        }),
      ).pipe(
        mergeMap(() => [
          userActions.changePasswordSuccess(),
          userActions.setNotification({
            message: 'Your password has been updated 🔐.',
            messageType: 'success',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.changePasswordFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const resetPasswordEpics = [
  requestPasswordTokenEpic,
  validateResetTokenEpic,
  changePasswordEpic,
];
