import React, {FC} from 'react';
import {FormError, FormLabel, LoadingSpinner} from 'components';
import {addMonths} from 'date-fns';
import {BookingCalendarContainer} from 'features/Appointment/Calendar/CalendarContainer.styles';
import {motion} from 'framer-motion';
import {useBooking} from 'hooks';
import {useCalendarLocalization} from 'hooks/useCalendarLocalization';
import DayPicker, {DayModifiers, Modifier} from 'react-day-picker';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

type Props = {
  isAvailabilityLoading: boolean;
  onMonthChange?: (month: Date) => void;
} & Partial<ReturnType<typeof useBooking>>;

const now = new Date();

export const BookingCalendar: FC<Props> = ({
  control,
  isAvailabilityLoading,
  disabledDays,
  onMonthChange,
}: Props) => {
  const {t} = useTranslation();
  const {months, weekdaysLong, weekdaysShort, locale, firstDayOfWeek} =
    useCalendarLocalization();
  return (
    <motion.article
      className="transform ease-in-out duration-1000 relative"
      animate={{opacity: 1, y: 0}}
      initial={{opacity: 0, y: 100}}
    >
      <FormLabel htmlFor="date">&nbsp;</FormLabel>
      <BookingCalendarContainer>
        <Controller
          control={control}
          name="date"
          render={({field: {onChange, onBlur, value}}) => {
            const handleDayClick = (day: Date, modifiers: DayModifiers) => {
              if (modifiers.disabled || modifiers.selected) {
                return;
              }
              onChange(day);
            };

            return (
              <DayPicker
                showOutsideDays
                month={now}
                fromMonth={now}
                disabledDays={disabledDays}
                toMonth={addMonths(now, 2)}
                onDayClick={handleDayClick}
                onBlur={onBlur}
                selectedDays={value as Modifier}
                weekdaysLong={weekdaysLong}
                weekdaysShort={weekdaysShort}
                months={months}
                firstDayOfWeek={firstDayOfWeek}
                locale={locale}
                onMonthChange={onMonthChange}
              />
            );
          }}
        />
        {isAvailabilityLoading ? (
          <div className="w-full h-full bg-white bg-opacity-70 z-999 absolute top-0 left-0 flex flex-col justify-center items-center">
            <LoadingSpinner type="Oval" width={20} height={20} />
            <p className="text-sm text-gray-700 pt-3">
              {t('loadingAvailability')}
            </p>
          </div>
        ) : null}
      </BookingCalendarContainer>
      <FormError />
    </motion.article>
  );
};
