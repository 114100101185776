import React, {
  FC,
  Fragment,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
} from 'react';
import {
  DesktopLeftNavigation,
  DesktopRightNavigation,
  DigitalPracticeSplitFeeAlert,
  MobileTabletNavigation,
} from 'components';
import {selectReleaseNotes, userActions} from 'features/User';
import ReleaseNotesInfo from 'features/User/ReleaseNote';
import {useWindowSize} from 'hooks';
import {useDispatch, useSelector} from 'react-redux';

export const DashboardLayout: FC<{children: ReactNode[] | ReactNode}> = ({
  children,
}) => {
  const {width} = useWindowSize();
  const releaseNotes = useSelector(selectReleaseNotes);
  const notesExist = releaseNotes.data.length > 0;

  const dispatch = useDispatch();

  const getReleaseNotes = useCallback(() => {
    if (releaseNotes.data.length === 0) {
      dispatch(userActions.getReleaseNotes());
    }
  }, [releaseNotes]);

  useEffect(() => {
    getReleaseNotes();
  }, []);

  return (
    <Fragment>
      {notesExist ? <ReleaseNotesInfo releaseNotes={releaseNotes} /> : null}
      <DigitalPracticeSplitFeeAlert />
      <main className="min-w-full bg-white grid grid-cols-1 lg:grid-cols-10 h-screen overflow-hidden ">
        <section className="hidden col-span-3 lg:block lg:border-r h-screen border-gray-200">
          <DesktopLeftNavigation />
        </section>
        <Suspense>{children}</Suspense>
        <section className="hidden lg:block lg:col-start-8 col-span-3 lg:border-l  border-gray-200 h-screen">
          <DesktopRightNavigation />
        </section>
        {width && width < 1025 ? <MobileTabletNavigation /> : null}
      </main>
    </Fragment>
  );
};
