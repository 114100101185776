import React, {FC, useEffect, useState} from 'react';
import LogoWhiteWebp from 'assets/images/logo-white.webp';
// import LogoWhiteWebp3x from 'assets/images/logo-white@3x.webp'
import LogoWhiteWebp4x from 'assets/images/logo-white@4x.webp';
import {DefaultLayout} from 'components/Layouts';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import {LoadingSpinner} from './SmallLoader';

const layoutStyles = {
  lgColumns: 'lg:grid-cols-10',
  bgColor: 'bg-white',
};

export const FullLoader: FC = () => {
  const [displayLoader, setDisplayLoader] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayLoader(true);
    }, 500);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  if (displayLoader)
    return (
      <DefaultLayout {...layoutStyles}>
        <section className="col-span-10 overflow-y-auto">
          <div className="flex flex-col h-full items-center justify-center gap-y-2 bg-blue-600">
            <LazyLoadImage
              width={184}
              height={143}
              placeholderSrc={LogoWhiteWebp}
              src={LogoWhiteWebp4x}
              className="max-w-xs mx-auto object-contain"
              alt="Wellnite Logo links to home"
            />
            <LoadingSpinner color="#fff" />
          </div>
        </section>
      </DefaultLayout>
    );
  return null;
};
