import React, {FC} from 'react';
import {selectUserProfile} from 'features/User';
import {UserRoles} from 'interfaces';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {isMember} from 'utils';

import {IconNavigationMenuItems} from '../navigationConstants';
import {NavLink} from '../NavLink';

type Props = {
  classes?: string;
};

export const IconsNavigation: FC<Props> = ({classes = 'fixed'}) => {
  const location = useLocation();
  const {pathname} = location;
  const user = useSelector(selectUserProfile);
  return isMember(user) ? (
    <nav className={`hidden ${classes} h-full lg:block bg-white flex-shrink`}>
      {IconNavigationMenuItems(pathname)[UserRoles.member].map(
        ({pathname, linkName, classNames, current, icon: Icon, first}, idx) => (
          <NavLink
            key={idx}
            pathname={pathname}
            current={current}
            linkName={linkName}
            classNames={classNames}
            first={first}
          >
            <Icon strokeColor={current ? 'text-blue-600' : undefined} />
          </NavLink>
        ),
      )}
    </nav>
  ) : user?.role === UserRoles.therapist ||
    user?.role === UserRoles.prescriber ? (
    <nav className={`hidden ${classes} h-full lg:block bg-white flex-shrink`}>
      {IconNavigationMenuItems(pathname)[user?.role].map(
        ({pathname, linkName, classNames, current, icon: Icon, first}, idx) => (
          <NavLink
            pathname={pathname}
            current={current}
            linkName={linkName}
            classNames={classNames}
            first={first}
            key={idx}
          >
            <Icon strokeColor={current ? 'text-blue-600' : undefined} />
          </NavLink>
        ),
      )}
    </nav>
  ) : null;
};
