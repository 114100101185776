import {
  UserRoles,
  WidgetsPatientsDetails,
  WidgetsProviderDetails,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const getWidgetPatientsDetails = createAction<{role: UserRoles}>(
  'getWidgetPatientsDetails',
);
export const getWidgetPatientsDetailsSuccess =
  createAction<WidgetsPatientsDetails>('getWidgetPatientsDetailsSuccess');
export const getWidgetPatientsDetailsFailure = createAction(
  'getWidgetPatientsDetailsFailure',
);

export const getWidgetProviderDetails = createAction<{role: UserRoles}>(
  'getWidgetProviderDetails',
);
export const getWidgetProviderDetailsSuccess =
  createAction<WidgetsProviderDetails>('getWidgetProviderDetailsSuccess');
export const getWidgetProviderDetailsFailure = createAction(
  'getWidgetProviderDetailsFailure',
);
