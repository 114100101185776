import {CreatePostSchema, SharePostSchema} from 'definitions/Yup';
import * as Yup from 'yup';

export type CreatePostFormData = Yup.InferType<typeof CreatePostSchema>;
export type SharePostFormData = Yup.InferType<typeof SharePostSchema>;

export type Post = {
  caption?: string;
  content?: string;
  timestamp: number;
  id: string;
  poster: {[key: string]: string | null};
  image?: string | null;
  lastLiker?: string | null;
  likeCount?: number;
  isLiked?: boolean;
  bookmarkType?: string;
  postShared?: string;
};

export enum CommunityTabs {
  feed = 'feed',
  yourPosts = 'yourPosts',
  savedPosts = 'savedPosts',
}

export enum PostSharedOptions {
  shareApproved = 'shareApproved',
  shareDeclined = 'shareDeclined',
  shareRequested = 'shareRequested',
}
