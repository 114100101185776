import {useMemo} from 'react';
import {RootState, StateSlices} from 'app/rootReducer';
import {makeSliceError} from 'app/selectors';
import {userActions} from 'features/User';
import {useSelector} from 'react-redux';

import {ActionCreatorWithPayload} from '@reduxjs/toolkit';

export function useError(filter: StateSlices): {
  apiError: string;
  resetAsyncError: ActionCreatorWithPayload<string, string>;
} {
  const selectModelLoading = useMemo(makeSliceError, []);
  const apiError = useSelector((state: RootState) =>
    selectModelLoading(state, filter),
  );
  const {resetAsyncError} = userActions;
  return {apiError, resetAsyncError};
}
