import * as Yup from 'yup';

export const UpdateProviderProfileShema = Yup.object()
  .shape({
    email: Yup.string()
      .trim()
      .min(2, 'validations.min')
      .max(50, 'validations.max')
      .email('validations.invalidEmail')
      .required('validations.required'),
    password: Yup.string()
      .min(8, 'validations.min')
      .max(50, 'validations.max')
      .required('validations.required'),

    confirmPassword: Yup.string()
      .required('validations.required')
      .test(
        'passwords-match',
        'validations.passwordMatch',
        function validatePassword(value) {
          return this.parent.password === value;
        },
      ),
  })
  .required();

export const UpdateProviderPreferredLanguageShema = Yup.object()
  .shape({
    preferredLanguage: Yup.string().required('validations.required'),
  })
  .required();

export const UpdateProviderProfileInfoSchema = Yup.object().shape({
  expertSummary: Yup.string().required('validations.required'),
  pronouns: Yup.string().optional(),
  bio: Yup.string().optional(),
  education: Yup.string().optional(),
  therapyTypes: Yup.array().of(Yup.string()).optional(),
  conditions: Yup.array().of(Yup.string()).optional(),
  languages: Yup.array().of(Yup.string()).optional(),
  ethnicity: Yup.array().of(Yup.string()).optional(),
  specificCulturalValues: Yup.string().optional(),
});
