import React from 'react';
import {selectUserProfile} from 'features/User';
import {
  ProviderRevenuePaymentsAppointmentTabs,
  TherapistProfile,
  UserRoles,
} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {history} from 'utils';
export function DigitalPracticeSplitFeeAlert() {
  const user = useSelector(selectUserProfile) as TherapistProfile;
  const {t} = useTranslation();

  const shouldShow =
    user?.role === UserRoles.therapist &&
    user.paypalMerchantId &&
    !user.feePercentage;

  const onSetPlatformFee = () => {
    history.push(
      `/provider/profile/revenue?tab=${ProviderRevenuePaymentsAppointmentTabs.digitalPracticeSessions}`,
    );
  };

  if (shouldShow) {
    return (
      <div
        className="w-full pointer-events-none absolute top-4 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 z-[9999]"
        onClick={onSetPlatformFee}
      >
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="text-sm leading-6 text-white">
            <a href="#">
              <strong className="font-semibold">
                {t('alert.platformFee.title')}
              </strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
              {t('alert.platformFee.message')}&nbsp;
              <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
      </div>
    );
  }

  return null;
}
