import React from 'react';

import {SvgIcon} from './SvgIcon';

export const PayPalIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 302"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fill="#27346A"
      d="M217.168 23.507C203.234 7.625 178.046.816 145.823.816h-93.52A13.393 13.393 0 0 0 39.076 12.11L.136 259.077c-.774 4.87 2.997 9.28 7.933 9.28h57.736l14.5-91.971-.45 2.88c1.033-6.501 6.593-11.296 13.177-11.296h27.436c53.898 0 96.101-21.892 108.429-85.221.366-1.873.683-3.696.957-5.477-1.556-.824-1.556-.824 0 0 3.671-23.407-.025-39.34-12.686-53.765"
    ></path>
    <path
      fill="#27346A"
      d="M102.397 68.84a11.737 11.737 0 0 1 5.053-1.14h73.318c8.682 0 16.78.565 24.18 1.756a101.6 101.6 0 0 1 6.177 1.182 89.928 89.928 0 0 1 8.59 2.347c3.638 1.215 7.026 2.63 10.14 4.287 3.67-23.416-.026-39.34-12.687-53.765C203.226 7.625 178.046.816 145.823.816H52.295C45.71.816 40.108 5.61 39.076 12.11L.136 259.068c-.774 4.878 2.997 9.282 7.925 9.282h57.744L95.888 77.58a11.717 11.717 0 0 1 6.509-8.74z"
    ></path>
    <path
      fill="#2790C3"
      d="M228.897 82.749c-12.328 63.32-54.53 85.221-108.429 85.221H93.024c-6.584 0-12.145 4.795-13.168 11.296L61.817 293.621c-.674 4.262 2.622 8.124 6.934 8.124h48.67a11.71 11.71 0 0 0 11.563-9.88l.474-2.48 9.173-58.136.591-3.213a11.71 11.71 0 0 1 11.562-9.88h7.284c47.147 0 84.064-19.154 94.852-74.55 4.503-23.15 2.173-42.478-9.739-56.054-3.613-4.112-8.1-7.508-13.327-10.28-.283 1.79-.59 3.604-.957 5.477z"
    ></path>
    <path
      fill="#1F264F"
      d="M216.952 72.128a89.928 89.928 0 0 0-5.818-1.49 109.904 109.904 0 0 0-6.177-1.174c-7.408-1.199-15.5-1.765-24.19-1.765h-73.309a11.57 11.57 0 0 0-5.053 1.149 11.683 11.683 0 0 0-6.51 8.74l-15.582 98.798-.45 2.88c1.025-6.501 6.585-11.296 13.17-11.296h27.444c53.898 0 96.1-21.892 108.428-85.221.367-1.873.675-3.688.958-5.477-3.122-1.648-6.501-3.072-10.14-4.279a83.26 83.26 0 0 0-2.77-.865"
    ></path>
  </svg>
);

export const StripeIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fill="#646FDE"
      d="M11.106 18.592c-2.215 0-5.077-.914-7.324-2.133v6.022A18.597 18.597 0 0 0 11.102 24c5.564 0 9.398-2.39 9.398-7.198 0-7.976-10.229-6.547-10.229-9.556l-.001-.001c0-1.045.873-1.448 2.271-1.448 2.036 0 4.621.623 6.658 1.72V1.223C16.981.337 14.766 0 12.547 0 7.118 0 3.5 2.83 3.5 7.564c0 7.401 10.173 6.201 10.173 9.392 0 1.238-1.074 1.636-2.567 1.636z"
    ></path>
  </svg>
);

export const PaystackIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28.47 28"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      fill="#0ba4db"
      d="M25.51,0H1.48C.67,0,0,.67,0,1.48v2.67c0,.81,.67,1.48,1.48,1.48H25.43c.81,0,1.48-.67,1.48-1.48V1.48c.07-.81-.59-1.48-1.4-1.48Zm0,14.89H1.48c-.81,0-1.48,.67-1.48,1.48v2.67c0,.81,.67,1.48,1.48,1.48H25.43c.81,0,1.48-.67,1.48-1.48v-2.67c.07-.81-.59-1.48-1.4-1.48h0Zm-10.5,7.48H1.48c-.81,0-1.48,.67-1.48,1.48v2.67c0,.81,.67,1.48,1.48,1.48H15.01c.81,0,1.48-.67,1.48-1.48v-2.67c0-.81-.67-1.48-1.48-1.48h0ZM26.99,7.48H1.48c-.81,0-1.48,.67-1.48,1.48v2.67c0,.81,.67,1.48,1.48,1.48H26.99c.81,0,1.48-.67,1.48-1.48v-2.67c0-.81-.67-1.48-1.48-1.48Z"
    />
  </svg>
);

export const DeelIcon: SvgIcon = ({
  width = 28,
  height = 28,
  strokeColor = '#5938B8',
  fillColor = '#FFF1DA',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <g clipPath="url(#clip0_4145_277)">
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26802 21.732 0 14 0C6.26802 0 0 6.26802 0 14C0 21.732 6.26802 28 14 28Z"
        fill={fillColor}
      />
      <path
        d="M19.7749 18.5312H22.6382V21.2852H19.7749V18.5312Z"
        fill={strokeColor}
      />
      <path
        d="M5.12695 15.4397C5.12695 11.1788 7.86929 9.28529 10.8763 9.28529C13.6207 9.28529 14.778 11.0148 14.778 11.0148V5.90625H17.7504V18.4958C17.7504 19.5292 17.7863 20.4618 17.859 21.2933H14.779V19.8803C14.779 19.8803 13.5995 21.5942 10.8773 21.5942C7.97507 21.5942 5.12695 19.9425 5.12695 15.4397ZM11.6355 19.332C13.7407 19.332 15.1017 17.7428 15.1017 15.4397C15.1017 13.0561 13.7398 11.5474 11.6355 11.5474C9.53126 11.5474 8.21957 12.9816 8.21957 15.4397C8.21957 17.8979 9.58653 19.332 11.6355 19.332Z"
        fill={strokeColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_4145_277">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
