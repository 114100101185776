import * as Yup from 'yup';

export const OneProviderOnboardingSchema = Yup.object().shape({
  providerAcceptableNoOfClients: Yup.number()
    .required('Required')
    .typeError('Number of clients must be a valid number'),
  from: Yup.object().required('From time is required'),
  to: Yup.object().required('To time is required'),

  timezone: Yup.object().required('Timezone is required'),
  startTime: Yup.object().required('Start time is required'),
  endTime: Yup.object().required('End time is required'),
  backgroundCheckDisclaimer: Yup.boolean().oneOf(
    [true],
    'You must agree to the onboarding disclaimer',
  ),
});

export const TwoProviderCaqhFormSchema = Yup.object().shape({
  caqhOption: Yup.string()
    .oneOf(['yes', 'no'])
    .required('Please Select an option'),
  professionalReference: Yup.string().required(
    'Professional Reference is required',
  ),
  culturalTrainingOption: Yup.string()
    .oneOf(['yes', 'no'])
    .required('Please Select an option'),
  culturalTrainingDate: Yup.date()
    .nullable()
    .when('culturalTrainingOption', {
      is: 'yes',
      then: Yup.date()
        .required('Required')
        .max(new Date(), 'Date must be in the past'),
      otherwise: Yup.date().nullable(),
    }),
  caqhUn: Yup.string().when('caqhOption', {
    is: 'yes',
    then: Yup.string().required('CAQH Username is required'),
    otherwise: Yup.string(),
  }),
  caqhPw: Yup.string().when('caqhOption', {
    is: 'yes',
    then: Yup.string().required('CAQH Password is required'),
    otherwise: Yup.string(),
  }),
  educationAndTraining: Yup.string().when('caqhOption', {
    is: 'no',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  providerDOB: Yup.date()
    .nullable()
    .when('caqhOption', {
      is: 'no',
      then: Yup.date()
        .required('Required')
        .max(new Date(), 'Date of Birth must be in the past'),
      otherwise: Yup.date().nullable(),
    }),
  homeAddress: Yup.string().when('caqhOption', {
    is: 'no',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  city: Yup.string().when('caqhOption', {
    is: 'no',
    then: Yup.string().required('Required'),
    otherwise: Yup.string(),
  }),
  state: Yup.object()
    .shape({label: Yup.string(), value: Yup.string()})
    .when('caqhOption', {
      is: 'no',
      then: Yup.object().required('Required'),
      otherwise: Yup.object(),
    }),
  providerSSN: Yup.string().when('caqhOption', {
    is: 'no',
    then: Yup.string()
      .required('Required')
      .matches(/^\d{9}$/, 'Invalid SSN format, it should be 9 digits'),
    otherwise: Yup.string(),
  }),
  zipCode: Yup.string().when('caqhOption', {
    is: 'no',
    then: Yup.string()
      .required('Required')
      .matches(
        /^\d{5}(?:[-\s]\d{4})?$/,
        'Invalid ZIP code format (use ##### or #####-####)',
      ),
    otherwise: Yup.string(),
  }),
  taxonomyCode: Yup.string().when('caqhOption', {
    is: 'no',
    then: Yup.string()
      .trim()
      .required('Required')
      .matches(
        /^[a-zA-Z0-9]{10}$/,
        'Taxonomy code must be alphanumeric and 10 characters long',
      ),
    otherwise: Yup.string(),
  }),
});
