import dayjs from 'dayjs';
import {
  AvailabilityData,
  AvailabilityInString,
  AvailabilityInTimeRange,
  BlockAvailabilityReq,
  BlockedAvailability,
  EndPoints,
  HttpMethods,
  ProviderRole,
  RawAvailabilityData,
  SpecificDayAvailabilityData,
  SpecificDayAvailabilityResponse,
} from 'interfaces';
import {requestHandler} from 'services/api';
import {
  deserializeAcuityData,
  deserializeGetAvailabilityData,
  serializeAcuityData,
  unwrapAPIError,
} from 'utils';

const getProviderAvailabilityHours = async (
  role: ProviderRole,
): Promise<AvailabilityData> => {
  try {
    const res = await requestHandler<{
      message: RawAvailabilityData;
    }>({
      method: HttpMethods.GET,
      url: `/api/${role}/${EndPoints.ProviderAvailability}` as unknown as EndPoints,
    });

    return deserializeGetAvailabilityData(res.data.message);
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const saveProviderAvailabilityHours = async ({
  data,
  role,
}: {
  data: AvailabilityInTimeRange;
  role: ProviderRole;
}): Promise<AvailabilityInTimeRange> => {
  try {
    const dataToSend = serializeAcuityData(data);

    const res = await requestHandler<{
      message: AvailabilityInString;
    }>({
      method: HttpMethods.PUT,
      url: `/api/${role}/${EndPoints.ProviderAvailabilityHours}` as unknown as EndPoints,
      data: dataToSend,
    });

    return deserializeAcuityData(res.data.message);
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const blockProviderAvailability = async ({
  data,
  role,
}: {
  data: BlockAvailabilityReq;
  role: ProviderRole;
}): Promise<BlockedAvailability> => {
  try {
    const res = await requestHandler<{
      message: BlockedAvailability;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}/${EndPoints.ProviderAvailabilityBlocks}` as unknown as EndPoints,
      data: {
        ...data,
        // Remove the utc offset so that date is stored in acuity calendar timezone
        startDate: dayjs(data.startDate).format('YYYY-MM-DDTHH:mm:ss'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DDTHH:mm:ss'),
      },
    });

    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const unblockProviderAvailability = async ({
  blockId,
  role,
}: {
  blockId: number;
  role: ProviderRole;
}): Promise<{message: string}> => {
  try {
    const res = await requestHandler<{
      message: string;
    }>({
      method: HttpMethods.DELETE,
      url: `/api/${role}/${EndPoints.ProviderAvailabilityBlocks}?blockId=${blockId}` as unknown as EndPoints,
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const setSpecificDayAvailability = async ({
  role,
  specificDayAvailability,
}: {
  role: ProviderRole;
  specificDayAvailability: SpecificDayAvailabilityData;
}): Promise<{message: SpecificDayAvailabilityResponse}> => {
  try {
    const res = await requestHandler<{
      message: SpecificDayAvailabilityResponse;
    }>({
      method: HttpMethods.POST,
      url: `/api/${role}/${EndPoints.SetSpecificDayAvailability}` as unknown as EndPoints,
      data: {...specificDayAvailability},
    });

    return res.data;
  } catch (error) {
    const errorVal = unwrapAPIError(error);
    return Promise.reject(errorVal);
  }
};

export const AvailabilityService = {
  getProviderAvailabilityHours,
  saveProviderAvailabilityHours,
  blockProviderAvailability,
  unblockProviderAvailability,
  setSpecificDayAvailability,
};
