import {DigitalPracticeProvider, SliceStatus} from 'interfaces';

import {
  ActionReducerMapBuilder,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';

export const getDigitalPracticeProviderData = createAction<{
  providerId: string;
  isReferralLink?: boolean;
}>('getDigitalPracticeProviderData');
export const getDigitalPracticeProviderDataSuccess =
  createAction<DigitalPracticeProvider>(
    'getDigitalPracticeProviderDataSuccess',
  );
export const getDigitalPracticeProviderDataFailure = createAction<string>(
  'getDigitalPracticeProviderDataFailure',
);

const stateLoadingDone = createAction<{
  state: {
    ui: {
      status: SliceStatus;
    };
  };
  isCacheAvailable: boolean;
}>('StateLoadingDone');

type UISliceState = {
  status: SliceStatus;
  digitalPracticeProvider: {
    data: {};
    status: SliceStatus;
  };
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    status: SliceStatus.idle,
    digitalPracticeProvider: {
      data: {} as DigitalPracticeProvider,
      status: SliceStatus.idle,
    },
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<UISliceState>) =>
    builder
      .addCase(getDigitalPracticeProviderData, state => ({
        ...state,
        digitalPracticeProvider: {
          ...state.digitalPracticeProvider,
          status: SliceStatus.pending,
        },
      }))
      .addCase(getDigitalPracticeProviderDataSuccess, (state, action) => ({
        ...state,
        digitalPracticeProvider: {
          ...state.digitalPracticeProvider,
          data: action.payload,
          status: SliceStatus.resolved,
        },
      }))
      .addCase(getDigitalPracticeProviderDataFailure, state => ({
        ...state,
        digitalPracticeProvider: {
          ...state.digitalPracticeProvider,
          status: SliceStatus.rejected,
        },
      }))
      .addDefaultCase(state => state),
});

export const {reducer: uiReducer, name: uiReducerName} = uiSlice;

export type TUIActions =
  | ReturnType<typeof getDigitalPracticeProviderData>
  | ReturnType<typeof getDigitalPracticeProviderDataSuccess>
  | ReturnType<typeof getDigitalPracticeProviderDataFailure>;

export const uiActions = {
  stateLoadingDone,
  getDigitalPracticeProviderData,
  getDigitalPracticeProviderDataSuccess,
  getDigitalPracticeProviderDataFailure,
};

export type UiState = ReturnType<typeof uiReducer>;
