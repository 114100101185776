import React, {FC} from 'react';
import {selectUserProfile} from 'features/User';
import {DigitalPracticeAccountTypes} from 'interfaces';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

export function withPaystackMember(Component: FC): () => React.ReactElement {
  const Wrapper = () => {
    const user = useSelector(selectUserProfile);

    if (
      Array.isArray(user?.paystackCards) &&
      user?.paystackDefaultCard &&
      user?.digitalPracticeAccountType ===
        DigitalPracticeAccountTypes.outOfPocket
    ) {
      return <Redirect to="/dashboard" />;
    }

    return <Component />;
  };

  return Wrapper;
}
