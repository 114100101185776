import {
  AirtableProviderDataTypes,
  InsuranceAndColleagues,
  UserRoles,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

// Creating actions for Digital Practice
export const getAirtableProviderFieldsOptions = createAction(
  'getAirtableProviderFieldsOptions',
);

export const getAirtableProviderFieldsOptionsSuccess = createAction<{
  [key: string]: string[];
}>('getAirtableProviderFieldsOptionsSuccess');

export const getAirtableProviderFieldsOptionsFailure = createAction(
  'getAirtableProviderFieldsOptionsFailure',
);

export const getAirtableProviderData = createAction<UserRoles>(
  'getAirtableProviderData',
);

export const getAirtableProviderDataSuccess =
  createAction<AirtableProviderDataTypes>('getAirtableProviderDataSuccess');

export const getAirtableProviderDataFailure = createAction(
  'getAirtableProviderDataFailure',
);

export const updateAirtableProviderData = createAction<{
  data: AirtableProviderDataTypes;
  cb: () => void;
}>('updateAirtableProviderData');

export const updateAirtableProviderDataSuccess =
  createAction<AirtableProviderDataTypes>('updateAirtableProviderDataSuccess');

export const updateAirtableProviderDataFailure = createAction<string>(
  'updateAirtableProviderDataFailure',
);

export const getInsuranceAndColleagues = createAction(
  'getInsuranceAndColleagues',
);

export const getInsuranceAndColleaguesSuccess =
  createAction<InsuranceAndColleagues>('getInsuranceAndColleaguesSuccess');

export const getInsuranceAndColleaguesFailure = createAction(
  'getInsuranceAndColleaguesFailure',
);
