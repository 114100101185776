export const addMinutesToTime = (
  timeString: string,
  minutesToAdd: number,
): string => {
  // Split the time string into hours, minutes, and am/pm
  const [time, ampm] = timeString.split(/(?=[ap]m)/i);
  const [hours, minutes] = time.split(':').map(Number);

  // Convert time to 24-hour format for easier manipulation
  const hours24 = (hours % 12) + (ampm.toLowerCase() === 'pm' ? 12 : 0);

  // Calculate the total minutes
  const totalMinutes = hours24 * 60 + minutes + minutesToAdd;

  // Convert back to hours and minutes
  let newHours = Math.floor(totalMinutes / 60) % 24;
  const newMinutes = totalMinutes % 60;

  // Format the result
  const newAmPm = newHours < 12 ? 'am' : 'pm';
  newHours = newHours % 12 || 12;
  const formattedTime = `${newHours}:${String(newMinutes).padStart(
    2,
    '0',
  )}${newAmPm}`;

  return formattedTime;
};
