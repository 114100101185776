import {selectUserProfile} from 'features/User';
import {AppointmentDurations, AppointmentTypes, UserRoles} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {isMember} from 'utils';

const providerCostWarning = 'providerCostWarning';
const memberCostWarning = 'memberCostWarning';

type SessionsType = {
  label: string;
  desc: {
    [UserRoles.member]?: string;
    [UserRoles.prescriber]?: string;
    [UserRoles.therapist]?: string;
  };
  duration: AppointmentDurations;
  type: AppointmentTypes;
};

const doctorSessions: SessionsType[] = [
  {
    label: 'Doctor Consultation',
    desc: {
      [UserRoles.member]: memberCostWarning,
      [UserRoles.prescriber]: providerCostWarning,
    },
    duration: AppointmentDurations.ten,
    type: AppointmentTypes.doctor_consultation,
  },
];

const groupTherapySessions: SessionsType[] = [
  {
    label: '60 minutes Group Session',
    desc: {
      [UserRoles.therapist]: providerCostWarning,
    },
    duration: AppointmentDurations.sixty,
    type: AppointmentTypes.group_call_with_therapist,
  },
];

const bookRecurringSessions: SessionsType[] = [
  AppointmentDurations.thirty,
  AppointmentDurations.forty_five,
  AppointmentDurations.sixty,
].map((duration: AppointmentDurations) => ({
  label: 'Book Recurring Session',
  desc: {
    [UserRoles.member]: memberCostWarning,
    [UserRoles.therapist]: providerCostWarning,
  },
  type: AppointmentTypes.recurring_session,
  duration,
}));

const therapySessions: SessionsType[] = [
  AppointmentDurations.thirty,
  AppointmentDurations.forty_five,
  AppointmentDurations.sixty,
].map((duration: AppointmentDurations) => ({
  desc: {
    [UserRoles.member]: memberCostWarning,
    [UserRoles.therapist]: providerCostWarning,
  },
  type: AppointmentTypes.video_call_with_therapist,
  label: `${duration} minutes Session`,
  duration,
}));

export const useSessionTypes = () => {
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();

  return {
    getSessions: (
      apptType?: AppointmentTypes,
      legacy: boolean = false,
    ): {
      label: string;
      desc: string;
      duration: AppointmentDurations;
      type: AppointmentTypes;
    }[] =>
      apptType
        ? (
            {
              [AppointmentTypes.doctor_consultation]: doctorSessions,
              [AppointmentTypes.video_call_with_therapist]: therapySessions,
              [AppointmentTypes.group_call_with_therapist]:
                groupTherapySessions,
              [AppointmentTypes.recurring_session]:
                isMember(user) && !user?.therapist_Id
                  ? []
                  : // TODO remove legacy check after members are migrated to V2 componenets
                  legacy
                  ? [bookRecurringSessions[0]]
                  : bookRecurringSessions,
              [AppointmentTypes.chat_with_coach]: [],
            }[apptType] ?? []
          ).map(session => ({
            ...session,
            desc: t(session.desc[user!.role]),
          }))
        : [],
  };
};
