import {AppActions, RootState} from 'app/rootReducer';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {MessagingService} from 'services/api';

import {messagingActions} from './messagingSlice';

export const searchFriendsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(messagingActions.searchFriends.match),
    switchMap(({payload}) =>
      from(MessagingService.searchFriends(payload.keyword, payload.next)).pipe(
        mergeMap(result => [
          messagingActions.searchFriendsSuccess({result, isNext: payload.next}),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'messaging', message})),
            of(messagingActions.searchFriendsFailure()),
          ),
        ),
      ),
    ),
  );

export const cometChatLogOutEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(messagingActions.cometChatLogOut.match),
    switchMap(() =>
      from(MessagingService.logOutUserOnCometChat()).pipe(
        mergeMap(() => [messagingActions.cometChatLogOutSuccess()]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'messaging', message})),
            of(messagingActions.cometChatLogOutFailure()),
          ),
        ),
      ),
    ),
  );

export const messagingEpics = [searchFriendsEpic, cometChatLogOutEpic];
