import React, {FC} from 'react';
import {
  CreditCardTypes,
  PaypalCreditCardBrandTypes,
  StripeCreditCardBrandTypes,
} from 'interfaces/Pages/CreditCard.types';

import {
  AmexCCIcon,
  CreditCardIcon,
  DinersCCIcon,
  DiscoverCCIcon,
  JCBCCIcon,
  MasterCardCCIcon,
  UnionPayCCIcon,
  VisaCCIcon,
} from '../SvgIcon';

export const CreditCardLogo: FC<{brand: string}> = ({brand}) => {
  switch (brand) {
    case StripeCreditCardBrandTypes.Amex:
    case PaypalCreditCardBrandTypes.AmericanExpress:
      return <AmexCCIcon />;
    case StripeCreditCardBrandTypes.Discover:
    case PaypalCreditCardBrandTypes.Discover:
      return <DiscoverCCIcon />;
    case StripeCreditCardBrandTypes.JCB:
    case PaypalCreditCardBrandTypes.JCB:
      return <JCBCCIcon />;
    case StripeCreditCardBrandTypes.MasterCard:
    case PaypalCreditCardBrandTypes.MasterCard:
      return <MasterCardCCIcon />;
    case StripeCreditCardBrandTypes.UnionPay:
    case PaypalCreditCardBrandTypes.ChinaUnionPay:
      return <UnionPayCCIcon />;
    case StripeCreditCardBrandTypes.Visa:
    case PaypalCreditCardBrandTypes.Visa:
      return <VisaCCIcon />;
    case StripeCreditCardBrandTypes.DinersClub:
      return <DinersCCIcon />;
    default:
      return <CreditCardIcon fillColor="#999" strokeColor="text-transparent" />;
  }
};

export const CreditCardOutLineLogo: FC<{
  brand: CreditCardTypes | undefined;
}> = ({brand}) => {
  if (brand === undefined) return null;
  switch (brand) {
    case 'amex':
      return <AmexCCIcon classes="absolute right-3" />;
    case 'diners':
      return <DinersCCIcon classes="absolute right-3" />;
    case 'discover':
      return <DiscoverCCIcon classes="absolute right-3" />;
    case 'jcb':
      return <JCBCCIcon classes="absolute right-3" />;
    case 'mastercard':
      return <MasterCardCCIcon classes="absolute right-3" />;
    case 'unionpay':
      return <UnionPayCCIcon classes="absolute right-3" />;
    case 'visa':
      return <VisaCCIcon classes="absolute right-3" />;
    default:
      return null;
  }
};

export const CreditCardStripeLogo: FC<{
  brand: CreditCardTypes | undefined;
}> = ({brand}) => {
  if (brand === undefined) return null;
  switch (brand) {
    case 'amex':
      return <AmexCCIcon width={32} height={22} classes="right-3" />;
    case 'diners':
      return <DinersCCIcon width={32} height={22} classes="right-3" />;
    case 'discover':
      return <DiscoverCCIcon width={32} height={22} classes="right-3" />;
    case 'jcb':
      return <JCBCCIcon width={32} height={22} classes="right-3" />;
    case 'mastercard':
      return <MasterCardCCIcon width={32} height={22} classes="right-3" />;
    case 'unionpay':
      return <UnionPayCCIcon width={32} height={22} classes="right-3" />;
    case 'visa':
      return <VisaCCIcon width={32} height={22} classes="right-3" />;
    default:
      return null;
  }
};
