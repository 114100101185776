import React, {FC} from 'react';
import classNames from 'classnames';
import {format} from 'date-fns';
import {useFileDownload} from 'hooks';
import {CometChatMessageType, MessageType} from 'interfaces';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import ReactLinkify from 'react-linkify';
import styled from 'styled-components';
import {
  formatChatDate,
  getFirstLetterOfNames,
  isYoutubeUrl,
  ThumbnailColors,
} from 'utils';

import {AudioPlayer} from '../AudioPlayer';
import {
  AudioIcon,
  CheckIcon,
  DoubleCheckIcon,
  PhotoIcon,
  UDownloadIcon,
  VideoIcon,
} from '../SvgIcon';
import {VideoPlayer} from '../VideoPlayer';

import {Icon} from './Icon';

const StyledSection = styled.section`
  max-width: 70%;
`;

const Time = styled.div`
  font-size: 0.6rem;
`;

interface ContentProps {
  message: CometChatMessageType;
  isSelf: boolean;
  time: string;
}

interface AvatarProps {
  image?: string;
  online?: boolean;
  fullName: string;
  classes?: string;
  noOnlineStatus?: boolean;
}

interface UnreadProps {
  count: number;
}

interface ChatMessageProps {
  isSelf: boolean;
  message: CometChatMessageType;
  isFirstForTheDay: boolean;
}

export const getContentType = (
  message: CometChatMessageType,
  isSelf?: boolean,
) => {
  if (message.data.action === 'deleted') {
    return <p className="italic text-sm">This message was deleted</p>;
  }
  if (message.deletedBy === 'app_system') {
    return <p className="italic text-sm">This message was deleted</p>;
  }
  if (
    Array.isArray(message.data.attachments) &&
    message.data.attachments.length &&
    message.type === MessageType.image
  ) {
    return (
      <LazyLoadImage
        alt={message.data.type}
        height={message.data?.metadata?.placeholderSrc?.dimension.height}
        width={message.data?.metadata?.placeholderSrc?.dimension.width}
        placeholderSrc={message.data?.metadata?.placeholderSrc?.base64}
        src={message.data.attachments[0].url}
        threshold={100}
        className="w-full max-w-[200px] sm:max-w-xs h-full object-cover bg-gray-100"
        style={message.data?.metadata?.placeholderSrc?.dimension}
      />
    );
  }
  if (
    Array.isArray(message.data.attachments) &&
    message.data.attachments.length &&
    message.type === MessageType.video
  ) {
    return (
      <div className="w-[270px] sm:max-w-xs h-full">
        <VideoPlayer url={message.data.attachments[0].url} title="" />
      </div>
    );
  }
  if (
    Array.isArray(message.data.attachments) &&
    message.data.attachments.length &&
    message.type === MessageType.audio
  ) {
    const audioData = message.data.attachments[0];
    return (
      <AudioPlayer key={message.id} audioData={audioData} isSelf={isSelf} />
    );
  }
  if (
    Array.isArray(message.data.attachments) &&
    message.data.attachments.length &&
    message.type === MessageType.file
  ) {
    const file = message.data.attachments[0];
    return (
      <article className="flex flex-col w-full max-w-[270px]">
        <article className="bg-gray-100 p-3 py-5 flex items-center justify-center">
          <Icon
            name={message.metadata?.ext}
            width={50}
            height={50}
            fillColor="#fff"
            color
          />
        </article>

        <p className="text-sm p-2">{file.name}</p>
      </article>
    );
  } else if (isYoutubeUrl(message.data.text))
    return (
      <div className="w-[270px] sm:max-w-xs h-full">
        <VideoPlayer url={message.data.text} />
      </div>
    );

  return (
    <ReactLinkify>
      <p className="text-sm">{message.data?.text}</p>
    </ReactLinkify>
  );
};

const getMessageIdentity = (message: CometChatMessageType) => {
  if (
    (message.data.text && isYoutubeUrl(message.data.text)) ||
    message.type === MessageType.video
  ) {
    return (
      <VideoIcon
        key={message.id}
        width={14}
        height={14}
        strokeColor="text-gray-500"
      />
    );
  } else if (message.type === MessageType.audio) {
    return (
      <AudioIcon
        key={message.id}
        width={14}
        height={14}
        strokeWidth={2}
        strokeColor="text-gray-500"
      />
    );
  } else if (message.type === MessageType.image) {
    return (
      <PhotoIcon
        key={message.id}
        width={10}
        height={10}
        strokeWidth={2}
        strokeColor="text-gray-500 mt-0.5"
      />
    );
  } else {
    return null;
  }
};

export const Content: FC<ContentProps> = ({message, isSelf, time}) => {
  const details = [
    isSelf ? (
      message.deliveredAt ? (
        <DoubleCheckIcon
          key="check"
          width={13}
          height={13}
          strokeColor={message.readAt ? 'text-green-600' : 'text-gray-500'}
        />
      ) : (
        <CheckIcon
          key="check"
          width={13}
          height={13}
          strokeColor="text-gray-500"
        />
      )
    ) : null,
    <p key="time">{time}</p>,
    message.deletedBy === undefined && getMessageIdentity(message),
    <p className="uppercase" key="ext">
      {message.metadata?.ext}
    </p>,
  ];
  return (
    <StyledSection>
      <div
        className={classNames('break-all h-fit', {
          'bg-white shadow-sm rounded-r-md rounded-bl-md overflow-hidden':
            !isSelf,
          'bg-blue-600 shadow-md text-white rounded-l-md rounded-br-md overflow-hidden':
            isSelf,
          'px-3 py-2':
            message.deletedBy ||
            (message.type === MessageType.text &&
              !isYoutubeUrl(message.data.text)),
        })}
        style={
          message.type === MessageType.image
            ? message.data.metadata?.placeholderSrc?.dimension
            : {}
        }
      >
        {getContentType(message, isSelf)}
      </div>

      <Time
        className={classNames(
          'flex items-center text-xs font-light mt-1 text-gray-700 space-x-1',
          {
            'justify-start': isSelf,
            'justify-end': !isSelf,
          },
        )}
      >
        {isSelf ? details : details.reverse()}
      </Time>
    </StyledSection>
  );
};

export const Avatar: FC<AvatarProps> = ({
  image,
  fullName = '',
  online,
  classes = '',
  noOnlineStatus = false,
}) => {
  const firstLetterOfName = getFirstLetterOfNames(fullName);
  const colors = ThumbnailColors[firstLetterOfName.charAt(0).toLowerCase()];

  return (
    <section
      className={classNames(
        `relative flex items-center justify-center rounded-full text-base bg-opacity-10 uppercase aspect-square ${classes}`,
        {
          'w-8 h-8': noOnlineStatus,
          'w-10 h-10': !noOnlineStatus,
        },
      )}
      style={{background: colors.bg, color: colors.text}}
    >
      {image ? (
        <img
          src={image}
          className="rounded-full w-full h-full object-cover aspect-square"
        />
      ) : (
        <span
          className={classNames({
            'text-sm': noOnlineStatus,
          })}
        >
          {fullName ? firstLetterOfName : null}
        </span>
      )}
      {noOnlineStatus ? null : (
        <div
          className={`absolute right-0 bottom-0 h-3 w-3 rounded-full border-2 border-white ${
            online ? 'bg-green-500' : 'bg-red-500'
          }`}
        />
      )}
    </section>
  );
};

export const Unread: FC<UnreadProps> = ({count}) => {
  return (
    <span className="w-fit flex items-center justify-center mr-1 h-5 px-2 rounded-full bg-blue-600/[15%] text-blue-600 text-xs">
      {count}
    </span>
  );
};

export const ChatMessage: FC<ChatMessageProps> = ({
  message,
  isSelf,
  isFirstForTheDay,
}) => {
  const {status, download} = useFileDownload(
    message.data?.attachments?.[0].url,
    message.data?.attachments?.[0].name,
  );

  const sentTime = format(new Date(message.sentAt * 1000), 'hh:mm a');

  if (!message.data) return null;
  return (
    <div>
      {isFirstForTheDay ? (
        <div className="flex justify-center my-2 py-3">
          {formatChatDate(message.sentAt * 1000)}
        </div>
      ) : null}
      <div
        className={classNames('flex justify-end my-2', {
          'flex-row-reverse': !isSelf,
        })}
      >
        <Content message={message} isSelf={isSelf} time={sentTime} />
        <div className="flex flex-col justify-between pb-5">
          <Avatar
            image={message.sender.avatar}
            fullName={message.sender.name}
            noOnlineStatus
            classes="mx-2"
          />
          {message.type === MessageType.text ||
          message?.deletedBy !== undefined ? null : (
            <button
              onClick={download}
              className="mx-auto block rounded-xl px-0.5 py-2 mt-2"
            >
              <UDownloadIcon
                strokeColor={classNames({
                  'text-blue-500 animate-bounce': status,
                  'text-gray-500 hover:text-blue-500': !status,
                })}
              />
            </button>
          )}
        </div>
      </div>
      <div
        className={classNames('flex justify-end my-2', {
          'flex-row-reverse': !isSelf,
        })}
      ></div>
    </div>
  );
};
