import React, {useCallback, useState} from 'react';
import PatientNotes from 'features/Provider/Notes';
import CreateNote from 'features/Provider/Notes/CreateNote';
import {useToggle} from 'hooks';
import {NotesType} from 'interfaces';

enum CurrentNotesPage {
  viewNotes = 'viewNotes',
  createNotes = 'createNotes',
}
export default function RightView() {
  const [currentView, setCurrentView] = useState<CurrentNotesPage>(
    CurrentNotesPage.viewNotes,
  );
  const [selectedNote, setSelectNoteToEdit] = useState<NotesType | null>(null);
  // to handle the note create & CMS1500 Form submission
  const [isNoteCreated, setIsNoteCreated] = useState(false);
  const [, setNoteError] = useState(
    '*Attention! Please complete the required fields on the notes section and click the done button before leaving the call',
  );
  const {onToggleClick} = useToggle();

  const onNoteSelect = (note: NotesType) => {
    setSelectNoteToEdit(note);
    setCurrentView(CurrentNotesPage.createNotes);
  };

  const toggleSlidePage = useCallback(() => {
    if (currentView === CurrentNotesPage.viewNotes) {
      setCurrentView(CurrentNotesPage.createNotes);
      setSelectNoteToEdit(null);
    } else {
      setCurrentView(CurrentNotesPage.viewNotes);
    }
  }, [currentView]);
  return (
    <section className="relative h-full">
      <div className="h-16 border-b flex flex-col justify-center items-center">
        <p className="font-medium text-sm lg:text-base text-center">Notes</p>
      </div>
      <div className="h-[calc(100%_-_4rem)]">
        {currentView === CurrentNotesPage.viewNotes ? (
          <PatientNotes
            togglePage={toggleSlidePage}
            isInVideoCall={true}
            onNoteSelect={onNoteSelect}
          />
        ) : (
          <CreateNote
            togglePage={toggleSlidePage}
            memberEmail=""
            selectedNote={selectedNote}
            isInVideoCall={true}
            setIsNoteCreated={setIsNoteCreated}
            isNoteCreated={isNoteCreated}
            setNoteError={setNoteError}
            onToggleClick={onToggleClick}
            setSelectNoteToEdit={setSelectNoteToEdit}
            memberHasInsurance={false}
          />
        )}
      </div>
    </section>
  );
}
