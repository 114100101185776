import {useCallback} from 'react';
import {useAppDispatch} from 'app/store';
import {messagingActions} from 'features/Messaging';
import {selectUserLogoutStatus, userActions} from 'features/User';
import {SliceStatus} from 'interfaces';
import {batch, useSelector} from 'react-redux';

export function useLogout(logoutCometChat: boolean): {
  logout: () => void;
  logoutStatus: SliceStatus;
} {
  const dispatch = useAppDispatch();
  const logoutStatus = useSelector(selectUserLogoutStatus);

  const logout = useCallback(() => {
    if (logoutStatus === SliceStatus.pending) return;

    batch(() => {
      if (logoutCometChat) {
        dispatch(messagingActions.cometChatLogOut());
      }
      dispatch(userActions.logout());
    });
  }, [dispatch, logoutStatus]);

  return {
    logout,
    logoutStatus,
  };
}

export default useLogout;
