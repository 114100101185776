import React, {FC} from 'react';
import classNames from 'classnames';
import {selectUserProfile} from 'features/User';
import {useUserInfo} from 'hooks';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {isMember} from 'utils';

type Props = {
  className?: string;
};

const HeaderProfileImage: FC<Props> = ({
  className = 'h-8 w-8 md:w-12 md:h-12',
}) => {
  const user = useSelector(selectUserProfile);
  const {image} = useUserInfo();

  return (
    <Link
      className="inline-block"
      to={isMember(user) ? '/profile' : '/provider/profile'}
    >
      <img
        className={classNames('rounded-full object-cover', className)}
        src={image}
        alt="Avatar"
      />
    </Link>
  );
};

export default HeaderProfileImage;
