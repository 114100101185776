import {useEffect, useState} from 'react';

export default function useCache() {
  const [browserStorage, setBrowserStorage] = useState({
    used: '',
    remaining: '',
  });

  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  useEffect(() => {
    (async () => {
      if ('storage' in navigator && 'estimate' in navigator.storage) {
        const estimate = await navigator.storage.estimate();
        setBrowserStorage({
          used: formatBytes(estimate.usage!),
          remaining: formatBytes(estimate.quota!),
        });
      }
    })();
  }, []);

  const clearBrowserStorage = () => {
    try {
      // clear local storage
      localStorage.clear();
      // clear session storage
      sessionStorage.clear();
      // clear cookies
      const allCookies = document.cookie.split(';');
      // The "expire" attribute of every cookie is
      // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
      for (let i = 0; i < allCookies.length; i++)
        document.cookie =
          allCookies[i] + '=;expires=' + new Date(0).toUTCString();

      // clear cache storage
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
      // clear indexDB storage
      const req = indexedDB.deleteDatabase('WellniteDB');
      req.onsuccess = function () {
        console.log('Deleted database successfully');
      };
      req.onerror = function () {
        console.log("Couldn't delete database");
      };
      req.onblocked = function () {
        console.log(
          "Couldn't delete database due to the operation being blocked",
        );
      };
      window.location.reload();
    } catch (error) {}
  };

  return {browserStorage, clearBrowserStorage};
}
