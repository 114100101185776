import {AppActions, RootState} from 'app/rootReducer';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {providerActions} from '../providerSlice';

import {getTherapyQuestionnaireEpic} from './MemberTabs/Clinicalntakes/TherapyQuestionnaire/TherapyQuestionnaireEpic';

export const getMemberEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.getMember.match),
    mergeMap(({payload}) =>
      from(ProviderService.getMember(payload)).pipe(
        mergeMap(({normalizedMembers}) => [
          providerActions.getMemberSuccess({normalizedMembers}),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getMemberFailure()),
          ),
        ),
      ),
    ),
  );

export const getMemberByIdEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getMemberById.match),
    mergeMap(({payload}) =>
      from(ProviderService.getMemberById(payload)).pipe(
        mergeMap(({normalizedMembers}) => [
          providerActions.getMemberSuccess({normalizedMembers}),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getMemberFailure()),
          ),
        ),
      ),
    ),
  );

export const MemberEpics = [
  getMemberEpic,
  getMemberByIdEpic,
  getTherapyQuestionnaireEpic,
];
