import {AppActions, RootState} from 'app/rootReducer';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, withLatestFrom} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {providerActions} from '../providerSlice';

const sendCalendarSettingsNotificationEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(providerActions.sendCalendarSettingsNotification.match),
    withLatestFrom(state$),
    mergeMap(([, currState]) =>
      from(
        ProviderService.sendCalendarSettingsNotification(
          currState.user.current!.role,
        ),
      ).pipe(
        mergeMap(() => [
          providerActions.sendCalendarSettingsNotificationSuccess(),
        ]),
        catchError(() =>
          concat(of(providerActions.sendCalendarSettingsNotificationFailure())),
        ),
      ),
    ),
  );

const sendAvailabilityUpdateNotificationEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(providerActions.sendAvailabilityUpdateNotification.match),
    withLatestFrom(state$),
    filter(
      ([, currState]) =>
        currState.availability.isAvailabilityModified &&
        !!currState.availability.availability,
    ),
    mergeMap(
      ([
        {
          payload: {role},
        },
        currState,
      ]) =>
        from(
          ProviderService.sendAvailabilityUpdateNotification({
            availabilityData: currState.availability.availability.recurring,
            role,
          }),
        ).pipe(
          mergeMap(() => [
            providerActions.sendAvailabilityUpdateNotificationSuccess(),
          ]),
          catchError(() =>
            concat(
              of(providerActions.sendAvailabilityUpdateNotificationFailure()),
            ),
          ),
        ),
    ),
  );

export const CalendarEpics = [
  sendCalendarSettingsNotificationEpic,
  sendAvailabilityUpdateNotificationEpic,
];
