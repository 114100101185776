import * as Yup from 'yup';

const answers = [
  'notAtAll',
  'severalDays',
  'moreThanHalfTheDays',
  'nearlyEveryDay',
];

export const ProgressAssessmentQuestionSchema = Yup.array().of(
  Yup.object().shape({
    key: Yup.string().required('Key is required'),
    value: Yup.string()
      .oneOf(answers, 'Value must be one of the specified options')
      .required(),
    score: Yup.number()
      .integer()
      .min(0)
      .max(3)
      .required('Score must be between 0 and 3'),
  }),
);

export const ProgressAssessmentSchema = Yup.object({
  phq9: Yup.array().of(ProgressAssessmentQuestionSchema),
  gad7: Yup.array().of(ProgressAssessmentQuestionSchema),
});

export const ProgressAssessmentFormItemSchema = Yup.object().shape({
  key: Yup.string().required('Key is required'),
  value: Yup.string().required('Value cannot be empty'),
  score: Yup.number()
    .required('Score is required')
    .integer('Score must be an integer'),
});

export const ProgressAssessmentFormSchema = Yup.object().shape({
  phq9: Yup.array()
    .of(ProgressAssessmentFormItemSchema)
    .required('PHQ9 array is required'),
  gad7: Yup.array()
    .of(ProgressAssessmentFormItemSchema)
    .required('GAD7 array is required'),
});
