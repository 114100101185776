import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {
  Appointment,
  AppointmentTypes,
  GroupCallAppointmentLabelsIDs,
  UserRoles,
} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {BookingService} from 'services';
import {AppointmentService} from 'services/api';
// @ts-ignore
import {isProvider} from 'utils';

import {appointmentActions} from '../appointmentSlice';

export const bookAppointmentEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(appointmentActions.bookAppointment.match),
    withLatestFrom(state$),

    switchMap(
      ([
        {
          payload: {providerId, ...rest},
        },
        currState,
      ]) =>
        from(
          BookingService.bookAppointment({
            ...rest,
            [`${
              rest.appointmentType ===
              AppointmentTypes.video_call_with_therapist
                ? 'therapistId'
                : 'prescriberId'
            }`]: providerId,
          }),
        ).pipe(
          mergeMap((appointment: Appointment) => {
            if (isProvider(currState?.user?.current)) {
              return [
                userActions.checkSession(
                  currState?.user?.current?.role === UserRoles.therapist
                    ? UserRoles.therapist
                    : UserRoles.prescriber,
                ),

                appointmentActions.bookAppointmentSuccess(appointment),
                userActions.resetAsyncError('appointment'),
                userActions.setNotification({
                  message: i18next.t('other.appointmentBooked'),
                  messageType: 'success',
                }),
              ];
            } else {
              return [
                userActions.checkSession(UserRoles.member),
                appointmentActions.bookAppointmentSuccess(appointment),
                userActions.setNotification({
                  message: i18next.t('other.yourAppointmentHasBeenBooked'),
                  messageType: 'success',
                }),
              ];
            }
          }),
          catchError((message: string) =>
            concat(
              of(appointmentActions.bookAppointmentFailure(message)),
              of(
                userActions.setAsyncError({
                  filter: 'appointment',
                  message,
                }),
              ),
            ),
          ),
        ),
    ),
  );

export const bookAppointmentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.bookAppointmentFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const bookOneTimeAppointmentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.bookOneTimeAppointment.match),
    mergeMap(({payload: {providerId, ...rest}}) =>
      from(
        BookingService.bookOneTimeAppointment({
          ...rest,
          [`${
            rest.appointmentType === AppointmentTypes.video_call_with_therapist
              ? 'therapistId'
              : 'prescriberId'
          }`]: providerId,
        }),
      ).pipe(
        mergeMap(() => [
          userActions.checkSession(UserRoles.member),
          appointmentActions.bookOneTimeAppointmentSuccess(false),
          userActions.setNotification({
            message: i18next.t('other.yourAppointmentHasBeenBooked'),
            messageType: 'success',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(appointmentActions.bookOneTimeAppointmentFailure(message)),
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const bookOneTimeAppointmentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.bookOneTimeAppointmentFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const cancelAppointmentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.cancelAppointment.match),
    mergeMap(
      ({payload: {idToCancel, reasonForCancellation, callback, onError}}) =>
        from(
          BookingService.cancelAppointment({
            appointmentID: idToCancel,
            ...(reasonForCancellation && {reasonForCancellation}),
          }),
        ).pipe(
          concatMap(() => {
            if (typeof callback === 'function') callback();
            return of(appointmentActions.cancelAppointmentSuccess(idToCancel));
          }),
          catchError((message: string) => {
            if (typeof onError === 'function') onError();
            return concat(
              of(
                userActions.setAsyncError({
                  filter: 'appointment',
                  message,
                }),
              ),
              of(appointmentActions.cancelAppointmentFailure(false)),
            );
          }),
        ),
    ),
  );

export const cancelAppointmentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(appointmentActions.cancelAppointmentFailure.match),
    withLatestFrom(state$),
    filter(([, state]) => state.appointment.error.length > 0),
    tap(([, state]) => {
      Toast({type: 'error', message: `${state.appointment.error}`});
    }),
    map(() => userActions.resetAsyncError('appointment')),
    ignoreElements(),
  );

// Group call epics
const bookGroupAppointmentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.bookGroupAppointment.match),
    switchMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(BookingService.bookGroupAppointment(rest)).pipe(
        concatMap(({data: {message}}) => {
          onSuccess();
          return of(appointmentActions.bookGroupAppointmentSuccess(message));
        }),
        catchError((message: string) => {
          onError();
          return concat(
            of(appointmentActions.bookGroupAppointmentFailure(message)),
          );
        }),
      ),
    ),
  );

export const bookGroupAppointmentFailure: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.bookGroupAppointmentFailure.match),
    tap(({payload: message}) => {
      Toast({type: 'error', message});
    }),
    ignoreElements(),
  );

// Update Appointment Labels Epic
export const updateGroupCallAppointmentLabelFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.updateGroupCallAppointmentLabelFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message: message.message});
      }
    }),
    ignoreElements(),
  );

export const updateGroupCallAppointmentLabelEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.updateGroupCallAppointmentLabel.match),
    switchMap(({payload: {onSuccess, ...rest}}) =>
      from(AppointmentService.updateGroupCallAppointmentLabelByID(rest)).pipe(
        concatMap(({data: {message}}) => {
          onSuccess();

          if (rest.labelID !== GroupCallAppointmentLabelsIDs.joined) {
            Toast({
              type: 'success',
              message: 'Your appointment interest has been set.',
            });
          }
          return of(
            appointmentActions.updateGroupCallAppointmentLabelSuccess({
              appointmentID: rest.appointmentID,
              message,
            }),
          );
        }),
        catchError((message: string) => {
          return concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(
              appointmentActions.updateGroupCallAppointmentLabelFailure({
                message: 'An error occurred while setting the interest.',
              }),
            ),
          );
        }),
      ),
    ),
  );

export const getProviderRecurringAvailableSlotsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.getProviderRecurringAvailableSlots.match),
    switchMap(({payload}) =>
      from(BookingService.getProviderRecurringAvailableSlots(payload)).pipe(
        mergeMap(({data: {message}}) => [
          appointmentActions.getProviderRecurringAvailableSlotsSuccess({
            slots: message,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(
              appointmentActions.getProviderRecurringAvailableSlotsFailure(
                message,
              ),
            ),
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const getProviderRecurringAvailableSlotsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.getProviderRecurringAvailableSlotsFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const bookRecurringAppointmentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(appointmentActions.bookRecurringAppointment.match),
    withLatestFrom(state$),

    switchMap(([{payload}, currState]) =>
      from(BookingService.bookRecurringAppointment(payload)).pipe(
        mergeMap((appointments: Appointment[]) => {
          if (isProvider(currState?.user?.current)) {
            return [
              userActions.checkSession(UserRoles.therapist),

              appointmentActions.bookRecurringAppointmentSuccess(appointments),
              userActions.resetAsyncError('appointment'),
              userActions.setNotification({
                message: i18next.t('other.appointmentsBooked'),
                messageType: 'success',
              }),
            ];
          } else {
            return [
              userActions.checkSession(UserRoles.member),
              appointmentActions.bookRecurringAppointmentSuccess(appointments),
              userActions.setNotification({
                message: i18next.t('other.yourAppointmentsHaveBeenBooked'),
                messageType: 'success',
              }),
            ];
          }
        }),
        catchError((message: string) =>
          concat(
            of(appointmentActions.bookRecurringAppointmentFailure(message)),
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const bookRecurringAppointmentEpicFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.bookRecurringAppointmentFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const getAvailableProvidersEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.getAvailableProviders.match),
    mergeMap(({payload}) =>
      from(BookingService.getAvailableProviders(payload)).pipe(
        mergeMap(({data}) => [
          appointmentActions.getAvailableProvidersSuccess(data.message),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
            ),
            of(appointmentActions.getAvailableProvidersFailure(false)),
          ),
        ),
      ),
    ),
  );

export const getAvailableSlotsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.getAvailableSlots.match),
    mergeMap(({payload}) =>
      from(BookingService.getAvailableSlots(payload)).pipe(
        mergeMap(({data}) => [
          appointmentActions.getAvailableSlotsSuccess({
            providerId: payload.providerId,
            yearAndMonth: payload.yearAndMonth,
            data: data.message,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
            ),
            of(appointmentActions.getAvailableSlotsFailure(false)),
          ),
        ),
      ),
    ),
  );

export const getProviderDataForAppointmentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.getProviderDataForAppointment.match),
    mergeMap(({payload}) =>
      from(BookingService.getProviderDataForAppointment(payload)).pipe(
        mergeMap(({data}) => [
          appointmentActions.getProviderDataForAppointmentSuccess(data.message),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
            ),
            of(appointmentActions.getProviderDataForAppointmentFailure(false)),
          ),
        ),
      ),
    ),
  );

export const appointmentBookingEpics = [
  bookAppointmentEpic,
  bookAppointmentFailureEpic,
  cancelAppointmentEpic,
  cancelAppointmentFailureEpic,
  bookOneTimeAppointmentEpic,
  bookOneTimeAppointmentFailureEpic,
  bookGroupAppointmentEpic,
  bookGroupAppointmentFailure,
  updateGroupCallAppointmentLabelEpic,
  updateGroupCallAppointmentLabelFailureEpic,
  getProviderRecurringAvailableSlotsEpic,
  getProviderRecurringAvailableSlotsFailureEpic,
  bookRecurringAppointmentEpic,
  bookRecurringAppointmentEpicFailureEpic,
  getAvailableProvidersEpic,
  getAvailableSlotsEpic,
  getProviderDataForAppointmentEpic,
];
