import React, {FC, ReactNode, Suspense} from 'react';
import {MobileTabletNavigation} from 'components';
import {useWindowSize} from 'hooks';

export const ProviderVideoLayout: FC<{children: ReactNode[] | ReactNode}> = ({
  children,
}) => (
  <main className="min-w-full h-screen bg-white">
    <Suspense>
      {children}
      <MobileTabletNavigation />
    </Suspense>
  </main>
);

export const MemberVideoLayout: FC<{children: ReactNode[] | ReactNode}> = ({
  children,
}) => {
  const {width} = useWindowSize();
  return (
    <main className="min-w-full h-screen bg-white flex flex-col">
      <Suspense>
        {children}
        {width && width < 769 ? <MobileTabletNavigation /> : null}
      </Suspense>
    </main>
  );
};
export const VideoLayout: FC<{children: ReactNode[] | ReactNode}> = ({
  children,
}) => (
  <main className="min-w-full h-screen bg-white">
    <Suspense>{children}</Suspense>
    <MobileTabletNavigation />
  </main>
);
