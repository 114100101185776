import {IntakeStatus, IntakeType} from 'definitions/Intake';
import {CurrentUser, IntakeFormattedData, IntakeResponse} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const checkUserExists = createAction<{
  email: string;
  intakeType: string;
}>('checkUserExists');
export const checkUserExistsSuccess = createAction<{exists: boolean}>(
  'checkUserExistsSuccess',
);
export const checkUserExistsFailure = createAction<string>(
  'checkUserExistsFailure',
);

export const saveIntakeData =
  createAction<IntakeFormattedData>('saveIntakeData');
export const saveIntakeDataSuccess = createAction<{
  intakeResponse: IntakeResponse;
  user: CurrentUser;
}>('saveIntakeDataSuccess');
export const saveIntakeDataFailure = createAction<string>(
  'saveIntakeDataFailure',
);

export const getMemberIntakeData = createAction<{
  patientId: string;
  intakeType: IntakeType;
}>('getMemberInatkedData');
export const getMemberIntakeDataSuccess = createAction<{
  intakeResponse: IntakeResponse;
  user: CurrentUser;
}>('getMemberInatkedDataSuccess');
export const getMemberIntakeDataFailure = createAction<string>(
  'getMemberInatkedDataFailure',
);

export const changeIntakeStep = createAction<number>('changeIntakeStep');

export const changeIntakeStatus =
  createAction<IntakeStatus>('changeIntakeStatus');
