import {selectUserProfile} from 'features/User';
import {UserWellbitWallet} from 'interfaces';
import {useSelector} from 'react-redux';
import {isMember} from 'utils';

export function useUserInfo(): {
  fullName: string;
  firstName: string;
  lastName: string;
  userInitials: string;
  phoneNumber: string;
  email: string;
  gender: string;
  image: string;
  dateOfBirth: Date | string;
  wellbitWallet?: UserWellbitWallet;
  preferredLanguage: string;
} {
  const user = useSelector(selectUserProfile);
  let fullName = '';
  let gender = '';
  let phoneNumber = '';
  let image = '';
  let email = '';
  let dateOfBirth;
  let preferredLanguage = 'en';

  fullName = user?.fullName || 'User User';
  const [firstName, ...restNames] = fullName.split(' ');
  const lastName = restNames[restNames.length - 1];
  image = user?.image ? user.image : '';

  if (isMember(user)) {
    gender = user.gender ? user.gender : 'N/A';
    dateOfBirth = user.dateOfBirth ? user.dateOfBirth : 'N/A';
    phoneNumber = user.phoneNumber ? user.phoneNumber : 'N/A';
    email = user.email ? user.email : 'N/A';
    preferredLanguage = user.preferredLanguage ? user.preferredLanguage : 'en';
  }
  const userInitials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
  const wellbitWallet = user?.wellbitWallet || undefined;
  return {
    fullName,
    firstName,
    lastName,
    userInitials,
    gender,
    email,
    dateOfBirth: dateOfBirth || 'N/A',
    phoneNumber,
    image,
    wellbitWallet,
    preferredLanguage,
  };
}
