import React, {FC, lazy, Suspense, useRef} from 'react';
import PlaceholderImage from 'assets/images/silhouette.jpg';
import {
  CalendarRecurringIcon,
  FormError,
  FormTextArea,
  TimeIcon,
} from 'components';
import {DetachedSearchSelect} from 'components/Basic/Form/Select/DetachedSearchSelect/DetachedSearchSelect';
import {detachedSearchBarStyles} from 'components/Basic/Form/Select/styles';
import dayjs from 'dayjs';
import {selectUserNotification, selectUserProfile} from 'features/User';
import {useRecurringBookingV2} from 'hooks';
import {TFunction} from 'i18next';
import {AppointmentTypes, MemberProfile, OptionType} from 'interfaces';
import {createPortal} from 'react-dom';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Select from 'react-select';
import {
  componentLoader,
  convertTimeToDiffTimeZone,
  isMember,
  isProvider,
} from 'utils';

import {BookingFormButtons} from '../BookingFormButtons';
import {ClientChooser} from '../ClientChooser';

import RecurringBookingChargeModal from './RecurringBookingChargeModal';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

export const durationOptions = [
  {value: '30', label: '30 mins'},
  {value: '45', label: '45 mins'},
  {value: '60', label: '60 mins'},
];

export const daysOptions = (t: TFunction) => [
  {value: 'M', label: t('days.monday', 'Monday')},
  {value: 'T', label: t('days.tuesday', 'Tuesday')},
  {value: 'W', label: t('days.wednesday', 'Wednesday')},
  {value: 'Th', label: t('days.thursday', 'Thursday')},
  {value: 'F', label: t('days.friday', 'Friday')},
  {value: 'S', label: t('days.saturday', 'Saturday')},
  {value: 'Su', label: t('days.sunday', 'Sunday')},
];

export const recurrenceOptions = (t: TFunction) => [
  // {value: '1', label: '1 Week'},
  {value: '2', label: t('recurrenceOptions.2', '2 Weeks')},
  {value: '3', label: t('recurrenceOptions.3', '3 Weeks')},
  {value: '4', label: t('recurrenceOptions.4', '4 Weeks')},
  {value: '5', label: t('recurrenceOptions.5', '5 Weeks')},
  {value: '6', label: t('recurrenceOptions.6', '6 Weeks')},
  {value: '7', label: t('recurrenceOptions.7', '7 Weeks')},
  {value: '8', label: t('recurrenceOptions.8', '8 Weeks')},
  {value: '9', label: t('recurrenceOptions.9', '9 Weeks')},
  {value: '10', label: t('recurrenceOptions.10', '10 Weeks')},
  {value: '11', label: t('recurrenceOptions.11', '11 Weeks')},
  {value: '12', label: t('recurrenceOptions.12', '12 Weeks')},
];

type Props = {
  selectedDate?: dayjs.Dayjs;
  duration: string;
  onCancel?: () => void;
  onSuccess?: () => void;
  member?: MemberProfile;
  footerRef?: React.RefObject<HTMLDivElement>;
};

export const RecurringBookingCard: FC<Props> = ({
  selectedDate,
  onCancel,
  onSuccess,
  duration,
  footerRef,
  member: preselectedMember,
}) => {
  const btnRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector(selectUserProfile);
  const {t} = useTranslation();

  const scrollToElement = () => {
    const {current} = btnRef;
    if (current !== null) {
      current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const {
    isLoading,
    timeSlots,
    selectedTime,
    setSelectedTime,
    selectedDay,
    setSelectedDay,
    recurrence,
    setRecurrence,
    availabilityRequestResolved,
    computeEndDate,
    checkbookingChargeAndbookAppointment,
    bookingCharge,
    closeExtraChargeModal,
    onConfirmBooking,
    isLoadingBooking,
    member,
    memberEmail,
    setNoteMessage,
    memberTimezone,
    handleInputChange,
    memberPresentInState,
  } = useRecurringBookingV2(duration, selectedDate?.toDate());

  const history = useHistory();

  const {message, messageType, navigateTo} = useSelector(
    selectUserNotification,
  );
  const handleTimeSlotClick = (timeOption: string) => {
    setSelectedTime(timeOption);
    const timeoutId = setTimeout(() => {
      scrollToElement();
    }, 0);

    return () => clearTimeout(timeoutId);
  };

  const day = daysOptions(t).find((day, idx) =>
    selectedDay ? day.value === selectedDay : idx + 1 === selectedDate?.day(),
  );

  const computedEndDateValue = computeEndDate();

  const modalProps = {
    message,
    messageType,
    isOpen: true,
    buttonFn: () => {
      onSuccess?.();
      if (navigateTo) {
        history.push(navigateTo);
      }
    },
  };

  const validForm =
    selectedTime &&
    Array.isArray(timeSlots) &&
    timeSlots.length > 0 &&
    availabilityRequestResolved;

  return (
    <>
      {messageType === 'none' || !isProvider(user) ? null : (
        <Suspense fallback={<div />}>
          <Modal {...modalProps} />
        </Suspense>
      )}
      {bookingCharge.showModal ? (
        <RecurringBookingChargeModal
          bookingCharge={bookingCharge}
          onConfirmBooking={onConfirmBooking}
          closeExtraChargeModal={closeExtraChargeModal}
          role={user?.role}
        />
      ) : null}
      <div className="flex flex-col font-inter justify-center text-xs gap-y-2 divide divide-gray-400">
        <div className=" w-full  border-gray-400 flex flex-col md:px-2 relative">
          {isMember(user) ? (
            <div className="flex items-center gap-2">
              <img
                className="w-10 h-10 border rounded-full object-cover"
                src={user?.therapistDetails?.image || PlaceholderImage}
                alt="Avatar"
              />
              <p className="text-sm">{user?.therapistDetails?.fullName}</p>
            </div>
          ) : null}

          {isProvider(user) && memberPresentInState ? (
            <p className="pt-1.5">{member?.fullName}</p>
          ) : null}

          {isProvider(user) && !memberPresentInState ? (
            <ClientChooser
              memberPresentInState={memberPresentInState}
              onMemberSelect={memberId => handleInputChange(memberId)}
              useEmailAsId={true}
              preselectedMember={preselectedMember}
            />
          ) : null}
        </div>

        {/* Middle Section */}
        <div className="border-gray-400 flex flex-col gap-2  my-2 md:px-2">
          <div className="flex gap-2 items-center">
            <p>{t('repeatFor')}&nbsp;</p>
            <DetachedSearchSelect
              selectComponent={Select}
              placeholder={t('week')}
              value={recurrenceOptions(t).find(
                r => r.value === recurrence.value,
              )}
              isMulti={false}
              styles={{
                ...detachedSearchBarStyles,
                detachedButton: (isOpen: boolean, hasValue: boolean) => {
                  const styles = detachedSearchBarStyles.detachedButton!(
                    isOpen,
                    hasValue,
                  );
                  return {
                    ...styles,
                    borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
                    color: hasValue ? 'black' : '#6B6B6B',
                  };
                },
              }}
              className="relative"
              isSearchable={false}
              options={recurrenceOptions(t)}
              onChange={selectedOption =>
                setRecurrence(selectedOption as OptionType)
              }
            />
          </div>
          <div>
            <div className="w-full flex flex-row gap-1 mt-2">
              <DetachedSearchSelect
                selectComponent={Select}
                placeholder={t('pickADay')}
                isMulti={false}
                className="w-full relative flex-1 flex-grow-0"
                // value={day}
                isDisabled={isProvider(user) && !memberEmail}
                isSearchable={false}
                styles={{
                  ...detachedSearchBarStyles,
                  detachedButton: (isOpen: boolean, hasValue: boolean) => {
                    const styles = detachedSearchBarStyles.detachedButton!(
                      isOpen,
                      hasValue,
                    );
                    return {
                      ...styles,
                      borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
                      color: hasValue ? 'black' : '#6B6B6B',
                    };
                  },
                }}
                options={daysOptions(t)}
                onChange={day => {
                  setSelectedTime('');
                  setSelectedDay(day!.value);
                }}
              />

              <DetachedSearchSelect
                selectComponent={Select}
                isMulti={false}
                className="w-full relative flex-1 max-w-full text-ellipsis min-w-0"
                isLoading={isLoading}
                noOptionsMessage={() => t('sorryNoSlotsAvailable')}
                loadingMessage={() => t('loading') + '...'}
                placeholder={t('availableSlots')}
                styles={{
                  ...detachedSearchBarStyles,
                  detachedButton: (isOpen: boolean, hasValue: boolean) => {
                    const styles = detachedSearchBarStyles.detachedButton!(
                      isOpen,
                      hasValue,
                    );
                    return {
                      ...styles,
                      borderColor: isOpen ? '#2E62EC' : 'rgb(204 204 204)',
                      color: hasValue ? 'black' : '#6B6B6B',
                    };
                  },
                }}
                isSearchable={false}
                options={
                  timeSlots?.map(timeOption => ({
                    value: convertTimeToDiffTimeZone(
                      timeOption,
                      memberTimezone,
                    ),
                    label: convertTimeToDiffTimeZone(
                      timeOption,
                      memberTimezone,
                      duration,
                    ),
                  })) ?? []
                }
                onChange={timeOption => {
                  handleTimeSlotClick(timeOption!.value);
                }}
              />
            </div>
          </div>

          {isProvider(user) ? (
            !memberEmail ? (
              <FormError error={t('chooseClientFirst')} />
            ) : null
          ) : null}
        </div>

        {validForm ? (
          <div className="flex flex-col gap-5 font-inter border-gray-500 my-2 py-4 px-1 md:px-2 pt-5">
            <section className="flex flex-col gap-3">
              <p>{t('confirmYourBooking')}</p>
              <section className="flex flex-col gap-2">
                <div className="flex gap-1 text-gray-500">
                  <CalendarRecurringIcon />
                  <p>
                    <Trans
                      i18nKey="recurringBookingTimes"
                      values={{
                        selectedTime,
                        day: day?.label,
                        computedEndDateValue,
                      }}
                    >
                      {selectedTime} Every {day} Until {computedEndDateValue}
                    </Trans>
                  </p>
                </div>
                <div className="flex text-gray-500 items-center gap-x-1">
                  <TimeIcon />
                  <p>{`${duration} mins`}</p>
                </div>
              </section>
            </section>
            {isMember(user) ? (
              <>
                <p className=" font-medium font-inter">
                  {t('addNoteToTherapist')}
                </p>

                <FormTextArea
                  id="noteToTherapist"
                  name="noteToTherapist"
                  placeholder=""
                  bgColor="white"
                  className="focus:border-primary font-inter  text-sm leading-4 rounded pl-3"
                  rows={3}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setNoteMessage(event.target.value);
                  }}
                />
              </>
            ) : null}
          </div>
        ) : null}

        {footerRef?.current
          ? createPortal(
              <BookingFormButtons
                member={member}
                appointmentType={AppointmentTypes.recurring_session}
                isPending={isLoadingBooking}
                selectedTime={selectedTime}
                onSubmit={checkbookingChargeAndbookAppointment}
                onCancel={onCancel}
                disabled={
                  !validForm ||
                  isLoadingBooking ||
                  (isProvider(user) && !memberEmail)
                }
                tooltipContent={
                  isProvider(user) && !memberEmail ? t('chooseClientFirst') : ''
                }
              />,
              footerRef.current,
            )
          : null}
      </div>
    </>
  );
};

export default RecurringBookingCard;
