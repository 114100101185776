import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {appointmentActions} from 'features/Appointment/appointmentSlice';
import {directBookingActions} from 'features/DirectBooking/directBookingSlice';
import {userActions} from 'features/User';
import {
  AppointmentTypes,
  StripePlanDurations,
  StripePlanNames,
} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {StripeService} from 'services';

import {paymentActions} from '../paymentSlice';

export const checkoutEpic: Epic<AppActions, AppActions, RootState> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(paymentActions.checkout.match),
    switchMap(
      ({
        payload: {
          referralCode,
          stripeResponse: {token},
          paymentPlanDuration,
          paymentPlan,
          couponCode,
          appointmentData,
        },
      }) =>
        from(
          StripeService.checkout({
            token,
            referralCode,
            couponCode,
            paymentPlan:
              paymentPlanDuration === StripePlanDurations.monthly
                ? paymentPlan
                : (`${paymentPlan}_${paymentPlanDuration}` as StripePlanNames),
            __subPaymentPlan: paymentPlan,
          }),
        ).pipe(
          withLatestFrom(state$),
          mergeMap(
            ([
              {
                data: {message: user},
              },
              state,
            ]) => {
              const {
                directBooking: {selectedProvider, selectedSession},
                user: {current},
              } = state;
              return [
                userActions.setUser(user),
                paymentActions.checkoutSuccess(),
                appointmentData
                  ? appointmentActions.bookAppointment(appointmentData)
                  : push({
                      pathname: '/dashboard',
                    }),
                ...(selectedSession && selectedProvider
                  ? [
                      directBookingActions.bookAppointment({
                        appointmentDateTimeString: selectedSession.datetime,
                        appointmentType:
                          selectedSession.appointmentType as AppointmentTypes,
                        appointmentTypeID: selectedSession.appointmentTypeId,
                        providerFullName: selectedProvider.providerFullName,
                        calendarId: selectedProvider.calendarId,
                        providerId: selectedProvider.providerId,
                        patientTimezone: selectedSession.timezone,
                        patientEmail: current?.email,
                        memberFullName: current?.fullName,
                      }),
                    ]
                  : []),
              ];
            },
          ),
          catchError((message: string) =>
            concat(
              of(paymentActions.checkoutFailure()),
              of(
                userActions.setAsyncError({
                  filter: 'payment',
                  message,
                }),
              ),
            ),
          ),
        ),
    ),
  );

export const paymentCheckoutEpics = [checkoutEpic];
