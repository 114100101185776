import {AppActions, RootState} from 'app/rootReducer';
import {FirebaseRefs} from 'definitions/Firebase';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {combineLatest, from, Observable, of} from 'rxjs';
import {
  catchError,
  delayWhen,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {UAParser} from 'ua-parser-js';
import {createObservableFromFirebase, isMobile} from 'utils';

import {pushNotificationActions} from './pushNotificationSlice';

const parser = new UAParser();

const checkSubscriptionStatusEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: Observable<firebase.app.App>}
> = (action$, state$, {firebase}) =>
  action$.pipe(
    filter(pushNotificationActions.checkSubscriptionStatus.match),
    delayWhen(() => action$.pipe(filter(userActions.setUser.match))),
    withLatestFrom(state$),
    filter(
      ([, state]) =>
        state.messaging.current !== null &&
        Boolean(state.messaging.userData?.patientId),
    ),
    mergeMap(([, currentState]) => {
      return combineLatest([firebase, from([currentState])]).pipe(
        map(([firebaseApp, selectedState]) => ({
          firebaseApp,
          patientId: selectedState.messaging.userData!.patientId,
          pushNotificationState: selectedState.pushNotification,
        })),
      );
    }),
    mergeMap(({firebaseApp, patientId, pushNotificationState}) => {
      return createObservableFromFirebase(
        firebaseApp
          .database()
          .ref(FirebaseRefs.subscriptionData)
          .child(patientId)
          .child(String(pushNotificationState.swInstallTimestamp))
          .once('value')
          .then(snap => {
            const subscriptionData = snap.val();
            return (
              subscriptionData.isMobile === isMobile &&
              subscriptionData.browserName === parser.getBrowser().name
            );
          }),
      ).pipe(
        map((val: boolean) =>
          pushNotificationActions.setSubscriptionStatus(val),
        ),
        catchError(err =>
          of(userActions.logData(`${err}, checkSubscriptionStatus`)),
        ),
      );
    }),
  );

const subscribeUserEpic: Epic<
  AppActions,
  AppActions,
  RootState,
  {firebase: Observable<firebase.app.App>}
> = (action$, state$, {firebase}) =>
  action$.pipe(
    filter(pushNotificationActions.subscribeUser.match),
    withLatestFrom(state$),
    filter(([, state]) => state.messaging.userData !== null),
    mergeMap(([action, currentState]) => {
      return combineLatest([
        firebase,
        from([action.payload]),
        from([currentState]),
      ]).pipe(
        map(([firebaseApp, payload, selectedState]) => ({
          firebaseApp,
          payload,
          currentUser: selectedState.messaging.userData!,
          paymentPlan: selectedState.user.current?.paymentPlan || '',
          __subPaymentPlan: selectedState.user.current?.__subPaymentPlan || '',
          pushNotificationDeviceData: selectedState.pushNotification,
        })),
      );
    }),
    mergeMap(
      ({
        firebaseApp,
        payload,
        currentUser,
        paymentPlan,
        __subPaymentPlan,
        pushNotificationDeviceData,
      }) => {
        const {fullName, patientId, uid, avatar, email} = currentUser;
        const {isMobile, browserName, swInstallTimestamp} =
          pushNotificationDeviceData;

        return createObservableFromFirebase(
          firebaseApp
            .database()
            .ref(FirebaseRefs.subscriptionData)
            .child(patientId)
            .child(String(swInstallTimestamp))
            .update({
              fullName,
              patientId,
              uid,
              avatar,
              email,
              ...payload,
              paymentPlan,
              __subPaymentPlan,
              isMobile,
              browserName,
              subscriptionId: swInstallTimestamp,
            }),
        ).pipe(
          map(() => pushNotificationActions.setSubscriptionStatus(true)),
          catchError(err => of(userActions.logData(`${err}, subscribeUser`))),
        );
      },
    ),
  );

export const pushNotificationEpics = [
  checkSubscriptionStatusEpic,
  subscribeUserEpic,
];
