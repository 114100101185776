import {format} from 'date-fns';
import dayjs from 'dayjs';
import {
  PaypalStandardPaymentCountries,
  StateInNetworkInsurances,
} from 'interfaces';

/**
 *
 * @param name string (i.e. insurance name)
 * @returns
 */
export const getInsuranceTagColors = (name: string) => {
  const insuranceName = name
    .toLowerCase()
    .trim()
    .replace(/\s+|[,/]/g, '_');
  switch (insuranceName) {
    case 'optum':
      return 'text-black bg-red-200';
    case 'oscar':
      return 'text-white bg-orange-500';
    case 'evernorth':
      return 'text-white bg-violet-700';
    case 'cigna_evernorth':
      return 'text-white bg-blue-500';
    case 'aetna':
      return 'text-black bg-slate-400';
    case 'florida_blue':
      return 'text-white bg-brick-700';
    case 'humana':
      return 'text-black bg-zinc-400';
    case 'united_health':
      return 'text-white bg-green-300';
    case 'bcbs':
      return 'text-black bg-sky-300';
    case 'blue_shield_of_ca':
      return 'text-black bg-blue-300';
    case 'magellan_health':
      return 'text-black bg-rose-100';
    case 'geha':
      return 'text-black bg-pink-200';
    case 'beacon':
      return 'text-white bg-green-900';
    default:
      return 'text-white bg-brick-700';
  }
};

/**
 *
 * @param type string (i.e. wellbit type)
 * @returns
 */

export enum WellbitType {
  providerReferral = 'providerReferral',
  supervision = 'supervision',
  oopDp = 'oopDp',
  inNetworkDp = 'inNetworkDp',
  oopWellnite = 'oopWellnite',
  inNetworkWellnite = 'inNetworkWellnite',
}

export enum WellbitColors {
  provider_referral = 'wellbit-provider-referral',
  supervision = 'wellbit-supervision',
  oop_dp = 'wellbit-oop-dp',
  in_network_dp = 'wellbit-in-network-dp',
  oop_wellnite = 'wellbit-oop-wellnite',
  in_network_wellnite = 'wellbit-in-network-wellnite',
}

export const getWellbitTypeColors = (type: string) => {
  // eslint-disable-next-line
  const wellbitType = type.trim();
  switch (wellbitType) {
    case WellbitType.providerReferral:
      return WellbitColors.provider_referral;
    case WellbitType.supervision:
      return WellbitColors.supervision;
    case WellbitType.oopDp:
      return WellbitColors.oop_dp;
    case WellbitType.inNetworkDp:
      return WellbitColors.in_network_dp;
    case WellbitType.oopWellnite:
      return WellbitColors.oop_wellnite;
    case WellbitType.inNetworkWellnite:
      return WellbitColors.in_network_wellnite;
    default:
      return WellbitColors.oop_dp;
  }
};

/**
 *
 * @param type number (i.e. Wellbits Count)
 * @returns
 */
export const shortNumberFormat = (count: number) => {
  if (count < 1000) {
    return count;
  }

  //  format
  const si = [
    {v: 1e3, s: 'K'},
    {v: 1e6, s: 'M'},
    {v: 1e9, s: 'B'},
    {v: 1e12, s: 'T'},
    {v: 1e15, s: 'P'},
    {v: 1e18, s: 'E'},
  ];

  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (count >= si[index].v) {
      break;
    }
  }

  return (
    (count / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
    si[index].s
  );
};

/**
 *
 * @param data string (i.e. in-network data)
 * @returns
 */
export const groupInNetworkDataByDate = (
  data: StateInNetworkInsurances[] | undefined,
) => {
  if (data) {
    const newData = data.map(function (item) {
      return {
        issuedDate: format(
          new Date(`${item.value?.createdTime}`),
          'MMM dd, yyyy',
        ),
        clientInsuranceName: item.value!.clientInsuranceName,
      };
    });

    const groupedInsurance = newData!.reduce(
      (group: {[key: string]: string[]}, item) => {
        if (!group[item.issuedDate as string]) {
          group[item.issuedDate as string] = [];
        }
        if (item.clientInsuranceName) {
          group[item.issuedDate].push(
            ...(item.clientInsuranceName as string[]),
          );
        }
        return group;
      },
      {},
    );
    return groupedInsurance;
  } else return {};
};

export function showPayLaterOptions(appointTime: string, duration: string) {
  const now = dayjs();
  const startTime = dayjs(appointTime);
  const endTime = startTime.add(Number(duration), 'minutes');

  // Calculate the difference in minutes between appointTime and now
  const diffInMinutes = startTime.diff(now, 'minute');

  // Check if the appointment is within the next 5 minutes
  if (
    (diffInMinutes >= 0 && diffInMinutes <= 5) ||
    (now.isAfter(startTime) && now.isBefore(endTime))
  ) {
    return true;
  } else {
    return false;
  }
}

export const paypalStandardPaymentCountries = [
  PaypalStandardPaymentCountries.SOUTH_AFRICA,
  PaypalStandardPaymentCountries.CHILE,
  PaypalStandardPaymentCountries.COLUMBIA,
];

export const isPaypalStandardPaymentCountries = (
  isoCode: PaypalStandardPaymentCountries,
) => paypalStandardPaymentCountries.includes(isoCode);
