import {selectUserProfile} from 'features/User';
import {useSelector} from 'react-redux';
import {isMember} from 'utils';

export function useShippingInfo(): {
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
} {
  const user = useSelector(selectUserProfile);
  let streetAddress = '';
  let city = '';
  let state = '';
  let postalCode = '';
  let country = '';

  if (isMember(user)) {
    streetAddress = user.shippingAddress?.streetAddress
      ? user.shippingAddress?.streetAddress
      : '';
    city = user.shippingAddress?.city ? user.shippingAddress?.city : '';
    state = user.shippingAddress?.state ? user.shippingAddress?.state : '';
    postalCode = user.shippingAddress?.postalCode
      ? user.shippingAddress?.postalCode
      : '';
    country = user.shippingAddress?.country
      ? user.shippingAddress?.country
      : '';
  }

  return {
    streetAddress,
    city,
    state,
    postalCode,
    country,
  };
}
