import {SliceStatus} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  submitRatingRequest,
  submitRatingRequestFailure,
  submitRatingRequestSuccess,
} from './ratingActions';

type RatingSliceState = {
  status: SliceStatus;
  error: string;
};

const RatingSlice = createSlice({
  name: 'rating',
  initialState: {
    status: SliceStatus.idle,
    error: '',
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<RatingSliceState>) =>
    builder
      .addCase(submitRatingRequest, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(submitRatingRequestFailure, (state, action) => ({
        ...state,
        status: SliceStatus.rejected,
        error: action.payload,
      }))
      .addCase(submitRatingRequestSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
        error: '',
      }))
      .addDefaultCase(state => state),
});

export const {reducer: ratingReducer, name: ratingReducerName} = RatingSlice;

export type TRatingActions =
  | ReturnType<typeof submitRatingRequest>
  | ReturnType<typeof submitRatingRequestSuccess>
  | ReturnType<typeof submitRatingRequestFailure>;

export const ratingActions = {
  submitRatingRequest,
  submitRatingRequestSuccess,
  submitRatingRequestFailure,
};

export type RatingState = ReturnType<typeof ratingReducer>;
