import {RefObject, useRef} from 'react';

export const useScrollToBottom = (): {
  scrollRef: RefObject<HTMLElement>;
  scrollToBottom: (behavior?: ScrollBehavior) => void;
} => {
  const scrollRef = useRef<HTMLElement>(null);

  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    if (scrollRef.current) {
      const {scrollHeight} = scrollRef.current;
      scrollRef.current.scrollTo({
        top: scrollHeight,
        behavior,
      });
    }
  };

  return {scrollToBottom, scrollRef};
};
