import {
  Appointment,
  AppointmentDurations,
  AppointmentLabels,
  AppointmentTypes,
  AvailableProvider,
  AvailableProviders,
  AvailableSlots,
  BookGroupAppointmentTypes,
  GetProviderAvailabiilitySlotsType,
  GroupCallAppointmentLabelReqData,
  UserRoles,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';
import {Token} from '@stripe/stripe-js';

export const bookAppointment = createAction<{
  appointmentDateTimeString: string;
  providerId: string;
  appointmentType: AppointmentTypes;
  patientTimezone: string;
  calendarId: number;
  appointmentTypeID: string;
  patientEmail?: string;
  providerFullName?: string;
  memberFullName?: string;
}>('BookAppointment');
export const bookAppointmentSuccess = createAction<Appointment>(
  'BookAppointmentSuccess',
);
export const bookAppointmentFailure = createAction<string>(
  'BookAppointmentFailure',
);
export const bookOneTimeAppointment = createAction<{
  token?: Token;
  appointmentDateTimeString: string;
  providerId: string;
  appointmentType: AppointmentTypes;
  patientTimezone: string;
  calendarId: number;
  appointmentTypeID: string;
}>('BookOneTimeAppointment');
export const bookOneTimeAppointmentSuccess = createAction<boolean>(
  'BookOneTimeAppointmentSuccess',
);
export const bookOneTimeAppointmentFailure = createAction<string>(
  'BookOneTimeAppointmentFailure',
);

export const cancelAppointment = createAction<{
  idToCancel: number;
  reasonForCancellation?: string;
  callback?: () => void;
  onError?: () => void;
}>('cancelAppointment');

export const cancelAppointmentSuccess = createAction<number>(
  'cancelAppointmentSuccess',
);
export const cancelAppointmentFailure = createAction<boolean>(
  'cancelAppointmentFailure',
);

export const bookGroupAppointment = createAction<
  BookGroupAppointmentTypes & {onSuccess: () => void; onError: () => void}
>('bookGroupAppointment');
export const bookGroupAppointmentSuccess = createAction<Appointment>(
  'bookGroupAppointmentSuccess',
);
export const bookGroupAppointmentFailure = createAction<string>(
  'bookGroupAppointmentFailure',
);

// updating appointment label
export const updateGroupCallAppointmentLabel = createAction<
  GroupCallAppointmentLabelReqData & {onSuccess: () => void}
>('UpdateGroupCallAppointmentLabel');

export const updateGroupCallAppointmentLabelSuccess = createAction<{
  appointmentID: number;
  message: AppointmentLabels[];
}>('UpdateGroupCallAppointmentLabelSuccess');

export const updateGroupCallAppointmentLabelFailure = createAction<{
  message: string;
}>('UpdateGroupCallAppointmentLabelFailure');

export const getProviderRecurringAvailableSlots = createAction<{
  startDate: string;
  duration: string;
  numberOfWeeks: string;
  patientEmail?: string;
}>('getProviderRecurringAvailableSlots');

export const getProviderRecurringAvailableSlotsSuccess =
  createAction<GetProviderAvailabiilitySlotsType>(
    'getProviderRecurringAvailableSlotsSuccess',
  );

export const getProviderRecurringAvailableSlotsFailure = createAction<string>(
  'getProviderRecurringAvailableSlotsFailure',
);
export const resetProviderAvailableSlots = createAction(
  'resetProviderAvailableSlots',
);

export const bookRecurringAppointment = createAction<{
  numberOfWeeks: number;
  appointmentStartDateTimeString: string;
  therapistId: string;
  patientTimezone: string;
  appointmentDuration: string;
  patientEmail?: string;
  noteMessage: string;
}>('BookRecurringAppointment');
export const bookRecurringAppointmentSuccess = createAction<Appointment[]>(
  'BookRecurringAppointmentSuccess',
);
export const bookRecurringAppointmentFailure = createAction<string>(
  'BookRecurringAppointmentFailure',
);

export const getAvailableProviders = createAction<{
  appointmentType: AppointmentTypes;
  duration: AppointmentDurations;
  email: string;
  role: UserRoles;
}>('GetAvailableProviders');

export const getAvailableProvidersSuccess = createAction<AvailableProviders>(
  'GetAvailableProvidersSuccess',
);

export const getAvailableProvidersFailure = createAction<boolean>(
  'GetAvailableProvidersFailure',
);

export const getAvailableSlots = createAction<{
  providerId: string;
  calendarId: number;
  yearAndMonth: string;

  // {appointmentType, duration, state} required for direct booking, and provider booking appointment
  appointmentType: AppointmentTypes;
  duration: AppointmentDurations;
  state?: string;
  day?: number;
}>('GetAvailableSlots');

export const getAvailableSlotsSuccess = createAction<{
  providerId: string;
  yearAndMonth: string;
  data: AvailableSlots;
}>('GetAvailableSlotsSuccess');

export const getAvailableSlotsFailure = createAction<boolean>(
  'GetAvailableSlotsFailure',
);

export const getProviderDataForAppointment = createAction<string>(
  'GetProviderDataForAppointment',
);
export const getProviderDataForAppointmentSuccess =
  createAction<AvailableProvider>('GetProviderDataForAppointmentSuccess');
export const getProviderDataForAppointmentFailure = createAction<boolean>(
  'GetProviderDataForAppointmentFailure',
);
