import {
  EndPoints,
  GetSelfAwarenessDataRequest,
  HttpMethods,
  SelfAwarenessFormWithUserId,
  SelfAwarenessResponse,
} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {attachQueryParameters, unwrapAPIError} from 'utils';

const getSelfAwarenessData = async ({
  patientId,
  ...args
}: GetSelfAwarenessDataRequest): Promise<SelfAwarenessResponse> => {
  try {
    const searchParams = attachQueryParameters('', args);

    const res = await requestHandler<{
      message: SelfAwarenessResponse;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.SelfAwarenessPatientData}/${patientId}/paginated${searchParams}` as unknown as EndPoints,
    });
    return res.data.message;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const saveSelfAwarenessData = async ({
  memberId,
  formData,
}: SelfAwarenessFormWithUserId): Promise<
  Response<{
    message: string;
  }>
> => {
  try {
    const res = await requestHandler<{message: string}, typeof formData>({
      method: HttpMethods.POST,
      url: `${EndPoints.SaveSelfAwarenessData}/${memberId}` as unknown as EndPoints,
      data: formData,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getSelfAwarenessPdf = async (
  docId: string,
  downloadExternally: boolean,
  password?: string,
): Promise<string> => {
  try {
    const res = await requestHandler<Blob>({
      method: HttpMethods.GET,
      url: `/api/self-awareness/${EndPoints.SelfAwarenessPatientDataPdf}/${docId}?downloadExternally=${downloadExternally}&password=${password}` as unknown as EndPoints,
      data: {
        responseType: 'blob',
      },
    });

    const pdfBlob = new Blob([res.data], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(pdfBlob);

    return url;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const SelfAwarenessService = {
  getSelfAwarenessData,
  saveSelfAwarenessData,
  getSelfAwarenessPdf,
};
