import * as Yup from 'yup';

export const VerifyPhoneTokenSchema = Yup.object()
  .shape({
    phoneNumber: Yup.string().required('Required'),
    // .test("invalid", "Phone number must be valid.", (phone) =>
    //   /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
    //     phone
    //   )
    // ),
    verificationCode: Yup.string()
      .min(6, 'Too Short!')
      .max(6, 'Too Long!')
      .required('Required!'),
  })
  .required();
