import {
  GetSelfAwarenessDataRequest,
  SelfAwarenessFormWithUserId,
  SelfAwarenessResponse,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const saveSelfAwarenessData = createAction<
  SelfAwarenessFormWithUserId & {callback: () => void}
>('saveSelfAwarenessData');
export const saveSelfAwarenessDataFailure = createAction<string>(
  'saveSelfAwarenessDataFailure',
);
export const saveSelfAwarenessDataSuccess = createAction(
  'saveSelfAwarenessDataSuccess',
);

export const getSelfAwarenessDataRequest =
  createAction<GetSelfAwarenessDataRequest>('GetSelfAwarenessDataRequest');

export const getSelfAwarenessDataFailure = createAction<string>(
  'GetSelfAwarenessDataFailure',
);

export const getSelfAwarenessDataSuccess = createAction<SelfAwarenessResponse>(
  'GetSelfAwarenessDataSuccess',
);

export const getSelfAwarenessPdfRequest = createAction<{
  docId: string;
  onSuccess: (url: string) => void;
  onError: () => void;
}>('GetSelfAwarenessPdfRequest');

export const getSelfAwarenessPdfFailure = createAction<string>(
  'GetSelfAwarenessPdfFailure',
);

export const getSelfAwarenessPdfSuccess = createAction(
  'GetSelfAwarenessPdfSuccess',
);

export const downloadSelfAwarenessPdfRequest = createAction<{
  docId: string;
  password: string;
  onSuccess: (url: string) => void;
  onError: () => void;
}>('DownloadSelfAwarenessPdfRequest');

export const downloadSelfAwarenessPdfFailure = createAction<string>(
  'DownloadSelfAwarenessPdfFailure',
);

export const downloadSelfAwarenessPdfSuccess = createAction(
  'DownloadSelfAwarenessPdfSuccess',
);
