import React, {FC, ReactNode} from 'react';
import {DashboardLayoutV2} from 'components';
import {selectUserProfile} from 'features/User';
import {useBreakpoint} from 'hooks';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {history, isPaypalStandardPaymentCountries} from 'utils';

import {selectAccountTab} from '../../features/Member';
import {AccountTabs, PaypalStandardPaymentCountries} from '../../interfaces';
import {MemberHeader} from '../Headers/MemberHeader';
import {MemberNavLink as NavLink} from '../Navigation/MemberNavLink';

const findTitle = (t: TFunction<'translate', undefined>, pathname: string) => {
  switch (pathname) {
    case '/membership':
      return t('membership');
    case '/payment':
      return t('manage_billing_methods');
    case '/profile':
      return t('profile');
    case '/terms-and-conditions':
      return t('termsAndConditions');
    case '/receipts':
      return t('title.receipts', 'Receipts');
    default:
      return t('title.profile');
  }
};

export const MemberProfileLayout: FC<{
  children: ReactNode[] | ReactNode;
}> = ({children}) => {
  const {pathname} = location;
  const {isLg} = useBreakpoint('lg');
  const accountTab = useSelector(selectAccountTab);
  const {t} = useTranslation();
  const user = useSelector(selectUserProfile);
  const isPaypalStandardMember = isPaypalStandardPaymentCountries(
    user?.countryOfResidence.code as PaypalStandardPaymentCountries,
  );

  return (
    <DashboardLayoutV2>
      <div className="h-full flex flex-col items-stretch w-full">
        {accountTab !== null && accountTab !== AccountTabs.overview ? null : (
          <MemberHeader
            title={t('rightNavigation.profile & account settings')}
            pageTitle={t('rightNavigation.profile & account settings')}
            mobileTitle={findTitle(t, pathname)}
            backButtonFn={() => {
              if (document.referrer.includes(window.location.host)) {
                history.goBack();
              } else {
                history.push('/account');
              }
            }}
          />
        )}
        <div className="grid grid-cols-1 lg:grid-cols-9 pb-24 lg:pb-0 responsive-w w-full">
          {isLg ? (
            <div className="lg:block lg:col-span-2 flex-col">
              <nav className="border rounded-3xl m-5 px-4">
                <NavLink
                  key="membership"
                  pathname="/membership"
                  classNames="pl-2 w-auto"
                  current={pathname == '/membership'}
                  linkName={t('membership')}
                ></NavLink>

                {isPaypalStandardMember ? null : (
                  <NavLink
                    key="payment"
                    pathname="/payment"
                    classNames="pl-2 w-auto"
                    current={pathname == '/payment'}
                    linkName={t('billing_payments')}
                  ></NavLink>
                )}

                <NavLink
                  key="receipts"
                  pathname="/receipts"
                  classNames="pl-2 w-auto"
                  current={pathname == '/receipts'}
                  linkName={t('receipts_menu_item', 'Receipts')}
                />
                <NavLink
                  key="profile"
                  pathname="/profile"
                  classNames="pl-2 w-auto"
                  current={pathname == '/profile'}
                  linkName={t('profile')}
                ></NavLink>
                <NavLink
                  key="terms-and-conditinos"
                  pathname="/terms-and-conditions"
                  classNames="pl-2 w-auto"
                  current={pathname == '/terms-and-conditions'}
                  linkName={t('termsAndConditions')}
                ></NavLink>
              </nav>
            </div>
          ) : null}

          <div className="col-span-7">{children}</div>
        </div>
      </div>
    </DashboardLayoutV2>
  );
};
