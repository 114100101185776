import {AppActions, RootState} from 'app/rootReducer';
import {uiActions} from 'app/uiReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {SliceStatus} from 'interfaces';
import {Epic, StateObservable} from 'redux-observable';
import {concat, from, Observable, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {AuthService} from 'services/api';

import {userActions} from '../../userSlice';

const outSideWellniteRegisterEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.outSideWellniteRegister.match),
    switchMap(({payload: {providerId, ...rest}}) =>
      from(
        AuthService.outsideWellniteRegister({
          ...rest,
          providerId,
          email: rest.email.toLowerCase(),
          keepSignedIn: true,
        }),
      ).pipe(
        mergeMap(({data: {message: user}}) => [
          userActions.setUser(user),
          userActions.outsideWellniteRegisterSuccess(),
          push({
            pathname: `/digital-practice/${providerId}`,
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.outSideWellniteRegisterFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

/**
 * @description `waitForUserSet` action handle three cases
 *  1. allow logged in user to access digital practice registration page
 *  2. allow new user to access digital practice registration page
 *  3. if user is on the DP insurance page, then `waitForUserSet` disregard the waiting for `setUser` action and fetch the insurance options
 *
 * @param action$
 * @param state$
 * @returns
 */
const waitForUserSet = (
  action$: Observable<AppActions>,
  state$: StateObservable<RootState>,
) => {
  return state$.pipe(
    map(
      state =>
        !!state.user.current?.email &&
        location.pathname.includes('/practice/register/'),
    ),
    switchMap(userExists =>
      userExists
        ? action$.pipe(filter(userActions.resetUser.match), take(1)) // Wait for resetUser action if user exists i.e. loadAppStateEpic
        : of(null),
    ),
  );
};

export const getDigitalPracticeProviderDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    filter(uiActions.getDigitalPracticeProviderData.match),
    withLatestFrom(state$), // Combine the action with the latest state
    filter(
      ([_, state]) =>
        state.ui.digitalPracticeProvider.status !== SliceStatus.resolved,
    ), // Proceed only if status is not 'resolved'
    concatMap(([action]) =>
      waitForUserSet(action$, state$).pipe(
        take(1), // Make sure we only wait once for the user to be set
        mergeMap(() =>
          from(
            AuthService.getDigitalPracticeProviderData(
              action.payload.providerId,
              action.payload.isReferralLink,
            ),
          ).pipe(
            mergeMap(({data}) => [
              uiActions.getDigitalPracticeProviderDataSuccess(data.message),
            ]),
            catchError((message: string) =>
              of(uiActions.getDigitalPracticeProviderDataFailure(message)),
            ),
          ),
        ),
      ),
    ),
  );

export const getDigitalPracticeProviderDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(uiActions.getDigitalPracticeProviderDataFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const getEmployersEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.getEmployers.match),
    switchMap(({payload}) => {
      return from(AuthService.getEmployers(payload.size, payload?.offset)).pipe(
        mergeMap(({data: {message}}) => {
          return [
            userActions.getEmployersSuccess({
              data: message,
            }),
          ];
        }),
        catchError(() => {
          return concat(of(userActions.getEmployersFailure()));
        }),
      );
    }),
  );

export const searchEmployersEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.searchEmployers.match),
    switchMap(({payload}) => {
      return from(
        AuthService.getEmployers(
          payload.size,
          payload?.offset,
          payload?.searchKeyword,
        ),
      ).pipe(
        mergeMap(({data: {message}}) => {
          return [
            userActions.searchEmployersSuccess({
              data: message,
            }),
          ];
        }),
        catchError(() => {
          return concat(of(userActions.searchEmployersFailure()));
        }),
      );
    }),
  );

export const outsideWellniteRegisterUserEpics = [
  outSideWellniteRegisterEpic,
  getDigitalPracticeProviderDataEpic,
  getDigitalPracticeProviderDataFailureEpic,
  getEmployersEpic,
  searchEmployersEpic,
];
