import {getAllCountries} from './countryUtils';

export const getLanguageFromCountry = (country: string) => {
  return getAllCountries().find(c => c.isoCode === country)?.languageCode;
};

export const getLocaleFromCountryCode = (countryCode: string) => {
  return getAllCountries().find(c => c.isoCode === countryCode)?.locale;
};

export const getLocaleFromCountryName = (countryName: string) => {
  return getAllCountries().find(c => c.name === countryName)?.locale;
};

export const isSupportedLocale = (locale: string) => {
  return getAllCountries().some(c => c.locale === locale?.toLowerCase());
};

export const getCountryCodeFromLocale = (locale: string) => {
  return getAllCountries().find(c => c.locale === locale?.toLowerCase())
    ?.isoCode;
};

export const getCountryNameFromLocale = (locale: string) => {
  return getAllCountries().find(c => c.locale === locale?.toLowerCase())?.name;
};
