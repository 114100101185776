export type PayPalCardDetail = {
  paymentToken: string;
  brand: string;
  lastDigits: string;
};

export type PayPalCardDetails = {
  cardDetails: PayPalCardDetail;
};

export type PayPalPaymentCardsListResponse = {
  creditCards: PayPalCardDetails[];
};

export type PayPalGetSellerOnboardingDetailsResponse = {
  legalName: string;
  paymentsReceivable: boolean;
  primaryEmailConfirmed: boolean;
  oauthIntegrations: [];
};

export enum PaypalStandardPaymentCountries {
  SOUTH_AFRICA = 'ZA',
  CHILE = 'CL',
  COLUMBIA = 'CO',
}
