import React from 'react';

import {SvgIcon} from './SvgIcon';

export const CurrencyDollarIcon: SvgIcon = ({
  width = 20,
  height = 20,
  classes = '',
  fillColor = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="2 2 20 20"
    stroke="currentColor"
    className={` ${classes}`}
    width={width}
    height={height}
  >
    <path d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
);

export const CurrencyEuroIcon: SvgIcon = ({
  width = 20,
  height = 20,
  classes = '',
  fillColor = 'none',
}) => {
  return (
    <svg
      fill={fillColor}
      strokeWidth={1.5}
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      width={width}
      height={height}
      className={` ${classes}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );
};
