import React, {FC, lazy, ReactNode, Suspense, useEffect, useRef} from 'react';
import {CacheBuster} from 'cache-controller';
import {FullLoader, MainLayout} from 'components';
import RatingModal from 'components/RatingModal';
import {ConnectedRouter} from 'connected-react-router';
import {PaypalBanner} from 'features/Payment/CreditCardMgmt/Paypal/PaypalBanner';
import {pushNotificationActions} from 'features/PushNotification';
import {
  selectUserBootstrap,
  selectUserCountry,
  selectUserLanguage,
  selectUserProfile,
  userActions,
} from 'features/User';
import {
  useCometChatListener,
  useInitializeProviderData,
  useSmartLook,
  useSocket,
  useWindowSize,
} from 'hooks';
import useCache from 'hooks/useCache';
import {UserRoles} from 'interfaces';
import {HelmetProvider} from 'react-helmet-async';
import {connect, useSelector} from 'react-redux';
import {Slide, toast, ToastContainer} from 'react-toastify';
import {IndexedDBService} from 'services';
import initializeIntercom from 'services/api/Intercom';
import {ThemeProvider} from 'styled-components';
import {GlobalStyles, theme} from 'styles';
import {componentLoader, history} from 'utils';

import {Action, Dispatch} from '@reduxjs/toolkit';

// eslint-disable-next-line
import pkg from '../../package.json';

import {AllRoutes} from './routes/Routes';
import {ErrorBoundary} from './ErrorPage';
import {initializeI18next} from './initI18next';
import {RootState} from './rootReducer';

import 'react-star-picker/styles.css';
import 'react-toastify/dist/ReactToastify.min.css';

const IntercomIds = {
  production: {
    [UserRoles.member]: process.env.REACT_APP_MEMBER_INTERCOM_APP_ID,
    [UserRoles.prescriber]: process.env.REACT_APP_PROVIDER_INTERCOM_APP_ID,
    [UserRoles.therapist]: process.env.REACT_APP_PROVIDER_INTERCOM_APP_ID,
  },
  staging: {
    [UserRoles.member]: process.env.REACT_APP_MEMBER_INTERCOM_APP_ID,
    [UserRoles.prescriber]: process.env.REACT_APP_PROVIDER_INTERCOM_APP_ID,
    [UserRoles.therapist]: process.env.REACT_APP_PROVIDER_INTERCOM_APP_ID,
  },
  development: {
    [UserRoles.member]: '',
    [UserRoles.prescriber]: '',
    [UserRoles.therapist]: '',
  },
};

const isProduction = process.env.NODE_ENV === 'production';

const OfflineNotification = lazy(() =>
  componentLoader(() => import('./OfflineNotification')),
);
const AppTheme: React.FC<{children: ReactNode}> = ({children}) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  getIDBState: IndexedDBService.GetUserState('WellniteDB', dispatch),
  setPwaInstallEvt: (event: Event) =>
    dispatch(userActions.setPwaInstallEvt(event)),
  setSWInstallTStamp: () =>
    dispatch(pushNotificationActions.setSwInstallTimestamp(+`${Date.now()}`)),
});

type Props = {
  getIDBState(): void;
  setPwaInstallEvt(event: Event): void;
  setSWInstallTStamp(): void;
  video: {
    rateVideoCall: {
      appointmentID: string;
    };
  };
};

const App: FC<Props> = ({getIDBState, video}) => {
  const user = useSelector(selectUserProfile);

  useCometChatListener(user);
  useSocket(user);
  useSmartLook(user);

  const cachedPreferredLanguage = useRef<string>('');
  const preferredLanguage = useSelector(selectUserLanguage);
  const countryOfResidence = useSelector(selectUserCountry);

  useEffect(() => {
    const newPreferredLanguage = `${countryOfResidence}-${preferredLanguage}`;
    if (newPreferredLanguage !== cachedPreferredLanguage.current) {
      initializeI18next();
      cachedPreferredLanguage.current = newPreferredLanguage;
    }
  }, [preferredLanguage, countryOfResidence]);

  useEffect(() => {
    getIDBState();
  }, []);

  const bootstrap = useSelector(selectUserBootstrap);
  const {isDesktop} = useWindowSize();
  const {clearBrowserStorage} = useCache();

  const {isProviderReady} = useInitializeProviderData();

  // Intercom initialization
  if (process.env.REACT_APP_ENV) {
    const intercomAppId =
      IntercomIds[process.env.REACT_APP_ENV][user?.role || '']; // this snippet `user?.role || ''` is necessary to run to add Intercom function to the window object.
    initializeIntercom(intercomAppId);
  }

  return (
    <CacheBuster
      currentVersion={pkg.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<FullLoader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory="." //If public assets are hosted somewhere other than root on your server.
      onCacheClear={clearBrowserStorage}
    >
      <HelmetProvider>
        <AppTheme>
          <GlobalStyles />
          <ErrorBoundary>
            <ToastContainer
              position={
                isDesktop
                  ? toast.POSITION.BOTTOM_RIGHT
                  : toast.POSITION.BOTTOM_CENTER
              }
              hideProgressBar
              transition={Slide}
            />

            <MainLayout>
              <Suspense fallback={<div />}>
                <OfflineNotification />
              </Suspense>
              <ConnectedRouter history={history}>
                <PaypalBanner user={user} />
                {bootstrap || !isProviderReady ? <FullLoader /> : <AllRoutes />}
              </ConnectedRouter>
            </MainLayout>
          </ErrorBoundary>
          {video?.rateVideoCall?.appointmentID ? <RatingModal /> : null}
        </AppTheme>
      </HelmetProvider>
    </CacheBuster>
  );
};
const mapStateToProps = (state: RootState) => ({
  video: state.video,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
