import {
  FetchReceiptPdfArguments,
  FetchReceiptsResponse,
  UserRoles,
} from 'interfaces';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {EndPoints, HttpMethods} from '../HTTP/HTTP.types';

/**
 * Asynchronously retrieves the list of receipts associated with a user.
 *
 * @async
 * @returns {Promise<{ message: FetchReceiptsResponse }>}
 * @throws If an error occurs during the request, the promise is rejected with the appropriate error.
 */
const fetchReceipts = async (args: {
  role: UserRoles;
}): Promise<{
  message: FetchReceiptsResponse;
}> => {
  try {
    const res = await requestHandler<{
      message: FetchReceiptsResponse;
    }>({
      method: HttpMethods.GET,
      url: `/api/${args.role}${EndPoints.FetchReceipts}` as unknown as EndPoints,
    });
    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const fetchReceiptPdf = async (
  args: FetchReceiptPdfArguments,
): Promise<Blob> => {
  try {
    const res = await requestHandler<Blob>({
      method: HttpMethods.GET,
      url: `/api/${args.role}${EndPoints.FetchReceiptPdf}/${args.id}` as unknown as EndPoints,
      data: {
        responseType: 'blob',
      },
    });

    return res.data;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const ReceiptService = {
  fetchReceipts,
  fetchReceiptPdf,
};
