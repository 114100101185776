import {selectUserProfile} from 'features/User';
import {AppointmentTypes, UserRoles} from 'interfaces';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';

export const useAppointmentTypes = (apptType?: AppointmentTypes) => {
  const {params} = useRouteMatch();
  const user = useSelector(selectUserProfile);
  const appointmentType =
    apptType ?? (params['appointmentType'] as AppointmentTypes);

  const roleBasedApptTypes = {
    [UserRoles.member]:
      appointmentType === AppointmentTypes.video_call_with_therapist
        ? [appointmentType, AppointmentTypes.recurring_session]
        : [appointmentType],
    [UserRoles.prescriber]: [AppointmentTypes.doctor_consultation],
    [UserRoles.therapist]: [
      AppointmentTypes.video_call_with_therapist,
      AppointmentTypes.recurring_session,
      AppointmentTypes.group_call_with_therapist,
    ],
  };

  const currRole = user?.role as
    | UserRoles.member
    | UserRoles.prescriber
    | UserRoles.therapist;

  return roleBasedApptTypes[currRole];
};
