import {store} from 'app/store';
import {
  EndPoints,
  HttpMethods,
  InsuranceNamesType,
  MemberProfile,
  PverifyInfoType,
  SocialOnboardingStatusEnum,
  SocialOnboardingType,
} from 'interfaces';
import {Response} from 'redaxios';
import {unwrapAPIError} from 'utils';

import {requestHandler} from '../HTTP';
import {emitIntercomEventForInsuranceOnboarding} from '../Intercom/helpers';

const saveSocialOnboardingData = async (obj: {
  data: Partial<SocialOnboardingType>;
  onboardStage: number;
}): Promise<
  Response<{
    message: {member: MemberProfile; pverifyInfo: PverifyInfoType};
  }>
> => {
  const data = {
    ...obj.data,
    onboardStage: obj.onboardStage,
  };

  data.gender = data.otherGender === '' ? data.gender : data.otherGender;
  delete data.otherGender;

  try {
    const res = await requestHandler<
      {
        message: {member: MemberProfile; pverifyInfo: PverifyInfoType};
      },
      typeof data
    >({
      method: HttpMethods.POST,
      url: EndPoints.SaveSocialOnboardingData,
      data,
    });

    const prev = store.getState().user.current;
    const curr = {...store.getState().user.current, insuranceDetails: data};

    emitIntercomEventForInsuranceOnboarding(
      prev as MemberProfile,
      curr as MemberProfile,
      res.data.message.pverifyInfo as PverifyInfoType,
    );

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const fetchInsuranceNames = async (): Promise<
  Response<{
    message: {
      modifiedInsuranceNames: InsuranceNamesType;
    };
  }>
> => {
  try {
    const res = await requestHandler<{
      message: any;
    }>({
      method: HttpMethods.GET,
      url: `${EndPoints.GetInsuranceNames}` as unknown as EndPoints,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const changeOnboardingStatusOrStage = async (data: {
  socialOnboardingStatus?: SocialOnboardingStatusEnum;
  onboardStage?: number;
}): Promise<
  Response<{
    message: MemberProfile;
  }>
> => {
  try {
    const res = await requestHandler<{
      message: MemberProfile;
    }>({
      method: HttpMethods.POST,
      url: `${EndPoints.ChangeSocialOnboardingStatus}` as unknown as EndPoints,
      data,
    });
    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const SocialOnboardingService = {
  saveSocialOnboardingData,
  fetchInsuranceNames,
  changeOnboardingStatusOrStage,
};
