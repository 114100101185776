/* eslint-disable max-lines-per-function */
import {useCallback, useEffect, useMemo, useState} from 'react';
import Toast from 'components/Basic/Toast';
import {addHours, format, isSameDay} from 'date-fns';
import {RescheduleAppointmentSchema} from 'definitions/Yup';
import {
  appointmentActions,
  getProvider,
  selectAvailability,
  selectSlotAvailabilityStatus,
} from 'features/Appointment';
import {
  bookAppointment,
  bookOneTimeAppointment,
} from 'features/Appointment/Booking/bookingActions';
import {selectUserProfile} from 'features/User';
import {
  AppointmentDurations,
  AppointmentTypes,
  AvailableProvider,
  MemberProfile,
  RescheduleAppointmentData,
  SliceStatus,
  UserAccountType,
} from 'interfaces';
import {Modifier} from 'react-day-picker';
import {
  Control,
  FieldErrorsImpl,
  useForm,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {BookingService} from 'services';
import {dateTimeStringCompatibility, isMember, isProvider} from 'utils';
import * as Yup from 'yup';

import {yupResolver} from '@hookform/resolvers/yup';

import {useError} from './useError';
import {useRequesting} from './useRequesting';

type OptionType = {label: string; value: string};

export function useRescheduleBooking(
  appointmentType: AppointmentTypes,
  duration: AppointmentDurations,
  member?: MemberProfile,
): {
  zones: OptionType[];
  time: {value: string; rawTime: string; disabled: boolean}[];
  selectedTime: string;
  memberState: string;
  providerId: string;
  providerCalendar: {
    availabilityDate: string;
    availabilityTimes: string[];
  }[];
  register: UseFormRegister<RescheduleAppointmentData>;
  watch: any;
  errors: FieldErrorsImpl<RescheduleAppointmentData>;
  disabledDays: Modifier | Modifier[];
  apiErrorMsg: string;
  isLoading: SliceStatus;
  bookingCharge: {
    status: SliceStatus;
    showModal: boolean;
    amount: number;
  };
  onSubmit: any;
  control: Control<RescheduleAppointmentData>;
  setValue: UseFormSetValue<RescheduleAppointmentData>;
  onConfirmBooking: () => void;
  closeExtraChargeModal: () => void;
  appointmentProvider: {
    data: AvailableProvider;
    status: SliceStatus;
  };
  resetLimitTo: () => void;
  slotLoadingStatus: SliceStatus;
  getProviderCalendarData: (date: Date) => void;
} {
  const [bookingCharge, setBookingCharge] = useState({
    status: SliceStatus.idle,
    showModal: false,
    amount: 0,
  });
  const [zones, setZones] = useState<OptionType[]>([]);
  const [timeArr, setTimeArr] = useState<
    {value: string; rawTime: string; disabled: boolean}[]
  >([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUserProfile);
  const availability = useSelector(selectAvailability);
  const slotLoadingStatus = useSelector(selectSlotAvailabilityStatus);
  const provider = useSelector(getProvider);

  const params = useParams<{providerId: string}>();

  const calendarId = provider?.data?.acuity?.calendarId;
  const providerId = useMemo(() => {
    if (
      appointmentType === AppointmentTypes.video_call_with_therapist &&
      provider?.data?.therapistId
    ) {
      return provider.data.therapistId;
    } else if (
      appointmentType === AppointmentTypes.doctor_consultation &&
      provider?.data?.prescriberId
    ) {
      return provider.data.prescriberId;
    }
    return '';
  }, [provider]);

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    watch,
    setError,
    setValue,
  } = useForm<RescheduleAppointmentData>({
    defaultValues: {
      appointmentType,
      timezone: '',
      date: new Date(),
      time: '',
    },
    resolver: yupResolver(RescheduleAppointmentSchema),
  });

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const FourtyEightHoursFromNow = addHours(today, 48);

  //this is to limit the calendar to intially show availabilty for no more than two days in advance.
  const [limitTo, setlimitTo] = useState<Date | undefined>(
    FourtyEightHoursFromNow,
  );

  const resetLimitTo = () => {
    setlimitTo(undefined);
  };
  const [date, timezone, time] = watch(['date', 'timezone', 'time']);

  const appointmentTypeID = String(availability?.appointmentTypeId);
  const isLoading = useRequesting('appointment');

  const {apiError, resetAsyncError} = useError('appointment');
  const memberState = isMember(user)
    ? user?.stateOfResidence
    : member?.stateOfResidence ?? '';
  const email = isMember(user) ? user?.email : member?.email ?? '';

  const getProviderCalendarData = useCallback(
    (date: Date): void => {
      if (providerId && calendarId) {
        dispatch(
          appointmentActions.getAvailableSlots({
            appointmentType,
            duration,
            yearAndMonth: format(date, 'yyyy-MM'),
            providerId,
            calendarId,
            state: memberState,
          }),
        );
      }
    },
    [dispatch, providerId, calendarId, appointmentType, duration, memberState],
  );

  const providerCalendar =
    providerId && availability?.slots ? availability?.slots[providerId] : [];
  const onBookAppoinment = (
    values: Yup.InferType<typeof RescheduleAppointmentSchema>,
  ) => {
    const {error, datetime} = BookingService.getAppointmentDateTimeString(
      {
        time: values.time,
        timezone: values.timezone,
        date: values.date as Date,
      },
      providerCalendar,
    );

    if (datetime && calendarId) {
      const appointmentData = {
        appointmentDateTimeString: datetime,
        providerId,
        appointmentType: values.appointmentType as AppointmentTypes,
        patientTimezone: values.timezone,
        calendarId,
        appointmentTypeID,
      };

      const scheduleAppointmentByMember = () => {
        if (
          user?.subscriptionStatus.includes('not-subscribed') &&
          appointmentType !== 'chat_with_coach' &&
          /(commercial|public)/.test(
            user?.insuranceDetails?.companyType ?? '',
          ) &&
          Array.isArray(user?.patientCards) &&
          user?.patientCards.length > 0
        ) {
          dispatch(bookOneTimeAppointment(appointmentData));
        } else if (
          (user?.socialOnboardingExtraFlow === 'zocdoc' &&
            !/(mindfulness|medicalcare_plus_therapy|uninsured|medicalcare)/.test(
              user?.paymentPlan,
            )) ||
          user?.accountType === UserAccountType.scale
        ) {
          dispatch(bookOneTimeAppointment(appointmentData));
        } else {
          if (
            user?.subscriptionStatus.includes('not-subscribed') &&
            appointmentType !== 'chat_with_coach'
          ) {
            history.push({
              pathname: '/update-plan',
              state: {
                appointmentData,
              },
            });
          } else {
            dispatch(bookAppointment(appointmentData));
          }
        }
      };

      if (!user?.governmentID && appointmentType === 'chat_with_coach') {
        history.push({
          pathname: '/onboarding-medical-intake',
          state: {
            appointmentData,
          },
        });
        return;
      }

      scheduleAppointmentByMember();
    }
    if (error)
      setError('date', {
        type: 'manual',
        message: error,
      });
  };

  const disabledDaysFn = useCallback(
    (limitTo?: Date) => {
      return BookingService.getDisabledDays(
        providerCalendar,
        isProvider(user),
        limitTo,
      );
    },
    [providerCalendar, limitTo],
  );

  useEffect(() => {
    if (user?.countryOfResidence.code) {
      setValue(
        'timezone',
        BookingService.isCountryOfResidenceRegion(
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          user.countryOfResidence.code,
        )
          ? Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
          : BookingService.getCountryDefaultTimeZone(
              user.countryOfResidence.code,
            ),
      );
    }
  }, [user]);

  //pass limitTo to intially show availabilty for no more than two days in advance.

  const disabledDays = disabledDaysFn(limitTo);

  useEffect(() => {
    if (providerId) {
      // first available day of the provider's calendar
      setValue('date', new Date());
    }
  }, [setValue, providerId]);

  useEffect(() => {
    if (providerCalendar.length) {
      const currentDay = providerCalendar.find(day => {
        const formattedDate = dateTimeStringCompatibility(day.availabilityDate);
        return isSameDay(new Date(formattedDate), date as Date);
      });
      const hours = BookingService.getAvailableTimeData(
        timezone,
        currentDay?.availabilityTimes || [],
      );
      setTimeArr(hours);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, timezone]);

  useEffect(() => {
    if (params?.providerId) {
      dispatch(
        appointmentActions.getProviderDataForAppointment(params.providerId),
      );
    }
  }, [params]);

  useEffect(() => {
    if (provider?.data?.acuity?.calendarId) {
      getProviderCalendarData(new Date());
    }
  }, [provider]);

  useEffect(() => {
    let isSubscribed = true;
    const timezoneOptions = BookingService.getTimezonesArray(
      user!.countryOfResidence.code,
    ).map(zone => ({
      label: zone.item.text,
      value: zone.id,
    }));

    if (isSubscribed) {
      setZones(timezoneOptions);
    }
    return (): void => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    return (): void => {
      if (apiError) dispatch(resetAsyncError('appointment'));
    };
  }, [apiError, dispatch, resetAsyncError]);

  const onSubmit = handleSubmit(async values => {
    try {
      setBookingCharge({...bookingCharge, status: SliceStatus.pending});
      const res = await BookingService.getBookingCharge({
        role: user!.role,
        patientEmail: email,
        appointmentType,
        appointmentTypeID,
      });

      if (res.data.message.charge > 0) {
        setBookingCharge({
          ...bookingCharge,
          status: SliceStatus.resolved,
          amount: res.data.message.charge,
          showModal: true,
        });
      } else {
        onBookAppoinment(values);
      }
    } catch (error) {
      setBookingCharge({
        ...bookingCharge,
        status: SliceStatus.rejected,
        showModal: false,
      });
      Toast({
        type: 'error',
        message: 'oops! something went wrong, please try booking again.',
      });
    }
  });

  const closeExtraChargeModal = () => {
    setBookingCharge({
      ...bookingCharge,
      showModal: false,
    });
  };

  const onConfirmBooking = () => {
    setBookingCharge({...bookingCharge, showModal: false});
    onBookAppoinment(watch());
  };

  return {
    zones,
    time: timeArr,
    onSubmit,
    errors,
    register,
    control,
    memberState,
    providerId,
    providerCalendar,
    disabledDays,
    selectedTime: time,
    apiErrorMsg: apiError,
    isLoading,
    watch,
    setValue,
    bookingCharge,
    onConfirmBooking,
    closeExtraChargeModal,
    appointmentProvider: provider,
    resetLimitTo,
    slotLoadingStatus,
    getProviderCalendarData,
  };
}
