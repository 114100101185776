import {
  ExercisesPaginationType,
  ExerciseType,
  GeneratedExerciseResponse,
  GenerateExerciseProps,
  SaveExerciseProps,
  UpdateExerciseAnswersType,
} from 'features/WellniteExercise/utils/constants';
import {EndPoints, HttpMethods, UserRoles} from 'interfaces';
import {Response} from 'redaxios';
import {requestHandler} from 'services/api';
import {unwrapAPIError} from 'utils';

const generateExercise = async <
  TData = GenerateExerciseProps,
  TResponse = GeneratedExerciseResponse,
>(
  data: TData,
): Promise<Response<{message: TResponse}>> => {
  try {
    const res = await requestHandler<{message: TResponse}, TData>({
      method: HttpMethods.POST,
      url: EndPoints.GenerateWellniteExercise as unknown as EndPoints,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const regenerateExerciseQuestion = async <
  TData = GenerateExerciseProps,
  TResponse = GeneratedExerciseResponse,
>(
  data: TData,
): Promise<Response<{message: TResponse}>> => {
  try {
    const res = await requestHandler<{message: TResponse}, TData>({
      method: HttpMethods.POST,
      url: `${EndPoints.GenerateWellniteExercise}?maximumQuestions=1` as unknown as EndPoints,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const publishExercise = async <
  TData = SaveExerciseProps,
  TResponse = ExerciseType,
>(
  data: TData,
): Promise<Response<{message: {exercise: TResponse}}>> => {
  try {
    const res = await requestHandler<{message: {exercise: TResponse}}, TData>({
      method: HttpMethods.POST,
      url: EndPoints.SaveExercise as unknown as EndPoints,
      data,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const getWellniteExcercises = async ({
  patientId,
  page,
  userRole,
  pageSize,
}: {
  patientId?: string;
  page: number;
  pageSize: number;
  userRole: UserRoles;
}): Promise<
  Response<{
    message: {exercises: ExerciseType[]; pagination: ExercisesPaginationType};
  }>
> => {
  const url = (userRole == UserRoles.member
    ? `${EndPoints.GetWellniteExcercisesByMember}?page=${page}&limit=${pageSize}`
    : `${EndPoints.GetWellniteExcercisesByProvider}/${patientId}?page=${page}&limit=${pageSize}`) as unknown as EndPoints;
  try {
    const res = await requestHandler<{
      status: number;
      message: {exercises: ExerciseType[]; pagination: ExercisesPaginationType};
    }>({
      method: HttpMethods.GET,
      url,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const updateExerciseAnswers = async <
  TData extends UpdateExerciseAnswersType,
  TResponse = ExerciseType,
>(
  data: TData,
): Promise<Response<{message: {exercise: TResponse}}>> => {
  const {exerciseId, answers} = data;
  try {
    const res = await requestHandler<
      {message: {exercise: TResponse}},
      {answers: {_id: string; answer: string}[]}
    >({
      method: HttpMethods.POST,
      url: `${EndPoints.UpdateExerciseAnswers}/${exerciseId}` as unknown as EndPoints,
      data: {answers},
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

const sendInitialExerciseRequest = async () => {
  try {
    const res = await requestHandler({
      method: HttpMethods.POST,
      url: EndPoints.SendInitialExerciseRequest as unknown as EndPoints,
    });

    return res;
  } catch (error) {
    const errorValue = unwrapAPIError(error);
    return Promise.reject(errorValue);
  }
};

export const WellniteExerciseService = {
  generateExercise,
  regenerateExerciseQuestion,
  publishExercise,
  getWellniteExcercises,
  updateExerciseAnswers,
  sendInitialExerciseRequest,
};
