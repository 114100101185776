import React, {FC} from 'react';

interface ProgressBarProps {
  fill: number;
  total: number;
  backgroundColor?: string;
  fillColor?: string;
  height?: string;
  width?: string;
  border?: string;
  shadow?: string;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  fill,
  total,
  backgroundColor = 'bg-grey-500',
  fillColor = 'bg-blue-600',
  height = 'h-1',
  width = 'w-full',
  border,
  shadow = 'shadow',
}) => {
  return (
    <div className={width}>
      <div
        className={`${shadow} ${width} ${backgroundColor} ${height} ${border}`}
      >
        <div
          className={`${fillColor} ${border} text-xs leading-none ${height} text-center text-white transition-all ease-out duration-500`}
          style={{
            width: `${(fill / total) * 100}%`,
          }}
        />
      </div>
    </div>
  );
};
