import {config} from '../tailwind.config';

import useMediaQuery from './useMediaQuery';

const breakpoints = config.theme.screens;

type BreakpointKey = keyof typeof breakpoints;

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  const isMatch = useMediaQuery(`(min-width: ${breakpoints[breakpointKey]})`);
  const capitalizedKey =
    breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  const breakpointWidth = Number(
    String(breakpoints[breakpointKey]).replace(/[^0-9]/g, ''),
  );

  type Key = `is${Capitalize<K>}`;
  type NotKey = `isNot${Capitalize<K>}`;
  type KeyBelow = `isBelow${Capitalize<K>}`;

  return {
    [breakpointKey]: breakpointWidth,
    [`is${capitalizedKey}`]: isMatch,
    [`isNot${capitalizedKey}`]: !isMatch,
    [`isBelow${capitalizedKey}`]: window.innerWidth < breakpointWidth,
  } as Record<K, number> & Record<Key | NotKey | KeyBelow, boolean>;
}
