import {CancellationValues, StripePlanNames, StripePlanTypes} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const cancelSubscription =
  createAction<CancellationValues>('CancelSubscription');
export const cancelSubscriptionSuccess = createAction(
  'CancelSubscriptionSuccess',
);
export const cancelSubscriptionFailure = createAction(
  'CancelSubscriptionFailure',
);

export const annualPlanInterest = createAction<{
  paymentPlan: StripePlanTypes | StripePlanNames;
}>('annualPlanInterest');
export const annualPlanInterestSuccess = createAction(
  'annualPlanInterestSuccess',
);
export const annualPlanInterestFailure = createAction(
  'annualPlanInterestFailure',
);

export const pauseSubscription = createAction('PauseSubscription');
export const pauseSubscriptionSuccess = createAction(
  'PauseSubscriptionSuccess',
);
export const pauseSubscriptionFailure = createAction(
  'PauseSubscriptionFailure',
);

export const setManageSubscriptionState = createAction<{
  subscriptionState: 'upgrade' | 'pause' | 'cancel' | 'confirmCancel';
  data?: number | string | CancellationValues;
}>('setManageSubscriptionState');
export const resetManageSubscriptionState = createAction<{
  subscriptionState: 'none';
}>('ResetManageSubscriptionState');
