import React from 'react';

import {SvgIcon} from './SvgIcon';

export const CreditCardIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'none',
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`stroke-current ${strokeColor}`}
  >
    <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
    <path
      fillRule="evenodd"
      d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z"
      clipRule="evenodd"
    />
  </svg>
);

export const AccountIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M344,144c-3.92,52.87-44,96-88,96s-84.15-43.12-88-96c-4-55,35-96,88-96S348,90,344,144Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256,304c-87,0-175.3,48-191.64,138.6C62.39,453.52,68.57,464,80,464H432c11.44,0,17.62-10.48,15.65-21.4C431.3,352,343,304,256,304Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M256,104v56h56a56,56,0,1,0-56-56Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256,104c0,15.46,0,56,0,56H200a56,56,0,1,1,56-56Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="64"
      y="160"
      width="384"
      height="112"
      rx="32"
      ry="32"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M416,272V416a48,48,0,0,1-48,48H144a48,48,0,0,1-48-48V272"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="256"
      y1="160"
      x2="256"
      y2="464"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TAndCIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      d="M416,221.25V416a48,48,0,0,1-48,48H144a48,48,0,0,1-48-48V96a48,48,0,0,1,48-48h98.75a32,32,0,0,1,22.62,9.37L406.63,198.63A32,32,0,0,1,416,221.25Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256,56V176a32,32,0,0,0,32,32H408"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="176"
      y1="288"
      x2="336"
      y2="288"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="176"
      y1="368"
      x2="336"
      y2="368"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HomeIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-[#747474]',
  strokeWidth = 1.5,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      d="M0.75 9.9225C0.75 8.9352 1.19879 8.00144 1.96974 7.38468L7.96974 2.58468C9.1567 1.63511 10.8433 1.63511 12.0303 2.58468L18.0303 7.38468C18.8012 8.00143 19.25 8.9352 19.25 9.9225V16C19.25 17.7949 17.7949 19.25 16 19.25H4C2.20507 19.25 0.75 17.7949 0.75 16V9.9225Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProfileIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M258.9,48C141.92,46.42,46.42,141.92,48,258.9,49.56,371.09,140.91,462.44,253.1,464c117,1.6,212.48-93.9,210.88-210.88C462.44,140.91,371.09,49.56,258.9,48ZM385.32,375.25a4,4,0,0,1-6.14-.32,124.27,124.27,0,0,0-32.35-29.59C321.37,329,289.11,320,256,320s-65.37,9-90.83,25.34a124.24,124.24,0,0,0-32.35,29.58,4,4,0,0,1-6.14.32A175.32,175.32,0,0,1,80,259C78.37,161.69,158.22,80.24,255.57,80S432,158.81,432,256A175.32,175.32,0,0,1,385.32,375.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M256,144c-19.72,0-37.55,7.39-50.22,20.82s-19,32-17.57,51.93C191.11,256,221.52,288,256,288s64.83-32,67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39,151.44,275.59,144,256,144Z"
    />
  </svg>
);

export const CommunityIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <title>People Circle</title>
    <path
      strokeWidth={strokeWidth}
      d="M256 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208zm0-384c-97 0-176 79-176 176s79 176 176 176 176-78.95 176-176S353.05 80 256 80z"
    />
    <path
      strokeWidth={strokeWidth}
      d="M323.67 292c-17.4 0-34.21-7.72-47.34-21.73a83.76 83.76 0 01-22-51.32c-1.47-20.7 4.88-39.75 17.88-53.62S303.38 144 323.67 144c20.14 0 38.37 7.62 51.33 21.46s19.47 33 18 53.51a84 84 0 01-22 51.3C357.86 284.28 341.06 292 323.67 292zm55.81-74zM163.82 295.36c-29.76 0-55.93-27.51-58.33-61.33-1.23-17.32 4.15-33.33 15.17-45.08s26.22-18 43.15-18 32.12 6.44 43.07 18.14 16.5 27.82 15.25 45c-2.44 33.77-28.6 61.27-58.31 61.27zM420.37 355.28c-1.59-4.7-5.46-9.71-13.22-14.46-23.46-14.33-52.32-21.91-83.48-21.91-30.57 0-60.23 7.9-83.53 22.25-26.25 16.17-43.89 39.75-51 68.18-1.68 6.69-4.13 19.14-1.51 26.11a192.18 192.18 0 00232.75-80.17zM163.63 401.37c7.07-28.21 22.12-51.73 45.47-70.75a8 8 0 00-2.59-13.77c-12-3.83-25.7-5.88-42.69-5.88-23.82 0-49.11 6.45-68.14 18.17-5.4 3.33-10.7 4.61-14.78 5.75a192.84 192.84 0 0077.78 86.64l1.79-.14a102.82 102.82 0 013.16-20.02z"
    />
  </svg>
);

export const NotificationsIcon: SvgIcon = ({
  width = 17.44,
  height = 20,
  strokeColor = 'text-[#747474]',
  strokeWidth = 2,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="-1 0 22 21"
    fill="none"
    stroke="#747474"
    className={`stroke-current ${strokeColor}`}
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth={strokeWidth}
  >
    <path
      d="M15.84 1.44a7.143 7.143 0 0 0-9.757 2.614L3.401 8.7a1 1 0 0 1-.502.431l-1.995.78a1 1 0 0 0-.136 1.797l13.37 7.72a1 1 0 0 0 1.5-.883l-.036-2.122a1 1 0 0 1 .134-.517l2.718-4.708A7.143 7.143 0 0 0 15.84 1.44ZM8.624 20.368a3.215 3.215 0 0 1-1.465-3.733l5.43 3.135a3.215 3.215 0 0 1-3.965.598Z"
      fill="none"
    />
  </svg>
);

export const DropDownIcon: SvgIcon = ({
  width = 20,
  height = 20,
  fillColor = '#8B9898',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={fillColor}
    height={height}
    width={width}
    className={`stroke-current ${fillColor}`}
  >
    <path
      fillRule="evenodd"
      d="M4.293 6.707a1 1 0 0 1 1.414 0L10 11.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414z"
    />
  </svg>
);
export const TimeIcon: SvgIcon = ({
  width = 16,
  height = 16,
  strokeColor = '#8B9898',
  fillColor = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 16 16"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M8 14C6.81331 14 5.65328 13.6481 4.66658 12.9888C3.67989 12.3295 2.91085 11.3925 2.45673 10.2961C2.0026 9.19975 1.88378 7.99335 2.11529 6.82946C2.3468 5.66557 2.91825 4.59648 3.75736 3.75736C4.59648 2.91825 5.66557 2.3468 6.82946 2.11529C7.99335 1.88378 9.19975 2.0026 10.2961 2.45673C11.3925 2.91085 12.3295 3.67989 12.9888 4.66658C13.6481 5.65328 14 6.81331 14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14ZM8 3C7.0111 3 6.0444 3.29325 5.22215 3.84265C4.39991 4.39206 3.75904 5.17296 3.3806 6.08659C3.00217 7.00022 2.90315 8.00555 3.09608 8.97545C3.289 9.94536 3.76521 10.8363 4.46447 11.5355C5.16373 12.2348 6.05465 12.711 7.02455 12.9039C7.99446 13.0969 8.99979 12.9978 9.91342 12.6194C10.8271 12.241 11.6079 11.6001 12.1574 10.7779C12.7068 9.95561 13 8.98891 13 8C13 6.67392 12.4732 5.40215 11.5355 4.46447C10.5979 3.52679 9.32608 3 8 3Z"
      fill={strokeColor}
    />
    <path
      d="M10 8.49984H8C7.86793 8.49811 7.74175 8.44488 7.64836 8.35148C7.55496 8.25808 7.50173 8.13191 7.5 7.99984V4.6665C7.5 4.5339 7.55268 4.40672 7.64645 4.31295C7.74022 4.21918 7.86739 4.1665 8 4.1665C8.13261 4.1665 8.25978 4.21918 8.35355 4.31295C8.44732 4.40672 8.5 4.5339 8.5 4.6665V7.49984H10C10.1326 7.49984 10.2598 7.55252 10.3536 7.64628C10.4473 7.74005 10.5 7.86723 10.5 7.99984C10.5 8.13245 10.4473 8.25962 10.3536 8.35339C10.2598 8.44716 10.1326 8.49984 10 8.49984Z"
      fill={strokeColor}
    />
  </svg>
);

export const CalendarRecurringIcon: SvgIcon = ({
  width = 16,
  height = 16,
  strokeColor = '#8B9898',
  fillColor = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    viewBox="0 0 16 16"
    className={`stroke-current ${strokeColor}`}
  >
    <path d="M11.3334 2.83333H10.5V2C10.5 1.86739 10.4474 1.74021 10.3536 1.64645C10.2598 1.55268 10.1326 1.5 10 1.5C9.86743 1.5 9.74026 1.55268 9.64649 1.64645C9.55272 1.74021 9.50004 1.86739 9.50004 2V2.83333H6.50004V2C6.50004 1.86739 6.44736 1.74021 6.35359 1.64645C6.25983 1.55268 6.13265 1.5 6.00004 1.5C5.86743 1.5 5.74026 1.55268 5.64649 1.64645C5.55272 1.74021 5.50004 1.86739 5.50004 2V2.83333H4.66671C4.18048 2.83333 3.71416 3.02649 3.37034 3.3703C3.02653 3.71412 2.83337 4.18044 2.83337 4.66667V12C2.83337 12.4862 3.02653 12.9525 3.37034 13.2964C3.71416 13.6402 4.18048 13.8333 4.66671 13.8333H11.3334C11.8196 13.8333 12.2859 13.6402 12.6297 13.2964C12.9736 12.9525 13.1667 12.4862 13.1667 12V4.66667C13.1667 4.18044 12.9736 3.71412 12.6297 3.3703C12.2859 3.02649 11.8196 2.83333 11.3334 2.83333ZM4.66671 3.83333H5.50004V4.66667C5.50004 4.79927 5.55272 4.92645 5.64649 5.02022C5.74026 5.11399 5.86743 5.16667 6.00004 5.16667C6.13265 5.16667 6.25983 5.11399 6.35359 5.02022C6.44736 4.92645 6.50004 4.79927 6.50004 4.66667V3.83333H9.50004V4.66667C9.50004 4.79927 9.55272 4.92645 9.64649 5.02022C9.74026 5.11399 9.86743 5.16667 10 5.16667C10.1326 5.16667 10.2598 5.11399 10.3536 5.02022C10.4474 4.92645 10.5 4.79927 10.5 4.66667V3.83333H11.3334C11.5544 3.83333 11.7663 3.92113 11.9226 4.07741C12.0789 4.23369 12.1667 4.44565 12.1667 4.66667V6.5H3.83337V4.66667C3.83337 4.44565 3.92117 4.23369 4.07745 4.07741C4.23373 3.92113 4.44569 3.83333 4.66671 3.83333ZM11.3334 12.8333H4.66671C4.44569 12.8333 4.23373 12.7455 4.07745 12.5893C3.92117 12.433 3.83337 12.221 3.83337 12V7.5H12.1667V12C12.1667 12.221 12.0789 12.433 11.9226 12.5893C11.7663 12.7455 11.5544 12.8333 11.3334 12.8333Z" />
  </svg>
);

export const LogoutIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor}`}
  >
    <path
      d="M320,176V136a40,40,0,0,0-40-40H88a40,40,0,0,0-40,40V376a40,40,0,0,0,40,40H280a40,40,0,0,0,40-40V336"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <polyline
      points="384 176 464 256 384 336"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="191"
      y1="256"
      x2="464"
      y2="256"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CalendarIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-[#747474]',
  strokeWidth = 1.5,
  fillColor = 'none',
}) => (
  <svg
    id="Isolation_Mode"
    data-name="Isolation Mode"
    width={width}
    height={height}
    className={`stroke-current ${strokeColor}`}
    strokeWidth={strokeWidth}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 20 18"
  >
    <path
      strokeWidth={strokeWidth}
      stroke={strokeColor}
      d="M.75,4C.75,2.21,2.21,.75,4,.75h12c1.79,0,3.25,1.46,3.25,3.25V14c0,1.79-1.46,3.25-3.25,3.25H4c-1.79,0-3.25-1.46-3.25-3.25V4Z"
    />
    <path
      strokeWidth={strokeWidth}
      stroke={strokeColor}
      d="M4,3.5h12v1.5H4v-1.5Z"
    />
  </svg>
);

export const CalendarOutlineIcon: SvgIcon = ({
  width = 15,
  height = 15,
  strokeColor = 'text-black',
  strokeWidth = 0.3,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z"
    />
  </svg>
);

export const EditAvatarIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      d="M350.54 148.68l-26.62-42.06C318.31 100.08 310.62 96 302 96h-92c-8.62 0-16.31 4.08-21.92 10.62l-26.62 42.06C155.85 155.23 148.62 160 140 160H80a32 32 0 00-32 32v192a32 32 0 0032 32h352a32 32 0 0032-32V192a32 32 0 00-32-32h-59c-8.65 0-16.85-4.77-22.46-11.32z"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="256"
      cy="272"
      r="80"
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
    />
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M124 158v-22h-24v22"
    />
  </svg>
);

export const PeerFeedbackIcon: SvgIcon = ({
  width = 86,
  height = 92,
  strokeColor = 'text-blue-600',
  strokeWidth = 0.5,
  fillColor = '#2E62EC',
  classes = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m255.999 248.226c-10.328-10.18-23.829-15.786-38.016-15.786-18.143 0-33.239 11.895-42.043 20.862-8.62-9.016-23.526-20.862-42.34-20.862-14.186 0-27.686 5.606-38.014 15.786-10.141 9.996-16.144 23.311-16.903 37.491-.968 18.152 6.065 31.094 11.44 39.25 13.054 19.808 73.404 59.978 80.243 64.492 1.671 1.104 3.59 1.655 5.51 1.655s3.839-.552 5.51-1.654c6.814-4.499 66.95-44.529 80.086-64.504 5.372-8.172 12.4-21.132 11.43-39.24-.759-14.181-6.762-27.494-16.903-37.49zm-11.238 65.743c-7.605 11.564-44.152 38.431-68.885 55.121-24.827-16.748-61.5-43.668-69.053-55.129-6.236-9.463-8.679-17.591-8.168-27.177 1.014-18.938 16.69-34.345 34.945-34.345 18.931 0 33.734 21.693 33.865 21.889 1.826 2.751 4.894 4.424 8.196 4.469 3.276.061 6.413-1.544 8.314-4.244.156-.222 16.062-22.113 34.008-22.113 18.257 0 33.934 15.407 34.948 34.346.512 9.561-1.931 17.691-8.17 27.183z"
    />
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m506.138 171.936c-12.022-45.648-41.06-83.83-81.763-107.511-40.523-23.577-87.783-29.942-133.065-17.925-41.701 11.066-77.499 36.795-101.311 72.69-35.458-2.96-70.926 4.974-102.346 23.255-40.703 23.682-69.74 61.863-81.763 107.511-6.31 23.959-7.557 48.42-3.705 72.705 3.426 21.6 10.711 42.094 21.674 60.996l-20.306 40.85c-1.665 3.349-1.322 7.346.888 10.361 2.21 3.017 5.918 4.551 9.612 3.97l49.815-7.793c19.609 16.29 42.126 27.882 67 34.482 14.961 3.971 30.133 5.934 45.211 5.934 30.56 0 60.72-8.071 87.856-23.859 23.711-13.795 43.463-32.512 58.199-54.757 4.674.378 9.351.566 14.024.566 15.12 0 30.199-1.974 45.005-5.903 24.874-6.6 47.391-18.192 67-34.481l49.813 7.793c3.695.577 7.402-.953 9.612-3.97 2.21-3.016 2.553-7.013.889-10.361l-20.307-40.851c10.964-18.902 18.249-39.396 21.675-60.996 3.851-24.285 2.604-48.747-3.707-72.706zm-252.262 258.38c-35.9 20.887-77.764 26.525-117.879 15.882-23.404-6.211-44.447-17.401-62.545-33.262-1.836-1.609-4.183-2.48-6.59-2.48-.514 0-1.031.04-1.546.12l-35.245 5.514 14.135-28.436c1.544-3.106 1.368-6.791-.465-9.735-23.051-37.034-29.625-80.67-18.511-122.869 10.66-40.475 36.4-74.326 72.48-95.317 35.899-20.888 77.764-26.529 117.879-15.883 83.033 22.035 132.724 107.784 110.768 191.149-10.66 40.475-36.401 74.325-72.481 95.317zm214.412-130.416c-1.833 2.944-2.01 6.629-.466 9.735l14.135 28.435-35.243-5.514c-2.933-.456-5.908.406-8.137 2.359-18.098 15.859-39.141 27.05-62.546 33.262-14.058 3.729-28.387 5.473-42.736 5.207 5.177-10.536 9.341-21.672 12.401-33.293 24.76-94.009-31.305-190.714-124.978-215.572-2.844-.755-5.696-1.437-8.553-2.047 20.935-27.752 50.41-47.655 84.275-56.642 40.111-10.644 81.977-5.005 117.877 15.882 36.08 20.991 61.821 54.842 72.482 95.317 11.114 42.201 4.54 85.836-18.511 122.871z"
    />
  </svg>
);

export const WellniteWandIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes = '',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fillColor}
    className={`stroke-current ${strokeColor} ${classes}`}
    viewBox="0 0 576 512"
  >
    <path
      strokeWidth={strokeWidth}
      d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"
    />
  </svg>
);

export const MarketingIcon: SvgIcon = ({
  width = 18,
  height = 18,
  strokeColor = '',
  classes = 'fill-current',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={strokeColor}
    className={classes}
    viewBox="0 0 18 18"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.90617 7.45714L5 2.05714L8.09383 7.45714H1.90617ZM4.11605 0.514286C4.50892 -0.171429 5.49108 -0.171429 5.88395 0.514286L9.86173 7.45714C10.2546 8.14286 9.76351 9 8.97778 9H1.02222C0.236487 9 -0.254597 8.14286 0.13827 7.45714L4.11605 0.514286ZM16.5 13.5C16.5 15.1569 15.1569 16.5 13.5 16.5C11.8431 16.5 10.5 15.1569 10.5 13.5C10.5 11.8431 11.8431 10.5 13.5 10.5C15.1569 10.5 16.5 11.8431 16.5 13.5ZM18 13.5C18 15.9853 15.9853 18 13.5 18C11.0147 18 9 15.9853 9 13.5C9 11.0147 11.0147 9 13.5 9C15.9853 9 18 11.0147 18 13.5Z"
    />
  </svg>
);
