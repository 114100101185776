import React, {lazy, Suspense, useRef} from 'react';
import PlaceholderImage from 'assets/images/silhouette.jpg';
import {
  Button,
  CalendarRecurringIcon,
  FormTextArea,
  LoadingSpinner,
  TimeIcon,
} from 'components';
import {selectUserNotification, selectUserProfile} from 'features/User';
import {useRecurringBooking} from 'hooks';
import {OptionType} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Select, {StylesConfig} from 'react-select';
import {componentLoader, isMember, isProvider} from 'utils';

import ChooseClient from './ChooseClient';
import RecurringBookingChargeModal from './RecurringBookingChargeModal';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);
export const daysOptions = [
  {value: 'M', label: 'Monday'},
  {value: 'T', label: 'Tuesday'},
  {value: 'W', label: 'Wednesday'},
  {value: 'Th', label: 'Thursday'},
  {value: 'F', label: 'Friday'},
  {value: 'S', label: 'Saturday'},
  {value: 'Su', label: 'Sunday'},
];
export const durationOptions = [
  {value: '30', label: '30 mins'},
  {value: '45', label: '45 mins'},
  {value: '60', label: '60 mins'},
];

export const recurrenceOptions = [
  // {value: '1', label: '1 Week'},
  {value: '2', label: '2 Weeks'},
  {value: '3', label: '3 Weeks'},
  {value: '4', label: '4 Weeks'},
  {value: '5', label: '5 Weeks'},
  {value: '6', label: '6 Weeks'},
  {value: '7', label: '7 Weeks'},
  {value: '8', label: '8 Weeks'},
  {value: '9', label: '9 Weeks'},
  {value: '10', label: '10 Weeks'},
  {value: '11', label: '11 Weeks'},
  {value: '12', label: '12 Weeks'},
];

interface CustomStyles {
  control: StylesConfig['control'];
  container: StylesConfig['container'];
  menuPortal: StylesConfig['menuPortal'];
}

export const RecurringBookingCard = () => {
  const btnRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector(selectUserProfile);

  const scrollToElement = () => {
    const {current} = btnRef;
    if (current !== null) {
      current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const customStyles: CustomStyles = {
    control: provided => ({
      ...provided,
      border: 'none',
      fontSize: '0.75rem',
    }),
    container: provided => ({
      ...provided,
      maxWidth: '7rem',
      color: '#3D3D43',
    }),
    menuPortal: provided => ({
      ...provided,
      fontSize: '0.75rem',
      color: '#3D3D43',
      zIndex: 9999,
    }),
  };
  const {
    duration,
    setDuration,
    recurrence,
    setRecurrence,
    selectedDay,
    setSelectedDay,
    selectedTime,
    setSelectedTime,
    timeSlots,
    isLoading,
    availabilityRequestResolved,
    computeEndDate,
    bookingCharge,
    closeExtraChargeModal,
    onConfirmBooking,
    checkbookingChargeAndbookAppointment,
    isLoadingBooking,
    memberEmail,
    member,
    setNoteMessage,

    config,
    onSearch,
    isSearchMembersLoading,
    clientOptions,
    handleInputChange,
    getValue,
    toggleOn,
    onToggleClick,
    isMemberDetailsLoading,
    memberPresentInState,
  } = useRecurringBooking();

  const history = useHistory();

  const {t} = useTranslation();

  const {message, messageType, navigateTo} = useSelector(
    selectUserNotification,
  );
  const handleTimeSlotClick = (timeOption: string) => {
    setSelectedTime(timeOption);
    const timeoutId = setTimeout(() => {
      scrollToElement();
    }, 0);

    return () => clearTimeout(timeoutId);
  };

  const day = daysOptions.find(day => day.value === selectedDay)?.label;
  const modalProps = {
    message,
    messageType,
    isOpen: true,
    ...(navigateTo && {
      buttonFn: () => {
        history.push(navigateTo);
      },
    }),
  };
  return (
    <>
      {messageType === 'none' || !isProvider(user) ? null : (
        <Suspense fallback={<div />}>
          <Modal {...modalProps} />
        </Suspense>
      )}
      {bookingCharge.showModal ? (
        <RecurringBookingChargeModal
          bookingCharge={bookingCharge}
          onConfirmBooking={onConfirmBooking}
          closeExtraChargeModal={closeExtraChargeModal}
          role={user?.role}
        />
      ) : null}
      <section className="m-4 sm:shadow-xl sm:border  font-inter p-4 max-w-lg mx-auto h-[80%] sm:h-[75%] overflow-y-auto relative">
        <p className="pb-2 font-semibold">
          {t('recurringBooking.setRecurringSession')}
        </p>
        <div className="flex flex-col justify-center text-xs gap-y-2 divid  divide-gray-400">
          <div className=" w-full  border-gray-400 flex flex-col md:px-2 relative">
            {isMember(user) ? (
              <div className="text-left">
                <img
                  className="w-10 h-10 border rounded-full object-cover"
                  src={user?.therapistDetails?.image || PlaceholderImage}
                  alt="Avatar"
                />
                <p className="pt-1.5">{user?.therapistDetails?.fullName}</p>
              </div>
            ) : null}

            {isProvider(user) && memberPresentInState ? (
              <p className="pt-1.5">{member?.fullName}</p>
            ) : null}

            {isProvider(user) && !memberPresentInState ? (
              <ChooseClient
                onSearch={onSearch}
                onToggleClick={onToggleClick}
                config={config}
                isMemberDetailsLoading={isMemberDetailsLoading}
                clientOptions={clientOptions}
                getValue={getValue}
                handleInputChange={handleInputChange}
                inputValue={memberEmail}
                isSearchMembersLoading={isSearchMembersLoading}
                toggleOn={toggleOn}
              />
            ) : null}

            {/* Duration */}
            <div className="text-left mt-4">
              <p className="text-xs text-gray-500">
                {t('recurringBooking.selectDuration')}
              </p>
              <Select
                options={durationOptions}
                defaultValue={duration}
                value={duration}
                onChange={selectedOption =>
                  setDuration(selectedOption as OptionType)
                }
                styles={customStyles as StylesConfig}
              />
            </div>
          </div>

          {/* Middle Section */}
          <div className="border-gray-400 flex flex-col gap-7  text-gray-500 my-2 md:px-2 pt-5">
            <div>
              {/* Recurrence Weeks  */}
              <p>{t('recurringBooking.repeatWeeklyFor')} </p>
              <Select
                options={recurrenceOptions}
                value={recurrence}
                onChange={selectedOption =>
                  setRecurrence(selectedOption as OptionType)
                }
                styles={customStyles as StylesConfig}
                menuPortalTarget={document.body}
              />
            </div>
            <div>
              {/* Day Section */}
              <p>{t('recurringBooking.pickADay')}</p>
              <div className="flex gap-1 mt-2">
                {daysOptions.map(day => (
                  <Button
                    key={day.value}
                    btnType="custom"
                    bgColor={
                      selectedDay === day.value ? 'blue-600' : 'gray-200'
                    }
                    textColor={selectedDay === day.value ? 'white' : 'gray-700'}
                    borderRadius="full"
                    disabled={isProvider(user) && !memberEmail}
                    tooltipContent={
                      isProvider(user) && !memberEmail
                        ? t('recurringBooking.chooseClient')
                        : ''
                    }
                    className="text-xs w-7 h-7 flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setSelectedTime('');
                      setSelectedDay(day.value);
                    }}
                  >
                    {day.value}
                  </Button>
                ))}
              </div>
            </div>
            {/* Available Slots */}
            <div className="w-full ">
              {isLoading ? (
                <div className="flex w-full justify-center">
                  <LoadingSpinner type="ThreeDots" />
                </div>
              ) : (
                <>
                  {Array.isArray(timeSlots) && timeSlots.length > 0 ? (
                    <>
                      <p>{t('availableSlots')}</p>

                      <div className="grid grid-cols-3 sm:grid-cols-4 gap-2 mt-2 max-h-24 w-full  overflow-y-auto">
                        {timeSlots?.map(timeOption => (
                          <Button
                            key={timeOption}
                            btnType="custom"
                            textColor={
                              selectedTime === timeOption ? 'white' : 'gray-700'
                            }
                            borderRadius="full"
                            borderColor="gray-300"
                            bgColor={
                              selectedTime === timeOption ? 'blue-600' : 'white'
                            }
                            className="flex text-xs items-center justify-center px-3 py-1 cursor-pointer max-w-full w-full"
                            onClick={() => handleTimeSlotClick(timeOption)}
                          >
                            {timeOption}
                          </Button>
                        ))}
                      </div>
                    </>
                  ) : selectedDay && availabilityRequestResolved ? (
                    <p className="pt-3 text-center text-red-600">
                      {t('recurringBooking.sorryNoSlots')}
                    </p>
                  ) : null}
                </>
              )}
            </div>
          </div>

          {selectedTime &&
          Array.isArray(timeSlots) &&
          timeSlots.length > 0 &&
          availabilityRequestResolved ? (
            <div className="flex flex-col gap-5 font-inter border-gray-500 my-2 py-4 px-1 md:px-2 pt-5">
              <section className="flex flex-col gap-3">
                <p>{t('confirmYourBooking')}</p>
                <section className="flex flex-col gap-2">
                  <div className="flex gap-1 text-gray-500">
                    <CalendarRecurringIcon />
                    <p>
                      {t('recurringBookingTimes', {
                        selectedTime,
                        day,
                        computedEndDateValue: computeEndDate(),
                      })}
                    </p>
                  </div>
                  <div className="flex text-gray-500 items-center gap-x-1">
                    <TimeIcon />
                    <p>{duration.label}</p>
                  </div>
                </section>
              </section>
              {isMember(user) ? (
                <>
                  <p className=" font-medium font-inter">
                    {t('addNoteToTherapist')}
                  </p>

                  <FormTextArea
                    id="noteToTherapist"
                    name="noteToTherapist"
                    placeholder=""
                    bgColor="white"
                    className="focus:border-primary font-inter  text-sm leading-4 rounded pl-3"
                    rows={3}
                    onChange={(
                      event: React.ChangeEvent<HTMLTextAreaElement>,
                    ) => {
                      setNoteMessage(event.target.value);
                    }}
                  />
                </>
              ) : null}

              <div className=" mx-auto w-max " ref={btnRef}>
                <Button
                  onClick={checkbookingChargeAndbookAppointment}
                  disabled={
                    isLoadingBooking || (isProvider(user) && !memberEmail)
                  }
                  isSubmitting={isLoadingBooking}
                  tooltipContent={
                    isProvider(user) && !memberEmail
                      ? t('chooseClientFirst')
                      : ''
                  }
                  className="w-20 h-7 px-3.5 py-2.5 bg-blue-600 rounded-2xl flex flex-col justify-center text-center items-center gap-1  text-xs"
                >
                  {t('recurringBooking.schedule')}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default RecurringBookingCard;
