import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  ignoreElements,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {DigitalPracticeService} from 'services/api/DigitalPractice';

import {digitalPracticeActions} from './../digitalPracticeSlice';

const sendDigitalPracticeLinkToContactsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(digitalPracticeActions.sendDigitalPracticeLinkToContacts.match),
    switchMap(({payload: {onSuccess, onError, ...rest}}) =>
      from(
        DigitalPracticeService.sendDigitalPracticeLinkToContacts(rest.data),
      ).pipe(
        concatMap(() => [onSuccess?.()]),
        mergeMap(() => [
          digitalPracticeActions.sendDigitalPracticeLinkToContactsSuccess(),
        ]),
        catchError((message: string) =>
          concat(
            of(
              digitalPracticeActions.sendDigitalPracticeLinkToContactsFailure(
                message,
              ),
            ),
          ).pipe(tap(() => onError?.(message))),
        ),
      ),
    ),
  );

const sendDigitalPracticeLinkToContactsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(
      digitalPracticeActions.sendDigitalPracticeLinkToContactsFailure.match,
    ),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

export const digitalPracticeLinkEpics = [
  sendDigitalPracticeLinkToContactsEpic,
  sendDigitalPracticeLinkToContactsFailureEpic,
];
