import React from 'react';
import styled from 'styled-components';

import {Svg} from './Svg';

const StyledSvg = styled(Svg)`
  min-height: 25px;
  min-width: 20px;
  max-width: 20px;
  position: relative;
  right: -10px;
  top: 7px;
`;

export const ChevronDown = () => (
  <StyledSvg viewBox="0 0 20 20">
    <path d="M6 5L11.5 0H0.5L6 5Z" fill="currentColor" fillRule="evenodd" />
  </StyledSvg>
);
