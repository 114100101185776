import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  selectEmployers,
  selectSearchedEmployers,
  userActions,
} from 'features/User';
import {OptionType, SliceStatus} from 'interfaces';
import debounce from 'lodash.debounce';
import {useDispatch, useSelector} from 'react-redux';

const EMPLOYERS_LIMIT = 5;

export const useDigitalPracticeEmployers = (
  employerValue: string,
): {
  employersInfiniteScrollConfig: any;
  employers: OptionType[];

  status: SliceStatus;
  hasMore: boolean;
  onSearchEmployers: (value: string) => void;
  setSelectedEmployerInsurance: Dispatch<SetStateAction<string | undefined>>;
  selectedEmployerInsurance?: string;
  searchedEmployers: {
    data: OptionType[];
    offset?: string;
    hasMore: boolean;
    status: SliceStatus;
  };
} => {
  const [selectedEmployerInsurance, setSelectedEmployerInsurance] =
    useState<string>();

  const dispatch = useDispatch();
  const employers = useSelector(selectEmployers);
  const searchedEmployers = useSelector(selectSearchedEmployers);

  const {status, hasMore, data, offset} = searchedEmployers.data.length
    ? searchedEmployers
    : employers;

  const fetchEmployers = useCallback(
    (size = EMPLOYERS_LIMIT) => {
      if (employerValue && employerValue.length > 2) {
        dispatch(
          userActions.searchEmployers({
            size,
            offset: offset,
            searchKeyword: employerValue,
          }),
        );
      } else {
        dispatch(userActions.getEmployers({size, offset: offset}));
      }
    },
    [employerValue, offset],
  );

  const employersInfiniteScrollConfig = {
    dataLength: data.length,
    hasMore: hasMore,
    next: fetchEmployers,
  };

  const onSearchEmployers = useCallback(
    debounce((value: string) => {
      dispatch(userActions.resetSearchEmployers());

      if (value === '') {
        dispatch(userActions.resetSearchEmployers());

        dispatch(userActions.getEmployers({size: EMPLOYERS_LIMIT, offset: ''}));
      } else if (value.length > 2) {
        dispatch(
          userActions.searchEmployers({
            size: EMPLOYERS_LIMIT,
            offset: offset,
            searchKeyword: value,
          }),
        );
      }
      return;
    }, 1000),
    [],
  );
  useEffect(() => {
    fetchEmployers();

    return () => {
      dispatch(userActions.resetSearchEmployers());
    };
  }, []);

  return {
    employersInfiniteScrollConfig,
    employers: data,
    status,
    hasMore,
    onSearchEmployers,
    setSelectedEmployerInsurance,
    selectedEmployerInsurance,
    searchedEmployers,
  };
};
