import React, {FC, lazy, Suspense} from 'react';
import classnames from 'classnames';
import {selectUnreadMessagesCount} from 'features/Messaging';
import {isProviderProfilePage} from 'features/Provider/Profile/utils';
import {selectUserProfile} from 'features/User';
import {useCalendar, useRouterPush, useUserNotifications} from 'hooks';
import {CurrentUser, UserRoles} from 'interfaces';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {CSSTransition} from 'react-transition-group';
import styled from 'styled-components';
import {componentLoader, isUserFromUnitedStates} from 'utils';

import {filterNavMenuItems, NavMenuItem} from './navigationConstants';

import 'styles/Animations.css';

const Count = styled.div`
  padding-top: 1.5px;
  font-size: 0.5rem;
`;

const MobileTabletCalendar = lazy(() =>
  componentLoader(() => import('features/Appointment/Calendar/MobileCalendar')),
);

export const MobileTabletNavigationV2: FC = () => {
  const {isCalendarOpen, closeCalendar} = useCalendar();
  const location = useLocation();
  const {pathname} = location;
  const user = useSelector(selectUserProfile);
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount);
  const {unreadNotifsLength} = useUserNotifications();

  const {onRoutePush} = useRouterPush();
  const handleRouteClick = (url: string) => {
    closeCalendar();
    onRoutePush(url);
  };
  const userRole = user?.role;
  const isUserFromUS = isUserFromUnitedStates(user);
  const filteredNavMenuItems = filterNavMenuItems(userRole!, isUserFromUS);

  return (
    <>
      <CSSTransition
        classNames="zoomIn"
        appear={true}
        unmountOnExit
        in={isCalendarOpen}
        timeout={1000}
      >
        <Suspense fallback={<div />}>
          <MobileTabletCalendar closeCalendar={closeCalendar} />
        </Suspense>
      </CSSTransition>

      <footer className="fixed h-20 z-50 inset-x-0 bottom-0 bg-white lg:hidden border-t p-2">
        <nav
          className={classnames(
            'flex flex-row flex-no-wrap justify-around md:px-48 items-center h-16',
          )}
        >
          {userRole &&
            filteredNavMenuItems?.map(
              ({
                route,
                icon: Icon,
                name,
                hideOnMobile,
                iconClasses,
              }: NavMenuItem) => (
                <button
                  key={route}
                  aria-label={name}
                  onClick={() => {
                    handleRouteClick(route);
                  }}
                  className={classnames(
                    'group flex items-center justify-center relative w-12 h-12 rounded-full',
                    {
                      'bg-blue-600': !isCalendarOpen && pathname === route,
                      'hidden lg:flex': hideOnMobile,
                    },
                  )}
                >
                  <Icon
                    strokeColor={
                      !isCalendarOpen && pathname === route
                        ? 'text-white'
                        : undefined
                    }
                    classes={iconClasses}
                  />
                  {route === '/message' && unreadMessagesCount ? (
                    <div className="absolute -top-1 -right-1 block h-3 w-3 rounded-full text-white shadow-solid bg-blue-500 ">
                      <Count>{unreadMessagesCount}</Count>
                    </div>
                  ) : null}

                  {route === '/notifications' && unreadNotifsLength ? (
                    <span
                      className={classnames(
                        'absolute top-2 right-1 inline-flex items-center rounded-full px-1 h-4 text-[10px] font-semibold text-blue-600 ring-1 ring-inset ring-blue-600/50',
                        {
                          hidden: pathname === route,
                          'bg-blue-600/[15%]': pathname !== route,
                        },
                      )}
                    >
                      {unreadNotifsLength}
                    </span>
                  ) : null}
                </button>
              ),
            )}

          {userRole == UserRoles.member ? (
            <BottomNavigationAvatarLink
              onClick={() => handleRouteClick('/account')}
              user={user}
              isActive={
                !isCalendarOpen &&
                [
                  '/account',
                  '/profile',
                  '/membership',
                  '/terms-and-conditions',
                  '/payment',
                ].includes(pathname)
              }
            />
          ) : null}
          {[UserRoles.therapist, UserRoles.prescriber].includes(
            (userRole ?? '') as unknown as UserRoles,
          ) ? (
            <BottomNavigationAvatarLink
              onClick={() => handleRouteClick('/provider/profile')}
              user={user}
              isActive={!isCalendarOpen && isProviderProfilePage(pathname)}
            />
          ) : null}
        </nav>
      </footer>
    </>
  );
};

export interface BottomNavigationAvatarLinkProps {
  onClick: VoidFunction;
  isActive: boolean;
  user: CurrentUser;
}

export function BottomNavigationAvatarLink(
  props: BottomNavigationAvatarLinkProps,
): React.ReactElement {
  return (
    <button
      onClick={props.onClick}
      className={classnames(
        'group flex items-center justify-center relative w-12 h-12 rounded-full text-gray-700',
        {
          'text-white bg-blue-600': props.isActive,
        },
      )}
    >
      <span className="inline-block relative">
        <img
          src={props.user?.image}
          className="w-6 h-6 bg-gray-300 rounded-full object-cover"
          alt="profile-photo"
        />
      </span>
    </button>
  );
}
