import {useEffect, useState} from 'react';
import {selectUserProfile} from 'features/User';
import {getWherebyRoomUrl} from 'features/Video/WherebyVideo/wherebyActions';
import {UserRoles, WherebyRoomModeTypes} from 'interfaces';
import {useSelector} from 'react-redux';

export const useWhereby = (
  role: UserRoles | null,
  id: string | null,
  appointmentTime: string | null,
  appointmentID: string,
  roomMode: WherebyRoomModeTypes = 'normal',
): {
  videoRoomUrl: string;
  error: string;
} => {
  const [videoRoomUrl, setVideoRoomUrl] = useState('');
  const user = useSelector(selectUserProfile);
  const [error, setError] = useState('');

  const getRoomUrl = async () => {
    if (role && id && appointmentTime) {
      const videoCredentials = await getWherebyRoomUrl(
        {role, id},
        user!.role,
        appointmentTime,
        appointmentID,
        roomMode,
      );
      setVideoRoomUrl(videoCredentials?.roomUrl ?? '');
    } else {
      setError('Error loading Video credentials');
    }
  };

  useEffect(() => {
    getRoomUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    videoRoomUrl,
    error,
  };
};
