import {AppActions, RootState} from 'app/rootReducer';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {catchError, filter, mergeMap, switchMap} from 'rxjs/operators';
import {OnboardingService} from 'services/api';

import {appointmentActions} from '../appointmentSlice';

export const submitOnboardingDataEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(appointmentActions.submitOnboardingData.match),
    switchMap(({payload: {data, appointmentData}}) =>
      from(OnboardingService.submitOnboardingData(data)).pipe(
        mergeMap(({data: {message: user}}) => [
          userActions.setUser(user),
          userActions.checkSession(UserRoles.member),
          appointmentActions.submitOnboardingDataSuccess(),
          appointmentData
            ? appointmentActions.bookAppointment(appointmentData)
            : userActions.setNotification({
                message: 'Onboarding completed 🗂',
                messageType: 'success',
              }),
          push({
            pathname: '/dashboard',
          }),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'appointment',
                message,
              }),
              appointmentActions.submitOnboardingDataFailed(),
            ),
          ),
        ),
      ),
    ),
  );

export const appointmentOnboardingEpics = [submitOnboardingDataEpic];
