import React, {Suspense} from 'react';
import Logo from 'assets/images/logo-blue-text.png';
import {LogoSmall} from 'components';
import {LayoutProps} from 'components/Layouts/Layout.types';

import Footer from './ArticleLayoutFooter';

export const links = [
  {text: 'Conditions', href: 'https://www.wellnite.com/conditions'},
  {text: 'Your Practice', href: 'https://www.wellnite.com/your-practice'},
  {text: 'Benefits', href: 'https://www.wellnite.com/benefits'},
  {text: 'Articles', href: 'https://www.wellnite.com/blog'},
];

export function ArticleLayout({children}: LayoutProps): React.ReactElement {
  return (
    <main className="w-full h-full min-w-full min-h-screen">
      <nav className="bg-white w-full border-b flex items-center justify-between h-20 px-6 lg:px-10">
        <div className="flex items-center space-x-8">
          <a href="https://wellnite.com" rel="noopener noreferrer">
            <img
              src={Logo}
              className="hidden lg:inline-block h-6 lg:h-8"
              alt="Wellnite | Accessible High-Quality Mental Healthcare"
            />
            <span className="lg:hidden">
              <LogoSmall />
            </span>
          </a>
          <ul
            className="hidden lg:flex items-center space-x-8 text-base"
            role="list"
          >
            {links.map((link, index) => (
              <li key={`nav-link-${index}`} role="listitem">
                <a
                  rel="noopener noreferrer"
                  href={link.href}
                  className="hover:text-blue-700"
                  role="link"
                  aria-label={link.text}
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center text-black space-x-6">
          <a
            className="hidden lg:inline-block hover:text-blue-500"
            href="https://wellnite.co/login"
            rel="noopener noreferrer"
          >
            Log in
          </a>
          <a
            className="inline-block text-center bg-blue-600 hover:bg-blue-700 text-white rounded-full px-4 py-3"
            href="https://www.wellnite.co/direct-booking/providers"
            rel="noopener noreferrer"
          >
            Get Started
          </a>
        </div>
      </nav>
      <div className="mx-auto w-full max-w-7xl py-10">
        <Suspense>{children}</Suspense>
      </div>
      <Footer />
    </main>
  );
}
