import React from 'react';

import {SvgIcon} from './SvgIcon';

export const ReceiptIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path d="M968.2 918.4c-42-26.5-83.9-53.1-125.9-79.6-5.9-3.7-11.8-7.5-17.7-11.2-14.6-9.2-35.9-9.2-50.5 0-42 26.5-83.9 53.1-125.9 79.6-5.9 3.7-11.8 7.5-17.7 11.2H681c-42-26.5-83.9-53.1-125.9-79.6-5.9-3.7-11.8-7.5-17.7-11.2-14.6-9.2-35.9-9.2-50.5 0-42 26.5-83.9 53.1-125.9 79.6-5.9 3.7-11.8 7.5-17.7 11.2h50.5c-42-26.5-83.9-53.1-125.9-79.6-5.9-3.7-11.8-7.5-17.7-11.2-14.6-9.2-35.9-9.2-50.5 0-42 26.5-83.9 53.1-125.9 79.6-5.9 3.7-11.8 7.5-17.7 11.2 25.1 14.4 50.2 28.8 75.2 43.2V133.3c0-3.5.2-7 .7-10.5-.6 4.4-1.2 8.9-1.8 13.3.9-6.2 2.6-12.2 5-17.9-1.7 4-3.4 8-5 11.9 2.5-5.6 5.6-10.8 9.3-15.7-2.6 3.4-5.2 6.7-7.8 10.1 3.5-4.4 7.5-8.4 11.9-11.9-3.4 2.6-6.7 5.2-10.1 7.8 4.9-3.7 10-6.8 15.7-9.3-4 1.7-8 3.4-11.9 5 5.8-2.4 11.7-4.1 17.9-5-4.4.6-8.9 1.2-13.3 1.8 8.9-1.1 18-.7 26.9-.7H872.8c3.4 0 6.9.2 10.3.7-4.4-.6-8.9-1.2-13.3-1.8 6.2.9 12.2 2.6 17.9 5-4-1.7-8-3.4-11.9-5 5.6 2.5 10.8 5.6 15.7 9.3-3.4-2.6-6.7-5.2-10.1-7.8 4.4 3.5 8.4 7.5 11.9 11.9-2.6-3.4-5.2-6.7-7.8-10.1 3.7 4.9 6.8 10 9.3 15.7-1.7-4-3.4-8-5-11.9 2.4 5.8 4.1 11.7 5 17.9-.6-4.4-1.2-8.9-1.8-13.3 1.2 9.7.7 19.8.7 29.6v775.1c0 10.9-.1 21.8 0 32.7v1.4c0 12.8 5.6 26.3 14.6 35.4 8.7 8.7 22.9 15.2 35.4 14.6 12.9-.6 26.3-4.8 35.4-14.6 9-9.8 14.6-21.8 14.6-35.4V132.7c-.2-27.1-9.2-52.3-25.7-73.6-23.4-30.3-59.1-46.5-97.2-46.5H154c-8.9 0-17.8 1.2-26.6 2.6-32.4 5.1-59 26.2-77 51.9C36.1 87.6 31 113 31 137.7V961.7c0 17.6 9.6 34.3 24.8 43.2 15 8.8 35.5 9.5 50.5 0 42-26.5 83.9-53.1 125.9-79.6 5.9-3.7 11.8-7.5 17.7-11.2h-50.5c42 26.5 83.9 53.1 125.9 79.6 5.9 3.7 11.8 7.5 17.7 11.2 14.6 9.2 35.9 9.2 50.5 0 42-26.5 83.9-53.1 125.9-79.6 5.9-3.7 11.8-7.5 17.7-11.2h-50.5c42 26.5 83.9 53.1 125.9 79.6 5.9 3.7 11.8 7.5 17.7 11.2 14.6 9.2 35.9 9.2 50.5 0 42-26.5 83.9-53.1 125.9-79.6 5.9-3.7 11.8-7.5 17.7-11.2h-50.5c42 26.5 83.9 53.1 125.9 79.6 5.9 3.7 11.8 7.5 17.7 11.2 10.7 6.8 26.4 8.4 38.5 5 11.6-3.2 24.2-12.2 29.9-23 13.4-24.7 6.1-53.5-17.6-68.5z" />
    <path d="M276.2 373.1h413.7c19 0 38.1.2 57.1 0h.8c12.8 0 26.3-5.6 35.4-14.6 8.7-8.7 15.2-22.9 14.6-35.4-.6-12.9-4.8-26.3-14.6-35.4-9.8-9-21.8-14.6-35.4-14.6H334.1c-19 0-38.1-.2-57.1 0h-.8c-12.8 0-26.3 5.6-35.4 14.6-8.7 8.7-15.2 22.9-14.6 35.4.6 12.9 4.8 26.3 14.6 35.4 9.8 8.9 21.8 14.6 35.4 14.6zm471.6 151.6H334.1c-19 0-38.1-.2-57.1 0h-.8c-12.8 0-26.3 5.6-35.4 14.6-8.7 8.7-15.2 22.9-14.6 35.4.6 12.9 4.8 26.3 14.6 35.4 9.8 9 21.8 14.6 35.4 14.6h413.7c19 0 38.1.2 57.1 0h.8c12.8 0 26.3-5.6 35.4-14.6 8.7-8.7 15.2-22.9 14.6-35.4-.6-12.9-4.8-26.3-14.6-35.4-9.8-9-21.8-14.6-35.4-14.6z" />
  </svg>
);
