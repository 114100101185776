import React from 'react';

import {SvgIcon} from './SvgIcon';

export const SuccessIcon: SvgIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="85px"
    height="85px"
    viewBox="0 0 85 85"
    enableBackground="new 0 0 85 85"
    xmlSpace="preserve"
  >
    {' '}
    <image
      id="image0"
      width="85"
      height="85"
      x="0"
      y="0"
      href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFUAAABVCAYAAAA49ahaAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
  AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
  CXBIWXMAAAsTAAALEwEAmpwYAAAXqUlEQVR42t1dXYwcVXb+blX1zPiP7Y1hbdgsGksLrNBiGinJ
  A8Km/bZ+iANSfhZtJNOaJDKK1oaXlawo8lhIUd5gn5AiJTZR8oIiwe5L3uIxdhCKFDHgFVr/4Vkv
  OxnYgNvMeKanu6tuHup+XV+drp4ZYxvv5kql7qmf+/Pdc8/5zjm3ehx+Q0vzymS9g3o96qEeOdT1
  Wj/B3ES73Z55Yq59t/tZVdzd7kDzymS936834LPHHXwDcE041OFRR2R66AFkAOLw3aPtgDkPP+fh
  3geimSRpz87surtg3xVQnzrXaEZj2dM+8k2krjkAjCDGATwXDh8e5Kee05FwNN7PeO9OZz56652H
  Z2e/6vF9ZaA2r0zWs+yeI+jjGd9HA05A5HdbvABogUxRSGwcrmdmVPn9cw6Y7sbd0+/u+vnc/wtQ
  n7rYaDqfPo/IHQTCwPsAagHILHym0ht+qrRG4Tle93JfJPfDnPPSBvzJXtw7fqfBvWOgPnnh0Ubs
  4leQuSZi06Iu5x4KiVMgdDnze2p6bT+9eZ7ftT4HoOdP9sbvHLi3HdTme5P1bNM9x3yEF0uD5BL1
  61RgpZD3q5715n77LMy9uiIU5DskubcV1D0fPvYMnDsBoD40IAVVpZCFKoDXhwBAMTmQvy3AoyZN
  QdX7Isy5CNNv7/rg9d8oUJtXJutpf9sJwD2D1ACioNnlTGDsUlWjpRNjVYQ+zzpScy7CMNDWoFHf
  RrdHam8Z1CcvPNqIo+RNAJOlWvso0yQLbmbOW3AyuS8ecX8q9SnIkdyv0mlBLaSVbc/1XHffrQIb
  3crDey/tPhi75BR8ANQOLDYPUBIz+dTzvuJ8FQh6zjIA/h2jPKGqVpz5uyiTNT92Zc/l3S/eFVD3
  Xt59zAMnQf1ZNTALKOS6qzivg3cY1r0Eo4eCp1rVYduwOtpe0/4Wbb2y9/LuY18pqAHQ6dLTCoBK
  qUqONSSR3FvVEwsqpThFIe0Ktk6EbYf3EVy26029bKqP6b3nvhywN61T917cfcx7TA8NQA0FdZoO
  KMOwbotQ1n8crLXQDrl0ao95j2UVo9iATriqlYFjIOPpA+jm19wYpt9+5IPjN4PRTUnqAFCrA20H
  reGgt6RGis/2UVhs1gM5x3spzfZQiVUwOTLLJPoojChQXlExypPfDxJ7/uYkdsOSuvfy7iMeeLUE
  AAdmpSPDsBTodbts1TkAygNOMawbLROAedbSLn5flfrGpD6CXEMuoVQDadEPBzz/9nc3xmU3BGrz
  yncm097YlcEg2UkbFFHJJfijSLyrOICyVadranW3rdNVtKEqKAtgsSTynbpU67V9yO9rp7X+vnce
  /nB2PbzWXf7NK9+ZTLOxUyVuWeVG6qBVn1ZJqi5f6+PrJ1DWtao/Vb3w6GFYffB7H8A4cglVIxUZ
  kGvhHgZ8CqNbj7vJm833GvVbBjXt1aaRBR6qgPBpaz0zlEFD6LQNzwHVPNVKndZj+S3M8yq1VD/U
  2QRW2+yjrJP1XlUjnKwIk+l4emI9zNZc/nsuPPY8Ineiks/pwKqoTdWUsbOJuQ8Vf6PiOoG17m4/
  DFwnIAbQQaGvNRpWC33oysRRtVmDa41uDMD7Z888cu6tmwY16NFTcOItqWW3Ho26kwROaZYFogrU
  KrUSmet6cKWoM9ALoLFPCQr9yn4kKLuxVa4sUMRvLSuI0I6Xol0zT8y2q7AbufzTbm16AKiCV6VD
  9VM7qVzSWm/r3Wh9lq7xWaViClSCQvrGpR6eZ/3jAjhXjVI1DdrEcq8GZzIAq6hn4+lImlUpqQNr
  v96g9e8qos1Z5qzb5WQDJVW+vvrvlByeWxVgMuQGJkO+7McFMEryBMpRLEh7BDZFOW6QorwqxfNK
  k/4TVWygUlLTXm16CEztQBV4OgEqYVWelD4by3N6KDuw9I0Aq+RaR4F95WRmKKsJNZ68V9kNjZY6
  EqwjPB8jfqUKvyHYBlJq4baSqQNW/Qbp+Kjwm1pqC7p+khMrRetLn2Kpz4YKR2UHFFRKKMG3epyg
  sj4yCNKwCPAu23f2oZ/NKFRDkpp2a9NDITaVTpvhVNAtDRoVJFZA7cCtPu2ikDBKJwfKdhKUJc9O
  PJd8DQX/tGpAObAJrpSkOEGJZbg0JDRHzGFZl1ZF2C0oNhhiAbPhNssnM/NdJaaGwpvSMB/PkZbx
  ukqoCoF6gTGws/YA9mzZBzjgzOIpLGTzhRTqquuF72Mor6g+CklH/ne8Gn1dmYD6Ekh7teYQAa8K
  7lalS7RYa111vzV2EIkkh4SA2wWwBWWJ1dS2Ah6jTPQDoPvvOYAf3vsjbI22AQBaX38Bhz+ewqXu
  +eoAuPZTx9NDbiTjvF99n70IYJqPmOXvjg0t3ao4qTUoQNmIeHOe5/Q+dS07KCy51s2IEgeamT4o
  EFQB1tB182P/lgM4+o2XB4ACwNZoG35434+Kvqil5yrsAFgGcCPURQMmQhB5/JGiOAD1yQuPNuAw
  WUm+Y2nEHtaDor7T2m1sVcFUFzJGwSXJO3k9SEXJp7d9VfdU9N/++gEcvf9lVJWHxh4pCwGBXEWh
  WzWIpKHM0L5P0XjqzHebQ6BGLnlmyIe3fnrVcvfmelWARHmfAj8RQLQ6ixI5Joe16JY/an8E5P3b
  D+Dot6oBBYBLvfPDjovWYyeK/bBsIYkGoA50qoN/Gs5VU6QM1UWtqGUHanXZkjVU1nil8mmCxRhD
  QWdi05ZSIDF+++v5kh9VlrJF/N383xbPAoWBUlXjTb9smNIDLvZPF1gCaF5p1NN+dq3UomWw1uVU
  4NWKo6JRnd3UtEGdqVt6aKg4EVQLVBccrAY+NA7QB/b/zgEc/d01AE0Xcfj8FC51zpdXkHJjlVbq
  cQZuKCgCbJzkLCACgH4fjcFD6m2oZ2IDIRoEtnul1JPROhTIVKQCKJb5RMWkELAERTxU9XofuQ4M
  eaz929cBtL+Iw5emcGnlfKHuwmRImK+sFixPVkMZJqDf6Td4O5BkzSHLrsZAjVUVSbe6hkbIunus
  axzlIAhTHVUWXemR6nt+9uTvLrB/2wEc/eY6gJ6fwqXl88UEsV0awp58T02fKGx0AoiFA/xY1GC3
  4eAfh3dlIq37nyzIVWEzWk2VePVMahheWspfayjr5gmUrW4P1SpGuHNr5yG0vvHCaEDTRRyem8Kl
  1fNlwbB7uNiujT0okDqO0K8oco8PQPXeTTq11JrbsS4lr5PukHooj6Q0AoWV7MpEqb5SNaNuIgeg
  XpONI7DePtB6cB1AVUJpntOKdnXCrMWHXNdY8WBMvjkA1ZGfWj2hlpWV6LJQlREjJ8m8NoEi4aZA
  aJRdHQTqRpZOuL9mJlQZR5C21gOH0Nq5joReDoBq4VhsxAqmzQRlG2GZTsE+6gDgmlca9bSXXSsl
  ueguKn1RIszGuWQZvySA3CldZbDsfn61uBr7VN9d9blOega0th9C6/7RgC6szuPwxSksdOfLk2bp
  XIKyNHKFqZ/PPqqa1L0Mac4AEnRQLy21UXHKnjTCRmsoGyg2apcLB2GTeKqftdOqbqo2ZxDQHWsv
  +YXVeRz++RQW0vkyZdJIf9XubbtPKzN/a18MA+gA9aSfYNLZTKemKtSYWCtISdQYgYD0J/f9AN/b
  lrvF/3b9X/DvX/y0eJZ+vY0jsF5Kunp2nOx0faO00J3H4UtTWOjNF5KuCUdKoBopm4JX3alSbY22
  /B31UE8GFxXMqki6cjnN7XAWIR3vA637DqG1vRj00YmXsTP5Jk58/lo5HkpwY9NRdn4V5eXmgg69
  fx1APwpLXgNEJOvq61tDbIXHVfRJdaly1wiI0K8nA5BsdpI3Ws+GFTMcp+DI5Oy/pxS4AZDrPwA4
  8dlrxckxmSy7TYgTKxLd2rkBQK8ECdV+c/KZx7Lek7ILnQhe56dHWZDsdvk+kJR0oS5zTfN2pQI1
  Kvqd14I62BpvQ1VpbT8EZMCJT14rpICDZ2eV7LPuGGjduw6gvQDo6vww/VKqCAwTe96rrMQG6HXS
  reQSnwSIsvFue5Bi0EoTlD0H67YpvdCEWAD7YsfQFwX2vkCBlB7R6KkfLrGC1n0bsPKXprCwMl+c
  VAdB1ZrqURvkIWAUEJvRsAF7VZWhr9FYOtYeCvEpf4zlUF3IyhN5jvkkAH/zyxfzJTgK2HsP5TFO
  S2s4AInTtnauzUMHVv7GfBkI1ZM27KiAazoH5rxu6QSGM6zGEej3k7kIHbTRCbPCzQiUIH1RAaZD
  BFddWW6WjYGlZBGHr05hoT8a2P31EOvU4AxLaHc9Yr/Qncfhy4E26aTYGC2LjSOk5hnl1Nbyw/yt
  qiMcExNoRzNPzLaRoD2QDqVKwLDepESx09yPxAjTRNHgQm8eh385taYq2P+1A4XEalseaN1/CK0H
  NgCo6lDdkUJgrL9ujZLqTnWCLNBWqqkaC/3dHoT+MIZcr3IW1Yvw5kFn7rPhQG5FDIAvLM7jyEcb
  APaBl0shttb2DUjohbDkCYrGam3g3O7xsjmzqrybLm3WYx0VwcBFmMPg8QingSBlsTnszCk/1U5q
  MEJ1UAYs9RZx5PIULq6sAazkkVo7Nuh6dubLkTBd1kDZ5VaQ1NmxaSJd2mpTbEKUNLJX1OEz/GIA
  ahZjduhBSAW8xsYYCy2mpbi3i2IbuHgxGwX2n779xvqe0vkpLCzNl/kmQajaoKE0ySYueU71blU+
  zkosz4kAeYfZASRuLJst5WKULlGZW4agxgkYJshMGbMVl0eL1gP22xOPrA3olSkseAGUhRJJsOhQ
  KF9VyVTQqrIYo6gX71sxOABAls0MQE06yewAefUoNHiiy0oDMDpbqxgOyKgBdEFiL0zh4vJoYEcC
  qq4n1Y7NRtj+VsQNhiiXjeRrXeSr+hbNMoCFcFzHgIYmEzmOEQDM7Jpto+9nhl6/ocRxWyLdVvJR
  SsQqymmTVK6zs1L3zQK70J3H4V9MFbzXTmbVngQFhsFspmwMDy654Aqqbl3iJK0AaIf6OgHUa4Dv
  +Blu/RnMjc/c6VLOR4MObISKmUtLY4pVk8BnrMWM8vTwkQtrqwIgGKWPglGi5LAetdQKpmYMIN+V
  yCsHtyDLrr5Sevp/AfwPgMUwxq1hnEsAfuVO6xBDp7KZQRSH21t4LKNsDDQFUhXR6YYZpURrLp08
  NwaW/CKOnB8N7MDKr4qVH+X96K5n9k/bVO9PAVe1Rklk6kdjuzeCZDKIxE0gYwBWgWw5eku7MyhP
  Xdr9nvNoDJYvZ6orHbX7mdQzsbqUy58dVgstkrE12YYfP/yPeGhzYaQWugHQdL6c9Kvaa6CgaIKO
  Vt3mwFioY6mfrwH4Ajm13Apgczi/ilx/roYjQeHojANYwdyZP/xg17CkAkAPPxlIJyW2K53rSWc1
  5cIOspNqQDKpA+Yz7Ktf8os4crGQ2IGn1Jsve3B2AxlQhPMst0ZR/4DqZajmnN0AaFvGuhQA/jQA
  2sVw+HM1l2A3Vuz4GwI1mYheHcRTU1OJAqmsQHWwhvJUV6mFZqlJvVQFl6dw5vp/5LQpnS9yYJZH
  2lwSlz83t2koEfnA8XEAqI0yQ1kGcBXArwKICYBNKBKZbRRLv4NhHr8MdD+PTkuPhoaKPe/tPgmP
  g0MZQxbrUfEa9bGNMyqR1mC2xjht6sKG15gGVwmzGQj129kPZkuvB2CYNh8PwHFpf4oi51ZHvuz7
  4ZlFMwa64FwFXXfyzPffb42UVADw/exkKfKvMVZKqFp/HUwVOPzUfL9u12HJTF0qaXyt0UaNbJu0
  BWkA4wsAnwigm8IzKwHoz4Mk0uBsCkcSpHRF+sgtR1wFwQD3vDtuMRwC9ezv/2wGY35mACYlROmI
  Ug9lAXajr74ZohsT1nL5bETeRNWHAjgqpbT4XyDXkcsodjxvlrZXA2C8ZvvNemgga3JN3y+I3cl3
  n5udWxdUAEiRvlRyW6tcPKszfcV3HbySaZVsu7OFQHI/Fp2LZZT1t02N0DLfCAeMALBvumsPKHJw
  3FT8GXIjBZSNKjOvNFge6MXDUgpU6FSWp/5796sOOFKqXI2QSibzSgqK6jwNFqsRs+4l7+Xg2C53
  iHCH9ThyaWSYkUueyzVD8QLECsouKPvCFPhqOM/QJ/u4JUwO1UXNjAHu5JnnyrqUpVJSASCJoml4
  tEu7NDTwYPeFVr1uY3M5NXOf6kSgcPs+Q0Hnghs4aHcJuZ5cQS69pEKLASBa6Bpy/cjtR1QrPQxT
  KoJbQ/GyxpL0m3UUQeq53kS1lK4Jau7H+tZQykEtobXca8VV+Te9NX6X5YRF5MbjhtxDfbYcpJP6
  cDF8asKRv7Oim+Ro8aveQ3AoGzCqoUTa121L4/k518f0u88O69J1QQWAM7937i0k/q2h3xlRXasx
  gGydWtXak0F0A5DLKBsdjULJO0sDHRg44gBIG4im9aZhUWOm6RBa/jGpnxNio143AKTu5NsH1/7Z
  jwTrlDiKW6nPGgAmS9zUGiMWNQ5VEk7r+zlyixwB+DXKSUfu8R8L4GwO30nCCQZ5qG6s45ahJNxP
  b0sjWOqCJyjvvOEe2wkzhi0AFjHXWxm97FncejcAwJP/9WgjjpNTyMJvQ2tKWfcLqOExLzWUUryL
  YcBK4jl4DhDIl6UHsC1c7yLXdUq/VDR6oW4XQEB4ZrOp2yOXYlUPQEH4Edpk/4L721uJdlVRKFui
  9W4AgHf+4MNZeP/SUFpXJUB3+vH8Igq9CeSey8codOZiqEuThYyoj6OIbpEqUefpjxtwizkLs7kd
  FEziGsrb2JWOUb1wDPUwCTR8QV9nPby0EUDZrQ2Vq//w6eyDz+1wzqNZ2knCzto9pNxLwMHVAjAe
  uRRxGY4hjwiRi6oLS6eDdXDJ20wuYxCUQubQCJ7qSUi97LtI4wBkBrUBeI/j//mnH/z9RrHaMKgA
  cPWfP5l58M92OJeiORgsrfCNUNs15Et0FYV7qa9DcsCU5nGRjAyFLnPhew3lt1iox+2uQdIe6+UB
  Rax1Cwo+reFCWvZNKFRI6JuPcPzs9z+YvhmcbgpUALj6r5/MPHhwh3M30Cxt7waKoDSXo76pp5Ev
  Je36uji5okcRKbohdW4Og9dNddxqWZO+UIo70g+V/lEvoK3IkQHe4fjZP785QL8UqECQ2D/e4VyM
  ZulnNfR9gFoAgWBTwkhPSHPo+jlznqqBelC3IzEFrtF9hHs0X4bQj4lQbxsFBeOkMILvZOId4Gs4
  fvbgzQP6pUEFgKtvfDLzrR/suO5ifA+QDqn3QYnQ3St0aWnxIYDrJmOe53IlMJyEifC8Te7pzm4C
  DRQSr782AZT1cW502/D+hbOtc69+WWw2RKnWKs1Tjcn0enYKwORgmRFMLkdKAw0A0zMsEkpDhIJK
  MdelLwZT330NheGiKlkKn5tQSDQZAlPNLBp9K94DmEt70bPv/NWt/cOFDVGqtcrMvtm52Ef70Mfr
  Q1vVqR9JqZS72lxXDWX9ChQGiHpUMw+aw9f4L/UsHQhyTc3C0uJ35Jx3b8WInrhVQIHbIKla9vz0
  seex1R3DKiaxjILW0EGgZaeeVPdTvTPe9+sAKLknNy7QEyOoHZSjSBqzZXRLUyh96RPQRuZbZ/5i
  9C+i3Wy5ZUnVcubAuZPxarQPaZBa1ZEk81xq+h8pSK30xw3p2TDOyTT5eLifvj1/0ULTx/oWCiVX
  32kNetl38ePYRbtuJ6DAbZZULc03G5NpnE0DOAigeHmNgQ1SIBoeTdh5lP186mJSNKBY4k7qoXuq
  ESvqXWCgInzqZ7LV+KV3/vrO/LOaOwZqCVxk00hwcCAt1HUaF6DOZICDESZ9USwJ5zVaptsnmU21
  2ycH+6LcSY/09bOt8u9I/daBWgI3S5vI3DFMYLIUQ9C3pfUFWnpM5K8ZCumlUVPOqj/gFdIrPsEs
  HH6SIHp1plX9g4e/taBqefKNRxtRmjwD+Kedc00sYzhjyt4RSLtniq4npZVOSB/wzs+g506jk82c
  /cs7K5VV5a6AqqV5olHvo9/ARNRE/rsDk85jEkkIM2oQnPq0cHfb8Gij72f8JjeLOHs/WUpmvyqJ
  HFXuOqijSvNEo96pd+pJO5kcnEyALMraWTdrT2CifbfBG1X+D0B2/KEUuUUXAAAAJXRFWHRkYXRl
  OmNyZWF0ZQAyMDI0LTAxLTMwVDA4OjI5OjI2KzAxOjAws3Y6agAAACV0RVh0ZGF0ZTptb2RpZnkA
  MjAyNC0wMS0zMFQwODoyOToyNiswMTowMMIrgtYAAAAASUVORK5CYII="
    />
  </svg>
);

export const MailIcon: SvgIcon = () => (
  <svg
    width="45"
    height="33"
    viewBox="0 0 45 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1.5"
      width="43"
      height="30"
      rx="5"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M4 5.5L22.5 18C26.6667 13.8333 41 5.5 41 5.5"
      stroke="black"
      strokeWidth="2"
    />
  </svg>
);
