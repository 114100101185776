import React, {FC} from 'react';
import classnames from 'classnames';
import {BlueLogoHeader, LoadingSpinner, LogoutIcon} from 'components';
import {selectUnreadMessagesCount} from 'features/Messaging';
import {selectUserProfile} from 'features/User';
import {useLogout, useUserNotifications} from 'hooks';
import {SliceStatus} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import styled from 'styled-components';
import {isUserFromUnitedStates} from 'utils';

import {filterNavMenuItems, NavMenuItem} from './navigationConstants';
import {NavLink} from './NavLink';

const NavigationContainer = styled.section`
  height: calc(100% - 5rem);
`;
export const DesktopLeftNavigation: FC = () => {
  const location = useLocation();
  const {pathname} = location;
  const user = useSelector(selectUserProfile);
  const isUserFromUS = isUserFromUnitedStates(user);
  const {t} = useTranslation();

  const {logout, logoutStatus} = useLogout(true);

  const {unreadNotifsLength} = useUserNotifications();
  const unreadMessagesCount = useSelector(selectUnreadMessagesCount);
  const menuItems = filterNavMenuItems(user!.role, isUserFromUS);

  return (
    <>
      <BlueLogoHeader />
      <NavigationContainer className="pt-24 flex flex-col">
        <nav className="border-t border-b border-gray-200 overflow-y-auto">
          {menuItems.map(
            ({name, route, icon: Icon}: NavMenuItem, index: number) => (
              <article key={route}>
                <NavLink
                  key={route}
                  pathname={route}
                  first={index === 0}
                  current={pathname === route}
                  linkName={t(`leftNavigation.${name.toLowerCase()}`)}
                  count={
                    route.includes('/notifications')
                      ? unreadNotifsLength
                      : route.includes('/message')
                      ? unreadMessagesCount
                      : undefined
                  }
                >
                  <Icon
                    strokeColor={
                      pathname === route ? 'text-blue-600' : undefined
                    }
                  />
                </NavLink>
              </article>
            ),
          )}
        </nav>
        <div
          onClick={logout}
          role="button"
          className={classnames(
            'flex items-center text-red-500 pl-24 py-6 text-sm leading-snug mt-auto border-t border-gray-200 cursor-pointer ',
            {
              'pointer-events-none opacity-50':
                logoutStatus === SliceStatus.pending,
            },
          )}
        >
          {logoutStatus === SliceStatus.pending ? (
            <LoadingSpinner
              type="Oval"
              width={20}
              height={20}
              color="#EF4444"
            />
          ) : (
            <LogoutIcon strokeColor="text-red-500" />
          )}
          <span className="pt-1 pl-5">{t('logout')}</span>
        </div>
      </NavigationContainer>
    </>
  );
};
