import React, {FC, lazy, Suspense} from 'react';
import {FormError, LoadingSpinner} from 'components';
import dayjs from 'dayjs';
import {SetAppointmentPriceSchema} from 'definitions/Yup/setAppointmentPriceSchema';
import {selectUserNotification} from 'features/User';
import i18next from 'i18next';
import {CurrentUser, ProviderProfile} from 'interfaces';
import {ChargeStatus} from 'interfaces/Meeting.types';
import {DPOOPAppointmentPrice} from 'interfaces/Pages/Pricing.types';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  componentLoader,
  getProviderCurrency,
  isTherapist,
  isUserFromUnitedStates,
  MapCurrencySymbol,
} from 'utils';

import {yupResolver} from '@hookform/resolvers/yup';

import {setAppointmentPrice} from './chargeActions';

const Modal = lazy(() =>
  componentLoader(() => import('components/Basic/Modal')),
);

type Options = {
  value: string;
  label: string;
};

type ChargeModalProps = {
  currencies?: Options[];
  price: number;
  appointmentChargeCurrency?: string;
  showChargeModal: boolean;
  setShowChargeModal: React.Dispatch<React.SetStateAction<boolean>>;
  user: CurrentUser;
  skip: boolean;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  showSuccessModal: boolean;
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOnCallPricingSkip: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentID: string;
  charged?: ChargeStatus;
  onChargeClick: (chargeAmount?: number) => void;
  isLoading?: boolean;
  patientId: string;
  localTime: Date;
};

const ChargeModal: FC<ChargeModalProps> = ({
  price,
  showChargeModal,
  setShowChargeModal,
  user,
  appointmentID,
  onChargeClick,
  isLoading,
  skip,
  showSuccessModal,
  setShowSuccessModal,
  setSkip,
  setOnCallPricingSkip,
  appointmentChargeCurrency,
  patientId,
  localTime,
}) => {
  const {t} = useTranslation();
  const providerCurrency = getProviderCurrency(user?.countryOfResidence?.code);
  const providerCurrencySymbol = MapCurrencySymbol[providerCurrency];

  const providerData = user as ProviderProfile;
  const {
    handleSubmit,
    register,
    watch,
    formState: {errors},
    getValues,
    setError,
  } = useForm<DPOOPAppointmentPrice>({
    mode: 'onSubmit',
    defaultValues: {
      charge: price || 0,
      chargeCurrency: price ? appointmentChargeCurrency : providerCurrency,
    },
    resolver: yupResolver(SetAppointmentPriceSchema),
  });
  const dispatch = useDispatch();
  const isUserFromUS = isUserFromUnitedStates(user);
  const canSetPrice = !price && skip === false;
  const canCharge = price;
  const shouldSkip = skip === true;

  const callback = () => {
    setShowChargeModal(false);
    setShowSuccessModal(true);
  };

  const closeModal = () => {
    setShowChargeModal(false);
  };

  const {message, messageType} = useSelector(selectUserNotification);

  const onAppointmentPriceSubmit = (val: DPOOPAppointmentPrice) => {
    if (val.charge && providerData?.sessionRate?.digitalPractice?.max) {
      const currentSessionRate = providerData.sessionRate.digitalPractice.max;

      if (val.charge > currentSessionRate) {
        setError('charge', {
          type: 'custom',
          message: i18next.t('chargeAmountWarning', {
            providerCurrency,
            providerCurrencySymbol,
            currentSessionRate,
          }),
        });
        return;
      }
    }

    const data = {
      providerType: user?.role,
      appointmentID,
      callback,
      closeModal,
      patientId,
      startDate: dayjs(localTime).toISOString(),
      ...val,
    };
    dispatch(setAppointmentPrice(data));
  };

  const chargeAmount = Number(watch('charge'));

  const handleChargeClick = () => {
    const currentSessionRate = providerData?.sessionRate?.digitalPractice?.max;
    if (currentSessionRate && chargeAmount > currentSessionRate) {
      setError('charge', {
        type: 'custom',
        message: i18next.t('chargeAmountWarning', {
          providerCurrency,
          providerCurrencySymbol,
          currentSessionRate,
        }),
      });
      return;
    }
    onChargeClick(chargeAmount);
  };

  const renderFooter = () => {
    return (
      <>
        {shouldSkip ? (
          <button
            className="w-1/3 min-w-[160px] h-11 flex items-center justify-center px-3 rounded-lg border border-gray-400 text-gray-700 hover:text-white hover:bg-primary"
            type="button"
            onClick={() => {
              setSkip(false);
              setOnCallPricingSkip(true);
              closeModal();
            }}
          >
            {t('continueSkipping')}
          </button>
        ) : (
          <button
            className="px-4 h-11 flex items-center justify-center rounded-lg border font-semibold border-gray-400 text-gray-700 hover:text-white hover:bg-primary"
            type="button"
            onClick={() => setSkip(true)}
          >
            {t('skip')}
          </button>
        )}

        {canCharge ? (
          <button
            className="w-1/3 h-11 flex items-center justify-center font-semibold rounded-lg border border-blue-600 bg-blue-600 text-white hover:text-blue-600 hover:bg-white"
            type="button"
            onClick={handleChargeClick}
          >
            {isLoading ? (
              <LoadingSpinner type="Oval" width={12} height={12} />
            ) : (
              t('charge')
            )}
          </button>
        ) : (
          <button
            className="w-1/3 h-11 flex items-center justify-center font-semibold rounded-lg border border-blue-600 bg-blue-600 text-white hover:text-blue-600 hover:bg-white"
            type="submit"
            form="set-appointment-price"
          >
            {isLoading ? (
              <LoadingSpinner type="Oval" width={12} height={12} />
            ) : (
              t('setPrice')
            )}
          </button>
        )}
      </>
    );
  };

  return (
    <>
      {showChargeModal && (
        <Suspense fallback={<div>Loading...</div>}>
          <Modal
            messageType="none"
            isOpen={showChargeModal}
            showCloseIcon
            title={
              shouldSkip
                ? t('areYouSure')
                : canCharge
                ? t('chargeSession')
                : t('setSessionPrice')
            }
            buttonFn={closeModal}
            floatingTitle={false}
            titleClasses="font-semibold px-2 text-lg block"
            headerClasses="w-full h-16 px-4 flex justify-end items-center"
            containerClasses="w-full w-[445px] max-h-[90vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-2xl text-left align-middle transition-all shadow-lg"
            footer={renderFooter()}
            backdropClasses="bg-black/30"
          >
            <form
              id="set-appointment-price"
              onSubmit={handleSubmit(onAppointmentPriceSubmit)}
            >
              <div className="flex flex-col w-full items-start p-1">
                {isUserFromUS && (
                  <div>
                    {shouldSkip || canSetPrice ? (
                      <p className="font-normal font-inter my-3 leading-6">
                        {shouldSkip
                          ? t('setPriceToEarnWellbit')
                          : t('earnWellbits')}
                      </p>
                    ) : null}
                    <p className="text-sm font-light">
                      {t('wellbitsRewarded')}
                    </p>
                  </div>
                )}
                <div className="mt-4 mb-2 flex text-sm w-full items-center justify-center">
                  <p> {getValues('chargeCurrency')}</p>

                  <input
                    className="w-full ml-4 rounded-lg text-center border-gray-400 border py-3 px-3 focus:outline-none focus:border-blue-600 focus:ring-blue-600"
                    disabled={shouldSkip ? false : !!price}
                    {...register('charge')}
                  />
                </div>
                <span className="m-auto mb-2">
                  <FormError error={errors.charge?.message} />
                </span>
              </div>
            </form>
          </Modal>
        </Suspense>
      )}

      {isTherapist(user) && showSuccessModal ? (
        <Suspense fallback={<div />}>
          <Modal
            message={message}
            messageType={messageType}
            isOpen={true}
            containerClasses="w-full w-[445px] max-h-[90vh] bg-white md:max-w-xl 2xl:max-w-2xl overflow-hidden transform rounded-2xl"
            backdropClasses="bg-black/30"
            buttonFn={() => setShowSuccessModal(false)}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default ChargeModal;
