import {
  ChatAuth,
  ChatChannel,
  ChatParticipant,
  ChatUserData,
  CometChatMessageType,
  CometFriend,
  SliceStatus,
} from 'interfaces';

import {CometChat} from '@cometchat-pro/chat';
import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  addFriend,
  addMessage,
  cometChatLogOut,
  cometChatLogOutFailure,
  cometChatLogOutSuccess,
  getFriendsList,
  getMediaMessageHistory,
  getMessageHistory,
  getMoreMediaMessageHistory,
  getMoreMessageHistory,
  getUnreadMessageCount,
  loginUserOnCometChat,
  markMessageAsDelivered,
  markMessageAsRead,
  resetMediaMessageHistory,
  resetMessageHistory,
  resetParticipant,
  resetSearchedFriendsList,
  resetUnreadCount,
  resetUnreadMessageCount,
  searchFriends,
  searchFriendsFailure,
  searchFriendsSuccess,
  setLastMessage,
  setParticipant,
  setUnreadCount,
  setUnreadMessageCount,
  setUserPresenceStatus,
  setUserTypingStatus,
} from './messagingActions';

const sortFriends = (a: CometFriend, b: CometFriend) =>
  b.unreadMessageCount - a.unreadMessageCount;

type MessagingSliceState = {
  current: ChatAuth;
  userData: ChatUserData;
  channel: ChatChannel;
  loadingMessages: boolean;
  percentUploaded: number;
  status: SliceStatus;
  typing: {
    isTyping: boolean;
    id: string;
    fullName: string;
  };
  error: string;
  timestamp: unknown;
  serverTimeOffset: number;

  participant: ChatParticipant | null;
  friendsList: {
    data: CometFriend[];
    status: SliceStatus;
    hasMore: boolean;
  };
  searchedFriendsList: {
    data: CometFriend[];
    status: SliceStatus;
    hasMore: boolean;
    keyword: string;
  };
  cometChatUser: CometChat.User | null;
  messages: {
    hasMore: boolean;
    lastMessageId: string;
    data: CometChatMessageType[];
  };
  mediaMessages: {
    hasMore: boolean;
    lastMessageId: string;
    data: CometChatMessageType[];
  };
  mediaMessagesLoading: SliceStatus;

  unreadMessageCount: number;

  loading: SliceStatus;
};

const messagingSlice = createSlice({
  name: 'messaging',
  initialState: {
    current: null as ChatAuth,
    userData: null as ChatUserData,
    channel: null as ChatChannel,
    loadingMessages: true,
    percentUploaded: 0,
    // messages: {} as ChatMessageHistory,
    status: SliceStatus.idle,
    error: '',
    timestamp: {},
    typing: {
      isTyping: false,
      id: '',
      fullName: '',
    },
    serverTimeOffset: 0,

    participant: null as ChatParticipant,
    friendsList: {
      data: [] as CometFriend[],
      status: SliceStatus.idle,
      hasMore: false,
    },
    searchedFriendsList: {
      data: [] as CometFriend[],
      status: SliceStatus.idle,
      hasMore: false,
      keyword: '',
    },
    cometChatUser: null,
    messages: {
      hasMore: true,
      lastMessageId: '',
      data: [] as CometChatMessageType[],
    },
    mediaMessages: {
      hasMore: true,
      lastMessageId: '',
      data: [] as CometChatMessageType[],
    },
    mediaMessagesLoading: SliceStatus.idle,
    unreadMessageCount: 0,
    loading: SliceStatus.idle,
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<MessagingSliceState>) =>
    builder
      .addCase(loginUserOnCometChat.pending, state => {
        state.loading = SliceStatus.pending;
      })
      .addCase(loginUserOnCometChat.fulfilled, (state, action) => {
        state.loading = SliceStatus.resolved;
        state.cometChatUser = action.payload || null;
      })
      .addCase(loginUserOnCometChat.rejected, state => {
        state.loading = SliceStatus.rejected;
      })
      .addCase(setParticipant, (state, action) => {
        state.messages.data = [];
        state.messages.lastMessageId = '';
        state.participant = action.payload;
      })
      .addCase(resetParticipant, state => {
        state.participant = null;
      })
      .addCase(addMessage.fulfilled, (state, action) => {
        state.messages.data = action.payload;
      })
      .addCase(setLastMessage, (state, action) => {
        const {uid, data} = action.payload;
        const fIdx = state.friendsList.data.findIndex(f => f.uid === uid);

        if (fIdx >= 0) {
          const cpyFriendsList = [...state.friendsList.data];
          cpyFriendsList[fIdx].lastMessage = data;
          state.friendsList.data = cpyFriendsList;
        }
      })
      .addCase(setUnreadCount, (state, action) => {
        const uid = action.payload;
        const fIdx = state.friendsList.data.findIndex(f => f.uid === uid);

        if (fIdx >= 0) {
          const cpyFriendsList = [...state.friendsList.data];
          cpyFriendsList[fIdx].unreadMessageCount += 1;
          state.friendsList.data = cpyFriendsList.sort(sortFriends);
        }
      })
      .addCase(resetUnreadCount, (state, action) => {
        const uid = action.payload;
        const fIdx = state.friendsList.data.findIndex(f => f.uid === uid);

        if (fIdx >= 0) {
          const cpyFriendsList = [...state.friendsList.data];
          cpyFriendsList[fIdx].unreadMessageCount = 0;
          state.friendsList.data = cpyFriendsList.sort(sortFriends);
        }
      })
      .addCase(setUserPresenceStatus, (state, action) => {
        const {uid, status, lastActiveAt} = action.payload;
        const fIdx = state.friendsList.data.findIndex(f => f.uid === uid);

        if (fIdx >= 0) {
          const cpyFriendsList = [...state.friendsList.data];
          cpyFriendsList[fIdx].status = status;
          cpyFriendsList[fIdx].lastActiveAt = lastActiveAt;
          state.friendsList.data = cpyFriendsList;
        }
      })
      .addCase(setUserTypingStatus, (state, action) => {
        const {uid, typing} = action.payload;
        const fIdx = state.friendsList.data.findIndex(f => f.uid === uid);

        if (fIdx >= 0) {
          const cpyFriendsList = [...state.friendsList.data];
          cpyFriendsList[fIdx].typing = typing;
          state.friendsList.data = cpyFriendsList;
        }
      })
      .addCase(markMessageAsDelivered, (state, action) => {
        const {uid, messageId, deliveredAt} = action.payload;
        const fIdx = state.friendsList.data.findIndex(f => f.uid === uid);
        const cpyFriendsList = [...state.friendsList.data];

        const mIdx = state.messages.data.findIndex(m => m.id === messageId);
        const cpyMessages = [...state.messages.data];

        if (fIdx >= 0 && cpyFriendsList[fIdx].lastMessage.id === messageId) {
          cpyFriendsList[fIdx].lastMessage.deliveredAt = deliveredAt;
          state.friendsList.data = cpyFriendsList;
        }

        if (state.participant && mIdx >= 0) {
          cpyMessages[mIdx].deliveredAt = deliveredAt;
          state.messages.data = cpyMessages;
        }
      })
      .addCase(markMessageAsRead, (state, action) => {
        const {uid, messageId, readAt} = action.payload;
        const fIdx = state.friendsList.data.findIndex(f => f.uid === uid);
        const cpyFriendsList = [...state.friendsList.data];

        const mIdx = state.messages.data.findIndex(m => m.id === messageId);
        const cpyMessages = [...state.messages.data];

        if (fIdx >= 0 && cpyFriendsList[fIdx].lastMessage.id === messageId) {
          cpyFriendsList[fIdx].lastMessage.readAt = readAt;
          state.friendsList.data = cpyFriendsList;
        }

        if (state.participant && mIdx >= 0) {
          cpyMessages[mIdx].readAt = readAt;
          state.messages.data = cpyMessages;
        }
      })
      .addCase(getMessageHistory.pending, state => {
        state.messages.hasMore = true;
      })
      .addCase(getMessageHistory.fulfilled, (state, action) => {
        state.messages = action.payload;
      })
      .addCase(getMoreMessageHistory.fulfilled, (state, action) => {
        state.messages = action.payload;
      })
      .addCase(resetMessageHistory, state => {
        state.messages = {
          hasMore: false,
          lastMessageId: '',
          data: [],
        };
      })
      .addCase(getMediaMessageHistory.pending, state => {
        state.mediaMessages.hasMore = true;
        state.mediaMessagesLoading = SliceStatus.pending;
      })
      .addCase(getMediaMessageHistory.fulfilled, (state, action) => {
        state.mediaMessages = action.payload;
        state.mediaMessagesLoading = SliceStatus.resolved;
      })
      .addCase(getMediaMessageHistory.rejected, state => {
        state.mediaMessagesLoading = SliceStatus.rejected;
      })
      .addCase(getMoreMediaMessageHistory.fulfilled, (state, action) => {
        state.mediaMessages = action.payload;
      })
      .addCase(resetMediaMessageHistory, state => {
        state.mediaMessages = {
          hasMore: false,
          lastMessageId: '',
          data: [],
        };
      })
      .addCase(getFriendsList.pending, state => {
        state.friendsList.status = SliceStatus.pending;
      })
      .addCase(
        getFriendsList.fulfilled,
        (state, {payload: {result, isNext}}) => {
          state.friendsList.status = SliceStatus.resolved;
          state.friendsList.data = isNext
            ? [...state.friendsList.data, ...result].sort(sortFriends)
            : result.sort(sortFriends);
          state.friendsList.hasMore = !!result.length;
        },
      )
      .addCase(getFriendsList.rejected, state => {
        state.friendsList.status = SliceStatus.rejected;
      })
      .addCase(searchFriends, (state, {payload}) => {
        state.searchedFriendsList.status = SliceStatus.pending;
        state.searchedFriendsList.keyword = payload.keyword;
      })
      .addCase(searchFriendsSuccess, (state, {payload: {result, isNext}}) => {
        state.searchedFriendsList.status = SliceStatus.resolved;
        state.searchedFriendsList.data = isNext
          ? [...state.searchedFriendsList.data, ...result].sort(sortFriends)
          : result.sort(sortFriends);
        state.searchedFriendsList.hasMore = !!result.length;
      })
      .addCase(searchFriendsFailure, state => {
        state.searchedFriendsList.status = SliceStatus.rejected;
      })
      .addCase(addFriend, (state, action) => {
        const isFriendExists = state.friendsList.data.some(
          data => data.uid === action.payload.uid,
        );
        if (!isFriendExists) {
          state.friendsList.data = [action.payload, ...state.friendsList.data];
        }
      })
      .addCase(getUnreadMessageCount.fulfilled, (state, action) => {
        state.unreadMessageCount = action.payload;
      })
      .addCase(resetUnreadMessageCount, state => {
        state.unreadMessageCount = 0;
      })
      .addCase(setUnreadMessageCount, (state, action) => {
        state.unreadMessageCount = state.unreadMessageCount + action.payload;
      })
      .addCase(resetSearchedFriendsList, state => {
        state.searchedFriendsList.status = SliceStatus.idle;
        state.searchedFriendsList.data = [];
      })
      .addDefaultCase(state => state),
});

export const {reducer: messagingReducer, name: messagingReducerName} =
  messagingSlice;

export type TMessagingActions =
  | ReturnType<typeof loginUserOnCometChat>
  | ReturnType<typeof cometChatLogOut>
  | ReturnType<typeof cometChatLogOutFailure>
  | ReturnType<typeof cometChatLogOutSuccess>
  | ReturnType<typeof getMessageHistory>
  | ReturnType<typeof getMoreMessageHistory>
  | ReturnType<typeof getMoreMediaMessageHistory>
  | ReturnType<typeof resetMediaMessageHistory>
  | ReturnType<typeof resetMessageHistory>
  | ReturnType<typeof getMediaMessageHistory>
  | ReturnType<typeof getUnreadMessageCount>
  | ReturnType<typeof resetUnreadMessageCount>
  | ReturnType<typeof addMessage>
  | ReturnType<typeof getFriendsList>
  | ReturnType<typeof searchFriends>
  | ReturnType<typeof searchFriendsFailure>
  | ReturnType<typeof searchFriendsSuccess>
  | ReturnType<typeof addFriend>
  | ReturnType<typeof resetSearchedFriendsList>
  | ReturnType<typeof setUnreadMessageCount>
  | ReturnType<typeof setParticipant>
  | ReturnType<typeof setUnreadCount>
  | ReturnType<typeof resetUnreadCount>
  | ReturnType<typeof setUserPresenceStatus>
  | ReturnType<typeof setUserTypingStatus>
  | ReturnType<typeof markMessageAsDelivered>
  | ReturnType<typeof markMessageAsRead>
  | ReturnType<typeof resetParticipant>
  | ReturnType<typeof setLastMessage>;

export const messagingActions = {
  loginUserOnCometChat,
  cometChatLogOut,
  cometChatLogOutFailure,
  cometChatLogOutSuccess,
  getMessageHistory,
  getMoreMessageHistory,
  getMoreMediaMessageHistory,
  resetMediaMessageHistory,
  resetMessageHistory,
  getMediaMessageHistory,
  getUnreadMessageCount,
  resetUnreadMessageCount,
  addMessage,
  getFriendsList,
  searchFriends,
  searchFriendsFailure,
  searchFriendsSuccess,
  addFriend,
  resetSearchedFriendsList,
  setUnreadMessageCount,
  setLastMessage,
  setParticipant,
  setUnreadCount,
  resetUnreadCount,
  setUserPresenceStatus,
  setUserTypingStatus,
  markMessageAsDelivered,
  markMessageAsRead,
  resetParticipant,
};

export type MessagingState = ReturnType<typeof messagingReducer>;
