import React, {FC, ReactNode} from 'react';
import {DashboardLayoutV2} from 'components';
import ProfileSideBar from 'features/Provider/Profile/ProfileSideBar';
import {useBreakpoint} from 'hooks';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {history} from 'utils';

import {ProviderHeader} from '../Headers/ProviderHeader';

const findTitle = (t: TFunction<'translate', undefined>, pathname: string) => {
  switch (pathname) {
    case '/provider/profile/in-network':
      return t('title.provider-in-network', 'In-Nework');
    case '/provider/profile/settings':
      return t('title.provider-settings', 'Settings');
    case '/provider/profile/revenue':
      return t('title.provider-revenue', 'Revenue');
    case '/provider/profile/receipts':
      return t('title.receipts', 'Receipts');
    case '/provider/profile/resource-center':
      return t('title.provider-resource-center', 'Resource Center');
    default:
      return t('rightNavigation.profile');
  }
};

export const ProviderProfileLayout: FC<{
  children: ReactNode[] | ReactNode;
}> = ({children}) => {
  const {pathname} = location;
  const {isLg} = useBreakpoint('lg');
  const {t} = useTranslation();

  return (
    <DashboardLayoutV2>
      <ProviderHeader
        title={t('rightNavigation.profile & account settings')}
        pageTitle={t('title.profile')}
        mobileTitle={findTitle(t, pathname)}
        backButtonFn={() => {
          if (document.referrer.includes(window.location.host)) {
            history.goBack();
          } else {
            history.push('/provider/profile');
          }
        }}
      />
      <div className="grid grid-cols-1 lg:grid-cols-9 w-full pb-24 lg:pb-0 responsive-w">
        {isLg ? <ProfileSideBar className="block lg:col-span-2" /> : null}

        <div className="block px-3 lg:col-span-7 pt-5">{children}</div>
      </div>
    </DashboardLayoutV2>
  );
};
