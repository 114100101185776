import {
  AvailabilityData,
  AvailabilityInTimeRange,
  BlockAvailabilityReq,
  BlockedAvailability,
  ProviderRole,
  SliceStatus,
  SpecificDayAvailabilityData,
  SpecificDayAvailabilityResponse,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';

export const getProviderAvailabilityHours = createAction<ProviderRole>(
  'getProviderAvailabilityHours',
);

export const getProviderAvailabilityHoursSuccess =
  createAction<AvailabilityData>('GetProviderAvailabilityHoursSuccess');

export const getProviderAvailabilityHoursFailure = createAction<string>(
  'GetProviderAvailabilityHoursFailure',
);

export const saveProviderRecurringAvailability = createAction<{
  data: AvailabilityInTimeRange;
  role: ProviderRole;
  cb: (status: SliceStatus) => void;
}>('saveProviderRecurringAvailability');

export const saveProviderRecurringAvailabilitySuccess =
  createAction<AvailabilityInTimeRange>(
    'saveProviderRecurringAvailabilitySuccess',
  );

export const saveProviderRecurringAvailabilityFailure = createAction<string>(
  'saveProviderRecurringAvailabilityFailure',
);

export const resetProviderAvailabilitySaveStatus = createAction(
  'resetProviderAvailabilitySaveStatus',
);

export const setIsAvailabilityModified = createAction<boolean>(
  'setIsAvailabilityModified',
);

// Blocking Provider's Availability
export const blockProviderAvailability = createAction<{
  data: BlockAvailabilityReq;
  role: ProviderRole;
  cb: (status: SliceStatus) => void;
}>('BlockProviderAvailability');

export const blockProviderAvailabilityFailure = createAction<string>(
  'BlockProviderAvailabilityFailure',
);

export const blockProviderAvailabilitySuccess =
  createAction<BlockedAvailability>('BlockProviderAvailabilitySuccess');

export const unblockProviderAvailability = createAction<{
  blockId: number;
  role: ProviderRole;
  cb?: (status: SliceStatus) => void;
}>('UnblockProviderAvailability');

export const unblockProviderAvailabilityFailure = createAction<string>(
  'UnblockProviderAvailabilityFailure',
);

export const unblockProviderAvailabilitySuccess = createAction<{
  message: string;
  blockId: number;
}>('UnblockProviderAvailabilitySuccess');

// Set Specific Day Availability
export const setSpecificDayAvailability = createAction<{
  role: ProviderRole;
  specificDayAvailability: SpecificDayAvailabilityData;
  cb: (status: SliceStatus) => void;
}>('SetSpecificDayAvailability');
export const setSpecificDayAvailabilityFailure = createAction<string>(
  'SetSpecificDayAvailabilityFailure',
);
export const setSpecificDayAvailabilitySuccess =
  createAction<SpecificDayAvailabilityResponse>(
    'SetSpecificDayAvailabilitySuccess',
  );
