import {userActions} from 'features/User/userSlice';
import {
  AccountTabs,
  InsuranceNamesType,
  PverifyInfoType,
  SliceStatus,
  SocialOnboardingStatusEnum,
} from 'interfaces';

import {ActionReducerMapBuilder, createSlice} from '@reduxjs/toolkit';

import {
  changeSocialOnboardingStatus,
  changeSocialOnboardingStatusFailure,
  changeSocialOnboardingStatusSuccess,
  fetchInsuranceNames,
  fetchInsuranceNamesFailure,
  fetchInsuranceNamesSuccess,
  saveSocialOnboardingData,
  saveSocialOnboardingDataFailure,
  saveSocialOnboardingDataSuccess,
  updatePverifyInfo,
} from './Onboarding/Insurance/SocialOnboardingActions';
import {changePhoneVerificationStage} from './Onboarding/PhoneVerification/phoneVerificationActions';
import {
  changeAddress,
  changeAddressFailure,
  changeAddressSuccess,
  changeEmail,
  changeEmailFailure,
  changeEmailSuccess,
  changePassword,
  changePasswordFailure,
  changePasswordSuccess,
  changePhone,
  changePhoneFailure,
  changePhoneSuccess,
  changePreferredLanguage,
  changePreferredLanguageFailure,
  changePreferredLanguageSuccess,
  changeProfilePicture,
  changeProfilePictureFailure,
  changeProfilePictureSuccess,
  setAccountTab,
  verifyPasswordFailure,
  verifyPasswordStart,
  verifyPasswordSuccess,
  verifyPhoneFailure,
  verifyPhoneStart,
  verifyPhoneSuccess,
} from './Profile/profileActions';
import {
  postMarketingChannel,
  postMarketingChannelSuccess,
} from './Thanks/marketingChannelAction';

type MemberSliceState = {
  status: SliceStatus;
  languageStatus: SliceStatus;
  uploading: boolean;
  accountTab: AccountTabs | null;
  error: string;
  insuranceNames: InsuranceNamesType;
  socialOnboardingStatus: SocialOnboardingStatusEnum;
  pverifyData: {
    data: PverifyInfoType;
  };
};

const MemberSlice = createSlice({
  name: 'member',
  initialState: {
    status: SliceStatus.idle,
    accountTab: null,
    languageStatus: SliceStatus.idle,
    error: '',
    uploading: false,
    insuranceNames: [],
    socialOnboardingStatus: SocialOnboardingStatusEnum.ongoing,
    pverifyData: {
      data: {} as PverifyInfoType,
    },
  },
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<MemberSliceState>) =>
    builder
      .addCase(userActions.setAsyncError, (state, action) => ({
        ...state,
        error:
          action.payload.filter === 'member'
            ? action.payload.message
            : state.error,
      }))
      .addCase(userActions.resetAsyncError, (state, action) => ({
        ...state,
        error: action.payload === 'member' ? '' : state.error,
      }))
      .addCase(verifyPasswordStart, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(verifyPasswordFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(verifyPasswordSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(changePassword, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changePasswordFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(changePasswordSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(changeEmail, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changeEmailFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(changeEmailSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(verifyPhoneStart, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(verifyPhoneFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(verifyPhoneSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(changePhone, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changePhoneFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(setAccountTab, (state, action) => ({
        ...state,
        accountTab: action.payload,
      }))
      .addCase(changePhoneSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(changeAddress, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changeAddressFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(changeAddressSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(changeProfilePicture, state => ({
        ...state,
        uploading: true,
      }))
      .addCase(changeProfilePictureSuccess, state => ({
        ...state,
        uploading: false,
      }))
      .addCase(changeProfilePictureFailure, state => ({
        ...state,
        uploading: false,
      }))
      .addCase(saveSocialOnboardingData, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(saveSocialOnboardingDataSuccess, state => ({
        ...state,
        status: SliceStatus.resolved,
      }))
      .addCase(saveSocialOnboardingDataFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(fetchInsuranceNames, state => {
        if (!state.insuranceNames.length) {
          state.status = SliceStatus.pending;
        }
      })
      .addCase(fetchInsuranceNamesSuccess, (state, action) => ({
        ...state,
        status: SliceStatus.resolved,
        insuranceNames: action.payload,
      }))
      .addCase(fetchInsuranceNamesFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(updatePverifyInfo, (state, action) => ({
        ...state,
        pverifyData: {
          ...state.pverifyData,
          data: action.payload,
        },
        status: SliceStatus.resolved,
      }))
      .addCase(changeSocialOnboardingStatus, state => ({
        ...state,
        status: SliceStatus.pending,
      }))
      .addCase(changeSocialOnboardingStatusSuccess, (state, action) => {
        return {
          ...state,
          socialOnboardingStatus:
            action.payload.insuranceDetails!.onboardingStatus,
          status: SliceStatus.resolved,
        };
      })
      .addCase(changeSocialOnboardingStatusFailure, state => ({
        ...state,
        status: SliceStatus.rejected,
      }))
      .addCase(changePreferredLanguage, state => ({
        ...state,
        languageStatus: SliceStatus.pending,
      }))
      .addCase(changePreferredLanguageFailure, state => ({
        ...state,
        languageStatus: SliceStatus.rejected,
      }))
      .addCase(changePreferredLanguageSuccess, state => ({
        ...state,
        languageStatus: SliceStatus.resolved,
      }))
      .addDefaultCase(state => state),
});

export const {reducer: memberReducer, name: memberReducerName} = MemberSlice;

export type TMemberActions =
  | ReturnType<typeof postMarketingChannel>
  | ReturnType<typeof postMarketingChannelSuccess>
  | ReturnType<typeof verifyPasswordStart>
  | ReturnType<typeof verifyPasswordSuccess>
  | ReturnType<typeof verifyPasswordFailure>
  | ReturnType<typeof changePassword>
  | ReturnType<typeof changePasswordSuccess>
  | ReturnType<typeof setAccountTab>
  | ReturnType<typeof changePasswordFailure>
  | ReturnType<typeof verifyPhoneStart>
  | ReturnType<typeof verifyPhoneSuccess>
  | ReturnType<typeof verifyPhoneFailure>
  | ReturnType<typeof changePhone>
  | ReturnType<typeof changePhoneSuccess>
  | ReturnType<typeof changePhoneFailure>
  | ReturnType<typeof changeAddress>
  | ReturnType<typeof changeAddressSuccess>
  | ReturnType<typeof changeAddressFailure>
  | ReturnType<typeof changeEmail>
  | ReturnType<typeof changeEmailSuccess>
  | ReturnType<typeof changeEmailFailure>
  | ReturnType<typeof changeProfilePicture>
  | ReturnType<typeof changeProfilePictureSuccess>
  | ReturnType<typeof changeProfilePictureFailure>
  | ReturnType<typeof saveSocialOnboardingData>
  | ReturnType<typeof saveSocialOnboardingDataSuccess>
  | ReturnType<typeof saveSocialOnboardingDataFailure>
  | ReturnType<typeof fetchInsuranceNames>
  | ReturnType<typeof fetchInsuranceNamesSuccess>
  | ReturnType<typeof fetchInsuranceNamesFailure>
  | ReturnType<typeof updatePverifyInfo>
  | ReturnType<typeof changeSocialOnboardingStatus>
  | ReturnType<typeof changeSocialOnboardingStatusSuccess>
  | ReturnType<typeof changeSocialOnboardingStatusFailure>
  | ReturnType<typeof changePhoneVerificationStage>
  | ReturnType<typeof changePreferredLanguage>
  | ReturnType<typeof changePreferredLanguageSuccess>
  | ReturnType<typeof changePreferredLanguageFailure>;

export const memberActions = {
  postMarketingChannel,
  postMarketingChannelSuccess,
  verifyPasswordFailure,
  verifyPasswordStart,
  verifyPasswordSuccess,
  changePassword,
  changePasswordFailure,
  changePasswordSuccess,
  setAccountTab,
  verifyPhoneFailure,
  verifyPhoneStart,
  verifyPhoneSuccess,
  changePhone,
  changePhoneFailure,
  changePhoneSuccess,
  changeAddress,
  changeAddressFailure,
  changeAddressSuccess,
  changeEmail,
  changeEmailFailure,
  changeEmailSuccess,
  changeProfilePicture,
  changeProfilePictureFailure,
  changeProfilePictureSuccess,
  saveSocialOnboardingData,
  saveSocialOnboardingDataSuccess,
  saveSocialOnboardingDataFailure,
  fetchInsuranceNames,
  fetchInsuranceNamesSuccess,
  fetchInsuranceNamesFailure,
  updatePverifyInfo,
  changeSocialOnboardingStatus,
  changeSocialOnboardingStatusSuccess,
  changeSocialOnboardingStatusFailure,
  changePhoneVerificationStage,
  changePreferredLanguage,
  changePreferredLanguageSuccess,
  changePreferredLanguageFailure,
};

export type MemberState = ReturnType<typeof memberReducer>;
