import React, {FC, ReactNode, Suspense, useCallback, useEffect} from 'react';
import {LeftNavigationV2, MobileTabletNavigationV2} from 'components';
import SessionRateChangeAlert from 'components/Basic/Alert/SessionRateChangeAlert';
import {selectReleaseNotes, userActions} from 'features/User';
import ReleaseNotesInfo from 'features/User/ReleaseNote';
import {useWindowSize} from 'hooks';
import {useDispatch, useSelector} from 'react-redux';

import PaymentRequestGlobalHandler from '../../features/Appointment/Charge/PaymentRequestGlobalHandler';

export const DashboardLayoutV2: FC<{
  children: ReactNode[] | ReactNode;
}> = ({children}) => {
  const {width} = useWindowSize();
  const releaseNotes = useSelector(selectReleaseNotes);
  const notesExist = releaseNotes.data.length > 0;

  const dispatch = useDispatch();

  const getReleaseNotes = useCallback(() => {
    if (releaseNotes.data.length === 0) {
      dispatch(userActions.getReleaseNotes());
    }
  }, [releaseNotes]);

  useEffect(() => {
    getReleaseNotes();
  }, []);

  return (
    <>
      {notesExist ? <ReleaseNotesInfo releaseNotes={releaseNotes} /> : null}
      <SessionRateChangeAlert />
      <main className="min-w-full bg-white h-screen overflow-y-auto">
        {/* Left Navigation Area */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-28 xl:w-56 lg:flex-col">
          <div className="flex grow flex-col items-center xl:items-start gap-y-5 bg-white px-6 border-r overflow-hidden">
            <LeftNavigationV2 />
          </div>
        </div>
        {/* Main Content Area */}
        <section className="lg:pl-28 xl:pl-56 lg:bg-white overflow-y-auto overflow-x-hidden h-full relative">
          <Suspense>{children}</Suspense>
        </section>
        {/* Mobile Navigation Area */}
        {width && width < 1025 ? <MobileTabletNavigationV2 /> : null}

        <PaymentRequestGlobalHandler />
      </main>
    </>
  );
};
