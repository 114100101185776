import React from 'react';

import {SvgIcon} from './SvgIcon';

export const TickCircle: SvgIcon = ({
  width = 35,
  height = 35,
  strokeColor = 'text-blue-600',
  strokeWidth = 1.5,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
    stroke="currentColor"
    className={`stroke-current ${strokeColor} p-1.5 flex-none bg-gray-400 rounded-full shadow-inner leading-10`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export const WarningCircle: SvgIcon = ({
  width = 35,
  height = 35,
  strokeColor = 'text-orange-600',
  strokeWidth = 1.5,
  fillColor = 'none',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
    stroke="currentColor"
    className={`stroke-current ${strokeColor} p-1.5 flex-none bg-gray-400 rounded-full shadow-inner leading-10`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
    />
  </svg>
);

export const NoteCircle: SvgIcon = ({
  width = 24,
  height = 24,
  strokeColor = 'text-blue-600',
  strokeWidth = 1.5,
  fillColor = 'none',
  classes = 'p-0.5',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="0 0 24 24"
    strokeWidth={strokeWidth}
    stroke="currentColor"
    className={`stroke-current ${strokeColor} ${classes} flex-none bg-gray-400 rounded-full leading-10`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 11.5v5M12 7.51l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
    />
  </svg>
);

export const LeftNavIcon: SvgIcon = ({
  width = 42,
  height = 42,
  strokeColor = 'text-black',
  strokeWidth = 1.5,
  fillColor = 'none',
  classes = 'p-0.5',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="-0.5 0 25 25"
    strokeWidth={strokeWidth}
    stroke="currentColor"
    className={`stroke-current ${strokeColor} ${classes} flex-none leading-10`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M16.3272 2.99998L7.85661 11.9706C7.5919 12.25 7.5919 12.6912 7.85661 12.9853L16.3419 22"
    />
  </svg>
);

export const RightNavIcon: SvgIcon = ({
  width = 42,
  height = 42,
  strokeColor = 'text-black',
  strokeWidth = 1.5,
  fillColor = 'none',
  classes = 'p-0.5',
}) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    fill={fillColor}
    viewBox="-0.5 0 25 25"
    strokeWidth={strokeWidth}
    stroke="currentColor"
    className={`stroke-current ${strokeColor} ${classes} flex-none leading-10`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M7.6728 22L16.1434 13.0294C16.4081 12.75 16.4081 12.3088 16.1434 12.0147L7.65808 3"
    />
  </svg>
);
