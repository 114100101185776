import Profile from 'assets/images/silhouette.jpg';
import {selectUserProfile} from 'features/User';
import {CurrentUser, DrugInfoKey, MemberProfile} from 'interfaces';
import {useSelector} from 'react-redux';
import {isMember, joinArrStrings} from 'utils';

import {useToggle} from './useToggle';

export const useProfile = (): {
  medicalTeam: {
    role: string;
    toggleOn: boolean;
    onToggleClick: () => void;
    fullName: string | undefined;
    image: string | undefined;
    bio: string;
    insurances: string[];
    education: string;
    states: string[];
    languages: string[];
    licenses: string[];
    conditions: string[];
  }[];
  email: string;
  phoneNumber: string;
  medications: string;
  allergies: string;
  localPharmName: string;
  governmentID: string;
} => {
  const user = useSelector(selectUserProfile) as MemberProfile;
  const {toggleOn: docInfo, onToggleClick: onDocInfoClick} = useToggle();
  const {toggleOn: therapistInfo, onToggleClick: onTherapistInfoClick} =
    useToggle();

  const joinDrugInfoStrings = (
    drugInfoKey: DrugInfoKey,
    member: CurrentUser,
  ): string => {
    if (isMember(member)) {
      const drugInfo = member[drugInfoKey];
      return joinArrStrings(drugInfo);
    }
    return '';
  };

  const medications =
    joinDrugInfoStrings(DrugInfoKey.currentMedications, user) || 'N/A';

  const allergies = joinDrugInfoStrings(DrugInfoKey.allergies, user) || 'N/A';

  const localPharmName = isMember(user)
    ? user?.localPharmData?.pharmName
    : undefined;
  const medicalTeam = [
    {
      role: 'Doctor',
      toggleOn: docInfo,
      onToggleClick: onDocInfoClick,
      fullName: isMember(user) ? user.prescriberDetails?.fullName : undefined,
      image: isMember(user) ? user.prescriberDetails?.image : Profile,
      bio: user?.prescriberDetails?.bio,
      insurances: user?.prescriberDetails?.insurances,
      education: user?.prescriberDetails?.education,
      states: user?.prescriberDetails?.states,
      languages: user?.prescriberDetails?.languages,
      licenses: user?.prescriberDetails?.licenses,
      conditions: user?.prescriberDetails?.conditions,
    },
    {
      role: 'Therapist',
      toggleOn: therapistInfo,
      onToggleClick: onTherapistInfoClick,
      fullName: isMember(user) ? user.therapistDetails?.fullName : undefined,
      image: isMember(user) ? user.therapistDetails?.image : Profile,
      bio: user?.therapistDetails?.bio,
      insurances: user?.therapistDetails?.insurances,
      education: user?.therapistDetails?.education,
      states: user?.therapistDetails?.states,
      languages: user?.therapistDetails?.languages,
      licenses: user?.therapistDetails?.licenses,
      conditions: user?.therapistDetails?.conditions,
    },
  ].filter(member => Boolean(member.fullName));

  return {
    medicalTeam,
    email: user?.email ?? '-',
    phoneNumber: isMember(user) ? user.phoneNumber : '-',
    medications,
    allergies,
    localPharmName: localPharmName || '',
    governmentID: isMember(user) ? user?.governmentID : '',
  };
};
