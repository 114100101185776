import {
  PayPalCardDetail,
  PayPalPaymentCardsListResponse,
  PaystackCardDetail,
  PaystackPaymentCardsListResponse,
} from 'interfaces';

import {createAction} from '@reduxjs/toolkit';
import {StripeError, Token} from '@stripe/stripe-js';

// Redux action to initiate the addition of a credit card on stripe.
export const addCreditCard = createAction<{
  stripeResponse: {
    token?: Token | undefined;
    error?: StripeError | undefined;
  };
}>('AddCreditCard');
export const addCreditCardSuccess = createAction('AddCreditCardSuccess');
export const addCreditCardFailure = createAction<{message: string}>(
  'AddCreditCardFailure',
);

// Redux action to initiate the setting of a stripe default payment card.
export const setDefaultCard = createAction<{cardId: string; last4: string}>(
  'SetDefaultCard',
);
export const setDefaultCardSuccess = createAction<string>(
  'SetDefaultCardSuccess',
);
export const setDefaultCardFailure = createAction('SetDefaultCardFailure');

// Redux action to initiate the retrieval of the PayPal payment cards list.
export const getPayPalPaymentCardsList = createAction<{
  defaultCard?: string;
}>('GetPayPalPaymentCardsList');
export const getPayPalPaymentCardsListFailure = createAction(
  'GetPayPalPaymentCardsListFailure',
);
export const getPayPalPaymentCardsListSuccess = createAction<{
  message: PayPalPaymentCardsListResponse;
  defaultCard?: string;
}>('GetPayPalPaymentCardsListSuccess');

// Redux action to initiate the setting of a default payment card on PayPal.
export const setPayPalDefaultPaymentCard = createAction<PayPalCardDetail>(
  'SetPayPalDefaultPaymentCard',
);
export const setPayPalDefaultPaymentCardFailure = createAction(
  'SetPayPalDefaultPaymentCardFailure',
);
export const setPayPalDefaultPaymentCardSuccess = createAction<{
  newPaypalDefaultCard: string;
  newPaypalDefaultCardLast4: string;
}>('SetPayPalDefaultPaymentCardSuccess');

// Redux action to initiate the deletion of a payment card on PayPal.
export const deletePaymentCardOnPayPal = createAction<string>(
  'DeletePaymentCardOnPayPal',
);
export const deletePaymentCardOnPayPalFailure = createAction(
  'DeletePaymentCardOnPayPalFailure',
);
export const deletePaymentCardOnPayPalSuccess = createAction<string>(
  'DeletePaymentCardOnPayPalSuccess',
);

// Redux action to initiate the retrieval of the Paystack payment cards list.
export const getPaystackPaymentCardsList = createAction(
  'GetPaystackPaymentCardsList',
);
export const getPaystackPaymentCardsListFailure = createAction(
  'GetPaystackPaymentCardsListFailure',
);
export const getPaystackPaymentCardsListSuccess =
  createAction<PaystackPaymentCardsListResponse>(
    'GetPaystackPaymentCardsListSuccess',
  );

// Redux action to initiate the setting of a default payment card on Paystack.
export const setPaystackDefaultPaymentCard = createAction<PaystackCardDetail>(
  'SetPaystackDefaultPaymentCard',
);
export const setPaystackDefaultPaymentCardFailure = createAction(
  'SetPaystackDefaultPaymentCardFailure',
);
export const setPaystackDefaultPaymentCardSuccess = createAction<{
  newPaystackDefaultCard: string;
  newPaystackDefaultCardLast4: string;
}>('SetPaystackDefaultPaymentCardSuccess');

// Redux action to initiate the deletion of a payment card on Paystack.
export const deletePaymentCardOnPaystack = createAction<string>(
  'DeletePaymentCardOnPaystack',
);
export const deletePaymentCardOnPaystackFailure = createAction(
  'DeletePaymentCardOnPaystackFailure',
);
export const deletePaymentCardOnPaystackSuccess = createAction<string>(
  'DeletePaymentCardOnPaystackSuccess',
);
