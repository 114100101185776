import {RootState} from 'app/rootReducer';

import {createSelector} from '@reduxjs/toolkit';

import {PushNotificationState} from './pushNotificationSlice';

function getPushNotification(state: RootState): PushNotificationState {
  return state.pushNotification;
}

export const selectPushNotification = createSelector(
  getPushNotification,
  pushNotification => pushNotification,
);
