import React from 'react';

import {SvgIcon} from './SvgIcon';

export const SuitcaseIcon: SvgIcon = ({
  width = 26,
  height = 22,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  classes,
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={`fill-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.5,4v-.5c0-1.1-.9-2-2-2s-2,.9-2,2v.5h4Zm-5.5-.5v.5H3c-1.66,0-3,1.34-3,3v7c0,1.66,1.34,3,3,3H14c1.66,0,3-1.34,3-3V7c0-1.66-1.34-3-3-3h-2v-.5c0-1.93-1.57-3.5-3.5-3.5s-3.5,1.57-3.5,3.5Zm5.5,2h3.5c.83,0,1.5,.67,1.5,1.5v1.25H1.5v-1.25c0-.83,.67-1.5,1.5-1.5h7.5ZM1.5,9.75v4.25c0,.83,.67,1.5,1.5,1.5H14c.83,0,1.5-.67,1.5-1.5v-4.25H1.5Z"
    />
  </svg>
);
