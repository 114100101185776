import React, {FC} from 'react';
import {Button} from 'components';
import Modal from 'components/Basic/Modal';
import {SliceStatus, UserRoles} from 'interfaces';

type Props = {
  role?: UserRoles;
  bookingCharge: {
    status: SliceStatus;
    showModal: boolean;
    amount: number;
  };
  closeExtraChargeModal: () => void;
  onConfirmBooking: () => void;
};
const RecurringBookingChargeModal: FC<Props> = ({
  bookingCharge,
  closeExtraChargeModal,
  role,
  onConfirmBooking,
}) => {
  return (
    <Modal
      messageType="none"
      isOpen={bookingCharge.showModal}
      buttonFn={closeExtraChargeModal}
    >
      <article className="flex flex-col items-center justify-center px-5">
        {role === UserRoles.member ? (
          <p className="text-lg text-justify">
            Hey there! Seems like you have run out of time. The price for the
            session selected is: ${bookingCharge.amount}. Once you click&nbsp;
            <span className="font-semibold">Complete Booking</span>, your card
            on file will be charged. If you have previously canceled any
            appointments with more than 24hr notice in the past 30 days, please
            Text: “Balance 24” to (415) 449-7796 for immediate resolution.
          </p>
        ) : (
          <p className="text-lg text-center">
            Please note there will be a charge for this booking. Have you
            confirmed with patient about this session?
          </p>
        )}

        <section className="grid grid-cols-1 md:grid-cols-2 md:gap-x-5 mt-12">
          <Button
            type="button"
            onClick={onConfirmBooking}
            borderColor="transparent"
            className="rounded-full px-8 py-3"
          >
            {role === UserRoles.member ? 'Complete Booking' : 'YES, BOOK!'}
          </Button>
          <Button
            type="button"
            onClick={closeExtraChargeModal}
            borderColor="transparent"
            bgColor="red-600"
            textColor="red-600"
            className="rounded-full px-8 py-3 font-semibold"
            btnType="danger"
            outline
          >
            NO, GO BACK
          </Button>
        </section>
      </article>
    </Modal>
  );
};

export default RecurringBookingChargeModal;
