import React, {FC} from 'react';
import classnames from 'classnames';

interface Props {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  disabled?: boolean;
  current?: boolean;
  width?: string;
  slim?: boolean;
  focusColor?: 'primary' | 'secondary' | 'custom';
  classes?: string;
  activeClasses?: string;
}

export const Tab: FC<Props> = ({
  children,
  onClick = undefined,
  disabled = false,
  current = false,
  focusColor = 'primary',
  slim = false,
  width = '1/2',
  classes,
  activeClasses,
}) => {
  const isPrimary = focusColor === 'primary';
  const isSecondary = focusColor === 'secondary';
  const isCustom = focusColor === 'custom';
  const customClasses = !disabled && current && isCustom ? activeClasses : '';
  return (
    <span
      onClick={disabled ? undefined : onClick}
      className={classnames(
        `w-${width} py-4 px-1 text-center font-medium cursor-pointer md:text-base leading-snug`,
        customClasses,
        {
          'border-b-4 text-sm': isPrimary,
          'border-b-2 text-base': !isPrimary && !isCustom,
          'flex-1': !slim,
          'border-blue-600 text-blue-600 focus:text-blue-800 focus:border-blue-700':
            !disabled && current && isPrimary,
          'border-gray-700 text-gray-700 focus:text-gray-600 focus:border-gray-700 border-b-2':
            !disabled && current && isSecondary,
          'text-gray-400': !current && isSecondary,
          'border-transparent text-black hover:text-gray-700 focus:outline-none':
            !disabled && !current && !isCustom,
          'border-transparent text-gray-500 focus:outline-none italic':
            disabled,
        },
        classes,
      )}
    >
      {children}
    </span>
  );
};
