import {RootState} from 'app/rootReducer';
import {SliceStatus} from 'interfaces';

import {createSelector} from '@reduxjs/toolkit';

import {CurrencyState} from './currencySlice';

function getCurrency(state: RootState): CurrencyState {
  return state.currency;
}

export const selectCurrencyRateByCode = (code: string) =>
  createSelector(getCurrency, currency => currency.data[code]);

export const selectCurrencyRateLoadingState = createSelector(
  getCurrency,
  currency => currency.status,
);

export const selectCurrencyRateIsLoading = createSelector(
  selectCurrencyRateLoadingState,
  status => status === SliceStatus.pending,
);
